import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Redux States
import { 
  FETCH_USER_DATA,
  FETCH_USER_DATA_SUCCESS,
  FETCH_USER_PROFILE_FAIL,
  SET_USER_DATA,
  SET_USER_DATA_SUCCESS,
  SET_USER_DATA_FAIL,
  TOKEN_NOT_FOUND,
  CLEAR_USER_DATA,
} from "./actionTypes"

import {
  PROFILE_SUCCESS,
} from "../auth/profile/actionTypes"

import {
  fetchUserData,
  fetchUserDataSuccess,
  fetchUserDataFail,
  setUser,
  setUserSuccess,
  setUserFail,
  clearUserData,
} from "./actions"

// App methods
import { getUserData, updateUser } from 'api/api.js';
import { setProjects, setSharedProjects } from '../projects/actions';
import { setDatabases } from '../databases/actions';

function* fetchUserDataSaga() {

  try {
    console.log('[store/user/saga.js] Fetching user data...');

    const data = yield call(getUserData); // getUserData API call
    yield put(fetchUserDataSuccess(data));


    /*---- Dispatch action to set projects and databases ----*/

    if (data.projects) { yield put(setProjects(data.projects)) }
    if (data.sharedProjects) { yield put(setSharedProjects(data.sharedProjects)) }
    if (data.databases) { yield put(setDatabases(data.databases)) }

    /*---- Dispatch action to set projects and databases Tail ----*/


  } catch (error) {

    if (error.message === 'No token found') {
      yield put({ type: TOKEN_NOT_FOUND });
    } else {
      yield put(fetchUserDataFail(error.message));
    }

  }
}

function* setUserDataSaga(action) {
  try {
    const response = yield call(updateUser, action.payload); // updateUser API call
    yield put(setUserSuccess(response.data));
  } catch (error) {
    yield put(setUserFail(error.message));
  }
}

function* userSaga() {
  yield takeLatest(FETCH_USER_DATA, fetchUserDataSaga);
  yield takeEvery(SET_USER_DATA, setUserDataSaga);
  // Additional saga to handle profile update success
  yield takeEvery(PROFILE_SUCCESS, function*() {
    yield put(fetchUserData());
  });
}

export default userSaga;
