import {
  SET_PRODUCTS,
  SET_PRODUCT_IMAGE,

  /*---- Load Product Image ----*/
  LOAD_PRODUCT_IMAGES,
  LOAD_PRODUCT_IMAGES_SUCCESS,
  LOAD_PRODUCT_IMAGES_FAIL,

  GET_PRODUCTSET,
  GET_PRODUCTSET_SUCCESS,
  GET_PRODUCTSET_FAIL,

  ADD_NEW_PRODUCT,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAIL,

  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,

  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
} from "./actionTypes";


/*---- Set Products ----*/

export const setProducts = (products) => ({
  type: SET_PRODUCTS,
  payload: products,
});

export const setProductImage = (pdId, imgUrl) => ({
  type: SET_PRODUCT_IMAGE,
  payload: { pdId, imgUrl }
});

/*---- Set Products Tail ----*/




/*---- Load Product Image ----*/

export const loadProductImages = (products) => ({
  type: LOAD_PRODUCT_IMAGES,
  payload: products,
});

export const loadProductImagesSuccess = (images) => ({
  type: LOAD_PRODUCT_IMAGES_SUCCESS,
  payload: images,
});

export const loadProductImagesFail = (error) => ({
  type: LOAD_PRODUCT_IMAGES_FAIL,
  payload: error,
});

/*---- Load Product Image Tail ----*/




/*---- Get Products ----*/

export const getProductSet = () => ({
  type: GET_PRODUCTSET,
});

export const getProductSetSuccess = products => ({
  type: GET_PRODUCTSET_SUCCESS,
  payload: products,
});

export const getProductSetFail = error => ({
  type: GET_PRODUCTSET_FAIL,
  payload: error,
});

/*---- Get Products Tail ----*/



/*---- Add Product ----*/

export const addNewProduct = product => ({
  type: ADD_NEW_PRODUCT,
  payload: product,
})

export const addProductSuccess = newProduct => ({
  type: ADD_PRODUCT_SUCCESS,
  payload: newProduct,
})

export const addProductFail = error => ({
  type: ADD_PRODUCT_FAIL,
  payload: error,
})

/*---- Add Product Tail ----*/



/*---- Delete Product ----*/

export const deleteProduct = productId => ({
  type: DELETE_PRODUCT,
  payload: productId,
})

export const deleteProductSuccess = productId => ({
  type: DELETE_PRODUCT_SUCCESS,
  payload: productId,
})

export const deleteProductFail = error => ({
  type: DELETE_PRODUCT_FAIL,
  payload: error,
})

/*---- Delete Product Tail ----*/



/*---- Update Product ----*/

export const updateProduct = product => ({
  type: UPDATE_PRODUCT,
  payload: product,
})

export const updateProductSuccess = product => ({
  type: UPDATE_PRODUCT_SUCCESS,
  payload: product,
})

export const updateProductFail = error => ({
  type: UPDATE_PRODUCT_FAIL,
  payload: error,
})

/*---- Update Product Tail ----*/


