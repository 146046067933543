import {
  FETCH_USER_DATA,
  FETCH_USER_DATA_SUCCESS,
  FETCH_USER_DATA_FAIL,
  SET_USER_DATA,
  SET_USER_DATA_SUCCESS,
  SET_USER_DATA_FAIL,
  TOKEN_NOT_FOUND,
  CLEAR_USER_DATA,
} from "./actionTypes"

const INIT_STATE = {
  userData: {},
  loading: false,
  error: null,
  tokenNotFound: false
}

const userReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_USER_DATA:
      return { ...state, loading: true, error: null };

    case FETCH_USER_DATA_SUCCESS:
      return { ...state, loading: false, userData: action.payload, tokenNotFound: false };

    case FETCH_USER_DATA_FAIL:
      return { ...state, loading: false, error: action.payload };

    case SET_USER_DATA:
      return { ...state, loading: true, error: null };

    case SET_USER_DATA_SUCCESS:
      return { ...state, loading: false, userData: action.payload, tokenNotFound: false };

    case SET_USER_DATA_FAIL:
      return { ...state, loading: false, error: action.payload };

    case TOKEN_NOT_FOUND:
      return { ...state, tokenNotFound: true };

    case CLEAR_USER_DATA:
      return { ...state, userData: {}};

    default:
      return state;
  }
};

export default userReducer;




