import React, { useState, useEffect } from "react";
import { Modal, ModalBody, Input, Button, Alert } from "reactstrap";

import axiosInstance from 'utils/axiosInstance';

import PropTypes from 'prop-types';

const ShareModal = ({ show, onCloseClick, projectId, title='' }) => {

  const [shareEmail, setShareEmail] = useState('');
  const [shareWith, setShareWith] = useState([]);
  const [shareError, setShareError] = useState('');
  const handleEmailChange = (e) => setShareEmail(e.target.value);


  const handleShareOrder = async (shareEmail, projectId) => {

    console.log('shareEmail:', shareEmail);
    const payload = { shareEmail };

    try {
      const response = await axiosInstance.post(`/knovia/share-project/${projectId}`, payload);
      console.log('Project shared successfully', response.data);
      setShareError('');
      setShareWith(response.data.updatedProject.shareWith);
    } catch (error) {
      console.error('Error sharing project:', error);
      const thisError = error.response.data.message;
      if(thisError==='userToAdd not found'){
        setShareError('沒有此帳號');
      }
    }

  };

  const handleShare = () => {
    if (shareEmail) {
      handleShareOrder(shareEmail, projectId);
      setShareEmail('');
    }
  };
  
  const retrieveShareWith = async () => {
    try {
      const response = await axiosInstance.get(`/knovia/get-project-sharewith/${projectId}`);
      const thisShareWith = response.data.shareWith;
      console.log('Project shareWith:', thisShareWith);
      setShareWith(thisShareWith);

    } catch (error) {
      console.error('Error retrieving project shareWith:', error);
    }
  }

  useEffect(()=>{
    if(show){retrieveShareWith();}
  }, [show, projectId])

  const removeAccessClick = async (userEmail) => {
    try {
      const response = await axiosInstance.post(`/knovia/remove-project-access/${projectId}`, { accountToRemoveEmail: userEmail });
      
      // Update your component state or context to reflect the updated shareWith list
      console.log('Access removed successfully', response.data);
      // Optionally trigger a refresh of the shareWith list here
    } catch (error) {
      console.error('Error removing user from project:', error);
    }
  };


  return (

    <Modal size="sm" isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">

        <ModalBody className="text-center"
          style={{
            padding: '3rem 1.5rem 2rem 1.5rem',
          }}
        >

          {/* Close Button */}
          <button type="button" onClick={onCloseClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
          
          {/* Icon */}
          <div className="avatar-sm mb-4 mx-auto">
            <div className="avatar-title bg-warning text-warning bg-opacity-10 font-size-20 rounded-3">
              <i className="mdi mdi-share-variant-outline"></i>
            </div>
          </div>

          {title && (<p className="text-muted font-size-16 mb-2">{title}</p>)}

          <div 
            style={{
              background: '#fef8ee',
              padding: '0.7rem 0.8rem',
              borderRadius: '0.5rem',
              border: '1px solid #f1b34c',
              margin: '2rem 0rem 2rem 0rem',
              minHeight: '10vh',
            }}
          >
            {/* List shared users */}
            {shareWith && shareWith.map((account, index) => (
              <>
                <div key={index} className="d-flex justify-content-between align-items-center">
                  <span>{account}</span>
                  <Button color="outline-danger" size="sm" onClick={() => removeAccessClick(account)}>-</Button>
                </div>
                <div style={{ margin: '0.4rem 0.4rem' }}></div>
              </>
            ))}
          </div>

          <div className="d-flex mt-2 mb-4 justify-content-between align-items-center">
            {/* Email Input Field */}
            <Input
              type="email"
              className="form-control"
              placeholder="新增分享帳號（email）"
              value={shareEmail}
              onChange={handleEmailChange}
              style={{ margin: '0rem 0.5rem 0rem 0rem' }}
            />
            <Button style={{margin:'0rem 0.5rem'}} color="outline-success" size="sm" onClick={handleShare}>+</Button>
          </div>

          {shareError &&
            <Alert color="danger">{shareError}</Alert>
          }

          {/* Decision Buttons */}
          <div className="hstack gap-2 justify-content-center mb-0">
            <button type="button" className="btn btn-primary" onClick={onCloseClick}>確定</button>
          </div>

        </ModalBody>

      </div>
    </Modal>

  )

}

// Check the types of props passed into the Component
ShareModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  title: PropTypes.string,
  shareWith: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  })),
}

export default ShareModal;
