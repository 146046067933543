export const CLEAR_GOOGLE_LOGIN_STATES = 'CLEAR_GOOGLE_LOGIN_STATES';

export const SET_GOOGLE_UNAUTHORIZED_ERROR = 'SET_GOOGLE_UNAUTHORIZED_ERROR';

export const GOOGLE_LOGIN_REQUEST = 'GOOGLE_LOGIN_REQUEST';
export const GOOGLE_LOGIN_SUCCESS = 'GOOGLE_LOGIN_SUCCESS';
export const GOOGLE_LOGIN_FAILURE = 'GOOGLE_LOGIN_FAILURE';

export const GOOGLE_LOG_OUT = 'GOOGLE_LOG_OUT';
export const GOOGLE_LOG_OUT_SUCCESS = 'GOOGLE_LOG_OUT_SUCCESS';
export const GOOGLE_LOG_OUT_FAILURE = 'GOOGLE_LOG_OUT_FAILURE';