import React, { useMemo, useState, useEffect } from "react";
import {
	Row,
	Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

// Custom Axios
import axiosInstance from 'utils/axiosInstance';

import FoldableSection from './FoldableSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';

const outputKeyToLabelMapping = { chooseColumns: "選擇匯出資料", };
const adjustTextAreaHeight = (element) => {
  element.style.height = 'inherit'; 
  element.style.height = `${element.scrollHeight}px`; 
};

/*---- Main Modal Component ----*/
const ReferenceModal = ({ 
	isReferenceModalOpen, 
	setIsReferenceModalOpen, 
	referenceList, 
	originalInRef,
	simpleTranslation,
	currentTranslation,
	dbs,
	baseDbs,
	toggleLocked,
	isLocked,
	paragraphId,
}) => {

	const [referenceModalTitle, setReferenceModalTitle] = useState("編輯參考資料");
	const orderedReferenceKeys = ["Original", /*"Simple",*/ "Current", "Reference"];

	const [lockStatuses, setLockStatuses] = useState([]);
	useEffect(() => {
	  // Assuming referenceList is passed as a prop and contains the initial lock statuses
	  const initialLockStatuses = referenceList.map(ref => ref.refIsLocked);
	  setLockStatuses(initialLockStatuses);
	}, [referenceList]);


  // Auto-adjust Textarea height
  useEffect(() => {
    setTimeout(() => {
      document.querySelectorAll('.modal .form-control').forEach(textarea => { adjustTextAreaHeight(textarea); });
    }, 0);
  }, []);

  const toggleItemLocked = async (referenceIndex) => {
  	if(!isLocked){
		  try {
		  	const response = await axiosInstance.patch(`/knovia/toggle-lock/${paragraphId}/${referenceIndex}`);
	    	const data = response.data; // With axios, the JSON response is automatically parsed

		  	console.log('response:', response);

		    if (data.success) {
		      console.log('Lock status toggled', data.refIsLocked);

		      // Update the lockStatuses array
		      const updatedLockStatuses = [...lockStatuses];
		      updatedLockStatuses[referenceIndex] = data.refIsLocked; // Update the specific lock status
		      setLockStatuses(updatedLockStatuses); // Set the new state
		    } else {
		      console.error('Failed to toggle lock status');
		    }
		  } catch (error) {
		    console.error('Error toggling lock status:', error.response ? error.response.data : error.message);
		  }
	  }
	};

  return (
    
    <React.Fragment>
    {/*--- Modal for showing Paragraph Reference ---*/}

      <Modal 
      	isOpen={isReferenceModalOpen} 
      	toggle={() => setIsReferenceModalOpen(!isReferenceModalOpen)}
      	style={{ '--bs-modal-width': '60vw' }}
      >
        <ModalHeader toggle={() => setIsReferenceModalOpen(!isReferenceModalOpen)}>{referenceModalTitle}</ModalHeader>
        
        {/* Modal Body */}
        <ModalBody>

          {orderedReferenceKeys.map((key) => (

            <div key={key} style={{ marginBottom: "15px" }}>

              {key === "Reference" ? (
                <>

                	<Col sm="9" style={{
				            display: 'flex',
				            flexWrap: 'wrap',
				            flexDirection: 'row',
				            justifyContent: 'flex-start',
				            alignItems: 'center',
				            marginBottom: '0.85rem',
				          }}>
				            <button 
				              className="btn btn-outline-info" 
				              style={{ 
				                border: 'none',
				                display: 'flex',
				                justifyContent: 'center',
				                alignItems: 'center',
				              }}
				              onClick={() => toggleLocked()}
				            >
				              { 
				                isLocked ? 
				                <FontAwesomeIcon icon={faLock} /> : 
				                <FontAwesomeIcon icon={faUnlock} />
				              }
				            </button>
				            <h5 style={{ fontWeight: '1000', marginBottom: '0rem' }}>參考資料：</h5>
				          </Col>


	                {referenceList.map((r, index) => {

	                  const matchDb = dbs.filter(d=>d._id===r.glossaryId)[0] || 
	                    baseDbs.filter(b=>b._id===r.glossaryId)[0];

	                  return(

	                  	<Row key={`reference${index}`} style={{ marginBottom: '0.8rem' }}>

	                  		<Col sm="12">
	                  			<p className="mb-2">
		                        {`${index+1}. [${(r.similarity*100).toFixed(1)}%]   `}
		                        <span style={{ fontWeight: '600' }}>{`${ matchDb?.dbName }` || ''}</span>
		                      </p>
	                  		</Col>

	                  		<Col sm="5">
	                  			<textarea 
	                  				className="form-control"
	                  				style={{ width: "100%", resize: 'none', overflow: 'hidden' }}
	                  				readOnly
	                  				disabled
	                  				defaultValue={r.paragraph}
	                  			>
	                  			</textarea>
	                  		</Col>

	                  		<Col sm="6">
	                  			<textarea 
	                  				className="form-control"
	                  				style={{ width: "100%", resize: 'none', overflow: 'hidden' }}
	                  				defaultValue={r.p_secondLang}
	                  			>
	                  			</textarea>
	                  		</Col>

	                  		<Col sm="1">
	                  			<button
							              className= {isLocked?"btn btn-outline-secondary":"btn btn-outline-info"}
							              style={{ 
							                border: 'none',
							                display: 'flex',
							                justifyContent: 'center',
							                alignItems: 'center',
							              }}
							              onClick={() => toggleItemLocked(index)}
							            >
							              { 
							                lockStatuses[index] ? 
							                <FontAwesomeIcon icon={faLock} /> : 
							                <FontAwesomeIcon icon={faUnlock} />
							              }
							            </button>
	                  		</Col>

	                    </Row>

	                  )
	                })}
                </>
              ) : key === "Original" ? (
              	<>
	                <FoldableSection title="原始段落：" defaultOpen={true}>
                    <p style={{ marginBottom: '0rem' }}>
                      {`${originalInRef}`}
                    </p>
                  </FoldableSection>

                  {/* 分隔線 */}
                  <div style={{ margin:"1.5rem 0rem", borderBottom:"1px solid #828282" }}></div>

                </>
              ) : (
              	<>
		              <FoldableSection title="ChatGPT 翻譯（無參考資料）：" 
		                style={{ marginBottom: "1rem" }}>
		                <p style={{ marginBottom: '0rem' }}>
		                  {simpleTranslation || "[未進行 AI 翻譯]"}
		                </p>
		              </FoldableSection>

		              <FoldableSection title="TheChart 目前翻譯：" 
		                style={{ marginBottom: "1rem" }}>
		                <p style={{ marginBottom: '0rem' }}>
		                  {currentTranslation || "[未進行 AI 翻譯]"}
		                </p>
		              </FoldableSection>

		              {/* 分隔線 */}
                  <div style={{ margin:"1.5rem 0rem", borderBottom:"1px solid #828282" }}></div>

	              </>
              )}
              
            </div>
          ))}
        </ModalBody>

        {/* Modal Footer */}
        <ModalFooter>
          <Button color="secondary" onClick={() => setIsReferenceModalOpen(false)}>儲存編輯</Button>
        </ModalFooter>

      </Modal>

      {/*--- Modal for showing Paragraph Reference Tail ---*/}
    </React.Fragment>
  );

};

export default ReferenceModal;


