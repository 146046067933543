import React, { useState, useEffect, useCallback } from "react";
import {
  Col,
  Row,
  Button,
  Container,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown
} from "reactstrap";
import { map } from "lodash";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import debounce from 'lodash/debounce';

// Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

// Import Card
import CardContact from "./card-contact";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { getChatbots, setChatbotImage } from "store/actions";

// Custom Utils
import { Pagination } from 'utils/pagination';

// Custom Axios
import axiosInstance from 'utils/axiosInstance';

// Custom CSS
import './custom.css';
import placeholder from "assets/images/placeholders/placeholder_card.png";


const ChatbotsGrid = () => {


  /*---- Title and Initial Setup ----*/

  document.title = "訂製智能客服總覽 | TheChart.AI";
  const dispatch = useDispatch();
  const [sortedChatbots, setSortedChatbots] = useState([]);

  /*---- Title and Initial Setup Tail ----*/



  /*---- Getting Chatbots from Redux ----*/

  useEffect(() => {
    dispatch({ type: 'GET_CHATBOTS' });
  }, [dispatch]);

  const { chatbots, chatbotImages, userData } = useSelector(state => ({
    chatbots: state.chatbots.chatbots,
    chatbotImages: state.chatbots.chatbotImages,
    userData: state.user.userData,
  }));

  useEffect(() => { console.log('Chatbots_changed...', chatbots) }, [chatbots]);
  useEffect(() => { console.log('ChatbotImages_changed...', chatbotImages) }, [chatbotImages]);

  /*---- Getting Chatbots from Redux Tail ----*/


  const [isEditModalOpen, setIsEditModalOpen] = useState(false);


  /*---- Search Input ----*/

  const [searchValue, setSearchValue] = useState("");
  const [filterOption, setFilterOption] = useState('cbName');

  const filterOptionMappings = {
    'cbName': '智能客服名稱',
    'tags': '標籤',
  };

  // A generic filter function
  const applyFilter = (chatbots) => {
    const searchLower = searchValue.toLowerCase();

    if (filterOption === 'tags') {
      // Check if any tag matches the search value
      return chatbots.tags.some(tag => tag.toLowerCase().includes(searchLower));
    } else {
      // Handle other string-based filters
      const filterValue = chatbots[filterOption] ? chatbots[filterOption].toLowerCase() : '';
      return filterValue.includes(searchLower);
    }
  };

  /*---- Search Input Tail ----*/


  /*---- Sorting Function ----*/

  const [sortAttribute, setSortAttribute] = useState('cbName'); // default sort attribute
  const [sortDirection, setSortDirection] = useState('asc'); // 'asc' or 'desc'

  const sortDirectionMappings = { 'asc': '升序', 'desc': '降序' };
  const sortAttributeMappings = {
    'cbName': '智能客服名稱',
    'tags': '標籤',
    'favorite': '我的最愛',
  };

  // Function to toggle sort direction
  const toggleSortDirection = () => {
    setSortDirection(prevDirection => prevDirection === 'asc' ? 'desc' : 'asc');
  };

  // Function to sort the data
  const sortData = (a, b) => {
    const isAFavorite = (favoriteChatbots ? favoriteChatbots.includes(a._id) : 0);
    const isBFavorite = (favoriteChatbots ? favoriteChatbots.includes(b._id) : 0);

    if (sortAttribute === 'favorite') {
      if (isAFavorite && !isBFavorite) {
        return sortDirection === 'asc' ? -1 : 1;
      }
      if (!isAFavorite && isBFavorite) {
        return sortDirection === 'asc' ? 1 : -1;
      }
      // If both are favorites or both are not, sort by another attribute, like chatbotName
      return a.cbName.localeCompare(b.cbName);
    } else {
      // Rest of your sorting logic for other attributes
      if (a[sortAttribute] < b[sortAttribute]) { return sortDirection === 'asc' ? -1 : 1 }
      if (a[sortAttribute] > b[sortAttribute]) { return sortDirection === 'asc' ? 1 : -1 }
    }
    return 0;
  };

  /*---- Sorting Function Tail ----*/


  /*---- Favorite Function ----*/

  const [favoriteChatbots, setFavoriteChatbots] = useState([]);
  const [favoriteLoading, setFavoriteLoading] = useState(false);

  useEffect(()=>{
    if(userData?.stats?.favoriteChatbots){
      setFavoriteChatbots(userData.stats.favoriteChatbots);
    }
  },[userData]);

  const toggleFavorite = async (chatbotId) => {

    if (favoriteLoading) return; // Prevent action if a request is already in progress
    setFavoriteLoading(true);

    try {
      // Optimistically update the state
      const isCurrentlyFavorite = favoriteChatbots.includes(chatbotId);
      const newFavorites = isCurrentlyFavorite 
        ? favoriteChatbots.filter(id => id !== chatbotId)
        : [...favoriteChatbots, chatbotId];
      setFavoriteChatbots(newFavorites);

      const response = await axiosInstance.post('/knovia/toggle-favoritechatbot', {chatbot: chatbotId});

      if (response.status !== 200) {
        setFavoriteChatbots(favoriteChatbots); // revert the state
        console.error('Failed to update favorite status with status:', response.status);
      } else {
        setFavoriteChatbots(response.data);
      }

      /*---- PENDING: Should just update the store instead of fetching again ----*/
      dispatch({ type: 'FETCH_USER_DATA' });

    } catch (error) {
      setFavoriteChatbots(favoriteChatbots); // revert to the original favorites
      console.error('Error:', error);
    } finally {
      setFavoriteLoading(false);
    }

  };

  const debouncedToggleFavorite = useCallback(
    debounce(toggleFavorite, 300), [favoriteChatbots, favoriteLoading]
  );

  /*---- Favorite Function Tail ----*/



  /*---- Pagination START ----*/

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [totalPages, setTotalPages] = useState(1);
  const [currentItems, setCurrentItems] = useState([]);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const itemsPerPageMappings = {'4': '4', '8': '8', '12': '12'}

  // Updating current items, items viewable in current page
  useEffect(() => {
    const newLastItem = currentPage * itemsPerPage;
    const newFirstItem = newLastItem - itemsPerPage;
    setCurrentItems(sortedChatbots.slice(newFirstItem, newLastItem));
  }, [currentPage, itemsPerPage, sortedChatbots]);
  // Set and Reset totalPage when paragraphs changes
  useEffect(() => { setTotalPages(Math.ceil(sortedChatbots.length / itemsPerPage)) }, [sortedChatbots, itemsPerPage]);
  // If itemsPerPage changed by user
  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(Number(value)); // event.target.value is the chosen value
    setCurrentPage(1); // Reset to the first page
  };

  /*---- Pagination TAIL ----*/


  useEffect(()=>{
    if(chatbots.length){
      const newSortedChatbots = chatbots.filter(applyFilter).sort(sortData);
      console.log('newSortedChatbots:', newSortedChatbots);
      setSortedChatbots(newSortedChatbots);
    }
  }, 
    [
      chatbots, 
      favoriteChatbots, 
      sortAttribute, 
      sortDirection, 
      searchValue, 
      filterOption
    ]
  );


  /*---- Return JSX ----*/

  return (
    <React.Fragment>

      {/* Div Containing Card of Chatbots */}
      <div className="page-content">
        <Container fluid>

          {/* Render Breadcrumbs */}
          <Breadcrumbs title="智能客服" breadcrumbItem="訂製智能客服總覽" />

          <Row>
            <Col sm="12" md="7" lg="7" xl="5" 
              style={{ 
                display:"flex"
              }}
            >

              {/* 篩選條件 Dropdown */}
              <div
                style={{ 
                display:"flex",
                flexWrap: "wrap",
                alignContent: "center",
                marginRight: "0.5rem"
              }}
              >
                <UncontrolledDropdown>
                  <DropdownToggle tag="button" className="btn btn-outline-info">
                    {`搜尋條件：${filterOptionMappings[filterOption]}`} 
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>

                  <DropdownMenu>
                    {Object.entries(filterOptionMappings).map(([key, label]) => (
                      <DropdownItem key={key} onClick={() => setFilterOption(key)}>
                        {label}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              {/* 篩選條件 Dropdown Tail */}


              {/* 輸入篩選 Input */}
              <div 
                className="search-box" 
                style={{
                  display: "flex",
                  alignContent: "center",
                  flexWrap: "wrap",
                  marginTop: "0.4rem"
                }}
              >
                <div className="position-relative">
                  <label htmlFor="search-bar-0" className="search-label">
                    <span id="search-bar-0-label" className="sr-only">Search this table</span>
                    <input 
                      id="search-bar-0" 
                      type="text" 
                      className="form-control" 
                      placeholder={`搜尋 ${chatbots.length} 筆資料`}
                      value={searchValue}
                      onChange={(e)=>{
                        setSearchValue(e.target.value);
                        //console.log('search changed!', e.target.value);
                      }}
                    />
                  </label>
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
              </div>
              {/* 輸入篩選 Input Tail */}

            </Col>


            <Col sm="7" md="5" lg="5" xl="4" 
              className="custom-sorting-col"
              style={{ display: "flex" }}
            >

              <div 
                style={{ 
                  display: "flex",
                  flexWrap: "wrap",
                  alignContent: "center",
                  marginRight: "0.5rem"
                }}
              >
                {/* Dropdown for selecting sort attribute */}
                <UncontrolledDropdown>
                  <DropdownToggle tag="button" className="btn btn-outline-secondary">
                    排序條件: {sortAttributeMappings[sortAttribute]}
                  </DropdownToggle>
                  <DropdownMenu>
                    {Object.keys(sortAttributeMappings).map(key => (
                      <DropdownItem key={key} onClick={() => setSortAttribute(key)}>
                        {sortAttributeMappings[key]}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>

              {/* Dropdown for selecting sort direction */}
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignContent: "center",
                }}
              >
                <button className="btn btn-outline-secondary" onClick={toggleSortDirection}>
                  排序方向: {sortDirectionMappings[sortDirection]}
                </button>
              </div>

            </Col>


            <Col xs="12" sm="5" md="12" lg="12" xl="3"
              className="custom-item-per-page-col"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
              }}
            >
                {/* Dropdown for selecting sort attribute */}
                <UncontrolledDropdown>
                  <DropdownToggle tag="button" className="btn btn-outline-warning">
                    每頁顯示段落數：{itemsPerPage}
                  </DropdownToggle>
                  <DropdownMenu>
                    {Object.keys(itemsPerPageMappings).map(key => (
                      <DropdownItem key={key} onClick={() => handleItemsPerPageChange(key)}>
                        {itemsPerPageMappings[key]}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>

            </Col> 
             
          </Row>

          {/* Div Margin */}
          <Row style={{ marginBottom:"1rem" }}>
            <Col sm="9"></Col>
          </Row>


          {/* Cards of the Chatbots */}
          <Row style={{ minHeight: "20rem" }}>
            { map(currentItems, (cb, key) => (
              <CardContact 
                cb={cb} 
                cbImage={chatbotImages[cb._id]}
                favoriteChatbots={favoriteChatbots}
                key={"_cb_" + key} 
                onToggleFavorite = {debouncedToggleFavorite}
              />
            ))}
          </Row>

          {/* Pagination Controls */}
          <div style={{ marginBottom:"1.5rem" }}>
            <Pagination currentPage={currentPage} totalPages={totalPages} paginate={paginate} />
          </div>
          {/* Pagination Controls Tail */}

        </Container>
      </div>
    </React.Fragment>
  );
};


export default withRouter(ChatbotsGrid);
