import React from "react";
import {
  Container,
  Row,
  Col,
  Modal,
} from "reactstrap";
import PropTypes from "prop-types";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector } from "react-redux";

//import action
import { getChartsData as onGetChartsData } from "../../store/actions";

// Pages Components
import ActivityComp from "./ActivityComp";


// Main Dashboard-Abstract Component
const Dashboard = props => {

  //meta title
  document.title = "AI 文本摘要 | TheChart.AI";

  return (

    <React.Fragment>

      <div className="page-content">
        <Container fluid>

          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("功能")}
            breadcrumbItem={props.t("問問 AI：文本摘要")}
          />

          {/* Page Content */}
          <Row>
            <Col xl="12">

              {/* ActivityComp */}
              <Row>
                <Col xl="12">
                  <ActivityComp isAdmin={true}/>
                </Col>
              </Row>

            </Col>
          </Row>

        </Container>
      </div>

    </React.Fragment>

  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Dashboard);
