// utils/logger.js

/**
 * Logs messages or errors to the console with environment-aware verbosity.
 * @param {string} message - The primary message to log.
 * @param {any} [error] - Optional error object to log in development mode.
 */
export const log = (message, error = null) => {
  if (process.env.NODE_ENV === 'development') {
    // In development, log everything.
    console.log(message);
    if (error) {
      console.error(error);
    }
  } else if (process.env.NODE_ENV === 'production') {
    // In production, limit what you log.
    // Log only the message and perhaps anonymize or omit sensitive details.
    console.log(message);
    // Optionally, send the error to an error tracking service here.
  }
};