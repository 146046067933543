import React, { useMemo, useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import {
  ImageName,
  ImageContent,
  Masterknowledge,
  Masterquestion,
  UploadTimeStamp,
} from "./LatestTransactionCol";
import TableContainer from "./TableContainer";
import { fetchImage } from 'utils/imageUtils';

import axiosInstance from "utils/axiosInstance";

// Function to map uploadStatus to color
const getUploadStatusColor = (status) => {
  switch (status) {
    case 'File uploaded successfully!':
      return 'mediumseagreen';
    case 'Failed to upload file.':
    case 'Error occurred during upload.':
      return 'red';
    // Add more cases as needed
    default:
      return 'black'; // Default color for other statuses
  }
};

/*---- Main Component ----*/
const ChatbotImgTab = props => {  

  const { 
    chatbotId,
    chatbotImgList,
    chatbotImgfileInputRef, 
    handleChatbotImgFileChange, 
    handleChatbotImgButtonClick,
    chatbotImgFilename,
    chatbotImgUploadStatus,
    chatbotImageUrl,
    uploadChatbotImg,
    uploadLoading,
    chatbotImgUploadLoading,
    refreshChatbotImgData
  } = props;

  const [images, setImages] = useState({});

  const filteredData = useMemo(() => 
    chatbotImgList.map((dataEntry) => ({
      id: dataEntry._id,
      filename: dataEntry.filename,
      imageContent: dataEntry.filename,
      addedAt: dataEntry.addedAt,
    })
  ), [chatbotImgList]);

  useEffect(() => {
    const fetchImages = async () => {
      if (filteredData && filteredData.length > 0) {
        const imagePromises = filteredData.map(async (img) => {
          const imageData = await fetchImage(img.filename);
          return { [img.filename]: imageData };
        });
        try {
          const fetchedImagesArray = await Promise.all(imagePromises);
          const fetchedImages = fetchedImagesArray.reduce((acc, curr) => ({ ...acc, ...curr }), {});
          setImages(fetchedImages);
        } catch (error) {
          console.error('Error fetching images:', error);
        }
      }
    };

    fetchImages();
  }, [filteredData]);

  useEffect(()=>{
    console.log('IMAGE FETCHED:', images);
  },[images])

  useEffect(()=>{
    console.log('chatbotImgList:', chatbotImgList);
    console.log('filteredData:', filteredData);
  },[])




  /*---- Modal viewing the images ----*/

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const toggleModal = () => setModalOpen(!modalOpen);

  const onImageClick = (imageData) => {
    setSelectedImage(imageData);
    setModalOpen(true);
  };

  /*---- Modal viewing the images Tail ----*/




  /*---- Delete existing Data Entries ----*/

  const deleteDataEntry = async (filename) => {
    console.log("Deleting ChatbotImg:", filename);

    try {
      const response = await axiosInstance.delete(`/knovia/chatbots-removeImg/${chatbotId}`, {
        data: { chatbotImg: filename },
      });
      console.log(response.data.message);
      if (props.refreshChatbotImgData) {
        props.refreshChatbotImgData();
      }
    } catch (err) {
      console.error(err);
    }
  };

  /*---- Delete existing Data Entries ----*/





  /*---- Data Entries Table ----*/

  const columns = useMemo(
    () => [
      {
        Header: "#",
        filterable: false,
        disableFilters: true,
        width: 50,
        Cell: cellProps => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "圖片名稱",
        accessor: "filename",
        disableFilters: true,
        width: 250,
        filterable: false,
        Cell: cellProps => {
          return <ImageName {...cellProps} />;
        },
      },
      {
        Header: "圖片內容",
        accessor: "imageContent",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          const filename = cellProps.value;
          const imageData = images[filename];
          return <ImageContent value={filename} imageData={imageData} onImageClick={onImageClick} />;
        },
      },
      {
        Header: "上傳日期",
        accessor: "addedAt",
        disableFilters: true,
        width: 250,
        filterable: false,
        Cell: cellProps => {
          return <UploadTimeStamp {...cellProps} />;
        },
      },
      {
        Header: "刪除",
        disableFilters: true,
        width: 100,
        Cell: cellProps => {
          return (
            <Button 
              color="outline-danger"
              onClick={() => deleteDataEntry(cellProps.row.original.filename)}
              style={{
                height: '2rem',
                width: '2rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 0,  // Set to 0 to override any default padding
                marginLeft: 'calc(50% - 16px)',
              }}
            >
              -
            </Button>
          );
        },
      },
    ],
    [images]
  );

  return (
    
    <React.Fragment>
      <Row>
        <Col>

          {/*---- Upload Image ----*/}
          <div className="form-group mt-4">

            <Row>
              <input
                type="file"
                ref={chatbotImgfileInputRef}
                onChange={handleChatbotImgFileChange}
                style={{ display: 'none' }}
              />
              <Col sm="3">
                <button className="btn btn-outline-info" onClick={handleChatbotImgButtonClick}>
                  {chatbotImgUploadLoading &&
                    (<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>)
                  }
                  選擇圖片
                </button>
              </Col>
              <Col sm="9" style={{display:"flex", alignItems:"center"}}>
                <span>{chatbotImgFilename || '還沒有選擇圖片喔'}</span>
              </Col>
            </Row>

            <Row>
              <Col sm="12">

                {chatbotImgUploadStatus && (
                  <p style={{ color: getUploadStatusColor(chatbotImgUploadStatus) }}>
                    {chatbotImgUploadStatus}
                  </p>
                )}

              </Col>
            </Row>

            <Row>
              <div>
                {chatbotImageUrl && 
                  <img 
                    src={chatbotImageUrl} 
                    className="profile-pic" 
                    alt="Fetched"
                    style={{ height:"7rem", width:"7rem" }}
                  />
                }
              </div>
            </Row>

          </div>

          <div className="text-center mt-4">
            <Button type="button" color="danger" onClick={uploadChatbotImg}>
              上傳圖片
              {uploadLoading && 
                (<i className="bx bx-loader bx-spin font-size-16 align-middle"></i>)
              }
            </Button>
          </div>


          {/*---- Profile Pic Tail ----*/}
        </Col>
      </Row>

      {/* 分隔線 */}
      <div style={{margin:"5vh 0vw", borderBottom:"1px solid #bdbdbd"}}></div>

      <Row>
        <Col>
          {filteredData?
            (<TableContainer
              columns={columns}
              data={filteredData}
              isGlobalFilter={true}
              isAddOptions={false}
              customPageSize={10}
            />)
          :<p>loading...</p>}
        </Col>
      </Row>

      <Modal isOpen={modalOpen} toggle={toggleModal} style={{ display: 'flex', width: 'fit-content', maxWidth: 'fit-content' }}>
        <ModalHeader toggle={toggleModal}>瀏覽圖片</ModalHeader>
        <ModalBody style={{ width: 'fit-content', height: 'auto', maxWidth:'75vw' }}>
          {selectedImage && (
            <img src={selectedImage} alt="Selected" style={{ maxWidth: '70vw', maxHeight: '70vh' }} />
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>關閉</Button>
        </ModalFooter>
      </Modal>
   
    </React.Fragment>
  );

};

export default ChatbotImgTab;


