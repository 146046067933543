import React, { useState } from "react";
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { Link } from "react-router-dom";
import { size, map } from "lodash";

// Custom Modal
import InfoModal from "./InfoModal";

// Custom CSS
import './custom.css';

// FontAwesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as farStar } from "@fortawesome/free-regular-svg-icons";
import { faStar as fasStar } from "@fortawesome/free-solid-svg-icons";


const CardContact = ({ baseDb, baseDbImage, favoriteBaseDbs, onToggleFavorite }) => {


  //console.log('baseDb:', baseDb);
  //console.log('baseDbImage:', baseDbImage);
  //console.log('favoriteBaseDbs', favoriteBaseDbs);


  /*---- Title and Initial Setup ----*/
  // Determine if the baseDb is a favorite
  const cardIsFavorite = (favoriteBaseDbs ? favoriteBaseDbs.includes(baseDb?._id) : 0);


  /*---- Title and Initial Setup Tail ----*/

  const [infoModal, setInfoModal] = useState(false);
  const toggleInfoModal = () => { setInfoModal(!infoModal) };


  /*---- Main CardContact ----*/
  return (
    <React.Fragment>

      <InfoModal baseDb={baseDb} baseDbImage={baseDbImage} infoModal={infoModal} toggle={toggleInfoModal}  />

      <Col xl="3" sm="6">
        <Card className="text-center">
          <CardBody>

            {/* Favorite Star */}
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                fontSize: "20px",
              }} 
            >
              <FontAwesomeIcon 
                icon={cardIsFavorite ? fasStar : farStar} 
                color={cardIsFavorite ? "gold" : "grey"} 
                onClick={() => onToggleFavorite(baseDb._id)}
              />
            </div>



            {/*---- Img ----*/}

            <div className="mb-4" style={{display: "flex", justifyContent: "center"}}>
              {!baseDbImage ? (
                <span 
                  className="avatar-bigger avatar-title rounded-circle bg-soft bg-primary text-primary font-size-16"
                >
                  {baseDb?.dbName.charAt(0) || ''}
                </span>
              ) : (
                <img
                  className="rounded-circle avatar-bigger"
                  src={baseDbImage}
                  alt={baseDb?.dbName || "User Image"}
                  style={{ objectFit: "cover" }}
                />
              )}
            </div>

            {/*---- Img Tail ----*/}




            {/*---- Glossary Name and Client ----*/}

            <h5 className="font-size-15 mb-1">
              {baseDb?.dbName}
            </h5>
            <p className="text-muted">{baseDb?.dbClient}</p>

            {/*---- Glossary Name and Client Tail ----*/}




            {/*---- Attribute Tags ----*/}
            <div>
              {map(baseDb?.tags, (tag, index) =>
                  index < 2 && (
                    <Link to="#" className="badge bg-primary font-size-11 m-1" key={"_skill_" + baseDb?._id + index}>
                      {tag}
                    </Link>
                  )
              )}
              {size(baseDb?.tags) > 2 && (
                <Link to="#" className="badge bg-primary font-size-11 m-1" key={"_skill_" + baseDb?._id}>
                  {size(baseDb?.tags) - 2} + more
                </Link>
              )}
            </div>
            {/*---- Attribute Tags Tail ----*/}


          </CardBody>



          {/* The Bottom Icons */}
          <CardFooter className="bg-transparent border-top">

            <div className="contact-links d-flex font-size-20">

              {/*---- Info Icon ----*/}
              <div className="flex-fill" onClick={() => setInfoModal(!infoModal)}>
                <Link to="#" id={"info" + baseDb?._id}>
                  <i className="mdi mdi-help-circle-outline font-size-20 text-secondary me-1"></i>
                  <UncontrolledTooltip
                    placement="top"
                    target={"info" + baseDb?._id}
                  >
                    資訊
                  </UncontrolledTooltip>
                </Link>
              </div>
              {/*---- Info Icon Tail ----*/}

            </div>

          </CardFooter>
          {/* The Bottom Icons Tails */}


        </Card>
      </Col>
      {/*---- Card Content Tail ----*/}


    </React.Fragment>
  );
};

CardContact.propTypes = {
  baseDb: PropTypes.object
};

export default CardContact;
