import {
  FETCH_USER_DATA,
  FETCH_USER_DATA_SUCCESS,
  FETCH_USER_DATA_FAIL,
  SET_USER_DATA,
  SET_USER_DATA_SUCCESS,
  SET_USER_DATA_FAIL,
  TOKEN_NOT_FOUND,
  CLEAR_USER_DATA,
} from "./actionTypes"

export const fetchUserData = () => ({
  type: FETCH_USER_DATA,
})

export const fetchUserDataSuccess = userData => ({
  type: FETCH_USER_DATA_SUCCESS,
  payload: userData,
})

export const fetchUserDataFail = error => ({
  type: FETCH_USER_DATA_FAIL,
  payload: error,
})

export const setUser = user => ({
  type: SET_USER_DATA,
  payload: user,
})

export const setUserSuccess = user => ({
  type: SET_USER_DATA_SUCCESS,
  payload: user,
})

export const setUserFail = error => ({
  type: SET_USER_DATA_FAIL,
  payload: error,
})

export const clearUserData = () => ({
  type: CLEAR_USER_DATA,
})






