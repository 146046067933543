import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Alert,
  Badge,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";
import { map } from "lodash";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// Import Components
import Breadcrumbs from "components/Common/Breadcrumb";

// Import additional Libraries
import * as Yup from "yup";
import { useFormik } from "formik";
import * as moment from "moment";

// Custom Modal
import DeleteModal from "components/Common/DeleteModal";

// Import Images
import placeholder from "assets/images/placeholders/placeholder_card.png"

import './custom.css';

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  addNewProject as onAddNewProject,
  updateProject as onUpdateProject,
  deleteProject as onDeleteProject,
  setProjectImage,
} from "../../store/actions";

// Custom Axios
import axiosInstance from '../../utils/axiosInstance';

// Custom Utility Functions
import { dateGen } from 'utils/toolUtils';
import { useDeepCompareMemoize, fetchImage, uploadImg } from 'utils/imageUtils';

const handleValidDate = date => {
  const validDate = moment(new Date(date)).format("YYYY-MM-DD");
  return validDate;
};

const ProjectStatus = ({ status }) => {
  switch (status) {
    case "Not started":
      return <Badge className="bg-secondary"> {status} </Badge>;
    case "Ongoing":
      return <Badge className="bg-info"> {status} </Badge>;
    case "Pending":
      return <Badge className="bg-warning"> {status} </Badge>;
    case "Delay":
      return <Badge className="bg-danger"> {status} </Badge>;
    case "Completed":
      return <Badge className="bg-success"> {status} </Badge>;

    default:
      return <Badge className="bg-success"> {status} </Badge>;
  }
};


const ProjectsList = () => {


  /*---- Title and Initial Setup ----*/
  document.title = "專案列表 | TheChart.AI";
  const dispatch = useDispatch();

  // Set through fetchUserData
  const [sortedProjects, setSortedProjects] = useState([]);

  const { userData, projects, projectImages } = useSelector(state => ({
    userData: state.user.userData,
    projects: state.projects.projects,
    projectImages: state.projects.projectImages,
  }));

  // createDate first, then compare startDate
  useEffect(() => {
    const sortProjects = projects => {
      return [...projects].sort((a, b) => {
        if (a.createDate && b.createDate) {
          // Later createDate comes first
          return new Date(b.createDate) - new Date(a.createDate);
        }
        if (a.createDate && !b.createDate) {
          return -1;
        }
        if (!a.createDate && b.createDate) {
          return 1;
        }
        // Later startDate comes first
        return new Date(b.startDate) - new Date(a.startDate); 
      });
    };

    setSortedProjects(sortProjects(projects));
  }, [projects]);
  /*---- Title and Initial Setup Tail ----*/


  /*---- Modal Editing a Project ----*/

  // Current project in Edit Modal
  const [project, setProject] = useState();
  // State for Edit Modal Open or Close
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  // Open and close the edit modals
  const toggle = () => {
    if (modal) {
      setModal(false);
      setProject(null);
    } else {
      setModal(true);
    }
  };

  // Open the modal to edit a project
  const handleProjectClick = async(arg) => {
    
    const project = arg;
    const iimg = await(fetchImage(project.img));

    setProject({
      _id: project._id,
      img: project.img,
      imgUrl: iimg,
      name: project.name,
      description: project.description,
      status: project.status,
      startDate: project.startDate,
      endDate: project.endDate,
    });
    // Set to edit mode
    setIsEdit(true);
    // Open the Modal
    toggle();

  };

  /*---- Modal Editing a Project Tail ----*/



  /*---- Modal Deleting a Project ----*/

  // State for Delete Modal Open or Close
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (project) => {
    setProject(project);
    setDeleteModal(true);
  };

  // Deleting a project and close the delete modal
  const handleDeleteOrder = () => {
    if (project && project._id) {
      dispatch(onDeleteProject(project._id));
    }
    setDeleteModal(false);
  };

  /*---- Modal Deleting a Project ----*/



  /*---- Form Validation ----*/

  const validation = useFormik({

    enableReinitialize: true,

    // sets the inital values of the form fields
    initialValues: {
      _id: (project && project._id) || "",
      img: (project && project.img) || "",
      name: (project && project.name) || "",
      description: (project && project.description) || "",
      status: (project && project.status) || "",
      startDate: (project && project.startDate) || "",
      endDate: (project && project.endDate) || "",
    },

    // define validation rules using Yup
    validationSchema: Yup.object({
      name: Yup.string().required(
        "請輸入專案名稱"
      ),
      description: Yup.string().required(
        "請輸入專案描述"
      ),
      status: Yup.string().required(
        "請選擇專案進行狀態"
      ),
      startDate: Yup.date()
        .required("請選擇開始日期")
        .max(
          Yup.ref('endDate'),
          "開始日期不能晚於結束日期"
        ),
      endDate: Yup.date()
        .required("請選擇結束日期")
    }),

    // gets called when the form is submitted
    onSubmit: (values) => {

      console.log('values', values);

      // Editing project
      if (isEdit) {

        const updateProject = {
          _id: project._id,
          img: values.img,
          name: values.name,
          description: values.description,
          status: values.status,
          startDate: values.startDate,
          endDate: values.endDate,
        };
        dispatch(onUpdateProject(updateProject));

      // Adding new project, not currently in use
      } else {

        const newProject = {
          name: values.name,
          description: values.description,
          status: values.status,
          startDate: values.startDate,
          endDate: values.endDate,
        };
        // save new project
        dispatch(onAddNewProject(newProject));

      }

      toggle(); // Close the modal
    },

  });

  /*---- Form Validation Tail ----*/



  /*---- Loading All Project images ----*/

  /*
  const projectImgs = useMemo(() => {
    return projects?.map(project => project.img);
  }, [useDeepCompareMemoize(projects?.map(project => project.img))]);
  */

  useEffect(() => {
    console.log('Reloading all Project Images...');
    reloadAllImages();
  }, [projects]);

  const reloadAllImages = () => {
    let fetchedInThisCycle = {};
    projects?.forEach(p => {
      if (!fetchedInThisCycle[p._id]) {
        fetchedInThisCycle[p._id] = true;
        fetchImageAndUpdateProject(p);
      }
    });
  }

  const fetchImageAndUpdateProject = useCallback((project) => {
    console.log('running fetch...');
    // Ensure that a Promise is returned
    return fetchImage(project.img || '')
      .then(url => {
        dispatch(setProjectImage(project._id, url));
      })
      .catch(error => {
        console.error('Error fetching the image:', error);
        dispatch(setProjectImage(project._id, placeholder)); // Or a default image URL
      });
  }, [dispatch]);

  /*---- Loading All Project images Tail ----*/



  /*---- Uploading a new Project Image ----*/

  const [filename, setFilename] = useState('還沒有選擇圖片喔');
  const [newImageUrl, setNewImageUrl] = useState();

  const handleImageChange = async(event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    if (file) {
      const date14string = await dateGen();
      const newfilename = date14string+'_'+file.name;
      setFilename(newfilename);
      const {newImageUrl, uploadStatus} = await uploadImg(newfilename, file);
      setFieldValue('img', newfilename);
      setFieldValue('imgUrl', newImageUrl);
    }
  };

  /*---- Uploading a new Project Image Tail ----*/



  return (
    <React.Fragment>

      {/* The delete modal shown when delete buttons are clicked */}
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />

      {/* The main UI shown */}
      <div className="page-content">

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Projects" breadcrumbItem="專案列表" />

          <Row>
            <Col lg="12">
              <div>
                <div className="table-responsive">

                  {/* Table for showing the projects */}
                  <Table 
                    className="project-list-table table-nowrap align-middle table-borderless"
                    style={{ padding:"0rem 10rem 0rem 5rem" }}
                  >
                    
                    {/* 表格標題 */}
                    <thead>
                      <tr>
                        <th scope="col" style={{ width: "10%", textAlign:"center" }}>#</th>
                        <th scope="col" style={{ width: "40%", textAlign:"center" }}>專案</th>
                        <th scope="col" style={{ width: "15%", textAlign:"center" }}>專案開始時間</th>
                        <th scope="col" style={{ width: "15%", textAlign:"center" }}>專案結束時間</th>
                        <th scope="col" style={{ width: "15%", textAlign:"center" }}>狀態</th>
                        {/*<th scope="col">管理員</th>*/}
                        <th scope="col" style={{ width: "5%", textAlign:"center" }}>編輯</th>
                      </tr>
                    </thead>

                    {/* 表格內容：專案 */}
                    <tbody>

                      {/*有專案時顯示專案資訊*/}

                      {sortedProjects.length > 0 ? (
                        sortedProjects.map((project, index) => (

                          <tr key={index}>
                            {/* Importing company logos from the companies array of images */}
                            <td style={{ textAlign:"center" }}>
                              <img
                                src={projectImages[project._id] || placeholder}
                                alt={project.name}
                                className="avatar-sm"
                                style={{
                                  borderRadius: "50%",
                                  objectFit: "cover"
                                }}
                              />
                            </td>
                            {/* Project names and descriptions */}
                            <td style={{ textAlign:"center" }}>
                              <h5 className="text-truncate font-size-14">
                                <Link
                                  to={`/projects-overview/${project._id}`}
                                  className="text-dark"
                                >
                                  {project.name}
                                </Link>
                              </h5>
                              <p className="text-muted mb-0">
                                {project.description}
                              </p>
                            </td>

                            {/* Project startDate formatted */}
                            <td style={{ textAlign:"center" }}> 
                              {handleValidDate(project.startDate)}
                            </td>

                            {/* Project endDate formatted */}
                            <td style={{ textAlign:"center" }}>
                              {handleValidDate(project.endDate)}
                            </td>

                            {/* Project status */}
                            <td style={{ textAlign:"center" }}>
                              <ProjectStatus status={project.status} />
                            </td>

                            {/* Project admins profile pics */}
                            {/*
                            <td>
                              <div className="avatar-group">

                                {map(project.team, (member, index) =>
                                  !member.img || member.img !== "Null" ? (

                                    <div
                                      className="avatar-group-item"
                                      key={index}
                                    >
                                      <Link
                                        to="#"
                                        className="team-member d-inline-block"
                                        id="member1"
                                      >
                                        <img
                                          src={images[member.img]}
                                          className="rounded-circle avatar-xs"
                                          alt=""
                                        />
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="member1"
                                        >
                                          {member.name}
                                        </UncontrolledTooltip>
                                      </Link>
                                    </div>

                                  ) : (
                                    <div
                                      className="avatar-group-item"
                                      key={"_team_" + index}
                                    >
                                      <Link
                                        to="#"
                                        className="d-inline-block"
                                        id={"member" + member.id}
                                      >
                                        <div className="avatar-xs">
                                          <span
                                            className={
                                              "avatar-title rounded-circle bg-soft bg-" +
                                              member.color +
                                              " text-" +
                                              member.color +
                                              " font-size-16"
                                            }
                                          >
                                            {member.name.charAt(0)}
                                          </span>
                                          <UncontrolledTooltip
                                            placement="top"
                                            target={"member" + member.id}
                                          >
                                            {member.name}
                                          </UncontrolledTooltip>
                                        </div>
                                      </Link>
                                    </div>
                                  )
                                )}
                              </div>
                            </td>
                            */}

                            <td>
                              <UncontrolledDropdown>
                                {/* Drop down for edit actions */}
                                <DropdownToggle
                                  className="card-drop"
                                  tag="a"
                                >
                                  <i className="mdi mdi-dots-horizontal font-size-18" />
                                </DropdownToggle>

                                <DropdownMenu className="dropdown-menu-end">
                                  {/* Edit actions: Edit */}
                                  <DropdownItem
                                    onClick={() => handleProjectClick(project)}
                                  >
                                    <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
                                    Edit
                                  </DropdownItem>
                                  {/* Edit actions: Delete */}
                                  <DropdownItem
                                    onClick={() => onClickDelete(project)}
                                  >
                                    <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
                                    Delete
                                  </DropdownItem>

                                </DropdownMenu>

                              </UncontrolledDropdown>
                            </td>

                          </tr>

                        ))

                      ):(
                        <tr>
                          <td colSpan="6" style={{ textAlign: "center" }}>
                            <Alert className="mt-4" color="success">
                              尚未有專案，前往
                              <Link to="/projects-create" className="text-primary custom-page-link">
                                新增專案
                              </Link>
                            </Alert>
                          </td>
                        </tr>
                      )}

                    </tbody>

                  </Table>

                  {/* 專案編輯視窗 */}
                  <Modal isOpen={modal} toggle={toggle}>

                    {/* 專案編輯視窗標題 */}
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "編輯專案" : "新增專案"}
                    </ModalHeader>

                    {/* 專案編輯視窗內容 */}
                    <ModalBody>

                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit(); // 確定填寫內容符合格式
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            {/* Image cell, hidden */}
                            {/*
                            <Input
                              type="hidden"
                              value={validation.values.img || ""}
                              name="img"
                            />
                            */}

                            <div className="mb-3">
                              <Label for="projectImage" className="form-label">專案圖片</Label>
                              <Input
                                id="projectImage"
                                name="img"
                                type="file"
                                onChange={(event) => {
                                  handleImageChange(event, validation.setFieldValue);
                                }}
                              />
                              {validation.values.imgUrl && (
                                <div style={{ display:"flex", justifyContent:"center", margin:"1rem 0rem" }}>
                                  <img 
                                    src={validation.values.imgUrl} 
                                    alt="Project" 
                                    style={{ 
                                        width: '100px', 
                                        height: '100px',
                                        borderRadius: '50%',
                                        objectFit: 'cover'
                                    }}
                                  />
                                </div>
                              )}
                            </div>

                            {/* Team cell, hidden */}
                            <Input
                              type="hidden"
                              value={validation.values.team || ""}
                              name="team"
                            />

                            {/* Name cell */}
                            <div className="mb-3">
                              <Label className="form-label">專案名稱</Label>
                              <Input
                                name="name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name && validation.errors.name ? true : false
                                }
                              />
                              {/* Display error message */}
                              {validation.touched.name && validation.errors.name ? (
                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                              ) : null}
                            </div>

                            {/* Description cell */}
                            <div className="mb-3">
                              <Label className="form-label">專案描述</Label>
                              <Input
                                name="description"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.description || ""}
                                invalid={
                                  validation.touched.description && validation.errors.description ? true : false
                                }
                              />
                              {validation.touched.description && validation.errors.description ? (
                                <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                              ) : null}
                            </div>

                            {/* Status select */}
                            <div className="mb-3">
                              <Label className="form-label">專案進行狀態</Label>
                              <Input
                                name="status"
                                id="status1"
                                type="select"
                                className="form-select"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.status || ""
                                }
                              >
                                <option>Not started</option>
                                <option>Ongoing</option>
                                <option>Completed</option>
                                <option>Pending</option>
                                <option>Delay</option>
                              </Input>
                              {validation.touched.status && validation.errors.status ? (
                                <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
                              ) : null}
                            </div>

                            {/* Start Date select */}
                            <div className="mb-3">
                              <Label className="form-label">開始日期</Label>
                              <Input
                                name="startDate"
                                type="date"
                                format="YYYY/MM/DD"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.startDate && validation.errors.startDate ? true : false
                                }
                                value={validation.values.startDate || ""}
                              ></Input>
                              {validation.touched.startDate && validation.errors.startDate ? (
                                <FormFeedback type="invalid">{validation.errors.startDate}</FormFeedback>
                              ) : null}
                            </div>

                            {/* End Date select */}
                            <div className="mb-3">
                              <Label className="form-label">結束日期</Label>
                              <Input
                                name="endDate"
                                type="date"
                                format="YYYY/MM/DD"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.endDate && validation.errors.endDate ? true : false
                                }
                                value={validation.values.endDate || ""}
                              ></Input>
                              {validation.touched.endDate && validation.errors.endDate ? (
                                <FormFeedback type="invalid">{validation.errors.endDate}</FormFeedback>
                              ) : null}
                            </div>

                          </Col>
                        </Row>

                        {/* Save Button */}
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>

                      </Form>


                    </ModalBody>

                  {/* ^專案編輯視窗 */}
                  </Modal>

                </div>
              </div>
            </Col>
          </Row>

          {/* The load more hint at the bottom */}
          {/*
          <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                  Load more
                </Link>
              </div>
            </Col>
          </Row>
          */}

        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ProjectsList);
