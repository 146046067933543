import { call, put, takeLatest, all } from "redux-saga/effects";
import { fetchImage } from 'utils/imageUtils';

import {
  LOAD_DATABASE_IMAGES,
  LOAD_BASEDB_IMAGES,
  GET_DATABASES,
  GET_BASE_DATABASES,
  ADD_NEW_DATABASE,
  DELETE_DATABASE, 
  UPDATE_DATABASE,
} from "./actionTypes";

import {
  loadDatabaseImagesSuccess,
  loadDatabaseImagesFail,

  loadBaseDbImagesSuccess,
  loadBaseDbImagesFail,

  //fetchDatabases
  getDatabasesSuccess,
  getDatabasesFail,

  //fetchBaseDatabase
  getBaseDatabasesSuccess,
  getBaseDatabasesFail,

  updateDatabaseSuccess,
  updateDatabaseFail,

  deleteDatabaseSuccess,
  deleteDatabaseFail,

  addDatabaseSuccess,
  addDatabaseFail,
} from "./actions"

import { fetchUserData } from "../user/actions.js";

import { 
  getDatabases, //fetchDatabases
  getBaseDatabase, //fetchBaseDatabase
  addNewDatabase,
  updateDatabase,
  deleteDatabase
} from "api/database";

/*
function* fetchDatabases() {
  try {
    const response = yield call(getDatabases);
    yield put(getDatabasesSuccess(response));
  } catch (error) {
    yield put(getDatabasesFail(error));
  }
}
*/

function* fetchBaseDatabase() {
  try {
    const response = yield call(getBaseDatabase);
    yield put(getBaseDatabasesSuccess(response));
  } catch (error) {
    yield put(getBaseDatabasesFail(error));
  }
}

function* fetchDatabaseImages({ payload: databases }) {
  console.log('[store/databases/saga.js] Reloading all Database Images...');
  try {
    const imageUrls = yield all(databases.map(db => 
      call(fetchImage, db.img || 'default_placeholder.png')
    ));
    const images = databases.reduce((acc, db, index) => {
      acc[db._id] = imageUrls[index];
      return acc;
    }, {});
    yield put(loadDatabaseImagesSuccess(images));
  } catch (error) {
    yield put(loadDatabaseImagesFail(error.message));
  }
}

function* fetchBaseDatabaseImages({ payload: baseDbs }) {
  console.log('[store/databases/saga.js] Reloading all baseDb Images...');
  try {
    const imageUrls = yield all(baseDbs.map(baseDb => 
      call(fetchImage, baseDb.img || 'default_placeholder.png')
    ));
    const images = baseDbs.reduce((acc, baseDb, index) => {
      acc[baseDb._id] = imageUrls[index];
      return acc;
    }, {});
    yield put(loadBaseDbImagesSuccess(images));
  } catch (error) {
    yield put(loadBaseDbImagesFail(error.message));
  }
}

function* onUpdateDatabase({ payload: database }) {
  try {
    const response = yield call(updateDatabase, database);
    console.log("[store/databases/saga.js] Updating database...", response);
    yield put(updateDatabaseSuccess(response));
    yield put(fetchUserData());
  } catch (error) {
    console.log('error:', error);
    yield put(updateDatabaseFail(error));
  }
}

function* onDeleteDatabase({ payload: databaseId }) {
  try {
    const response = yield call(deleteDatabase, databaseId);
    console.log("[store/databases/saga.js] Deleting database...", response);
    yield put(deleteDatabaseSuccess(databaseId));
    yield(fetchUserData());
  } catch (error) {
    yield put(deleteDatabaseFail(error));
  }
}

function* onAddNewDatabase({ payload: database }) {
  try {
    const response = yield call(addNewDatabase, database);
    console.log("[store/databases/saga.js] Adding database...", response);
    yield put(addDatabaseSuccess(response));
    yield put(fetchUserData());
  } catch (error) {
    yield put(addDatabaseFail(error));
  }
}

function* databasesSaga() {
  //yield takeLatest(GET_DATABASES, fetchDatabase);
  yield takeLatest(LOAD_BASEDB_IMAGES, fetchBaseDatabaseImages);
  yield takeLatest(LOAD_DATABASE_IMAGES, fetchDatabaseImages);
  yield takeLatest(GET_BASE_DATABASES, fetchBaseDatabase);
  yield takeLatest(ADD_NEW_DATABASE, onAddNewDatabase);
  yield takeLatest(DELETE_DATABASE, onDeleteDatabase);
  yield takeLatest(UPDATE_DATABASE, onUpdateDatabase);
}

export default databasesSaga;



