import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, CardBody, CardTitle, Col, Container, Input, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { 
  describeOrder, 
  findDifferences,
  isCompleteOrderDrink,
  formatDateTimeToChinese,
  mapGoToQueryType,
  mapGoToQueryAnswer,
} from 'utils/kioskUtils';
import { calculateOrderPrice } from 'api/product';
// Custom Axios
import axiosInstance from 'utils/axiosInstance';
// Importing only the merge function
import merge from 'lodash/merge';
import Select from 'react-select';


const mergeInfo = async (thisJsonData, newJsonData, customerResponseType) => {

  console.log('merging Info...');
  console.log('thisJsonData:', thisJsonData);
  console.log('newJsonData:', newJsonData);
  console.log('customerResponseType:', customerResponseType);

  let newJsonAnswer = '';

  if(Object.keys(thisJsonData).length && Object.keys(newJsonData).length){
    const prevJsonInput = thisJsonData;
    const jsonInput = newJsonData;
    const jsonDifference = findDifferences(prevJsonInput, jsonInput);
    console.log('prevJsonInput:', prevJsonInput);
    console.log('jsonInput:', jsonInput);
    console.log('jsonDifference:', jsonDifference);

    const { statedOpening: prevStatedOpening, isPreOrder: prevIsPreOrder, preOrderTime: prevPreOrderTime,
      orderDrink: prevOrderDrink, askedConfirmOrder: prevAskedConfirmOrder, repliedConfirmOrder: prevRepliedConfirmOrder,
      askedOrderComplete: prevAskedOrderComplete, repliedOrderComplete: prevRepliedOrderComplete, askedNeedPbagaskedNeedPbag: prevAskedNeedPbag,
      repliedNeedPbag: prevRepliedNeedPbag, needPbag: prevNeedPbag, needPbagNum: prevNeedPbagNum,
      separatePbag: prevSeparatePbag, askedPaymentMethod: prevAskedPaymentMethod, repliedPaymentMethod: prevRepliedPaymentMethod,
      paymentMethod: prevPaymentMethod, invoiceMethod: prevInvoiceMethod,
      receivedBarCodeSuccessSignal: prevReceivedBarCodeSuccessSignal,
      isPaymentCompleted: prevIsPaymentCompleted, receivedPaymentSuccessSignal: prevReceivedPaymentSuccessSignal,
      isPaymentFailed: prevIsPaymentFailed, goToQuery: prevGoToQuery,
    } = prevJsonInput;

    const { statedOpening, isPreOrder, preOrderTime,
      orderDrink, askedConfirmOrder, repliedConfirmOrder,
      askedOrderComplete, repliedOrderComplete, askedNeedPbag,
      repliedNeedPbag, needPbag, needPbagNum,
      separatePbag, askedPaymentMethod, repliedPaymentMethod,
      paymentMethod, invoiceMethod, receivedBarCodeSuccessSignal, isPaymentCompleted, 
      receivedPaymentSuccessSignal, isPaymentFailed, goToQuery,
    } = jsonInput;

    console.log('prevGoToQuery:', prevGoToQuery);
    console.log('goToQuery:', goToQuery);
    /*
    console.log('isPaymentCompleted:', isPaymentCompleted);
    console.log('receivedPaymentSuccessSignal:', receivedPaymentSuccessSignal);
    */

    while(1){


      /*---- 對話開始 ----*/
      if(statedOpening==="N"){ newJsonAnswer = '您好! 今天想喝什麼?'; break; }
      /*---- 對話開始 ----*/


      /*---- 預定點單時間 ----*/
      if(isPreOrder==="Y" && preOrderTime && Object.keys(orderDrink).length===0){ 
        newJsonAnswer = '好的，沒問題，請問需要什麼飲品呢?'; break; 
      }
      /*---- 預定點單時間 ----*/


      const orderDrinkCheck = await isCompleteOrderDrink(orderDrink);
      console.log('orderDrinkCheck:', orderDrinkCheck);

      /*---- 顧客點單中 ----*/

      /* 顧客點餐品項完整 *//* 顧客補說大小冰塊甜度 */
      if(orderDrinkCheck==='ok' && repliedOrderComplete==="N"){ 
        const orderDescription = describeOrder(orderDrink);
        // alt: 沒問題，請問還需要加點其他品項嗎? 
        // alt: 還需要加點什麼嗎？ // alt: 還需要加點其他品項嗎？
        newJsonAnswer = `好的，您點的是${orderDescription}，對嗎? 還需要點些什麼呢?`; 
        break; 
      }

      /*--- 品項不合規---*/
      if(orderDrinkCheck.includes('is Invalid Item Name, try>>')){
        const alternateDrink = orderDrinkCheck.split('>>')[1];
        const thisItemOrder = orderDrinkCheck.split('#')[1].split(' ')[0];
        const thisItemName = orderDrink[Number(thisItemOrder)].itemName;
        newJsonAnswer = `好的，請問${thisItemName}是指${alternateDrink}嗎?我會幫您把品項名稱更新在訂單上`; break; 
      }
      /* 顧客新增不能做溫熱品項 */
      if(orderDrinkCheck.includes('can not be made Warm/Hot Drink')){
        const thisItemOrder = orderDrinkCheck.split('#')[1].split(' ')[0];
        const thisItemName = orderDrink[Number(thisItemOrder)].itemName;
        newJsonAnswer = `不好意思，${thisItemName}只能製作成冷飲哦`; break; 
      }
      /* 顧客品項加料不合規 */
      if(orderDrinkCheck.includes('has invalid toppings')){
        const thisItemOrder = orderDrinkCheck.split('#')[1].split(' ')[0];
        const thisItemName = orderDrink[Number(thisItemOrder)].itemName;
        const toppings = orderDrink[Number(thisItemOrder)].toppings;
        const toppingCount = Object.keys(toppings).reduce((acc, key) => acc + Number(toppings[key]), 0);
        // 顧客說配料要「多一點」
        const isOverLimit = Object.values(toppings).some(quantity => quantity === 9);
        console.log('isOverLimit:', Object.values(toppings), isOverLimit)

        if (isOverLimit) { newJsonAnswer = `配料份量是固定的不能調整哦`; } 
        else { newJsonAnswer = `抱歉，為確保飲品最佳風味與份量，最多只能加3種（3份）哦，現在${thisItemName}的加料有${toppingCount}份`;}
        break;
      }
      /*--- 品項不合規---*/

      /*--- 品項不完整---*/
      /* 顧客新增品項缺少冰塊甜度 */
      if(orderDrinkCheck.includes('has Invalid QtyIce and QtySugar')){
        const thisItemOrder = orderDrinkCheck.split('#')[1].split(' ')[0];
        const thisItemName = orderDrink[Number(thisItemOrder)].itemName;

        console.log("HERE!!!", orderDrinkCheck, thisItemName);

        /* 優惠套組 */
        if(thisItemName.includes('買一送一')){
          newJsonAnswer = `請問${thisItemName}需要什麼冰塊和甜度，提醒您買一送一的兩杯冰塊和甜度須一致哦`; break; 
        } else {
          newJsonAnswer = `好的，請問${thisItemName}的冰塊跟甜度?`; break; 
        }
      }
      /* 顧客新增品項缺少甜度 */
      if(orderDrinkCheck.includes('has Invalid QtySugar')){
        const thisItemOrder = orderDrinkCheck.split('#')[1].split(' ')[0];
        const thisItemName = orderDrink[Number(thisItemOrder)].itemName;
        newJsonAnswer = `好的，請問${thisItemName}的甜度要多少?`; break; 
      }
      /* 顧客新增品項缺少大小、甜度 */
      if(orderDrinkCheck.includes('has Invalid SizeDrink and QtySugar')){
        const thisItemOrder = orderDrinkCheck.split('#')[1].split(' ')[0];
        const thisItemName = orderDrink[Number(thisItemOrder)].itemName;
        newJsonAnswer = `好的，請問${thisItemName}要大杯還是中杯，甜度如何?`; break; 
      }
      /* 顧客新增品項缺少大小冰塊甜度 */
      if(orderDrinkCheck.includes('has Invalid SizeDrink, QtyIce and QtySugar')){
        const thisItemOrder = orderDrinkCheck.split('#')[1].split(' ')[0];
        const thisItemName = orderDrink[Number(thisItemOrder)].itemName;
        newJsonAnswer = `好的，請問${thisItemName}要大杯還是中杯，甜度、冰塊如何?`; break; 
      }
      /* 顧客新增品項缺少大小 */
      if(orderDrinkCheck.includes('has Invalid SizeDrink')){
        const thisItemOrder = orderDrinkCheck.split('#')[1].split(' ')[0];
        const thisItemName = orderDrink[Number(thisItemOrder)].itemName;
        newJsonAnswer = `好的，請問${thisItemName}要大杯還是中杯?`; break; 
      }
      /* 顧客新增品項缺少冰塊 */
      if(orderDrinkCheck.includes('has Invalid qtyIce')){
        const thisItemOrder = orderDrinkCheck.split('#')[1].split(' ')[0];
        const thisItemName = orderDrink[Number(thisItemOrder)].itemName;
        newJsonAnswer = `好的，請問${thisItemName}冰塊如何？`; break; 
      }
      /*--- 品項不完整---*/

      /* Difference in orderDrink */
      if(jsonDifference?.orderDrink?.length>0){
        const {itemName: diffItemName, qtyDrink: diffQtyDrink, sizeDrink: diffSizeDrink, qtyIce: diffQtyIce, 
          qtySugar: diffQtySugar, toppings: diffToppings, isOwnCup: diffIsOwnCup} = jsonDifference?.orderDrink[0];
        /* 顧客改飲料數量 */
        if(diffQtyDrink){
          newJsonAnswer = `好的，幫您改為${ describeOrder(orderDrink) }，還需要加點什麼嗎？`; 
          break; 
        }
      }

      

      /*---- 顧客點單中 ----*/




      /*----  顧客點單完成 ----*/

      /* 顧客確認不再加點 */ /* 顧客確認不再加點，有買一送一品項 */
      if(repliedOrderComplete==="Y" && repliedNeedPbag==="N"){
        const totalPrice = await calculateOrderPrice(orderDrink);
        const totalDrinkCount = orderDrink.reduce((acc, d) => {
          return acc + d.qtyDrink;
        }, 0);
        console.log('totalDrinkCount:', totalDrinkCount);
        const hasBuyOneGetOneFree = (orderDrink.map(d=>d.itemName).filter(d=>d.includes('買一送一')).length>0);
        // alt: 總金額是 85 元，請問要加購塑膠袋嗎? // alt: 一共是110元，請問需要加購塑膠袋嗎?
        if(hasBuyOneGetOneFree){
          newJsonAnswer = `算上買一送一優惠後，總金額是${totalPrice}元。請問要加購塑膠袋嗎? 一個塑膠袋是2元`; break; 
        } else {
          /* 顧客確認不再加點，有大量品項 */
          if(totalDrinkCount>10){
            newJsonAnswer = `您點的飲料總共是${totalPrice}元。請問您要購買塑膠袋嗎? 預計需要${Math.ceil(totalDrinkCount / 6)}個塑膠袋來分裝，每個塑膠袋2元，將增加${totalDrinkCount/5}元費用。`; 
            break;
          } else {
            newJsonAnswer = `您點的飲料總共是${totalPrice}元。請問要加購塑膠袋嗎? 一個塑膠袋是2元`; break; 
          }
        }
      }

      /*----  顧客點單完成 ----*/
      


      console.log('repliedNeedPbag:', repliedNeedPbag);
      console.log('repliedPaymentMethod:', repliedPaymentMethod);


      /*---- 選擇支付方式 ----*/
      if(repliedNeedPbag==="Y" && repliedPaymentMethod==="N" && paymentMethod===""){
        newJsonAnswer = `請問要使用哪一種支付方式呢?`; break;
      }
      /*---- 選擇支付方式 ----*/

      /* 不支援的支付方式 */
      if(paymentMethod==="WeChatPay"){
        newJsonAnswer = `抱歉，我們目前尚不支援微信支付`; break;
      }
      if(paymentMethod==="EzPay"){
        newJsonAnswer = `抱歉，我們目前尚不支援一卡通`; break;
      }

      console.log('invoiceMethod:', invoiceMethod);



      /*---- 選擇載具與掃描載具 ----*/
      if(repliedPaymentMethod==="Y" && invoiceMethod=="" && paymentMethod!=='EzCard'){
        newJsonAnswer = `發票要列印還是存載具?`; break;
      }
      console.log('receivedBarCodeSuccessSignal', receivedBarCodeSuccessSignal);
      if(invoiceMethod==="phone" && receivedBarCodeSuccessSignal==="N"){
        newJsonAnswer = `好的，請先將載具對準下方掃描區`; break;
      }
      /*---- 選擇載具與掃描載具 ----*/


      console.log('paymentMethod:', paymentMethod);
      console.log('isPaymentCompleted:', isPaymentCompleted);
      console.log('receivedPaymentSuccessSignal:', receivedPaymentSuccessSignal);


      /*---- 依據支付方式進行支付 ----*/
      if(isPaymentCompleted==="N" && isPaymentFailed==="N" && receivedPaymentSuccessSignal!=="Y"){
        console.log('HERE paymentMethod:', paymentMethod);
        /* 顧客選完發票方式，支付方式使用 LinePay */
        if(paymentMethod==="LinePay"){
          newJsonAnswer = `好的，請將Line Pay付款條碼對準下方掃描區`; break;
        }
        /* 顧客選完發票方式，支付方式使用悠遊卡 */
        if(paymentMethod==="EzCard"){
          newJsonAnswer = `好的，請將悠遊卡靠近悠遊卡NFC感應區`; break;
        }
        /* 顧客選完發票方式，支付方式使用信用卡 */
        if(paymentMethod==="CreditCard"){
          newJsonAnswer = `好的，請將信用卡靠近感應掃描區`; break;
        }
        /* 顧客選完發票方式，支付方式使用街口支付 */
        if(paymentMethod==="JkoPay"){
          newJsonAnswer = `好的，請將街口支付付款條碼對準下方掃描區`; break;
        }
        /* 顧客要用現金付款 */
        if(paymentMethod==="Cash"){

          const hasOwnCup = orderDrink.map(d=>d.isOwnCup).filter(d=>d==="Y").length>0;
          /* 無環保杯 */
          if(!hasOwnCup){
            newJsonAnswer = `好的，請您領取收據後，先至櫃台結帳，謝謝您! #對話結束`; break;
          } else {
            newJsonAnswer = `好的，請您領取收據後，先至櫃台結帳並將您的環保杯連同杯蓋拿給櫃檯人員，謝謝您! #對話結束`; break;
          }
          
        }


      } else if (isPaymentFailed==="Y"){

        /* 系統回覆付款失敗 */
        newJsonAnswer = `抱歉，付款失敗。請問您要再試一次還是要選擇另一種支付方式呢？`; 
        break;

      }
      /*---- 依據支付方式進行支付 ----*/



      /*---- 成功完成支付 ----*/
      if(receivedPaymentSuccessSignal==="Y"){

        /* 系統回覆付款成功，是預定訂單 */
        if(isPreOrder==="Y"){
          newJsonAnswer = `已完成付款囉! 謝謝您，發票已存入載具，請領取收據。我們會在預定時間前10至15分鐘開始為您製作飲品，請依照您的預訂時間${ formatDateTimeToChinese(preOrderTime) }，攜帶收據至櫃檯取餐，謝謝您! #對話結束`; 
          break;
        }

        /* 系統回覆 LinePay/信用卡/街口付款成功 */
        if(paymentMethod==="LinePay" || paymentMethod==="CreditCard" || paymentMethod==="JkoPay"){
          newJsonAnswer = `已完成付款囉! 謝謝您，發票已存入載具，請領取收據。我們將開始為您製作飲品，請稍待片刻，依照收據上的編號取餐 #對話結束`; 
          break;
        }
        /* 系統回覆悠遊卡付款成功 */
        if(paymentMethod==="EzCard"){
          newJsonAnswer = `已完成付款囉! 謝謝您，發票已存入悠遊卡載具，請領取收據。我們將開始為您製作飲品，請稍待片刻後，依照收據上的編號取餐 #對話結束`; 
          break;
        }

      } else if(receivedPaymentSuccessSignal==="N"){

        
      }
      /*---- 成功完成支付 ----*/

      newJsonAnswer = `沒有對應的回答喔`;
      break;
    }

    console.log('JSON ANSWER 1', newJsonAnswer);

    if(goToQuery.length){
      const goToQueryType = mapGoToQueryType(goToQuery);
      console.log('GOTOQUERY TYPE', goToQueryType);
      const goToQueryAnswer = mapGoToQueryAnswer(goToQueryType);
      console.log('GOTOQUERY ANSWER', goToQueryAnswer);
      //if(newJsonAnswer==='沒有對應的回答喔' && goToQueryAnswer){newJsonAnswer = goToQueryAnswer}
      if(goToQueryAnswer){newJsonAnswer = goToQueryAnswer}
      // 顧客還有其他問題時，不應直接進入確認訂單步驟
      if(newJsonAnswer.includes('還需要點些什麼呢?')  && goToQueryAnswer){
        newJsonAnswer = goToQueryAnswer;
      }
    }
  }

  if(customerResponseType){
    const responseTypeAnswer = mapGoToQueryAnswer(customerResponseType);
    console.log('RESPONSETYPE ANSWER', responseTypeAnswer);
    //if(newJsonAnswer==='沒有對應的回答喔' && responseTypeAnswer){newJsonAnswer = responseTypeAnswer}
    if(responseTypeAnswer){newJsonAnswer = responseTypeAnswer}
  }
  if(newJsonAnswer==='沒有對應的回答喔'){
    newJsonAnswer = '不好意思，我不太確定您的意思，可以換句話說嗎？'
  } else if(newJsonAnswer===''){
    newJsonAnswer = '不好意思，我不太確定您的意思，可以再說一次嗎？'
  }

  return newJsonAnswer;

}
const options = [
  { value: '顧客', label: '顧客' },  // Customer
  { value: '系統', label: '系統' }   // System
];

/*---- Main Function ----*/
const QADialog = () => {

  /*---- Title and Initial Setup ----*/
  document.title = "點餐對話模擬 | TheChart.AI";
  const [prevJson, setPrevJson] = useState({});
  const [thisJson, setThisJson] = useState({});
  const [prevJsonString, setPrevJsonString] = useState({});
  const [thisJsonString, setThisJsonString] = useState({});
  useEffect(()=>{//console.log('prevJson changed!', prevJson);
    setPrevJsonString(JSON.stringify(prevJson, null, 2))},[prevJson]);
  useEffect(()=>{//console.log('thisJson changed!', thisJson);
    setThisJsonString(JSON.stringify(thisJson, null, 2))},[thisJson]);
  const [deltaJson, setDeltaJson] = useState({});
  const [jsonAnswer, setJsonAnswer] = useState('');
  const [simInput, setSimInput] = useState('');
  const [simDialog, setSimDialog] = useState('');
  const [simDialogArray, setSimDialogArray] = useState(['店員：您好! 今天想喝點什麼?']);
  const [jsonReturn, setJsonReturn] = useState('');
  const [queryReturn, setQueryReturn] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const textAreaRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState('顧客'); // Default to '顧客'
  const [canEditInput, setCanEditInput] = useState(true);
  const handleChange = selectedOption => {
    console.log("Selected option:", selectedOption);
    setSelectedOption(selectedOption.value);
  };

  const initiateThisJson = ()=>{
    setThisJson({
      "statedOpening": "N",
      "isPreOrder": "N",    
      "preOrderTime": "",
      "orderDrink":[
        {
          "itemName": " ",
          "qtyDrink": 0,
          "sizeDrink": "",
          "qtyIce": "",
          "qtySugar": "",
          "toppings": {},
          "isOwnCup": "N"
        }
      ],
      "askedConfirmOrder": "N",    
      "repliedConfirmOrder": "N",
      "askedOrderComplete": "N",
      "repliedOrderComplete": "N",
      "askedNeedPbag": "N",
      "repliedNeedPbag": "N",
      "needPbag": "N",
      "needPbagNum": 0,
      "separatePbag": "N",
      "askedPaymentMethod": "N",
      "repliedPaymentMethod": "N",
      "paymentMethod": "",
      "invoiceMethod": "",
      "receivedBarCodeSuccessSignal": "N",
      "isPaymentCompleted": "N",
      "receivedPaymentSuccessSignal": "N",
      "isPaymentFailed": "N",
      "goToQuery": []
    });
  }

  useEffect(()=>{
    setSimDialog(simDialogArray.join('\n'));
  },[simDialogArray])
  useEffect(()=>{
    initiateThisJson();
  }, [])
  /*---- Title and Initial Setup ----*/

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
    }
  }, [simDialog]); 

  const startNewDialog = ()=>{
    setPrevJson({});
    initiateThisJson();
    setDeltaJson({});
    setJsonAnswer('');
    setSimInput('');
    setSimDialog('');
    setSimDialogArray(['店員：您好! 今天想喝點什麼?']);
    setJsonReturn('');
    setQueryReturn('');
    setIsLoading(false);
  }

  const findResponseType = async (newSimDialogArray)=>{
    try {
      const response = await axiosInstance.post(
        `/knovia/kiosk/customer-responsetype`, { simDialogArray: newSimDialogArray }
      );
      const { customerResponseType } = response.data;
      //console.log('responseType:', customerResponseType);
      setQueryReturn(customerResponseType);
      return customerResponseType;
    } catch (error) {
      console.error('Error findResponseType:', error);
    }
  }

  const defaultDrink = {
    "itemName": " ", // Ideally, this should not be blank in practice
    "qtyDrink": 1,
    "sizeDrink": "",
    "qtyIce": "",
    "qtySugar": "",
    "toppings": {},
    "isOwnCup": "N"
  };

  const mergeDrinks = (originalDrinks, deltaDrinks) => {
    // Convert originalDrinks to a map for quick lookup and merging
    const drinkMap = new Map(originalDrinks.map(drink => [drink.itemName.trim(), {...defaultDrink, ...drink}]));

    // Iterate over deltaDrinks to update, add, or cancel drinks
    deltaDrinks.forEach(deltaDrink => {
      const trimmedItemName = deltaDrink.itemName.trim();
      if (trimmedItemName) {
        if (drinkMap.has(trimmedItemName)) {
          const existingDrink = drinkMap.get(trimmedItemName);
          if (deltaDrink.qtyDrink === 0) {
            // Option 1: Remove the drink from the list to handle cancellation
            drinkMap.delete(trimmedItemName);

            // Option 2: Alternatively, mark the drink as cancelled by setting a flag or modifying quantity
            // existingDrink.cancelled = true; // Uncomment this if you prefer marking over deletion
            // drinkMap.set(trimmedItemName, existingDrink);
          } else {
            // Deep merge for nested objects like toppings
            if (deltaDrink.toppings) {
              deltaDrink.toppings = {...existingDrink.toppings, ...deltaDrink.toppings};
            }
            // Merge changes into existing drink items
            drinkMap.set(trimmedItemName, {...existingDrink, ...deltaDrink});
          }
        } else {
          // Add new drink items not existing in original
          drinkMap.set(trimmedItemName, {...defaultDrink, ...deltaDrink});
        }
      }
    });

    // Optionally remove the placeholder if any real drink is added
    if (drinkMap.size > 1 && drinkMap.has("")) {
      drinkMap.delete("");
    }

    // Convert the map back to an array
    return Array.from(drinkMap.values());
  };

  const generateNewJson = (deltaJson) => {
    // Clear goToQuery and prepare the base for new merges
    const updatedJson = {...thisJson, goToQuery: []};
    console.log("HERE", deltaJson, deltaJson.orderDrink);
    // Special handling for the 'orderDrink' array
    if (deltaJson.orderDrink) {
      console.log("HERE2");
      //updatedJson.orderDrink = mergeDrinks(thisJson.orderDrink, deltaJson.orderDrink);
      updatedJson.orderDrink = [...deltaJson.orderDrink];
      delete deltaJson.orderDrink; // Remove so it doesn't get re-merged
    }

    // Merge other properties normally
    const newJson = merge({}, updatedJson, deltaJson);
    setThisJson(newJson);
    return newJson;
  };

  const findNewJson = async (newSimDialogArray)=>{
    try {

      // Assuming thisJson is correctly structured and accessible
      const updatedJson = {...thisJson, goToQuery: []};
      console.log('Updated thisJson:', updatedJson);

      const response = await axiosInstance.post(
        `/knovia/kiosk/customer-newjson`, { 
          simDialogArray: newSimDialogArray, 
          thisJson: updatedJson
        }
      );
      const { deltaJson: deltaJsonString } = response.data;
      const deltaJson = JSON.parse(deltaJsonString);
      //console.log('deltaJson:', deltaJson);
      setJsonReturn(JSON.stringify(deltaJson, null, 2));
      setPrevJson(thisJson);
      const newJson = generateNewJson(deltaJson);
      setCanEditInput(true);
      return {thisJsonData: thisJson, newJsonData: newJson};
    } catch (error) {
      console.error('Error findNewJson:', error);
    }
  }

  const newCustomerInput = async ()=>{
    //console.log('顧客輸入:', simInput);
    setSimInput('');
    let newSimDialogArray = [];
    if(selectedOption==='顧客'){
      newSimDialogArray = [...simDialogArray, `顧客：${simInput}`];
    } else if(selectedOption==='系統'){
      newSimDialogArray = [...simDialogArray, `<系統訊息>：${simInput}`];
    }
    setSimDialogArray(newSimDialogArray); 
    setIsLoading(true);
    setCanEditInput(false);

    /*
    const tasks = [
      findResponseType(newSimDialogArray)
      findNewJson(newSimDialogArray),
    ];
    const results = await Promise.all(tasks);
    setIsLoading(false);

    const [ customerResponseType, jsonReturn ] = results;

    const { thisJsonData, newJsonData } = jsonReturn;
    const newJsonAnswer = await mergeInfo(thisJsonData, newJsonData, customerResponseType);

    const newNewSimDialogArray = [...newSimDialogArray, `店員：${newJsonAnswer}`];
    setSimDialogArray(newNewSimDialogArray);
    */

    // Start both asynchronous operations without waiting
    const customerResponseTypePromise = findResponseType(newSimDialogArray);
    const findNewJsonPromise = findNewJson(newSimDialogArray);

    // First resolve the findResponseType
    const customerResponseType = await customerResponseTypePromise;

    let jsonReturn;
    if (["specificType1", "specificType2", "specificType3"].includes(customerResponseType)) {
      //  If the customer response type is one of the specific values,
      //  do not wait for findNewJson to finish and use a default or previously cached value
      jsonReturn = { thisJsonData: {}, newJsonData: {} };
    } else {
      // If not, wait for findNewJson
      jsonReturn = await findNewJsonPromise;
    }

    setIsLoading(false);

    const { thisJsonData, newJsonData } = jsonReturn;
    const newJsonAnswer = await mergeInfo(thisJsonData, newJsonData, customerResponseType);

    // Append the new answer to the dialog array and update the state
    const newNewSimDialogArray = [...newSimDialogArray, `店員：${newJsonAnswer}`];
    setSimDialogArray(newNewSimDialogArray);
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="點餐" breadcrumbItem="對話模擬" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    點餐對話模擬
                  </CardTitle>

                  <Row
                    style={{
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >

                    {/*--- Prev Json ---*/}
                    {/*
                    <Col lg="3" md="3">
                      <div>
                        <p>Prev Json</p>
                        <textarea
                          readOnly
                          className="form-control scrollable-div"
                          value={prevJsonString}
                          style={{ height: '80vh' }}
                          onChange={(e) => { setPrevJson(e.target.value)} }
                        />
                      </div>
                    </Col>

                    <Col lg="3" md="3">
                      <div>
                        <p>This Json</p>
                        <textarea
                          readOnly
                          className="form-control scrollable-div"
                          value={thisJsonString}
                          style={{ height: '80vh' }}
                          onChange={(e) => { setThisJson(e.target.value)} }
                        />
                      </div>
                    </Col>
                    */}

                    <Col lg="12">
                      <Row style={{ marginBottom:'0.3rem' }}>
                        <Col lg="6" style={{ display: 'flex', alignItems: 'center' }}>
                          <p style={{ margin:'0' }}>對話進行</p>
                        </Col>
                        <Col lg="6" style={{ display:'flex', justifyContent: 'end'}}>
                          <Button color='info' onClick={()=>{startNewDialog();}}>開新對話</Button>
                        </Col>
                      </Row>
                      <textarea
                        className="form-control scrollable-div"
                        value={simDialog}
                        style={{ height: '45vh' }}
                        readOnly
                        ref={textAreaRef}
                      />
                      <Row style={{ marginTop: '1rem', paddingRight:'1rem' }}>
                        <Col sm="3" style={{ display:'flex', alignItems:'center' }}>
                          <Select
                            defaultValue={options[0]}
                            options={options}
                            onChange={handleChange}
                            isClearable={false}
                          />
                        </Col>
                        <Col sm="8">
                          <Input
                            id="simInput"
                            name="simInput"
                            type="text"
                            className="form-control"
                            placeholder="模擬對話輸入"
                            value={simInput}
                            onChange={(e)=>{ setSimInput(e.target.value); }}
                            disabled={!canEditInput}
                          />
                        </Col>
                        <Col sm="1" style={{ padding: '0', display: 'flex', justifyContent: 'center' }}>
                          <Button 
                            color="info"
                            onClick={() => { 
                              newCustomerInput();
                            }}
                            onChange={()=>{}}
                          >
                            {isLoading ?
                              (<i className="bx bx-loader bx-spin font-size-16 align-middle"></i>
                              ) : (
                                `>>`
                              )
                            }
                          </Button>
                        </Col>
                      </Row>


                      <Row style={{ marginTop:'1rem' }}>
                        {/*
                        <Col lg="6" md="6">
                          <p>Json Return</p>
                          <textarea
                            readOnly
                            className="form-control scrollable-div"
                            value={jsonReturn}
                            style={{ height: '20vh' }}
                            onChange={(e) => { setJsonReturn(e.target.value)} }
                          />
                        </Col>
                        */}
                        <Col lg="12" md="12">
                          <p>任務類型</p>
                          <textarea
                            readOnly
                            className="form-control scrollable-div"
                            value={queryReturn}
                            style={{ height: '20vh' }}
                            onChange={(e) => { setQueryReturn(e.target.value)} }
                          />
                        </Col>
                      </Row>


                    </Col>
                  </Row>

                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>

  );
};

export default QADialog;
