import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Col,
  Row,
} from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";

// Redux
import { useSelector, useDispatch } from "react-redux";

// Custom Utility Functions
import { dateGen } from 'utils/toolUtils';
import { fetchImage, uploadImg } from 'utils/imageUtils';

import { updateChatbot as onUpdateChatbot } from "store/actions";

// Import Images
import placeholder from "assets/images/placeholders/placeholder_card.png"


const EditModal = ({ cb, cbImage, editModal, toggle }) => {

  //console.log('cb', cb);
  //console.log('editModal', editModal);


  /*---- Initial Setup ----*/
  const dispatch = useDispatch();

  // Current chatbot in Edit Modal
  const [chatbot, setChatbot] = useState({});
  useEffect(()=>{
    if(cb){ setChatbot(cb) }
  },[cb])

  /*---- Initial Setup Tail ----*/



  /*---- Form Validation ----*/

  const validation = useFormik({

    enableReinitialize: true,

    // Inital values of the form fields
    initialValues: {
      _id: (cb && cb._id) || "",
      img: cb.img || "",
      name: (cb && cb.cbName) || "",
      description: (cb && cb.cbDesc) || "",
      tags: (cb && cb.tags) || "",
    },

    // validation rules using Yup
    validationSchema: Yup.object({
      name: Yup.string().required("請輸入智能客服名稱"),
      tags: Yup.array().of(Yup.string()).required("請輸入標籤").min(1, "至少需要一個標籤"),
    }),

    // gets called when the form is submitted
    onSubmit: (values) => {
      console.log('submitting values:', values);
      const updateChatbot = {
        _id: cb._id,
        img: filename,
        cbName: values.name,
        cbDesc: values.description,
        tags: values.tags,
      };
      dispatch(onUpdateChatbot(updateChatbot));
      toggle(); // Close the modal
    },

  });

  /*---- Form Validation Tail ----*/



  /*---- Uploading a new Chatbot Image ----*/

  // cb.img is the image name, cbImage is the image itself
  const [filename, setFilename] = useState((cb.img || '還沒有選擇圖片喔'));
  const [newImageUrl, setNewImageUrl] = useState(cbImage);

  const handleImageChange = async(event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    if (file) {
      const date14string = await dateGen();
      const newfilename = date14string+'_'+file.name;
      setFilename(newfilename);
      const {newImageUrl:thisImage, uploadStatus} = await uploadImg(newfilename, file);
      setNewImageUrl(thisImage);
    }
  };

  /*---- Uploading a new Chatbot Image Tail ----*/



  return (
    <React.Fragment>


      {/* 智能客服編輯視窗 */}
      <Modal isOpen={editModal} toggle={toggle}>

        {/* 專案編輯視窗標題 */}
        <ModalHeader toggle={toggle} tag="h4">
          {`編輯智能客服`}
        </ModalHeader>

        {/* 專案編輯視窗內容 */}
        <ModalBody>

          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col xs={12}>


                {/* 智能客服名稱 */}
                <div className="mb-3">
                  <Label for="chatbotName" className="form-label">智能客服名稱</Label>
                  <Input
                    if="chatbotName"
                    name="chatbotName"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name || ""}
                    invalid={
                      validation.touched.name && validation.errors.name ? true : false
                    }
                  />
                  {/* Display error message */}
                  {validation.touched.name && validation.errors.name ? (
                    <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                  ) : null}
                </div>


                {/* 智能客服頭貼 */}
                <div className="mb-3">
                  <Label for="chatbotImage" className="form-label">智能客服頭貼</Label>
                  <Input
                    id="chatbotImage"
                    name="chatbotImage"
                    type="file"
                    onChange={(event) => {
                      handleImageChange(event, validation.setFieldValue);
                    }}
                  />
                  <div style={{ display:"flex", justifyContent:"center", margin:"1rem 0rem" }}>
                    <img 
                      src={newImageUrl || cbImage || placeholder} 
                      alt="Chatbot" 
                      style={{ 
                          width: '100px', 
                          height: '100px',
                          borderRadius: '50%',
                          objectFit: 'cover'
                      }}
                    />
                  </div>
                </div>


                {/* 智能客服類型 */}
                <div className="mb-3">
                  <Label className="form-label">智能客服類型</Label>

                  <Input
                    name="tags"
                    id="tags"
                    type="select"
                    multiple
                    //className="form-select"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.tags || ""}
                  >
                    <option value="">選擇智能客服類型:</option>
                    <option value="產品說明">產品說明</option>
                    <option value="產品銷售">產品銷售</option>
                    <option value="場館導覽">場館導覽</option>
                    <option value="其他">其他</option>
                  </Input>

                  {validation.touched.tags && validation.errors.tags ? (
                    <FormFeedback type="invalid">{validation.errors.tags}</FormFeedback>
                  ) : null}
                </div>


                
                {/* 智能客服描述 */}
                <div className="mb-3">
                  <Label className="form-label">智能客服描述</Label>
                  <Input
                    name="description"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.description || ""}
                    invalid={
                      validation.touched.description && validation.errors.description ? true : false
                    }
                  />
                  {validation.touched.description && validation.errors.description ? (
                    <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                  ) : null}
                </div>


              </Col>
            </Row>

            {/* 儲存變更按鈕 */}
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="submit"
                    className="btn btn-success save-user"
                  >
                    儲存變更
                  </button>
                </div>
              </Col>
            </Row>

          </Form>


        </ModalBody>

      {/* ^專案編輯視窗 */}
      </Modal>
    </React.Fragment>
  );

};

export default EditModal;
