import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

// Custom Axios
import axiosInstance from 'utils/axiosInstance';

import './OrderModal.css';
// Import Images
import placeholder from "assets/images/placeholders/placeholder_card.png";

const iceOptionsMappings = {
  none: '去冰',
  fortyPercent: '微冰',
  seventyPercent: '少冰',
  hundredPercent: '正常冰',
  warm: '溫',
  hot: '熱',
}

const sugarOptionsMappings = {
  none: '無糖',
  thirtyPercent: '微糖',
  fiftyPercent: '半糖',
  seventyPercent: '少糖',
  hundredPercent: '全糖',
}

const toppingAmountMappings = {
  less: '少',
  normal: '正常',
  more: '多',
}

const OrderModal = ({ pd, pdImage, orderModal, toggle, addNewItem, orderId }) => {

  /*---- Form Validation ----*/

  const validation = useFormik({

    enableReinitialize: true,

    // sets the inital values of the form fields
    initialValues: {
      drinkSize: '中',
      sugarLevel: '全糖',
      temperature: '正常冰',
      toppings: [],
      otherNeeds: [],
    },

    // Validation rules
    validationSchema: Yup.object({
      drinkSize: Yup.string().required("請選擇飲料尺寸"),
      sugarLevel: Yup.string().required("請選擇糖度"),
      temperature: Yup.string().required("請選擇溫度"),
      toppings: Yup.array().of(Yup.string()),
      otherNeeds: Yup.array().of(Yup.string()),
    }),

    onSubmit: (values) => {
      console.log('values', values);
      const newItem = {
        _id: pd._id,
        pdName: pd.pdName,
        drinkSize: values.drinkSize,
        sugarLevel: values.sugarLevel,
        temperature: values.temperature,
        toppings: values.toppings,
        otherNeeds: values.otherNeeds,
      };

      // Here's where you make the Axios call
      axiosInstance.post(`/knovia/addOrderItem/${orderId}`, newItem)
        .then(response => {
          console.log(response.data);
          // Optionally reset form here
          // formik.resetForm();
          toggle(); // Close the modal
        })
        .catch(error => {
          console.error('There was an error!', error);
        });

      addNewItem(newItem);
      //toggle(); // Close the modal
    },

  });

  useEffect(()=>{
    //console.log('validation.values.toppings', validation.values.toppings);
  },[validation.values.toppings])
  useEffect(()=>{
    //console.log('validation.values.otherNeeds', validation.values.otherNeeds);
  },[validation.values.otherNeeds])

  const iceOptionsArray = Object.keys(pd.customizations.iceOptions)
    .filter(i=>pd.customizations.iceOptions[i]===true)
    .map(i=>iceOptionsMappings[i]);
  const sugarOptionsArray = Object.keys(pd.customizations.sugarOptions)
    .filter(s=>pd.customizations.sugarOptions[s]===true)
    .map(s=>sugarOptionsMappings[s]);
  const toppingAmountArray = Object.keys(pd.customizations.toppingAmount)
    .filter(t=>pd.customizations.toppingAmount[t]===true)
    .map(t=>toppingAmountMappings[t]);
  const [toppingTypesArray, setToppingTypesArray] = useState([]);
  useEffect(()=>{
    const toppingTypes = [];
    if(pd.customizations.bubblesAvailable){toppingTypes.push('波霸')};
    if(pd.customizations.pearlsAvailable){toppingTypes.push('珍珠')};
    if(pd.customizations.oatsAvailable){toppingTypes.push('燕麥')};
    if(pd.customizations.coconutJellyAvailable){toppingTypes.push('椰果')};
    setToppingTypesArray(toppingTypes);
    console.log('toppingTypes', toppingTypes);
  },[pd]);
  /*---- Form Validation Tail ----*/


  return (
    <React.Fragment>


      {/* 智能客服編輯視窗 */}
      <Modal 
        isOpen={orderModal} 
        toggle={toggle}
        style={{
          borderRadius: '1.5rem',
          border: '0.7rem solid #d7d6db',
        }}
      >

        <div
          style={{
            position: 'absolute',
            top: '1rem',
            right: '1rem',
            height: '1.8rem',
            width: '1.8rem',
            background: '#bdbdbd',
            borderRadius: '0.5rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            fontSize: '1.4rem',
            paddingTop: '0.1rem',
            cursor: 'pointer',
            zIndex: '100',
          }}
          onClick={()=>{toggle();}}
        >×</div>


        <ModalBody style={{ }}>

          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col xs={12}>

                {/* 飲料圖片 */}
                <div style={{ display:"flex", justifyContent:"center", margin:"1rem 0rem" }}>
                  <img 
                    src={pdImage || placeholder} 
                    alt="Project" 
                    style={{
                      maxHeight: '40vh',
                      borderRadius: '50%',
                      objectFit: 'cover'
                    }}
                  />
                </div>

                <div>
                  <h4 style={{ marginLeft:'0.2rem' }}>{pd.pdName}</h4>
                  <h4 style={{ marginLeft:'0.2rem' }}>{`NT$${pd.customizations.sellPrice}`}</h4>
                </div>

                <div style={{ borderTop: '1px solid #bdbdbd', margin: '1.5rem 0.2rem 1rem 0.2rem' }}></div>

                <div className="mb-4">
                  <Label style={{marginBottom:'0.5rem', fontSize:'1rem'}} className="form-label">
                    飲料尺寸
                    <span style={{ marginLeft:'0.5rem', color: '#9d9a9a' }}>必填</span>
                  </Label>
                  
                  <div>
                    {['中', '大'].map((size) => (
                      <FormGroup check key={size} className="drink-option"
                        onClick={() => validation.setFieldValue('drinkSize', size)}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Label check style={{ display: 'inline-block' }}>
                          <Input
                            name="drinkSize"
                            type="radio"
                            value={size} // The value is dynamically assigned from the array
                            onChange={()=>{}}
                            onBlur={validation.handleBlur}
                            checked={validation.values.drinkSize === size} // Check if this option is selected
                          />{' '}
                          {size}
                        </Label>

                        {size==='大' && (
                          <p 
                            style={{ 
                              display: 'inline-block', 
                              marginBottom: '0rem',
                              marginRight: '1rem',
                            }}
                          >{`+NT$${pd.customizations.extraFeeForLarge}`}</p>
                        )}

                      </FormGroup>
                    ))}
                  </div>
                  {/* Display error message for drinkSize */}
                  {validation.touched.drinkSize && validation.errors.drinkSize && (
                    <FormFeedback type="invalid">{validation.errors.drinkSize}</FormFeedback>
                  )}
                </div>

                <div className="mb-4">
                  <Label style={{marginBottom:'0.5rem', fontSize:'1rem'}} className="form-label">
                    糖度
                    <span style={{ marginLeft:'0.5rem', color: '#9d9a9a' }}>必填</span>
                  </Label>
                  <div>
                    {sugarOptionsArray.map((level) => (
                      <FormGroup check key={level} className="drink-option"
                        onClick={() => validation.setFieldValue('sugarLevel', level)}
                      >
                        <Label check>
                          <Input
                            name="sugarLevel"
                            type="radio"
                            value={level}
                            onChange={()=>{}}
                            onBlur={validation.handleBlur}
                            checked={validation.values.sugarLevel === level}
                          />{' '}
                          {level}
                        </Label>
                      </FormGroup>
                    ))}
                  </div>
                  {validation.touched.sugarLevel && validation.errors.sugarLevel && (
                    <FormFeedback type="invalid">{validation.errors.sugarLevel}</FormFeedback>
                  )}
                </div>

                <div className="mb-4">
                  <Label style={{marginBottom:'0.5rem', fontSize:'1rem'}} className="form-label">
                    溫度
                    <span style={{ marginLeft:'0.5rem', color: '#9d9a9a' }}>必填</span>
                  </Label>
                  <div>
                    {iceOptionsArray.map((temp) => (
                      <FormGroup check key={temp} className="drink-option"
                        onClick={() => validation.setFieldValue('temperature', temp)}
                      >
                        <Label check>
                          <Input
                            name="temperature"
                            type="radio"
                            value={temp}
                            onChange={()=>{}}
                            onBlur={validation.handleBlur}
                            checked={validation.values.temperature === temp}
                          />{' '}
                          {temp}
                        </Label>
                      </FormGroup>
                    ))}
                  </div>
                  {validation.touched.temperature && validation.errors.temperature && (
                    <FormFeedback type="invalid">{validation.errors.temperature}</FormFeedback>
                  )}
                </div>

                <div className="mb-4">
                  <Label style={{ marginBottom: '0.5rem', fontSize: '1rem' }} className="form-label">
                    配料區
                  </Label>
                  <div>
                    {toppingTypesArray.map((topping, index) => (
                      <FormGroup check key={index} className="drink-option"
                        onClick={(e) => {
                          if (e.target.type === 'checkbox') { return; }
                          e.preventDefault(); 
                          const currentToppings = validation.values.toppings;
                          const isToppingSelected = currentToppings.includes(topping);
                          const newToppings = isToppingSelected
                            ? currentToppings.filter((t) => t !== topping)
                            : [...currentToppings, topping];

                          validation.setFieldValue('toppings', newToppings);
                        }}
                      >
                        <Label check>
                          <Input
                            name="toppings"
                            type="checkbox"
                            value={topping}
                            checked={validation.values.toppings.includes(topping)}
                            onClick={(e) => {
                              const currentToppings = validation.values.toppings;
                              const isToppingSelected = currentToppings.includes(topping);
                              const newToppings = isToppingSelected
                                ? currentToppings.filter((t) => t !== topping)
                                : [...currentToppings, topping];

                              validation.setFieldValue('toppings', newToppings);
                            }}
                            onChange={() => {}} // Keep this for form handling, but we manage the state in Label's onClick
                            style={{ marginRight: '5px' }}
                          />
                          {topping}
                        </Label>
                      </FormGroup>
                    ))}
                  </div>
                </div>

                {/* 其他需求 */}
                <div className="mb-4">
                  <Label style={{ marginBottom: '0.5rem', fontSize: '1rem' }} className="form-label">
                    其他需求
                  </Label>
                  <div>
                    {['自備環保杯'].map((otherNeed, index) => (
                      <FormGroup check key={index} className="drink-option"
                        onClick={(e) => {
                          if (e.target.type === 'checkbox') { return; }
                          e.preventDefault(); 
                          const currentNeeds = validation.values.otherNeeds;
                          const isNeedSelected = currentNeeds.includes(otherNeed);
                          const newNeeds = isNeedSelected
                            ? currentNeeds.filter((t) => t !== otherNeed)
                            : [...currentNeeds, otherNeed];

                          validation.setFieldValue('otherNeeds', newNeeds);
                        }}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Label check>
                          <Input
                            name="otherNeeds"
                            type="checkbox"
                            value={otherNeed}
                            checked={validation.values.otherNeeds.includes(otherNeed)}
                            onClick={(e) => {
                              const currentNeeds = validation.values.otherNeeds;
                              const isNeedSelected = currentNeeds.includes(otherNeed);
                              const newNeeds = isNeedSelected
                                ? currentNeeds.filter((t) => t !== otherNeed)
                                : [...currentNeeds, otherNeed];

                              validation.setFieldValue('otherNeeds', newNeeds);
                            }}
                            onChange={() => {}} // Keep this for form handling, but we manage the state in Label's onClick
                            style={{ marginRight: '5px' }}
                          />
                          {otherNeed}
                        </Label>

                        {otherNeed==='自備環保杯' && (
                          <p 
                            style={{ 
                              display: 'inline-block', 
                              marginBottom: '0rem',
                              marginRight: '1rem',
                            }}
                          >{`-NT$${pd.customizations.reductionForOwnCup}`}</p>
                        )}

                      </FormGroup>
                    ))}
                  </div>
                </div>


              </Col>
            </Row>

            {/* 儲存變更按鈕 */}
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="submit"
                    className="btn btn-success save-user"
                  >
                    儲存變更
                  </button>
                </div>
              </Col>
            </Row>

          </Form>


        </ModalBody>

      {/* ^專案編輯視窗 */}
      </Modal>
    </React.Fragment>
  );

};

export default OrderModal;
