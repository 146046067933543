import { call, put, takeLatest, all } from "redux-saga/effects";
import { fetchImage } from 'utils/imageUtils';

import { 
  LOAD_PRODUCT_IMAGES,
  GET_PRODUCTSET,
  ADD_NEW_PRODUCT, 
  DELETE_PRODUCT, 
  UPDATE_PRODUCT,
} from "./actionTypes";

import {
  loadProductImagesSuccess,
  loadProductImagesFail,

  getProductSetSuccess,
  getProductSetFail,

  updateProductSuccess,
  updateProductFail,

  deleteProductSuccess,
  deleteProductFail,

  addProductSuccess,
  addProductFail,
} from "./actions"

import { fetchUserData } from "../user/actions.js";

import { 
  getProductSet,
  addNewProduct,
  updateProduct,
  deleteProduct
} from "api/product";

function* fetchProducts() {
  try {
    const response = yield call(getProductSet);
    yield put(getProductSetSuccess(response));
  } catch (error) {
    yield put(getProductSetFail(error));
  }
}

function* fetchProductImages({ payload: products }) {
  console.log('[store/products/saga.js] Reloading all Product Images...');
  try {
    const imageUrls = yield all(products.map(pd => 
      call(fetchImage, pd.img || 'default_placeholder')
    ));
    const images = products.reduce((acc, pd, index) => {
      acc[pd._id] = imageUrls[index];
      return acc;
    }, {});
    yield put(loadProductImagesSuccess(images));
  } catch (error) {
    yield put(loadProductImagesFail(error.message));
  }
}

function* onUpdateProduct({ payload: product }) {
  try {
    const response = yield call(updateProduct, product);
    console.log("[store/products/saga.js] Updating product...", response);
    yield put(updateProductSuccess(response));
    yield put(fetchUserData());
  } catch (error) {
    console.log('error:', error);
    yield put(updateProductFail(error));
  }
}

function* onDeleteProduct({ payload: productId }) {
  try {
    const response = yield call(deleteProduct, productId);
    yield put(deleteProductSuccess(productId));
    yield(fetchUserData());
  } catch (error) {
    yield put(deleteProductFail(error));
  }
}

function* onAddNewProduct({ payload: product }) {
  try {
    const response = yield call(addNewProduct, product);
    yield put(addProductSuccess(response));
    yield put(fetchUserData());
  } catch (error) {
    yield put(addProductFail(error));
  }
}

function* productsSaga() {
  yield takeLatest(LOAD_PRODUCT_IMAGES, fetchProductImages);
  yield takeLatest(GET_PRODUCTSET, fetchProducts);
  yield takeLatest(ADD_NEW_PRODUCT, onAddNewProduct);
  yield takeLatest(UPDATE_PRODUCT, onUpdateProduct);
  yield takeLatest(DELETE_PRODUCT, onDeleteProduct);
}

export default productsSaga;



