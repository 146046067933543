
/* SET CHATBOT */
export const SET_CHATBOTS = 'SET_CHATBOTS';
export const SET_CHATBOT_IMAGE = 'SET_CHATBOT_IMAGE';

export const LOAD_CHATBOT_IMAGES = 'LOAD_CHATBOT_IMAGES';
export const LOAD_CHATBOT_IMAGES_SUCCESS = 'LOAD_CHATBOT_IMAGES_SUCCESS';
export const LOAD_CHATBOT_IMAGES_FAIL = 'LOAD_CHATBOT_IMAGES_FAIL';

/* GET CHATBOT */
export const GET_CHATBOTS = "GET_CHATBOTS";
export const GET_CHATBOTS_SUCCESS = "GET_CHATBOTS_SUCCESS";
export const GET_CHATBOTS_FAIL = "GET_CHATBOTS_FAIL";

/* ADD CHATBOT */
 export const ADD_NEW_CHATBOT = "ADD_NEW_CHATBOT";
 export const ADD_CHATBOT_SUCCESS = "ADD_CHATBOT_SUCCESS";
 export const ADD_CHATBOT_FAIL = "ADD_CHATBOT_FAIL";

/* DELETE CHATBOT */
export const DELETE_CHATBOT = "DELETE_CHATBOT";
export const DELETE_CHATBOT_SUCCESS = "DELETE_CHATBOT_SUCCESS";
export const DELETE_CHATBOT_FAIL = "DELETE_CHATBOT_FAIL";
 
/* UPDATE CHATBOT */
export const UPDATE_CHATBOT = "UPDATE_CHATBOT";
export const UPDATE_CHATBOT_SUCCESS = "UPDATE_CHATBOT_SUCCESS";
export const UPDATE_CHATBOT_FAIL = "UPDATE_CHATBOT_FAIL";
 
