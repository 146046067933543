import {
  SET_DATABASES,
  SET_DATABASE_IMAGE,

  LOAD_DATABASE_IMAGES,
  LOAD_DATABASE_IMAGES_SUCCESS,
  LOAD_DATABASE_IMAGES_FAIL,

  LOAD_BASEDB_IMAGES,
  LOAD_BASEDB_IMAGES_SUCCESS,
  LOAD_BASEDB_IMAGES_FAIL,

  GET_DATABASES_SUCCESS,
  GET_DATABASES_FAIL,

  GET_BASE_DATABASES_SUCCESS,
  GET_BASE_DATABASES_FAIL,
  SET_BASE_DATABASE_IMAGE,

  ADD_NEW_DATABASE,
  ADD_DATABASE_SUCCESS,
  ADD_DATABASE_FAIL,

  UPDATE_DATABASE_SUCCESS,
  UPDATE_DATABASE_FAIL,

  DELETE_DATABASE_SUCCESS,
  DELETE_DATABASE_FAIL,
} from "./actionTypes";

const INIT_STATE = {

  databases: [],
  baseDatabases: [],
  databaseImages: {},
  baseDbImages: {},
  
  loading: false,
  error: {},

};

const databases = (state = INIT_STATE, action) => {

  switch (action.type) {

    /*--- SET DATABASE ---*/

    case SET_DATABASES:
      return {
        ...state,
        databases: action.payload,
      };

    case SET_DATABASE_IMAGE:
      return {
        ...state,
        databaseImages: {
          ...state.databaseImages, 
          [action.payload.dbId]: action.payload.imgUrl,
        }
      };

    /*--- SET DATABASE TAIL ---*/



    /*--- LOAD DB IMAGES ---*/

    case LOAD_DATABASE_IMAGES:
      return { ...state, loading: true };
    case LOAD_DATABASE_IMAGES_SUCCESS:
      return { ...state, loading: false, databaseImages: action.payload };
    case LOAD_DATABASE_IMAGES_FAIL:
      return { ...state, loading: false, error: action.payload };


    case LOAD_BASEDB_IMAGES:
      return { ...state, loading: true };
    case LOAD_BASEDB_IMAGES_SUCCESS:
      return { ...state, loading: false, baseDbImages: action.payload };
    case LOAD_BASEDB_IMAGES_FAIL:
      return { ...state, loading: false, error: action.payload };

    /*--- LOAD DB IMAGES TAIL ---*/



    /*--- GET DATABASE ---*/

    case GET_DATABASES_SUCCESS:
      return {
        ...state,
        databases: action.payload,
      };

    case GET_DATABASES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_BASE_DATABASES_SUCCESS:
      return {
        ...state,
        baseDatabases: action.payload,
      };

    case GET_BASE_DATABASES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case SET_BASE_DATABASE_IMAGE:
      return {
        ...state,
        baseDbImages: {
          ...state.baseDbImages, 
          [action.payload.baseDbId]: action.payload.imgUrl,
        }
      };

    /*--- GET DATABASE TAIL ---*/

    
    /*--- ADD DATABASE ---*/

    case ADD_NEW_DATABASE:
      return {
        ...state,
        loading: true
      };

    case ADD_DATABASE_SUCCESS:
      return {
        ...state,
        databases: [...state.databases, action.payload],
        loading: false
      };

    case ADD_DATABASE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    /*--- ADD DATABASE TAIL ---*/



    /*--- DELETE DATABASE ---*/

    case DELETE_DATABASE_SUCCESS:
      return {
        ...state,
        databases: state.databases.filter(
          database => database._id.toString() !== action.payload.toString()
        ),
      };

    case DELETE_DATABASE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    /*--- DELETE DATABASE TAIL ---*/



    /*--- UPDATE DATABASE ---*/

    // Notice that the mapping won't show error when logic isn't right!
    case UPDATE_DATABASE_SUCCESS:
      console.log("update database success processing...")
      return {
        ...state,
        databases: state.databases.map(database =>
          database._id.toString() === action.payload._id.toString()
            ? { ...database, ...action.payload }
            : database
        ),
      };

    case UPDATE_DATABASE_FAIL:
      console.log("[store/database/reducer.js] Update database failed!");
      return {
        ...state,
        error: action.payload,
      };

    /*--- UPDATE DATABASE TAIL ---*/


  
    default:
      return state;
  }

};

export default databases;


