import React, { useState, useEffect } from "react";
import {
	Row,
	Col,
	Button,
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
} from "reactstrap";

const orderedKeys = ["masterquestion", "masterknowledge"];
const selectOptionsMapping = {};


// Main LatestTransaction Component
const PromptModal = props => {

const { isPromptModalOpen, setIsPromptModalOpen, customPrompt, setCustomPrompt } = props;

  /*---- Initial States ----*/

  const promptOriginal = 
  	`如果使用者的提問使用{1.繁體中文}，請務必使用{2.繁體中文}回答，如果是其他語言，使用{3.該語言}回答。`
  	+`\n注意，你的身份是{4.客服人員}，你務必盡量參考「{5.客服手冊}」的內容來回答，如果是{5.客服手冊}裡沒有提到的內容，`
  	+`不要給出一般性、可能不符合「{4.客服人員}」身份的建議。`
  	+`回答語氣讓人感到「{6.和緩}」，但不超出{5.客服手冊}裡你看到的內容。`
  	+`\n\n你不能建議使用者直接參考「{5.客服手冊}」，因為使用者無法接觸到「{5.客服手冊}」，`
  	+`這是只提供給{4.客服人員}的參考文本，也不要建議他們聯絡{7.真人客服}。`
  	+`\n你的答案不可以提到「{5.客服手冊}」，也不可以提到「聯絡{7.真人客服}」或「聯繫{8.平台客服}」。`
  	+`\n\n以下是「{5.客服手冊}」的內容，以一個Q、一個A為一組。格式如<<圖...>>、<<表...>>的內容是參考圖表，`
  	+`\n凡是你參考到的QA組中出現的圖與表，都要盡量包含在你的答案中，或者放在答案最後面，並一樣用<<圖...>>形式表達即可，`
  	+`只要你有在答案中提到，該圖表都會自動顯示給使用者查看：`;


  const { thisCb, chatbotId, allData } = props;
	const [modalData, setModalData] = useState({ promptText: "", masterknowledge: "" });
	

	/*---- Prompt Inserts ----*/

	const [promptText, setPromptText] = useState('');
	const [promptInsert, setPromptInsert] = useState(
		['繁體中文', '繁體中文', '該語言', '客服人員', '客服手冊', '和緩', '真人客服', '平台客服']
	);


	useEffect(() => {

		if(customPrompt){
	    const extractTexts = (inputString) => {
	      const regex = /\{(\d+)\.(.*?)\}/g;
	      const prompts = [];
	      let match;

	      while ((match = regex.exec(inputString)) !== null) {
	        const index = parseInt(match[1], 10) - 1;
	        const text = match[2];
	        prompts[index] = text; // Assign text to the specific index
	      }

	      return prompts;
	    };

	    //console.log('customPrompt HERE:', customPrompt);
	    const extractedTexts = extractTexts(customPrompt);
	    //console.log('extractedTexts:', extractedTexts);
	    setPromptInsert(extractedTexts);
    }

  }, [customPrompt]);


	useEffect(() => {
	  // Create a new version of the prompt that we'll modify
	  let updatedPrompt = promptOriginal;

	  // Iterate over each item in promptInsert
	  promptInsert.forEach((insert, index) => {
	    // Create a regex to find the placeholder text, accounting for the 1-based indexing in your placeholders
	    const regex = new RegExp(`\\{${index + 1}\\..+?\\}`, 'g');
	    // Replace the placeholder in the prompt with the actual value from promptInsert
	    updatedPrompt = updatedPrompt.replace(regex, `{${index+1}.${insert}}`);
	  });

	  // Update the state with the modified prompt
	  setPromptText(updatedPrompt);
	}, [promptInsert]);


  const setPlainCustomPrompt = () => {

  	let updatedPrompt = promptText;
  	/*
  	promptInsert.forEach((insert, index) => {
	    // Create a regex to find the placeholder text, accounting for the 1-based indexing in your placeholders
	    const regex = new RegExp(`\\{${index + 1}\\..+?\\}`, 'g');
	    // Replace the placeholder in the prompt with the actual value from promptInsert
	    updatedPrompt = updatedPrompt.replace(regex, insert);
	  });
	  */
	  console.log('Setting CustomPrompt to:', updatedPrompt);
		setCustomPrompt(updatedPrompt);

  }

  const autoResize = (e) => {
    e.target.style.height = 'auto'; // Reset the height
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the height to scroll height
    if(e.target.value.length<25){
    	e.target.style.height='30px';
    }
  };
  


	// Return Component
  return (
    <React.Fragment>

	    {/*---- Modal for editing dataentries ----*/}
	    <Modal 
	    	isOpen={isPromptModalOpen} 
	    	toggle={() => setIsPromptModalOpen(!isPromptModalOpen)}
	    	style={{
	    		minWidth: '65vw',
	    	}}
	    >
	      {/* Modal Header */}
	      <ModalHeader toggle={() => setIsPromptModalOpen(!isPromptModalOpen)}>設定智能客服 Prompt</ModalHeader>
	      {/* Modal Body */}
	      <ModalBody>

	      	<Row>

	      		<Col sm="6">
		          <p>Prompt 內容:</p>
		          
		          <textarea
		            value={promptText}
		            onChange={(e) => {
		              setModalData((prevData) => ({ ...prevData, 'promptText': e.target.value}));
		            }}
		            style={{ 
		            	width: '100%',
							    resize: 'none',
							    overflow: 'scroll',
							    minHeight: '20rem',
		            }}
		            className="form-control"
		            disabled
		          />

		        </Col>


		        <Col sm="6">

		        	{promptInsert.map((promptText, index) => (
							  <Row key={index} style={{ marginBottom: '0.5rem' }}>
							    <Col sm="2"
							      style={{
							        display: 'flex',
							        flexWrap: 'wrap',
							        justifyContent: 'center',
							        alignContent: 'center',
							      }}
							    >
							      <div>
							        <p style={{ marginBottom:'0rem' }}>{index + 1}</p>
							      </div>
							    </Col>
							    <Col sm="10">
							      <textarea
							        value={promptText || ''} // Use the element of the array or an empty string if undefined
							        onChange={(e) => {
				                const updatedArray = promptInsert.map((item, itemIndex) => itemIndex === index ? e.target.value : item);
				                setPromptInsert(updatedArray);
				              }}
				              onInput={autoResize}
							        style={{ width: "100%", resize: 'none', overflow: 'hidden', height:'30px' }}
							        className="form-control"
							      />
							    </Col>
							  </Row>
							))}

		        </Col>
		        

          </Row>

	      </ModalBody>

	      {/* Modal Footer */}
	      <ModalFooter>
	          <Button 
              color="primary" 
              onClick={() => {
				const yes = confirm('確定要修改嗎？');
				if (yes) {
                    setPlainCustomPrompt(promptText);
                    setIsPromptModalOpen(false);
				}
              }}
	          >
	            儲存變更
	          </Button>
	          <Button color="secondary" onClick={() => setIsPromptModalOpen(false)}>取消變更</Button>
	      </ModalFooter>
	    </Modal>

    {/*---- Modal for editing dataentries Tail ----*/}
    </React.Fragment>
  );
};

export default PromptModal;

