import React, { useState } from 'react';
import { 
  Col,
  Button,
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowRight, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';

const FoldableSection = ({ 
  title, 
  children, 
  style, 
  defaultOpen=false, 
  canLock=false,
  isLocked=false,
  toggleLocked={} 
}) => {

  const [isOpen, setIsOpen] = useState(defaultOpen); // State to track if the section is open

  const toggleOpen = () => setIsOpen(!isOpen); // Toggle function

  return (
    <div style={style}>
      <div 
        style={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between',
          marginBottom: '0.5rem',
        }}
      >
        {canLock?(
          <Col sm="9" style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
            <button 
              className="btn btn-outline-info" 
              style={{ 
                border: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => toggleLocked()}
            >
              { 
                isLocked ? 
                <FontAwesomeIcon icon={faLock} /> : 
                <FontAwesomeIcon icon={faUnlock} />
              }
            </button>
            <h5 style={{ fontWeight: '1000', marginBottom: '0rem' }}>參考資料：</h5>
          </Col>
        ):(
          <h5 style={{ fontWeight: 1000, marginBottom: 0 }}>{title}</h5>
        )}
        <Button 
          onClick={toggleOpen} 
          style={{ 
            height: '1rem',
            width: '1rem',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
          
          color="outline-primary"
        >
          <FontAwesomeIcon icon={isOpen ? faArrowDown : faArrowRight} />
        </Button>
      </div>
      {isOpen && (
        <div style={{ marginBottom: 0 }}>
          {children}
        </div>
      )}
    </div>
  );
};

export default FoldableSection;