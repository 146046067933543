import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import PropTypes from "prop-types";

// Pages Components
import ActivityComp from "./ActivityComp";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";


// Main Dashboard-Master Component
const Dashboard = props => {

  //meta title
  document.title = "AI 智能客服 | TheChart.AI";

  return (
    <React.Fragment>

      <div className="page-content">
        <Container fluid>

          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("功能")}
            breadcrumbItem={props.t("TheChart.AI 平台智能客服")}
          />

          {/* ActivityComp */}
          <Row>
            <Col xl="12">
              <ActivityComp isAdmin={true}/>
            </Col>
          </Row>

        </Container>
      </div>

    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Dashboard);
