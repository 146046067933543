import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from "react-redux";
import {
    Col,
    Row,
    Container,
    ListGroupItem,
    ListGroupItemHeading,
    ListGroupItemText,
    Card,
    CardHeader,
    CardFooter,
    Button,
    InputGroup,
    Input,
  } from "reactstrap";
import axiosInstance from 'utils/axiosInstance';
import { get, last } from "lodash";
// uuid
import { v4 as uuidv4 } from 'uuid';

// Image
import placeholder from "assets/images/placeholders/placeholder_card.png"

const ChatRoom = () => {
    const [profilePic, SetProfilePic] = useState(placeholder);
    /*---- User Data ----*/
    const { userData } = useSelector(state => ({
        userData: state.user.userData,
    }));
    
    const chatListRef = useRef(null);
    const [chatHistory, setChatHistory] = useState([]);
    const pushChatHistoryItemText = (id, newText) => {
        setChatHistory(prevItems =>
            prevItems.map(item =>
              item.id === id ? { ...item, text: `${item.text}${newText}` } : item
            )
        );
    };
    useEffect(() => {
        const current = chatListRef.current;
        if (current) current.scrollTop = current.scrollHeight;
    }, [chatHistory]);
    const [message, setMessage] = useState('');
    const sendMsg = async (e) => {
        const newMessageId = uuidv4();
        // console.log(newMessageId, message);
        const newItem = {
            id: newMessageId,
            text: message,
            sender: userData.username,
            time: new Date().toLocaleTimeString()
        };
        setChatHistory(prevItems => [...prevItems, newItem]);
        try {
            const data = { content: message };
            let processingMessageId;
            const response = await axiosInstance.post(`/knovia/phi3/translate/en-to-zh`, data,
                {
                  headers: { 'Content-Type': 'application/json' },
                  onDownloadProgress: (evt) => {
                    const currentResponse = evt.event.target.responseText.replace(/^data: /g, '').replace(/\n\n$/g, '');
                    const datas = currentResponse.split('\n\ndata: ');
                    // console.log('onDownloadProgress', datas);
                    const firstItem = JSON.parse(datas[0]);
                    const {role, content} = get(firstItem.choices[0], 'delta');
                    // console.log('=============', role, content);
                    // console.log(`processingMessageId`, processingMessageId);
                    // console.log(`findIndex ${firstItem.id}`, chatHistory, findIndex(chatHistory, {id: firstItem.id}));
                    const newItem = {
                        id: firstItem.id,
                        text: content,
                        sender: role,
                        time: new Date().toLocaleTimeString()
                    };
                    if (processingMessageId != firstItem.id) {
                        setChatHistory(prevItems => [...prevItems, newItem]);
                        processingMessageId = firstItem.id;
                    } else {
                        //
                        const lastItem = JSON.parse(last(datas));
                        const text = get(lastItem.choices[0], 'delta.content');
                        pushChatHistoryItemText(processingMessageId, text);
                        // console.log('lastItem', lastItem);
                    }

                    // datas.forEach((itemStr, index) => {
                    //     const item = JSON.parse(itemStr);
                    //     console.log(item, index, datas.length -1);
                    //     if (datas.length -1 === index) {
                    //         // console.log('push');
                    //     }
                    // });
                    
                    
                  },
                  responseType: 'stream'
                }
            ).then((response) => {
                setMessage('');
                return response;
            });
            return response.data;
        } catch (error) {
            console.error("[src/api/api.js] Error in userLogin", error);
            return Promise.reject(error);
        }
    }

    return (
        <>
            <Container className="page-content">
                <Row>
                    <Col>
                        <Card
                            className="my-2"
                            color="dark"
                            inverse
                        >
                            <CardHeader>
                                Chat
                            </CardHeader>
                            <ul
                                className="list-group list-group-flush"
                                ref={chatListRef}
                                style={{
                                    height: '500px',
                                    overflowY: 'scroll'
                                }}
                            >
                                {chatHistory.map((item, inedx) => {
                                    const selfSent = userData.username === item.sender;
                                    return selfSent ? (<ListGroupItem key={inedx}>
                                        <span className="ms-4 float-end">
                                            <img
                                            className="rounded-circle header-profile-user"
                                            src={profilePic || placeholder}
                                            style={{ objectFit: "cover", width: 50, height: 50 }}
                                        />
                                        </span>
                                        <ListGroupItemHeading className='text-end'>
                                            {item.sender}
                                        </ListGroupItemHeading>
                                        <ListGroupItemText className='text-end'>
                                            {item.text}
                                        </ListGroupItemText>
                                    </ListGroupItem>) : (<ListGroupItem key={inedx}>
                                        <span className="me-4 float-start">
                                            <img src="http://placehold.it/50/55C1E7/fff&text=AI" alt="User Avatar" className="img-circle" />
                                        </span>
                                        <ListGroupItemHeading>
                                            AI
                                        </ListGroupItemHeading>
                                        <ListGroupItemText>
                                            {item.text}
                                        </ListGroupItemText>
                                    </ListGroupItem>);
                                })}
                            </ul>
                            <CardFooter>
                                <InputGroup>
                                    <Input placeholder="Type your message here..." value={message} onChange={(event) => setMessage(event.target.value)} />
                                    <Button onClick={sendMsg} >
                                        Send
                                    </Button>
                                </InputGroup>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default ChatRoom;