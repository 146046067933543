// src/store/selectors.js
import { createSelector } from 'reselect';

const loginState = (state) => state.Login;
const googleLoginState = (state) => state.googleLogin;

export const getLoginError = createSelector(
  [loginState],
  (login) => login.error
);

export const getLoginLoading = createSelector(
  [loginState],
  (login) => login.loading
);

export const getIsLoggedIn = createSelector(
  [loginState],
  (login) => login.isLoggedIn
);

export const getGoogleError = createSelector(
  [googleLoginState],
  (googleLogin) => googleLogin.error
);

export const getGoogleLoading = createSelector(
  [googleLoginState],
  (googleLogin) => googleLogin.loading
);

export const getGoogleLoggedIn = createSelector(
  [googleLoginState],
  (googleLogin) => googleLogin.isGoogleLoggedIn
);

export const isLoginStateClear = (state) => {
  return !state.Login.loading && !state.Login.isLoggedIn && !state.Login.error;
};

// Selector to check if the Google login state is clear
export const isGoogleLoginStateClear = (state) => {
  return !state.googleLogin.loading && !state.googleLogin.isGoogleLoggedIn && !state.googleLogin.error;
};