import {
  CLEAR_CHANGE_PASSWORD,
  CLEAR_CHANGE_PASSWORD_SUCCESS,
  CLEAR_CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
} from "./actionTypes"

export const clearChangePassword = () => {
  return {
    type: CLEAR_CHANGE_PASSWORD,
  }
}

export const clearChangePasswordSuccess = message => {
  return {
    type: CLEAR_CHANGE_PASSWORD_SUCCESS,
  }
}

export const clearChangePasswordFailed = error => {
  return {
    type: CLEAR_CHANGE_PASSWORD_FAILED,
    payload: error,
  }
}

export const userChangePassword = (values, token) => {
  return {
    type: CHANGE_PASSWORD,
    payload: {values, token},
  }
}

export const userChangePasswordSuccess = message => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const userChangePasswordError = message => {
  return {
    type: CHANGE_PASSWORD_ERROR,
    payload: message,
  }
}
