import React, { useEffect, useRef, useState, useMemo } from "react";
import withRouter from "components/Common/withRouter";

import {
  Col,
  Row,
  Button,
  Card,
  CardBody,
} from "reactstrap";
import './Chatbot.css';

import axiosInstance from 'utils/axiosInstance';

export const API_ENDPOINT = (
  process.env.NODE_ENV === 'production'
    ? 'https://backend.thechart.ai'
    : 'http://localhost:3013'
)

import chatIcon from '../../assets/images/chatbot.png';

/*---- Example Message ----*/
/*
const messages = [
  { id: 1, text: "Hello, how can I help you?", sender: "received", time: "18:45"  },
  { id: 2, text: "I have a question about my order.", sender: "sent", time: "18:45"  },
  { id: 3, text: "Sure, what is your order number?", sender: "received", time: "18:46"  },
  { id: 4, text: "It's #1234.", sender: "sent", time: "18:46"  }
];
*/

const Chatbot = props => {

  const [isOpen, setIsOpen] = useState(false);
  const [conversationId, setConversationId] = useState(null);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);

  const [inputHeight, setInputHeight] = useState('');

  const messagesEndRef = useRef(null);
  const inputTextareaRef = useRef(null);

  let jsonPart='', textPart='';

  

  /*---- Text Window Related Settings ----*/

  // Use useEffect to scroll to the bottom every time messages change
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, [messages]); // Only runs when messages change

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const adjustTextareaHeight = () => {
    if (inputTextareaRef.current) {
      inputTextareaRef.current.style.height = "auto"; 
      if(inputTextareaRef.current.scrollHeight>36){
        inputTextareaRef.current.style.height = `${inputTextareaRef.current.scrollHeight}px`;
      } else {
        inputTextareaRef.current.style.height = '40px';
      }
    }
  };


  const handleChange = (e) => {
    setMessage(e.target.value);
    adjustTextareaHeight();
  };

  const toggleChatWindow = () => { setIsOpen(!isOpen) };
  const closeChatWindow = () => { setIsOpen(false) };

  /*---- Text Window Related Settings ----*/




  const sendMessage = async () => {

    // Reset the input field after sending the message
    setMessage('');
    inputTextareaRef.current.style.height = '40px';

    if (message.trim()) {

      /*---- Optimistic Update of user message ----*/

      const tempMessageId = Date.now(); // Temporary ID for optimistic UI update
      const tempBotMessageId = 'tempBot';
      let conversation, processedMessages;

      let parsedData={};
      let jsonPart, textPart;

      const optimisticUserMessage = {
        id: tempMessageId,
        text: message,
        sender: 'sent',
        time: new Date().toLocaleTimeString(),
      };

      /*
      // Placeholder loading message
      const loadingMessageId = `loading-${tempMessageId}`;
      const loadingPlaceholder = {
        id: loadingMessageId,
        text: '...', // or you could leave it empty and handle the UI purely with CSS
        sender: 'loading',
        time: new Date().toLocaleTimeString(),
      };
      */

      // Add the optimistic user message
      setMessages(prevMessages => [...prevMessages, optimisticUserMessage/*, loadingPlaceholder*/]);

      /*---- Optimistic Update of user message ----*/



      /*---- Post and Receive streaming Response ----*/

      const data = { sender: 'User', message, conversationId };

      try {

        const response = await axiosInstance.post(`/knovia/conversations`, data,
          {
            headers: { 'Content-Type': 'application/json' },
            onDownloadProgress: (evt) => {

              const currentResponse = evt.event.target.response;

              // Process stream data here
              let splitIndex = currentResponse.indexOf('\n\n\n\n');

              if (splitIndex !== -1) {
                textPart = currentResponse.substring(0, splitIndex);
                jsonPart = currentResponse.substring(splitIndex + 4);

                console.log('Text Part:', textPart);
                console.log('Json Part:', jsonPart);
                //setNewResponseSimple(textPart);

                /*
                try {
                  const { responseBytelingo, responseSimple } = JSON.parse(jsonPart);

                  fetchedData['responseBytelingo'] = responseBytelingo;
                  fetchedData['responseSimple'] = responseSimple;

                } catch (e) {
                  console.log('Error parsing JSON:', e);
                }
                */

                // Get Json Part
                if(Object.keys(parsedData).length===0){
                  // Try to parse the accumulated data
                  try {
                    parsedData = JSON.parse(jsonPart);
                    
                    // Assuming parsedData contains the necessary information
                    ({ conversation, processedMessages } = parsedData);

                    // Check and update conversation ID if needed
                    if (!conversationId && conversation._id) {
                      setConversationId(conversation._id);
                    }        

                  } catch (e) {
                    console.log('error', e);
                  }
                }

              } else {
                console.log('Streaming:', currentResponse);
                // Update the messages in the UI
                setMessages(prevMessages => {
                  // Filter out the previous incomlete bot message
                  let updatedMessages = prevMessages.filter(message => message.id !== tempBotMessageId);
                  let newStreamMessage = {};

                  // Add the new bot message
                  if (currentResponse) {
                    newStreamMessage = {
                      id: tempBotMessageId,
                      text: currentResponse,
                      sender: 'received',
                      time: new Date().toLocaleTimeString(), // Adjust the time as per your need
                    }
                  }

                  return [...updatedMessages, newStreamMessage];
                });
                //setNewResponseSimple(currentResponse);
              }


            }
          }
        );

        //return data;

      } catch (error) {
        console.error('Error fetching stream:', error);
      }


      /*
      await fetch(`${API_ENDPOINT}/knovia/conversations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then(response => {

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let streamData = '';
        let parsedData={};

        function processStream(result) {
          if (result.done) return;

          streamData += decoder.decode(result.value, { stream: true });
          let splitIndex = streamData.indexOf('\n\n\n\n');

          // Transmitting Json
          if(splitIndex!==-1){

            textPart = streamData.substring(0, splitIndex);
            jsonPart = streamData.substring(splitIndex + 2);
          
            // Get Json Part
            if(Object.keys(parsedData).length===0){
              // Try to parse the accumulated data
              try {
                parsedData = JSON.parse(jsonPart);
                
                // Assuming parsedData contains the necessary information
                ({ conversation, processedMessages } = parsedData);

                // Check and update conversation ID if needed
                if (!conversationId && conversation._id) {
                  setConversationId(conversation._id);
                }        

              } catch (e) {
                console.log('error', e);
              }
            }

          // Streaming in progress: transmitting texts
          } else {

            // Get Text Part
            //console.log('textPart:', streamData);
            // Update the messages in the UI
            setMessages(prevMessages => {
              // Filter out the previous incomlete bot message
              let updatedMessages = prevMessages.filter(message => message.id !== tempBotMessageId);
              let newStreamMessage = {};

              // Add the new bot message
              if (streamData) {
                newStreamMessage = {
                  id: tempBotMessageId,
                  text: streamData,
                  sender: 'received',
                  time: new Date().toLocaleTimeString(), // Adjust the time as per your need
                }
              }

              return [...updatedMessages, newStreamMessage];
            });

          }

          return reader.read().then(processStream);
        }

        return reader.read().then(processStream);
      
      })
      .catch(error => {
        console.error('Error:', error);
        // Handle the failed state here, removing the optimistic message
        setMessages(prevMessages => prevMessages.filter(message => message.id !== tempMessageId));
        // Also remove the loading placeholder in case of error
        //setMessages(prevMessages => prevMessages.filter(message => message.id !== loadingMessageId));
      });
      */

      /*---- Post and Receive streaming Response Tail ----*/



      /*---- Update User Message Tail ----*/

      try {

        // console.log("HERE", messages);

        // Update the messages in the UI
        setMessages(prevMessages => {

          // console.log("HERE1", prevMessages);

          // Filter out the loading and optimistic messages
          let updatedMessages = prevMessages.filter(message => message.id !== tempMessageId && message.id !== tempBotMessageId);

          // console.log("HERE2", processedMessages);

          // Add the user message confirmed by the server
          if (processedMessages.userMessage) {
            updatedMessages.push({
              ...optimisticUserMessage,
              id: processedMessages.userMessage._id, // Update ID with the one from server
            });
          }

          // Add the new bot message
          if (processedMessages.botMessage) {
            updatedMessages.push({
              id: processedMessages.botMessage._id,
              text: textPart,
              sender: 'received',
              time: new Date().toLocaleTimeString(), // Adjust the time as per your need
            });
          }

          return updatedMessages;
        });

      } catch (e) {
        console.log("HERE1");
      }

      /*---- Update User Message Tail ----*/


    }

  };


  
  /*---- Main ChatBot Component ----*/

  return (
    <React.Fragment>
      <div className="chatbot-container">

        <div className={`chat-window ${isOpen ? 'open' : ''}`}>
          {/* Chat Header */}
          <div className="chat-header">
            <Row style={{width:"100%", marginLeft:"0px"}}>
              <Col sm={10}>
                <h5 className="chat-header-text">TheChart.AI <br /> 平台智能客服</h5>
                {/*<p className="chat-header-text" style={{fontSize: "10px"}}>*歡迎問我有關企業大師的問題</p>*/}
              </Col>
              <Col sm={2}>
                <button className="close-chat" onClick={closeChatWindow}>&times;</button>
              </Col>
            </Row>
          </div>

          {/* Chat Messages */}
          <div className="chat-messages" ref={messagesEndRef}>

            {/*
            <div key='1111' className="dialog-row received-side">
              <div className="dialog-box">
                <div className="loading-dots">
                  <div className="dot" style={{ '--dot-index': 0 }}></div>
                  <div className="dot" style={{ '--dot-index': 1 }}></div>
                  <div className="dot" style={{ '--dot-index': 2 }}></div>
                </div>
              </div>
            </div>
            */}

            {
              [
                {
                  sender:'received', 
                  text:`歡迎問我有關 TheChart.AI 平台操作的問題！
                  我會根據目前頁面上已經添加的操作資訊進行相關回覆，您也可以編輯頁面上的操作資訊，看看回覆內容會產生什麼不同。`
                }, 
                ...messages
              ].map((messageItem, index) => {
              // If the message is a loading placeholder, render the loading dots instead
              if (messageItem.sender === 'loading') {
                return (
                  <div key={`${index}${messageItem.id}`} className="dialog-row received-side">
                    <div className="dialog-box">
                      <div className="loading-dots">
                        <div className="dot" style={{ '--dot-index': 0 }}></div>
                        <div className="dot" style={{ '--dot-index': 1 }}></div>
                        <div className="dot" style={{ '--dot-index': 2 }}></div>
                      </div>
                    </div>
                  </div>
                );
              }
              // Otherwise, render the message as usual
              return (
                <div key={`${index}${messageItem.id}`} className={`dialog-row ${messageItem.sender}-side`}>
                  {messageItem.sender === "sent" && <div className="timer-sent">{messageItem.time}</div>}
                  <div className="dialog-box">
                    <div className="title">{messageItem.text}</div>
                  </div>
                  {messageItem.sender === "received" && <div className="timer-received">{messageItem.time}</div>}
                </div>
              );
            })}
          </div>

          {/* Chat Input */}
          <div className="chat-input-104">
            <textarea
              rows="1"
              ref={inputTextareaRef}
              placeholder="想問什麼呢？" 
              value={message}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              style={{ 'maxHeight':'5rem', 'width': "100%", 'border':'none', 'resize': 'none' }}
            />
            <button className="send-message" onClick={sendMessage}>
              ➤
            </button>
          </div>

        </div>

      </div>

      <button className="chatbot-button" onClick={toggleChatWindow}>
        <img className="chatbot-img" style={{ height: "6.2rem" }} src={chatIcon} alt="Chat" />
      </button>
    </React.Fragment>
  );
};

export default withRouter(Chatbot);
