import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Input,
} from "reactstrap"
import img7 from "../../../assets/images/product/img-7.png"
import img4 from "../../../assets/images/product/img-4.png"
import { nanoid } from "nanoid";

const EcommerceOrdersModal = props => {
  const { isOpen, toggle, selectedRowData } = props
  const { visitor, 
          interactQuestion, 
          interactResponse,
          rating,
          apiCost,
          timeStamp,
          otherDetail } = selectedRowData || ""

  const formattedDate = (timeStamp
    ?`${timeStamp.substring(0, 4)}`
      +`/${timeStamp.substring(4, 6)}`
      +`/${timeStamp.substring(6, 8)}`
      +` ${timeStamp.substring(8, 10)}`
      +`:${timeStamp.substring(10, 12)}`
      +`:${timeStamp.substring(12, 14)}`
    :"")

  const dateId = (timeStamp
    ?`${timeStamp.substring(0, 8)}`
      +` ${timeStamp.substring(8, 14)}`
    :"")

  const [selectedRating, setSelectedRating] = useState(0);
  const [hover, setHover] = useState(0);

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>問答細節</ModalHeader>
        <ModalBody>
          <p className="mb-2">
            提問編號: <span className="text-primary">{dateId}</span>
          </p>
          <p className="mb-4">
            管理人員: <span className="text-primary">#01</span>
          </p>

          <div className="table-responsive">
            <Table className="table align-middle">
              <thead>
                <tr>
                  <th scope="col">問題</th>
                  <th scope="col">回答</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>

                <tr>
                  <th scope="row" style={{width:"40%"}}>
                    <div>
                      <h5 className="text-muted font-size-14">{interactQuestion}</h5>
                    </div>
                  </th>
                  <td colSpan="2">
                    <div>
                      <h5 className="text-muted font-size-14">{interactResponse}</h5>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td colSpan="1">
                    <h6 className="m-0 text-right">編輯回答:</h6>
                  </td>
                  <td colSpan="2">
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="輸入回答..."
                      aria-label="Recipient's username"
                    />
                  </td>
                </tr>

                <tr>
                  <td colSpan="1">
                    <h6 className="m-0 text-right">評分:</h6>
                  </td>
                  <td colSpan="2">
                    {rating}

                    <div className="star-rating">
                      {[...Array(5)].map((star, index) => {
                        index += 1;
                        return (
                          <button
                            type="button"
                            key={index}
                            className={`${index <= (hover || selectedRating) ? "on" : "off"} star-button`}
                            onClick={() => setSelectedRating(index)}
                            onMouseEnter={() => setHover(index)}
                            onMouseLeave={() => setHover(selectedRating)}
                          >
                            <span className="star">&#9733;</span>
                          </button>
                        );
                      })}
                    </div>

                  </td>
                </tr>

                <tr>
                  <td colSpan="1">
                    <h6 className="m-0 text-right">API 計費:</h6>
                  </td>
                  <td colSpan="2">
                    {apiCost}
                  </td>
                </tr>

                <tr>
                  <td colSpan="1">
                    <h6 className="m-0 text-right">時間標記:</h6>
                  </td>
                  <td colSpan="2">
                    {formattedDate}
                  </td>
                </tr>

              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="success" onClick={toggle}>
            儲存
          </Button>
          <Button type="button" color="secondary" onClick={toggle}>
            關閉
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

EcommerceOrdersModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default EcommerceOrdersModal
