import React, { useState } from 'react';
import {
  Input
} from "reactstrap";
import * as XLSX from 'xlsx';

const UploadExcel = ({ onDataLoaded }) => {
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      // Assuming the data is in the first sheet
      const sheetName = workbook.SheetNames[0];
      const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });

      const reconstructedData = reconstructDataFromSheet(worksheet);
      onDataLoaded(reconstructedData);
    };

    reader.readAsArrayBuffer(file);
  };

  const reconstructDataFromSheet = (sheet) => {
    const reconstructedData = [];
    let currentItem = null;

    sheet.forEach((row, rowIndex) => {
      if (row.length === 0) return;

      if (row[0] === '編號' && rowIndex !== 0) {
        if (currentItem) {
          reconstructedData.push(currentItem);
        }
        currentItem = {
          order: row[1],
          masterquestion: '',
          masterknowledge: '',
          subknowledges: []
        };
      } else if (row[0] === '主題') {
        if (currentItem) {
          currentItem.masterquestion = row[1];
        }
      } else if (row[0] === '主題知識') {
        if (currentItem) {
          currentItem.masterknowledge = row[1];
        }
      } else if (row[1] === '範例提問' && row[3] === '範例回答') {
        // Subknowledge entry
        if (currentItem) {
          currentItem.subknowledges.push({
            question: row[2],
            knowledgecontent: row[4]
          });
        }
      }
    });

    if (currentItem) {
      reconstructedData.push(currentItem);
    }

    return reconstructedData;
  };

  return (
    <div>
      <Input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
    </div>
  );
};

export default UploadExcel;

