import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

// Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "components/Common/withRouter";
import { Link } from "react-router-dom";

// redux
import { useSelector, useDispatch } from "react-redux";

// i18n
import { withTranslation } from "react-i18next";


const SidebarContent = props => {

  const ref = useRef();



  // state.projects.projects is set through fetchUserData
  const { userData } = useSelector(state => ({
    userData: state.user.userData,
  }));
  const [role, setRole] = useState(userData?.role ?? '');
  useEffect(() => { 
    if(userData?.role){ 
      console.log("current role:", role);
      setRole(userData?.role);
    } 
  }, [userData?.role]);



  const activateParentDropdown = useCallback((item) => {

    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();

  const activeMenu = useCallback(() => {
    
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }

    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }

  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">

            <li className="menu-title">{props.t("Menu")} </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("功能總覽")}</span>
              </Link>

              {/* 功能總覽項目 */}
              <ul className="sub-menu" style={{ }} >
                <li>
                  <Link to="/dashboard">{props.t("功能主頁 | TheChart.AI")}</Link>
                </li>
                <li>
                  <Link to="/dashboard-translate">{props.t("TheChart：AI 即時翻譯")}</Link>
                </li>
                {/*
                <li>
                  <Link to="/dashboard-master">{props.t("TheChart：AI 智能客服")}</Link>
                </li>
                */}
                {/*
                <li>
                  <Link to="/dashboard-db">{props.t("資料建置：詞組管理")}</Link>
                </li>
                */}
                {/*
                <li>
                  <Link to="/dashboard-faceapi">{props.t("影像識別")}</Link>
                </li>
                */}
                
                {/*
                <li>
                  <Link to="/dashboard-tree">{props.t("對話追蹤")}</Link>
                </li>
                */}
                {/*
                <li>
                  <Link to="/dashboard-saas">{props.t("統計")}</Link>
                </li>
                */}
                {/*
                <li>
                  <Link to="/dashboard-crypto">{props.t("Crypto")}</Link>
                </li>
                */}
                {/*
                <li>
                  <Link to="/blog">{props.t("Blog")}</Link>
                </li>
                */}
                {/*
                <li>
                  <Link to="/dashboard-job">
                    <span className="badge rounded-pill text-bg-success float-end" key="t-new">New</span>
                    {props.t("Job")}
                  </Link>
                </li>
                */}
              </ul>
            </li>

            <li className="menu-title">{props.t("應用單元")}</li>

            {/*
            <li>
              <Link to="/calendar" >
                <i className="bx bx-calendar"></i>
                <span>{props.t("日程")}</span>
              </Link>
            </li>
            */}
            {/*
            <li>
              <Link to="/chat" >
                <i className="bx bx-chat"></i>
                <span>{props.t("Chat")}</span>
              </Link>
            </li>
            */}
            {/*
            <li>
              <Link to="/apps-filemanager" >
                <i className="bx bx-file"></i>
                <span>{props.t("File Manager")}</span>
              </Link>
            </li>
            */}
            {/*
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-store"></i>
                <span>{props.t("委派案件")}</span>
              </Link>
              <ul className="sub-menu">
                
                <li>
                  <Link to="/ecommerce-products">{props.t("Products")}</Link>
                </li>
                <li>
                  <Link to="/ecommerce-product-detail/1">
                    {props.t("Product Detail")}
                  </Link>
                </li>

                <li>
                  <Link to="/ecommerce-orders">{props.t("訂單資訊")}</Link>
                </li>

                <li>
                  <Link to="/ecommerce-customers">{props.t("Customers")}</Link>
                </li>


                <li>
                  <Link to="/ecommerce-cart">{props.t("Cart")}</Link>
                </li>

                <li>
                  <Link to="/ecommerce-checkout">{props.t("Checkout")}</Link>
                </li>

                <li>
                  <Link to="/ecommerce-shops">{props.t("Shops")}</Link>
                </li>

                <li>
                  <Link to="/ecommerce-add-product">
                    {props.t("下單")}
                  </Link>
                </li>
              </ul>
            </li>
          */}

            {/*
            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-bitcoin"></i>
                <span>{props.t("Crypto")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/crypto-wallet">{props.t("Wallet")}</Link>
                </li>
                <li>
                  <Link to="/crypto-buy-sell">{props.t("Buy/Sell")}</Link>
                </li>
                <li>
                  <Link to="/crypto-exchange">{props.t("Exchange")}</Link>
                </li>
                <li>
                  <Link to="/crypto-lending">{props.t("Lending")}</Link>
                </li>
                <li>
                  <Link to="/crypto-orders">{props.t("Orders")}</Link>
                </li>
                <li>
                  <Link to="/crypto-kyc-application">
                    {props.t("KYC Application")}
                  </Link>
                </li>
                <li>
                  <Link to="/crypto-ico-landing">{props.t("ICO Landing")}</Link>
                </li>
              </ul>
            </li>
            */}
            {/*
            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-envelope"></i>
                <span>{props.t("Email")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/email-inbox">{props.t("Inbox")}</Link>
                </li>
                <li>
                  <Link to="/email-read">{props.t("Read Email")} </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow ">
                    <span key="t-email-templates">{props.t("Templates")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/email-template-basic">
                        {props.t("Basic Action")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/email-template-alert">
                        {props.t("Alert Email")}{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="/email-template-billing">
                        {props.t("Billing Email")}{" "}
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            */}

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-briefcase-alt-2"></i>
                <span>{props.t("專案")}</span>
              </Link>
              <ul className="sub-menu" style={{ }}>
                {/*
                <li>
                  <Link to="/projects-grid">{props.t("Projects Grid")}</Link>
                </li>
                */}
                <li>
                  <Link to="/projects-list">{props.t("我的專案列表")}</Link>
                </li>
                <li>
                  <Link to="/projects-shared">{props.t("與我共享的專案")}</Link>
                </li>
                {/*
                <li>
                  <Link to="/projects-overview">
                    {props.t("Project Overview")}
                  </Link>
                </li>
                */}
                <li>
                  <Link to="/projects-create">{props.t("新增專案")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                {/*<i className="bx bx-briefcase-alt-2"></i>*/}
                {/*<i className="bx bx-server"></i>*/}
                <i className="bx bx-hdd"></i>
                {/*<i className="bx bx-cloud"></i>*/}
                {/*<i className="bx bx-folder"></i>*/}
                <span>{props.t("資料庫")}</span>
              </Link>
              <ul className="sub-menu" style={{ }}>
                <li>
                  <Link to="/dashboard-basedb">{props.t("清單：底層資料庫")}</Link>
                </li>
                <li>
                  <Link to="/dashboard-menu">{props.t("清單：客製化參考資料")}</Link>
                </li>
                <li>
                  <Link to="/dashboard-dbexcel">{props.t("資料建置：上傳檔案")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-message-dots"></i>
                <span>{props.t("智能客服")}</span>
              </Link>
              <ul className="sub-menu" style={{ }}>
                <li>
                  <Link to="/chatbot-menu">{props.t("智能客服列表")}</Link>
                </li>
                <li>
                  <Link to="/chatbot-conversations">{props.t("檢視所有對話")}</Link>
                </li>
                <li>
                  <Link to="/chatbot-create">{props.t("建立智能客服")}</Link>
                </li>
                <li>
                  <Link to="/qa-create">{props.t("自動生成問答集")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow " style={{ display: (role === 'admin'?'block':'none') }}>
                <i className="bx bx-receipt"></i>
                <span>{props.t("流程式 AI 應用")}</span>
              </Link>
              <ul className="sub-menu" style={{ }}>
                {role === 'admin' && (
                  <>
                    <li>
                      <Link to="/kiosk-menu">{props.t("點餐介面模擬")}</Link>
                    </li>
                    <li>
                      <Link to="/kiosk-qadialog">{props.t("點餐對話模擬")}</Link>
                    </li>
                    <li>
                      <Link to="/kiosk-qatest">{props.t("點餐資料測試")}</Link>
                    </li>
                    <li>
                      <Link to="/product-create">{props.t("新增產品")}</Link>
                    </li>
                    <li>
                      <Link to="/questionnaire-create">{props.t("建立問卷")}</Link>
                    </li>
                  </>
                )}
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-credit-card-alt"></i>
                <span>{props.t("TheChart 點數")}</span>
              </Link>
              <ul className="sub-menu" style={{ }}>
                <li>
                  <Link to="/purchase-point">{props.t("購買 TheChart 點數")}</Link>
                </li>
                <li>
                  <Link to="/purchase-point-history">{props.t("點數購買紀錄")}</Link>
                </li>
              </ul>
            </li>


            {/*
            <li>
              <Link to="/#" className="has-arrow " style={{ display: (role === 'admin'?'block':'none') }}>
                <i className="bx bx-id-card"></i>
                <span>{props.t("名片")}</span>
              </Link>
              <ul className="sub-menu" style={{ }}>
                {role === 'admin' && (
                  <>
                    <li>
                      <Link to="/order-list">{props.t("展示名片")}</Link>
                    </li>
                    <li>
                      <Link to="/order-list">{props.t("建立名片")}</Link>
                    </li>
                  </>
                )}
              </ul>
            </li>
            */}
            
            <li>
              <Link to="/#" className="has-arrow " style={{ display: (role === 'admin'?'block':'none') }}>
                {/*<i className="bx bx-cog"></i>*/}
                {/*<i className="bx bx-slider-alt"></i>*/}
                {/*<i className="bx bx-user-pin"></i>*/}
                {/*<i className="bx bx-lock"></i>*/}
                {/*<i className="bx bx-dashboard"></i>*/}
                {/*<i className="bx bx-tachometer"></i>*/}
                <i className="bx bx-key"></i>
                {/*<i className="bx bx-wrench"></i>*/}
                {/*<i className="bx bx-bar-chart-alt-2"></i>*/}
                {/*<i className="bx bx-line-chart"></i>*/}
                <span>{props.t("管理員")}</span>
              </Link>
              <ul className="sub-menu" style={{ }}>
                {role === 'admin' && (
                  <>
                    <li>
                      <Link to="/dashboard-cabin">{props.t("Admin Panel")}</Link>
                    </li>
                  </>
                )}
              </ul>
            </li>
            

            <li>
              <Link to="/#" className="has-arrow ">
                <i className='bx bx-wrench'></i>
                <span>{props.t("工具")}</span>
              </Link>
              <ul className="sub-menu" style={{ }}>
                <li>
                  <Link to="/dashboard-admin">{props.t("TheChart 工具：演算法比對")}</Link>
                </li>
                <li>
                  <Link to="/word-read">{props.t("檔案讀取")}</Link>
                </li>
                <li>
                  <Link to="/word-write">{props.t("檔案寫入")}</Link>
                </li>
                <li>
                  <Link to="/image-crop">{props.t("圖片上傳")}</Link>
                </li>
                <li>
                  <Link to="/upload-pdf">{props.t("PDF 檔案上傳")}</Link>
                </li>
              </ul>
            </li>

            <li>
              {role === 'admin' && (
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-map-alt"></i>
                <span>{props.t("關於")}</span>
              </Link>
              )}
              <ul className="sub-menu" style={{ }}>
                {role === 'admin' && (
                  <>
                    <li>
                      <Link to="/order-list">{props.t("操作手冊")}</Link>
                    </li>
                    <li>
                      <Link to="/order-list">{props.t("什麼是 TheChart？")}</Link>
                    </li>
                  </>
                )}
              </ul>
            </li>

            {/*
            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-task"></i>
                <span>{props.t("文本")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/tasks-list">{props.t("所有文本")}</Link>
                </li>
                <li>
                  <Link to="/tasks-create">{props.t("新增文本")}</Link>
                </li>
              </ul>
            </li>
            */}

          {/*
            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-receipt"></i>
                <span>{props.t("發票")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/invoices-list">{props.t("發票列表")}</Link>
                </li>
                <li>
                  <Link to="/invoices-detail">{props.t("檢視發票細節")}</Link>
                </li>
              </ul>
            </li>
          */}

            {/*
            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bxs-user-detail"></i>
                <span>{props.t("Contacts")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/contacts-grid">{props.t("User Grid")}</Link>
                </li>
                <li>
                  <Link to="/contacts-list">{props.t("User List")}</Link>
                </li>
                <li>
                  <Link to="/contacts-profile">{props.t("Profile")}</Link>
                </li>
              </ul>
            </li>
            */}
            {/*
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-detail" />

                <span>{props.t("Blog")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/blog-list">{props.t("Blog List")}</Link>
                </li>
                <li>
                  <Link to="/blog-grid">{props.t("Blog Grid")}</Link>
                </li>
                <li>
                  <Link to="/blog-details">{props.t("Blog Details")}</Link>
                </li>
              </ul>
            </li>
            */}
            {/*
            <li>
              <Link to="/#">
                <span className="badge rounded-pill bg-success float-end" key="t-new">New</span>
                <i className="bx bx-briefcase-alt"></i>
                <span key="t-jobs">{props.t("Jobs")}</span>
              </Link>
              <ul className="sub-menu">
                <li><Link to="/job-list">{props.t("Job List")}</Link></li>
                <li><Link to="/job-grid">{props.t("Job Grid")}</Link></li>
                <li><Link to="/job-apply">{props.t("Apply Job")}</Link></li>
                <li><Link to="/job-details">{props.t("Job Details")}</Link></li>
                <li><Link to="/job-categories">{props.t("Jobs Categories")}</Link></li>
                <li>
                  <Link to="/#" className="has-arrow">{props.t("Candidate")}</Link>
                  <ul className="sub-menu" aria-expanded="true">
                    <li><Link to="/candidate-list">{props.t("List")}</Link></li>
                    <li><Link to="/candidate-overview">{props.t("Overview")}</Link></li>
                  </ul>
                </li>
              </ul>
            </li>
            */}

            {/*
            <li className="menu-title">Pages</li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-user-circle"></i>
                <span>{props.t("Authentication")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/pages-login">{props.t("Login")}</Link>
                </li>
                <li>
                  <Link to="/pages-login-2">{props.t("Login 2")}</Link>
                </li>
                <li>
                  <Link to="/pages-register">{props.t("Register")}</Link>
                </li>
                <li>
                  <Link to="/pages-register-2">{props.t("Register 2")}</Link>
                </li>
                <li>
                  <Link to="/page-recoverpw">
                    {props.t("Recover Password")}
                  </Link>
                </li>
                <li>
                  <Link to="/page-recoverpw-2">
                    {props.t("Recover Password 2")}
                  </Link>
                </li>
                <li>
                  <Link to="/auth-lock-screen">{props.t("Lock Screen")}</Link>
                </li>
                <li>
                  <Link to="/auth-lock-screen-2">
                    {props.t("Lock Screen 2")}
                  </Link>
                </li>
                <li>
                  <Link to="/page-confirm-mail">{props.t("Confirm Mail")}</Link>
                </li>
                <li>
                  <Link to="/page-confirm-mail-2">
                    {props.t("Confirm Mail 2")}
                  </Link>
                </li>
                <li>
                  <Link to="/auth-email-verification">
                    {props.t("Email Verification")}
                  </Link>
                </li>
                <li>
                  <Link to="/auth-email-verification-2">
                    {props.t("Email Verification 2")}
                  </Link>
                </li>
                <li>
                  <Link to="/auth-two-step-verification">
                    {props.t("Two Step Verification")}
                  </Link>
                </li>
                <li>
                  <Link to="/auth-two-step-verification-2">
                    {props.t("Two Step Verification 2")}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-file"></i>
                <span>{props.t("Utility")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/pages-starter">{props.t("Starter Page")}</Link>
                </li>
                <li>
                  <Link to="/pages-maintenance">{props.t("Maintenance")}</Link>
                </li>
                <li>
                  <Link to="/pages-comingsoon">{props.t("Coming Soon")}</Link>
                </li>
                <li>
                  <Link to="/pages-timeline">{props.t("Timeline")}</Link>
                </li>
                <li>
                  <Link to="/pages-faqs">{props.t("FAQs")}</Link>
                </li>
                <li>
                  <Link to="/pages-pricing">{props.t("Pricing")}</Link>
                </li>
                <li>
                  <Link to="/pages-404">{props.t("Error 404")}</Link>
                </li>
                <li>
                  <Link to="/pages-500">{props.t("Error 500")}</Link>
                </li>
              </ul>
            </li>
            */}

            {/*
            <li className="menu-title">{props.t("Components")}</li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-tone" />
                <span>{props.t("UI Elements")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/ui-alerts">{props.t("Alerts")}</Link>
                </li>
                <li>
                  <Link to="/ui-buttons">{props.t("Buttons")}</Link>
                </li>
                <li>
                  <Link to="/ui-cards">{props.t("Cards")}</Link>
                </li>
                <li>
                  <Link to="/ui-carousel">{props.t("Carousel")}</Link>
                </li>
                <li>
                  <Link to="/ui-dropdowns">{props.t("Dropdowns")}</Link>
                </li>
                <li>
                  <Link to="/ui-offcanvas">{props.t("OffCanvas")}</Link>
                </li>
                <li>
                  <Link to="/ui-grid">{props.t("Grid")}</Link>
                </li>
                <li>
                  <Link to="/ui-images">{props.t("Images")}</Link>
                </li>
                <li>
                  <Link to="/ui-lightbox">{props.t("Lightbox")}</Link>
                </li>
                <li>
                  <Link to="/ui-modals">{props.t("Modals")}</Link>
                </li>
                <li>
                  <Link to="/ui-rangeslider">
                    {props.t("Range Slider")}
                  </Link>
                </li>
                <li>
                  <Link to="/ui-session-timeout">
                    {props.t("Session Timeout")}
                  </Link>
                </li>
                <li>
                  <Link to="/ui-progressbars">
                    {props.t("Progress Bars")}
                  </Link>
                </li>
                <li>
                  <Link to="/ui-placeholders">{props.t("Placeholders")}</Link>
                </li>
                <li>
                  <Link to="/ui-sweet-alert">
                    {props.t("Sweet-Alert")}
                  </Link>
                </li>
                <li>
                  <Link to="/ui-tabs-accordions">
                    {props.t("Tabs & Accordions")}
                  </Link>
                </li>
                <li>
                  <Link to="/ui-typography">
                    {props.t("Typography")}
                  </Link>
                </li>
                <li>
                  <Link to="/ui-toasts">{props.t("Toasts")}</Link>
                </li>
                <li>
                  <Link to="/ui-video">{props.t("Video")}</Link>
                </li>
                <li>
                  <Link to="/ui-general">{props.t("General")}</Link>
                </li>
                <li>
                  <Link to="/ui-colors">{props.t("Colors")}</Link>
                </li>
                <li>
                  <Link to="/ui-rating">{props.t("Rating")}</Link>
                </li>
                <li>
                  <Link to="/ui-notifications">
                    {props.t("Notifications")}
                  </Link>
                </li>
                <li>
                  <Link to="/ui-breadcrumb">
                    {props.t("Breadcrumb")}
                  </Link>
                </li>
                <li>
                  <Link to="/ui-utilities">
                    {props.t("Utilities")}
                    <span className="badge rounded-pill bg-success float-end" key="t-new">New</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" >
                <i className="bx bxs-eraser"></i>
                <span className="badge rounded-pill bg-danger float-end">
                  10
                </span>
                <span>{props.t("Forms")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/form-elements">{props.t("Form Elements")}</Link>
                </li>
                <li>
                  <Link to="/form-layouts">{props.t("Form Layouts")}</Link>
                </li>
                <li>
                  <Link to="/form-validation">
                    {props.t("Form Validation")}
                  </Link>
                </li>
                <li>
                  <Link to="/form-advanced">{props.t("Form Advanced")}</Link>
                </li>
                <li>
                  <Link to="/form-editors">{props.t("Form Editors")}</Link>
                </li>
                <li>
                  <Link to="/form-uploads">{props.t("Form File Upload")} </Link>
                </li>
                <li>
                  <Link to="/form-repeater">{props.t("Form Repeater")}</Link>
                </li>
                <li>
                  <Link to="/form-wizard">{props.t("Form Wizard")}</Link>
                </li>
                <li>
                  <Link to="/form-mask">{props.t("Form Mask")}</Link>
                </li>
                <li>
                  <Link to="/dual-listbox">{props.t("Transfer List")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-list-ul"></i>
                <span>{props.t("Tables")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/tables-basic">{props.t("Basic Tables")}</Link>
                </li>
                <li>
                  <Link to="/tables-datatable">{props.t("Data Tables")}</Link>
                </li>
                <li>
                  <Link to="/tables-responsive">
                    {props.t("Responsive Table")}
                  </Link>
                </li>
                <li>
                  <Link to="/tables-dragndrop">{props.t("Drag & Drop Table")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bxs-bar-chart-alt-2"></i>
                <span>{props.t("Charts")}</span>
              </Link>

              <ul className="sub-menu">
                <li>
                  <Link to="/apex-charts">{props.t("Apex charts")}</Link>
                </li>
                <li>
                  <Link to="/chartist-charts">{props.t("Chartist Chart")}</Link>
                </li>
                <li>
                  <Link to="/chartjs-charts">{props.t("Chartjs Chart")}</Link>
                </li>
                <li>
                  <Link to="/e-charts">{props.t("E Chart")}</Link>
                </li>
                <li>
                  <Link to="/sparkline-charts">
                    {props.t("Sparkline Chart")}
                  </Link>
                </li>
                <li>
                  <Link to="/charts-knob">{props.t("Knob Chart")}</Link>
                </li>
                <li>
                  <Link to="/re-charts">{props.t("Re Chart")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-aperture"></i>
                <span>{props.t("Icons")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/icons-boxicons">{props.t("Boxicons")}</Link>
                </li>
                <li>
                  <Link to="/icons-materialdesign">
                    {props.t("Material Design")}
                  </Link>
                </li>
                <li>
                  <Link to="/icons-dripicons">{props.t("Dripicons")}</Link>
                </li>
                <li>
                  <Link to="/icons-fontawesome">{props.t("Font awesome")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-map"></i>
                <span>{props.t("Maps")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/maps-google">{props.t("Google Maps")}</Link>
                </li>
                <li>
                  <Link to="/maps-vector">{props.t("Vector Maps")}</Link>
                </li>
                <li>
                  <Link to="/maps-leaflet">{props.t("Leaflet Maps")}</Link>
                </li>
              </ul>
            </li>
            */}
            {/*
            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-share-alt"></i>
                <span>{props.t("Multi Level")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#">{props.t("Level 1.1")}</Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {props.t("Level 1.2")}
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/#">{props.t("Level 2.1")}</Link>
                    </li>
                    <li>
                      <Link to="/#">{props.t("Level 2.2")}</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
