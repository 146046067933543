// sagas.js
import { select, call, put, takeLatest } from "redux-saga/effects";
import { isLoginStateClear, isGoogleLoginStateClear } from '../../selectors';

// Login Redux States
import { 
	CLEAR_GOOGLE_LOGIN_STATES,
	GOOGLE_LOGIN_REQUEST, 
	GOOGLE_LOG_OUT,
} from "./actionTypes";

import { 
	clearGoogleLoginStates,
	googleLoginSuccess, 
	googleLoginFailure, 
} from "./actions";

import {
	// Also clear normal login states
	clearLoginStates,
} from "../../actions";

import { googleUserLogin } from 'api/api.js';


function* handleGoogleLogin({ payload: res }) {

	const loginStateClear = yield select(isLoginStateClear);
  const googleLoginStateClear = yield select(isGoogleLoginStateClear);

	if (!loginStateClear) {
		console.log('[googleLogin/saga.js] putting clearLoginStates');
    yield put(clearLoginStates());
  }

  if (!googleLoginStateClear) {
  	console.log('[googleLogin/saga.js] putting clearGoogleLoginStates');
    yield put(clearGoogleLoginStates());
  }

  try {
    // Call API to verify the token and get user data
    const userData = yield call(googleUserLogin, res);
    yield put(googleLoginSuccess(userData));

  } catch (error) {
    yield put(googleLoginFailure(error?.response?.data?.message));
  }
}

function* watchGoogleLoginRequest() {
  yield takeLatest(GOOGLE_LOGIN_REQUEST, handleGoogleLogin);
}

export default watchGoogleLoginRequest;












