import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Components
import KnoviaData from "./knoviadata";
import PollMain from "./pollmain";

import { useSelector, useDispatch, shallowEqual } from "react-redux";

import ActivityComp from "../Dashboard/ActivityComp";

const DashboardAdmin = () => {

  //meta title
  document.title = "OPA x ByteLingo 管理後台";
  const dispatch = useDispatch();

  console.log('rerendered');

  useEffect(() => {
    dispatch({ type: 'GET_BASE_DATABASES' });
  }, [dispatch]);

  const { databases, baseDatabases } = useSelector(state => ({
    databases: state.databases.databases,
    baseDatabases: state.databases.baseDatabases,
  }), shallowEqual);

  /*
  const databases = useSelector(state => state.databases.databases);
  const baseDatabases = useSelector(state => state.databases.baseDatabases);
  */

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Dashboards" breadcrumbItem="相似度分析" />

          <Row>
            <Col xl="12">
              <KnoviaData databases={databases} baseDatabases={baseDatabases} />
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default DashboardAdmin;



