export const CLEAR_REGISTER_USER = "clear_register_user";
export const CLEAR_REGISTER_USER_SUCCESSFUL = "clear_register_user_successful";
export const CLEAR_REGISTER_USER_FAILED = "clear_register_user_failed";

export const REGISTER_USER = "register_user";
export const REGISTER_USER_SUCCESSFUL = "register_user_successfull";
export const REGISTER_USER_FAILED = "register_user_failed";

export const VERIFY_ACCOUNT_REQUEST = 'VERIFY_ACCOUNT_REQUEST';
export const VERIFY_ACCOUNT_SUCCESS = 'VERIFY_ACCOUNT_SUCCESS';
export const VERIFY_ACCOUNT_FAILURE = 'VERIFY_ACCOUNT_FAILURE';