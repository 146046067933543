/*
const doc = new Document({
  sections: [
    {
      properties: {},
      children: [
        new Paragraph({
          children: [
            new TextRun("Hello World"),
            new TextRun({
              text: "Foo Bar",
              bold: true,
            }),
            new TextRun({
              text: "\tGithub is the best",
              bold: true,
            }),
          ],
        }),
      ],
    },
  ],
});
*/
import { v4 as uuidv4 } from 'uuid';
import { 
  Document, 
  Packer, 
  Paragraph, 
  TextRun, 
  Numbering, 
  AbstractNumbering, 
  Level, 
  LevelFormat, 
  AlignmentType,
  Table,
  TableCell,
  TableRow,
} from 'docx';

export const parseHtmlToDocxElements = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  //console.log('doc:', doc);
  const elements = [];

  const processNode = (node) => {
    let textRunOptions = { text: node.textContent };

    if (node.nodeName === 'SPAN') {
        const style = node.getAttribute('style');
        if (style) {
            if (style.includes('text-decoration:underline')) {
                textRunOptions.underline = true;
            }
            if (style.includes('color:')) {
                const colorMatch = style.match(/color:(#[0-9a-fA-F]{6}|#[0-9a-fA-F]{3}|[a-zA-Z]+)/);
                if (colorMatch) {
                    textRunOptions.color = colorMatch[1].replace('#', '');
                }
            }
            if (style.includes('font-weight:bold')) {
                textRunOptions.bold = true;
            }
            if (style.includes('font-size:')) {
                const sizeMatch = style.match(/font-size:(\d+)pt/);
                if (sizeMatch) {
                    textRunOptions.size = parseInt(sizeMatch[1]) * 2; // Convert pt to half-points
                }
            }
            if (style.includes('font-family:')) {
                const fontFamilyMatch = style.match(/font-family:([^;]+)/);
                if (fontFamilyMatch) {
                    textRunOptions.font = fontFamilyMatch[1].trim();
                }
            }
        }
    }
    return new TextRun(textRunOptions);
  };

  const processParagraph = (pNode) => {
    const paragraphOptions = {};
    const style = pNode.getAttribute('style');
    if (style) {
        if (style.includes('text-align: center;')) {
            paragraphOptions.alignment = AlignmentType.CENTER;
        }
        // Add more conditions here for other alignments if necessary
    }

    const paragraph = new Paragraph(paragraphOptions);
    pNode.childNodes.forEach(child => {
        paragraph.addChildElement(processNode(child));
    });
    return paragraph;
  };

  const processList = (listNode) => {
      listNode.childNodes.forEach(itemNode => {
          if (itemNode.nodeName === 'LI') {
              const listItemParagraph = new Paragraph({
                  numbering: {
                      reference: "my-bullet-points",
                      level: 0,
                  },
              });

              itemNode.childNodes.forEach(child => {
                  if (child.nodeName === 'P') {
                      child.childNodes.forEach(subChild => {
                          listItemParagraph.addChildElement(processNode(subChild));
                      });
                  }
              });
              elements.push(listItemParagraph);
          }
      });
  };

  const processTable = (tableNode) => {
      const tableRows = [];
      tableNode.querySelectorAll('tr').forEach(tr => {
          const tableCells = [];
          tr.querySelectorAll('td').forEach(td => {
              const cellParagraphs = [];
              td.querySelectorAll('p').forEach(p => {
                // Use processParagraph here
                cellParagraphs.push(processParagraph(p));
              });
              tableCells.push(new TableCell({
                  children: cellParagraphs,
              }));
          });
          tableRows.push(new TableRow({
              children: tableCells,
          }));
      });
      return new Table({
          rows: tableRows,
      });
  };

  doc.body.childNodes.forEach(node => {
    //console.log('node:', node, node.nodeName);
    if (node.nodeName === 'P') {
      elements.push(processParagraph(node));
    } else if (node.nodeName === 'UL') {
      processList(node);
    } else if (node.nodeName === 'TABLE') {
      elements.push(processTable(node));
    }
    // Handle other HTML tags as needed
  });

  return elements;
};

export function convertToHTML(xmlDoc) {
    let htmlContent = '';
    let listOpen = false;

    // Get all body elements
    const bodyElements = xmlDoc.getElementsByTagName('w:body')[0].childNodes;

    // Loop through all body elements
    for (let i = 0; i < bodyElements.length; i++) {
        const element = bodyElements[i];

        if (element.nodeName === 'w:p') {
            // Check if the paragraph is a list item
            const isListItem = element.getElementsByTagName('w:numPr').length > 0;

            if (isListItem) {
                if (!listOpen) {
                    htmlContent += '<ul>'; // Start a new list
                    listOpen = true;
                }
                htmlContent += processListItem(element);
            } else {
                if (listOpen) {
                    htmlContent += '</ul>'; // Close the current list
                    listOpen = false;
                }
                htmlContent += processParagraph(element);
            }
        } else if (element.nodeName === 'w:tbl') {
            // Process table
            htmlContent += processTable(element);
        }
    }

    // Close list if it's still open
    if (listOpen) {
        htmlContent += '</ul>';
    }

    return htmlContent;
}

function processTable(tableElement) {
    let tableHTML = '<table>';

    // Get all row elements within the table
    const rows = tableElement.getElementsByTagName('w:tr');
    for (let i = 0; i < rows.length; i++) {
        tableHTML += '<tr>';

        // Get all cell elements within the row
        const cells = rows[i].getElementsByTagName('w:tc');
        for (let j = 0; j < cells.length; j++) {
            let cellStyle = '';

            // Check for vertical alignment in cell properties
            const cellProperties = cells[j].getElementsByTagName('w:tcPr')[0];
            if (cellProperties) {
                // WRONG!!
                const valign = cellProperties.getElementsByTagName('w:val')[0];
                if (valign && valign.getAttribute('w:val') === 'center') {
                    cellStyle = 'vertical-align: middle;';
                }
                // Other vertical alignment cases (top, bottom) can be added here
            }

            tableHTML += `<td style="${cellStyle}">`;

            // Process each paragraph within the cell
            const cellParagraphs = cells[j].getElementsByTagName('w:p');
            for (let k = 0; k < cellParagraphs.length; k++) {
                const pStyle = processParagraphStyle(cellParagraphs[k]);
                const id = uuidv4();
                tableHTML += `<p style="${pStyle}" data-id=${id}>${processParagraph(cellParagraphs[k],1)}</p>`;
            }

            tableHTML += '</td>';
        }

        tableHTML += '</tr>';
    }

    tableHTML += '</table>';
    return tableHTML;
}

function processParagraphStyle(paragraphElement) {
    let style = '';
    
    const pPr = paragraphElement.getElementsByTagName('w:pPr')[0];
    if (pPr) {
        const jc = pPr.getElementsByTagName('w:jc')[0];
        if (jc) {
            const alignVal = jc.getAttribute('w:val');
            if (alignVal === 'center') {
                style = 'text-align: center;';
            }
            // Additional alignment cases (left, right, justify) can be added here
        }
    }

    return style;
}

function processListItem(listItemElement) {
    let listItemHTML = processParagraph(listItemElement); // Reuse paragraph processing
    return `<li>${listItemHTML}</li>`;
}

function processParagraph(paragraphElement, isTable=0) {
    let paragraphHTML = '';

    // Get all run elements within the paragraph
    const runs = paragraphElement.getElementsByTagName('w:r');
    for (let j = 0; j < runs.length; j++) {
        const run = runs[j];
        const textNode = run.getElementsByTagName('w:t')[0];
        if (!textNode) continue;

        let textHTML = textNode.textContent;
        let style = '';  // Initialize style string for inline CSS

        // Check for run properties
        const runProperties = run.getElementsByTagName('w:rPr')[0];
        if (runProperties) {
            // Font size
            const szTag = runProperties.getElementsByTagName('w:sz')[0];
            if (szTag) {
                const fontSize = parseInt(szTag.getAttribute('w:val'), 10) / 2; // Convert half-points to points
                style += `font-size:${fontSize}pt;`;
            }

            // Font color
            const colorTag = runProperties.getElementsByTagName('w:color')[0];
            if (colorTag) {
                const color = colorTag.getAttribute('w:val');
                style += `color:#${color};`;
            }

            // Bold
            if (runProperties.getElementsByTagName('w:b').length > 0) {
                style += 'font-weight:bold;';
            }

            // Italic
            if (runProperties.getElementsByTagName('w:i').length > 0) {
                style += 'font-style:italic;';
            }

            // Underline
            if (runProperties.getElementsByTagName('w:u').length > 0) {
                style += 'text-decoration:underline;';
            }

            // Strikethrough
            if (runProperties.getElementsByTagName('w:strike').length > 0) {
                style += 'text-decoration:line-through;';
            }

            // Font family
            const fontTag = runProperties.getElementsByTagName('w:rFonts')[0];
            if (fontTag) {
                const fontFamily = fontTag.getAttribute('w:ascii'); // You can also check 'w:hAnsi' or 'w:cs'
                if (fontFamily) {
                    style += `font-family:${fontFamily};`;
                }
            }
        }

        const id = uuidv4();

        // Apply styles
        if (style) {
          textHTML = `<span data-id=${id} style="${style}">${textHTML}</span>`;
        } else {
          //textHTML = `<span data-id=${id}">${textHTML}</span>`;
        }

        paragraphHTML += textHTML;
    }

    const id = uuidv4();
    // Return paragraph HTML content
    if(!isTable){
      return `<p data-id=${id}>${paragraphHTML}</p>`;
    } else {
      return `${paragraphHTML}`;
    }
}

export default convertToHTML;
