// Custom Axios
import axiosInstance from 'utils/axiosInstance';

export const getChatbots = async () => {
  console.log("[api/chatbot.js] Getting Chatbots...");

  try {
    const response = await axiosInstance.get(`/knovia/chatbots-user`);
    const data = response.data;
    console.log('[api/chatbot.js] Chatbots fetched:', data);

    return data || [];
  } catch (error) {
    throw error;
  }

} 

// Add new Chatbot
export const addNewChatbot = async (chatbot) => {
	try {
		const response = await axiosInstance.post(`/knovia/chatbots`, chatbot);
		const data = response.data;
   	console.log("[api/chatbot.js] Created new chatbot:", data);
   	return data || [];
   } catch (error) {
   	throw error;
   }
}

// Delete existing Chatbot
export const deleteChatbot = async(cbId) => {
	try {
		const response = await axiosInstance.delete(`/knovia/chatbots/${cbId}`);
		const data = response.data;
   	console.log("[api/chatbot.js] Deleted chatbot Id:", data);
   	return data || [];
   } catch (error) {
   	throw error;
   }
}

// Update existing Chatbot
export const updateChatbot = async (cbData) => {
  try {
    console.log('cbData:', cbData);
    const response = await axiosInstance.put(
    	`/knovia/chatbots/${cbData._id}`, 
    	cbData,
    );
    const data = response.data;
    console.log("[api/chatbot.js] Updated chatbot:", data);
    return response.data;
  } catch (error) {
    console.error("Error updating chatbot:", error);
    throw error;
  }
};







