import { takeEvery, fork, put, all, call } from "redux-saga/effects"

import {
  EDIT_PROFILE,
  EDIT_PROFILE_IMAGE,
  EDIT_PROFILE_EMAIL,
  EDIT_PROFILE_USERNAME,
  EDIT_PROFILE_PASSWORD 
} from "./actionTypes";

import { 
  profileSuccess, 
  profileError,
  profileImageSuccess,
  profileImageError,
  profileEmailSuccess,
  profileEmailError,
  profileUsernameSuccess,
  profileUsernameError,
  profilePasswordSuccess,
  profilePasswordError,
  clearProfileSuccess,
  clearProfileError,
} from "./actions"

import { fetchUserData } from '../../user/actions';

// Custom Axios
import axiosInstance from 'utils/axiosInstance';

function* editProfileImage({ payload: values }) {
  try {
    const { img: newImg } = values;
    console.log('newImg:', newImg);
    const response = yield call(
      axiosInstance.post,
      `/knovia/update-image`,
      { newImg }
    );
    yield put(clearProfileSuccess());
    yield put(clearProfileError());
    yield put(profileImageSuccess(response.data?.message));
    yield put(fetchUserData());
  } catch (error) {
    yield put(clearProfileSuccess());
    yield put(clearProfileError());
    yield put(profileImageError(error?.response?.data?.message || 'Some Error Happened when changing Profile Image'));
  }
}

function* editProfileEmail({ payload: values }) {
  try {
    const { email: newEmail } = values;
    const response = yield call(
      axiosInstance.post,
      `/knovia/update-email`, // Adjust endpoint as needed
      { newEmail }
    );
    yield put(clearProfileSuccess());
    yield put(clearProfileError());
    yield put(profileEmailSuccess(response?.data.message));
    yield put(fetchUserData());
  } catch (error) {
    yield put(clearProfileSuccess());
    yield put(clearProfileError());
    yield put(profileEmailError(error?.response?.data?.message || 'Some Error Happened when changing Email'));
  }
}

function* editProfileUsername({ payload: values }) {
  try {
    const { username: newUsername } = values;
    const response = yield call(
      axiosInstance.post,
      `/knovia/update-username`, // Adjust endpoint as needed
      { newUsername }
    );
    yield put(clearProfileSuccess());
    yield put(clearProfileError());
    yield put(profileUsernameSuccess(response?.data.message));
    yield put(fetchUserData());
  } catch (error) {
    yield put(clearProfileSuccess());
    yield put(clearProfileError());
    yield put(profileUsernameError(error?.response?.data?.message || 'Some Error Happened when changing Username'));
  }
}

function* editProfilePassword({ payload: passwordData }) {
  try {
    const {currentPassword, newPassword} = passwordData;
    const response = yield call(
      axiosInstance.post,
      `/knovia/update-password`, // Adjust endpoint as needed
      { currentPassword, newPassword }
    );
    yield put(clearProfileSuccess());
    yield put(clearProfileError());
    yield put(profilePasswordSuccess(response.data.message));
    yield put(fetchUserData());
  } catch (error) {
    yield put(clearProfileSuccess());
    yield put(clearProfileError());
    yield put(profilePasswordError(error?.response?.data?.message || 'Some Error Happened when changing password'));
  }
}

/*
function* editProfile({ payload: { user } }) {

  try {
    console.log("user in saga.js:", user);
    const token = getAuthToken();

    const response = yield call(
      axiosInstance.post, 
      `/knovia/userdata`, 
      {
        newImg: user.img,
        newEmail: user.email,
        newPassword: user.password,
      }
    );
    yield put(profileSuccess(response.data));

  } catch (error) {
    yield put(profileError(error.response ? error.response.data : error.message));
  }

}
*/

export function* watchProfile() {
  // yield takeEvery(EDIT_PROFILE, editProfile);
  yield takeEvery(EDIT_PROFILE_IMAGE, editProfileImage);
  yield takeEvery(EDIT_PROFILE_EMAIL, editProfileEmail);
  yield takeEvery(EDIT_PROFILE_USERNAME, editProfileUsername);
  yield takeEvery(EDIT_PROFILE_PASSWORD, editProfilePassword);
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga;
