// store/auth/login/sagas.js
import { select, call, put, takeLatest } from "redux-saga/effects";
import { isLoginStateClear, isGoogleLoginStateClear } from '../../selectors';
import axiosInstance from 'utils/axiosInstance';

// Login Redux States
import {
  CLEAR_LOGIN_STATES,
  LOGIN_USER, 
  LOGOUT_USER,
} from "./actionTypes";

import {
  clearLoginStates,
  loginSuccess,
  loginFailure,
  logoutUserSuccess,
  logoutUserFailure,
 } from "./actions";

import { refreshToken } from 'utils/authUtils';

import { 
  fetchUserData, 
  clearUserData,
  googleLogOut,

  // Also clear google login states
  clearGoogleLoginStates
} from '../../actions';

import { userLogin } from 'api/api.js';


function* loginUser({ payload: { user, history } }) {

  const loginStateClear = yield select(isLoginStateClear);
  const googleLoginStateClear = yield select(isGoogleLoginStateClear);

  if (!loginStateClear) {
    console.log('[login/saga.js] putting clearLoginStates');
    yield put(clearLoginStates());
  }

  if (!googleLoginStateClear) {
    console.log('[login/saga.js] putting clearGoogleLoginStates');
    yield put(clearGoogleLoginStates());
  }

  try {
    /*
    // Perform login logic, set authUser in localStorage
    const refreshInterval = 1000 * 60 * 60; // 1 hour
    setInterval(refreshToken, refreshInterval);
    */

    const userData = yield call(userLogin, { email: user.email, password: user.password });
    yield put(loginSuccess(userData));
  } catch (error) {
    console.log('[store/auth/login/saga.js] Error in loginUser()', error);
    yield put(loginFailure(error?.response?.data?.message || 'Login failed'));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");

    yield put(clearUserData());
    // Also mark googleLogin status as Logged Out
    yield put(googleLogOut());

    yield put(logoutUserSuccess());

    history('/login');
  } catch (error) {
    yield put(logoutUserFailure(error));
  }
}

/*
function* socialLogin({ payload: { type, history } }) {
  try {
   
    const response = yield call(socialLogin, type);

    if (response.status !== 200 && response.status !== 201) {
      throw new Error(response.data.message || 'Failed to register');
    }

    // Setting the JWT token in localStorage
    localStorage.setItem("authUser", JSON.stringify(response.data));
    yield put(loginSuccess(response.data));
    yield put(fetchUserData());
    history('/dashboard');

  } catch (error) {
    console.log("[store/auth/login/saga.js] Error in socialLogin()", error);
    yield put(apiError(error?.response?.data?.message));
  }
}
*/

function* authSaga() {
  yield takeLatest(LOGIN_USER, loginUser);
  yield takeLatest(LOGOUT_USER, logoutUser);
  // yield takeLatest(SOCIAL_LOGIN, socialLogin);
}

export default authSaga;
