import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Col,
  Row,
} from "reactstrap";

// Import Images
import placeholder from "assets/images/placeholders/placeholder_card.png"


const UrlListModal = ({ urlListModal, toggleUrlListModal, urlList }) => {

  return (
    <React.Fragment>


      {/* 智能客服編輯視窗 */}
      <Modal isOpen={urlListModal} toggle={toggleUrlListModal}
        style={{
          display: 'flex',
          justifyContent: 'center',
          minWidth: '70vw'
        }}
      >

        {/* 專案編輯視窗標題 */}
        <ModalHeader toggle={toggleUrlListModal} tag="h4">
          {`試試看從這些 url 生成智能客服的效果吧！`}
        </ModalHeader>

        {/* 專案編輯視窗內容 */}
        <ModalBody>

          <Row>
            {Object.entries(urlList).map(([name, url]) => (
              <Row key={name}>
                <Col sm={4}>
                  <p style={{ wordWrap: 'break-word' }}>{name}</p>
                </Col>
                <Col sm={8}>
                  <p style={{ wordWrap: 'break-word' }}>{url}</p>
                </Col>
              </Row>
            ))}
          </Row>

        </ModalBody>

      {/* ^專案編輯視窗 */}
      </Modal>
    </React.Fragment>
  );

};

export default UrlListModal;
