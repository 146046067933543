import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Col,
  Row,
} from "reactstrap";

// Import additional Libraries
import * as Yup from "yup";
import { useFormik } from "formik";

// Custom Axios
import axiosInstance from 'utils/axiosInstance';

// Redux
import { useSelector, useDispatch } from "react-redux";

// Import Images
import placeholder from "assets/images/placeholders/placeholder_card.png"


const EditModal = ({ offer, offerImage, editModal, toggle }) => {

  const dispatch = useDispatch();
  const [htmlContent, setHtmlContent] = useState('');
  const [purchaseLoading, setPurchaseLoading] = useState(false);

  // state.projects.projects is set through fetchUserData
  const { userData } = useSelector(state => ({
    userData: state.user.userData,
  }));

  const sendPurchase = async()=>{
    const purchaseData = {
      offer,
      buyVolume
    }

    const emailPurchase=userData?.email;

    try {
      setPurchaseLoading(true);
      const response = await axiosInstance.post(
        `https://service.bytelingo.co/purchase-point/${emailPurchase}`,
        {purchaseData}
      );

      setHtmlContent(response.data); // Set the HTML content in the state

      // Trigger the script manually
      setTimeout(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = "document.getElementById('_form_aiochk').submit();";
        document.body.appendChild(script);
      }, 100);

      setPurchaseLoading(false);

    } catch (error) {
      console.error('Error:', error);
      setPurchaseLoading(false);
    }


  }

  const [buyVolume, setBuyVolume] = useState(1);
  useEffect(()=>{
    setBuyVolume(1);
  },[editModal])


  return (
    <React.Fragment>


      {/* 專案編輯視窗 */}
      <Modal isOpen={editModal}>

        {/* 專案編輯視窗標題 */}
        <ModalHeader toggle={()=>{toggle()}} tag="h4">購買點數</ModalHeader>

        {/* 專案編輯視窗內容 */}
        <ModalBody>

          <Form
            onSubmit={(e) => {
              e.preventDefault();
              return false;
            }}
          >
            <Row>
              <Col xs={12}>

                {/* 資料庫名稱 */}
                <div className="mb-3">
                  <h5 className="form-label"
                    style={{ fontSize: '1rem' }}
                  >點數單位：{offer?.offerUnit}</h5>
                </div>

                <div style={{ display:"flex", justifyContent:"center", margin:"1rem 0rem" }}>
                  <img 
                    src={offerImage || placeholder} 
                    alt="offerImage" 
                    style={{ 
                        width: '100px', 
                        height: '100px',
                        borderRadius: '50%',
                        objectFit: 'cover'
                    }}
                  />
                </div>

                {/* 資料庫客戶 */}
                <div className="mb-3">
                  <div className="mt-4">
                    <h5 className="font-size-14">選擇購買點數數量：</h5>
                    <p className="card-title-desc">{buyVolume*offer?.offerUnit || 0}</p>
                    <input type="range" className="form-range" min={offer?.minVolume || 1} max={offer?.maxVolume || 10} id="customRange2" 
                      onChange={(e)=>{setBuyVolume(e.target.value)}}
                      defaultValue="1"
                    />
                  </div>
                </div>

                {/* 資料庫圖片 */}
                <div className="mb-3">
                  <h5 className="font-size-14"
                    style={{ margin:'2vh 0vh 2vh 0vh'}}
                  >{ `<相當於>`}</h5>
                  <p style={{ margin:'1vh 0vh 1vh 0vh'}}>翻譯字數：{buyVolume*offer?.offerUnit} 字，或</p>
                  <p style={{ margin:'1vh 0vh 1vh 0vh'}}>智能客服對話：{buyVolume*offer?.offerUnit*10} 字</p>
                </div>


              </Col>
            </Row>

            {/* Save Button */}
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    onClick={()=>{sendPurchase();}}
                    className="btn btn-success save-user"
                  >
                    <p style={{ margin: '0vh 0vw 0vh 0vw', display: 'inline-block' }}>購買</p>
                    {purchaseLoading && 
                      (<i 
                        className="bx bx-loader bx-spin font-size-16 align-middle"
                        style={{ margin: '0vh 0vw 0vh 0.5vw', display: 'inline-block' }}
                      >
                      </i>)
                    }
                  </button>
                </div>
              </Col>
            </Row>

          </Form>

          <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>


        </ModalBody>

      {/* ^專案編輯視窗 */}
      </Modal>
    </React.Fragment>
  );

};

export default EditModal;
