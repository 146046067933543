import {
  CLEAR_FORGET_PASSWORD,
  CLEAR_FORGET_PASSWORD_SUCCESS,
  CLEAR_FORGET_PASSWORD_FAILED,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
} from "./actionTypes"

export const clearForgetPassword = () => {
  return {
    type: CLEAR_FORGET_PASSWORD,
  }
}

export const clearForgetPasswordSuccess = message => {
  return {
    type: CLEAR_FORGET_PASSWORD_SUCCESS,
  }
}

export const clearForgetPasswordFailed = error => {
  return {
    type: CLEAR_FORGET_PASSWORD_FAILED,
    payload: error,
  }
}

export const userForgetPassword = (user, history) => {
  return {
    type: FORGET_PASSWORD,
    payload: { user, history },
  }
}

export const userForgetPasswordSuccess = message => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const userForgetPasswordError = message => {
  return {
    type: FORGET_PASSWORD_ERROR,
    payload: message,
  }
}
