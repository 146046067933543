import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import withRouter from "components/Common/withRouter";

//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";

import { Link } from "react-router-dom";

import logo from "../../assets/images/logo.svg";
import logoLightPng from "../../assets/images/thechart-logo.png";
import logoLightSvg from "../../assets/images/logo-light.svg";
import logoDark from "../../assets/images/logo-dark.png";
import logoShip from "../../assets/images/thechart-logoship.png";
import logoSmallLightSvg from "../../assets/images/thechart-logosvg-light.png";

const Sidebar = props => {

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box" style={{ "paddingLeft": "0.8rem" }}>

          {/* small logo small screen -> Header.js */}

          <Link to="/" className="logo logo-dark">
            {/* small logo big screen (when collapsed) */}
            <span className="logo-sm">
              <img src={logoSmallLightSvg} alt="" style={{ height: "2.2rem", marginLeft: "0.3rem" }} />
            </span>
            {/* big logo small screen */}
            <span className="logo-lg">
              <img src={logoShip} alt="" height="45" />
            </span>
          </Link>

          <Link to="/" className="logo logo-light">
            {/* small logo big screen (when collapsed) */}
            <span className="logo-sm">
              <img src={logoSmallLightSvg} alt="" style={{ height: "2.2rem", marginLeft: "0.3rem" }} />
            </span>
            {/* big logo big screen */}
            <span className="logo-lg">
              <img src={logoShip} alt="" height="45" />
            </span>
          </Link>

        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  };
};
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)));
