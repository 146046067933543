import React, { useState } from 'react';
import { 
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

const MultipleChoiceQuestion = ({ item, onChange, onClick, isOpen, onClose, onSave }) => {
  const [updateItem, setUpdateItem] = useState(item);

  const handleChange = ({ target: { name, value } }) => {
    setUpdateItem(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSaveQuestion = () => {
    onSave(updateItem);
    onClose();
  };

  const handleDeleteOption = index => {
    setUpdateItem(prevState => ({
      ...prevState,
      options: prevState.options.filter((_, i) => i !== index)
    }));
  };

  const handleOptionChange = (index, value) => {
    const newOptions = updateItem.options.map((option, i) => 
      i === index ? { ...option, text: value } : option
    );
    setUpdateItem(prevState => ({ ...prevState, options: newOptions }));
  };

  const addOption = () => {
    setUpdateItem(prevState => ({
      ...prevState,
      options: [...prevState.options, { text: `Option ${prevState.options.length + 1}`, direction: '' }]
    }));
  };

  return (
    <>
      <div className="multiple-choice-question" onClick={onClick}>
        <Row style={{ padding: '2vh 3vw' }}>
          <Col xs="3" style={{ display: 'flex', alignItems: 'center' }}>
            <p className="question-text" style={{ margin: '0' }}>{item.questionName}</p>
          </Col>
          <Col xs="9" style={{ padding: '0' }}>
            <div className="options-container">
              {item.options.map((option, index) => (
                <div key={index} className="option" style={{ display: 'flex' }}>
                  <input 
                    type="radio" 
                    id={`${item._id}-option-${index}`} 
                    name={item._id} 
                    value={option.text}
                    onChange={(e) => onChange(item._id, e.target.value)}
                    style={{ marginRight: '1vw' }}
                  />
                  <label htmlFor={`${item._id}-option-${index}`} style={{ margin: '0' }}>{option.text}</label>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </div>

      <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeader toggle={onClose}>編輯問題</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="questionName">問題名稱</Label>
            <Input 
              type="text" 
              name="questionName" 
              id="questionName" 
              value={updateItem.questionName} 
              onChange={handleChange} 
            />
          </FormGroup>

          <FormGroup>
            <Label for="options">選項</Label>
            {updateItem.options.map((option, index) => (
              <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                <Input
                  type="text"
                  name={`option-${index}`}
                  value={option.text}
                  onChange={(e) => handleOptionChange(index, e.target.value)}
                  style={{ marginRight: '1rem' }}
                />
                <Button color="outline-danger" onClick={() => handleDeleteOption(index)}>-</Button>
              </div>
            ))}
            <Button onClick={addOption}>增加選項</Button>
          </FormGroup>

        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSaveQuestion}>保存</Button>
          <Button color="secondary" onClick={onClose}>取消</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default MultipleChoiceQuestion;

