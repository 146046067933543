import {
  CLEAR_CHANGE_PASSWORD,
  CLEAR_CHANGE_PASSWORD_SUCCESS,
  CLEAR_CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
} from "./actionTypes"

const initialState = {
  changePasswordSuccessMsg: null,
  changePasswordError: null,
  loading: false,
}

const changePassword = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_CHANGE_PASSWORD:
      state = { ...state }
      break
    case CLEAR_CHANGE_PASSWORD_SUCCESS:
      state = {
        ...state,
        changePasswordSuccessMsg: null,
        changePasswordError: null,
      }
      break
    case CLEAR_CHANGE_PASSWORD_FAILED:
      state = {
        ...state,
        changePasswordSuccessMsg: null,
        changePasswordError: action.payload,
      }
      break
    case CHANGE_PASSWORD:
      state = { 
        ...state, 
        changePasswordSuccessMsg: null,
        changePasswordError: null,
        loading: true,
      };
      break;
    case CHANGE_PASSWORD_SUCCESS:
      state = { 
        ...state, 
        changePasswordSuccessMsg: action.payload,
        changePasswordError: null,
        loading: false,
      };
      break;
    case CHANGE_PASSWORD_ERROR:
      state = { 
        ...state,
        changePasswordSuccessMsg: null,
        changePasswordError: action.payload,
        loading: false,
      };
      break;
    default:
      state = { ...state }
      break
  }
  return state
}

export default changePassword;
