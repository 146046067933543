import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";

import ApexRadial from "./ApexRadial";

// redux
import { useSelector } from "react-redux";

import { toNumber } from "lodash";

/*---- Current Month Component ----*/
const CurrentMonthComponent = () => {

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;

  // Format the date as 'YYYY 年 M 月'
  const formattedDate = `${year} 年 ${month} 月`;
  return ( <p className="text-muted">{formattedDate} - 全平台用戶用量：</p> );
};
/*---- Current Month Component Tail ----*/



const MonthlyEarning = () => {

  const [availWordPercentage, setAvailWordPercentage] = useState(0);
  const [availProjectPercentage, setAvailProjectPercentage] = useState(0);
  const [translationWordCount, setTranslationWordCount] = useState(0);
  const [translationChangePercentage, setTranslationChangePercentage] = useState(0);
  const [conversationWordCount, setConversationWordCount] = useState(0);
  const [conversationChangePercentage, setConversationChangePercentage] = useState(0);

  const userData = useSelector(state => state.user.userData);

  useEffect(() => {
    if (userData?.stats?.wordThisMonth != null && userData?.projects?.length != null) {
      const packageWordCount = userData.isPremiumUser ? 50000 : 3000;
      const wordPercentage = (userData.stats.wordThisMonth / packageWordCount) * 100;
      setAvailWordPercentage(wordPercentage);

      const packageProjectLimit = userData.isPremiumUser ? 100 : 10;
      const projectPercentage = ((packageProjectLimit-userData.projects.length) / packageProjectLimit) * 100;
      setAvailProjectPercentage(projectPercentage);
    }
    if (userData) {
      const { monthlyTranslationWordCount, translationWordCountMonthlyChangePercentage, monthlyConversationWordCount, conversationWordCountMonthlyChangePercentage } = userData;
      setTranslationWordCount(monthlyTranslationWordCount);
      setTranslationChangePercentage(toNumber(translationWordCountMonthlyChangePercentage));
      setConversationWordCount(monthlyConversationWordCount);
      setConversationChangePercentage(toNumber(conversationWordCountMonthlyChangePercentage));
      
    }

  }, [userData]);

  const performance = (num) => {
    if (num > 0) {
      return (<span className="text-success me-2" style={{ marginLeft:'0.4vw' }}>
        {" "}
        {num}% <i className="mdi mdi-arrow-up"></i>{" "}
      </span>);
    } else if (num < 0) {
      return (<span className="text-danger me-2" style={{ marginLeft:'0.4vw' }}>
        {" "}
        {num*-1}% <i className="mdi mdi-arrow-down"></i>{" "}
      </span>);
    } else {
      return (<span className="text-primary me-2" style={{ marginLeft:'0.4vw' }}>
        {" "} <i className="mdi mdi-minus"></i>{" "}
      </span>);
    }
  }

  return (
    <React.Fragment>
      {" "}
      <Card>
        <CardBody>
          <CardTitle className="mb-4">本月全平台使用量總覽</CardTitle>
          <Row>
            <Col sm="12">

              <CurrentMonthComponent />

              <hr style={{ borderTop: "1px solid #979797" }} />

              <h5>翻譯字數：{translationWordCount} 字</h5>

              <p className="text-muted">
                與上月相比{" "}
                {performance(translationChangePercentage)}
              </p>

              <hr style={{ borderTop: "1px solid #979797" }} />

              <h5>對話字數：{conversationWordCount} 字</h5>

              <p className="text-muted">
                與上月相比{" "}
                {performance(conversationChangePercentage)}
              </p>

              <hr style={{ borderTop: "1px solid #979797" }} />

            </Col>

            <Col sm="12">
              <p className="text-muted" style={{ margin:'2vh 0vw 1vh 0vw' }}>我的用量：</p>
            </Col>

            <Col sm="12" 
              style={{ 
                padding:"0rem", 
                margin: "-0.5rem 0rem",
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >

              <div className="mt-4 mt-sm-0" style={{ marginRight:'1rem' }}>
                <ApexRadial
                  label={`本月剩餘方案字數`}
                  dataColors='["--bs-primary"]' 
                  seriesPercentage={parseFloat(availWordPercentage).toFixed(2)}
                />
              </div>

              <div className="mt-4 mt-sm-0">
                <ApexRadial 
                  label={`建立專案數量`}
                  dataColors='["--bs-success"]' 
                  seriesPercentage={parseFloat(availProjectPercentage).toFixed(2)}
                />
              </div>

            </Col>

          </Row>

          <Link to="https://bytelingo.co/pricing" target="_blank" className="text-dark">
            <CardTitle className="text-muted mb-0" style={{ margin: "1.5rem 0rem" }}>
              了解方案用量{" "}
              <div className="arrow-icon">
                <i className="fas fa-arrow-right"></i>
              </div>
            </CardTitle>
          </Link>

        </CardBody>
      </Card>
    </React.Fragment>
  );
};
//TheChart.AI 使 AI 進入企業的生產流程
export default MonthlyEarning;


