import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Col,
  Row,
  Button,
  Card,
  CardBody,
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter, 
} from "reactstrap";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";

import {
  Date,
  Masterknowledge,
  Masterquestion,
  TimeStamp,
} from "./LatestTransactionCol";

import axiosInstance from "utils/axiosInstance";

import TableContainer from "../../components/Common/TableContainer";
import './custom.css';

const orderedKeys = ["masterquestion", "masterknowledge"];
const keyToLabelMapping = {
  masterquestion: "提問",
  masterknowledge: "參考資料內容"
};


// Main LatestTransaction Component
const LatestTransaction = props => {


  /*---- Initial States ----*/

  const { allData } = props;

  const filteredData = useMemo(() => 
    allData.map((dataEntry) => ({
      id: dataEntry._id,
      masterquestion: dataEntry.question,
      masterknowledge: dataEntry.knowledgecontent,
    })
  ), [allData]);

  const [newDataEntry, setNewDataEntry] = useState("");
  const [responseStatus, setResponseStatus] = useState("inactive"); // retrieving / inactive
  const [userWarning, setUserWarning] = useState("");

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [originalModalValue, setOriginalModalValue] = useState("");
  const [modalInputValue, setModalInputValue] = useState("");

  // multi-factor originalModalValue
  const [modalData, setModalData] = useState({
    masterquestion: "",
    masterknowledge: "",
  });

  const editingDataRef = useRef({ id: null, dataContent: "" });
  const selectOptionsMapping = {};

  /*---- Initial States Tail ----*/



  /*---- Auto Resize Modal TextAreas ----*/

  const textareaRefs = useRef({});
  const adjustHeight = (key) => {
    if (textareaRefs.current[key]) {
      const textarea = textareaRefs.current[key];
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
      if(textarea.scrollHeight<54){
        textarea.style.height = `30px`;
      }
    }
  }
  useEffect(() => {
    setTimeout(() => {
      Object.keys(modalData).forEach(key => {
        adjustHeight(key);
      });
    }, 0); 
  }, [modalData, isEditModalOpen]);

  /*---- Auto Resize Modal TextAreas Tail ----*/



  /*---- Auto Resize New Entry Input TextAreas ----*/

  function useAutoResize(initialValue = "") {
    const [value, setValue] = useState(initialValue);
    const textareaRef = useRef(null);

    const adjustTextareaHeight = () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto"; 
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; 
      }
    };

    useEffect(() => { setValue(initialValue) }, [initialValue]);
    useEffect(() => { adjustTextareaHeight() }, [value]);

    return {
      value,
      onChange: (e) => {
        setValue(e.target.value);
        setModalInputValue(e.target.value);
        adjustTextareaHeight();
      },
      textareaRef,
    };
  }

  /* masterquestion */
  const masterquestionProps = useAutoResize(newDataEntry);
  const { textareaRef: masterquestionRef, ...masterquestionInputProps } = masterquestionProps;

  /* masterknowledge */
  const masterknowledgeProps = useAutoResize(newDataEntry);
  const { textareaRef: masterknowledgeRef, ...masterknowledgeInputProps } = masterknowledgeProps;

  /*---- Auto Resize New Entry Input TextAreas Tail ----*/



  /*---- Submit new Data Entry ----*/

  const onSubmitData = async (e) => {
    e.preventDefault();

    const payload = {
      question: masterquestionProps.value,
      knowledgecontent: masterknowledgeProps.value,
    };

    console.log("Sending to server:", payload);

    // Returns true if all values in the object are non-empty, otherwise false
    const allFieldsFilled = Object.values(payload).every(value => value && value.trim() !== "");

    if (allFieldsFilled) {
      setResponseStatus('retrieving');

      try {
        const response = await axiosInstance.post(`/knovia/knowledges`, payload);
        setResponseStatus('inactive');
        props.refreshData();
        setNewDataEntry("");
      } catch (err) {
        console.error(err);
      }
    } else {
      setUserWarning("All fields must be filled in!");
    }
  }

  /*---- Submit new Data Entry Tail ----*/



  /*---- Update and Delete existing Data Entries ----*/

  const updateDataEntry = async (id, updatedRowData) => {

    console.log('id:',id)
    console.log('updatedRowData', updatedRowData)

    try {
      const response = await axiosInstance.put(`/knovia/knowledges/${id}`, {
        question: updatedRowData.masterquestion,
        knowledgecontent: updatedRowData.masterknowledge
      });
      console.log(response.data.message);
      props.refreshData();
    } catch (err) {
      console.error(err);
    }

  }

  const deleteDataEntry = async (id, content) => {

    console.log("Deleting ID:",id);

    try {
      const response = await axiosInstance.delete(`/knovia/knowledges/${id}`);
      console.log(response.data.message);
      props.refreshData();
    } catch (err) {
      console.error(err);
    }
  }

  /*---- Update and Delete existing Data Entries ----*/



  /*---- Data Entries Table ----*/

  const columns = useMemo(
    () => [
      {
        Header: "#",
        filterable: false,
        disableFilters: true,
        width: 50,
        Cell: cellProps => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "提問（選用）",
        accessor: "masterquestion",
        disableFilters: true,
        width: 150,
        filterable: false,
        Cell: cellProps => {
          return <Masterquestion {...cellProps} />;
        },
      },
      {
        Header: "企業大師參考資料",
        accessor: "masterknowledge",
        disableFilters: true,
        width: 300,
        filterable: false,
        Cell: cellProps => {
          return <Masterknowledge {...cellProps} />;
        },
      },
      {
        Header: "刪除",
        disableFilters: true,
        width: 60,
        Cell: cellProps => {
            return (
                <button 
                    className="btn btn-outline-danger"
                    onClick={() => deleteDataEntry(cellProps.row.original.id)}
                    style={{
                        height: '2rem',
                        width: '2rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 0,  // Set to 0 to override any default padding
                        marginLeft: 'calc(50% - 16px)',
                    }}
                >
                    -
                </button>
            );
        },
      },
      {
        Header: "編輯",
        disableFilters: true,
        width: 60,
        Cell: cellProps => {
            return (
              <button 
                  className="btn btn-outline-info"
                  onClick={() => {

                      editingDataRef.current.id = cellProps.row.original.id;
                      // Show user the original content before editing
                      setOriginalModalValue(cellProps.row.original.dataContent);

                      setModalData({
                        masterquestion: cellProps.row.original.masterquestion,
                        masterknowledge: cellProps.row.original.masterknowledge,
                      });

                      // Set the initial editing textarea value same as original content
                      setModalInputValue(cellProps.row.original.dataContent);
                      // Open the Modal
                      setIsEditModalOpen(true);
                      // Adjust heights immediately
                      Object.keys(modalData).forEach(key => {
                        adjustHeight(key);
                      });

                  }}
                  style={{
                    height: '2rem',
                    width: '2rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 0,  // Set to 0 to override any default padding
                    marginLeft: 'calc(50% - 16px)',
                  }}
              >
                <i className="fas fa-pen"></i>
              </button>
            );
        },
      },
    ],
    []
  );


  // Return Component
  return (
    <React.Fragment>


      {/*---- Modal for editing dataentries ----*/}
      <Modal isOpen={isEditModalOpen} toggle={() => setIsEditModalOpen(!isEditModalOpen)}>
        {/* Modal Header */}
        <ModalHeader toggle={() => setIsEditModalOpen(!isEditModalOpen)}>編輯企業大師參考資料</ModalHeader>
        {/* Modal Body */}
        <ModalBody>

            {orderedKeys.map((key) => (
              <div key={key} style={{ marginBottom: "15px" }}>
                <p>{keyToLabelMapping[key] || key}:</p>
                
                {/* Conditionally render select or textarea */}
                {selectOptionsMapping[key] ? (
                  <select
                    value={modalData[key]}
                    onChange={(e) => {
                      setModalData((prevData) => ({
                        ...prevData,
                        [key]: e.target.value
                      }));
                    }}
                    className="form-control"
                  >
                    {selectOptionsMapping[key].map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                ) : (
                  <textarea
                    ref={(el) => { textareaRefs.current[key] = el; }}
                    value={modalData[key]}
                    onChange={(e) => {
                      setModalData((prevData) => ({
                        ...prevData,
                        [key]: e.target.value
                      }));
                    }}
                    style={{ width: "100%", resize: 'none', overflow: 'hidden' }}
                    className="form-control"
                  />
                )}
                
              </div>
            ))}
        </ModalBody>

        {/* Modal Footer */}
        <ModalFooter>
            <Button 
                color="primary" 
                onClick={() => {
                    updateDataEntry(editingDataRef.current.id, modalData);
                    setIsEditModalOpen(false);
                }}
            >
                儲存變更
            </Button>
            <Button color="secondary" onClick={() => setIsEditModalOpen(false)}>取消變更</Button>
        </ModalFooter>
      </Modal>

      {/*---- Modal for editing dataentries Tail ----*/}


      {}
      <Card>
        <CardBody>

          {/* TextArea for Inputing new dataentry */}
          <Row style={{'marginBottom':'1rem'}}>
            <div className="mb-4 h4 card-title">有什麼關於平台操作的問題，問問 AI 客服吧！</div>
          </Row>

          {/* 提問輸入 */}
          <Row className="datainput-row">
            <Col sm={2}>
              <div className="mb-4 h4 card-title">範例提問</div>
            </Col>
            <Col sm={7}>
              <div className="toast fade show" style={{"width": "100%", "border": "1px solid var(--bs-input-border-color)", "boxShadow": "none"}} role="alert">

                <div className="toast-body" style={{ 'minHeight':'4rem' }}>
                <textarea
                  ref={masterquestionRef}
                  style={{ 'minHeight':'0rem', 'border':'none', 'resize': 'none' }}
                  className="form-control" 
                  id="newdataentry"
                  {...masterquestionInputProps}
                />
                </div>
              </div>
            </Col>

          </Row>

          {/* 知識輸入 */}
          <Row className="datainput-row">
            <Col sm={2}>
              <div className="mb-4 h4 card-title">相關知識</div>
            </Col>
            <Col sm={7}>
              <div className="toast fade show" style={{"width": "100%", "border": "1px solid var(--bs-input-border-color)", "boxShadow": "none"}} role="alert">

                <div className="toast-body">
                <textarea
                  ref={masterknowledgeRef}
                  style={{ 'minHeight':'0rem', 'border':'none', 'resize': 'none' }}
                  className="form-control" 
                  id="newdataentry"
                  {...masterknowledgeInputProps}
                />
                </div>
              </div>
            </Col>

            <Col sm={3}>
              <button type="button" onClick={onSubmitData} className="btn btn-outline-success">添加資料</button>
            </Col>

          </Row>



          {/*---- Table for Showing all dataentries ----*/}

          <Row style={{"marginTop":"2rem"}}>
            <Col>
              {filteredData?
                (<TableContainer
                  columns={columns}
                  data={filteredData}
                  isGlobalFilter={true}
                  isAddOptions={false}
                  customPageSize={10}
                />)
              :<p>loading...</p>}
            </Col>
          </Row>

          {/*---- Table for Showing all dataentries Tail ----*/}


        </CardBody>
      </Card>
    </React.Fragment>
  );
};

LatestTransaction.propTypes = {
  orders: PropTypes.array,
};

export default withRouter(LatestTransaction);
