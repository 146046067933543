import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import PropTypes from "prop-types";

//i18n
import { withTranslation } from "react-i18next";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { getChatbots, setChatbotImage } from "store/actions";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
// Pages Components
import ActivityComp from "./ActivityComp";
import { fetchImage } from 'utils/imageUtils';
import placeholder from "assets/images/placeholders/placeholder_card.png"


// Main Chatbot-User Component
const Dashboard = props => {

  const { chatbotId } = useParams();


  /*---- Title and Setup ----*/
  document.title = "客製化 AI 智能客服 | TheChart.AI";
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: 'GET_CHATBOTS' });
  }, [dispatch]);
  /*---- Title and Setup Tail ----*/


  /*---- Getting Chatbots from Redux ----*/

  const [thisCb, setThisCb] = useState({});
  const { chatbots, chatbotImages } = useSelector(state => ({
    chatbots: state.chatbots.chatbots,
    chatbotImages: state.chatbots.chatbotImages,
  }));

  const fetchImageAndUpdateChatbots = useCallback((cb) => {
    return fetchImage(cb.img || '')
      .then(url => {
        dispatch(setChatbotImage(cb._id, url));
      })
      .catch(error => {
        console.error('Error fetching the image:', error);
        dispatch(setChatbotImage(cb._id, placeholder));
      });
  }, [dispatch]);

  useEffect(() => { 
    console.log('Chatbots_changed...', chatbots);
    if(chatbots.length>0){ setThisCb(chatbots.filter(c=>c._id===chatbotId)[0]); }
  }, [chatbots]);
  useEffect(() => { 
    console.log('This Chatbot:', thisCb);
    fetchImageAndUpdateChatbots(thisCb);
  }, [thisCb]);

  /*---- Getting Chatbots from Redux Tail ----*/


  return (
    <React.Fragment>

      <div className="page-content">
        <Container fluid>

          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("功能")}
            breadcrumbItem={props.t(`客製化智能客服：{ ${thisCb.cbName} }`)}
          />

          {/* ActivityComp */}
          <Row>
            <Col xl="12">
              <ActivityComp chatbotId={chatbotId} chatbotImg={chatbotImages[chatbotId] || placeholder} thisCb={thisCb} />
            </Col>
          </Row>

        </Container>
      </div>

    </React.Fragment>
  );
};

Dashboard.propTypes = { t: PropTypes.any };

export default withTranslation()(Dashboard);
