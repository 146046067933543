import React, { useMemo, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from "reactstrap";
// Custom Axios
import axiosInstance from 'utils/axiosInstance';

const orderedKeys = ["masterquestion", "masterknowledge"];
const keyToLabelMapping = {
  masterquestion: "提問",
  masterknowledge: "參考資料內容"
};

/*---- Main Modal Component ----*/
const KnowledgeEditModal = ({ 
  isEditModalOpen, 
  setIsEditModalOpen, 
  textareaRefs,
  questionRefs,
  contentRefs,
  editingDataRef,
  subknowledges,
  setSubknowledges,
  modalData, 
  setModalData,
  updateDataEntry,
  viewSampleQA,
  generateSubknowledge,
  generateSubknowledgeLoading
}) => {  

  const addSubknowledge = () => {
    setSubknowledges([
      ...subknowledges,
      { question: "", knowledgecontent: "" }
    ]);
  };

  const deleteSubknowledge = (index) => {
    setSubknowledges(subknowledges.filter((_, idx) => idx !== index));
  };

  return (
    <React.Fragment>
      {/*---- Modal for editing dataentries ----*/}
      <Modal isOpen={isEditModalOpen} toggle={() => setIsEditModalOpen(!isEditModalOpen)}>
        {/* Modal Header */}
        <ModalHeader toggle={() => setIsEditModalOpen(!isEditModalOpen)}>編輯智能客服參考資料</ModalHeader>
        {/* Modal Body */}
        <ModalBody>
          {/* 提問與參考資料內容 */}
          {orderedKeys.map((key) => (
            <div key={key} style={{ marginBottom: "15px" }}>
              <p>{keyToLabelMapping[key] || key}:</p>
              <textarea
                ref={(el) => { textareaRefs.current[key] = el; }}
                value={modalData[key]}
                onChange={(e) => {
                  setModalData((prevData) => ({
                    ...prevData,
                    [key]: e.target.value
                  }));
                }}
                style={{ width: "100%", resize: 'none', overflow: 'hidden' }}
                className="form-control"
              />
            </div>
          ))}

          {/* 分隔線 */}
          {!viewSampleQA && subknowledges?.length > 0 && (<div style={{ margin: "5vh 0vw", borderBottom: "1px solid #bdbdbd" }}></div>)}

          {/* 範例問答 */}
          {!viewSampleQA && subknowledges.map((subknowledge, index) => (
            <Row key={`subknowledge_${index}`} style={{ marginBottom: "5vh" }}>
              <Col sm="10">
                <p style={{ marginBottom: '1.5vh' }}>{`範例提問${index + 1}`}</p>
                <textarea
                  ref={(el) => { questionRefs.current[`question_${index}`] = el; }}
                  value={subknowledge.question}
                  onChange={(e) => {
                    const updatedSubknowledges = subknowledges.map((sk, idx) =>
                      idx === index ? { ...sk, question: e.target.value } : sk
                    );
                    setSubknowledges(updatedSubknowledges);
                  }}
                  style={{ width: "100%", resize: 'none', overflow: 'hidden' }}
                  className="form-control"
                />
                <p style={{ margin: '2vh 0vh 1.5vh 0vh' }}>{`範例回答${index + 1}`}</p>
                <textarea
                  ref={(el) => { contentRefs.current[`content_${index}`] = el; }}
                  value={subknowledge.knowledgecontent}
                  onChange={(e) => {
                    const updatedSubknowledges = subknowledges.map((sk, idx) =>
                      idx === index ? { ...sk, knowledgecontent: e.target.value } : sk
                    );
                    setSubknowledges(updatedSubknowledges);
                  }}
                  style={{ width: "100%", resize: 'none', overflow: 'hidden' }}
                  className="form-control"
                />
              </Col>
              <Col xm="2"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Button color="outline-danger" onClick={() => deleteSubknowledge(index)}>-</Button>
              </Col>
            </Row>
          ))}
        </ModalBody>

        {/* Modal Footer */}
        <ModalFooter>
          <Row style={{ width:'100%' }}>
            <Col sm="8">
              {!viewSampleQA && 
                <Button 
                  color="success" 
                  onClick={addSubknowledge}
                  style={{ marginRight:'1vw' }}
                >新增範例問答</Button>
              }
              <Button color="warning" onClick={generateSubknowledge}>
                {generateSubknowledgeLoading &&
                  (<div style={{ display:'inline-block', marginRight:'0.3rem' }}>
                    <i className="bx bx-loader bx-spin font-size-16 align-middle"></i>
                  </div>)
                }
                AI 生成問答
              </Button>
            </Col>
            <Col sm="4"
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button 
                color="primary" 
                onClick={() => {
                  const yes = confirm('確定要修改嗎？');
                  if (yes) {
                    updateDataEntry(editingDataRef.current.id, modalData);
                    setIsEditModalOpen(false);
                  }
                }}
                style={{ marginRight:'1vw' }}
              >
                儲存
              </Button>
              <Button color="secondary" onClick={() => setIsEditModalOpen(false)}>取消</Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
  {/*---- Modal for editing dataentries Tail ----*/}

};

export default KnowledgeEditModal;