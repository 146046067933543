import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { 
  userChangePassword
} from "../../store/actions";

import "./custom.css";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/placeholders/placeholder_card.png";

const changePasswordErrorMappings = {
  'Password reset token is invalid or has expired.': '重置密碼要求已過期'
}

const ResetPasswordPage = props => {

  const { token } = useParams();

  //meta title
  document.title = "重置密碼 | TheChart.AI";

  const dispatch = useDispatch();

  const validation = useFormik({

    enableReinitialize: true,

    initialValues: {
      password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(userChangePassword(values, token));
    }
  });

  const { changePasswordSuccessMsg, changePasswordError, loading } = useSelector(state => ({
    changePasswordError: state.ChangePassword.changePasswordError,
    changePasswordSuccessMsg: state.ChangePassword.changePasswordSuccessMsg,
    loading: state.ChangePassword.loading,
  }));

  return (
    <React.Fragment>

      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>

      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">

                {/* Visual */}
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">重置密碼</h5>
                        <p>輸入新密碼</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>

                <CardBody className="pt-0">

                  {/* Logo */}
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            style={{ height:"100%", width:"100%" }}
                          />
                        </span>
                      </div>
                    </Link>
                  </div>

                  <div className="p-2">
                    {changePasswordError && changePasswordErrorMappings[changePasswordError] ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {changePasswordErrorMappings[changePasswordError]}
                      </Alert>
                    ) : null}
                    {changePasswordSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {changePasswordSuccessMsg}
                      </Alert>
                    ) : null}

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">密碼</Label>
                        <Input
                          name="password"
                          className="form-control"
                          placeholder="新密碼"
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md "
                            type="submit"
                          >
                            {loading &&
                              (<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>)
                            }
                            重置密碼
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-5 text-center">
                <p>
                  回到{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    登入頁面
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} TheChart.AI Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> in Taipei
                </p>
              </div>

            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};

ResetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ResetPasswordPage);
