import {
  SET_PRODUCTS,
  SET_PRODUCT_IMAGE,

  LOAD_PRODUCT_IMAGES,
  LOAD_PRODUCT_IMAGES_SUCCESS,
  LOAD_PRODUCT_IMAGES_FAIL,

  GET_PRODUCTSET_SUCCESS,
  GET_PRODUCTSET_FAIL,

  ADD_NEW_PRODUCT,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAIL,

  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,

  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  products: [],
  productImages: {},

  loading: false,
  error: {},
};

const products = (state = INIT_STATE, action) => {

  switch (action.type) {


    /*--- SET PRODUCTS ---*/

    case SET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };

    case SET_PRODUCT_IMAGE:
      return {
        ...state,
        productImages: {
          ...state.productImages, 
          [action.payload.pdId]: action.payload.imgUrl,
        }
      };

    /*--- SET PRODUCTS TAIL ---*/



    /*--- LOAD PRODUCT IMAGES ---*/

    case LOAD_PRODUCT_IMAGES:
      return { ...state, loading: true };
    case LOAD_PRODUCT_IMAGES_SUCCESS:
      return { ...state, loading: false, productImages: action.payload };
    case LOAD_PRODUCT_IMAGES_FAIL:
      return { ...state, loading: false, error: action.payload };

    /*--- LOAD PRODUCT IMAGES TAIL ---*/



    /*--- GET PRODUCT ---*/

    case GET_PRODUCTSET_SUCCESS:
      return {
        ...state,
        products: action.payload,
      };

    case GET_PRODUCTSET_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    /*--- GET PRODUCT TAIL ---*/

    

    /*--- ADD PRODUCT ---*/

    case ADD_NEW_PRODUCT:
      return {
        ...state,
        loading: true
      };

    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        products: [...state.products, action.payload],
        loading: false
      };

    case ADD_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    /*--- ADD PRODUCT TAIL ---*/



    /*--- DELETE PRODUCT ---*/

    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: state.products.filter(
          product => product._id.toString() !== action.payload.toString()
        ),
      };

    case DELETE_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    /*--- DELETE PRODUCT TAIL ---*/



    /*--- UPDATE PRODUCT ---*/

    // Notice that the mapping won't show error when logic isn't right!
    case UPDATE_PRODUCT_SUCCESS:
      console.log("update product success processing...")
      return {
        ...state,
        products: state.products.map(product =>
          product._id.toString() === action.payload._id.toString()
            ? { ...product, ...action.payload }
            : product
        ),
      };

    case UPDATE_PRODUCT_FAIL:
      console.log("[store/product/reducer.js] Update product failed!");
      return {
        ...state,
        error: action.payload,
      };

    /*--- UPDATE PRODUCT TAIL ---*/

    default:
      return state;
  }

};

export default products;


