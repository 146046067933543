import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import axiosInstance from 'utils/axiosInstance';

// Login Redux States
import { 
  CLEAR_FORGET_PASSWORD,
  FORGET_PASSWORD,
} from "./actionTypes";
import { 
  clearForgetPassword,
  clearForgetPasswordSuccess,
  clearForgetPasswordFailed,
  userForgetPasswordSuccess, 
  userForgetPasswordError,
} from "./actions";

function* clearForgetUser() {
  try {
    yield put(clearForgetPasswordSuccess());
  } catch {
    yield put(clearForgetPasswordFailed());
  }
}

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {

  try{
    const response = yield call(axiosInstance.post, '/knovia/forgot-password', user);
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(response.data.message || 'Failed to send reset link');
    }
    // Dispatch success action
    yield put(userForgetPasswordSuccess("已將重置密碼連結傳送到您的信箱，請至信箱查看"));
  } catch (error) {
    console.log('error:', error);
    // Dispatch error action
    yield put(userForgetPasswordError(error.response?.data?.message || error.message));
  }

}

export function* watchUserPasswordForget() {
  yield takeEvery(CLEAR_FORGET_PASSWORD, clearForgetUser);
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga
