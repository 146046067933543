import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Container,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
} from "reactstrap";
import { map } from "lodash";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

// Import Card
import CardContact from "./card-contact";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { getProducts, setProductImage } from "store/actions";

// uuid
import { v4 as uuidv4 } from 'uuid';

// Custom Utils
import { Pagination } from 'utils/pagination';

// Custom Axios
import axiosInstance from 'utils/axiosInstance';

// Custom CSS
import './custom.css';
import placeholder from "assets/images/placeholders/placeholder_card.png"


const OrderGrid = () => {


  /*---- Title and Initial Setup ----*/

  document.title = "點餐介面模擬 | TheChart.AI";
  const dispatch = useDispatch();
  const [sortedProducts, setSortedProducts] = useState([]);

  /*---- Title and Initial Setup Tail ----*/



  /*---- Getting Products from Redux ----*/

  useEffect(() => {
    dispatch({ type: 'GET_PRODUCTSET' });
  }, [dispatch]);

  const { products, productImages, userData } = useSelector(state => ({
    products: state.products.products,
    productImages: state.products.productImages,
    userData: state.user.userData,
  }));

  useEffect(() => { console.log('Products_changed...', products) }, [products]);
  useEffect(() => { console.log('ProductImages_changed...', productImages) }, [productImages]);

  /*---- Getting Products from Redux Tail ----*/

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);



  /*---- Search Input ----*/

  const [searchValue, setSearchValue] = useState("");
  const [filterOption, setFilterOption] = useState('pdName');

  const filterOptionMappings = {
    'pdName': '產品名稱',
    'tags': '標籤',
  };

  // A generic filter function
  const applyFilter = (products) => {
    const searchLower = searchValue.toLowerCase();

    if (filterOption === 'tags') {
      // Check if any tag matches the search value
      return products.tags.some(tag => tag.toLowerCase().includes(searchLower));
    } else {
      // Handle other string-based filters
      const filterValue = products[filterOption] ? products[filterOption].toLowerCase() : '';
      return filterValue.includes(searchLower);
    }
  };

  /*---- Search Input Tail ----*/


  /*---- Sorting Function ----*/

  const [sortAttribute, setSortAttribute] = useState('customizations.sellPrice'); // default sort attribute
  const [sortDirection, setSortDirection] = useState('asc'); // 'asc' or 'desc'

  const sortDirectionMappings = { 'asc': '升序', 'desc': '降序' };
  const sortAttributeMappings = {
    'customizations.sellPrice': '價格',
    'pdName': '產品名稱',
  };

  // Function to toggle sort direction
  const toggleSortDirection = () => {
    setSortDirection(prevDirection => prevDirection === 'asc' ? 'desc' : 'asc');
  };

  // Function to sort the data
  const sortData = (a, b) => {
    let valueA, valueB;
    // Check if the sortAttribute is nested within 'customizations'
    if (sortAttribute.includes('customizations.')) {
      // Extract the nested attribute name
      const nestedAttr = sortAttribute.split('.')[1];
      valueA = a.customizations[nestedAttr];
      valueB = b.customizations[nestedAttr];
    } else {
      // For top-level attributes, access directly
      valueA = a[sortAttribute];
      valueB = b[sortAttribute];
    }

    // Perform the comparison based on the extracted values
    if (valueA < valueB) { return sortDirection === 'asc' ? -1 : 1; }
    if (valueA > valueB) { return sortDirection === 'asc' ? 1 : -1; }
    return 0; // Equal values, no sorting preference
  };

  /*---- Sorting Function Tail ----*/


  /*---- Pagination START ----*/

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [totalPages, setTotalPages] = useState(1);
  const [currentItems, setCurrentItems] = useState([]);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const itemsPerPageMappings = {'6': '6', '9': '9', '12': '12'}

  // Updating current items, items viewable in current page
  useEffect(() => {
    const newLastItem = currentPage * itemsPerPage;
    const newFirstItem = newLastItem - itemsPerPage;
    setCurrentItems(sortedProducts.slice(newFirstItem, newLastItem));
  }, [currentPage, itemsPerPage, sortedProducts]);
  // Set and Reset totalPage when paragraphs changes
  useEffect(() => { setTotalPages(Math.ceil(sortedProducts.length / itemsPerPage)) }, [sortedProducts, itemsPerPage]);
  // If itemsPerPage changed by user
  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(Number(value)); // event.target.value is the chosen value
    setCurrentPage(1); // Reset to the first page
  };

  /*---- Pagination TAIL ----*/


  useEffect(()=>{
    if(products.length){
      const newSortedProducts = products.filter(applyFilter).sort(sortData);
      console.log('newSortedProducts:', newSortedProducts);
      setSortedProducts(newSortedProducts);
    }
  }, 
    [
      products, 
      sortAttribute, 
      sortDirection, 
      searchValue, 
      filterOption
    ]
  );

  const searchValueMappings = {
    allItems: '所有品項',
    goodTea: '找好茶',
    milkTea: '找奶茶',
    latteTea: '找拿鐵',
    freshTea: '找新鮮',
    seasonTea: '季節限定',
  }

  const categoryClick = (e) =>{
    setFilterOption('tags');
    setCurrentPage(1);
    const thisSearchValue = e.currentTarget.dataset.search;
    if(searchValueMappings[thisSearchValue]==='所有品項'){
      setSearchValue('');
    } else {
      setSearchValue(searchValueMappings[thisSearchValue]);
    }
  }

  const [orderId, setOrderId] = useState('');
  const [shoppingCart, setShoppingCart] = useState([]);
  const [totalDollar, setTotalDollar] = useState(0);

  useEffect(()=>{
    setOrderId(uuidv4());
  }, [dispatch])

  const addNewItem = (newItem) => {
    console.log('newItem Added:', newItem);
    setShoppingCart([...shoppingCart, newItem]);
  }

  useEffect(()=>{
    console.log('ShoppingCart updated:', shoppingCart);
    let thisTotalDollar = 0;
    shoppingCart.map(s=>{
      const thisProduct = products.filter(p=>p.pdName===s.pdName)[0];
      console.log('thisProduct', thisProduct);
      if(!thisProduct){return;}
      const thisPrice = thisProduct.customizations.sellPrice;
      console.log('thisPrice:', thisPrice);
      thisTotalDollar+=Number(thisPrice);
    });
    setTotalDollar(thisTotalDollar);
  }, [shoppingCart])
  

  /*---- Return JSX ----*/

  return (
    <React.Fragment>

      <div className="page-content">
        <Container fluid>

          {/* Render Breadcrumbs */}
          <Breadcrumbs title="點餐介面" breadcrumbItem={`點餐介面模擬`} />

          <Row>
            <Col>
              <Link to={`/confirm-order/${orderId}`} className="text-dark">
                <div style={{
                  position: 'fixed',
                  top: '30vh',
                  right: '20px',
                  zIndex: '1000',
                  height: '7vh',
                  width: '25vh',
                  background: '#ffc61a',
                  borderRadius: '1rem',
                  color: 'white',
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignContent: 'center',
                  justifyContent: 'space-between',
                  padding: '0rem 1rem',
                  opacity: '0.93',
                }}>
                  <div style={{
                    position: 'absolute',
                    top: '-0.7rem',
                    right: '-0.5rem',
                    height: '1.5rem',
                    width: '1.5rem',
                    background: 'red',
                    borderRadius: '0.3rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    flexWrap: 'wrap',
                    fontSize: '0.8rem',
                  }}>
                    <p style={{ marginBottom:'0rem' }}>{shoppingCart.length}</p>
                  </div>
                  <p style={{ display:'inline', marginBottom: '0rem' }}> >> 前往購物車</p>
                  <p style={{ display:'inline', marginBottom: '0rem' }}>{`$ ${totalDollar}`}</p>
                </div>
              </Link>
            </Col>
          </Row>

          <Row>
            <Col sm="12" md="7" lg="7" xl="5" 
              style={{ 
                display:"flex"
              }}
            >

              {/* 篩選條件 Dropdown */}
              <div
                style={{ 
                display:"flex",
                flexWrap: "wrap",
                alignContent: "center",
                marginRight: "0.5rem"
              }}
              >
                <UncontrolledDropdown>
                  <DropdownToggle tag="button" className="btn btn-outline-info">
                    {`搜尋條件：${filterOptionMappings[filterOption]}`} 
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>

                  <DropdownMenu>
                    {Object.entries(filterOptionMappings).map(([key, label]) => (
                      <DropdownItem key={key} onClick={() => setFilterOption(key)}>
                        {label}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              {/* 篩選條件 Dropdown Tail */}


              {/* 輸入篩選 Input */}
              <div 
                className="search-box" 
                style={{
                  display: "flex",
                  alignContent: "center",
                  flexWrap: "wrap",
                  marginTop: "0.4rem"
                }}
              >
                <div className="position-relative">
                  <label htmlFor="search-bar-0" className="search-label">
                    <span id="search-bar-0-label" className="sr-only">Search this table</span>
                    <input 
                      id="search-bar-0" 
                      type="text" 
                      className="form-control" 
                      placeholder={`搜尋 ${products.length} 筆資料`}
                      value={searchValue}
                      onChange={(e)=>{
                        setSearchValue(e.target.value);
                        //console.log('search changed!', e.target.value);
                      }}
                    />
                  </label>
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
              </div>
              {/* 輸入篩選 Input Tail */}

            </Col>


            <Col sm="7" md="5" lg="5" xl="4" 
              className="custom-sorting-col"
              style={{ display: "flex" }}
            >

              <div 
                style={{ 
                  display: "flex",
                  flexWrap: "wrap",
                  alignContent: "center",
                  marginRight: "0.5rem"
                }}
              >
                {/* Dropdown for selecting sort attribute */}
                <UncontrolledDropdown>
                  <DropdownToggle tag="button" className="btn btn-outline-secondary">
                    排序條件: {sortAttributeMappings[sortAttribute]}
                  </DropdownToggle>
                  <DropdownMenu>
                    {Object.keys(sortAttributeMappings).map(key => (
                      <DropdownItem key={key} onClick={() => setSortAttribute(key)}>
                        {sortAttributeMappings[key]}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>

              {/* Dropdown for selecting sort direction */}
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignContent: "center",
                }}
              >
                <button className="btn btn-outline-secondary" onClick={toggleSortDirection}>
                  排序方向: {sortDirectionMappings[sortDirection]}
                </button>
              </div>

            </Col>


            <Col xs="12" sm="5" md="12" lg="12" xl="3"
              className="custom-item-per-page-col"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
              }}
            >
                {/* Dropdown for selecting sort attribute */}
                <UncontrolledDropdown>
                  <DropdownToggle tag="button" className="btn btn-outline-warning">
                    每頁顯示段落數：{itemsPerPage}
                  </DropdownToggle>
                  <DropdownMenu>
                    {Object.keys(itemsPerPageMappings).map(key => (
                      <DropdownItem key={key} onClick={() => handleItemsPerPageChange(key)}>
                        {itemsPerPageMappings[key]}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>

            </Col> 

            <Col xs="12" sm="12" md="12" lg="12" xl="12"
              className="custom-item-per-page-col"
              style={{
                margin: '1.5rem 0rem',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <Button 
                data-search="allItems" 
                color={searchValue==='所有品項'?'outline-danger':'outline-secondary'}
                onClick={(e)=>{categoryClick(e)}} 
                style={{ marginRight: '1rem', borderRadius: '3rem' }}
              >所有品項</Button>
              <Button 
                data-search="goodTea" 
                color={searchValue==='找好茶'?'outline-danger':'outline-secondary'}
                onClick={(e)=>{categoryClick(e)}} 
                style={{ marginRight: '1rem', borderRadius: '3rem' }}
              >找好茶</Button>
              <Button 
                data-search="milkTea" 
                color={searchValue==='找奶茶'?'outline-danger':'outline-secondary'}
                onClick={(e)=>{categoryClick(e)}} 
                style={{ marginRight: '1rem', borderRadius: '3rem' }}
              >找奶茶</Button>
              <Button 
                data-search="latteTea" 
                color={searchValue==='找拿鐵'?'outline-danger':'outline-secondary'}
                onClick={(e)=>{categoryClick(e)}} 
                style={{ marginRight: '1rem', borderRadius: '3rem' }}
              >找拿鐵</Button>
              <Button 
                data-search="freshTea" 
                color={searchValue==='找新鮮'?'outline-danger':'outline-secondary'}
                onClick={(e)=>{categoryClick(e)}} 
                style={{ marginRight: '1rem', borderRadius: '3rem' }}
              >找新鮮</Button>
              <Button 
                data-search="seasonTea" 
                color={searchValue==='季節限定'?'outline-danger':'outline-secondary'}
                onClick={(e)=>{categoryClick(e)}} 
                style={{ marginRight: '1rem', borderRadius: '3rem' }}
              >季節限定</Button>
            </Col> 
             
          </Row>

          {/* Div Margin */}
          <Row style={{ marginBottom:"1rem" }}>
            <Col sm="9"></Col>
          </Row>

          <Row>
            {map(currentItems, (pd, key) => (
              <CardContact 
                pd={pd} 
                pdImage={productImages[pd._id]} 
                orderId={orderId}
                key={"_pd_" + key} 
                addNewItem={addNewItem}
              />
            ))}
          </Row>

          {/* Pagination Controls */}
          <div style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
            <Pagination currentPage={currentPage} totalPages={totalPages} paginate={paginate} />
          </div>
          {/* Pagination Controls Tail */}

        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(OrderGrid);
