import React from "react";
import { Container, Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
//i18n
import { withTranslation } from "react-i18next";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
// Custom Component
import ActivityComp from "./ActivityComp";

// Index.js for Single Glossary Display and Edit
const Dashboard = props => {

  const { glossaryId } = useParams();

  document.title = "詞組對照資料庫 | TheChart.AI";

  return (
    <React.Fragment>

      <div className="page-content">

        <Container fluid>

          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("資料庫")}
            breadcrumbItem={props.t("詞組對照資料庫")}
          />

          {/* Main Page Content */}
          <Row>
            <Col xl="12">
              <ActivityComp glossaryId={glossaryId}/>  
            </Col>
          </Row>

        </Container>

      </div>

    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Dashboard);
