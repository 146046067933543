import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';

import { 
  Alert,
  Button,
  Card, 
  CardBody, 
  CardTitle, 
  Col, 
  Container, 
  Form, 
  FormGroup, 
  Input, 
  Label,
  Row 
} from "reactstrap";

// Custom Axios
import axiosInstance from 'utils/axiosInstance';

// Import Components
import Breadcrumbs from "components/Common/Breadcrumb";

const UploadPdf = () => {
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [textArray, setTextArray] = useState([]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) return;  // Early return if no file selected

    // Check if the file's MIME type is 'application/pdf'
    if (selectedFile.type !== 'application/pdf') {
      alert('請選擇一個有效的 PDF 檔案。');
      if (fileInputRef.current) fileInputRef.current.value = "";  // Reset file input
      setFile(null);
      return;
    }

    // Check if the file extension is '.pdf'
    if (!selectedFile.name.endsWith('.pdf')) {
      alert('請選擇一個有效的 PDF 檔案。');
      if (fileInputRef.current) fileInputRef.current.value = "";  // Reset file input
      setFile(null);
      return;
    }

    setFile(selectedFile); // Set the file to the first file selected
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      alert('請先選擇一個 pdf 檔案！');
      return;
    }

    const formData = new FormData();
    formData.append('pdf', file);

    try {
      // Upload pdf file to backend
      const response = await axiosInstance.post('/knovia/pdf/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      const data = response.data;
      // Replace all occurrences of '\t' and '\n'
      const cleanText = data.replace(/\t/g, ' ').replace(/\n/g, '');
      // Split on (1) period followed by a space, then a capital letter or a new line. or
      // (2) Chinese full stop '。'
      const cleanTextArray = cleanText.split(/(?<=\.)(?=\s[A-Z]|$)|(?<=。)/).map(c=>c.trim());  

      console.log('Extracted Text:', cleanTextArray);
      setTextArray(cleanTextArray);
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to upload the file.');
    }
  };



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="上傳檔案" breadcrumbItem="上傳Pdf" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">上傳Pdf</CardTitle>

                    <Form onSubmit={handleSubmit}>
                      <FormGroup>
                        <Label for="pdfUpload">Upload PDF</Label>
                        <Input type="file" id="pdfUpload" onChange={handleFileChange} ref={fileInputRef} />
                      </FormGroup>
                      <Button type="submit" color="primary">上傳 pdf 檔案</Button>
                    </Form>

                    {textArray && (<div style={{ marginTop:'1.5rem' }}></div>)}

                    {textArray && (
                      textArray.map((t, index)=> {
                        console.log('t:', t);
                        return (
                          <Row key={index} style={{ marginTop:'0rem' }}>
                            <Col sm="1">{`${index}:`}</Col>
                            <Col sm="10">
                              <p>{t}</p>
                            </Col>
                          </Row>
                        )
                        
                      })
                    
                    )}

                </CardBody>
              </Card>
            </Col>
          </Row>


        </Container>
      </div>
    </React.Fragment>
  )
}

export default UploadPdf;
