import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';

// Placeholder for authentication logic
const useAuth = () => {
  const [authState, setAuthState] = useState({ isLoading: true, isAuthenticated: false });

  useEffect(() => {
    setTimeout(() => {
      const isAuthenticated = localStorage.getItem("authUser") ? true : false;
      setAuthState({ isLoading: false, isAuthenticated });
    }, 0);
  }, []);

  return authState;
};

const AuthMiddleware = ({ children }) => {
  const location = useLocation();
  const { isLoading, isAuthenticated } = useAuth();

  if (isLoading) {
    return <div>Loading...</div>; // Or any loading component
  }

  if (!isAuthenticated) {
    return <Navigate to={{ pathname: "/login", state: { from: location } }} />;
  }

  return children;
};

AuthMiddleware.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthMiddleware;
