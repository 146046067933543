import React, { useRef } from 'react';
import placeholder from "assets/images/placeholders/placeholder_card.png"
// Custom Axios
import axiosInstance from './axiosInstance';

function deepEqual(a, b) {
  if (typeof a !== 'object' || typeof b !== 'object') { return a === b; }
  const keysA = Object.keys(a), keysB = Object.keys(b);
  if (keysA.length !== keysB.length) { return false; }
  for (const key of keysA) {
    if (!keysB.includes(key) || !deepEqual(a[key], b[key])) { return false; }
  }
  return true;
}

export const useDeepCompareMemoize = (value) => {
  const ref = useRef();
  if (!deepEqual(value, ref.current)) { ref.current = value; }
  return ref.current;
}

export const isValidImage = (file) => {
  // Define your size limit, for example 5MB
  const sizeLimit = 5 * 1024 * 1024; // 5MB in bytes
  // List of allowed file types
  const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
  // Check file type
  if (!allowedFileTypes.includes(file.type)) {
    alert('Only JPG, PNG, and GIF files are allowed.');
    return false;
  }
  // Check file size
  if (file.size > sizeLimit) {
    alert(`File is too large. Maximum size is ${sizeLimit / 1024 / 1024}MB.`);
    return false;
  }
  // Additional checks can be added here (like image dimensions)
  return true; // If all checks pass, return true
}

// Function to log FormData entries
function logFormData(formData) {
  console.log('Logging FormData entries:');
  for (let pair of formData.entries()) {
    console.log(`${pair[0]}: ${pair[1] instanceof File ? pair[1].name : pair[1]}`);
  }
}

// Uploading the selected file to the server
export const uploadImg = async (newfilename, file) =>{

  // Allowed image formats
  const allowedFormats = ['jpg', 'jpeg', 'png', 'svg'];
  // Extract file extension and convert it to lowercase
  const fileExtension = file.name.split('.').pop().toLowerCase();

  // Check if the file's format is allowed
  if (!allowedFormats.includes(fileExtension)) {
    console.error(`File format not allowed: .${fileExtension}`);
    return { newImageUrl: '', uploadStatus: `File format .${fileExtension} not allowed` };
  }

  // Proceed with the upload if the format is allowed
  const formData = new FormData();
  formData.append('file', file, newfilename);
  // Debugging logs
  console.log('file:', file);
  console.log('newfilename:', newfilename);
  logFormData(formData);

  console.log('[imageUtils uploadImg()] Uploading Image to Server...', newfilename);
  
  if(formData){
    try {
      const response = await axiosInstance.post(`/knovia/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.status === 200) {
        const newImageUrl = await fetchImage(newfilename);
        return {newImageUrl, uploadStatus: 'File uploaded successfully!'}
      } else {
        return {newImageUrl:'', uploadStatus: 'Failed to upload file.'}
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      return {newImageUrl:'', uploadStatus: 'Error occurred during upload.'};
    }
  }

}


/*---- Utility functions for IndexedDB ----*/

const dbRequest = indexedDB.open("imageCacheDB", 1);

dbRequest.onupgradeneeded = function(event) {
  let db = event.target.result;
  db.createObjectStore("images", { keyPath: "filename" });
};

const writeToIDB = (filename, data) => {
  return new Promise((resolve, reject) => {
    const dbOpenRequest = indexedDB.open("imageCacheDB", 1);

    dbOpenRequest.onsuccess = function(event) {
      let db = event.target.result;
      let transaction = db.transaction("images", "readwrite");
      let store = transaction.objectStore("images");

      let request = store.put({ filename: filename, data: data });
      request.onsuccess = () => resolve();
      request.onerror = () => reject(request.error);
    };

    dbOpenRequest.onerror = function(event) {
      reject("IndexedDB could not be opened: " + event.target.errorCode);
    };
  });
};

const readFromIDB = (filename) => {
  return new Promise((resolve, reject) => {
    if (!filename) {
      reject('Invalid filename provided:', filename);
      return;
    }

    const dbOpenRequest = indexedDB.open("imageCacheDB", 1);

    dbOpenRequest.onsuccess = function(event) {
      let db = event.target.result;
      let transaction = db.transaction("images", "readonly");
      let store = transaction.objectStore("images");

      let request = store.get(filename);
      request.onsuccess = () => {
        if (request.result) resolve(request.result.data);
        else reject('Image not found in IndexedDB');
      };
      request.onerror = (event) => reject('Error in request: ' + event.target.error);
    };

    dbOpenRequest.onerror = function(event) {
      reject("IndexedDB could not be opened: " + event.target.errorCode);
    };
  });
};

/*---- Utility functions for IndexedDB Tail ----*/


// Modified fetchImage function
export const fetchImage = (newfilename) => {
    // axiosInstance.get(`/knovia/image/${newfilename}`, { responseType: 'arraybuffer' }).then((response) => 
    //     {
    //       const { headers, data } = response;
    //       const blob = new Blob([data], { type: headers.get("content-type") });
    //       console.log('blob', blob);

    //       let reader = new FileReader();
    //       reader.readAsDataURL(blob);
    //       reader.onloadend = function () {
    //         let base64String = reader.result;
    //         console.log('Base64 String - ', base64String);

    //         // Simply Print the Base64 Encoded String, 
    //         // without additional data: Attributes. 
    //         console.log('Base64 String without Tags- ',
    //         base64String.substr(base64String.indexOf(', ') + 1));
    //       } 
    //     })
    //     .catch((error) => {
    //         console.error(error);
    //     });
      
  if (newfilename === '預設圖片' || newfilename === '還沒有選擇圖片喔') {
    console.log(`Fetching ${newfilename} is not a valid act, returning...`);
    return Promise.resolve(placeholder);
  } else if(!newfilename){
    console.log(`Fetching empty [newfilename] is not a valid act, returning...`);
    return Promise.resolve(placeholder);
  } else {
    return readFromIDB(newfilename)
      .then(data => {
        //console.log(`Image ${newfilename} found in IndexedDB`);
        return data;
      })
      .catch(() => {
        const sendNewFilename = encodeURIComponent(newfilename);
        console.log(`Image not found in IndexedDB, fetching [${sendNewFilename}] from network...`);
        return axiosInstance.get(`/knovia/image/${sendNewFilename}`, { responseType: 'arraybuffer' })
          .then(response => {
            const { headers, data } = response;
            const blob = new Blob([data], { type: headers.get("content-type") });

            let reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
              let base64String = reader.result;
              // console.log('Base64 String - ', base64String);
              writeToIDB(newfilename, base64String).then(() => {
                // console.log('Image cached in IndexedDB');
                return base64String;
              }).catch(error => {
                // console.error('Error caching the image in IndexedDB:', error);
                return base64String;
              });
            }
          })
          .catch(error => {
            console.error('Error fetching the image:', error);
            return ''; // Return an empty string or a default image URL on error
          });

      });
  }
};

