import React, { useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { nanoid } from "nanoid";
import {
  Col,
  Row,
  Card,
  CardBody,
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
  CardTitle,
  CardSubtitle,
  Container,
  UncontrolledDropdown,
  Input,
  Label,
  Form,  
} from "reactstrap"

// Link down to component <ActivityItem />
import ActivityItem from "./ActivityItem";
import LatestTransaction from "./LatestTransaction";

import img1 from '../../assets/images/small/knovia1.jpeg'
import img2 from '../../assets/images/small/knovia14.png'
import img3 from '../../assets/images/small/knovia5.png'

// label is shown in the page
// value is what the program receives
const langOneOptions = [
  {
    label: "來源語言",
    options: [
      {label:"英文", value:"英文"},
      {label:"中文", value:"中文"},
    ]
  }
];

const lengthOptions = [
  {
    label: "回答長度選擇",
    options: [
      {label:"20字", value:"20字"},
      {label:"50字", value:"50字"},
      {label:"100字", value:"100字"},
    ]
  }
];

const ActivityComp = (props) => {

  const [selectedLangOne, setSelectedLangOne] = useState("");
  const [selectedLength, setSelectedLength] = useState("");
  const [userWarning, setUserWarning] = useState("");

  const [newQuestion, setNewQuestion] = useState("");
  const [newResponse, setNewResponse] = useState("摘要生成中，再按一次取消");

  const [responseStatus, setResponseStatus] = useState("inactive"); // retrieving / inactive

  async function onSubmitQuestion(e) {

    // Prevent the browser from reloading the page
    e.preventDefault();
    console.log('newQuestion:', textProps.value)
    setNewQuestion(textProps.value);

    if(newQuestion){

        setResponseStatus('retrieving');

        // POST `knoviabackend.bytelingo.co/knovia`
        fetch(`/knovia/abstracts`, {
          method: 'POST',
          body: JSON.stringify({ langOne: selectedLangOne.value, length: selectedLength.value, question: newQuestion }),
          headers: {
            'content-type': 'application/json'
          },
        })
          .then((res) => res.json())
          .then((data) => {
            setResponseStatus('inactive');
            setNewResponse(data.content);
          })
          .catch((err) => console.error(err))
    } else {
        setUserWarning("無法送出空白提問")
    }

  }

  const cancelResponse = () => {
    setResponseStatus('inactive');
  }

  const [interactionList, setInteractionList] = useState([]);

  const getInteractionList = () => {

    // GET `knoviabackend.bytelingo.co/knovia/interactions`
    /*
    fetch(`/knovia/abstracts`)
      .then((res) => res.json())
      .then((data) => {
        setInteractionList(data);
        //console.log('interactionList', interactionList);
      })
      .catch((err) => console.error(err))
    */

  }

  function useAutoResize(initialValue = "") {
    const [value, setValue] = useState(initialValue);
    const textareaRef = useRef(null);

    const adjustTextareaHeight = () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto"; 
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; 
      }
    };

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
      adjustTextareaHeight();
    }, [value]);

    return {
      value,
      onChange: (e) => {
        setValue(e.target.value);
        adjustTextareaHeight();
      },
      textareaRef,
    };
  }

  /* textOne */
  const textProps = useAutoResize(newQuestion);
  const { textareaRef: textRef, ...textInputProps } = textProps;


  useEffect(() => {
    getInteractionList();
  }, [newResponse]);

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  )

  const [matches2, setMatches2] = useState(
    window.matchMedia("(min-width: 350px)").matches
  )

  useEffect(() => {
    window
    .matchMedia("(min-width: 768px)")
    .addEventListener('change', e => setMatches( e.matches ));
    window
    .matchMedia("(min-width: 350px)")
    .addEventListener('change', e => setMatches2( e.matches ));
  }, []);


  return (
    <React.Fragment>
      <Card>
        <CardBody>

          <Row>
            <Col sm={12}>
              <CardTitle className="mb-3" style={{ "marginTop":"0.5rem" }}>TheChart.AI</CardTitle>
            </Col>
          </Row>

          <form onSubmit={onSubmitQuestion}>

            <Row style={{ "marginTop": "1.5rem" }}>

              <Col sm={2}>
                <CardTitle className="mb-3">選項</CardTitle>
              </Col>

              <Col sm={3}>
                <div className="mb-3">
                  <Select
                    name="selectedLangOne"
                    value={selectedLangOne}
                    onChange={(option)=>{
                      setSelectedLangOne({label: option.label, value: option.value})
                    }}
                    options={langOneOptions}
                    className="select2-selection"
                    id='langoneselector'
                    placeholder={'選取文本語言'}
                  />
                </div>
              </Col>

              <Col sm={3}>
                <div className="mb-3">
                  <Select
                    name="selectedLength"
                    value={selectedLength}
                    onChange={(option)=>{
                      setSelectedLength({label: option.label, value: option.value})
                    }}
                    options={lengthOptions}
                    className="select2-selection"
                    id='lengthselector'
                    placeholder={'選取摘要長度'}
                  />
                </div>
              </Col>

            </Row>

            <Row>

              <Col sm={2} style={{"display": "flex", "alignItems": "center", "justifyContent": "center"}}>
                <h3>文本</h3>
              </Col>

              <Col sm={7}>
                <div className="toast fade show" style={{"width": "100%", "border": "1px solid #a6b0cf", "--bs-toast-box-shadow": "0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.175)"}} role="alert">
                  <div className="toast-body">
                  <textarea
                    ref={textRef}
                    style={{ 'minHeight':'10rem', 'border':'none', 'resize': 'none' }}
                    className="form-control" 
                    id="question"
                    {...textInputProps}
                    //value={newQuestion}
                    //onChange={(e) => {
                    //  setNewQuestion(e.target.value);
                    //}}
                  />
                  </div>
                </div>
              </Col>

              <Col sm={3} style={{ "paddingTop": "1.5rem" }}>

                <div className="audio-controls" style={{"marginTop":"1rem"}}>

                  {responseStatus === "inactive" ? (
                    <button type="submit" className="btn btn-rounded btn-success ">
                      <i className="bx bx-check-double font-size-16 align-middle me-2"></i> 
                        送出文本
                    </button>
                  ) : null}
                  {responseStatus === "retrieving" ? (
                    <button type="button" onClick={cancelResponse} className="btn btn-rounded btn-success">
                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                        生成摘要中
                    </button>
                  ) : null}

                </div> 
                
              </Col>

            </Row>

          </form>

          <Row style={{"marginTop": "1rem"}}>

            <Col sm={2} style={{"display": "flex", "alignItems": "center", "justifyContent": "center"}}>
              <h3>摘要</h3>
            </Col>

            <Col sm={7}>
              <div className="toast fade show" style={{"width": "100%", "border": "1px solid #a6b0cf", "--bs-toast-box-shadow": "0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.175)"}} role="alert">
                <div className="toast-body">
                  <textarea
                    style={{ 'minHeight':'20rem', 'border':'none', 'resize': 'none' }}
                    className="form-control" 
                    type="text" 
                    id="chatresponse"
                    value={newResponse}
                    readOnly={true}
                  />
                </div>
              </div>
            </Col>    

          </Row>

        </CardBody>

      </Card>

      <Row>
        <Col lg="12">
          <LatestTransaction interactionList={interactionList}/>
        </Col>
      </Row>

    </React.Fragment>
  );
};

export default ActivityComp;

