import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";

// Link down to components <Chatbot /> <LatestTransaction />
import Chatbot from './Chatbot';
import LatestTransaction from "./LatestTransaction";

// Custom Axios
import axiosInstance from 'utils/axiosInstance';

const ActivityComp = (props) => {

  const [interactionList, setInteractionList] = useState([]);

  const getInteractionList = async () => {

    console.log("refreshing data");

    try {
      const response = await axiosInstance.get(`knovia/knowledges`);
      const { data } = response;
      setInteractionList(data);
      console.log('knowledgeList', data);
    } catch (error){
      console.log('error:', error);
    }

  }

  useEffect(() => {
    getInteractionList();
  }, []);

  return (
    <React.Fragment>

      <Row>
        <Col lg="12">
          <LatestTransaction allData={interactionList} refreshData={getInteractionList} />
        </Col>
      </Row>

      <Chatbot />

    </React.Fragment>
  );
};

export default ActivityComp;

