import React, { useState } from "react";
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { Link } from "react-router-dom";
import { size, map } from "lodash";

// Redux
import { useDispatch } from "react-redux";
import { deleteDatabase as onDeleteDatabase } from "store/actions";

// Custom CSS
import './custom.css';

// Custom Modal
import EditModal from "./EditModal";
import InfoModal from "./InfoModal";
import DeleteModal from "components/Common/DeleteModal";


const CardContact = ({ offer, offerImage }) => {


  /*---- Title and Initial Setup ----*/

  const dispatch = useDispatch();

  /*---- Title and Initial Setup Tail ----*/



  /*---- State for Edit and Info Modals ----*/

  const [editModal, setEditModal] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const toggleEditModal = () => { setEditModal(!editModal) };
  const toggleInfoModal = () => { setInfoModal(!infoModal) };

  /*---- State for Edit and Info Modals Tail ----*/



  /*---- Modal Deleting a Project ----*/

  // State for Delete Modal Open or Close
  const [deleteModal, setDeleteModal] = useState(false);
  
  const onClickDelete = (offer) => { setDeleteModal(true) };
  const handleCloseDeleteModal = () => { setDeleteModal(false) };

  // Deleting a project and close the delete modal
  const deleteOrder = () => {
    if (offer && offer._id) { dispatch(onDeleteDatabase(offer._id)) };
    setDeleteModal(false);
  };
  
  /*---- Modal Deleting a Project Tail ----*/



  /*---- Main CardContact ----*/
  return (
    <React.Fragment>


      {/*---- Edit, Delete, and Info Modals ----*/}
      <EditModal offer={offer} offerImage={offerImage} editModal={editModal} toggle={toggleEditModal}  />
      <InfoModal offer={offer} offerImage={offerImage} infoModal={infoModal} toggle={toggleInfoModal}  />
      <DeleteModal 
        show={deleteModal} 
        onDeleteClick={deleteOrder} 
        onCloseClick={handleCloseDeleteModal}
        title={`資料庫 [${offer?.offerName}]`}
      />
      {/*---- Edit, Delete, and Info Modals ----*/}


      {/*---- Card Content ----*/}
      <Col xl="3" sm="6">
        <Card className="text-center">
          <CardBody>

            {/* Favorite Star */}
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                fontSize: "20px",
              }} 
            >
            </div>



            {/*---- Img ----*/}

            <div className="mb-4" style={{ display: "flex", justifyContent: "center" }}>
              {!offerImage ? (
                <span 
                  className="avatar-bigger avatar-title rounded-circle bg-soft bg-primary text-primary font-size-16"
                >{offer.offerName.charAt(0) || ''}
                </span>
              ) : (
                <img
                  className="rounded-circle avatar-bigger"
                  src={offerImage}
                  alt={offer?.offerName || "User Image"}
                  style={{ objectFit: "cover" }}
                />
              )}
            </div>
          
            {/*---- Img Tail ----*/}




            {/*---- Glossary Name and Client ----*/}

            <h5 className="font-size-15 mb-1">
              <Link to={`/dashboard-db/${offer?._id}`} className="text-dark">{offer?.offerName}</Link>
            </h5>

            <div style={{ marginTop:'3vh' }}>
              {offer?.offerDesc && 
                offer.offerDesc.map((d,key)=>{
                  return (
                    <p 
                      key={`${d}_${key}`}
                      className="text-muted"
                      style={{ margin:'1vh 0vh 1vh 0vh' }}
                    >{d}</p>
                  )
                })
              }
            </div>

            {/*---- Glossary Name and Client Tail ----*/}



            {/*---- Attribute Tags ----*/}
            <div>
              {/*--- show the first 2 tags ---*/}
              {map(offer?.tags, (tag, index) =>
                index < 2 && (
                  <Link to="#" className="badge bg-primary font-size-11 m-1" key={"_skill_" + offer?._id + index}>
                    {tag}
                  </Link>
                )
              )}
              {/*--- show the remaining tag number ---*/}
              {size(offer?.tags) > 2 && (
                <Link to="#" className="badge bg-primary font-size-11 m-1" key={"_skill_" + offer?._id}>
                  {size(offer.tags) - 2} + more
                </Link>
              )}

            </div>
            {/*---- Attribute Tags Tail ----*/}


          </CardBody>



          {/* Bottom Icons */}
          <CardFooter className="bg-transparent border-top">

            <div className="contact-links d-flex font-size-20"
              style={{ justifyContent: 'center' }}
            >

              <Button color='outline-info'
                style={{ minWidth:'10vw' }}
                onClick={() => setEditModal(!editModal)}
              >
                購買
              </Button>


            </div>

          </CardFooter>
          {/* Bottom Icons Tail */}

        </Card>
      </Col>
      {/*---- Card Content Tail ----*/}

    </React.Fragment>
  );
};

CardContact.propTypes = {
  offer: PropTypes.object
};

export default CardContact;
