import React, { useState, useEffect } from "react";
import {
  Alert,
  Col,
  Row,
  Button,
  Container,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown
} from "reactstrap";
import withRouter from "components/Common/withRouter";
import { Link } from "react-router-dom";

// Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

// Import Card
import CardContact from "./CardContact";
import NewDataModal from "./NewDataModal";

// Redux
import { useSelector, useDispatch } from "react-redux";

// Custom Axios
import axiosInstance from 'utils/axiosInstance';

// Custom CSS
import './custom.css';

// Custom Image
import placeholder from "assets/images/placeholders/placeholder_card.png";
import pearl_handful from "assets/images/product/pearl_handful.png";
import pearl_pile from "assets/images/product/pearl_pile.png";
import pearl_bowl from "assets/images/product/pearl_bowl.png";
import pearl_bucket from "assets/images/product/pearl_bucket.png";


const DbList = () => {


  /*---- Title and Initial Setup ----*/

  document.title = "購買 TheChart 點數 | TheChart.AI";
  const dispatch = useDispatch();
  const [sortedDbs, setSortedDbs] = useState([]);
  const [isAddNewDbModalOpen, setIsAddNewDbModalOpen] = useState(false);

  /*---- Title and Initial Setup Tail ----*/


  /*---- Getting Database from Redux ----*/

  // state.projects.projects is set through fetchUserData
  const { databases, dbImages, userData } = useSelector(state => ({
    databases: state.databases.databases,
    dbImages: state.databases.databaseImages,
    userData: state.user.userData,
  }));

  /*---- Getting Database from Redux Tail ----*/

  const offers = [
    {
      createDate: "20231230183922",
      offerName: "100 點",
      offerUnit: 100,
      minVolume: 1,
      maxVolume: 10,
      offerDesc: ['相當於', '翻譯字數: 100 字，或', '智能客服對話: 1,000 字'],
    },
    {
      createDate: "20231230183922",
      offerName: "500 點",
      offerUnit: 500,
      minVolume: 1,
      maxVolume: 10,
      offerDesc: ['相當於', '翻譯字數: 500 字，或', '智能客服對話: 5,000 字'],
    },
    {
      createDate: "20231230183922",
      offerName: "1000 點",
      offerUnit: 1000,
      minVolume: 1,
      maxVolume: 10,
      offerDesc: ['相當於', '翻譯字數: 1000 字，或', '智能客服對話: 10,000 字'],
    },
    {
      createDate: "20231230183922",
      offerName: "5000 點",
      offerUnit: 5000,
      minVolume: 1,
      maxVolume: 10,
      offerDesc: ['相當於', '翻譯字數: 5000 字，或', '智能客服對話: 50,000 字'],
    }
  ]


  /*---- Return JSX ----*/

  return (
    <React.Fragment>

      {/* 新增資料庫 Modal */}
      <NewDataModal isEditModalOpen={isAddNewDbModalOpen} setIsEditModalOpen={setIsAddNewDbModalOpen} />

      {/* Div Containing Card of Databases */}
      <div className="page-content">
        <Container fluid>

          {/* Render Breadcrumbs */}
          <Breadcrumbs title="功能總覽" breadcrumbItem="購買 TheChart 點數" />


          {/* Div Margin */}
          <Row style={{ marginBottom:"1rem" }}><Col sm="9"></Col></Row>

          {/* Cards of the Dbs */}
          <Row style={{ minHeight: "20rem" }}>

            <CardContact 
              offer={offers[0]}
              offerImage={pearl_handful || ''} 
            />
            <CardContact 
              offer={offers[1]} 
              offerImage={pearl_pile || ''} 
            />
            <CardContact 
              offer={offers[2]} 
              offerImage={pearl_bowl || ''} 
            />
            <CardContact 
              offer={offers[3]} 
              offerImage={pearl_bucket || ''} 
            />

          </Row>
          {/* Cards of the Dbs Tails */}

        </Container>
      </div>
      {/* Div Containing Card of Databases Tail */}

    </React.Fragment>
  );
};

export default withRouter(DbList);



