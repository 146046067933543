import {
  SET_CHATBOTS,
  SET_CHATBOT_IMAGE,

  LOAD_CHATBOT_IMAGES,
  LOAD_CHATBOT_IMAGES_SUCCESS,
  LOAD_CHATBOT_IMAGES_FAIL,

  GET_CHATBOTS_SUCCESS,
  GET_CHATBOTS_FAIL,

  ADD_NEW_CHATBOT,
  ADD_CHATBOT_SUCCESS,
  ADD_CHATBOT_FAIL,

  DELETE_CHATBOT_SUCCESS,
  DELETE_CHATBOT_FAIL,

  UPDATE_CHATBOT_SUCCESS,
  UPDATE_CHATBOT_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  chatbots: [],
  chatbotImages: {},

  loading: false,
  error: {},
  chatbotAddSuccess: false,
};

const chatbots = (state = INIT_STATE, action) => {

  switch (action.type) {


    /*--- SET CHATBOT ---*/

    case SET_CHATBOTS:
      return {
        ...state,
        chatbots: action.payload,
      };

    case SET_CHATBOT_IMAGE:
      return {
        ...state,
        chatbotImages: {
          ...state.chatbotImages, 
          [action.payload.cbId]: action.payload.imgUrl,
        }
      };

    /*--- SET CHATBOT TAIL ---*/



    /*--- LOAD CHATBOT IMAGES ---*/

    case LOAD_CHATBOT_IMAGES:
      return { ...state, loading: true };
    case LOAD_CHATBOT_IMAGES_SUCCESS:
      return { ...state, loading: false, chatbotImages: action.payload };
    case LOAD_CHATBOT_IMAGES_FAIL:
      return { ...state, loading: false, error: action.payload };

    /*--- LOAD CHATBOT IMAGES TAIL ---*/



    /*--- GET CHATBOT ---*/

    case 'RESET_CHATBOT_ADD_SUCCESS':
      return {
        ...state,
        chatbotAddSuccess: false,
      };

    case GET_CHATBOTS_SUCCESS:
      return {
        ...state,
        chatbots: action.payload,
      };

    case GET_CHATBOTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    /*--- GET CHATBOT TAIL ---*/

    

    /*--- ADD CHATBOT ---*/

    case ADD_NEW_CHATBOT:
      return {
        ...state,
        loading: true,
        chatbotAddSuccess: false,
      };

    case ADD_CHATBOT_SUCCESS:
      return {
        ...state,
        chatbots: [...state.chatbots, action.payload],
        loading: false,
        chatbotAddSuccess: true,
      };

    case ADD_CHATBOT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        chatbotAddSuccess: false,
      };

    /*--- ADD CHATBOT TAIL ---*/



    /*--- DELETE CHATBOT ---*/

    case DELETE_CHATBOT_SUCCESS:
      return {
        ...state,
        chatbots: state.chatbots.filter(
          chatbot => chatbot._id.toString() !== action.payload.toString()
        ),
      };

    case DELETE_CHATBOT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    /*--- DELETE CHATBOT TAIL ---*/



    /*--- UPDATE CHATBOT ---*/

    // Notice that the mapping won't show error when logic isn't right!
    case UPDATE_CHATBOT_SUCCESS:
      console.log("update chatbot success processing...", action.payload);
      return {
        ...state,
        chatbots: state.chatbots.map(chatbot =>
          chatbot._id.toString() === action.payload._id.toString()
            ? { ...chatbot, ...action.payload }
            : chatbot
        ),
      };

    case UPDATE_CHATBOT_FAIL:
      console.log("[store/chatbot/reducer.js] Update chatbot failed!");
      return {
        ...state,
        error: action.payload,
      };

    /*--- UPDATE CHATBOT TAIL ---*/



    default:
      return state;
  }

};

export default chatbots;


