import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";

// Link down to components <Chatbot /> <LatestTransaction />
import Chatbot from './Chatbot';
import LatestTransaction from "./LatestTransaction";

// Custom Axios
import axiosInstance from 'utils/axiosInstance';

const ActivityComp = (props) => {

  const [interactionList, setInteractionList] = useState([]);
  const [chatbotImgList, setChatbotImgList] = useState([]);
  const { chatbotId, thisCb, chatbotImg } = props;

  const [customPrompt, setCustomPrompt] = useState('');


  useEffect(() => {

    const fetchCustomPrompt = async () => {
      if (!customPrompt) {
        try {
          const response = await axiosInstance.get(`/knovia/chatbot-getCustomPrompt/${chatbotId}`);
          const { data } = response;
          console.log('customPrompt fetched:', data.customPrompt);
          setCustomPrompt(data.customPrompt)

        } catch (error) {
          console.log('error:', error);
        }
      }
    };

    fetchCustomPrompt();

  }, [chatbotId]);


  const saveCustomPrompt = async (customPrompt) => {
    const response = await axiosInstance.post(`knovia/chatbot-customPrompt/${chatbotId}`, 
      {customPrompt}
    );
    const { data } = response;

    console.log('response:', data);
  }

  // Save customPrompt whenever it's changed
  useEffect(()=>{
    console.log('customPrompt:', customPrompt);
    if(customPrompt){saveCustomPrompt(customPrompt);}
  }, [customPrompt])

  const getInteractionList = async () => {

    console.log(`fetching knowledge data for chatbot [${chatbotId}] ...`);

    try {
      const response = await axiosInstance.get(`knovia/knowledges/${chatbotId}`);
      const { data } = response;

      //console.log('knowledgeList:', data);

      // Sort data by `order` in descending order
      data.sort((a, b) => b.order - a.order);

      setInteractionList(data);
      //console.log('sorted knowledgeList:', data);
    } catch (error){
      console.log('error:', error);
    }

  }

  const getChatbotImageList = async () => {

    console.log(`fetching chatbot images for chatbot [${chatbotId}] ...`);

    try {
      const response = await axiosInstance.get(`knovia/chatbots-getImg/${chatbotId}`);
      const { chatbotImgs:data } = response.data;

      console.log('chatbotImageList:', data);

      // Sort data by `addedAt` in descending order
      data.sort((a, b) => b.addedAt - a.addedAt);

      setChatbotImgList(data);
      console.log('sorted chatbotImageList:', data);
    } catch (error){
      console.log('error:', error);
    }

  }

  useEffect(() => {
    getInteractionList();
    getChatbotImageList();
  }, []);

  return (
    <React.Fragment>

      <Row>
        <Col lg="12">
          <LatestTransaction
            thisCb={thisCb}
            chatbotId={chatbotId}
            allData={interactionList} 
            chatbotImgList={chatbotImgList}
            refreshData={getInteractionList}
            refreshChatbotImgData={getChatbotImageList}
            customPrompt={customPrompt}
            setCustomPrompt={setCustomPrompt}
          />
        </Col>
      </Row>

      <Chatbot thisCb={thisCb} chatbotImg={chatbotImg} customPrompt={customPrompt}/>

    </React.Fragment>
  );
};

export default ActivityComp;

