import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import {
  Alert,
  Col,
  Row,
  Card,
  CardBody,
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
  CardTitle,
  CardSubtitle,
  Container,
  UncontrolledDropdown,
  Input,
  Label,
  Form,  
} from "reactstrap";

// Custom Axios
import axiosInstance from 'utils/axiosInstance';

import './custom.css';

const KnoviaData = (props) => {
  

  /*---- States and Refs ----*/

  const newQuestionRef = useRef("");
  const rectanglesContainerRef = useRef(null);

  const [responseStatus, setResponseStatus] = useState("inactive");
  const [userWarning, setUserWarning] = useState("");
  const [similarityScores, setSimilarityScores] = useState([]);
  const [rectanglesData, setRectanglesData] = useState([]);

  // Click "取得回應中" to cancel Data Retrieval
  const cancelResponse = () => { setResponseStatus('inactive'); }

  /*---- States and Refs Tail ----*/



  /*---- Question Input TextArea ----*/

  // Function to handle the change from QuestionInput
  const handleQuestionChange = useCallback((question) => {
    newQuestionRef.current = question;
  }, []);

  const QuestionInput = ({ onQuestionChange, initialValue = "" }) => {
    const [text, setText] = useState(initialValue);

    useEffect(() => {
      setText(initialValue); // Update text state when initialValue changes
    }, [initialValue]);

    const handleChange = (e) => {
      const newText = e.target.value;
      setText(newText);
      onQuestionChange(newText);
    };

    return (
      <textarea
        style={{ minHeight: '10rem', border: 'none', resize: 'none' }}
        className="form-control" 
        value={text}
        onChange={handleChange}
      />
    );
  };

  /*---- Question Input Textarea Tail ----*/



  /*---- Submitting a new Text for generating comparisons ----*/

  async function onSubmitQuestion(e) {

    e.preventDefault();

    // Temporarily fix the container height to prevent collapsing when setting rectangle data to []
    const currentHeight = rectanglesContainerRef.current.clientHeight;
    rectanglesContainerRef.current.style.height = `${currentHeight}px`;
    setRectanglesData([]);

    // If there's indeed a new text to compare
    if(newQuestionRef.current){

      console.log('Question submitted:', newQuestionRef.current);

      setUserWarning('');
      setResponseStatus('retrieving');

      // Calling Server with sentence to match
      axiosInstance.post(`/knovia/testing`, { question: newQuestionRef.current })
        .then((response) => {
          const similarityScores = response.data.extractedSimilarityScores;
          setResponseStatus('inactive');
          console.log('similarityScores:', similarityScores);
          setSimilarityScores(similarityScores);
          startRenderingRectangles(similarityScores);
          // Make the rectangle height fit the data again
          rectanglesContainerRef.current.style.height = 'auto';
        })
        .catch((err) => {
          console.error(err);
        });

    // Send warning when the text is blank
    } else {
      setUserWarning("無法送出空白提問")
    }

  }

  /*---- Submitting a new Text for generating comparisons Tail ----*/



  // Prepare the data of each rectangles including their delays as attributes
  function startRenderingRectangles(similarityScores) {
    // Sort and prepare the initial data with animation delays
    const preparedData = similarityScores
      .sort((a, b) => b.similarity - a.similarity)
      .map((score, index) => ({
        ...score,
        animationDelay: `${index * 0.1}s` // for example, 0s, 0.2s, 0.4s, etc.
      }));
    // Set the initial state with all rectangles including animation delays.
    setRectanglesData(preparedData);
  }

  

  const NewResponse = React.memo(({ similarityScores }) => {

    const getBackgroundColor = (index) => {
      const totalResponses = rectanglesData.length;
      const thresholdForGreen = Math.ceil(totalResponses * 0.3); // Top 30%
      const thresholdForOrange = Math.ceil(totalResponses * 0.6); // Next 30%

      if (index < thresholdForGreen) return '#cedc39'; // Top 30%
      if (index < thresholdForOrange) return '#fddc58'; // Next 30%
      return '#fcbbbb'; // Rest
    };

    const renderedRectangles = useMemo(() => {

      console.log("Rendering rectangles...",String([...rectanglesData]));

      return rectanglesData.map((paragraph, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: getBackgroundColor(index),
            padding: '10px',
            borderRadius: '5px',
            marginBottom: '10px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            animationDelay: paragraph.animationDelay,
            animationName: 'appearAndMoveUp',
            animationDuration: '0.5s',
            animationFillMode: 'both',
            opacity: paragraph.visible ? 1 : 0,
            transition: 'opacity 0.5s ease'
          }}
          className="rectangle"
        >
          <div style={{ flex: 1, padding: '0.3rem 1rem', borderRight: 'solid grey 1.5px' }}>
            <p style={{ marginBottom: '0.2rem', fontWeight: '1000' }}>
              {props?.baseDatabases && 
                `[${props.baseDatabases.filter(b=>b._id===paragraph.glossaryId)[0].dbName}]`
              }
            </p>
            <p style={{ marginBottom: '0rem' }}>
              {paragraph.paragraph + " -> " + paragraph.p_secondLang}
            </p>
          </div>
          <div style={{ width: '50px', textAlign: 'center', paddingLeft: '0.4rem' }}>
            <strong>{paragraph.similarity.toFixed(2)}</strong>
          </div>
        </div>
      ));

    }, [rectanglesData]);

    return <div>{renderedRectangles}</div>;

  });

  NewResponse.displayName = 'NewResponse';

  return (
    <React.Fragment>
      <Card>
        <CardBody>

          <form onSubmit={onSubmitQuestion}>

            <Row>

              {/* Title for the Comparison Input section */}
              <Col sm={3} style={{"display": "flex", "alignItems": "center", "justifyContent": "center"}}>
                <h3>提問</h3>
              </Col>

              {/* Input text for Comparison */}
              <Col sm={7}>
                <div className="toast fade show" style={{"width": "100%", "border": "1px solid #a6b0cf", "--bs-toast-box-shadow": "0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.175)"}} role="alert">
                  <div className="toast-body">
                    <QuestionInput 
                      onQuestionChange={handleQuestionChange}
                      initialValue={newQuestionRef.current} 
                    />
                  </div>
                </div>
              </Col>

              {/* Button and User Warning */}
              <Col sm={2} style={{ "paddingTop": "1.5rem" }}>

                <div className="audio-controls" style={{"marginTop":"1rem"}}>

                  {responseStatus === "inactive" ? (
                    <button type="submit" className="btn btn-rounded btn-success ">
                      <i className="bx bx-check-double font-size-16 align-middle me-2"></i> 
                      開始比對
                    </button>
                  ) : null}
                  {responseStatus === "retrieving" ? (
                    <button type="button" onClick={cancelResponse} className="btn btn-rounded btn-success">
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                      取得回應中
                    </button>
                  ) : null}

                </div>

                {userWarning ? <Alert color="danger">{userWarning}</Alert> : null}
                
              </Col>

            </Row>

          </form>


          <Row style={{"marginTop": "1rem"}}>

            <div style={{"display": "flex"}} ref={rectanglesContainerRef}>

                {/* Title for generated comparisons */}
                <Col sm={3} style={{"display": "flex", "alignItems": "center", "justifyContent": "center"}}>
                  <h3>比對相似度</h3>
                </Col>

                {/* Rectangles containing the comparisons */}
                <Col style={{"padding": "0.5rem", "paddingRight": "10rem"}} sm={9}>
                  <NewResponse similarityScores={similarityScores} />
                </Col>

            </div>

          </Row>

        </CardBody>

      </Card>

    </React.Fragment>
  );
};

export default KnoviaData;

