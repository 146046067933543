import {
	CLEAR_GOOGLE_LOGIN_STATES,
  SET_GOOGLE_UNAUTHORIZED_ERROR,

  GOOGLE_LOGIN_REQUEST,
  GOOGLE_LOGIN_SUCCESS,
  GOOGLE_LOGIN_FAILURE,

  GOOGLE_LOG_OUT,
  GOOGLE_LOG_OUT_SUCCESS,
  GOOGLE_LOG_OUT_FAILURE,
} from "./actionTypes";

export const clearGoogleLoginStates = () => {
  console.log("[store/auth/googleLogin/action.js] CLEARING GOOGLE LOGIN STATES...");
  return {type: CLEAR_GOOGLE_LOGIN_STATES};
};

export const setGoogleUnauthorizedError = (errorMessage) => ({
  type: SET_GOOGLE_UNAUTHORIZED_ERROR,
  payload: errorMessage,
});

export const googleLoginRequest = (res) => ({
  type: GOOGLE_LOGIN_REQUEST,
  payload: res,
});

export const googleLoginSuccess = (userData) => ({
  type: GOOGLE_LOGIN_SUCCESS,
  payload: userData,
});

export const googleLoginFailure = (error) => ({
  type: GOOGLE_LOGIN_FAILURE,
  payload: error,
});

export const googleLogOut = history => ({
  type: GOOGLE_LOG_OUT,
  payload: { history },
});

export const googleLogOutSuccess = () => ({
  type: GOOGLE_LOG_OUT_SUCCESS,
  payload: {},
});

export const googleLogOutFailure = error => ({
  type: GOOGLE_LOG_OUT_FAILURE,
  payload: error,
});
