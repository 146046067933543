import React, { useMemo, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

const outputKeyToLabelMapping = { chooseColumns: "選擇匯出資料", };

/*---- Main Modal Component ----*/
const OutputExcelModal = ({ isOutputModalOpen, setIsOutputModalOpen, downloadExcel }) => {  

  const [modalTitle, setModalTitle] = useState("匯出 Excel 檔");
  const orderedOutputKeys = ["chooseColumns"];

  const [columnCheckboxes, setColumnCheckboxes] = useState([
    /*{ id: 'id', label: 'ID', checked: true },*/
    { id: 'order', label: '編號', checked: true },
    { id: 'masterquestion', label: '主題', checked: true },
    { id: 'masterknowledge', label: '主題知識', checked: true },
    { id: 'subknowledges', label: '範例問答', checked: true },
  ]);

  const handleColumnCheckboxChange = (index) => {
    const updatedCheckboxes = [...columnCheckboxes];
    updatedCheckboxes[index].checked = !updatedCheckboxes[index].checked;
    setColumnCheckboxes(updatedCheckboxes);
  };

  const checkedColumns = useMemo(() => {
    return columnCheckboxes.filter((c)=>c.checked===true).map((m)=>m.id);
  }, [columnCheckboxes]);

  return (
    
    <React.Fragment>
    {/*--- Modal for output Excel file ---*/}

      <Modal isOpen={isOutputModalOpen} toggle={() => setIsOutputModalOpen(!isOutputModalOpen)}>
        <ModalHeader toggle={() => setIsOutputModalOpen(!isOutputModalOpen)}>{modalTitle}</ModalHeader>

        {/* Modal Body */}
        <ModalBody>
          {orderedOutputKeys.map((key) => (

            <div key={key} style={{ marginBottom: "15px" }}>

              <p>{outputKeyToLabelMapping[key] || key}:</p>

              {key === "chooseColumns" ? (
                
                columnCheckboxes.map((checkbox, index) => (
                  <div key={checkbox.id}>
                    <input
                      type="checkbox"
                      id={checkbox.id}
                      checked={checkbox.checked}
                      onChange={()=>{}}
                      onClick={() => handleColumnCheckboxChange(index)}
                    />
                    <label style={{ marginLeft: "0.5rem" }} htmlFor={checkbox.id}>{checkbox.label}</label>
                  </div>
                ))
                
              ) : (
                <textarea
                  value={modalData[key]}
                  onChange={(e) => {
                    setModalData((prevData) => ({
                      ...prevData,
                      [key]: e.target.value
                    }));
                    adjustTextAreaHeight(e.target);
                  }}
                  style={{ width: "100%", resize: 'none', overflow: 'hidden' }}
                  className="form-control"
                  readOnly={key === "originalText"}
                />
              )}
              
            </div>
          ))}
        </ModalBody>

        {/* Modal Footer */}
        <ModalFooter>
          <Button 
            color="primary" 
            onClick={() => {
              downloadExcel(checkedColumns);
              setIsOutputModalOpen(false);
            }}
          >
            開始匯出
          </Button>
          <Button color="secondary" onClick={() => setIsOutputModalOpen(false)}>取消匯出</Button>
        </ModalFooter>

      </Modal>

      {/*--- Modal for output Excel file Tail ---*/}
    </React.Fragment>
  );

};

export default OutputExcelModal;


