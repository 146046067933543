import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import PropTypes from "prop-types";
import { map, get } from "lodash";
import { 
  Row,
  Col,
  Card, 
  CardBody, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Badge,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";

/*---- Utils ----*/
import * as XLSX from 'xlsx';

// Imgs
import placeholder from "assets/images/placeholders/placeholder_card.png";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { 
  setProjectImage,
  showFooterAction,
} from "../../../store/actions";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';

// Custom Axios
import axiosInstance from 'utils/axiosInstance';
// Custom Utils
import { formatDate } from './translate_util';
import { Pagination } from 'utils/pagination';
import { dateGen } from 'utils/toolUtils';
import { useDeepCompareMemoize, fetchImage, fetchAndCacheImage } from 'utils/imageUtils';

import './projectTranslate.css';

// Custom Modals
import DbListEditModal from './DbListEditModal';
import OutputExcelModal from './OutputExcelModal';
import ReferenceModal from './ReferenceModal';
import GlossaryModal from './GlossaryModal';

// Custom Components
import FoldableSection from './FoldableSection';
import SearchResultItem from './SearchResultItem';


const badgeClassNames = {
  translated: "bg-secondary",
  ai: "bg-danger",
  pending: "bg-warning",
  human: "bg-info",
  confirmed: "bg-success"
};

const adjustTextAreaHeight = (element) => {
  element.style.height = 'inherit'; 
  element.style.height = `${element.scrollHeight}px`; 
};

const ProjectDetail = ({ project, projectImages, baseDbs, dbs, updateColSize }) => {

  // console.log('project in ProjectDetail', project);
  // console.log('baseDbs', baseDbs, 'dbs', dbs);


  /*---- Setup and Initiations ----*/

  const dispatch = useDispatch();

  const [isLocked, setIsLocked] = useState(true);
  const [selectedTranslateRow, setSelectedTranslateRow] = useState(0);
  const selectTranslateRow = (rowIndex) => { setSelectedTranslateRow(rowIndex) };

  const userData = useSelector(state => state.user.userData);
  //useEffect(() => { dispatch({ type: 'FETCH_USER_DATA' }) }, []);
  //useEffect(() => { console.log('userData in projectTranslate:', userData) }, [userData]);

  const { projects } = useSelector(state => ({
    projects: state.projects.projects,
  }));

  /*---- Setup and Initiations ----*/



  /*---- Right Sidebar Visibility and Scroll ----*/

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => { 
    const newVisibility = !isSidebarVisible;
    setIsSidebarVisible(newVisibility);
    dispatch(showFooterAction(!newVisibility)); // Hide the footer when the sidebar is visible
  };

  // Effect to update colSize based on isSidebarVisible
  useEffect(() => {
    if (isSidebarVisible) {updateColSize("9");} 
    if (!isSidebarVisible) {updateColSize("12");}
  }, [isSidebarVisible, updateColSize]); 

  /*---- Right Sidebar Visibility and Scroll Tail ----*/



  /*---- Pagination States ----*/
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalPages, setTotalPages] = useState(1);
  const estimateLineCount = (text, charsPerLine = 35) => {return Math.ceil(text.length / charsPerLine)};
  /*---- For Excel Output Modal -----*/
  const [isOutputModalOpen, setIsOutputModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  /*---- Project File and Paragraphs -----*/
  const [fileDetails, setFileDetails] = useState(null); // Current Project File
  const [translatedParagraphs, setTranslatedParagraphs] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);  // Paragraphs in current page
  const processingParagraphsRef = useRef(new Set()); // Prevent repeat translation
  /*---- Percentages of each status ---*/
  const [stats, setStats] = useState({
    translated: { count: 0, percentage: 0 },
    ai: { count: 0, percentage: 0 },
    pending: { count: 0, percentage: 0 },
    human: { count: 0, percentage: 0 },
    confirmed: { count: 0, percentage: 0 }
  });



  /*---- Custom Database Sorting Modal ----*/

  const [dbModalIsOpen, setDbModalIsOpen] = useState(false);

  const [sortedDbList, setSortedDbList] = useState(project.dbs);
  const [sortedBaseDbList, setSortedBaseDbList] = useState(project.baseDbs);
  useEffect(()=>{ setSortedDbList(project.dbs) }, [dispatch, project.dbs]);
  useEffect(()=>{ setSortedBaseDbList(project.baseDbs) }, [dispatch, project.baseDbs]);
  
  const handleSortedItems = async (sortedBaseItems, sortedItems) => {
    console.log('sortedItems:', sortedItems);
    console.log('sortedBaseItems:', sortedBaseItems);
    try {
      const projectId = project._id;

      const response = await axiosInstance.post(
        `/knovia/project-database/${projectId}`, 
        {sortedItems, sortedBaseItems}
      );

      const { baseDbs, dbs } = response.data.updatedProject;
      console.log('baseDbs and dbs from response:', baseDbs, dbs);

      setSortedDbList(dbs.map(d=>d._id));
      setSortedBaseDbList(baseDbs.map(b=>b._id));
      dispatch({ type: 'FETCH_USER_DATA' });
  
    } catch (error) {
      console.error('Error updating reference database:', error);
    }

  };

  useEffect(() => {
    console.log('project.dbs changed!', project.dbs);
    if(project.dbs.length>0){setSortedDbList(project.dbs);}
  }, [project.dbs]);

  useEffect(() => {
    if(project.baseDbs.length>0){
      console.log('project.baseDb changed!', project.baseDbs);
      setSortedBaseDbList(project.baseDbs);
    }
  }, [project.baseDb]);

  /*---- Custom Database Sorting Modal Tail ----*/



  /*--- Updating current items, items viewable in current page ---*/

  const [indexOfFirstItem, setIndexOfFirstItem] = useState(0);
  const [indexOfLastItem, setIndexOfLastItem] = useState(0);
  useEffect(() => {
    const newLastItem = currentPage * itemsPerPage;
    const newFirstItem = newLastItem - itemsPerPage;
    setIndexOfFirstItem(newFirstItem);
    setIndexOfLastItem(newLastItem);

    console.log('currentItems updated!');

    setCurrentItems(translatedParagraphs.slice(newFirstItem, newLastItem)
                      .map(item => ({ ...item, isFetching: false })));
  }, [currentPage, itemsPerPage, translatedParagraphs]);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Resetting totalPage when paragraphs changes
  useEffect(() => { 
    setTotalPages(Math.ceil(translatedParagraphs.length / itemsPerPage)); 
  }, [translatedParagraphs]);

  // If itemsPerPage changed by user, event.target.value is the chosen value
  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setTotalPages(Math.ceil(translatedParagraphs.length / Number(event.target.value)));
    setCurrentPage(1); // Reset to the first page
  };

  /*--- Set total and current page item count Tail ---*/



  /*--- Fetch Image when project changes ---*/

  const fetchImageAndUpdateProject = useCallback((project) => {
    console.log('running fetch...');
    return fetchImage(project.img)
      .then(url => {
        dispatch(setProjectImage(project._id, url));
      })
      .catch(error => {
        console.error('Error fetching the image:', error);
        dispatch(setProjectImage(project._id, ''));
      });
  }, []);

  useEffect(() => {
    if(project && project.img){fetchImageAndUpdateProject(project);}
  }, [project && project.img]);

  /*--- Fetch Image when project changes Tail ---*/



  /*--- Calculate all status counts and percentages ---*/

  const totalParagraphs = useMemo(() => { return translatedParagraphs.length; }, [translatedParagraphs]);

  const computeStats = () => {
    const total = translatedParagraphs.length;
    const counts = {
      translated: translatedParagraphs.filter(p => p.translation !== "").length,
      ai: translatedParagraphs.filter(p => p.status === "AI").length,
      pending: translatedParagraphs.filter(p => p.status === "Pending").length,
      human: translatedParagraphs.filter(p => p.status === "Human").length,
      confirmed: translatedParagraphs.filter(p => p.status === "Confirmed").length
    };

    const newStats = {};
    for (const key in counts) {
      newStats[key] = {
        count: counts[key],
        percentage: ((counts[key] / total) * 100).toFixed(2)
      };
    }

    setStats(newStats);
  };

  useEffect(() => { computeStats(); }, [translatedParagraphs]);

  /*--- Calculate all status counts and percentages Tail ---*/




  /*---- Get translation from API and update Page ----*/

  const updateCurrentItemFetchingState = (index, isFetching) => {
    const itemActualIndex = index-indexOfFirstItem;

    setTranslatedParagraphs(prevParagraphs => {
      const newParagraphs = prevParagraphs.map((p, idx) => 
        idx === index ? { ...p, isFetching } : p
      );
      return newParagraphs;
    });
  };


  const getTranslationFromOpenAI = async (batch) => {
    // The batch is an array of { paragraphText, index }
    // Start by updating the fetching state for all items
    batch.forEach(({ index }) => {
      updateCurrentItemFetchingState(index, true);
    });

    try {
      console.log('Translating batch in [getTranslationFromOpenAI()]:', batch);
      console.log('Referencing Base Databases:', sortedBaseDbList);
      console.log('Referencing Databases:', sortedDbList);

      console.log('Sending to server:', {
        paragraphs: batch.map(item => ({
          text: item.paragraphText,
          translateId: translatedParagraphs[item.index]._id,
          index: item.index
        })),
        sortedBaseDbList,
        sortedDbList,
        file: project.file[0],
      })

      // Adjust the payload for batch processing
      const response = await axiosInstance.post(`/knovia/translate/batch`, {
        paragraphs: batch.map(item => ({
          text: item.paragraphText,
          translateId: translatedParagraphs[item.index]._id,
          index: item.index
        })),
        sortedBaseDbList,
        sortedDbList,
        file: project.file
      });

      // update wordThisMonth
      dispatch({ type: 'FETCH_USER_DATA' });

      // Assuming the response structure aligns with the batch request
      // e.g., an array of { index, translation, simpleTM, reference }
      const translations = response.data.translations.translations;

      console.log('Batch translation response:', translations);

      // Update paragraphs with new translations and status
      setTranslatedParagraphs(prevParagraphs => {
        return prevParagraphs.map((paragraph, idx) => {

          //console.log('idx:',idx);
          const translationData = translations.filter((t)=>{ return t.index===idx; })[0];

          if (translationData) {
            console.log('translationData:', translationData);
            const updatedStatus = paragraph.status === 'No translation' ? 'AI' : paragraph.status;

            console.log('setting translatedParagraphs:', {
              ...paragraph,
              translation: translationData.chatResponse.content,
              simpleTM: translationData.simpleChatResponse.content,
              reference: translationData.reference,
              status: updatedStatus,
              isFetching: false,
            });

            return {
              ...paragraph,
              translation: translationData.chatResponse.content,
              simpleTM: translationData.simpleChatResponse.content,
              reference: translationData.reference,
              status: updatedStatus,
              isFetching: false,
            };
          } else {
            return paragraph;
          }
        });
      });

      return translations;

    } catch (error) {
      console.error('Error fetching batch translation:', error);
      // Optionally, handle error for each item in the batch
      batch.forEach(({ index }) => {
        updateCurrentItemFetchingState(index, false); // Reset fetching state
        // Additional error handling here
      });
      throw error; // Or handle it differently
    }
  };

  

  /*---- Get translation from API and update Page ----*/




  /*---- Fetch translation of a single paragraph ----*/

  const TIMEOUT_MS = 180000;


  async function fetchTranslation(batch) {

    // batch is an array of { paragraphText, index }
    return new Promise(async (resolve, reject) => {

      // Mark each paragraph in the batch as being processed
      batch.forEach(({ index }) => {
        processingParagraphsRef.current.add(index);
      });

      // Set timeout for the whole batch
      let timeoutId = setTimeout(() => {
        console.log("Translation timed out for batch");
        batch.forEach(({ index }) => {
          processingParagraphsRef.current.delete(index);
          // Update state with timeout message for each paragraph in the batch
          setTranslatedParagraphs(prevParagraphs => prevParagraphs.map(p => {
            return batch.find(b => b.index === p.index) ? {
              ...p,
              translation: '[translation timed out]',
              isFetching: false
            } : p;
          }));
        });
        resolve(batch.map(({ index }) => ({ index, translation: '[translation timed out]' })));
      }, TIMEOUT_MS);

      try {
        // Perform the batch translation
        const translations = await getTranslationFromOpenAI(batch);
        clearTimeout(timeoutId);

        // Update translations and clear processing state
        /*
        setTranslatedParagraphs(prevParagraphs => prevParagraphs.map(p => {
          const translationData = translations.find(t => t.index === p.index);
          if (translationData) {
            processingParagraphsRef.current.delete(p.index); // Clear processing state
            return {
              ...p,
              translation: translationData.translation,
              simpleTM: translationData.simpleTM,
              reference: translationData.reference,
              isFetching: false
            };
          } else {
            return p;
          }
        }));
        */

        resolve(translations);

      } catch (error) {
        clearTimeout(timeoutId);
        batch.forEach(({ index }) => {
          processingParagraphsRef.current.delete(index);
        });
        resolve(batch.map(({ index }) => ({ index, translation: '[translation failed]' })));
      }
    });
  }


  const translateSingleParagraph = async (item, index) => {
    console.log("[translateSingleParagraph()]", item, index);
    await fetchTranslation([{ paragraphText: item.original, index: index }]);
  };

  const translateOneBatchParagraph = async (batch) => {
    // Log the batch operation
    console.log("[translateOneBatchParagraph()]", batch);
    await fetchTranslation(batch.map(item => ({ paragraphText: item.original, index: item.index })));
  };

  /*---- Fetch translation of a single paragraph Tail ----*/



  /*---- Batched Translation ----*/

  const [checkedItems, setCheckedItems] = useState([]);

  const BATCH_SIZE = 10;
  const [translationProgress, setTranslationProgress] = useState(
    { processed: 0, total: checkedItems.length }
  );


  /*--- New ---*/
  const processBatch = async (batch) => {
    batch.forEach(({ index: actualIndex }) => {
      if (!processingParagraphsRef.current.has(actualIndex)) {
        updateCurrentItemFetchingState(actualIndex, true);
      }
    });

    await translateOneBatchParagraph(batch);
  };



  const translateAllParagraphs = async () => {

    console.log('checkedItems:', checkedItems);
    // Reset progress at the start
    setTranslationProgress({ processed: 0, total: checkedItems.length });

    // Filter paragraphs that need to be translated (indices are in checkedItems)
    const paragraphsToTranslate = translatedParagraphs
      .map((p, index) => ({ ...p, index }))
      .filter(p => checkedItems.includes(p.index));

    const totalAvailWordCount = (userData?.stats?.wordThisMonth || 0)+(userData?.stats?.pointsLeft || 0);
    const usePoints = totalWordsToTranslate - userData?.stats?.wordThisMonth;

    if (usePoints > 0 && userData?.stats?.pointsLeft >= usePoints){
      if (confirm(`目前 TheChart 點數還有 ${userData?.stats?.pointsLeft} 點，本次翻譯會使用 ${usePoints} 點，要繼續嗎？`)) {
        console.log("繼續翻譯");
      } else {
        console.log("取消翻譯");
        return;
      }
    } else if(userData?.stats?.pointsLeft < usePoints){
      alert('可翻譯字數不足');  
      return;
    }
    
    let processedCount = checkedItems.length - paragraphsToTranslate.length;

    // Process in batches
    for (let i = 0; i < paragraphsToTranslate.length; i += BATCH_SIZE) {
      // Extract only necessary info for the batch
      const batch = paragraphsToTranslate.slice(i, i + BATCH_SIZE)
        .map(p => ({ original: p.original, index: p.index }));
      //await processBatch(batch, batch[0].index); // Pass the actual start index of the batch
      await processBatch(batch);

      if (processedCount + batch.length <= checkedItems.length){
        processedCount += batch.length;
      } else {
        processCount = checkedItems.length;
      }
      // Update state with the new progress
      setTranslationProgress({ processed: processedCount, total: checkedItems.length });
    }

  };

  /*---- Batched Translation Tail ----*/


  /*---- Fetch paragraphs from the Project File ----*/

  const [fileLoading, setFileLoading] = useState(false);

  useEffect(() => {

    const fetchFileData = async () => {

      // Get and set file paragraphs
      try {

        setFileLoading(true);
        const response = await axiosInstance.get(`/knovia/file/${project.file[0]}`);

        const { file, paragraphs } = response.data;
        setFileDetails(file);

        paragraphs.sort((a, b) => a.order - b.order); // sort by 'order' property
        const sortedTexts = paragraphs.map(p => ({
          _id: p._id,
          original: p.text,
          translation: p.translation ? p.translation : "",
          simpleTM: p.simpleTM,
          status: p.status,
          reference: p.reference,
        }));
        setTranslatedParagraphs(sortedTexts);

        setFileLoading(false);

      } catch (error) {
        console.error('Error:', error);
      }

    };

    if ((project && project.file)) { 
      console.log('Fetching file from project:', project);
      fetchFileData(); 
    }
  
  }, [project.file[0]]);

  /*---- Fetch paragraphs from the Project File Tail ----*/



  /*---- Checking Items ----*/

  const handleCheckboxChange = (index, isChecked) => {

    if (index >= 0) {
      if (!isChecked) { setCheckedItems(prevItems => [...prevItems, index]);
      } else { setCheckedItems(prevItems => prevItems.filter(i => i !== index)); }
    } else if (index === -1) {
      // Handle "check all" functionality
      if (isChecked) {
        console.log('Checking all', itemsPerPage, translatedParagraphs.length-indexOfFirstItem);
        const allIndices = Array.from(
          { length: Math.min(itemsPerPage, translatedParagraphs.length-indexOfFirstItem) }, 
          (_, i) => i + indexOfFirstItem
        );
        setCheckedItems(allIndices);
      } else {
        // Uncheck all
        setCheckedItems([]);
      }
    }

  };

  useEffect(()=>{
    setTranslationProgress({ processed: 0, total: checkedItems.length });

    console.log('1 translatedParagraphs', translatedParagraphs);
    console.log('1 checkedItems', checkedItems);

    let totalWordsToTranslate = 0;
    checkedItems.map(c=>translatedParagraphs[c].original);

  }, [checkedItems]);

  const [totalWordsToTranslate, setTotalWordsToTranslate] = useState(0);
  useEffect(() => {
    setTranslationProgress({ processed: 0, total: checkedItems.length });
    // Calculate the combined length
    const currentWordsToTranslate = checkedItems.reduce((sum, currentIndex) => {
      const originalText = translatedParagraphs[currentIndex].original || '';
      return sum + originalText.length;
    }, 0);
    setTotalWordsToTranslate(currentWordsToTranslate);
    console.log('Total words to translate:', currentWordsToTranslate);
  }, [checkedItems]);
  

  /*---- Checking Items Tail ----*/



  /*---- Editing Single Paragraph and save ----*/

  const saveEditResult = async (newModalData) => {

    const { originalText, translatedText } = newModalData;
    const editId = translatedParagraphs[newModalData.index]._id;
    
    try {

      const response = await axiosInstance.put(
        `/knovia/translate`, 
        {originalText, translatedText, editId }
      );

      console.log('Response', response);
      if (response.status === 200) {
        const updatedParagraphs = translatedParagraphs.map(item => 
          item._id === editId ? { ...item, original: originalText, translation: translatedText } : item
        );
        setTranslatedParagraphs(updatedParagraphs);
        setEditingId(-1);
      }
    } catch (error) {
      console.error('Error fetching translation:', error);
      setEditingId(-1);
    }

  }

  // State to track edit mode (div->textarea)
  const [editingId, setEditingId] = useState(-1);
  // Toggle edit mode through double click
  const toggleEdit = (index) => { setEditingId(index); };


  useEffect(()=>{
    document
      .querySelectorAll('.form-control')
      .forEach(textarea => { adjustTextAreaHeight(textarea); });
  },[editingId]);

  const editSingleParagraph = async(item, index) =>{
    console.log('Editing single paragraph [editSingleParagraph()]', item, index);
    //console.log('textAreaValues', textAreaValues);
    const newModalData = {
      index: index,
      originalText: item.original,
      translatedText: textAreaValues[index],
    }
    saveEditResult(newModalData);
  }
  /*---- Editing Single Paragraph and save Tail ----*/


  /*---- Copied Value showed after double click for editing ----*/

  // Initialize state with each item's translation
  const [textAreaValues, setTextAreaValues] = useState(translatedParagraphs.map(t=>t.translation));
  useEffect(()=>{ setTextAreaValues(translatedParagraphs.map(t=>t.translation)); },[translatedParagraphs])
  // Handle change for each editing textarea
  const handleTextAreaChange = (index, value) => {
    const updatedArray = [...textAreaValues];
    updatedArray[index] = value;
    setTextAreaValues(updatedArray);
  };

  /*---- Copied Value showed after double click for editing Tail ----*/



  /*---- For output Excel checkboxes choosing Columns ----*/

  function filterColumns(checkedColumns, data) {
    return data.map((item, index) => {
      let filteredItem = {};
      let highestSimilarity = (item.reference
        .map(r=>parseFloat(r.similarity)*100)
        .sort(function(a, b){return b-a})[0]
        ?.toFixed(1) || 0)+'%';
      if (checkedColumns.includes('numberColumn')){ filteredItem['編號'] = index+1; }
      if (checkedColumns.includes('originalText')){ filteredItem['原始段落'] = item['original'] || ''; }
      if (checkedColumns.includes('translatedText')){ filteredItem['翻譯結果'] = item['translation'] || ''; }
      if (checkedColumns.includes('statusCode')){ filteredItem['狀態'] = item['status'] || 'No translation'; }
      if (checkedColumns.includes('highestSim')){ filteredItem['最高相似度'] = highestSimilarity || '0%'; }
      return filteredItem;
    });
  }

  const downloadExcel = async(checkedColumns) => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(filterColumns(checkedColumns, translatedParagraphs));
    //console.log('checkedColumns', checkedColumns);
    XLSX.utils.book_append_sheet(wb, ws, "Bytelingo Output");
    //console.log('output', filterColumns(checkedColumns, translatedParagraphs));

    const newFileDate = await dateGen();
    const excelFilename = `${newFileDate.slice(0,8)}_${newFileDate.slice(8,14)}-[${project.name}]`;
    //console.log('excelFilename:', excelFilename);
    XLSX.writeFile(wb, `${excelFilename}.xlsx`);
  };

  /*---- For output Excel checkboxes choosing Columns Tail ----*/



  /*---- For Status dropdowns ----*/

  const [dropdownStates, setDropdownStates] = useState({});
  const toggleDropdown = (index) => {
    setDropdownStates(prevStates => ({
      ...prevStates,
      [index]: !prevStates[index]
    }));
  };

  const changeDropdownValue = async (index, value) => {

    // value = 'AI', 'Pending', 'Human', 'Confirmed'
    const statusId = translatedParagraphs[index]._id;
    console.log("statusId", statusId, "value", value);
    
    try {
      const response = await axiosInstance.put(
        `/knovia/paragraphstatus`, 
        { statusId, value }
      );
      console.log('Response', response);
      if (response.status === 200) {
        setTranslatedParagraphs(prevParagraphs => {
          const newParagraphs = prevParagraphs.map((p, idx) => 
            idx === index ? { ...p, status:value } : p
          );
          return newParagraphs;
        });
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }

  }

  /*---- For Status dropdowns Tail ----*/




  /*---- For Glossary Modal -----*/
  
  const [isGlossaryModalOpen, setIsGlossaryModalOpen] = useState(false);

  useEffect(() => {
    if (isGlossaryModalOpen) {
      setTimeout(() => {
        document.querySelectorAll('.modal .form-control').forEach(textarea => { adjustTextAreaHeight(textarea); });
      }, 0);
    }
  }, []);

  /*---- For Glossary Modal Tail -----*/



  /*---- For Reference Modal -----*/
  
  const [isReferenceModalOpen, setIsReferenceModalOpen] = useState(false);
  const [originalInRef, setOriginalInRef] = useState("");
  const [referenceList, setReferenceList] = useState([]);
  const [simpleTranslation, setSimpleTranslation] = useState("");
  const [currentTranslation, setCurrentTranslation] = useState("");
  const [refParagraphId, setRefParagraphId] = useState("");

  const handleReferenceClick = (index)=>{
    setIsReferenceModalOpen(true);
    const { original, simpleTM, translation, reference } = translatedParagraphs[index];
    setRefParagraphId(translatedParagraphs[index]._id);
    const originalInRefShow = `${original || "[原始段落文字]"}`
    const simpleTranslationShow = `${simpleTM || "[未進行 AI 翻譯]"}`
    const currentTranslationShow = `${translation || "[未進行 AI 翻譯]"}`
    setOriginalInRef(originalInRefShow);
    setSimpleTranslation(simpleTranslationShow);
    setCurrentTranslation(currentTranslationShow);
    setReferenceList(reference);
  }

  /*---- For Reference Modal Tail -----*/



  /*---- Auto-adjust Textarea height ----*/
  useEffect(() => {
    if (isReferenceModalOpen || isGlossaryModalOpen) {
      setTimeout(() => {
        document.querySelectorAll('.modal .form-control').forEach(textarea => { adjustTextAreaHeight(textarea); });
      }, 0);
    }
  }, [isReferenceModalOpen, isGlossaryModalOpen]);
  /*---- Auto-adjust Textarea height ----*/




  /*---- Right Sidebar Functions ----*/

  const [mouseSelectedText, setMouseSelectedText] = useState("");
  const [searchTMResult, setSearchTMResult] = useState([]);
  const [deepLResult, setDeepLResult] = useState('');
  const [diffLoading, setDiffLoading] = useState(false);

  const searchBackend = async(selectedText) => { 
    try {

      /*---- Search Glossaries ----*/

      if (selectedPanel==='A'){
        const response = await axiosInstance.post(
          `/knovia/search-database`, 
          { selectedText, sortedBaseDbList, sortedDbList }
        );

        const { shortSearchResult } = response.data;
        const searchResultBeautified = shortSearchResult.map(
          s=>`${(parseFloat(s.similarity)*100).toFixed(1) + '%'}:${s.paragraph}=>${s.p_secondLang}`
          );

        setSearchTMResult(shortSearchResult);
        console.log('Search Result:', searchResultBeautified);
        setDiffLoading(false);
      }

      /*---- Search Glossaries Tail ----*/


      /*---- Search Glossaries ----*/

      if(selectedPanel==='B' && selectedText){
        const deeplResponse = await axiosInstance.post(
          `/knovia/deepl`, 
          { deeplText: selectedText }
        );

        const { translateResult: deeplResult } = deeplResponse.data;
        console.log('Deepl Result:', deeplResult);
        setDeepLResult(deeplResult);
      }

      /*---- Search Glossaries Tail ----*/


    } catch (error) {
      console.error('Error searching text in database:', error);
    }
  }

  const [isTextSelected, setIsTextSelected] = useState(false);

  /*
  useEffect(() => {
    // Function to execute when mouse up event is detected
    const handleMouseUp = async e => {
      e.stopPropagation();
      const selectedText = window.getSelection().toString().trim();
      if (selectedText.length > 0) {
        console.log('selectedText:', selectedText);
        setMouseSelectedText(selectedText);
        setIsTextSelected(true); // Set flag to true when text is selected

        setDeepLResult("");

        console.log('sortedDbList', sortedDbList);
        console.log('sortedBaseDbList', sortedBaseDbList);
        console.log('Referencing Base Databases:', sortedBaseDbList);
        console.log('Referencing Databases:', sortedDbList);
      } else {
        setIsTextSelected(false); // Reset flag if no text is selected
      }
    };

    // Add event listener for mouse up to the document
    document.addEventListener('mouseup', handleMouseUp);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);
  */

  /*---- Right Sidebar Functions Tail ----*/



  /*---- Stop Scrolling Propagation of the right Sidebar ----*/
  const scrollableDivRef = useRef(null);
  useEffect(() => {
    const div = scrollableDivRef.current;
    if (!div) return;

    const preventScrollChaining = (event) => {
      // 0 652 473  // 178.5 652 473
      const { scrollTop, scrollHeight, clientHeight } = div;
      const tolerance = 1;
      //console.log('scrolling:  ,', scrollTop, scrollHeight, clientHeight);
      //console.log('(scrollTop + clientHeight + tolerance) >= scrollHeight-1 && event.deltaY > 0)', ((scrollTop + clientHeight + tolerance) >= scrollHeight-1 && event.deltaY > 0));
      if (
        (scrollTop === 0 && event.deltaY < 0) || // Scrolling up at the top
        ((scrollTop + clientHeight + tolerance) >= scrollHeight-1 && event.deltaY > 0) // Scrolling down at the bottom
      ) { event.preventDefault(); }
    };
    div.addEventListener('wheel', preventScrollChaining, { passive: false });
    return () => {
      if (div) { div.removeEventListener('wheel', preventScrollChaining) }
    };
  }, [scrollableDivRef.current]);
  /*---- Stop Scrolling Propagation of the right Sidebar Tail ----*/


  const [selectedPanel, setSelectedPanel] = useState('A');


  //const [diffElements, setDiffElements] = useState([]);
  const [showDiffTextA, setShowDiffTextA] = useState(true);
  const [showDiffTextC, setShowDiffTextC] = useState(true);

  const [select123, setSelect123] = useState(translatedParagraphs[selectedTranslateRow]?.original || "");

  useEffect(()=>{

    if(mouseSelectedText){
      setSearchTMResult([]);
      setSelect123(mouseSelectedText);
      //searchBackend(mouseSelectedText);
    } else if(selectedTranslateRow>=0 && translatedParagraphs[selectedTranslateRow]?.original) {
      setSearchTMResult([]);
      setSelect123(translatedParagraphs[selectedTranslateRow]?.original);
      //searchBackend(translatedParagraphs[selectedTranslateRow]?.original);
    }

  }, [mouseSelectedText, selectedTranslateRow, translatedParagraphs[selectedTranslateRow]]);


  const clickDiff = ()=>{
    console.log('clicking Diff:', translatedParagraphs[selectedTranslateRow]);

    if(mouseSelectedText){
      setSearchTMResult([]);
      setSelect123(mouseSelectedText);
      setDiffLoading(true);
      searchBackend(mouseSelectedText);
    } else if(selectedTranslateRow>=0 && translatedParagraphs[selectedTranslateRow]?.original) {
      setSearchTMResult([]);
      setSelect123(translatedParagraphs[selectedTranslateRow]?.original);
      setDiffLoading(true);
      searchBackend(translatedParagraphs[selectedTranslateRow]?.original);
    }
  }

  const handleRowClick = (index)=>{

    if (isTextSelected) {
      // Reset the text selection flag and do not execute further logic
      setIsTextSelected(false);
      return;
    }

    console.log('index:', index);
    setDeepLResult("");
    setMouseSelectedText("");
    setSelectedTranslateRow(index);
  }

  useEffect(()=>{

    if(selectedPanel!=='A'){};
    if(selectedPanel!=='B'){setDeepLResult('')};

    if(selectedPanel==='B'){searchBackend(select123)};

  },[selectedPanel]);

  const toggleLocked = ()=>{setIsLocked(!isLocked)};
  

  return (

    <React.Fragment>

      <OutputExcelModal 
        isOutputModalOpen={isOutputModalOpen}
        setIsOutputModalOpen={setIsOutputModalOpen}
        downloadExcel={downloadExcel}
      />

      <ReferenceModal 
        isReferenceModalOpen={isReferenceModalOpen}
        setIsReferenceModalOpen={setIsReferenceModalOpen}
        referenceList={referenceList}
        originalInRef={originalInRef}
        simpleTranslation={simpleTranslation}
        currentTranslation={currentTranslation}
        dbs={dbs}
        baseDbs={baseDbs}
        toggleLocked={toggleLocked}
        isLocked={isLocked}
        paragraphId={refParagraphId}
      />


      <Row style={{ paddingRight: "1rem" }} className="sticky-sidebar-container">
        <Col
          xl={isSidebarVisible ? "9" : "12"}
          sm={isSidebarVisible ? "9" : "12"} 
          xs="12"
          style={{ paddingRight: "0px" }}
        >
          {/* Main Content */}
          <Card>
            <CardBody>

              <Row>

                <Col md="2" sm="3" xs="12">

                  <div className="d-flex"
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      justifyContent: "center"
                    }}
                  >
                    <img 
                      src={(project && projectImages && projectImages[project._id]) || placeholder} 
                      alt="" 
                      className="me-4" 
                      style={{ 
                        objectFit:"cover", 
                        padding: "0.5rem",
                        height: "6rem",
                        width: "6rem",
                        borderRadius: "50%"
                      }}
                    />
                  </div>

                </Col>

                <Col 
                  md="6" sm="9" xs="12" xxs="6"
                  style={{ 
                    display: "flex", 
                    alignItems: "center",
                    paddingTop: "1rem",
                  }}
                >

                  <div className="flex-grow-1 overflow-hidden">
                    <h5 className="text-truncate font-size-20">{(project && project.name) || ""}</h5>
                    <p className="text-muted font-size-14">{(project && project.description) || ""}</p>
                  </div>

                </Col>


                <Col md="4" sm="12" xs="12">
                  <div style={{ 
                    display:"flex", 
                    alignItems:"flex-end", 
                    flexDirection: "column",
                    justifyContent: "center",
                    paddingRight: "1rem",
                    height: "100%", 
                    margin: "0rem" }}
                  >

                    <button 
                      className="btn btn-outline-success" 
                      onClick={() => setIsOutputModalOpen(true)}
                    >
                      匯出 Excel 檔
                    </button>

                    <button 
                      className="btn btn-outline-info" 
                      onClick={() => setDbModalIsOpen(true)}
                      style={{ marginTop:"0.5rem" }}
                    >
                      {`編輯參考資料庫`}
                    </button>

                    <button 
                      className="btn btn-outline-warning" 
                      onClick={() => setDbModalIsOpen(true)}
                      style={{ marginTop:"0.5rem" }}
                    >
                      {`編輯專案固定提示/詞彙`}
                    </button>

                    <DbListEditModal
                      isOpen={dbModalIsOpen}
                      onRequestClose={() => setDbModalIsOpen(false)}
                      onItemsSorted={handleSortedItems}
                      sortedDbList={sortedDbList}
                      sortedBaseDbList={sortedBaseDbList}
                    />

                  </div>
                    
                </Col>

              </Row>


              <Row className="task-descriptions">

                {/* xl: >1200px, lg: >992px, md: >768px, sm: >576px */}

                {/* 開始日期 */}
                <Col xl="2" lg="3" md="3" sm="3" xs="6">
                  <div className="mt-4"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "column",
                      alignContent: "center",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h5 className="font-size-14"><i className="bx bx-calendar me-1 text-primary" />開始日期</h5>
                    <p className="text-muted mb-0">{formatDate((project && project.startDate) || "")}</p>
                  </div>
                </Col>

                {/* 結束日期 */}
                <Col xl="2" lg="3" md="3" sm="3" xs="6">
                  <div className="mt-4"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "column",
                      alignContent: "center",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h5 className="font-size-14"><i className="bx bx-calendar-check me-1 text-primary" />結束日期</h5>
                    <p className="text-muted mb-0">{formatDate((project && project.endDate) || "")}</p>
                  </div>
                </Col>


                {/* 文本語言 */}
                <Col xl="2" lg="3" md="3" sm="3" xs="6">
                  <div className="mt-4"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "column",
                      alignContent: "center",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h5 className="font-size-14"><i className="bx bx-file me-1 text-primary" />文本語言</h5>
                    <p className="text-muted mb-0">{((project && project.langOne) || "")}</p>
                  </div>
                </Col>

                {/* 目標語言 */}
                <Col xl="2" lg="3" md="3" sm="3" xs="6">
                  <div className="mt-4"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "column",
                      alignContent: "center",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h5 className="font-size-14"><i className="bx bx-shuffle me-1 text-primary" />目標語言</h5>
                    <p className="text-muted mb-0">{((project && project.langTwo) || "")}</p>
                  </div>
                </Col>


                {/* 參考資料庫 */}
                <Col xl="4" lg="12" md="12" sm="12" xs="11"
                  style={{ paddingRight: '2rem' }}
                >
                  <div 
                    style={{    
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "flex-end",
                      marginTop: "1rem",
                    }}
                  >

                    <h5 className="font-size-12">{`參考底層資料庫數量：${sortedBaseDbList.length}`}</h5>
                    <h5 className="font-size-12">{`參考額外資料庫數量：${sortedDbList.length}`}</h5>

                  </div>
                </Col>

              </Row>


              {/* 分隔線 */}
              <Row>
                <Col sm="12">
                  <div style={{margin:"1rem 0rem", borderBottom:"1px solid #bdbdbd"}}></div>
                </Col>
              </Row>


              {/* 檔案資料 */}
              <Row>

                <Col sm="12">

                  <Row style={{ marginBottom: '1.5rem' }}>

                    <Col xl="3" lg="5" md="5" sm="12" xs="12"
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignContent: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <div>
                        {fileDetails && (
                          <div>
                            {/* Display file information here */}
                            <h5>{fileDetails.name}</h5>
                            <p>上傳時間: {formatDate(fileDetails.createdAt)}</p>
                            {/* Add more file details as needed */}
                          </div>
                        )}
                      </div>
                    </Col>

                    <Col xl="6" lg="3" md="3" sm="6" xs="6"
                      style={{ 
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignContent: 'center',
                        justifyContent: 'center',
                        marginBottom: '1rem',
                      }}
                    >
                      <div style={{ display:"flex", flexDirection: "column", alignItems: "flex-end", minWidth: '10rem' }}>
                        <label>每頁顯示段落數:&nbsp;&nbsp;</label>
                        <select className="form-select" value={itemsPerPage} onChange={handleItemsPerPageChange}>
                          <option value="10">10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="200">200</option>
                          <option value="500">500</option>
                          <option value="1000">1000</option>
                          <option value="2000">2000</option>
                        </select>
                      </div>
                    </Col> 

                    <Col xl="3" lg="4" md="4" sm="6" xs="6">
                      <div style={{ 
                        display:"flex", 
                        width: "100%", 
                        alignItems: "flex-end", 
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        height: "100%" }}
                      >
                        { Object.entries(stats).map(([key, { count, percentage }]) => (
                          <span key={key} style={{ marginLeft: '1rem' }}>
                            <Badge 
                              className={badgeClassNames[key] || `bg-${key}`} 
                              style={{ 
                                fontSize: '12px',
                                width: '10rem',
                                textAlign: 'right',
                              }}
                            >
                              {`${key.charAt(0).toUpperCase() + key.slice(1)}: ${count}/${totalParagraphs} (${percentage}%)`}
                            </Badge>
                          </span>
                        ))}

                      </div>
                    </Col>

                  </Row>

                </Col>


                {checkedItems.length > 0 ? (
                  <Col sm="12">
                    <Row>

                      <Col sm="6">
                        <div className={`alert 
                          ${((userData?.stats?.wordThisMonth || 0)+(userData?.stats?.pointsLeft || 0) 
                              > totalWordsToTranslate) ? 'alert-success' : 'alert-danger'} 
                            fade show`} role="alert">
                          {/* Display the count and list of selected paragraphs */}
                          {`已選取 ${checkedItems.length} 個段落: [` +
                            checkedItems
                              .map(c => c + 1) // Adjust for zero-based indexing to display to the user
                              .sort((a, b) => a - b) // Sort the paragraph numbers in ascending order
                              .join(', ') +
                          `]`}<br /><br />

                          {/* Display the total number of characters selected for translation and the remaining words that can be translated this month */}
                          {`共 ${totalWordsToTranslate} 個字元，
                            本月方案用量還可翻譯 ${(userData?.stats?.wordThisMonth || 0)} 個字`}<br />
                          {`TheChart 點數還有 ${(userData?.stats?.pointsLeft || 0)} 點`}

                        </div>
                      </Col>

                      <Col sm="6">
                        <Row>

                          {( (userData?.stats?.wordThisMonth || 0) + (userData?.stats?.pointsLeft || 0) 
                              > totalWordsToTranslate) ?
                            (
                              <Col sm="3">
                                <Button 
                                  color="outline-danger"
                                  onClick={()=>{translateAllParagraphs();}}
                                >
                                  全部翻譯  
                                </Button>
                              </Col>
                            ) : (
                              <Col sm="3">
                                <Button 
                                  color="outline-info"
                                  onClick={()=>{
                                    alert('點數線上購買功能尚未啟用，如欲購買，請電洽 070-1011-6693 或使用註冊 email 寄信至 admin@bytelingo.co，主旨「購買 OOO 點 TheChart 點數」')}
                                  }
                                >
                                  前往購買點數
                                </Button>
                              </Col>
                            )
                          }

                          <Col sm="9">
                            <p style={{ marginBottom:'0rem' }}>Translation Progress: {translationProgress.processed} / {translationProgress.total}</p>
                            <progress value={translationProgress.processed} max={translationProgress.total}
                              style={{ width: '70%' }}
                            ></progress>
                          </Col>

                        </Row>
                      </Col>

                    </Row>
                  </Col>
                ) : ( <></> )}


                {/* Spinner when file is not loaded yet */}
                {fileLoading &&
                  <div 
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'center'
                    }}
                  >
                    <Spinner className="ms-2" color="success" />
                  </div>
                }

                {/* Render paragraphs */}
                {!fileLoading && (<Col sm="12" style={{marginTop:"1rem"}}>
                  <div>

                    <table style={{ width:"100%" }}>
                      <thead>
                        <tr>
                          <th style={{ width: '6%', padding: "0.5rem 0rem" }}>
                            <input 
                              type="checkbox" 
                              className="form-check-input" 
                              id={`checkbox-all`}
                              value="checked"
                              onChange={e => handleCheckboxChange(-1, e.target.checked)}
                            />
                            &nbsp;&nbsp;#
                          </th>
                          <th style={{ width: '25%', padding: "0.5rem 0rem" }}>原始段落</th>
                          <th style={{ width: '35%', padding: "0.5rem 0rem" }}>翻譯結果</th>
                          <th style={{ width: '15%', padding: "0.5rem 0rem" }}>
                            <div style={{ display:"flex", justifyContent:"center" }}>狀態</div>
                          </th>
                          <th style={{ width: '7%', padding: "0.5rem 0rem" }}>
                            <div style={{ display:"flex", justifyContent:"center" }}>AI 翻譯</div>
                          </th>
                          <th style={{ width: '7%', padding: "0.5rem 0rem" }}>
                            <div style={{ display:"flex", justifyContent:"center" }}>更多</div>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {currentItems && currentItems.map((item, index) => {

                          const lineCount = estimateLineCount(item.original);
                          const actualIndex = indexOfFirstItem + index;
                          const isChecked = checkedItems.includes(actualIndex);

                          {/* status value */}
                          const statusValue = translatedParagraphs[actualIndex].status;

                          let buttonClass;
                          if (statusValue === 'AI') {
                            buttonClass = 'btn-soft-danger';
                          } else if (statusValue === 'Pending') {
                            buttonClass = 'btn-soft-warning';
                          } else if (statusValue === 'Human') {
                            buttonClass = 'btn-soft-info';
                          } else if (statusValue === 'Confirmed') {
                            buttonClass = 'btn-soft-success';
                          } else {
                            buttonClass = 'btn-soft-secondary';
                          }
                          {/* status value tail */}

                          return (
                            <tr 
                              className={actualIndex===selectedTranslateRow?"translate-row translate-row-selected":
                              "translate-row"}
                              key={actualIndex}
                              onClick={()=>{ 
                                handleRowClick(actualIndex); 
                              }}
                            >

                              {/* 段落編號 */}
                              <td>
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id={`checkbox-${index}`}
                                  checked={isChecked}
                                  onChange={()=>{}}
                                  onClick={
                                    e=> handleCheckboxChange(actualIndex, e.target.checked)
                                  }
                                />
                                &nbsp;&nbsp;{actualIndex+1}
                              </td>

                              {/* 原文 */}
                              <td style={{ 
                                padding: "0.5rem",
                                paddingRight: '20px', 
                                borderBottom: "1px solid #ababab" 
                              }}>
                                {item.original}
                              </td>

                              {/* 譯文 */}
                              <td 
                                onDoubleClick={() => toggleEdit(actualIndex)}
                                style={{ 
                                  borderBottom: "1px solid #ababab",
                                  /*display: 'flex',
                                  flexDirection: 'column',
                                  alignContent: 'stretch',
                                  flexWrap: 'wrap',
                                  justifyContent: 'center',
                                  alignItems: 'stretch',*/
                                }}
                              >
                                {/* 編輯譯文 TextArea */}
                                {editingId===actualIndex ? (
                                  <Row>
                                    <Col sm="12" xs="12" style={{ marginBottom: "0.5rem" }}>
                                      <textarea
                                        key={item.id}
                                        className="form-control scrollable-div"
                                        value={textAreaValues[actualIndex]}
                                        onChange={(e) => handleTextAreaChange(actualIndex, e.target.value)}
                                      />
                                    </Col>
                                    <Col sm="12" xs="12" style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "0.5rem" }}>
                                      <div style={{ display:"flex", justifyContent:"center", width: "100%" }}>
                                        <button 
                                          className="btn btn-outline-success"
                                          onClick={() => editSingleParagraph(item, actualIndex)}
                                          style={{
                                              height: '2rem',
                                              width: '30%',
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              padding: 0,
                                          }}
                                        >
                                          {/*<i className="fas fa-pen"></i>*/}
                                          <i className="fas fa-check"></i>
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                ) : (
                                  translatedParagraphs[actualIndex].isFetching ? (
                                    // Display the placeholder div when isFetching is true
                                    <div className="placeholder-glow">
                                      {Array.from({ length: lineCount }, (_, idx) => (
                                        <span key={idx} className="placeholder col-12" style={{ cursor: "default" }}></span>
                                      ))}
                                    </div>
                                  ) : item.translation ? (
                                    // Display the translation if isFetching is false and translation exists
                                    item.translation
                                  ) : (
                                    <div className="placeholder-xs col-12">
                                      {Array.from({ length: lineCount }, (_, idx) => (
                                        <span key={idx} className="placeholder col-12" style={{ cursor: "default" }}></span>
                                      ))}
                                    </div>
                                  )
                              )}
                              </td>

                              {/* 狀態 */}
                              <td style={{ textAlign:"center" }}>
                                {/*<ParagraphStatus status={project.status} />*/}
                                <div>
                                  <div className="btn-group mb-2">
                                    <ButtonDropdown
                                      isOpen={dropdownStates[actualIndex]}
                                      toggle={() => toggleDropdown(actualIndex)}
                                    >
                                      <DropdownToggle
                                        caret
                                        className={`btn ${buttonClass} btn-sm`}
                                        style={{ width: "7rem" }}
                                      >
                                        {statusValue || 'No translation'}
                                        <i className="mdi mdi-chevron-down" />
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem onClick={()=>changeDropdownValue(actualIndex, 'AI')}>AI translated</DropdownItem>
                                        <DropdownItem onClick={()=>changeDropdownValue(actualIndex, 'Pending')}>Pending edit</DropdownItem>
                                        <div className="dropdown-divider"></div>
                                        <DropdownItem onClick={()=>changeDropdownValue(actualIndex, 'Human')}>Human translated</DropdownItem>
                                        <div className="dropdown-divider"></div>
                                        <DropdownItem onClick={()=>changeDropdownValue(actualIndex, 'Confirmed')}>Confirmed</DropdownItem>
                                      </DropdownMenu>
                                    </ButtonDropdown>
                                  </div>
                                  {translatedParagraphs[actualIndex].reference && 
                                    (<p>
                                      {`{${(
                                        translatedParagraphs[actualIndex].
                                        reference.map(r=>parseFloat(r.similarity)*100).
                                        sort(function(a, b){return b-a})[0]?.toFixed(1) || 0)}`+'%}'}
                                    </p>)
                                  }
                                </div>
                              </td>

                              {/* AI 翻譯按鈕 */}
                              <td>
                                <div style={{ display:"flex", justifyContent:"center" }}>
                                  <button 
                                      className="btn btn-outline-secondary"
                                      onClick={() => translateSingleParagraph(item, actualIndex)}
                                      style={{
                                          height: '2rem',
                                          width: '2rem',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          padding: 0,
                                          margin: '0.5rem 1rem',
                                      }}
                                  >
                                    AI
                                  </button>
                                </div>
                              </td>

                              {/* 更多按鈕 */}
                              <td>
                                <UncontrolledDropdown style={{ display:"flex", justifyContent:"center" }}>
                                  {/* Drop down for edit actions */}
                                  <DropdownToggle className="card-drop" tag="a" >
                                    <i className="mdi mdi-dots-horizontal font-size-18" />
                                  </DropdownToggle>

                                  <DropdownMenu className="dropdown-menu-end">
                                    {/* 檢視參考資料 */}
                                    <DropdownItem onClick={() => handleReferenceClick(actualIndex)} >
                                      <i className="mdi mdi-book-outline font-size-16 text-success me-1"></i>
                                      {" "} 檢視參考資料
                                    </DropdownItem>
                                    {/* 另一個 action */}
                                    {/*
                                    <DropdownItem onClick={() => onClickDelete(project)} >
                                      <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
                                      Delete
                                    </DropdownItem>
                                    */}
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>

                            </tr>)

                        {!currentItems && (<p>No related paragraphs found.</p>)}

                        })}
                      </tbody>
                    </table>
                    
                  </div>
                </Col>)}

              </Row>

              {/* Pagination Controls */}
              <div style={{ marginTop:'1.5rem' }}>
                <Pagination currentPage={currentPage} totalPages={totalPages} paginate={paginate} />
              </div>

              {/*
              <h5 className="font-size-15 mt-4">專案簡述：</h5>

              <p className="text-muted">
                {get(project, "projectDetails.description")}
              </p>

              <div className="text-muted mt-4">
                {project.projectDetails &&
                  map(project.projectDetails.points, (point, index) => (
                    <p key={index}>
                      <i className="mdi mdi-chevron-right text-primary me-1" />{" "}
                      {point}
                    </p>
                  ))}
              </div>
              */}

            </CardBody>
          </Card>

        </Col>
        {/* Main Content Tail */}

        {/* Right Side Bar */}
        {isSidebarVisible && (
          <Col 
            xl="3"
            sm="3"
            xs="12"
            className='right-sidebar'
            style={{ 
              position: 'fixed', 
              //top: `${sidebarTop}px`,
              top: '6rem',
              height: 'calc(100vh - 0px)',
              right: '0px', 
              overflowY: 'hidden',
              padding: '0px',
            }}
          >
            {/* Right Side Bar */}
            <Card 
              style={{
                height: 'calc(-7rem + 100vh)',
                border: '1px solid rgb(168 178 200)',
                borderRight: 'none',
                borderRadius: '3.5px',
                background: '#fff9ed',
              }}
            >
              <div 
                style={{
                  minHeight: '5vh',
                  borderBottom: '1px solid #7ebaff',
                  padding: '0px'
                }}
              >
                <Row
                  style={{
                    display: 'flex',
                    height: '100%',
                    width: '100%',
                    margin: '0px',
                    alignItems: 'center',
                    flexWrap: 'nowrap',
                    overflowX: 'scroll',
                  }}
                  className="scrollable-div"
                >
                  <Col sm="4" 
                    style={{
                      display: 'flex',
                      height: '100%',
                      padding: '0px',
                      flexDirection: 'row',
                      alignContent: 'center',
                      justifyContent: 'center',
                      flexWrap: 'wrap',
                      background: selectedPanel==='A' ? '#aad7ff' : '#fce5b7',
                      cursor: 'pointer',
                      borderRight: '1px solid #a8b2c8',
                    }}
                    onClick={()=>{setSelectedPanel('A');}}
                  >
                    <div 
                      style={{
                        fontSize: '0.75rem',
                        padding: '0.2rem',
                      }}
                    >比對資料庫</div>
                  </Col>
                  <Col sm="4" 
                    style={{
                      display: 'flex',
                      height: '100%',
                      padding: '0px',
                      flexDirection: 'row',
                      alignContent: 'center',
                      justifyContent: 'center',
                      flexWrap: 'wrap',
                      background: selectedPanel==='B' ? '#aad7ff' : '#fce5b7',
                      cursor: 'pointer',
                      borderRight: '1px solid #a8b2c8',
                    }}
                    onClick={()=>{setSelectedPanel('B');}}
                  >
                    <div 
                      style={{
                        fontSize: '0.75rem',
                        padding: '0.2rem',
                      }}
                    >DeepL</div>
                  </Col>

                  <Col sm="4" 
                    style={{
                      display: 'flex',
                      height: '100%',
                      padding: '0px',
                      flexDirection: 'row',
                      alignContent: 'center',
                      justifyContent: 'center',
                      flexWrap: 'wrap',
                      background: selectedPanel==='C' ? '#aad7ff' : '#fce5b7',
                      cursor: 'pointer',
                      borderRight: '1px solid #a8b2c8',
                    }}
                    onClick={()=>{setSelectedPanel('C');}}
                  >
                    <div>參考資料</div>
                  </Col>

                  <Col sm="4" 
                    style={{
                      display: 'flex',
                      height: '100%',
                      padding: '0px',
                      flexDirection: 'row',
                      alignContent: 'center',
                      justifyContent: 'center',
                      flexWrap: 'wrap',
                      background: selectedPanel==='D' ? '#aad7ff' : '#fce5b7',
                      cursor: 'pointer',
                      borderRight: '1px solid #a8b2c8',
                    }}
                    onClick={()=>{setSelectedPanel('D');}}
                  >
                    <div>設定</div>
                  </Col>
                </Row>
              </div>

              <CardBody style={{ padding: '0.8rem 0.8rem 0.4rem 0.8rem', overflow: "scroll" }}>

                <div 
                  className='scrollable-div'
                  ref={scrollableDivRef} 
                  style={{ overflowX: "hidden", overflowY: "scroll", maxHeight: "100%" }}
                >
                  {selectedPanel==='A' &&
                    <>
                      <Row style={{ marginBottom: '0.5rem' }}>
                        <Col sm="12" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                          <input type="checkbox" id="diffCheckBox" defaultChecked="true"
                            onClick={(e) => { setShowDiffTextA(e.target.checked)}}
                          />
                          <label style={{ marginLeft: "0.5rem", marginBottom: "0rem" }} htmlFor="diffCheckBox">顯示段落差異</label>
                        </Col>
                      </Row>

                      <FoldableSection 
                        defaultOpen={true}
                        title={`選取文字（段落${selectedTranslateRow+1}）：`}
                      >
                        {select123 && <p style={{ marginBottom: '0rem' }}>{`${select123}`}</p> }
                      </FoldableSection>

                      {/* 分隔線 */}
                      <Row>
                        <Col sm="12">
                          <div style={{margin:"1.5rem 0rem", borderBottom:"1px solid #828282"}}>
                          </div>
                        </Col>
                      </Row>

                      {translatedParagraphs[selectedTranslateRow].reference.length>0 ? (
                        <Row>
                          <Col
                            style={{ marginBottom: '1rem' }}
                          >
                            <h5>相似度資料：</h5>
                          </Col>
                        </Row>
                        ) : (
                        <Row>
                          <Col 
                            sm="12" 
                            style={{ 
                              display: 'flex',
                              flexWrap: 'wrap',
                              justifyContent: 'center',
                              marginBottom: '1.5rem',
                            }}
                          >
                            <Button 
                              color="outline-warning"
                              onClick={()=>{clickDiff();}}
                            >
                              {diffLoading &&
                                (<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>)
                              }
                              開始比對
                            </Button>
                          </Col>
                        </Row>
                      )}

                      {!translatedParagraphs[selectedTranslateRow].reference.length>0 && 
                        searchTMResult && searchTMResult.map((resItem, index) => {

                        //console.log('resItem', resItem);
                        const similarity = (parseFloat(resItem.similarity)*100).toFixed(1) + '%';
                        const paragraph = resItem.paragraph;
                        const secondLang = resItem.p_secondLang;
                        const glossaryId = resItem.glossaryId;
                        const matchedGlossary = dbs.filter(d=>d._id===glossaryId)[0] || baseDbs.filter(b=>b._id===glossaryId)[0];
                        const glossaryName = matchedGlossary.dbName;

                        //console.log('glossaryName', glossaryName);
                        //const diffText = textDiff(select123, paragraph);
                        //console.log('diffText:', diffText);

                        return (
                          <SearchResultItem
                            key={index}
                            select123={select123} 
                            paragraph={paragraph}
                            similarity={similarity}
                            glossaryName={glossaryName}
                            secondLang={secondLang}
                            showDiffText={showDiffTextA}
                          />
                        );

                      })}

                      {translatedParagraphs[selectedTranslateRow] && 
                        translatedParagraphs[selectedTranslateRow].reference.map((resItem, index) => {

                        //console.log('resItem', resItem);
                        const similarity = (parseFloat(resItem.similarity)*100).toFixed(1) + '%';
                        const paragraph = resItem.paragraph;
                        const secondLang = resItem.p_secondLang;
                        const glossaryId = resItem.glossaryId;
                        const matchedGlossary = dbs.filter(d=>d._id===glossaryId)[0] || baseDbs.filter(b=>b._id===glossaryId)[0];
                        const glossaryName = matchedGlossary?.dbName;

                        return (
                          <SearchResultItem
                            key={index}
                            select123={select123} 
                            paragraph={paragraph}
                            similarity={similarity}
                            glossaryName={glossaryName}
                            secondLang={secondLang}
                            showDiffText={showDiffTextA}
                          />
                        );

                      })}

                    </>
                  }

                  {selectedPanel==='B' &&
                    <>
                      <FoldableSection 
                        defaultOpen={true}
                        title={`選取文字（段落${selectedTranslateRow+1}）：`}
                      >
                        {select123 && <p style={{ marginBottom: '0rem' }}>{`${select123}`}</p> }
                      </FoldableSection>

                      {/* 分隔線 */}
                      <Row>
                        <Col sm="12"><div style={{margin:"1.5rem 0rem", borderBottom:"1px solid #828282"}}></div></Col>
                      </Row>

                      <p style={{ fontWeight: '1000' }}>DeepL 翻譯結果：</p>
                      {(select123 && !deepLResult) ? 
                        <div style={{ display:'flex', justifyContent: 'center' }}><Spinner className="ms-2" color="primary" /></div> :
                        <p style={{ marginBottom: '0rem' }}>{`${deepLResult}`}</p>
                      }
                      
                    </>
                  }

                  {selectedPanel==='C' &&
                    <>

                      <FoldableSection title="選取段落：">
                        {selectedTranslateRow>=0 && 
                          <p style={{ marginBottom: '0rem' }}>
                            {`${translatedParagraphs[selectedTranslateRow].original}`}
                          </p>
                        }
                      </FoldableSection>

                      {selectedTranslateRow>=0 && 
                        <>
                          {/* 分隔線 */}
                          <Row>
                            <Col sm="12">
                              <div style={{ margin:"1.5rem 0rem", borderBottom:"1px solid #828282" }}>
                              </div>
                            </Col>
                          </Row>

                          <FoldableSection title="TheChart 翻譯：" 
                            style={{ marginBottom: "1rem" }}>
                            <p style={{ marginBottom: '0rem' }}>
                              {translatedParagraphs[selectedTranslateRow].translation || "[未進行 AI 翻譯]"}
                            </p>
                          </FoldableSection>

                          <FoldableSection title="ChatGPT 翻譯：" 
                            style={{ marginBottom: "1rem" }}>
                            <p style={{ marginBottom: '0rem' }}>
                              {translatedParagraphs[selectedTranslateRow].simpleTM || "[未進行 AI 翻譯]"}
                            </p>
                          </FoldableSection>

                          {/* 分隔線 */}
                          <Row>
                            <Col sm="12">
                              <div style={{ margin:"1.5rem 0rem", borderBottom:"1px solid #828282" }}>
                              </div>
                            </Col>
                          </Row>

                          <Row style={{ marginBottom: '0.5rem' }}>
                            <Col sm="12" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                              <input type="checkbox" id="diffCheckBox" defaultChecked="true"
                                onClick={(e) => { setShowDiffTextC(e.target.checked)}}
                              />
                              <label style={{ marginLeft: "0.5rem", marginBottom: "0rem" }} htmlFor="diffCheckBox">顯示段落差異</label>
                            </Col>
                          </Row>

                          <FoldableSection 
                            title="參考資料：" 
                            defaultOpen={true}
                            canLock={true} 
                            toggleLocked={toggleLocked}
                            isLocked={isLocked}
                            style={{ marginBottom: "1rem" }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignContent: 'center',
                                marginBottom: '0.8rem',
                              }}
                            >
                              <Button
                                color="outline-secondary"
                                style={{
                                  marginBottom: '0rem',
                                  height: '1.5rem',
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  alignContent: 'center',
                                  width: '80%',
                                  justifyContent: 'center',
                                }}
                                onClick={()=>{ handleReferenceClick(selectedTranslateRow); }}
                              >
                                編輯參考資料
                              </Button>
                            </div>

                            {/*
                            {translatedParagraphs[selectedTranslateRow].reference.map((rItem, index)=>{

                              console.log('baseDbs:', baseDbs);
                              console.log('dbs:', dbs);
                              console.log('rItem:', rItem);

                              const matchedDb = dbs.filter(d=>d._id===rItem.glossaryId)[0]?.dbName || 
                                '(底) '+baseDbs.filter(b=>b._id===rItem.glossaryId)[0]?.dbName;

                              return(
                                <div key={index}>
                                  <p style={{ fontWeight: "800", marginBottom: "0.3rem" }}>
                                    <span>{`[${(rItem.similarity*100).toFixed(1)}%]`}</span>
                                    <span style={{ marginLeft: "0.2rem" }}>{`${matchedDb || '未知的資料庫'}`}</span>
                                  </p>
                                  <p style={{ marginLeft: "0.2rem" }}>{rItem.paragraph}</p>
                                  <p>{`->${rItem.p_secondLang}`}</p>
                                </div>
                              )
                            })}
                            */}

                            {translatedParagraphs[selectedTranslateRow] && 
                              translatedParagraphs[selectedTranslateRow].reference.map((resItem, index) => {

                              //console.log('resItem', resItem);
                              const similarity = (parseFloat(resItem.similarity)*100).toFixed(1) + '%';
                              const paragraph = resItem.paragraph;
                              const secondLang = resItem.p_secondLang;
                              const glossaryId = resItem.glossaryId;
                              const matchedGlossary = dbs.filter(d=>d._id===glossaryId)[0] || baseDbs.filter(b=>b._id===glossaryId)[0];
                              const glossaryName = matchedGlossary?.dbName;

                              return (
                                <SearchResultItem
                                  key={index}
                                  select123={select123} 
                                  paragraph={paragraph}
                                  similarity={similarity}
                                  glossaryName={glossaryName}
                                  secondLang={secondLang}
                                  showDiffText={showDiffTextC}
                                />
                              );

                            })}

                          </FoldableSection>
                          

                        </>
                      }

                    </>
                  }

                  

                </div>
                
              </CardBody>
            </Card>
          </Col>
        )}
        {/* Right Side Bar Tail */}

      </Row>

      <Button 
        color="info" 
        onClick={toggleSidebar} 
        style={{ 
          position: 'fixed', 
          right: 0, 
          top: '50%',
          height: '5rem',
          fontSize: '0.8rem',
          padding: '0rem 0.3rem 0rem 0.4rem',
          borderRadius: '0.5rem 0rem 0rem 0.5rem',
          opacity: isSidebarVisible ? '0.3' : '1',
        }}
      >
        {isSidebarVisible ? 
          "\u25B6" : // Unicode for right-pointing triangle
          "\u25C0" // Unicode for left-pointing triangle
        } 
      </Button>

    </React.Fragment>

  );
};

ProjectDetail.propTypes = {
  project: PropTypes.object,
};

export default ProjectDetail;
