import React, { useState, useEffect } from "react";

const Terms = props => {

  /*---- Title and Initial Setup ----*/

  document.title = "網站使用條款 | TheChart.AI";

  return (
    <React.Fragment>
        <div>
            <h1>網站使用條款</h1>
            <p>請在使用本平台前仔細閱讀本使用條款。在任何電腦、手機、平板電腦、控制台或者其他裝備（以下統稱“裝備”）上使用本網站、行動應用程式或服務(“本平台”)，即表明您已經閱讀、理解並同意
            受本使用條款和其他適用法律約束，不論您是否為本平台的註冊用戶。本平台有權在任何時間不經通知修改本使用條款，並於公布在本平台之時起生效。您如持續使用本平台， 即視為接受本使用條款的
            修訂。如果您不同意本使用條款，請勿使用本平台。</p>
        </div>
    </React.Fragment>
  );
};

export default Terms;