import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle, 
} from "reactstrap";
import { Link } from "react-router-dom";

import { nanoid } from "nanoid";
import './custom.css';

import cardImg1 from '../../assets/images/small/knovia1.jpeg';
import cardImg2 from '../../assets/images/small/knovia14.png';
import cardImg3 from '../../assets/images/small/knovia5.png';
import cardImg4 from '../../assets/images/small/knovia9.png';
import cardImg5 from '../../assets/images/small/knovia2.png';
import cardImg6 from '../../assets/images/small/knovia15.jpeg';

// Main Activity Comp Component
const ActivityComp = (props) => {

  /*---- Card Prompts ----*/

  const [cardPrompts, setCardPrompts] = useState([]);

  const promptInput = [
    { order: 1, image: cardImg1, title:"文本生成", text: "幫我寫一個敘述喝咖啡對健康有益的講稿" },
    { order: 2, image: cardImg2, title:"翻譯", text: "參考中英對照資料，把這些文本從英文翻譯成中文" },
    { order: 3, image: cardImg3, title:"摘要", text: "幫我把這篇文章縮短成 50 字的摘要" },
    { order: 4, image: cardImg4, title:"客服對話", text: "我要去哪裡更改帳號資訊？" },
    { order: 5, image: cardImg5, title:"產品介紹", text: "可以介紹一下 TheChart.AI 的付費方案嗎？" },
    { order: 6, image: cardImg6, title:"篩選應徵者", text: "哪個應徵者最適合我在招的 App 工程師職位？" },
  ]

  useEffect(() => {

    const prompts = promptInput.map((prompt, index) => (

        <Col key={nanoid()} sm={4}>

          <Link to={prompt.url}>

            <div 
              className={`card card-prompt`} 
              style={{ "--bs-card-border-radius": "1rem 1rem 1rem 1rem" }}
            >
              <div className="no-gutters align-items-center row">

                {/* Image */}
                <div className="col-md-12">
                  <img 
                    src={prompt.image} 
                    style={{ width: "auto", borderRadius:"1rem 1rem 0rem 0rem" }} 
                    alt={`image_${prompt.order}`}
                    className="img-fluid card-img" 
                  />
                </div>

                {/* Text */}
                <div className="col-md-12">
                  <div 
                    className={`card-body card-body${index+1}`}
                    style={{ minHeight: "6.7rem" }}
                  >
                    <div className="card-title">{prompt.title}</div>
                    <p className="card-text">{prompt.text}</p>
                  </div>
                </div>

              </div>
            </div>

          </Link>

        </Col>
        
    ));

    setCardPrompts(prompts);

  }, []);

  /*---- Card Prompts Tail ----*/



  /*--- Return Component ---*/

  return (
    <React.Fragment>

      <Card>
        <CardBody>

          {/* Card Prompts */}
          <Row>

            {/* Card Title */}
            <Col sm={12}>
              <CardTitle className="mb-3" style={{ "marginTop":"0.5rem" }}>
                TheChart.AI 可以做什麼？
              </CardTitle>
            </Col>

            {/* Card Body */}
            <Col sm={12}>
              <Row className="row-prompt">
                {cardPrompts}
              </Row>
            </Col>

          </Row>

        </CardBody>
      </Card>

    </React.Fragment>
  );
};

export default ActivityComp;

