import React, { useState, useEffect } from "react";
import { 
  Alert,
  Row, 
  Col, 
  Card,
  CardBody, 
  Container,
  Input,
  Label,
  Form,
  FormFeedback
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";

//redux
import { registerUser, clearRegisterUser } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// Import Images
// The Ship
import profileImg from "../../assets/images/profile-img.png";
// The Card
import logoImg from "../../assets/images/placeholders/placeholder_card.png";

const registrationErrorMapping = {
  'A user with this email already exists.':'已經存在用此 email 申請的帳號',
}

// Main Registration Component
const Register = props => {


  /*---- Title and Initial Setup ----*/

  document.title = "註冊帳號 | TheChart.AI";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isRegistered, setIsRegistered] = useState(false);

  /*---- Title and Initial Setup ----*/



  /*---- Store and Actions ----*/

  // Store > auth > register
  const { registrationError, message, loading, user } = useSelector(state => ({
    registrationError: state.Account.registrationError,
    message: state.Account.message,
    loading: state.Account.loading,
    user: state.Account.user,
  }));

  // Clear apiError
  useEffect(() => {

  }, []);

  // When user registered successfully, go back to login
  useEffect(() => {
    if (user) {
      setIsRegistered(true);
      setTimeout(() => {
        setIsRegistered(false);
        navigate('/login');
        dispatch(clearRegisterUser());
      }, 3000);
    }
  }, [user, navigate]);

  /*---- Store and Actions Tail ----*/



  /*---- Registration Form ----*/

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email format")
        .required("Please Enter Your Email"),
      username: Yup.string()
       .matches(/^[A-Za-z0-9_-]*$/, "Username can only contain a-z A-Z letters, numbers, and - or _")
       .required("Please Enter Your Username"),
      password: Yup.string()
       .matches(/^[A-Za-z0-9-_$%^&*!]*$/, "Password can only contain letters, numbers, and -,_,$,%,^,&,*,!")
       .required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(registerUser(values));
    }
  });

  /*---- Registration Form Tail ----*/


  return (
    <React.Fragment>

      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          {/*<i className="bx bx-home h2" />*/}
          <i className="bx bx-log-in-circle h2"></i>
          {/*<i className="bx bx-lock-open h2"></i>*/}
          {/*<i className="bx bx-user h2"></i>*/}
        </Link>
      </div>

      <div className="account-pages pt-sm-5">
        <Container>

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">


                {/* Title text in the Card */}
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">註冊新帳號</h5>
                        <p>立刻註冊體驗 TheChart.AI 平台</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>


                {/* Body of the Card */}
                <CardBody className="pt-0">

                  {/* Card Image Circle */}
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            style={{ height: "100%", width: "100%" }}
                          />
                        </span>
                      </div>
                    </Link>
                  </div>

                  {/* Form containing Email and Password*/}
                  <div className="p-2">

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >

                      {/* Show Successful Message */}
                      {isRegistered && user ? (
                        <Alert color="success">
                          { message }
                        </Alert>
                      ) : null}

                      {/* Show Error Message */}
                      {registrationError ? (
                        <Alert color="danger">
                          {registrationErrorMapping[registrationError] || registrationError}
                        </Alert>
                      ) : null}

                      {/* Input Email */}
                      <div className="mb-3">
                        <Label className="form-label" for="email">Email</Label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="輸入註冊 Email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      {/* Input Username */}
                      <div className="mb-3">
                        <Label className="form-label" for="username">使用者名稱</Label>
                        <Input
                          id="username"
                          name="username"
                          type="text"
                          placeholder="輸入使用者名稱"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ""}
                          invalid={
                            validation.touched.username && validation.errors.username ? true : false
                          }
                        />
                        {validation.touched.username && validation.errors.username ? (
                          <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                        ) : null}
                      </div>

                      {/* Input Password */}
                      <div className="mb-3">
                        <Label className="form-label" for="password">密碼</Label>
                        <Input
                          id="password"
                          name="password"
                          type="password"
                          placeholder="輸入註冊密碼"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      {/* Create Account Button, triggers onSubmit upon click */}
                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit"
                        >
                          創建帳號
                        </button>
                      </div>

                      {/* Warning */}
                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          註冊代表您同意 TheChart.AI 平台服務條款{" "}<br/>
                          <Link to="/terms" className="text-primary">
                            TheChart.AI 平台使用條款
                          </Link>
                        </p>
                      </div>

                    </Form>
                  </div>
                  {/* End of Registration Form */}

                </CardBody>
              </Card>
              {/* Body of the Card Tail */}

              {/* Back to Login Link */}
              <div className="mt-5 text-center">
                <p>
                  已有帳號？{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}登入
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} TheChart.AI Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> in Taipei
                </p>
              </div>
              {/* Back to Login Link Tail */}

            </Col>
          </Row>

        </Container>

      </div>
    </React.Fragment>
  );
};

export default Register;
