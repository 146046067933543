import React from 'react';

const Date = (cell) => { return cell.value ? cell.value+"*" : ''; };
const Masterknowledge = (cell) => { return cell.value ? cell.value : ''; };
const Masterquestion = (cell) => { return cell.value ? cell.value : ''; };

const TimeStamp = (cell) => {
  const formattedDate = `${cell.value.substring(0, 4)}`
    +`/${cell.value.substring(4, 6)}`
    +`/${cell.value.substring(6, 8)}`
    +` ${cell.value.substring(8, 10)}`
    +`:${cell.value.substring(10, 12)}`
    +`:${cell.value.substring(12, 14)}`
  return formattedDate ? `${formattedDate}` : '';
};

export {
  Date,
  Masterknowledge,
  Masterquestion,
  TimeStamp,
};