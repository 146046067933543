import React from "react";

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hour = String(date.getHours()).padStart(2, '0');
  const minute = String(date.getMinutes()).padStart(2, '0');
  const second = String(date.getSeconds()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const Pagination = ({ currentPage, totalPages, paginate }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '2rem' }}>
      <button
        onClick={() => paginate(1)}
        disabled={currentPage === 1}
        style={{ margin: '0 5px' }}
        className="btn btn-primary"
      >
        &lt;&lt;
      </button>
      <button
        onClick={() => paginate(currentPage - 1)}
        disabled={currentPage === 1}
        style={{ margin: '0 5px' }}
        className="btn btn-primary"
      >
        &lt;
      </button>
      <span style={{ margin: '0 10px' }}>
        Page {currentPage} of {totalPages}
      </span>
      <input 
        type="text" 
        value={currentPage}
        style={{ width: '40px', textAlign: 'center', margin: '0 10px' }}
        onChange={() => paginate(2)}
      />
      <button
        onClick={() => paginate(currentPage + 1)}
        disabled={currentPage === totalPages}
        style={{ margin: '0 5px' }}
        className="btn btn-primary"
      >
        &gt;
      </button>
      <button
        onClick={() => paginate(totalPages)}
        disabled={currentPage === totalPages}
        style={{ margin: '0 5px' }}
        className="btn btn-primary"
      >
        &gt;&gt;
      </button>
    </div>
  );
};


