import React, { useState, useEffect } from "react";
import {
  Alert,
  Col,
  Row,
  Button,
  Container,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown
} from "reactstrap";
import withRouter from "components/Common/withRouter";
import { Link } from "react-router-dom";

// Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

// Import Card
import CardContact from "./CardContact";
import NewDataModal from "./NewDataModal";

// Custom Utils
import { Pagination } from 'utils/pagination';

// Redux
import { useSelector, useDispatch } from "react-redux";

// Custom Axios
import axiosInstance from 'utils/axiosInstance';

// Custom CSS
import './custom.css';

// Custom Image
import placeholder from "assets/images/placeholders/placeholder_card.png"


const DbList = () => {


  /*---- Title and Initial Setup ----*/

  document.title = "資料庫總覽 | TheChart.AI";
  const dispatch = useDispatch();
  const [sortedDbs, setSortedDbs] = useState([]);
  const [isAddNewDbModalOpen, setIsAddNewDbModalOpen] = useState(false);

  /*---- Title and Initial Setup Tail ----*/


  /*---- Getting Database from Redux ----*/

  // state.projects.projects is set through fetchUserData
  const { databases, dbImages, userData } = useSelector(state => ({
    databases: state.databases.databases,
    dbImages: state.databases.databaseImages,
    userData: state.user.userData,
  }));

  /*---- Getting Database from Redux Tail ----*/
  


  /*---- Search Input ----*/

  const [searchValue, setSearchValue] = useState("");
  const [filterOption, setFilterOption] = useState('dbName');

  const filterOptionMappings = {
    'dbName': '資料庫名稱',
    'dbClient': '客戶名稱',
    'tags': '標籤',
  };

  // A generic filter function
  const applyFilter = (db) => {
    const searchLower = searchValue.toLowerCase();

    if (filterOption === 'tags') {
      // Check if any tag matches the search value
      return db.tags.some(tag => tag.toLowerCase().includes(searchLower));
    } else {
      // Handle other string-based filters
      const filterValue = db[filterOption] ? db[filterOption].toLowerCase() : '';
      return filterValue.includes(searchLower);
    }
  };

  /*---- Search Input Tail ----*/

  

  /*---- Sorting Function ----*/

  const [sortAttribute, setSortAttribute] = useState('dbName');
  const [sortDirection, setSortDirection] = useState('asc'); // 'asc' or 'desc'

  const sortDirectionMappings = { 'asc': '升序', 'desc': '降序' };
  const sortAttributeMappings = {
    'dbName': '資料庫名稱',
    'dbClient': '客戶名稱',
    'tags': '標籤',
    'favorite': '我的最愛',
  };

  // Function to toggle sort direction
  const toggleSortDirection = () => {
    setSortDirection(prevDirection => prevDirection === 'asc' ? 'desc' : 'asc');
  };

  // Function to sort the data
  const sortData = (a, b) => {
    const isAFavorite = (favoriteDbs ? favoriteDbs.includes(a._id) : 0);
    const isBFavorite = (favoriteDbs ? favoriteDbs.includes(b._id) : 0);

    if (sortAttribute === 'favorite') {
      if (isAFavorite && !isBFavorite) {
        return sortDirection === 'asc' ? -1 : 1;
      }
      if (!isAFavorite && isBFavorite) {
        return sortDirection === 'asc' ? 1 : -1;
      }
      // If both are favorites or both are not, sort by another attribute, like dbName
      return a.dbName.localeCompare(b.dbName);
    } else {
      // Rest of your sorting logic for other attributes
      if (a[sortAttribute] < b[sortAttribute]) { return sortDirection === 'asc' ? -1 : 1 }
      if (a[sortAttribute] > b[sortAttribute]) { return sortDirection === 'asc' ? 1 : -1 }
    }
    return 0;
  };

  /*---- Sorting Function Tail ----*/



  /*---- Favorite Function ----*/

  const [favoriteDbs, setFavoriteDbs] = useState([]);
  useEffect(()=>{
    setFavoriteDbs(userData?.stats?.favoriteDbs);
    //console.log('favoriteDbs:', userData?.stats?.favoriteDbs);
  },[userData]);
  useEffect(()=>{
    setSortedDbs(databases.filter(applyFilter).sort(sortData));
  }, [databases, favoriteDbs, sortAttribute, sortDirection, searchValue, filterOption]);

  const toggleFavorite = async (dbId) => {
    try {
      // Optimistically update the state
      const isCurrentlyFavorite = favoriteDbs.includes(dbId);
      const newFavorites = isCurrentlyFavorite 
        ? favoriteDbs.filter(id => id !== dbId)
        : [...favoriteDbs, dbId];
      setFavoriteDbs(newFavorites);

      const response = await axiosInstance.post('/knovia/toggle-favoritedb', {db: dbId});

      if (response.status !== 200) {
        setFavoriteDbs(favoriteDbs); // revert the state
        console.error('Failed to update favorite status with status:', response.status);
      }
      // console.log('favoriteDbs', response.data);
      setFavoriteDbs(response.data);

      /*---- PENDING: Should just update the store instead of fetching again ----*/
      dispatch({ type: 'FETCH_USER_DATA' });

    } catch (error) {
      setFavoriteDbs(favoriteDbs); // revert to the original favorites
      console.error('Error:', error);
    }
  };

  /*---- Favorite Function Tail ----*/



  /*---- Pagination START ----*/

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [totalPages, setTotalPages] = useState(1);
  const [currentItems, setCurrentItems] = useState([]);

  // Updating current items, items viewable in current page
  useEffect(() => {
    const newLastItem = currentPage * itemsPerPage;
    const newFirstItem = newLastItem - itemsPerPage;
    setCurrentItems(sortedDbs.slice(newFirstItem, newLastItem));
  }, [currentPage, itemsPerPage, sortedDbs]);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  // Set and Reset totalPage when paragraphs changes
  useEffect(() => { setTotalPages(Math.ceil(sortedDbs.length / itemsPerPage)) }, [sortedDbs, itemsPerPage]);
  // If itemsPerPage changed by user
  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(Number(value)); // event.target.value is the chosen value
    setCurrentPage(1); // Reset to the first page
  };
  const itemsPerPageMappings = {
    '4': '4',
    '8': '8',
    '12': '12',
  }
  
  /*---- Pagination TAIL ----*/



  /*---- Return JSX ----*/

  return (
    <React.Fragment>

      {/* 新增資料庫 Modal */}
      <NewDataModal isAddNewDbModalOpen={isAddNewDbModalOpen} setIsAddNewDbModalOpen={setIsAddNewDbModalOpen} />

      {/* Div Containing Card of Databases */}
      <div className="page-content">
        <Container fluid>

          {/* Render Breadcrumbs */}
          <Breadcrumbs title="資料庫" breadcrumbItem="資料庫總覽" />


          {/* 新增資料庫 Button */}
          <Row style={{ marginBottom:"1rem" }}>
            <Col sm="3">
              <Button 
                color="outline-info" 
                onClick={() => setIsAddNewDbModalOpen(true)}
              >
                + 新增空白資料庫
              </Button>
            </Col>
          </Row>
          {/* 新增資料庫 Button Tail */}


          {/* Searching, Sorting, and ItemsPerPage */}
          <Row>
            <Col sm="12" md="7" lg="7" xl="5" 
              style={{ 
                display:"flex"
              }}
            >

              {/* 篩選條件 Dropdown */}
              <div
                style={{ 
                display:"flex",
                flexWrap: "wrap",
                alignContent: "center",
                marginRight: "0.5rem"
              }}
              >
                <UncontrolledDropdown>
                  <DropdownToggle tag="button" className="btn btn-outline-info">
                    {`搜尋條件：${filterOptionMappings[filterOption]}`} 
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>

                  <DropdownMenu>
                    {Object.entries(filterOptionMappings).map(([key, label]) => (
                      <DropdownItem key={key} onClick={() => setFilterOption(key)}>
                        {label}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              {/* 篩選條件 Dropdown Tail */}


              {/* 輸入篩選 Input */}
              <div 
                className="search-box" 
                style={{
                  display: "flex",
                  alignContent: "center",
                  flexWrap: "wrap",
                  marginTop: "0.4rem"
                }}
              >
                <div className="position-relative">
                  <label htmlFor="search-bar-0" className="search-label">
                    <span id="search-bar-0-label" className="sr-only">Search this table</span>
                    <input 
                      id="search-bar-0" 
                      type="text" 
                      className="form-control" 
                      placeholder={`搜尋 ${databases.length} 筆資料`}
                      value={searchValue}
                      onChange={(e)=>{
                        setSearchValue(e.target.value);
                        //console.log('search changed!', e.target.value);
                      }}
                    />
                  </label>
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
              </div>
              {/* 輸入篩選 Input Tail */}

            </Col>


            <Col sm="7" md="5" lg="5" xl="4" 
              className="custom-sorting-col"
              style={{ display: "flex" }}
            >

              <div 
                style={{ 
                  display: "flex",
                  flexWrap: "wrap",
                  alignContent: "center",
                  marginRight: "0.5rem"
                }}
              >
                {/* Dropdown for selecting sort attribute */}
                <UncontrolledDropdown>
                  <DropdownToggle tag="button" className="btn btn-outline-secondary">
                    排序條件: {sortAttributeMappings[sortAttribute]}
                  </DropdownToggle>
                  <DropdownMenu>
                    {Object.keys(sortAttributeMappings).map(key => (
                      <DropdownItem key={key} onClick={() => setSortAttribute(key)}>
                        {sortAttributeMappings[key]}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>

              {/* Dropdown for selecting sort direction */}
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignContent: "center",
                }}
              >
                <button className="btn btn-outline-secondary" onClick={toggleSortDirection}>
                  排序方向: {sortDirectionMappings[sortDirection]}
                </button>
              </div>

            </Col>


            <Col xs="12" sm="5" md="12" lg="12" xl="3"
              className="custom-item-per-page-col"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
              }}
            >
                {/* Dropdown for selecting sort attribute */}
                <UncontrolledDropdown>
                  <DropdownToggle tag="button" className="btn btn-outline-warning">
                    每頁顯示段落數：{itemsPerPage}
                  </DropdownToggle>
                  <DropdownMenu>
                    {Object.keys(itemsPerPageMappings).map(key => (
                      <DropdownItem key={key} onClick={() => handleItemsPerPageChange(key)}>
                        {itemsPerPageMappings[key]}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>

            </Col> 
             
          </Row>

          {/* Div Margin */}
          <Row style={{ marginBottom:"1rem" }}>
            <Col sm="9"></Col>
          </Row>


          {/* Cards of the Dbs */}
          <Row style={{ minHeight: "20rem" }}>
            {currentItems.length > 0 ? (
             currentItems
              .map((db, key) => (
                <CardContact 
                  db={db} 
                  dbImage={dbImages[db._id]} 
                  favoriteDbs={favoriteDbs}
                  key={"_db_" + key}
                  onToggleFavorite = {toggleFavorite}
                />
              ))
            ) : (
              <Row>
                <Col sm="12"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: 'fit-content',
                  }}
                >
                  <Alert className="mt-4" color="success"
                    style={{
                      paddingLeft: '15vw',
                      paddingRight: '15vw',
                    }}
                  >
                    尚未有客製化資料庫，前往
                    <Link to="/dashboard-dbexcel" className="text-primary custom-page-link">
                      新增客製化資料庫
                    </Link>
                  </Alert>
                </Col>
              </Row>
            )}
          </Row>
          {/* Cards of the Dbs Tails */}


          {/* Pagination Controls */}
          <div style={{ marginBottom:"1.5rem" }}>
            <Pagination currentPage={currentPage} totalPages={totalPages} paginate={paginate} />
          </div>
          {/* Pagination Controls Tail */}


        </Container>
      </div>
      {/* Div Containing Card of Databases Tail */}

    </React.Fragment>
  );
};

export default withRouter(DbList);
