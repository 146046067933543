import React, { useState } from "react";
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  UncontrolledTooltip,
} from "reactstrap";
import { Link } from "react-router-dom";

import { size, map } from "lodash";
import './custom.css';

// Custom Modal
import EditModal from "./EditModal";
import OrderModal from "./OrderModal";
import DeleteModal from "components/Common/DeleteModal";

// Redux
import { useDispatch } from "react-redux";

import {
  updateProduct as onUpdateProduct,
  deleteProduct as onDeleteProduct,
} from "store/actions";

const CardContact = ({ pd, pdImage, addNewItem, orderId }) => {


  /*---- States and Initial Settings ----*/

  // console.log('pd:', pd);
  // console.log('pdImage', pdImage);

  const dispatch = useDispatch();

  /*---- States and Initial Settings Tail ----*/



  /*---- Modal Editing a Project ----*/

  // State for Edit Modal Open or Close
  const [editModal, setEditModal] = useState(false);

  // Open and close the edit modal
  const toggle = () => {
    if (editModal) { setEditModal(false) } 
    else { setEditModal(true);}
  };

  /*---- Modal Editing a Project Tail ----*/



  /*---- Modal for having an order ----*/

  // State for Edit Modal Open or Close
  const [orderModal, setOrderModal] = useState(false);

  // Open and close the edit modal
  const toggleOrderModal = () => {
    if (orderModal) { setOrderModal(false) } 
    else { setOrderModal(true);}
  };

  /*---- Modal for having an order Tail ----*/



  /*---- Modal Deleting a Project ----*/

  // State for Delete Modal Open or Close
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (pd) => {
    setDeleteModal(true);
  };
  const handleCloseDeleteModal = () => { setDeleteModal(false) };
  // Deleting a project and close the delete modal
  const handleDeleteOrder = () => {
    if (pd && pd._id) {
      dispatch(onDeleteProduct(pd._id));
    }
    setDeleteModal(false);
  };

  /*---- Modal Deleting a Project Tail ----*/


  const productCardClicked = ()=>{
    setOrderModal(true);
  }
  


  return (
    <React.Fragment>


      {/*---- The edit modals ----*/}
      {/*<EditModal pd={pd} pdImage={pdImage} editModal={editModal} toggle={toggle}  />*/}

      <OrderModal 
        pd={pd} 
        pdImage={pdImage} 
        orderModal={orderModal} 
        toggle={toggleOrderModal}  
        addNewItem={addNewItem}
        orderId={orderId}
      />

      {/*---- The delete modals ----*/}
      <DeleteModal 
        show={deleteModal}
        onDeleteClick={handleDeleteOrder} 
        onCloseClick={handleCloseDeleteModal}
        title={`產品：[${pd?.pdName}]`}
      />

      {/*---- The cards ----*/}
      <Col xl="4" sm="6">
        <Card className="text-center" style={{ borderRadius: '3rem' }}>
          <CardBody onClick={productCardClicked} style={{ cursor:'pointer' }}>

            <Row 
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Col sm="8">

                <Row style={{ marginBottom:'5vh' }}>
                  <Col sm="12">
                    {/* Product Name and Client */}
                    <h5 className="font-size-15 mb-1">
                      {/*<Link to={`/chatbot-user/${pd._id}`} className="text-dark">*/}
                        {pd.pdName}
                      {/*</Link>*/}
                    </h5>
                    {/* Product Name and Client Tail */}
                  </Col>
                </Row>

                <Row>
                  <Col sm="12">
                    <h5 className="font-size-15 mb-1">{`NT$${pd.customizations.sellPrice}`}</h5>
                  </Col>
                </Row>

              </Col>
              <Col sm="4">

                {/* Product Img */}
                {!pdImage ? (
                  <div className="mb-4" style={{display: "flex", justifyContent: "center"}}>
                    <span 
                      className="avatar-bigger avatar-title rounded-circle bg-soft bg-primary text-primary font-size-16"
                    >
                      {pd.pdName.charAt(0)}
                    </span>
                  </div>
                ) : (
                  <div className="mb-4">
                    <img
                      className="rounded-circle avatar-bigger"
                      src={pdImage}
                      alt={pd.pdName || "User Image"}
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                )}
                {/* Product Img Tail */}

              </Col>
            </Row>


            {/* Attribute Tags */}
            {/*
            <div>
              {map(
                pd.tags,
                (tag, index) =>
                  index < 2 && (
                    <Link
                      to="#"
                      className="badge bg-primary font-size-11 m-1"
                      key={"_tag_" + pd._id + index}
                    >
                      {tag}
                    </Link>
                  )
              )}
              {size(pd.tags) > 2 && (
                <Link
                  to="#"
                  className="badge bg-primary font-size-11 m-1"
                  key={"_tag_" + pd._id}
                >
                  {size(pd.tags) - 2} + more
                </Link>
              )}
            </div>
            */}
            {/* Attribute Tags Tail */}


          </CardBody>


          {/* The Bottom Logos */}
          
          <CardFooter className="bg-transparent border-top">
            <div className="contact-links d-flex font-size-20">

              {/*
              <div className="flex-fill" onClick={() => setEditModal(!editModal)}>
                <Link to="#" id={"message" + pd._id}>
                  <i className="mdi mdi-pencil font-size-20 text-success me-1"></i>
                  <UncontrolledTooltip
                    placement="top"
                    target={"message" + pd._id}
                  >
                    編輯
                  </UncontrolledTooltip>
                </Link>
              </div>
              */}

              <div className="flex-fill" onClick={() => onClickDelete(pd)}>
                <Link to="#" id={"project" + pd._id}>
                  <i className="mdi mdi-trash-can font-size-20 text-danger me-1"></i>
                  <UncontrolledTooltip
                    placement="top"
                    target={"project" + pd._id}
                  >
                    刪除
                  </UncontrolledTooltip>
                </Link>
              </div>

            </div>

          </CardFooter>
          

        </Card>
      </Col>
    </React.Fragment>
  );
};

CardContact.propTypes = {
  db: PropTypes.object
};

export default CardContact;
