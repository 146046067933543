import React, { useState } from 'react';
import { 
  Alert,
  Button, 
  Card, 
  CardBody, 
  CardTitle, 
  Col, 
  Container, 
  Form, 
  FormGroup, 
  Input, 
  Label, 
  Row 
} from "reactstrap";
import { 
  Document, 
  Packer, 
  Paragraph, 
  TextRun, 
  Numbering, 
  AbstractNumbering, 
  Level, 
  LevelFormat, 
  AlignmentType,
  Table,
  TableCell,
  TableRow,
} from 'docx';

import DOMPurify from 'dompurify';
import JSZip from 'jszip';
import parse from 'html-react-parser';
import { saveAs } from 'file-saver';
import { v4 as uuidv4 } from 'uuid';

import TextSegment from './textSegment';
import { dateGen } from 'utils/toolUtils';
import { convertToHTML, parseHtmlToDocxElements } from './docToHtml';

// Custom Axios
import axiosInstance from 'utils/axiosInstance';

// Import Components
import Breadcrumbs from "components/Common/Breadcrumb";

const numbering = {
  config: [
    {
      reference: "my-bullet-points",
      levels: [
        {
          level: 0,
          format: LevelFormat.BULLET,
          text: "●",
          alignment: AlignmentType.LEFT,
          style: {
            paragraph: {
              // left: text left margin
              // left - hanging: bullet point left margin
              indent: { left: 360, hanging: 360 },
            },
          },
        },
      ],
    },
  ],
};

const WordRead = () => {

  const [docxText, setDocxText] = useState('');
  //const [docxTextEdited, setDocxTextEdited] = useState('');
  //const [xmlContent, setXmlContent] = useState('');

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      JSZip.loadAsync(file).then(zip => {
        zip.file('word/document.xml').async('string').then(xmlStr => {
          const parser = new DOMParser();

          const xmlDoc = parser.parseFromString(xmlStr, 'text/xml');
          const htmlContent = convertToHTML(xmlDoc);
          const cleanHtml = DOMPurify.sanitize(htmlContent); // Sanitize the HTML
          setDocxText(cleanHtml);
          //console.log("HERE clean Html", cleanHtml);

        });
      });

    }
  };


  const downloadHtmlAsDocx = async () => {

    // HTML -> Docx
    const elements = parseHtmlToDocxElements(docxText);

    const doc = new Document({
      numbering: numbering,
      sections: [
        {
          properties: {},
          children: elements,
        },
      ],
    });

    const date14 = await dateGen();

    // Save Docx
    Packer.toBlob(doc).then(blob => {
      saveAs(blob, `TheChartAI 輸出文檔_${date14}.docx`);
    });

  };


  const HTMLTextEditor = ({ html }) => {
    const [content, setContent] = useState(html);
    const [editsMap, setEditsMap] = useState(new Map());
    //const [editedContent, setEditedContent] = useState(html);
    //console.log('editedContent:', editedContent);

    const handleTextUpdate = (id, newText) => {
      setEditsMap(new Map(editsMap.set(id, newText)));
      reconstructHtmlAndUpdateState();
    };

    /*
    const transform = (node) => {
      if (node.type === 'text') {
        const id = uuidv4();
        return (
          <span data-id={id} key={id}>
            <TextSegment
              id={id}
              initialText={node.data}
              onTextUpdate={handleTextUpdate}
            />
          </span>
        );
      }
      // If the node is not a text node, you might want to handle it differently
    };
    */

    const reconstructHtml = () => {
      let newHtml = content;

      console.log('newHtml:', newHtml);
      console.log('editsMap:', editsMap);

      editsMap.forEach((newText, id) => {

        console.log('newText', newText);
        console.log('id', id);

        // Find the HTML element with the matching ID
        //const regex = new RegExp(`(<span data-id="${id}">)(.*?)(</span>)`, 'g');
        const regex = new RegExp(`(<p [^>]*data-id="${id}"[^>]*>)(.*?)(</p>)`, 'g');
        const regex2 = new RegExp(`(<span [^>]*data-id="${id}"[^>]*>)(.*?)(</span>)`, 'g');
        // Replace the content of the found HTML element with the new text
        newHtml = newHtml.replace(regex, `$1${newText}$3`);
        newHtml = newHtml.replace(regex2, `$1${newText}$3`);
      });

      console.log('newHtmlAfterEditing:', newHtml);

      return newHtml;
    };

    const reconstructHtmlAndUpdateState = () => {
      const newHtml = reconstructHtml();
      setContent(newHtml);
      setDocxText(newHtml);
    };

    const options = {
      replace(node, index) {

        if (node.type === 'text') {
          const text = node.data;
          //console.log("TEXT:", text);
          const id = node.parent.attribs['data-id'];
          //console.log("ID:", id);
          return (
            <TextSegment
              id={id}
              key={id+index}
              initialText={text}
              onTextUpdate={handleTextUpdate}
            />
          );
        }
      },
    };

    const handleSave = async () => {
      try {
        const response = await axiosInstance.post('/knovia/save-doc', {
          docxText: content
        });

        if (response.status === 200) {
          alert('Content saved!');
        } else {
          alert('Failed to save content');
        }
      } catch (error) {
        alert('Error saving content: ' + error.message);
      }
    };

    //return (<div>{parse(content, { replace: transform })}</div>);
    return (
      <div>
          {parse(content, options)}
          <Button onClick={handleSave}>Save</Button>
      </div>
    );
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="資料庫" breadcrumbItem="建立資料庫" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">新資料庫：上傳檔案</CardTitle>

                    <Row 
                      style={{
                        display: "flex", 
                        alignItems: "center",
                        flexDirection: "row"
                      }}
                    >
                      <Col lg="8">
                        {/* File Input for Image Upload */}
                        <FormGroup>
                          <Label for="docxUpload">上傳檔案</Label>
                          <Input type="file" name="file" id="docxUpload" onChange={handleFileChange} />
                        </FormGroup>
                      </Col>
                      <Col lg="4" style={{ display: "flex", justifyContent: "center" }}>
                        <Button onClick={downloadHtmlAsDocx}>
                          下載 Word 檔
                        </Button>
                      </Col>
                    </Row>

                    <Row>

                      <Col lg="12" id="editable-html">
                        {/*<div dangerouslySetInnerHTML={{ __html: docxText }} />*/}
                        {/*<textarea value={xmlContent} readOnly style={{ width: '100%', height: '400px' }} />*/}

                        <HTMLTextEditor html={docxText} />

                        <div>
                          <TextSegment initialText="This is a clickable and editable text segment." />
                          <TextSegment initialText="Second clickable and editable text segment." />
                        </div>

                      </Col>

                    </Row>

                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default WordRead;

