
const LangOne = (cell) => { return cell.value ? cell.value : ''; };
const LangTwo = (cell) => { return cell.value ? cell.value : ''; };

const TextOne = (cell) => { return cell.value ? cell.value : ''; };
const TextTwo = (cell) => { return cell.value ? cell.value : ''; };

export {
  LangOne,
  TextOne,
  LangTwo,
  TextTwo,
};