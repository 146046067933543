import { call, put, takeLatest, all } from "redux-saga/effects";
import { fetchImage } from 'utils/imageUtils';

import {
  LOAD_PROJECT_IMAGES,
  GET_PROJECTS,
  ADD_NEW_PROJECT,
  UPDATE_PROJECT, 
  DELETE_PROJECT, 
} from "./actionTypes";

import {
  loadProjectImagesSuccess,
  loadProjectImagesFail,

  getProjectsSuccess,
  getProjectsFail,

  updateProjectSuccess,
  updateProjectFail,

  deleteProjectSuccess,
  deleteProjectFail,

  addProjectSuccess,
  addProjectFail,
} from "./actions";

import { fetchUserData } from "../user/actions.js";

import { 
  addNewProject,
  updateProject,
  deleteProject
} from "api/project";

/*
function* fetchProjects() {
  try {
    const response = yield call(getProjects);
    yield put(getProjectsSuccess(response));
  } catch (error) {
    yield put(getProjectsFail(error));
  }
}
*/

function* fetchProjectImages({ payload: projects }) {
  console.log('[store/projects/saga.js] Reloading all Project Images...');
  try {
    const imageUrls = yield all(projects.map(project => 
      call(fetchImage, project.img || 'default_placeholder.png')
    ));
    const images = projects.reduce((acc, project, index) => {
      acc[project._id] = imageUrls[index];
      return acc;
    }, {});
    yield put(loadProjectImagesSuccess(images));
  } catch (error) {
    yield put(loadProjectImagesFail(error.message));
  }
}

function* onUpdateProject({ payload: project }) {
  try {
    const response = yield call(updateProject, project);
    console.log("[store/projects/saga.js] Updating project...", response);
    yield put(updateProjectSuccess(project));
    yield put(fetchUserData());
  } catch (error) {
    console.log('error:', error);
    yield put(updateProjectFail(error));
  }
}

function* onDeleteProject({ payload: projectId }) {
  try {
    const response = yield call(deleteProject, projectId);
    console.log("[store/projects/saga.js] Deleting project...", response);
    yield put(deleteProjectSuccess(projectId));
    yield put(fetchUserData());
  } catch (error) {
    yield put(deleteProjectFail(error));
  }
}

function* onAddNewProject({ payload: project }) {
  try {
    const response = yield call(addNewProject, project);
    console.log("[store/projects/saga.js] Adding project...", response);
    const newProject = {
      img: project.projectImg,
      name: project.projectName,
      description: project.projectDesc,
      status: 'Ongoing',
      startDate: project.startDate,
      endDate: project.endDate,
      team: project.team,
      file: project.seletedFile
    };
    yield put(addProjectSuccess(newProject));
    yield put(fetchUserData());
  } catch (error) {
    yield put(addProjectFail(error));
  }
}

function* projectsSaga() {
  //yield takeLatest(GET_PROJECTS, fetchProjects);
  yield takeLatest(LOAD_PROJECT_IMAGES, fetchProjectImages);
  yield takeLatest(ADD_NEW_PROJECT, onAddNewProject);
  yield takeLatest(DELETE_PROJECT, onDeleteProject);
  yield takeLatest(UPDATE_PROJECT, onUpdateProject);
}

export default projectsSaga;



