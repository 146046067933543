import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Table
} from "reactstrap";
import { Link } from "react-router-dom";
import { size, map } from "lodash";

// Custom Axios
import axiosInstance from 'utils/axiosInstance';

// Custom Utils
import { formatTimestamp } from 'utils/toolUtils';

// Import Images
import placeholder from "assets/images/placeholders/placeholder_card.png";


/*---- Main Modal Component ----*/
const InfoModal = ({ offer, offerImage, infoModal, toggle }) => {

  //console.log('db', db);
  //console.log('infoModal', infoModal);


  /*---- Modal Editing a Database Tail ----*/

  const [entryList, setEntryList] = useState([]);
  const [glossaryData, setGlossaryData] = useState({});

  const getEntryList = () => {
    console.log("Getting offer entries...");
    const url = `/knovia/langcomp-preview/${offer._id}`;

    axiosInstance.get(url)
      .then((response) => {
        setEntryList(response.data.langcomp_preview);
        console.log('Glossary Entry List', response.data);
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    if(infoModal){
      getEntryList();
    }
  }, [infoModal]);

  /*---- Modal Editing a Database Tail ----*/



  return (
    <React.Fragment>


      {/* 專案編輯視窗 */}
      <Modal isOpen={infoModal} toggle={toggle} style={{ minWidth: "50%" }}>

        {/* 專案編輯視窗標題 */}
        <ModalHeader toggle={toggle} tag="h4">
          資料庫 Info
        </ModalHeader>

        {/* 專案編輯視窗內容 */}
        <ModalBody>

          <Row>


            <Col sm="12">

              <Row style={{ minHeight: "5rem", marginBottom: "1rem" }}>

                <Col sm="3" xs="3" xl="2">
                  {/* Img */}
                  <div 
                    style={{
                      display: "flex", 
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      <img
                        className="rounded-circle avatar-bigger"
                        src={offerImage || placeholder}
                        alt={offer?.dbName || "User Image"}
                        style={{ objectFit: "cover" }}
                      />
                    </div>
                  </div>
                </Col>

                <Col 
                  sm="9"
                  xs="9"
                  xl="10"
                  style={{ 
                    display: "flex",
                    alignContent: "center",
                    flexWrap: "wrap"
                  }}
                >
                  <h5 
                    style={{
                      marginBottom: "0rem"
                    }}
                  >{offer?.dbName}</h5>
                </Col>

              </Row>
              
            </Col>


            <Col sm="12" className="mt-2">
              <p style={{ fontSize: "0.9rem", marginBottom: "0rem" }}>
                建立者：{offer?.dbClient}
              </p>
            </Col>

            <Col sm="12" className="mt-2">
              <p style={{ fontSize: "0.75rem", marginBottom: "0rem", fontWeight: "600" }}>
                最近編輯時間：{ (offer?.lastEditTime && formatTimestamp(offer?.lastEditTime)) || formatTimestamp(Date.now()) }
              </p>
            </Col>

            <Col sm="12" className="mb-3">
              <p style={{ fontSize: "0.75rem", marginBottom: "0rem", fontWeight: "600" }}>
                建立時間：{ (offer?.createAt && formatTimestamp(offer?.createAt)) || formatTimestamp(Date.now()) }
              </p>
            </Col>

            <Col sm="12" className="mt-2">
              <p style={{ fontSize: "0.8rem", marginBottom: "0rem" }}>
                {`對照文本總計：${entryList.length} 筆`}
              </p>
            </Col>

            <Col sm="12" className="mt-2 mb-3">
              <p style={{ fontSize: "0.8rem", margin: "0rem 0.5rem 0rem 0rem", display: "inline-block" }}>
                標籤：
              </p>
              {/* Attribute Tags */}
              <div style={{ display: "inline-block", position: "absolute" }}>
                {map(
                  offer?.tags,
                  (tag, index) =>(
                      <Link
                        to="#"
                        className="badge bg-primary font-size-11"
                        style={{ marginRight: "0.5rem" }}
                        key={"_skill_" + offer?._id + index}
                      >
                        {tag}
                      </Link>
                    )
                )}
              </div>
            </Col>


            <Col sm="12" className="mt-4">
              <p style={{ fontSize: "0.9rem", marginBottom: "0.8rem" }}>
                預覽資料：
              </p>
            </Col>

            <Col sm="12">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th style={{ width: "50%" }}>來源語言</th>
                    <th style={{ width: "50%" }}>目標語言</th>
                  </tr>
                </thead>
                <tbody>
                  {entryList.map((entry, index) => (
                    <tr key={index}>
                      <td>
                        {entry.textOne.length > 100 
                          ? `${entry.textOne.substring(0, 100)}...` 
                          : entry.textOne}
                      </td>
                      <td>
                        {entry.textTwo.length > 150 
                          ? `${entry.textTwo.substring(0, 150)}...` 
                          : entry.textTwo}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>


          </Row>


        </ModalBody>

      {/* ^專案編輯視窗 */}
      </Modal>
    </React.Fragment>
  );

};

export default InfoModal;
