/* USERS PROFILE */
export const FETCH_USER_DATA = "FETCH_USER_DATA"
export const FETCH_USER_DATA_SUCCESS = "FETCH_USER_DATA_SUCCESS"
export const FETCH_USER_DATA_FAIL = "FETCH_USER_DATA_FAIL"

/* EDIT USER */
export const SET_USER_DATA = "SET_USER_DATA"
export const SET_USER_DATA_SUCCESS = "SET_USER_DATA_SUCCESS"
export const SET_USER_DATA_FAIL = "SET_USER_DATA_FAIL"

/* TOKEN */
export const TOKEN_NOT_FOUND = "TOKEN_NOT_FOUND";

export const CLEAR_USER_DATA = "CLEAR_USER_DATA";


