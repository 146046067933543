
/* SET PRODUCT */
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_PRODUCT_IMAGE = 'SET_PRODUCT_IMAGE';

export const LOAD_PRODUCT_IMAGES = 'LOAD_PRODUCT_IMAGES';
export const LOAD_PRODUCT_IMAGES_SUCCESS = 'LOAD_PRODUCT_IMAGES_SUCCESS';
export const LOAD_PRODUCT_IMAGES_FAIL = 'LOAD_PRODUCT_IMAGES_FAIL';

/* GET PRODUCT */
export const GET_PRODUCTSET = "GET_PRODUCTSET";
export const GET_PRODUCTSET_SUCCESS = "GET_PRODUCTSET_SUCCESS";
export const GET_PRODUCTSET_FAIL = "GET_PRODUCTSET_FAIL";

/* ADD PRODUCT */
 export const ADD_NEW_PRODUCT = "ADD_NEW_PRODUCT";
 export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
 export const ADD_PRODUCT_FAIL = "ADD_PRODUCT_FAIL";

/* DELETE PRODUCT */
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL";
 
/* UPDATE PRODUCT */
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL";
 
