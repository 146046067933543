import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Col,
  Row,
} from "reactstrap";

// Import additional Libraries
import * as Yup from "yup";
import { useFormik } from "formik";

// Redux
import { useSelector, useDispatch } from "react-redux";

// Custom Utility Functions
import { dateGen } from 'utils/toolUtils';
import { uploadImg } from 'utils/imageUtils';

import { updateDatabase as onUpdateDatabase } from "store/actions";

// Import Images
import placeholder from "assets/images/placeholders/placeholder_card.png"

const typeList = [
  {label:"底層資料", value:"底層資料"},
  {label:"詞彙表 Glossary", value:"詞彙表 Glossary"},
  {label:"財報", value:"財報"},
  {label:"年報", value:"年報"},
  {label:"永續報告書", value:"永續報告書"},
  {label:"議事手冊", value:"議事手冊"},
  {label:"重大訊息", value:"重大訊息"},
  {label:"新聞稿", value:"新聞稿"},
];

const clients = [
  "世界先進",
  "京城銀行",
  "台聚",
  "台通光電",
  "新盛力",
  "是方",
  "桂盟",
];

const languages = { //TODO: langOptions 模組化
  source: ["中文", "英文", "日文", "韓文", "德文", "法文", "西班牙文", "越南文", "印尼文", "泰文"],
  target: ["英文", "中文", "日文", "韓文", "德文", "法文", "西班牙文", "越南文", "印尼文", "泰文"],
};

const EditModal = ({ db, dbImage, editModal, toggle }) => {


  const dispatch = useDispatch();

  // state.projects.projects is set through fetchUserData
  const { userData } = useSelector(state => ({
    userData: state.user.userData,
  }));


  // label is shown in the page
  // value is what the program receives
  const [clientOptions, setClientOptions] = useState([]);

  useEffect(()=>{
    const newClientOptions = [
      {
        label: "選擇報告客戶",
        options: [
          ...(userData?.clients ? 
            userData.clients.map(c => ({ label: c.name, value: c.name }))
                           .sort((a, b) => a.label.localeCompare(b.label)) : 
            []),
          { label: "其他", value: "其他" },
        ]
      }
    ];
    setClientOptions(newClientOptions);
  },[userData.clients]);


  useEffect(()=>{
    console.log('clientOptions.options:', clientOptions.options);
  },[clientOptions.options])

  /*---- Form Validation ----*/

  const validation = useFormik({

    enableReinitialize: true,

    // Inital values of the form fields
    initialValues: {
      _id: (db && db._id) || "",
      name: (db && db.dbName) || "",
      langSource: (db && db.langOne) || languages.source[0],
      langTarget: (db && db.langTwo) || languages.target[0],
      img: (db && db.img) || "",
      imgUrl: dbImage || "",
      client: (db && db.dbClient) || "",
      tags: (db && db.tags) || "",
    },

    // define validation rules using Yup
    validationSchema: Yup.object({
      name: Yup.string().required("請輸入資料庫名稱"),
      langSource: Yup.string().required("請輸入來源語言"),
      langTarget: Yup.string().required("請輸入目標語言"),
      client: Yup.string().required("請輸入資料庫客戶"),
      tags: Yup.array().of(Yup.string()).required("請輸入標籤").min(1, "至少需要一個標籤"),
    }),

    // gets called when the form is submitted
    onSubmit: (values, { setSubmitting, setErrors }) => {
      const updateDatabase = {
        _id: db._id,
        img: values.img,
        dbName: values.name,
        langOne: values.langSource,
        langTwo: values.langTarget,
        dbClient: values.client,
        tags: values.tags
      };
    
      const errors = validatelangs(values);
      if (Object.keys(errors).length === 0) {
        dispatch(onUpdateDatabase(updateDatabase));
        toggle(); // Close the modal
      } else {
        setErrors(errors); // 將錯誤設置到表單中
      }
    },

  });

  /*---- Form Validation Tail ----*/
  const validatelangs = (values) => {
    const errors = {};
    if (values.langSource === values.langTarget) {
      errors.langTarget = '來源語言和目標語言不能相同';
    }
    return errors;
  };

  /*---- Uploading a new Database Image ----*/

  const [filename, setFilename] = useState('還沒有選擇圖片喔');
  const [newImageUrl, setNewImageUrl] = useState(dbImage);

  const handleImageChange = async(event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    if (file) {
      const date14string = await dateGen();
      const newfilename = date14string+'_'+file.name;
      setFilename(newfilename);
      const {newImageUrl, uploadStatus} = await uploadImg(newfilename, file);
      setFieldValue('img', newfilename);
      setFieldValue('imgUrl', newImageUrl);
    }
  };

  /*---- Uploading a new Database Image Tail ----*/



  return (
    <React.Fragment>


      {/* 專案編輯視窗 */}
      <Modal 
        isOpen={editModal} 
        toggle={()=>{
          validation.setFieldValue('imgUrl', dbImage);
          toggle();
        }}
      >

        {/* 專案編輯視窗標題 */}
        <ModalHeader 
          toggle={()=>{
            validation.setFieldValue('imgUrl', dbImage);
            toggle();
          }}
          tag="h4"
        >編輯資料庫</ModalHeader>

        {/* 專案編輯視窗內容 */}
        <ModalBody>

          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit(); // 確定填寫內容符合格式
              return false;
            }}
          >
            <Row>
              <Col xs={12}>

                {/* 資料庫名稱 */}
                <div className="mb-3">
                  <Label className="form-label">資料庫名稱</Label>
                  <Input
                    name="name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name || ""}
                    invalid={
                      validation.touched.name && validation.errors.name ? true : false
                    }
                  />
                  {/* Display error message */}
                  {validation.touched.name && validation.errors.name ? (
                    <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                  ) : null}
                </div>

                {/* 資料庫客戶 */}
                <div className="mb-3">
                  <Label className="form-label">資料庫客戶</Label>
                  <Input
                    name="client"
                    id="client"
                    type="select"
                    className="form-select"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.client || ""}
                  >
                    {clientOptions[0]?.options?.map((client, index) => (
                      <option key={index}>{client.label}</option>
                    ))}
                  </Input>
                  {validation.touched.client && validation.errors.client ? (
                    <FormFeedback type="invalid">{validation.errors.client}</FormFeedback>
                  ) : null}
                </div>

                {/* 來源語言 */}
                <div className="mb-3">
                  <Label className="form-label">來源語言</Label>
                  <Input
                    name="langSource"
                    id="langSource"
                    type="select"
                    className="form-select"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.langSource || ""}
                  >
                    {languages.source.map((language, index) => (
                      <option key={index}>{language}</option>
                    ))}
                  </Input>
                  {validation.touched.langSource && validation.errors.langSource ? (
                    <FormFeedback type="invalid">{validation.errors.langSource}</FormFeedback>
                  ) : null}
                </div>

                {/* 目標語言 */}
                <div className="mb-3">
                  <Label className="form-label">目標語言</Label>
                  <Input
                    name="langTarget"
                    id="langTarget"
                    type="select"
                    className="form-select"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.langTarget || ""}
                    invalid={
                      validation.touched.langTarget && validation.errors.langTarget ? true : false
                    }
                  >
                    {languages.target.map((language, index) => (
                      <option key={index}>{language}</option>
                    ))}
                  </Input>
                  {validation.touched.langTarget && validation.errors.langTarget ? (
                    <FormFeedback type="invalid">{validation.errors.langTarget}</FormFeedback>
                  ) : null}
                </div>

                {/* 資料庫圖片 */}
                <div className="mb-3">
                  <Label for="databaseImage" className="form-label">資料庫圖片</Label>
                  <Input
                    id="databaseImage"
                    name="img"
                    type="file"
                    onChange={(event) => {
                      handleImageChange(event, validation.setFieldValue);
                    }}
                  />
                  <div style={{ display:"flex", justifyContent:"center", margin:"1rem 0rem" }}>
                    <img 
                      src={validation.values.imgUrl || dbImage || placeholder} 
                      alt="Project" 
                      style={{ 
                          width: '100px', 
                          height: '100px',
                          borderRadius: '50%',
                          objectFit: 'cover'
                      }}
                    />
                  </div>
                </div>

                {/* 資料庫類型 */}
                <div className="mb-3">
                  <Label className="form-label">選擇資料庫類型：</Label>
                  <Input
                    name="tags"
                    type="select"
                    multiple
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.tags || ""}
                    invalid={
                      validation.touched.tags && validation.errors.tags ? true : false
                    }
                  >
                    {typeList.map(type => (
                      <option key={type.value} value={type.value}>
                        {type.label}
                      </option>
                    ))}
                  </Input>
                  {validation.touched.tags && validation.errors.tags ? (
                    <FormFeedback type="invalid">{validation.errors.tags}</FormFeedback>
                  ) : null}
                </div>

              </Col>
            </Row>

            {/* Save Button */}
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="submit"
                    className="btn btn-success save-user"
                  >
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </Form>


        </ModalBody>

      {/* ^專案編輯視窗 */}
      </Modal>
    </React.Fragment>
  );

};

export default EditModal;
