import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
    Col,
    Row,
    Container,
    ListGroupItem,
    ListGroupItemHeading,
    ListGroupItemText,
    Card,
    CardHeader,
    CardFooter,
    Button,
    InputGroup,
    Input,
  } from "reactstrap";
import axiosInstance from 'utils/axiosInstance';
import { get, isEmpty } from "lodash";
// uuid
import { v4 as uuidv4 } from 'uuid';

// Image
import placeholder from "assets/images/placeholders/placeholder_card.png"

// third-party
import { Client } from '@stomp/stompjs';

const client = new Client();
const CustomerServiceLiveChat = () => {
    const { sessionId, customerId } = useParams();

    const [profilePic, SetProfilePic] = useState(placeholder);
    const [errorMessage, setErrorMessage] = useState('');

    /*---- User Data ----*/
    const { userData } = useSelector(state => ({
        userData: state.user.userData,
    }));

    useEffect(() => {
        if (!isEmpty(userData)) {
            const { _id: agentId } = userData;
            // console.log('Connect to the WebSocket server');
            // console.log('[[subscribe url]]', `/topic/${sessionId}/chat/${agentId}`);
            const wsURL = `${(window.location.protocol === 'https:') ? 'wss://' : 'ws://'}${process.env.REACT_APP_WEB_SOCKET_URL}`;
            client.configure({
                // brokerURL: 'ws://localhost:8080/websocket',
                brokerURL: wsURL,
                debug:(str) => console.log(str),
                onConnect: () => {
                    client.subscribe(`/topic/${sessionId}/chat/${agentId}`, message => {
                        // console.log(`Received:`, message.body);
                        const res = JSON.parse(message.body);
                        if (res.status && res.status != 200) {
                            setErrorMessage(res.message || 'subscribe 發生錯誤');
                        } else {
                            // SUCCESS
                            const newMessageId = uuidv4();
                            const newItem = {
                                id: newMessageId,
                                text: res.message,
                                sender: res.customerId,
                                time: new Date().toLocaleTimeString()
                            };
                            setChatHistory(prevItems => [...prevItems, newItem]);
                        }
                        
                    }, {sessionId: sessionId, senderId: agentId});
                },
                onWebSocketError: (error) => {
                    console.error('Error with websocket', error);
                },
                // onDisconnect: (e) => console.log('onDisconnect', e),
                // onStompError: (e) => console.log('onStompError', e),
                // onWebSocketClose: (e) => console.log('onWebSocketClose', e),
                // onUnhandledMessage: (e) => console.log('onUnhandledMessage', e),
                // onUnhandledReceipt: (e) => console.log('onUnhandledReceipt', e),
                // onUnhandledFrame: (e) => console.log('onUnhandledFrame', e),
                // reconnectDelay: 0
            });
            // Connect to the WebSocket server
            client.activate();
        }
        
    }, [userData]);
    
    const chatListRef = useRef(null);
    const [chatHistory, setChatHistory] = useState([]);
    const pushChatHistoryItemText = (id, newText) => {
        setChatHistory(prevItems =>
            prevItems.map(item =>
              item.id === id ? { ...item, text: `${item.text}${newText}` } : item
            )
        );
    };
    useEffect(() => {
        const current = chatListRef.current;
        if (current) current.scrollTop = current.scrollHeight;
    }, [chatHistory]);
    const [message, setMessage] = useState('');
    const sendMsg = async (e) => {
        const { _id: agentId } = userData;
        const newMessageId = uuidv4();
        // console.log(newMessageId, message);
        const newItem = {
            id: newMessageId,
            text: message,
            sender: userData.username,
            time: new Date().toLocaleTimeString()
        };
        setChatHistory(prevItems => [...prevItems, newItem]);
        try {
            const chatSendMessageDTO = { message: message, agentId: agentId, customerId: customerId };
            // console.log(data, client);
            client?.publish({
                destination: `/chatroom/${sessionId}/chat/${customerId}/sendMessage`,
                body: JSON.stringify(chatSendMessageDTO),
            });
            setMessage(''); // 清空輸入框
        } catch (error) {
            console.error("[src/api/api.js] Error in userLogin", error);
            return Promise.reject(error);
        }
    }


    return (
        <>
            <Container className="page-content">
                <Row>
                    <Col>
                        <Card
                            className="my-2"
                            color="dark"
                            inverse
                        >
                            <CardHeader>
                                線上客服 - 案件 {customerId}
                            </CardHeader>
                            <ul
                                className="list-group list-group-flush"
                                ref={chatListRef}
                                style={{
                                    height: '500px',
                                    overflowY: 'scroll'
                                }}
                            >
                                {chatHistory.map((item, inedx) => {
                                    const selfSent = userData.username === item.sender;
                                    return selfSent ? (<ListGroupItem key={inedx}>
                                        <span className="ms-4 float-end">
                                            <img
                                            className="rounded-circle header-profile-user"
                                            src={profilePic || placeholder}
                                            style={{ objectFit: "cover", width: 50, height: 50 }}
                                        />
                                        </span>
                                        <ListGroupItemHeading className='text-end'>
                                            {item.sender}
                                        </ListGroupItemHeading>
                                        <ListGroupItemText className='text-end'>
                                            {item.text}
                                        </ListGroupItemText>
                                    </ListGroupItem>) : (<ListGroupItem key={inedx}>
                                        <span className="me-4 float-start">
                                            <span className="mdi-48px mdi mdi-account-question"></span>
                                        </span>
                                        <ListGroupItemHeading>
                                            民眾
                                        </ListGroupItemHeading>
                                        <ListGroupItemText>
                                            {item.text}
                                        </ListGroupItemText>
                                    </ListGroupItem>);
                                })}
                            </ul>
                            <CardFooter>
                                {errorMessage && (
                                    <div className="alert alert-danger" role="alert">
                                        {errorMessage}
                                    </div>
                                )}
                                <InputGroup>
                                    <Button onClick={() => alert('結案')} disabled>
                                        結案
                                    </Button>
                                    <Input
                                        placeholder="輸入訊息"
                                        value={message} onChange={(event) => setMessage(event.target.value)}
                                        disabled={errorMessage}
                                    />
                                    <Button onClick={sendMsg} disabled={errorMessage}>
                                        發送
                                    </Button>
                                </InputGroup>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default CustomerServiceLiveChat;