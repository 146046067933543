// src/utils/axiosInstance.js
import axios from 'axios';
// API_ENDPOINT in index.js
import { BASE_URL } from ".";

// Common Axios configuration
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 20000, // Default timeout of 20 seconds
});

// Request Interceptor for adding tokens or other headers
axiosInstance.interceptors.request.use(config => {

  // Check if the user has been redirected to login
  const isRedirectedToLogin = sessionStorage.getItem("isRedirectedToLogin");

  // Define login and authentication-related URLs
  const authUrls = [
    '/login',
    '/register',
    '/google-login',
    '/forgot-password',
    '/reset-password', // Adjust according to actual URL structure
  ];

  // Check if the current request is for an authentication-related endpoint
  const isAuthRequest = authUrls.some(url => config.url.includes(url));

  if (isRedirectedToLogin && !isAuthRequest) {

    console.log('condition 1');

    // Option 1: Cancel the request with a message
    /*
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    config.cancelToken = source.token;
    source.cancel("Request canceled due to user being unauthorized.");
    */

    // Option 2: Modify the request config, e.g., by setting a custom header
    // config.headers['X-Custom-Unauthorized'] = 'true';
    
    // Option 3: Immediately reject the promise without sending the request
    return Promise.reject(new Error("Request skipped due to user being unauthorized."));

  } else {

    // Proceed with the request as usual
    /*---- Endpoints that require longer timeouts ----*/

    // translate: in projectTranslate.js, transbyte/transsimple: in Dashboard-Translate
    const longTimeoutEndpoints = ['/transbyte', '/transsimple', '/login'];
    const longLongTimeoutEndpoints = [
      '/conversations', 
      '/projects', 
      '/image', 
      '/get-qapairs',
      '/customer-newjson',
      '/customer-responsetype'
    ];
    const longLongLongTimeoutEndpoints = ['search-database', '/translate', '/db'];
    const longTimeoutDuration = 30000; // Longer timeout duration, e.g., 30 seconds
    const longLongTimeoutDuration = 90000; // Very long timeout duration, e.g., 90 seconds
    const longLongLongTimeoutDuration = 180000; // Very Very long timeout duration, e.g., 180 seconds

    // Adjust timeout for specific endpoints
    if (longTimeoutEndpoints.some(url => config.url.includes(url))) {
      config.timeout = longTimeoutDuration; // Set a longer timeout
    } else if (longLongTimeoutEndpoints.some(url => config.url.includes(url))) {
      config.timeout = longLongTimeoutDuration; // Set a longer timeout
    } else if (longLongLongTimeoutEndpoints.some(url => config.url.includes(url))) {
      config.timeout = longLongLongTimeoutDuration; // Set very very longer timeout
    }

    /*---- Endpoints that require longer timeouts Tail ----*/
    


    /*---- Endpoints that don't require token ----*/

    const resetPasswordRegex = /\/reset-password\/[^\/]+$/;
    const skipTokenUrls = [
      '/register', 
      '/login', 
      '/google-login', 
      '/verify-account', 
      '/resend-verification',
      '/forgot-password', 
      '/anotherEndpointWithoutToken'
    ];
    const requiresToken = !skipTokenUrls.some(url => config.url.includes(url)) &&
                          !resetPasswordRegex.test(config.url);

    /*---- Endpoints that don't require token Tail ----*/

    if (requiresToken) {
      const authUser = JSON.parse(localStorage.getItem("authUser"));
      const token = authUser?.accessToken;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        console.log('Requesting without token failed:', config.url);
        //window.dispatchEvent(new CustomEvent("customUnauthorizedEvent"));
        //throw new Error('No token found');
      }

    }

  }
  
  return config;
}, error => {
  return Promise.reject(error);
});


axiosInstance.interceptors.response.use(
  response => {
    if(response?.config?.url !== '/knovia/refresh-token'){
      console.debug("[axiosInstance.js] Axios getting response from", response?.config?.url);
      console.debug("The received response is:", response);
    }
    return response;
  },
  async(error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const authUser = JSON.parse(localStorage.getItem("authUser"));
      
      const refreshToken = authUser?.refreshToken;
      if (refreshToken) {
        try {
          const response = await axios.post(`${BASE_URL}/knovia/refresh-token`, {refreshToken});
          const newAccessToken = response.data.accessToken;
          localStorage.setItem("authUser", JSON.stringify({ ...authUser, accessToken: newAccessToken }));  //set new access token
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return axios(originalRequest); //recall Api with new token
        } catch (error) {
          console.error('token refresh failure',error.response);
        }
      }
    }
    return Promise.reject(error);
  }
);

function handleFailedRefresh() {
  const now = new Date().getTime();
  const lastAlertTime = sessionStorage.getItem("lastUnauthorizedAlertTime");
  if (!sessionStorage.getItem("isRedirectedToLogin")) {
    sessionStorage.setItem("isRedirectedToLogin", "true");
    if (!lastAlertTime || now - lastAlertTime > 5000) { // 5 seconds interval
      sessionStorage.setItem("lastUnauthorizedAlertTime", now.toString());
      // Optionally show an alert or redirect the user
      window.dispatchEvent(new CustomEvent("customUnauthorizedEvent"));
      console.log('Token refresh failed, action pending...');
    }
  }
}


export default axiosInstance;

