// Import file icons and images
import wordIcon from "../../assets/images/small/word_file_icon.png";
import pdfIcon from "../../assets/images/small/pdf_file_icon.png";
import excelIcon from "../../assets/images/small/excel_file_icon.png";
import defaultIcon from "../../assets/images/small/unknown_file_icon.png";

export const getFileIcon = (file) => {
  const fileName = file.filename.toLowerCase();
  if (fileName.endsWith('.doc') || fileName.endsWith('.docx')) {
    return wordIcon;
  } else if (fileName.endsWith('.pdf')) {
    return pdfIcon;
  } else if (fileName.endsWith('.xls') || fileName.endsWith('.xlsx') || fileName.endsWith('.csv')) {
    return excelIcon;
  } else {
    return defaultIcon; // Default icon or any other filetype
  }
}

// Function to map uploadStatus to color
export const getUploadStatusColor = (status) => {
  // Regex to match the pattern "File format .<extension> not allowed"
  // [\.] is the '.', [.+] is any characters
  const fileFormatNotAllowedRegex = /^File format \..+ not allowed$/;
  
  // Color for file format not allowed
  if (fileFormatNotAllowedRegex.test(status)) { return 'orange'; }
  switch (status) {
    case 'File uploaded successfully!':
      return 'mediumseagreen';
    case 'Failed to upload file.':
    case 'Error occurred during upload.':
      return 'red';
    default:
      return 'black'; // Default color
  }
};