import React, { useState, useRef, useEffect } from 'react';
import './TextSegment.css'; // Import your CSS file

const TextSegment = ({ id, initialText, onTextUpdate }) => {

  const [isEditMode, setIsEditMode] = useState(false);
  const [text, setText] = useState(initialText);
  const textInput = useRef(null);
  const textWidth = useRef(null);

  useEffect(() => {
    if (isEditMode && textInput.current && textWidth.current) {
      const width = textWidth.current.offsetWidth + 20; // +2 for some padding
      textInput.current.style.width = `${width}px`;
    }
  }, [text, isEditMode]);

  const handleDoubleClick = () => {
    setIsEditMode(true);
  };

  const handleChange = (e) => {
    setText(e.target.value);
  };

  /*
  const handleBlur = () => {
    setIsEditMode(false);
    onTextUpdate(initialText, text);
  };
  */

  const handleKeyDown = (e) => {
    console.log('id in textSegment:', id);
    if (e.key === 'Enter') {
      setIsEditMode(false);
      onTextUpdate(id, text);
    }
  };

  return (
    <span data-id={id} className="text-segment" onDoubleClick={handleDoubleClick}>
      {isEditMode ? (
        <>
          <input
            ref={textInput}
            type="text"
            value={text}
            onChange={handleChange}
            //onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            autoFocus
          />
          <span ref={textWidth} className="hidden-text" aria-hidden="true">
            {text}
          </span>
        </>
      ) : (
        <span>{text}</span>
      )}
    </span>
  );

};

export default TextSegment;

