import { call, put, takeLatest, all } from "redux-saga/effects";
import { fetchImage } from 'utils/imageUtils';

import { 
  LOAD_CHATBOT_IMAGES,
  GET_CHATBOTS,
  ADD_NEW_CHATBOT, 
  DELETE_CHATBOT, 
  UPDATE_CHATBOT,
} from "./actionTypes";

import {
  loadChatbotImagesSuccess,
  loadChatbotImagesFail,

  getChatbotsSuccess,
  getChatbotsFail,

  updateChatbotSuccess,
  updateChatbotFail,

  deleteChatbotSuccess,
  deleteChatbotFail,

  addChatbotSuccess,
  addChatbotFail,
} from "./actions"

import {
  navigateAction,
} from "../actions"

import { fetchUserData } from "../user/actions.js";

import { 
  getChatbots,
  addNewChatbot,
  updateChatbot,
  deleteChatbot
} from "api/chatbot";

function* fetchChatbots() {
  try {
    const response = yield call(getChatbots);
    yield put(getChatbotsSuccess(response));
  } catch (error) {
    yield put(getChatbotsFail(error));
  }
}

function* fetchChatbotImages({ payload: chatbots }) {
  console.log('[store/chatbots/saga.js] Reloading all Chatbot Images...');
  try {
    const imageUrls = yield all(chatbots.map(cb => 
      call(fetchImage, cb.img || 'default_placeholder.png')
    ));
    const images = chatbots.reduce((acc, cb, index) => {
      acc[cb._id] = imageUrls[index];
      return acc;
    }, {});
    yield put(loadChatbotImagesSuccess(images));
  } catch (error) {
    yield put(loadChatbotImagesFail(error.message));
  }
}

function* onUpdateChatbot({ payload: chatbot }) {
  try {
    const response = yield call(updateChatbot, chatbot);
    const { updatedData } = response;
    console.log("[store/chatbots/saga.js] Updating chatbot...", updatedData);
    yield put(updateChatbotSuccess(updatedData));
    yield put(fetchUserData());
    //yield put({ type: 'GET_CHATBOTS' });
  } catch (error) {
    console.log('error:', error);
    yield put(updateChatbotFail(error));
  }
}

function* onDeleteChatbot({ payload: chatbotId }) {
  try {
    const response = yield call(deleteChatbot, chatbotId);
    yield put(deleteChatbotSuccess(chatbotId));
    yield(fetchUserData());
  } catch (error) {
    yield put(deleteChatbotFail(error));
  }
}

function* onAddNewChatbot({ payload: chatbot }) {
  try {
    const response = yield call(addNewChatbot, chatbot);
    yield put(addChatbotSuccess(response));
    yield put(fetchUserData());
  } catch (error) {
    yield put(addChatbotFail(error));
  }
}

function* chatbotsSaga() {
  yield takeLatest(LOAD_CHATBOT_IMAGES, fetchChatbotImages);
  yield takeLatest(GET_CHATBOTS, fetchChatbots);
  yield takeLatest(ADD_NEW_CHATBOT, onAddNewChatbot);
  yield takeLatest(UPDATE_CHATBOT, onUpdateChatbot);
  yield takeLatest(DELETE_CHATBOT, onDeleteChatbot);
}

export default chatbotsSaga;



