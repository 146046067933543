// Custom Axios
import axiosInstance from 'utils/axiosInstance';
// Custom Logger
import { log } from 'utils/logger';
// uuid
import { v4 as uuidv4 } from 'uuid';

const chunkSize = 100;

const sendChunk = async (chunk, transactionId, isFinalChunk = false, projectMetaData) => {

  console.log("This chunk", chunk);
  const chunkData = {
    ...projectMetaData,
    entries: chunk,
    isChunk: !isFinalChunk,
    transactionId
  };

  try {
    const response = await axiosInstance.post(`/knovia/projects`, chunkData, 
      { headers: {'Content-Type': 'application/json',}}
    );
    console.log("Chunk sent, server response:", response.data);
    return true;
  } catch (error) {
    console.error("Error sending chunk to server:", error);
    return Promise.reject(error);
  }

  navigate('/projects-list');  

};


// Add new Project
export const addNewProject = async (project) => {

  const projectMetaData = {
    startDate: project.startDate,
    endDate: project.endDate,
    langSource: project.langSource,
    langTarget: project.langTarget,
    filename: project.selectedFiles[0].name,
    projectDesc: project.projectDesc,
    projectImg: project.projectImg,
    projectName: project.projectName,
    user: project.user,
  }

  console.log("Creating Project with metadata:", projectMetaData);

  const paragraphEntries = project.selectedFiles[0].paragraphs;

  console.log("HERE paragraphEntries:", paragraphEntries);

  const transactionId = uuidv4();
  let operationSuccessful = true;
    
  /*---- Sending Chunks ----*/
  for (let i = 0; i < paragraphEntries.length; i += chunkSize) {
    const chunk = paragraphEntries.slice(i, i + chunkSize);
    const isFinalChunk = (i + chunkSize >= paragraphEntries.length);
    try {
      const success = await sendChunk(chunk, transactionId, isFinalChunk, projectMetaData);
      if (!success) {
        operationSuccessful = false;
        break; // Exit the loop if a chunk fails to send
      }
    } catch (error) {
      console.error("Error in addNewProject:", error);
      //setCreateWarnings(`發生未預期的錯誤: ${error.message}`);
      operationSuccessful = false;
      throw error;
    }
  }
  /*---- Sending Chunks ----*/

  if (!operationSuccessful) {
    log("[api/project.js] Error creating new project", error);
    throw error;
  }

}

// Delete existing Project
export const deleteProject = async(projectId) => {
	try {
		const response = await axiosInstance.delete(`/knovia/projects/${projectId}`);
		const data = response.data;
   	log("[api/project.js] Deleted project:", data);
   	return data || {};
   } catch (error) {
    /* TODO: Distinguish between types of errors */
    log("[api/project.js] Error deleting project", error); // Logging error
    throw error;
   }
}

// Update existing Project
export const updateProject = async (projectData) => {
  try {
    const response = await axiosInstance.put(
    	`/knovia/projects/${projectData._id}`, 
    	projectData,
    );
    const data = response.data;
    log("[api/project.js] Updated project:", data);
    return data || {};
  } catch (error) {
    /* TODO: Distinguish between types of errors */
    log("[api/project.js] Error updating project", error);
    throw error;
  }
};
