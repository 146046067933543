// store/auth/login/actions.js
import {
  CLEAR_LOGIN_STATES,
  SET_UNAUTHORIZED_ERROR,

  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAILURE,
} from "./actionTypes"

export const clearLoginStates = () => ({
  type: CLEAR_LOGIN_STATES,
});

export const setUnauthorizedError = (errorMessage) => ({
  type: SET_UNAUTHORIZED_ERROR,
  payload: errorMessage,
});

export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history },
});

export const loginSuccess = user => ({
  type: LOGIN_SUCCESS,
});

export const loginFailure = error => ({
  type: LOGIN_FAILURE,
  payload: error,
});

export const logoutUser = history => ({
  type: LOGOUT_USER,
  payload: { history },
});

export const logoutUserSuccess = () => ({
  type: LOGOUT_USER_SUCCESS,
  payload: {},
});

export const logoutUserFailure = error => ({
  type: LOGOUT_USER_FAILURE,
  payload: error,
});

/*
export const socialLogin = (type, history) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { type, history },
  };
};
*/
