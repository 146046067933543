import React, { useState } from "react";
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { Link } from "react-router-dom";
import { size, map } from "lodash";

// Redux
import { useDispatch } from "react-redux";
import { deleteDatabase as onDeleteDatabase } from "store/actions";

// Custom CSS
import './custom.css';

// FontAwesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as farStar } from "@fortawesome/free-regular-svg-icons";
import { faStar as fasStar } from "@fortawesome/free-solid-svg-icons";

// Custom Modal
import EditModal from "./EditModal";
import InfoModal from "./InfoModal";
import DeleteModal from "components/Common/DeleteModal";


const CardContact = ({ db, dbImage, favoriteDbs, onToggleFavorite }) => {


  //console.log('db:', db);
  //console.log('dbImage:', dbImage);
  //console.log('favoriteDbs', favoriteDbs);


  /*---- Title and Initial Setup ----*/

  const dispatch = useDispatch();
  // Determine if the baseDb is a favorite
  const cardIsFavorite = (favoriteDbs ? favoriteDbs.includes(db?._id) : 0);

  /*---- Title and Initial Setup Tail ----*/



  /*---- State for Edit and Info Modals ----*/

  const [editModal, setEditModal] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const toggleEditModal = () => { setEditModal(!editModal) };
  const toggleInfoModal = () => { setInfoModal(!infoModal) };

  /*---- State for Edit and Info Modals Tail ----*/



  /*---- Modal Deleting a Project ----*/

  // State for Delete Modal Open or Close
  const [deleteModal, setDeleteModal] = useState(false);
  
  const onClickDelete = (db) => { setDeleteModal(true) };
  const handleCloseDeleteModal = () => { setDeleteModal(false) };

  // Deleting a project and close the delete modal
  const deleteOrder = () => {
    if (db && db._id) { dispatch(onDeleteDatabase(db._id)) };
    setDeleteModal(false);
  };
  
  /*---- Modal Deleting a Project Tail ----*/



  /*---- Main CardContact ----*/
  return (
    <React.Fragment>


      {/*---- Edit, Delete, and Info Modals ----*/}
      <EditModal db={db} dbImage={dbImage} editModal={editModal} toggle={toggleEditModal}  />
      <InfoModal db={db} dbImage={dbImage} infoModal={infoModal} toggle={toggleInfoModal}  />
      <DeleteModal 
        show={deleteModal} 
        onDeleteClick={deleteOrder} 
        onCloseClick={handleCloseDeleteModal}
        title={`資料庫 [${db?.dbName}]`}
      />
      {/*---- Edit, Delete, and Info Modals ----*/}


      {/*---- Card Content ----*/}
      <Col xl="3" sm="6">
        <Link to={`/dashboard-db/${db._id}`} className="text-dark">
          <Card className="text-center">
            <CardBody>

              {/* Favorite Star */}
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  cursor: "pointer",
                  fontSize: "20px",
                }} 
              >
                <FontAwesomeIcon 
                  icon={cardIsFavorite ? fasStar : farStar} 
                  color={cardIsFavorite ? "gold" : "grey"} 
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onToggleFavorite(db._id);
                  }}
                />
              </div>



              {/*---- Img ----*/}

              <div className="mb-4" style={{ display: "flex", justifyContent: "center" }}>
                {!dbImage ? (
                  <span 
                    className="avatar-bigger avatar-title rounded-circle bg-soft bg-primary text-primary font-size-16"
                  >{db.dbName.charAt(0) || ''}
                  </span>
                ) : (
                  <img
                    className="rounded-circle avatar-bigger"
                    src={dbImage}
                    alt={db?.dbName || "User Image"}
                    style={{ objectFit: "cover" }}
                  />
                )}
              </div>
            
              {/*---- Img Tail ----*/}




              {/*---- Glossary Name and Client ----*/}

              <h5 className="font-size-15 mb-1">
                {db?.dbName}
              </h5>
              <p className="text-muted">{db?.dbClient}</p>

              {/*---- Glossary Name and Client Tail ----*/}



              {/*---- Attribute Tags ----*/}
              <div>
                {/*--- show the first 2 tags ---*/}
                {map(db.tags, (tag, index) =>
                  index < 2 && (
                    <Link to="#" className="badge bg-primary font-size-11 m-1" key={"_skill_" + db._id + index}>
                      {tag}
                    </Link>
                  )
                )}
                {/*--- show the remaining tag number ---*/}
                {size(db.tags) > 2 && (
                  <Link to="#" className="badge bg-primary font-size-11 m-1" key={"_skill_" + db._id}>
                    {size(db.tags) - 2} + more
                  </Link>
                )}

              </div>
              {/*---- Attribute Tags Tail ----*/}


            </CardBody>



            {/* Bottom Icons */}
            <CardFooter className="bg-transparent border-top">

              <div className="contact-links d-flex font-size-20">


                {/*---- Edit Icon ----*/}
                <div className="flex-fill" onClick={() => setEditModal(!editModal)}>
                  <Link to="#" id={"edit" + db._id}>
                    <i className="mdi mdi-pencil font-size-20 text-success me-1"></i>
                    <UncontrolledTooltip placement="top" target={"edit" + db._id}>
                      編輯
                    </UncontrolledTooltip>
                  </Link>
                </div>
                {/*---- Edit Icon Tail ----*/}



                {/*---- Delete Icon ----*/}
                <div className="flex-fill" onClick={() => onClickDelete(db)}>
                  <Link to="#" id={"delete" + db._id}>
                    <i className="mdi mdi-trash-can font-size-20 text-danger me-1"></i>
                    <UncontrolledTooltip placement="top" target={"delete" + db._id}>
                      刪除
                    </UncontrolledTooltip>
                  </Link>
                </div>
                {/*---- Delete Icon Tail ----*/}



                {/*---- Info Icon ----*/}
                <div className="flex-fill" onClick={() => setInfoModal(!infoModal)}>
                  <Link to="#" id={"info" + db?._id}>
                    <i className="mdi mdi-help-circle-outline font-size-20 text-secondary me-1"></i>
                    <UncontrolledTooltip
                      placement="top"
                      target={"info" + db?._id}
                    >
                      資訊
                    </UncontrolledTooltip>
                  </Link>
                </div>
                {/*---- Info Icon Tail ----*/}


              </div>

            </CardFooter>
            {/* Bottom Icons Tail */}

          </Card>
        </Link>
      </Col>
      {/*---- Card Content Tail ----*/}

    </React.Fragment>
  );
};

CardContact.propTypes = {
  db: PropTypes.object
};

export default CardContact;
