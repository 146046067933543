import React, { useState, useEffect } from "react";

import { Row, Col, Card, CardBody, UncontrolledTooltip } from "reactstrap"
import { Link } from "react-router-dom"

import { useSelector } from "react-redux";

// Custom Utils
import { fetchImage } from 'utils/imageUtils';
// Image
import placeholder from "assets/images/placeholders/placeholder_card.png"
import profileImg from "assets/images/profile-img.png"

const WelcomeComp = (props) => {

  const { userData } = props;

  const [profilePic, SetProfilePic] = useState(placeholder);

  useEffect(() => {
    const fetchAndSetProfilePic = async () => {
      const newProfilePic = await fetchImage(userData.profileImg);
      SetProfilePic(newProfilePic);
    };
    if(userData.profileImg){fetchAndSetProfilePic();}
  }, [userData]);


  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row className="welcome-purple">
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">歡迎回來 !</h5>
                <div 
                  style={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                  }}
                >
                  <p style={{ marginBottom:'0rem' }}>{`TheChart.AI {${ userData?.isPremiumUser ? '專業版' : '試用版' }} 帳號`}</p>

                  {/*---- Info Icon ----*/}
                  <Link to="#" id={"info_package"}
                    style={{ marginLeft: '0.3rem' }}
                  >
                    <i className="mdi mdi-help-circle-outline font-size-20 text-secondary me-1"></i>
                    {userData?.isPremiumUser && (
                      <UncontrolledTooltip
                        placement="top"
                        target={"info_package"}
                      >
                        專業版<br/>
                        可同時建立專案數：100<br/>
                        每月方案翻譯字數：50000
                      </UncontrolledTooltip>
                    )}
                    {!userData?.isPremiumUser && (
                      <UncontrolledTooltip
                        placement="top"
                        target={"info_package"}
                      >
                        試用版<br/>
                        可同時建立專案數：10<br/>
                        每月方案翻譯字數：3000
                      </UncontrolledTooltip>
                    )}
                  </Link>
                  {/*---- Info Icon Tail ----*/}
                </div>

              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img 
                src={profileImg} 
                alt="Ship_Image" 
                className="img-fluid"
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover"
                }}
              />
            </Col>
          </Row>
        </div>

        <CardBody className="pt-0">

          <Row>

            <Col sm="12">
              <div className="avatar-md profile-user-wid" style={{ marginBottom: "-1.5rem" }}>
                <img
                  src={profilePic || placeholder}
                  alt="profilePic"
                  className="img-thumbnail rounded-circle"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover"
                  }}
                />
              </div>
              <h5 
                className="font-size-15 text-truncate"
                style={{ textAlign: "end" }}
              >
                {userData.username || ''}
              </h5>
              <p 
                className="text-muted mb-0"
                style={{ textAlign: "end" }}
              >
                {userData.email || ''}
              </p>
            </Col>

            <Col sm="12">
              <div className="pt-4">
                <Row>
                  <Col xs="4" lg="4" xl="3">
                    <h5 className="font-size-15">
                      {userData.projects && userData.sharedProjects && 
                        (userData.projects.length+userData.sharedProjects.length || 0)}
                    </h5>
                    <p className="text-muted mb-0">專案總數</p>
                  </Col>
                  <Col xs="4" lg="4" xl="3">
                    <h5 className="font-size-15">{userData.databases && userData.databases.length || 0}</h5>
                    <p className="text-muted mb-0">資料庫總數</p>
                  </Col>
                  <Col xs="4" lg="4" xl="6"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      marginTop: "1rem",
                    }}
                  >
                    <Link
                      to="/profile"
                      className="btn btn-primary btn-sm" 
                      style={{ minWidth: "3rem" }}
                    >
                      帳號資訊 <i className="mdi mdi-arrow-right ms-1"></i>
                    </Link>
                  </Col>
                </Row>
              </div>
            </Col>

          </Row>

        </CardBody>

      </Card>


    </React.Fragment>
  )
}
export default WelcomeComp
