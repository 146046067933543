// Edit Profile
export const EDIT_PROFILE = "EDIT_PROFILE";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_ERROR = "PROFILE_ERROR";

export const EDIT_PROFILE_EMAIL = 'EDIT_PROFILE_EMAIL';
export const EMAIL_SUCCESS = "EMAIL_SUCCESS";
export const EMAIL_ERROR = "EMAIL_ERROR";

export const EDIT_PROFILE_IMAGE = 'EDIT_PROFILE_IMAGE';
export const IMAGE_SUCCESS = "IMAGE_SUCCESS";
export const IMAGE_ERROR = "IMAGE_ERROR";

export const EDIT_PROFILE_USERNAME = 'EDIT_PROFILE_USERNAME';
export const USERNAME_SUCCESS = "USERNAME_SUCCESS";
export const USERNAME_ERROR = "USERNAME_ERROR";

export const EDIT_PROFILE_PASSWORD = 'EDIT_PROFILE_PASSWORD';
export const PASSWORD_SUCCESS = "PASSWORD_SUCCESS";
export const PASSWORD_ERROR = "PASSWORD_ERROR";

// Edit Profile Results

export const CLEAR_PROFILE_SUCCESS = "CLEAR_PROFILE_SUCCESS";
export const CLEAR_PROFILE_ERROR = "CLEAR_PROFILE_ERROR";
export const RESET_PROFILE_FLAG = "RESET_PROFILE_FLAG";