import React, { useMemo, useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from "reactstrap";

const orderedKeys = ["question", "knowledgecontent"];
const keyToLabelMapping = {
  question: "範例提問",
  knowledgecontent: "範例回答"
};

/*---- Main Modal Component ----*/
const EditSubknowledgeModal = ({ 
  isEditSubknowledgeModalOpen, 
  setIsEditSubknowledgeModalOpen, 
  textareaRefs,
  editingDataRef,
  subknowledges,
  editingSubknowledge,
  setSubknowledges,
  newSubknowledge,
  setNewSubknowledge,
  modalData, 
  setModalData,
  updateDataEntry,
  filteredData
}) => {

  useEffect(()=>{
    if(isEditSubknowledgeModalOpen){
      if(filteredData.length && editingSubknowledge){
        console.log("HERE151", filteredData, editingSubknowledge);
        const thisFilteredData = filteredData.slice().filter(f=>f.id===editingSubknowledge.knowledgeId)[0];
        setSubknowledges(thisFilteredData?.subknowledges);
        setModalData({
          masterquestion: thisFilteredData.masterquestion,
          masterknowledge: thisFilteredData.masterknowledge,
        });
      }

      setNewSubknowledge({
        question: editingSubknowledge.question,
        knowledgecontent: editingSubknowledge.knowledgecontent
      })
    }
  },[isEditSubknowledgeModalOpen])
 

  return (
    <React.Fragment>
      {/*---- Modal for editing dataentries ----*/}
      <Modal isOpen={isEditSubknowledgeModalOpen} toggle={() => setIsEditSubknowledgeModalOpen(!isEditSubknowledgeModalOpen)}>
        {/* Modal Header */}
        <ModalHeader toggle={() => setIsEditSubknowledgeModalOpen(!isEditSubknowledgeModalOpen)}>編輯範例問答</ModalHeader>

        {/* Modal Body */}
        <ModalBody>
          {/* 提問與參考資料內容 */}
          {orderedKeys.map((key) => (
            <div key={key} style={{ marginBottom: "15px" }}>
              <p>{keyToLabelMapping[key] || key}:</p>
              <textarea
                ref={(el) => { textareaRefs.current[key] = el; }}
                value={newSubknowledge[key]}
                onChange={(e) => {
                  setNewSubknowledge((prevData) => ({
                    ...prevData,
                    [key]: e.target.value
                  }));
                }}
                style={{ width: "100%", resize: 'none', overflow: 'hidden' }}
                className="form-control"
              />
            </div>
          ))}
        </ModalBody>

        {/* Modal Footer */}
        <ModalFooter>
          <Row style={{ width:'100%' }}>
            <Col sm="12"
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button 
                color="primary" 
                onClick={() => {
                  const updatedSubknowledges = subknowledges.map((sk, idx) =>
                    sk._id === editingSubknowledge._id ? newSubknowledge : sk
                  );
                  setSubknowledges(updatedSubknowledges);
                  updateDataEntry(editingDataRef.current.id, modalData, updatedSubknowledges);
                  setIsEditSubknowledgeModalOpen(false);
                }}
                style={{ marginRight:'1vw' }}
              >
                儲存變更
              </Button>
              <Button color="secondary" onClick={() => setIsEditSubknowledgeModalOpen(false)}>取消變更</Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
  {/*---- Modal for editing dataentries Tail ----*/}

};

export default EditSubknowledgeModal;
