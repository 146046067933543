
/* SET DATABASE */
export const SET_DATABASES = 'SET_DATABASES';
export const SET_DATABASE_IMAGE = 'SET_DATABASE_IMAGE';

export const LOAD_DATABASE_IMAGES = 'LOAD_DATABASE_IMAGES';
export const LOAD_DATABASE_IMAGES_SUCCESS = 'LOAD_DATABASE_IMAGES_SUCCESS';
export const LOAD_DATABASE_IMAGES_FAIL = 'LOAD_DATABASE_IMAGES_FAIL';

export const LOAD_BASEDB_IMAGES = 'LOAD_BASEDB_IMAGES';
export const LOAD_BASEDB_IMAGES_SUCCESS = 'LOAD_BASEDB_IMAGES_SUCCESS';
export const LOAD_BASEDB_IMAGES_FAIL = 'LOAD_BASEDB_IMAGES_FAIL';


/* GET DATABASE */
export const GET_DATABASES = "GET_DATABASES";
export const GET_DATABASES_SUCCESS = "GET_DATABASES_SUCCESS";
export const GET_DATABASES_FAIL = "GET_DATABASES_FAIL";

export const GET_BASE_DATABASES = "GET_BASE_DATABASES";
export const GET_BASE_DATABASES_SUCCESS = "GET_BASE_DATABASES_SUCCESS";
export const GET_BASE_DATABASES_FAIL = "GET_BASE_DATABASES_FAIL";
export const SET_BASE_DATABASE_IMAGE = "SET_BASE_DATABASE_IMAGE";

/* ADD DATABASE */
export const ADD_NEW_DATABASE = "ADD_NEW_DATABASE";
export const ADD_DATABASE_SUCCESS = "ADD_DATABASE_SUCCESS";
export const ADD_DATABASE_FAIL = "ADD_DATABASE_FAIL";

/* DELETE DATABASE */
export const DELETE_DATABASE = "DELETE_DATABASE";
export const DELETE_DATABASE_SUCCESS = "DELETE_DATABASE_SUCCESS";
export const DELETE_DATABASE_FAIL = "DELETE_DATABASE_FAIL";
 
/* UPDATE DATABASE */
export const UPDATE_DATABASE = "UPDATE_DATABASE";
export const UPDATE_DATABASE_SUCCESS = "UPDATE_DATABASE_SUCCESS";
export const UPDATE_DATABASE_FAIL = "UPDATE_DATABASET_FAIL";
 
