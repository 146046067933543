//src/pages/Authentication/Login.js
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { 
  Row, 
  Col, 
  CardBody, 
  Card, 
  Alert, 
  Container, 
  Form, 
  Input, 
  FormFeedback, 
  Label 
} from "reactstrap";
import withRouter from "components/Common/withRouter";

// Custom Axios
import axiosInstance from 'utils/axiosInstance';

// Redux
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { 
  loginUser, 
  logoutUser,
  clearRegisterUser, 
  clearForgetPassword, 
  clearChangePassword, 
  googleLoginRequest,
  clearLoginStates,
  clearGoogleLoginStates,
} from "../../store/actions";

// Import the selectors
import { 
  getLoginError, 
  getLoginLoading, 
  getIsLoggedIn, 
  getGoogleError, 
  getGoogleLoading, 
  getGoogleLoggedIn 
} from 'store/selectors';

// Custom Images
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo.svg";
import perlTea from "assets/images/perlTea12.jpeg";
import perlTeaLogo from "assets/images/perlTea2.jpeg";

const errorMessagesMap = {
  "Invalid credentials": "帳號或密碼錯誤",
  "Another error": "Another custom message",
};


// Main Component
const Login = props => {


  /*---- Title and Initial Setup ----*/

  document.title = "帳號登入 | TheChart.AI";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [viewPassword, setViewPassword] = useState(false);
  const [saveAccount, setSaveAccount] = useState(false);
  const [initialEmail, setInitialEmail] = useState('');
  const [isUserGoBack, setIsUserGoBack] = useState(false);

  // Clear authUser from localStorage upon loading Login Page
  // Clear status in register, forget-password, and change-password
  useEffect(() => {

    // Do not remove this logic below!! This is necessary for user clicking GO BACK from dashboard
    const thisAuthUser = localStorage.getItem('authUser');
    if(thisAuthUser){
      localStorage.setItem('isUserGoBack', true);
    } else {
      localStorage.removeItem('isUserGoBack');
    }
    localStorage.removeItem('authUser');
    console.log('[Login.js] authUser removed!')
    // Do not remove this logic above!! This is necessary for user clicking GO BACK from dashboard

    dispatch(logoutUser(navigate));

    dispatch(clearRegisterUser());
    dispatch(clearForgetPassword());
    dispatch(clearChangePassword());
    if(isLoggedIn){setIsUserGoBack(true)};
  }, []);

  const togglePasswordVisibility = (e) => {
    e.preventDefault();
    setViewPassword(!viewPassword);
  };

  useEffect(() => {
    // Check if there is an account saved in local storage
    const savedAccount = localStorage.getItem('account');
    console.log('savedAccount:', savedAccount);
    if (savedAccount) {
      setInitialEmail(savedAccount);
      setSaveAccount(true);
    }
  }, []);

  const handleSaveAccountChange = (e) => {
    setSaveAccount(!saveAccount);
  };

  /*---- Title and Initial Setup Tail ----*/



  /*---- Store States and Changes ----*/

  // Use memoized selectors
  const error = useSelector(getLoginError);
  const loading = useSelector(getLoginLoading);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const googleError = useSelector(getGoogleError);
  const googleLoading = useSelector(getGoogleLoading);
  const googleLoggedIn = useSelector(getGoogleLoggedIn);

  useEffect(() => { 
    const thisUserGoBack = localStorage.getItem('isUserGoBack');
    if (isLoggedIn && !thisUserGoBack) { navigate('/dashboard'); }
  }, [isLoggedIn]);


  useEffect(() => { 
    const thisUserGoBack = localStorage.getItem('isUserGoBack');
    if (googleLoggedIn && !thisUserGoBack) { navigate('/dashboard'); }
  }, [googleLoggedIn]);



  const [errorMessage, setErrorMessage] = useState("");
  const [googleErrorMessage, setGoogleErrorMessage] = useState("");
  
  useEffect(() => {
    //console.log("error changed:", error);
    setErrorMessage(errorMessagesMap[error] || error);
  }, [error]);

  useEffect(() => {
    //console.log("google error changed:", googleError);
    setGoogleErrorMessage(errorMessagesMap[googleError] || googleError);
  }, [googleError]);

  /*---- Store States and Changes Tail ----*/





  /*---- Login Form ----*/

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: initialEmail || '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Email 不能留空"),
      password: Yup.string().required("密碼不能留空"),
    }),

    onSubmit: async (values) => {
      if (saveAccount) { localStorage.setItem('account', values.email);}
      if (!saveAccount) { localStorage.removeItem('account');}
      // Dispatch the login action
      dispatch(loginUser(values, props.router.navigate));
    }

  });

  // Pressing 'enter' when in password field or clicking Login button
  function handleSubmit(event) {
    event.preventDefault(); // Prevent the default form submission behavior
    localStorage.removeItem('isUserGoBack');
    //setIsLoginAgain(true);
    // Trigger Formik's validation and then, if the input is valid, the onSubmit function
    validation.handleSubmit();
  }

  /*---- Login Form Tail ----*/
  



  /*---- Loading Google Script ----*/

  const [isGoogleApiLoaded, setIsGoogleApiLoaded] = useState(false);

  useEffect(() => {
    const loadGoogleScript = () => {
      const script = document.createElement('script');
      script.src = "https://accounts.google.com/gsi/client";
      script.onload = () => setIsGoogleApiLoaded(true);
      document.body.appendChild(script);
    };

    if (!window.google) {
      loadGoogleScript();
    } else {
      setIsGoogleApiLoaded(true);
    }
  }, []);

  /*---- Loading Google Script Tail ----*/



  /*---- Google Login Button ----*/

  const divRef = useRef(null);
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  useEffect(() => {
    if (isGoogleApiLoaded && divRef.current) {
      const handleCredentialResponse = async (res, error) => {
        localStorage.removeItem('isUserGoBack');
        dispatch(googleLoginRequest(res));
      }
      google.accounts.id.initialize({
        client_id: googleClientId,
        callback: (res, error)=>{ handleCredentialResponse(res, error) },
      });
      google.accounts.id.renderButton( document.getElementById("buttonDiv"), { 
        theme: "outline", 
        size: "large", // large/medium
        type: 'standard',
        text: 'signin-with',
        shape: 'rectangular', // rectangular/pill
        logo_alignment: 'left',
        width: '220',
      });
      //google.accounts.id.prompt(); // also display the One Tap dialog
    }
  }, [isGoogleApiLoaded, divRef, dispatch]);

  /*---- Google Login Button Tail ----*/



  /*---- Send Verification email ----*/

  const resendVerificationEmail = async (email) => {
    try {
      const response = await axiosInstance.post('/knovia/resend-verification', { email });
      alert('已重新寄出驗證信，請前往驗證帳號');
      dispatch(clearLoginStates());
    } catch (error) {
      console.error('Error resending verification email:', error);
      alert('重新發送驗證信時發生錯誤，請稍後再試');
      dispatch(clearLoginStates());
    }
  };

  /*---- Send Verification email Tail ----*/



  /*---- Return Component ----*/

  return (
    <React.Fragment>
      <div className="account-pages" style={{ marginTop: "0rem", padding: '0' }}>
        <Container style={{ maxWidth:'100%', overflow: 'hidden' }}>
          <Row
            style={{
              display: 'flex',
              justifyContent: 'flex-start'
            }}
          >

            <Col sm={12} md={6}
              style={{
                padding: '0',
              }}
            >
              <Card className="overflow-hidden" style={{ height: '100vh', margin:'0' }}>
                <div style={{ backgroundColor: '#fcf5e8' }}>
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary" style={{ padding: '2.5rem 3rem 0rem 8vw' }}>
                        <h5 className="text-primary" style={{ fontSize: '1.2rem' }}>歡迎回來 !</h5>
                        <p
                          style={{ fontSize: '0.85rem', marginTop: '0.8rem' }}
                        >登入 TheChart.AI 平台</p>
                      </div>
                    </Col>
                    <Col xs={5} className="align-self-end"
                      style={{
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    > {/*
                      <img src={perlTeaLogo} alt="" className="img-fluid"
                        style={{ maxHeight: '138px' }}
                      />
                      */}
                      <div style={{ height: '138px' }}></div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>

                  <div style={{ padding: '5vh 8vw 5vh 8vw' }}>
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(e);
                        {/*navigate('/dashboard')*/}
                        return false;
                      }}
                    >


                      {/*--- Login Error ---*/}
                      {!googleErrorMessage && errorMessage &&
                        <Alert color="danger">
                          {errorMessage}
                          {errorMessage === "帳號尚未驗證，請先至註冊信箱收取驗證信，並點擊連結驗證您的帳號" && (
                            <span>
                              {" "}
                              <a href="#" onClick={() => resendVerificationEmail(values.email)}>重寄驗證信</a>
                            </span>
                          )}
                        </Alert>
                      }

                      {/*--- google Login Error ---*/}
                      {!errorMessage && 
                        googleErrorMessage 
                        ? <Alert color="danger">{googleErrorMessage}</Alert> 
                        : null
                      }

                      {/*
                      {true && (<>
                        <Alert color="success">{errorMessage}</Alert> 
                        <Alert color="warning">{googleErrorMessage}</Alert> 
                        </>
                        )
                      }
                      */}


                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="example@gmail.com"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">密碼</Label>
                        
                        <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type={viewPassword ? 'text' : 'password'}
                            placeholder="輸入密碼"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            onKeyPress={e => {
                              if (e.key === 'Enter') {
                                e.preventDefault();  // Prevent the default form submit behavior
                                // Assuming there's a function to handle form submission:
                                handleSubmit(e);     // Replace `handleSubmit` with your actual submission handler
                              }
                            }}
                          />
                          {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                          ) : null}
                            <button 
                              onClick={e=>{togglePasswordVisibility(e)}} 
                              aria-label="Toggle password visibility"
                              style={{
                                position: 'absolute',
                                right: 10,
                                border: 'none',
                                background: 'none',
                                color: '#556ee6',
                                cursor: 'pointer'
                              }}
                              type="button"
                            >
                            <FontAwesomeIcon icon={viewPassword ? faEyeSlash : faEye} />
                          </button>
                        </div>

                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                          onClick={handleSaveAccountChange}
                          onChange={()=>{}}
                          checked={saveAccount}
                          //onChange={(e) => setSaveAccount(e.target.checked)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          記住帳號
                        </label>
                      </div>

                      <div className="mt-3" style={{ display:'flex', justifyContent: 'center' }}>
                        <button
                          className="btn btn-outline-primary btn-block"
                          type="submit"
                          style={{ width:'18vw' }}
                        >
                        {loading &&
                          (<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>)
                        }
                          登入
                        </button>
                      </div>


                      {/* Other Login Methods */}
                      <div className="mt-4 text-center">

                        <h4 className="font-size-13 mb-3">或使用 Google 帳號直接登入</h4>

                        {/* Google Login */}
                        <ul className="list-inline" style={{ height: "3.0rem" }}>
                          
                          <li className="list-inline-item">
                            <div id="buttonDiv" ref={divRef} />
                          </li>

                        </ul>
                        {/* Google Login Tail */}

                      </div>

                    </Form>
                  </div>

                  <div className="text-center" 
                    style={{
                      position: 'absolute',
                      left: 'calc(50% - 144px)',
                      bottom: '5vh',
                    }}
                  >
                    <p style={{ marginBottom:'0.5rem' }}>
                      <Link to="/forgot-password" className="text-muted">
                        <i className="mdi mdi-lock me-1" />
                        忘記密碼?
                      </Link>
                    </p>
                    <p  style={{ marginBottom:'1.2rem' }}>
                      還沒有帳號?{" "}
                      <Link to="/register" className="fw-medium text-primary">
                        {" "}
                        註冊帳號{" "}
                      </Link>{" "}
                    </p>
                    <p>
                      © {new Date().getFullYear()} TheChart.AI Crafted with{" "}
                      <i className="mdi mdi-chart-bubble" style={{ color:'#9d6237' }}/> in Taipei
                    </p>
                  </div>

                </CardBody>
              </Card>


            </Col>

            <Col md={6}
              style={{
                padding: '0',
                height: '100vh'
              }}
              className="d-none d-md-block"
            >
              <img
                src={perlTea}
                alt="perlTea"
                style={{
                  maxHeight: '100vh',
                  minHeight: '100vh',
                  minWidth: '50vw',
                  objectFit: 'cover'
                }}
              />
            </Col>

          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};

/*---- Optional Redirect Pattern for Google Login ----*/

  /*
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (token) {
      // Create an object with the token
      const tokenObject = { token: token };
      // Store the object in localStorage
      localStorage.setItem('authUser', JSON.stringify(tokenObject));
      navigate('/dashboard');
    }
    // If there's no token, stay on the login page
  }, [dispatch]);
  */

/*---- Optional Redirect Pattern for Google Login Tail ----*/



