import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone";
import Select from "react-select";
import { 
  Alert,
  Button, 
  Card, 
  CardBody, 
  CardTitle, 
  Col, 
  Container, 
  Form, 
  FormGroup, 
  Input, 
  Label, 
  Row 
} from "reactstrap";

// uuid
import { v4 as uuidv4 } from 'uuid';

// Custom Axios
import axiosInstance from '../../utils/axiosInstance';

// Redux
import { useSelector, useDispatch } from "react-redux";

// Tools
import { uploadImg } from 'utils/imageUtils';
import { dateGen, formatBytes } from 'utils/toolUtils';

// XLSX: read Excel Files
import * as XLSX from 'xlsx';

//Import file icons
import wordIcon from "../../assets/images/small/word_file_icon.png";
import pdfIcon from "../../assets/images/small/pdf_file_icon.png";
import excelIcon from "../../assets/images/small/excel_file_icon.png";
import defaultIcon from "../../assets/images/small/unknown_file_icon.png";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

function getFileIcon(file) {
  const fileName = file.filename.toLowerCase();
  if (fileName.endsWith('.doc') || fileName.endsWith('.docx')) { return wordIcon; } 
  if (fileName.endsWith('.pdf')) { return pdfIcon; }
  if (fileName.endsWith('.xls') || fileName.endsWith('.xlsx')) { return excelIcon; } 
  return defaultIcon;
}

// Function to map uploadStatus to color
const getUploadStatusColor = (status) => {
  // Regex to match the pattern "File format .<extension> not allowed"
  // [\.] is the '.', [.+] is any characters
  const fileFormatNotAllowedRegex = /^File format \..+ not allowed$/;

  if (fileFormatNotAllowedRegex.test(status)) {
    return 'orange'; // Color for file format not allowed
  }
  switch (status) {
    case 'File uploaded successfully!':
      return 'mediumseagreen';
    case 'Failed to upload file.':
    case 'Error occurred during upload.':
      return 'red';
    default:
      return 'black'; // Default color for other statuses
  }
};



/*---- Main Component ----*/
const ChatbotCreate = () => {


  /*---- Title and Initial Setup ----*/

  document.title = "建立資料庫：上傳檔案 | TheChart.AI";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  /*---- Title and Initial Setup ----*/



  /*---- Form fields States and Changes ----*/

  const [chatbotName, setChatbotName] = useState('');
  const [chatbotDesc, setChatbotDesc] = useState('');

  const handleChatbotNameChange = (e) => setChatbotName(e.target.value);
  const handleChatbotDescChange = (e) => setChatbotDesc(e.target.value);

  const [selectedChatbotTypes, setSelectedChatbotTypes] = useState([]);

  const handleChatbotTypeChange = (e) => {
    const options = e.target.options;
    const values = [];
    for (let i = 0, len = options.length; i < len; i++) {
      if (options[i].selected) { values.push(options[i].value) }
    }
    setSelectedChatbotTypes(values);
  };

  const [hasTitleRow, setHasTitleRow] = useState(false);
  const handleCheckboxChange = (e) => { setHasTitleRow(!hasTitleRow) };

  /*---- Form fields States and Changes Tail ----*/



  /*---- Handle File dropped in the Dropzone ----*/

  // For Excel files
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [excelKeys, setExcelKeys] = useState([]);
  const [excelEntries, setExcelEntries] = useState([]);
  // For Showing file format error
  const [errorMessage, setErrorMessage] = useState('');

  function handleAcceptedFiles(files) {

    // Only processing the first file selected
    const fileProcessingPromises = files.slice(0,1).map(file => {

      return new Promise((resolve, reject) => {

        // Filename in lowercase, pop() gets the last item of the array
        const fileType = file.name.split('.').pop().toLowerCase();

        // Only process Excel files
        if (fileType === 'xls' || fileType === 'xlsx' || fileType === 'csv' || fileType === 'xlsm') {

          setErrorMessage('');

          const fileReader = new FileReader();
          fileReader.readAsArrayBuffer(file);

          fileReader.onload = (e) => {
            const bufferArray = e.target.result;

            const wb = XLSX.read(bufferArray, { type: 'buffer' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];

            // Read the data into an array of arrays
            const rawData = XLSX.utils.sheet_to_json(ws, { header: 1 });

            let keys;
            let data;

            if (hasTitleRow) {
              keys = rawData[0].slice(0,2);
              data = rawData.slice(1).map(row => {
                const obj = {};
                row.slice(0,2).forEach((cell, index) => {
                  obj[keys[index]] = cell;
                });
                return obj;
              });
            } else {
              // First row is data, generate keys based on column index
              keys = rawData[0].slice(0,2).map((_, index) => `Column ${index + 1}`);
              data = rawData.map(row => {
                const obj = {};
                row.slice(0,2).forEach((cell, index) => {
                  obj[keys[index]] = cell;
                });
                return obj;
              });
            }

            resolve({ data, keys });
          };

          fileReader.onerror = (error) => { reject(error); };

        } else {
          setErrorMessage('請上傳 Excel 檔案');
          console.log('Not processing file', file.name, 'as this is a', fileType);
          resolve({});
        }

      }).then((d) => {

        if(d.data){
          // Create a new object with file's properties and additional attributes
          setExcelKeys(d.keys);
          setExcelEntries(d.data);
          console.log('Excel Keys:', d.keys);
          console.log('Excel Entries:', d.data);

          return {
            ...file,
            filename: file.name,
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size)
          };
        } else {
          return {};
        }
      });
    });

    Promise.all(fileProcessingPromises).then(processedFiles => {
      setSelectedFiles(processedFiles);
    });

  }

  /*---- Handle File dropped in the Dropzone Tail ----*/

  

  /*---- Processing Chatbot Profile Image ----*/

  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState('還沒有選擇圖片喔');
  const [imageUrl, setImageUrl] = useState(''); // 顯示伺服器端回傳預覽圖片 url
  const [uploadStatus, setUploadStatus] = useState(''); // 圖片上傳狀態
  const fileInputRef = useRef(null);  // The original button for choosing image file

  // OnClick handler for the beautified choosing image file button
  const handleButtonClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    setUploadStatus('');
    //console.log("Selected file:", event.target.files[0]);
    setFile(event.target.files[0]);
  };

  const [imgUploading, setImgUploading] = useState(false);

  useEffect(() => {
    const performUpload = async () => {
      if (file) {
        // Reset upload status
        setUploadStatus('');

        const date14string = await dateGen();
        const newfilename = date14string + '_' + file.name;
        setFilename(newfilename);
        setImgUploading(true);
        const {newImageUrl, uploadStatus} = await uploadImg(newfilename, file);
        setImgUploading(false);
        setUploadStatus(uploadStatus);
        setImageUrl(newImageUrl);
      }
    };
    performUpload();
  }, [file]);

  /*---- Processing Chatbot Profile Image Tail ----*/



  /*----- Create New Chatbot -----*/

  const chunkSize = 100;
  const [uploadLoading, setUploadLoading] = useState(false);
  const [createWarnings, setCreateWarnings] = useState("");

  const sendChunk = async (chunk, transactionId, isFinalChunk = false) => {
    const submitEntries = chunk.map(item => ({
      'Source': item[excelKeys[0]] || '',
      'Target': item[excelKeys[1]] || ''
    }));

    const chatbotData = {
      cbName: chatbotName,
      cbDesc: chatbotDesc,
      img: filename,
      createDate: new Date(),
      tags: selectedChatbotTypes,
    };
    console.log("Chatbot metadata:", chatbotData);
    console.log("This chunk", chunk);

    const chunkData = {
      ...chatbotData,
      entries: submitEntries,
      isChunk: !isFinalChunk,
      transactionId
    };

    try {

      setUploadLoading(true);
      const response = await axiosInstance.post(`/knovia/chatbots`,
        chunkData, 
        {
          headers: {
            'Content-Type': 'application/json',
        },
      });

      console.log("Chunk sent, server response:", response.data);
      setUploadLoading(false);
      return true;

    } catch (error) {
      const errorMessage = error.response && error.response.data && error.response.data.message;
      if (errorMessage === 'A Chatbot with the same name already exists for this user.') {
        setCreateWarnings("無法建立名稱重複的智能客服，請另取智能客服名稱");
      } else {
        setCreateWarnings(`Error sending data to the server: ${errorMessage || "Please try again."}`);
      }
      console.error("Error sending chunk to server:", error);
      setUploadLoading(false);
      return false;
    }
  };

  const createChatbot = async (e) => {

    e.preventDefault();

    const transactionId = uuidv4(); // Generate a unique transaction ID
    console.log("Creating Chatbot...");

    for (let i = 0; i < excelEntries.length; i += chunkSize) {
      const chunk = excelEntries.slice(i, i + chunkSize);
      const isFinalChunk = (i + chunkSize >= excelEntries.length);
      const success = await sendChunk(chunk, transactionId, isFinalChunk);
      if (!success) {
        // Handle error, possibly by breaking the loop
        break;
      } else {
        if (isFinalChunk){
          // Get the new databases
          dispatch({ type: 'FETCH_USER_DATA' });
          navigate('/chatbot-menu'); 
        }; 
      }
    }

  };

  /*----- Create New Database Tail -----*/



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="智能客服" breadcrumbItem="建立智能客服" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">上傳檔案</CardTitle>
                  <Form>

                    {/* 專案名稱 */}
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="chatbotname"
                        className="col-form-label col-lg-2"
                      >
                        智能客服名稱
                      </Label>

                      <Col lg="10">
                        <Input
                          id="chatbotname"
                          name="chatbotname"
                          type="text"
                          className="form-control"
                          placeholder="輸入智能客服名稱"
                          value={chatbotName}
                          onChange={handleChatbotNameChange}
                        />
                      </Col>
                    </FormGroup>

                    {/* Select Input for Glossary Client */}
                    {/*
                    <FormGroup className="mb-4" row>
                      <Label htmlFor="glossaryClient" className="col-form-label col-lg-2">
                        資料庫客戶
                      </Label>

                      <Col sm={10}>
                        <div className="mb-3">
                          <Select
                            id='glossaryClient'
                            name="glossaryClient"
                            onChange={(option)=>{handleGlossaryClientChange(option)}}
                            options={clientOptions}
                            placeholder={'選擇報告客戶'}
                          />
                        </div>
                      </Col>

                    </FormGroup>
                    */}

                    {/* Multi-Select for Glossary Types */}
                    <FormGroup className="mb-4" row>
                      <Label htmlFor="chatbotType" className="col-form-label col-lg-2">
                        <span>智能客服類型<br/></span>
                        <div style={{ marginBottom:'0.3rem' }}></div>
                        <span style={{ fontSize:'0.6rem' }}>(選取多個項目<br/>Windows: Ctrl/Shift<br/>Mac: Command/Shift)</span>
                      </Label>
                      <Col lg="10">
                        <Input
                          id="chatbotType"
                          name="chatbotType"
                          type="select"
                          multiple
                          value={selectedChatbotTypes} // This should be an array of selected values
                          onChange={handleChatbotTypeChange} // Handler function needs to be adjusted for multi-select
                        >
                          <option value="">選擇智能客服類型:</option>
                          <option value="產品說明">產品說明</option>
                          <option value="產品銷售">產品銷售</option>
                          <option value="場館導覽">場館導覽</option>
                          <option value="文本問答">文本問答</option>
                          <option value="智能客服">智能客服</option>
                          <option value="其他">其他</option>
                        </Input>
                      </Col>
                    </FormGroup>


                    {/*---- 選擇資料庫圖片 ----*/}
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectpic"
                        className="col-form-label col-lg-2"
                      >
                        智能客服頭像
                      </Label>
                      <Col lg="10">

                        {/* 選擇圖片按鈕 + 圖片名稱 */}
                        <Row>
                          <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                          />
                          <Col sm="3">
                            <button className="btn btn-outline-info" onClick={handleButtonClick}>
                              <span style={{ marginRight: imgUploading?'0.3rem':'0rem' }}>選擇圖片</span>
                              {imgUploading && 
                                (<i className="bx bx-loader bx-spin font-size-16 align-middle"></i>)
                              }
                            </button>
                          </Col>
                          <Col sm="9" style={{display:"flex", alignItems:"center"}}>
                            <span>{filename}</span>
                          </Col>
                        </Row>

                        {/* 上傳圖片狀態 */}
                        <Row>
                          <Col sm="12">
                            {uploadStatus && (
                              <p style={{ color: getUploadStatusColor(uploadStatus) }}>
                                {uploadStatus}
                              </p>
                            )}
                          </Col>
                        </Row>

                        {/* 已選擇圖片預覽 */}
                        <Row>
                          <div>
                            {imageUrl && <img src={imageUrl} className="profile-pic" alt="Fetched" />}
                          </div>
                        </Row>

                      </Col>
                      
                    </FormGroup>

                    {/* 智能客服功能描述 */}
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="chatbotdesc"
                        className="col-form-label col-lg-2"
                      >
                        智能客服功能描述
                      </Label>
                      <Col lg="10">
                        <textarea
                          className="form-control"
                          id="chatbotdesc"
                          rows="3"
                          placeholder="輸入智能客服功能描述"
                          value={chatbotDesc}
                          onChange={handleChatbotDescChange}
                        />
                      </Col>
                    </FormGroup>

                  </Form>


                  <Row className="mb-4">


                    <Col lg="2">
                      <div>
                        <Label className="col-form-label">
                          <span style={{ display: 'block'}}>上傳 Excel 檔</span>
                        </Label>
                      </div>

                      <div>
                        <label>
                          <Input
                            type="checkbox"
                            checked={hasTitleRow}
                            onClick={handleCheckboxChange}
                            onChange={()=>{}}
                          />
                          <span style={{ marginLeft: "0.3rem" }}>檔案有標題列</span>
                        </label>
                      </div>
                    </Col>


                    {/* 檔案上傳 */}
                    <Col lg="10">
                      <Form>

                        {/* 上傳區塊 */}
                        <Dropzone onDrop={acceptedFiles => { handleAcceptedFiles(acceptedFiles) }}>
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone" style={{cursor:"pointer"}}>
                              <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="dz-message needsclick">
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>拖曳檔案到此或點擊上傳 Excel 檔</h4>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>

                        {/* 上傳檔案預覽 */}
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {selectedFiles[0]?.filename && selectedFiles.map((f, i) => {
                            let fileIcon='';
                            if(f.filename){
                              fileIcon = getFileIcon(f)
                            }
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >

                                <div className="p-2">
                                  <Row className="align-items-center">

                                    {/* 檔案預覽圖片 */}
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.filename}
                                        src={fileIcon || ''}
                                      />
                                    </Col>

                                    <Col>

                                      {/* 檔案名稱 */}
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.filename}
                                      </Link>

                                      {/* 檔案大小 */}
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>

                                    </Col>

                                  </Row>
                                </div>

                              </Card>
                            );
                          })}
                        </div>
                        {errorMessage ? <Alert color="danger">{errorMessage}</Alert> : null}

                      </Form>
                    </Col>

                  </Row>

                  {/* Preview Excel Content */}
                  <Row className="mb-4">

                    <Label className="col-form-label col-lg-2">
                      資料庫內容預覽
                    </Label>

                    {/* 檔案上傳 */}
                    <Col lg="10">
                      <div 
                        style={{
                          border: "1px dashed #cdcbcb", 
                          borderRadius: "0.5rem", 
                          padding: "1rem 1rem 0rem 1rem",
                          minHeight: "5rem",
                          marginTop: "0.3rem",
                        }}
                      >

                        {/* Rendering paragraphs */}
                        <table style={{ width: "100%" }}>
                          {/* 表格標題 */}
                          <thead>
                            {excelEntries && excelEntries.length>0?(
                              <tr>
                              <th style={{
                                padding: "0.8rem 0rem",
                                marginBottom: "0.5rem",
                              }}>#</th>
                              <th style={{ 
                                textAlign: "center",
                                padding: "0.8rem 0rem",
                                marginBottom: "0.5rem",
                                borderBottom: "2px solid #586c7b",
                                width: "30%",
                              }}>{`提問 - `}</th>
                              <th style={{ 
                                textAlign: "center",
                                padding: "0.8rem 0rem",
                                marginBottom: "0.5rem",
                                borderBottom: "2px solid #586c7b",
                                width:"70%",
                              }}>{`回答 - `}</th>
                            </tr>
                          ):(<></>)}
                          </thead>
                          {/* 表格內容 */}

                          <tbody>
                            {excelEntries && excelEntries.map((item, index) => (
                              <tr key={index}>
                                {/* Assuming your Excel has columns: 'Name' and 'Age' */}
                                <td style={{ padding: "0.5rem 0.7rem 0.5rem 0.3rem" }}>{index+1}</td>
                                <td style={{ borderBottom: "1px solid #9da5c6", padding:"0.5rem 1.5rem" }}>{item[excelKeys[0]]}</td>
                                <td style={{ borderBottom: "1px solid #9da5c6", padding:"0.5rem 1.5rem" }}>{item[excelKeys[1]]}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                      </div>
                    </Col>
                  </Row>

                  {createWarnings && <Alert color="danger">{createWarnings}</Alert> }

                  {/* 開啟新專案按鈕 */}
                  <Row className="justify-content-end">
                    <Col lg="10" style={{ display: "flex", justifyContent: "center" }}>
                      <Button type="submit" color="primary" onClick={createChatbot}>
                        <span style={{ marginRight: uploadLoading?'0.3rem':'0rem' }}>建立智能客服</span>
                        {uploadLoading && 
                          (<i className="bx bx-loader bx-spin font-size-16 align-middle"></i>)
                        }
                      </Button>
                    </Col>
                  </Row>

                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>

  );
};

export default ChatbotCreate;
