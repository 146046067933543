import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { 
  Row, 
  Col, 
  Alert, 
  Card, 
  CardBody, 
  Container, 
  FormFeedback, 
  Input, 
  Label, 
  Form 
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// action
import { 
  verifyAccountRequest,
} from "../../store/actions";

import "./custom.css";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/placeholders/placeholder_card.png";

const VerifyAccountPage = props => {

  const { token } = useParams();

  //meta title
  document.title = "帳號驗證成功 | TheChart.AI";
  const dispatch = useDispatch();

  useEffect(()=>{
    console.log('Verifying account for:', token);
    dispatch(verifyAccountRequest(token));
  }, [dispatch]);
  

  return (
    <React.Fragment>

      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>

      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">

                {/* Visual */}
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">帳號驗證成功！</h5>
                        <p>歡迎加入 TheChart.AI</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>

                <CardBody className="pt-0">

                  {/* Logo */}
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            style={{ height:"100%", width:"100%" }}
                          />
                        </span>
                      </div>
                    </Link>
                  </div>

                  <div 
                    className="p-2"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Link to="/login">
                      <button
                        className="btn btn-primary w-md"
                        type="button"
                      >
                        回到登入頁面
                      </button>
                    </Link>
                  </div>

                </CardBody>
              </Card>

              
              <div className="mt-5 text-center">

                {/*
                <p>
                  回到{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    登入頁面
                  </Link>{" "}
                </p>
                */}

                <p>
                  © {new Date().getFullYear()} TheChart.AI Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> in Taipei
                </p>

              </div>

            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};

VerifyAccountPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(VerifyAccountPage);
