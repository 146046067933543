import React, { useMemo, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from "reactstrap";

const orderedKeys = ["question", "knowledgecontent"];
const keyToLabelMapping = {
  question: "範例提問",
  knowledgecontent: "範例回答"
};

/*---- Main Modal Component ----*/
const AddSubknowledgeModal = ({ 
  isAddSubknowledgeModalOpen, 
  setIsAddSubknowledgeModalOpen, 
  textareaRefs,
  editingDataRef,
  subknowledges,
  setSubknowledges,
  newSubknowledge,
  setNewSubknowledge,
  modalData, 
  setModalData,
  updateDataEntry,
}) => {

  const addSubknowledge = () => {
    setSubknowledges((prev)=>[
      ...prev,
      newSubknowledge
    ]);
  };

  return (
    <React.Fragment>
      {/*---- Modal for editing dataentries ----*/}
      <Modal isOpen={isAddSubknowledgeModalOpen} toggle={() => setIsAddSubknowledgeModalOpen(!isAddSubknowledgeModalOpen)}>
        {/* Modal Header */}
        <ModalHeader toggle={() => setIsAddSubknowledgeModalOpen(!isAddSubknowledgeModalOpen)}>新增範例提問</ModalHeader>
        {/* Modal Body */}
        <ModalBody>
          {/* 提問與參考資料內容 */}
          {orderedKeys.map((key) => (
            <div key={key} style={{ marginBottom: "15px" }}>
              <p>{keyToLabelMapping[key] || key}:</p>
              <textarea
                ref={(el) => { textareaRefs.current[key] = el; }}
                value={modalData[key]}
                onChange={(e) => {
                  setNewSubknowledge((prevData) => ({
                    ...prevData,
                    [key]: e.target.value
                  }));
                }}
                style={{ width: "100%", resize: 'none', overflow: 'hidden' }}
                className="form-control"
              />
            </div>
          ))}
        </ModalBody>

        {/* Modal Footer */}
        <ModalFooter>
          <Row style={{ width:'100%' }}>
            <Col sm="12"
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button 
                color="primary" 
                onClick={() => {
                  addSubknowledge();
                  updateDataEntry(editingDataRef.current.id, modalData, [
                    ...subknowledges,
                    newSubknowledge
                  ]);
                  setIsAddSubknowledgeModalOpen(false);
                }}
                style={{ marginRight:'1vw' }}
              >
                儲存變更
              </Button>
              <Button color="secondary" onClick={() => setIsAddSubknowledgeModalOpen(false)}>取消變更</Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
  {/*---- Modal for editing dataentries Tail ----*/}

};

export default AddSubknowledgeModal;