import React, { useState, useEffect, useCallback } from 'react';
import {
	Alert,
	Row,
	Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Redux
import { useSelector, useDispatch } from "react-redux";
import { setDatabaseImage, setBaseDatabaseImage } from "store/actions";

import { fetchImage } from 'utils/imageUtils';

// Custom CSS
import "./DbListEditModalStyle.css";

// Custom Axios
import axiosInstance from 'utils/axiosInstance';

import placeholder from "assets/images/placeholders/placeholder_card.png";


/*---- Main Modal Component ----*/
const DbListEditModal = ({ isOpen, onRequestClose, onItemsSorted, sortedDbList, sortedBaseDbList }) => {




  /*---- States and Inital Setups ----*/

	const dispatch = useDispatch();
  const userData = useSelector(state => state.user.userData);

	const [items, setItems] = useState([]);
  const [baseItems, setBaseItems] = useState([]);
  const [totalDbLength, setTotalDbLength] = useState(0);
  const [maxRefNum, setMaxRefNum] = useState(6000);

  useEffect(()=>{
    setMaxRefNum((userData?.isPremiumUser ? 12000 : 6000));
  },[userData?.isPremiumUser]) 

  const fetchTotalDbLength = async (addItem=[]) => {
    try {

      let fullItems = [];
      if(addItem.length>0){
        fullItems = [...addItem.map(addItem => addItem?._id), ...items.map(item => item?._id), ...baseItems.map(baseItem => baseItem?._id)]; 
      } else {
        fullItems = [...items.map(item => item?._id), ...baseItems.map(baseItem => baseItem?._id)]
      }

      const response = await axiosInstance.post('/knovia/db-length', 
        { maxRefNum, selectedDbIds: fullItems }
      );
      let thisTotalDbLength = 0;

      if(response?.data?.totalLength) {
        thisTotalDbLength = response.data.totalLength;
        if(response?.data?.totalLength<=maxRefNum){setTotalDbLength(thisTotalDbLength);}
      }

      return thisTotalDbLength;

    } catch (error) {
      console.error('Error fetching total DB length:', error);
    }

  };


  /*---- Save to Backend when items or baseItems change ----*/ 
  useEffect(() => {
    if (items.length+baseItems.length > 0) {
      console.log('running recalculate:');
      fetchTotalDbLength();
    } else {
      setTotalDbLength(0);
    }
  }, [items, baseItems]);
  /*---- Save to Backend when items or baseItems change Tail ----*/



  /*---- Getting Databases Data ----*/

  useEffect(() => {
    dispatch({ type: 'GET_BASE_DATABASES' });
  }, [dispatch]);

  const { baseDbs, baseDbImages } = useSelector(state => ({
    baseDbs: state.databases.baseDatabases,
    baseDbImages: state.databases.baseDbImages,
  }));

  const { dbs, dbImages } = useSelector(state => ({
    dbs: state.databases.databases,
    dbImages: state.databases.databaseImages,
  }));
  
  useEffect(()=>{ console.log('dbs:', dbs); }, [dbs]);
  
  /*---- Getting Databases Data Tail ----*/



  /*---- Link sortedDbList and sortedBaseDbList (both ids) to real Dbs ----*/
  useEffect(()=>{

    setDbWarningMessage("");
    setBaseDbWarningMessage("");

    const fullSortedDbList = sortedDbList.map(s=>dbs.filter(d=>d._id===s)[0]).filter(s=>!!s);
    const fullSortedBaseDbList = sortedBaseDbList.map(s=>baseDbs.filter(b=>b._id===s)[0]).filter(s=>!!s);

    setItems(fullSortedDbList);
    setBaseItems(fullSortedBaseDbList);

    console.log('isOpen sortedDbList:', fullSortedDbList);
    console.log('isOpen sortedBaseDbList:', fullSortedBaseDbList);

  }, [isOpen]);
  /*---- Link sortedDbList and sortedBaseDbList (both ids) to real Dbs ----*/


  /*---- States and Inital Setups Tail ----*/





  /*---- Selecting Base Database ----*/

  const MAX_BASEDB_ITEMS = 10;
  const [baseDbWarningMessage, setBaseDbWarningMessage] = useState('');

  const [thisBaseDb, setThisBaseDb] = useState("");
  const [availableBaseDbs, setAvailableBaseDbs] = useState([]);

  /*---- Set the available baseDbs list when the modal opens ----*/
  useEffect(() => {
    const initialAvailableBaseDbs = baseDbs.filter(baseDb => 
      !baseItems.some(baseItem => baseItem?._id === baseDb?._id)
    );
    setAvailableBaseDbs(initialAvailableBaseDbs);
  }, [baseDbs, baseItems]);
  /*---- Set the available baseDbs list when the modal opens Tail ----*/


  /*---- Update baseItems when baseDbs are chosen ----*/
  const handleSelectBaseDbChange = async(baseDbindex) => {
    if (baseItems.length >= MAX_BASEDB_ITEMS) {  
      setBaseDbWarningMessage(`不能同時添加超過 ${MAX_BASEDB_ITEMS} 筆底層參考資料`);
      return;
    }

    // Reset warning message if below limit
    setDbWarningMessage('');
    setBaseDbWarningMessage('');

    if (baseDbindex >= 0) {
      const selectedBaseDb = baseDbs[baseDbindex];

      const totalLengthAfterAdded = await fetchTotalDbLength([selectedBaseDb]);
      console.log('totalLengthAfterAdded:', totalLengthAfterAdded);

      if(totalLengthAfterAdded>maxRefNum){
        setBaseDbWarningMessage(`超過最高參考詞組數，若加上此資料庫，總參考詞組數為 ${totalLengthAfterAdded}`);
      } else {
        setThisBaseDb(selectedBaseDb.dbName);
        setBaseItems(prevBaseItems => [...prevBaseItems, selectedBaseDb]);
        setAvailableBaseDbs(prevAvailableBaseDbs => prevAvailableBaseDbs.filter(baseDb => baseDb._id !== selectedBaseDb._id));
      }

    }
  };
  /*---- Update baseItems when baseDbs are chosen Tail ----*/

  /*---- Selecting Base Database ----*/




  /*---- Selecting Database ----*/

  const MAX_DB_ITEMS = 10;
  const [dbWarningMessage, setDbWarningMessage] = useState('');

  const [thisDb, setThisDb] = useState("");
  const [availableDbs, setAvailableDbs] = useState([]);

  /*---- Set the available dbs list when the modal opens ----*/
  useEffect(() => {
	  const initialAvailableDbs = dbs.filter(db => 
	    !items.some(item => item?._id === db?._id)
	  );
    setAvailableDbs(initialAvailableDbs);
  }, [dbs, items]);
  /*---- Set the available dbs list when the modal opens Tail ----*/


  /*---- Update items when dbs are chosen ----*/
	const handleSelectDbChange = async(dbIndex) => {
		if (items.length >= MAX_DB_ITEMS) {  
	    setDbWarningMessage(`不能同時添加超過 ${MAX_DB_ITEMS} 筆額外參考資料`);
	    return;
	  }

	  // Reset warning message if below limit
  	setDbWarningMessage('');
    setBaseDbWarningMessage('');

	  if (dbIndex >= 0) {
	    const selectedDb = dbs[dbIndex];

      const totalLengthAfterAdded = await fetchTotalDbLength([selectedDb]);
      console.log('totalLengthAfterAdded:', totalLengthAfterAdded);

      if(totalLengthAfterAdded>maxRefNum){
        setDbWarningMessage(`超過最高參考詞組數，若加上此資料庫，總參考詞組數為${totalLengthAfterAdded}`);
      } else {
        setThisDb(selectedDb.dbName);
  	    setItems(prevItems => [...prevItems, selectedDb]);
  	    setAvailableDbs(prevAvailableDbs => prevAvailableDbs.filter(db => db._id !== selectedDb._id));
      }
	  }
	};
  /*---- Update items when dbs are chosen ----*/


  /*---- Selecting Database Tail ----*/




  /*---- Loading All BaseDb images ----*/
  /*
  useEffect(() => {
    console.log('Reloading all BaseDb Images...');
    reloadAllBaseImages();
  }, [dispatch, baseDbs]);

  const reloadAllBaseImages = ()=>{
    let fetchedInThisCycle = {};
    baseDbs?.forEach(b => {
      if (!fetchedInThisCycle[b._id]) {
        fetchedInThisCycle[b._id] = true;
        fetchImageAndUpdateBaseDatabases(b);
      }
    });
  }

  const fetchImageAndUpdateBaseDatabases = useCallback((baseDb) => {
    console.log('running fetch...');

    return fetchImage(baseDb.img || '')
      .then(url => {
        dispatch(setBaseDatabaseImage(baseDb._id, url));
      })
      .catch(error => {
        console.error('Error fetching the image:', error);
        dispatch(setBaseDatabaseImage(baseDb._id, placeholder));
      });

  }, [dispatch]);
  */
  /*---- Loading All BaseDb Tail ----*/



  /*---- Loading All Database images ----*/
  /*
  useEffect(() => {
    console.log('Reloading all Database Images...');
    reloadAllImages();
  }, [dispatch, dbs]);

  const reloadAllImages = ()=>{
    let fetchedInThisCycle = {};
    dbs?.forEach(d => {
      if (!fetchedInThisCycle[d._id]) {
        fetchedInThisCycle[d._id] = true;
        fetchImageAndUpdateDatabases(d);
      }
    });
  }

  const fetchImageAndUpdateDatabases = useCallback((db) => {
    console.log('running fetch...');

    return fetchImage(db.img || '')
      .then(url => {
        dispatch(setDatabaseImage(db._id, url));
      })
      .catch(error => {
        console.error('Error fetching the image:', error);
        dispatch(setDatabaseImage(db._id, placeholder));
      });

  }, [dispatch]);
  */
  /*---- Loading All Database images Tail ----*/



  /*---- Delete Selection in List ----*/
  const handleBaseDbDeleteItem = (index) => {
    const deletedBaseItem = baseItems[index];
    setBaseItems(currentItems => currentItems.filter((_, i) => i !== index));
    // Add the baseDb back to availableBaseDbs
    if (deletedBaseItem) {
      setAvailableBaseDbs(prevAvailableBaseDbs => [...prevAvailableBaseDbs, deletedBaseItem]);
      setBaseDbWarningMessage('');
    }
  };

  const handleDeleteItem = (index) => {
	  const deletedItem = items[index];
  	setItems(currentItems => currentItems.filter((_, i) => i !== index));
    // Add the db back to availableDbs
	  if (deletedItem) {
	    setAvailableDbs(prevAvailableDbs => [...prevAvailableDbs, deletedItem]);
      setDbWarningMessage('');
	  }
	};
  /*---- Delete Selection in List Tail ----*/



  /*---- Drag and Drop Utils ----*/

  const onBaseDbDragEnd = (result) => {
    if (!result.destination) { return; }
    const reorderedItems = Array.from(baseItems);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);
    setBaseItems(reorderedItems);
   };

	const onDragEnd = (result) => {
    if (!result.destination) { return; }
    const reorderedItems = Array.from(items);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);
    setItems(reorderedItems);
	};

  /*---- Drag and Drop Utils Tail ----*/




  /* Main Returned Component */

  return (

    <Modal isOpen={isOpen} toggle={() => onRequestClose()} style={{ minWidth: "40rem" }}>
      <ModalHeader toggle={() => onRequestClose()}>排序參考資料庫</ModalHeader>

      {/* Modal Body */}
      <ModalBody style={{ padding: "1.3rem 1.5rem" }}>

      	<Row>

          {/*--- 底層參考資料 ---*/}
      		<Col sm="6" 
            style={{ padding:"0.5rem 1.5rem", borderRight: "1px solid #979ea5" }}
          >

            {/* baseDb Dropdown */}
            <div style={{ display: "flex", flexDirection: "column" }}>

              <h5 className="font-size-12">底層參考資料：</h5>

              <UncontrolledDropdown>
                <DropdownToggle tag="button" className="btn btn-secondary">
                  {thisBaseDb || "選擇底層參考資料"} <i className="mdi mdi-chevron-down" />
                </DropdownToggle>

                <DropdownMenu>
                  {/* 檢視底層參考資料 */}
                  {availableBaseDbs.map((baseDb, index) => (
                    <DropdownItem key={`dropdown-${index}`} onClick={() => handleSelectBaseDbChange(baseDbs.indexOf(baseDb))} >
                      {baseDb.dbName}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>

            </div>
            {/* BaseDb Dropdown Tail */}

            <div style={{ marginTop: "1rem" }}></div>

            {/* BaseDb Drag and Drop List */}
            <DragDropContext onDragEnd={onBaseDbDragEnd}>

              <Droppable droppableId="baseDbdroppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {baseItems.map((item, index) => (
                      <Draggable key={item._id} draggableId={item._id} index={index}>
                        {(provided) => (
                          <div
                            className="draggable-item"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {/* Item display */}
                            <div>
                              <img 
                                src={baseDbImages[item._id] || placeholder} 
                                className="item-logo" 
                                alt="Item Logo" 
                              />
                            </div>
                            <div className="item-name">{item.dbName}</div>

                            {/* Delete button */}
                            <button 
                              type="button" 
                              className="btn-close delete-item-btn" 
                              aria-label="Close"
                              onClick={() => handleBaseDbDeleteItem(index)}
                            >
                            </button>

                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>

            </DragDropContext>
            {/* BaseDb Drag and Drop List Tail */}

            {/* Display warning */}
            {baseDbWarningMessage && <Alert color="danger">{baseDbWarningMessage}</Alert>}

      		</Col>
          {/*--- 底層參考資料 Tail ---*/}



          {/*--- 額外參考資料 ---*/}
      		<Col sm="6" style={{ padding:"0.5rem 1.5rem" }}>

		      	{/* Dropdown */}
		        <div style={{ display: "flex", flexDirection: "column" }}>

		          <h5 className="font-size-12">額外參考資料：</h5>

		          <UncontrolledDropdown>
		            <DropdownToggle tag="button" className="btn btn-info">
		              {thisDb || "選擇額外參考資料"} <i className="mdi mdi-chevron-down" />
		            </DropdownToggle>

		            <DropdownMenu>
		              {/* 檢視參考資料 */}
		              {availableDbs.map((db, index) => (
								    <DropdownItem key={`dropdown-${index}`} onClick={() => handleSelectDbChange(dbs.indexOf(db))} >
								      {db.dbName}
								    </DropdownItem>
								  ))}
		            </DropdownMenu>
		          </UncontrolledDropdown>

		        </div>
		        {/* Dropdown Tail */}

			      <div style={{ marginTop: "1rem" }}></div>

            {/* Drag and Drop List */}
			      <DragDropContext onDragEnd={onDragEnd}>

			        <Droppable droppableId="droppable">
			          {(provided) => (
			            <div {...provided.droppableProps} ref={provided.innerRef}>
			              {items.map((item, index) => {
                      console.log('This item:', item);
                      return (
  			                <Draggable key={item?._id || `item${index}`} draggableId={item?._id} index={index}>
  			                  {(provided) => (
  			                    <div
  			                    	className="draggable-item"
  			                      ref={provided.innerRef}
  			                      {...provided.draggableProps}
  			                      {...provided.dragHandleProps}
  			                    >
  			                      {/* Item display */}
  		                        <div>
  		                          <img 
  		                          	src={dbImages[item?._id] || placeholder} 
  		                          	className="item-logo" 
  		                          	alt="Item Logo" 
  		                          />
  		                        </div>
  		                        <div className="item-name">{item.dbName}</div>

  		                        {/* Delete button */}
  		                        <button 
  		                        	type="button" 
  		                        	className="btn-close delete-item-btn" 
  		                        	aria-label="Close"
  		                        	onClick={() => handleDeleteItem(index)}
  		                        >
  		                        </button>

  			                    </div>
  			                  )}
  			                </Draggable>
			                )
                    })}
			              {provided.placeholder}
			            </div>
			          )}
			        </Droppable>

			      </DragDropContext>
            {/* Drag and Drop List Tail */}

            {/* Display warning */}
            {dbWarningMessage && <Alert color="danger">{dbWarningMessage}</Alert>}

			    </Col>
          {/*--- 額外參考資料 Tail ---*/}

			  </Row>


      </ModalBody>

      {/* Modal Footer */}
      <ModalFooter
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <p style={{ margin: '0px' }}>{`總共參考詞組數：${totalDbLength}`}</p>
          <p style={{ margin: '0px' }}>{`目前方案最高參考詞組數：${(userData?.isPremiumUser ? 12000 : 6000 )} 組`}</p>
        </div>
        <div>
          <Button 
            color="primary" 
            onClick={() => {
            	// Pass the sorted items back
  			      if (onItemsSorted) { onItemsSorted(baseItems.map(b=>b._id), items.map(d=>d._id)); }
  			      // Close the modal
  			      onRequestClose(); 
  			    }}
            style={{ marginRight: '0.5rem' }}
          >
            儲存資料庫排序
          </Button>
          <Button color="secondary" onClick={() => onRequestClose()}>取消</Button>
        </div>
      </ModalFooter>

    </Modal>
  );

};

export default DbListEditModal;

