
/* SET PROJECT */
export const SET_PROJECTS = 'SET_PROJECTS';
export const SET_PROJECT_IMAGE = 'SET_PROJECT_IMAGE';
export const SET_SHARED_PROJECTS = 'SET_SHARED_PROJECTS';

export const LOAD_PROJECT_IMAGES = 'LOAD_PROJECT_IMAGES';
export const LOAD_PROJECT_IMAGES_SUCCESS = 'LOAD_PROJECT_IMAGES_SUCCESS';
export const LOAD_PROJECT_IMAGES_FAIL = 'LOAD_PROJECT_IMAGES_FAIL';

/* GET PROJECT */
export const GET_PROJECTS = "GET_PROJECTS";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_FAIL = "GET_PROJECTS_FAIL";

/* ADD PROJECT */
export const ADD_NEW_PROJECT = "ADD_NEW_PROJECT";
export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS";
export const ADD_PROJECT_FAIL = "ADD_PROJECT_FAIL";

/* DELETE PROJECT */
export const DELETE_PROJECT = "DELETE_PROJECT";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const DELETE_PROJECT_FAIL = "DELETE_PROJECT_FAIL";

/* UPDATE PROJECT */
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_FAIL = "UPDATE_PROJECT_FAIL";
 



