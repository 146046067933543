import React from 'react';
import { Link } from 'react-router-dom';
import * as moment from "moment";
import { Badge } from 'reactstrap';
import { 
  format, 
  parseISO, 
  formatDistanceToNow,
  differenceInYears, 
  differenceInMonths, 
  differenceInDays, 
  differenceInHours, 
  differenceInMinutes, 
  differenceInSeconds
} from 'date-fns';

const calculateDetailedTimeDifference = (isoDateString) => {
  const targetDate = parseISO(isoDateString);
  const now = new Date();

  //const years = differenceInYears(now, targetDate);
  //const months = differenceInMonths(now, targetDate) % 12;
  //const days = differenceInDays(now, targetDate) % 30;
  const days = differenceInDays(now, targetDate);
  const hours = differenceInHours(now, targetDate) % 24;
  const minutes = differenceInMinutes(now, targetDate) % 60;
  //const seconds = differenceInSeconds(now, targetDate) % 60;

  //return `${years} years, ${months} months, ${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds ago`;
  return { differenceDays: days, differenceHours: hours, differenceMinutes: minutes };
};

const formateDate = (date, format) => {
  const dateFormat = format ? format : "DD MMM Y";
  const date1 = moment(new Date(date)).format(dateFormat);
  return date1;
};
const calculateTimeDifference = (isoDateString) => {
  const targetDate = parseISO(isoDateString);
  const difference = formatDistanceToNow(targetDate, { addSuffix: true });
  return difference;
};

const toLowerCase1 = str => {
  return (
    str === "" || str === undefined ? "" : str.toLowerCase()
  );
};

const ConversationId = (cell) => {
  return (
    <Link to="#" className="text-body fw-bold">{cell.value ? cell.value.slice(-6) : ''}</Link>
  );
};

const CbName = (cell) => {
  return cell.value ? cell.value : '';
};

const StartDate = (cell) => {
  const parsedDate = parseISO(cell.value);
  const formattedDate = format(parsedDate, 'yyyy-MM-dd') + '\n' + format(parsedDate, 'HH:mm:ss');
  return formattedDate ? formattedDate : '';
};

const FinishDate = (cell) => {
  const parsedDate = parseISO(cell.value);
  const formattedDate = format(parsedDate, 'yyyy-MM-dd') + '\n' + format(parsedDate, 'HH:mm:ss');
  return formattedDate ? formattedDate : '';
};

const Total = (cell) => {
  return cell.value ? cell.value : '';
};

const ConversationStatus = (cell) => {
  //console.log('StatusDate:', cell.value);
  const { differenceDays, differenceHours, differenceMinutes } = calculateDetailedTimeDifference(cell.value);
  return (
    <Badge
      className={"font-size-12 badge-soft-" +
      (differenceDays>0 ? "warning" : "success")}
    >
      {differenceDays>0 && (`${differenceDays} Days, ${differenceHours} Hours ago`)}
      {differenceDays===0 && (`${differenceHours} Hours, ${differenceMinutes} Mins ago`)}
    </Badge>
  );
};

const PaymentMethod = (cell) => {
  return (
    <span>
      <i
        className={
          (cell.value === "Paypal" ? "fab fa-cc-paypal me-1" : "" ||
            cell.value === "COD" ? "fab fas fa-money-bill-alt me-1" : "" ||
              cell.value === "Mastercard" ? "fab fa-cc-mastercard me-1" : "" ||
                cell.value === "Visa" ? "fab fa-cc-visa me-1" : ""
          )}
      />{" "}
      {cell.value}
    </span>
  );
};

export {
  ConversationId,
  CbName,
  StartDate,
  FinishDate,
  Total,
  ConversationStatus,
  PaymentMethod
};


