import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";

//redux
import { useSelector, useDispatch } from "react-redux";
import withRouter from "components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

// Custom Utils
import { dateGen } from 'utils/toolUtils';
import { isValidImage, fetchImage, uploadImg } from 'utils/imageUtils';

import placeholder from "assets/images/placeholders/placeholder_card.png";

// actions
import { 
  editProfile,
  resetProfileFlag,
  editProfileImage, 
  editProfileEmail, 
  editProfileUsername,
  editProfilePassword,
  clearProfileSuccess,
  clearProfileError,
} from "../../store/actions";

// Function to map uploadStatus to color
const getUploadStatusColor = (status) => {
  switch (status) {
    case 'File uploaded successfully!':
      return 'mediumseagreen';
    case 'Failed to upload file.':
      return 'red';
    case 'Error occurred during upload.':
      return 'red';
    // Add more cases as needed
    default:
      return 'black'; // Default color for other statuses
  }
};


const UserProfile = () => {


  /*---- Title and Initial Setup ----*/

  document.title = "檢視帳號資訊 | TheChart.AI";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /*---- Title and Initial Setup Tail ----*/



  /*---- User data and States ----*/

  const userData = useSelector(state => state.user.userData);
  const { 
    imageSuccess, 
    imageError,
    emailSuccess,
    emailError,
    usernameSuccess,
    usernameError,
    passwordSuccess,
    passwordError
  } = useSelector(state => ({
    imageSuccess: state.Profile.imageSuccess,
    imageError: state.Profile.imageError,
    emailSuccess: state.Profile.emailSuccess,
    emailError: state.Profile.emailError,
    usernameSuccess: state.Profile.usernameSuccess,
    usernameError: state.Profile.usernameError,
    passwordSuccess: state.Profile.passwordSuccess,
    passwordError: state.Profile.passwordError,
  }));

  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [profilePic, setProfilePic] = useState('');

  /*---- User data and States Tail ----*/



  /*---- Display current user data in the form ----*/

  useEffect(() => {

    if(userData.email){setEmail(userData.email)}
    if(userData.username){setUsername(userData.username)}

    async function getProfileImage (){
      const newProfileImage = await fetchImage(userData.profileImg);
      setProfilePic(newProfileImage);
    }
    if(userData.profileImg){ getProfileImage(); };

    // Clear Success and Error in Store
    dispatch(clearProfileSuccess());
    dispatch(clearProfileError());

  }, [dispatch]);

  useEffect(()=>{
    async function getProfileImage (){
      const newProfileImage = await fetchImage(userData.profileImg);
      setProfilePic(newProfileImage);
    }
    if(userData.profileImg){ getProfileImage(); };
    dispatch(clearProfileError());
  }, [userData.profileImg])

  /*---- Display current user data in the form Tail ----*/



  /*---- Upload New Image ----*/

  const [file, setFile] = useState(null);
  const [imgError, setImgError] = useState('');

  const [filename, setFilename] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState('');
  const fileInputRef = useRef(null);

  const handleButtonClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    setUploadStatus('');
    const imgFile = event.target.files[0];
    if (imgFile) {
      console.log("Selected file:", imgFile);
      if (isValidImage(imgFile)) {
        setFile(imgFile);
        setImgError('');
      } else {
        setImgError('檔案格式不正確，請上傳 jpg 或 png 檔');
      }
    }
  };

  // Change in uploaded file
  useEffect(() => {

    async function setImageFile(){
      if (file) {
        setUploadStatus('');
        const date14string = await dateGen();
        const newfilename = date14string+'_'+file.name;

        try {
          setUploadLoading(true);
          const {newImageUrl, uploadStatus} = await uploadImg(newfilename, file);
          setUploadLoading(false);
          setFilename(newfilename);
          setUploadStatus(uploadStatus);
          const previewImage = await fetchImage(newfilename);
          setImageUrl(previewImage);
        } catch (e) {
          console.log('error:', error);
          setUploadLoading(false);
        }

      }
    }

    setImageFile();

  }, [file]);

  /*---- Upload Image Tail ----*/



  /*---- Profile Form Data ----*/

  const getValidationSchema = (submissionType) => {
    switch (submissionType) {
      case 'image':
        return Yup.object({
          img: Yup.string().required("Please choose an image to upload"),
        });
      case 'username':
        return Yup.object({
          username: Yup.string().required("Invalid username").required("Please enter username"),
        });
      case 'email':
        return Yup.object({
          email: Yup.string().email("Invalid email format").required("Please enter your email"),
        });
      case 'password':
        return Yup.object({
          currentPassword: Yup.string().required("請輸入現有密碼"),
          newPassword: Yup.string().required("請輸入新密碼"),
          confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], "密碼輸入不一致")
            .required("請確認新密碼"),
        });
      default:
        return Yup.object({});
    }
  };

  const [submissionType, setSubmissionType] = useState('');

  async function image_sent(image){
    const newProfileImage = await fetchImage(image);
    setProfilePic(newProfileImage);
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      img: filename || '',
      email: email || '',
      username: username || '',
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: getValidationSchema(submissionType),
    onSubmit: (values) => {
      switch (submissionType) {
        case 'image':
          dispatch(editProfileImage(values));
          image_sent(filename);
          //validation.setFieldValue('submissionType', '');
          break;
        case 'email':
          dispatch(editProfileEmail(values));
          //validation.setFieldValue('submissionType', '');
          break;
        case 'username':
          dispatch(editProfileUsername(values));
          //validation.setFieldValue('submissionType', '');
          break;
        case 'password':
          dispatch(editProfilePassword(values));
          //validation.setFieldValue('submissionType', '');
          break;
        default:
          //console.log('Default submit, values.submissionType:', values.submissionType);
          //validation.setFieldValue('submissionType', '');
          setSubmissionType('');
      }
    }
  });

  /*---- Profile Form Data Tail ----*/



  /*---- Form Submit Handlers ----*/

  // Separate submit handlers
  const handleImageSubmit = () => {
    setSubmissionType('image');
    if (!filename) {
      setImgError('請選擇要上傳的圖片');
      return;
    };
    validation.setFieldTouched('image', true, false);
    validation.validateForm().then(errors => {
      if (!errors.image) {
        validation.handleSubmit();
      }
    });
  };

  const handleEmailSubmit = () => {
    setSubmissionType('email');
    setImgError('');
    validation.setFieldTouched('email', true, false);
    validation.validateForm().then(errors => {
      if (!errors.email) {
        validation.handleSubmit();
      }
    });
  };

  const handleUsernameSubmit = () => {
    setSubmissionType('username');
    setImgError('');
    validation.setFieldTouched('username', true, false);
    validation.validateForm().then(errors => {
      if (!errors.username) {
        validation.handleSubmit();
      }
    });
  };

  const handlePasswordSubmit = () => {
    setSubmissionType('password');
    setImgError('');
    // Mark all password fields as touched
    validation.setFieldTouched('currentPassword', true, false);
    validation.setFieldTouched('newPassword', true, false);
    validation.setFieldTouched('confirmPassword', true, false);

    validation.validateForm().then(errors => {
      if (!errors.password) {
        validation.handleSubmit();
      }
    });
  };

  /*---- Form Submit Handlers Tail ----*/


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="帳號管理" breadcrumbItem="帳號資訊" />

          <Card style={{ margin: '3vh 10vw 6vh 10vw' }}>
            <CardBody>
              <div className="d-flex">
                <div className="ms-3">
                  <img
                    src={profilePic || placeholder}
                    alt=""
                    className="avatar-md rounded-circle img-thumbnail"
                    style={{ 
                      height: "7rem",
                      width: "7rem",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div 
                  className="flex-grow-1 align-self-center"
                  style={{ paddingLeft: "2rem" }}
                >
                  <div className="text-muted">
                    <h4>{userData.username}</h4>
                    <p className="mt-1 mb-1" style={{ fontSize:"0.9rem" }}>{userData.email}</p>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>

          <h4 className="card-title mb-4" style={{ marginLeft: '10vw' }}>更新帳號資訊</h4>

          <Card style={{ margin: '3vh 10vw 6vh 10vw' }}>
            <CardBody>

              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >


                {/*---- Email ----*/}

                <div className="form-group">
                  <Label className="form-label">Email 地址</Label>
                  <Input
                    name="username"
                    className="form-control"
                    placeholder={userData.email || ""}
                    type="text"
                    readOnly
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email ? true : false
                    }
                    style={{ color:'#9da0a7' }}
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                  ) : null}
                  {/*<Input name="idx" value={idx} type="hidden" />*/}
                </div>
                
                {/*---- Email Tail ----*/}


                <div style={{ borderTop:'1px solid #b4b4b4', margin:'1.5rem 0rem' }}></div>


                {/*---- Package ----*/}

                <div className="form-group">
                  <Label className="form-label">目前訂閱方案</Label>
                  <Input
                    name="package"
                    className="form-control"
                    placeholder={`{}`}
                    type="text"
                    readOnly
                    style={{ marginBottom: '1rem', color:'#9da0a7' }}
                    value={userData?.isPremiumUser ? '專業版' : '試用版'}
                  />
                  <Label className="form-label">本月剩餘字數</Label>
                  <Input
                    name="package"
                    className="form-control"
                    placeholder={0}
                    type="text"
                    readOnly
                    style={{ marginBottom: '1rem', color:'#9da0a7' }}
                    value={userData?.stats?.wordThisMonth || 0}
                  />
                  <Label className="form-label">TheChart 平台點數</Label>
                  <Input
                    name="package"
                    className="form-control"
                    placeholder={0}
                    type="text"
                    readOnly
                    style={{ marginBottom: '1rem', color:'#9da0a7' }}
                    value={userData?.stats?.pointsLeft || 0}
                  />
                </div>
                
                {/*---- Package Tail ----*/}



                <div style={{ borderTop:'1px solid #b4b4b4', margin:'1.5rem 0rem' }}></div>


                {/*---- Username ----*/}

                <div className="form-group">
                  <Label className="form-label">使用者名稱</Label>
                  <Input
                    name="username"
                    // value={name}
                    className="form-control"
                    placeholder={userData.username || ""}
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.username || ""}
                    invalid={
                      validation.touched.username && validation.errors.username ? true : false
                    }
                  />
                  {validation.touched.username && validation.errors.username ? (
                    <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                  ) : null}
                  {/*<Input name="idx" value={idx} type="hidden" />*/}
                </div>
                <div className="text-center mt-4">
                  <Button type="button" color="danger" onClick={handleUsernameSubmit}>
                    更改使用者名稱
                  </Button>
                </div>

                {usernameError ? <Alert className="mt-4" color="danger">{usernameError}</Alert> : null}
                {usernameSuccess ? <Alert className="mt-4" color="success">{usernameSuccess}</Alert> : null}

                {/*---- Username Tail ----*/}


                <div style={{ borderTop:'1px solid #b4b4b4', margin:'1.5rem 0rem' }}></div>


                {/*---- Profile Pic ----*/}

                <div className="form-group mt-4">

                  <Label className="form-label">帳號頭像</Label>

                  <Row>
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                    />
                    <Col sm="3">
                      <button className="btn btn-outline-info" onClick={handleButtonClick}>
                        {uploadLoading &&
                          (<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>)
                        }
                        選擇圖片
                      </button>
                    </Col>
                    <Col sm="9" style={{display:"flex", alignItems:"center"}}>
                      <span>{filename || '還沒有選擇圖片喔'}</span>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="12">

                      {uploadStatus && (
                        <p style={{ color: getUploadStatusColor(uploadStatus) }}>
                          {uploadStatus}
                        </p>
                      )}

                    </Col>
                  </Row>

                  <Row>
                    <div>
                      {imageUrl && 
                        <img 
                          src={imageUrl} 
                          className="profile-pic" 
                          alt="Fetched"
                          style={{ height:"7rem", width:"7rem" }}
                        />
                      }
                    </div>
                  </Row>

                </div>

                <div className="text-center mt-4">
                  {imgError && <p style={{ color: 'red' }}>{imgError}</p>}
                  <Button type="button" color="danger" onClick={handleImageSubmit}>
                    更改帳號頭像
                  </Button>
                </div>

                {imageError ? <Alert className="mt-4" color="danger">{imageError}</Alert> : null}
                {imageSuccess ? <Alert className="mt-4" color="success">{imageSuccess}</Alert> : null}


                {/*---- Profile Pic Tail ----*/}


                {!userData.isGoogleUser && <hr 
                  style={{ 
                    borderTop:"1px solid #b4b4b4",
                    margin: "1.5rem 0rem",
                  }}
                />}


                {
                  !userData.isGoogleUser && (<>

                    {/*---- Password ----*/}

                    {/* Current Password Field */}
                    <div className="form-group">
                      <Label className="form-label">現有密碼</Label>
                      <Input
                        name="currentPassword"
                        className="form-control"
                        type="password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.currentPassword}
                        invalid={validation.touched.currentPassword && validation.errors.currentPassword ? true : false}
                      />
                      {validation.touched.currentPassword && validation.errors.currentPassword ? (
                        <FormFeedback type="invalid">{validation.errors.currentPassword}</FormFeedback>
                      ) : null}
                    </div>

                    {/* New Password Field */}
                    <div className="form-group">
                      <Label className="form-label">新密碼</Label>
                      <Input
                        name="newPassword"
                        className="form-control"
                        type="password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.newPassword}
                        invalid={validation.touched.newPassword && validation.errors.newPassword ? true : false}
                      />
                      {validation.touched.newPassword && validation.errors.newPassword ? (
                        <FormFeedback type="invalid">{validation.errors.newPassword}</FormFeedback>
                      ) : null}
                    </div>

                    {/* Confirm New Password Field */}
                    <div className="form-group">
                      <Label className="form-label">確認新密碼</Label>
                      <Input
                        name="confirmPassword"
                        className="form-control"
                        type="password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.confirmPassword}
                        invalid={validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false}
                      />
                      {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                        <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                      ) : null}
                    </div>

                    {/* Submit Button */}
                    <div className="text-center mt-4">
                      <Button type="button" color="danger" onClick={handlePasswordSubmit}>
                        更改密碼
                      </Button>
                    </div>

                    {passwordError ? <Alert className="mt-4" color="danger">{passwordError}</Alert> : null}
                    {passwordSuccess ? <Alert className="mt-4" color="success">{passwordSuccess}</Alert> : null}

                    {/*---- Password Tail ----*/}
                  </>)
                }

              </Form>

            </CardBody>
          </Card>


        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
