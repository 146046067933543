import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import withRouter from "components/Common/withRouter";

import TableContainer from "./TableContainer";

// flatpickr
import "flatpickr/dist/themes/material_blue.css";
import FlatPickr from "react-flatpickr";

import "./tableCustom.css";

// Custom Axios
import axiosInstance from 'utils/axiosInstance';
import { format, subHours, parse, isSameDay } from 'date-fns';

// Redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { getCryptoOrders } from "store/crypto/actions";
import Spinners from "components/Common/Spinner";
import { handleSearchData } from "components/Common/searchFile";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

const CryptoOrders = props => {

  //meta title
  document.title = "點數購買紀錄 | TheChart.AI";

  const dispatch = useDispatch();

  const { loading } = props;
  const [activeTab, setActiveTab] = useState("1");
  const [startDate, setStartDate] = useState(new Date('06-01-2024'));
  const [isLoading, setLoading] = useState(loading)
  const [orders, setOrders] = useState(null);
  const [order, setOrder] = useState(null);
  const [packageOrder, setPackageOrder] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('all');
  const [paymentStatus, setPaymentStatus] = useState('all');

  const handleSelectPaymentMethod = (e) => {
    setPaymentMethod(e.target.value);
    handleSelect(e);
  };

  const handleSelectPaymentStatus = (e) => {
    setPaymentStatus(e.target.value);
    handleSelect(e);
  };


  const toggleTab = tab => {
    if (activeTab !== tab) { setActiveTab(tab);}
  };


  const loadPurchaseHistory = async()=>{
    try {
      //setUploadLoading(true);
      const response = await axiosInstance.get(`/knovia/purchase-point-history`);
      console.log("Purchase Points history:", response.data);

      // Format TradeDate and modify PaymentType
      const formattedData = response.data.map(item => {
        let paymentType = item.PaymentType;
        if (paymentType === 'Credit_CreditCard') {
          paymentType = '信用卡';
        } else if (paymentType === 'Cash_Remittance') {
          paymentType = '匯款';
        }

        // Format TradeDate to 'yyyy-MM-dd HH:mm:ss' in UTC
        // Need to Subtract 8 hours from TradeDate
        const adjustedTradeDate = subHours(new Date(item.TradeDate), 8);
        const formattedTradeDate = format(adjustedTradeDate, 'yyyy-MM-dd HH:mm:ss');

        return {
          ...item,
          TradeDate: adjustedTradeDate, // Keep the adjusted date for sorting
          FormattedTradeDate: formattedTradeDate,
          PaymentType: paymentType,
        };
      });
      //setUploadLoading(false);
      // Sort the formattedData by TradeDate with the time closest to now at the front
      const sortedData = formattedData.sort((a, b) => b.TradeDate - a.TradeDate);
      // Replace the adjusted date with the formatted date for final output
      const finalData = sortedData.map(item => ({
        ...item,
        TradeDate: item.FormattedTradeDate,
      }));
      //setUploadLoading(false);
      return finalData;

    } catch (error) {
      console.error("Error:", error);
      //setUploadLoading(false);
      return null;
    }
  }

  const loadPurchasePackageHistory = async()=>{
    try {
      //setUploadLoading(true);
      const response = await axiosInstance.get(`/knovia/purchase-package-history`);
      console.log("Purchase Package history:", response.data);

      // Format TradeDate and modify PaymentType
      const formattedData = response.data.map(item => {
        let paymentType = item.PaymentType;
        if (paymentType === 'Credit_CreditCard') {
          paymentType = '信用卡';
        } else if (paymentType === 'Cash_Remittance') {
          paymentType = '匯款';
        }

        // Format TradeDate to 'yyyy-MM-dd HH:mm:ss' in UTC
        // Need to Subtract 8 hours from TradeDate
        const adjustedTradeDate = subHours(new Date(item.TradeDate), 8);
        const formattedTradeDate = format(adjustedTradeDate, 'yyyy-MM-dd HH:mm:ss');

        return {
          ...item,
          TradeDate: adjustedTradeDate, // Keep the adjusted date for sorting
          FormattedTradeDate: formattedTradeDate,
          PaymentType: paymentType,
        };
      });
      //setUploadLoading(false);
      // Sort the formattedData by TradeDate with the time closest to now at the front
      const sortedData = formattedData.sort((a, b) => b.TradeDate - a.TradeDate);
      // Replace the adjusted date with the formatted date for final output
      const finalData = sortedData.map(item => ({
        ...item,
        TradeDate: item.FormattedTradeDate,
      }));
      //setUploadLoading(false);
      return finalData;
    } catch (error) {
      console.error("Error:", error);
      //setUploadLoading(false);
      return null;
    }
  }

  useEffect(() => {
    if(startDate.length){
      console.log('startDate:', startDate);
      console.log('orders:', orders);

      setPaymentMethod('all');
      setPaymentStatus('all');

      // Parse startDate
      const parsedStartDate = new Date(startDate);

      // Filter orders by the same date
      const filteredOrders = orders?.filter(order => {
        const parsedTradeDate = parse(order.TradeDate, 'yyyy-MM-dd HH:mm:ss', new Date());
        return isSameDay(parsedTradeDate, parsedStartDate);
      });

      console.log('Filtered Orders:', filteredOrders);
      setOrder(filteredOrders);
    }
  }, [startDate, orders]);


  useEffect(()=>{
    setOrder(orders);
  },[orders])

  const fetchOrders = async () => {
    const allPointOrders = await loadPurchaseHistory();
    const allPackageOrders = await loadPurchasePackageHistory();
    setOrders(allPointOrders);
    setPackageOrder(allPackageOrders);
  };

  useEffect(() => {
    fetchOrders();
  }, [dispatch]);
  

  // search 
  const handleSelect = (ele) => {
    let search = ele.target.value;
    if (search === "all") {
      setOrder(orders);
    } else {
      handleSearchData({ setState: setOrder, data: order, item: search })
    }
  };

  const resetFilters = () => {
    setStartDate(null);
    document.querySelector('.flatpickr-input')._flatpickr.clear();
    setOrder(orders);
  }

  // 點數購買紀錄
  const columnPoints = useMemo(
    () => [
      {
        header: "購買時間",
        accessorKey: "TradeDate",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "購買品項",
        accessorKey: "CustomField2",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "加值前點數",
        accessorKey: "pointsBefore",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "加值後點數",
        accessorKey: "pointsAfter",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "付款方式",
        accessorKey: "PaymentType",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          switch (cellProps.row.original.PaymentType) {
            case "信用卡":
              return <Badge className="bg-info font-size-10">信用卡</Badge>
            case "匯款":
              return <Badge className="bg-warning font-size-10">匯款</Badge>
          }
        },
      },
      {
        header: "付款狀態",
        accessorKey: "RtnCode",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          switch (cellProps.row.original.RtnCode) {
            case "1":
              return <Badge className="bg-success font-size-10">付款完成</Badge>
            case "10100060":
              return <Badge className="bg-warning font-size-10">Pending</Badge>
            case "10100058":
              return <Badge className="bg-danger font-size-10">付款失敗</Badge>
          }
        },
      },
    ],
    []
  );

  // 點數購買紀錄
  const columnPackage = useMemo(
    () => [
      {
        header: "購買時間",
        accessorKey: "TradeDate",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "購買品項",
        accessorKey: "CustomField2",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "購買前方案",
        accessorKey: "pointsBefore",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "購買後方案",
        accessorKey: "pointsAfter",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "付款方式",
        accessorKey: "PaymentType",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          switch (cellProps.row.original.PaymentType) {
            case "信用卡":
              return <Badge className="bg-info font-size-10">信用卡</Badge>
            case "匯款":
              return <Badge className="bg-warning font-size-10">匯款</Badge>
          }
        },
      },
      {
        header: "付款狀態",
        accessorKey: "RtnCode",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          switch (cellProps.row.original.RtnCode) {
            case "1":
              return <Badge className="bg-success font-size-10">付款完成</Badge>
            case "10100060":
              return <Badge className="bg-warning font-size-10">Pending</Badge>
            case "10100058":
              return <Badge className="bg-danger font-size-10">付款失敗</Badge>
          }
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="點數" breadcrumbItem="購買紀錄" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {/*<h4 className="card-title mb-3">檢視購買紀錄</h4>*/}
                  {
                    isLoading ? <Spinners setLoading={setLoading} />
                      :
                      <>
                        <Row>
                          <Col xs="10">
                            <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active: activeTab === "1",
                                  })}
                                  onClick={() => {
                                    toggleTab("1");
                                  }}
                                >
                                  點數購買紀錄
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active: activeTab === "2",
                                  })}
                                  onClick={() => {
                                    toggleTab("2");
                                  }}
                                >
                                  方案購買紀錄
                                </NavLink>
                              </NavItem>
                            </ul>
                          </Col>

                          <Col xs="2"
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              margin: '0vh 0vw 3vh 0vw',
                              paddingRight: '4vw'
                            }}
                          > 
                            <div style={{ paddingBottom: '2px' }}>
                              <Button onClick={fetchOrders} color="primary">
                                <FontAwesomeIcon icon={faSyncAlt} /> {/* Refresh icon */}
                              </Button>
                            </div>
                          </Col>
                        </Row>

                        <TabContent activeTab={activeTab} className="p-3">

                          {/* 點數購買紀錄 */}
                          <TabPane tabId="1" id="all-order">
                            <Form>
                              <Row className="mb-4">
                                <Col sm={6} className="col-xl">
                                  <FormGroup className="mb-3">
                                    <Label className="form-label">交易日期</Label>
                                    <FlatPickr
                                      className="form-control"
                                      selected={startDate}
                                      onChange={(date) => setStartDate(date)}
                                      name="joiningDate"
                                      placeholder="選擇日期"
                                      options={{
                                        dateFormat: "d M, Y"
                                      }}
                                    />
                                  </FormGroup>
                                </Col>

                                {/*
                                <Col sm={6} className="col-xl">
                                  <FormGroup className="mb-3">
                                    <Label className="form-label">Coin</Label>
                                    <select className="form-control select2-search-disable" onChange={(e) => handleSelect(e)}>
                                      <option defaultValue="all">All </option>
                                      <option value="Bitcoin">Bitcoin</option>
                                      <option value="Ethereum">Ethereum</option>
                                      <option value="Litecoin">Litecoin</option>
                                    </select>
                                  </FormGroup>
                                </Col>
                                */}

                                <Col sm={6} className="col-xl">
                                  <FormGroup className="mb-3">
                                    <Label className="form-label">付款方式</Label>
                                    <select
                                      className="form-control select2-search-disable"
                                      value={paymentMethod}
                                      onChange={(e)=>{handleSelectPaymentMethod(e)}}
                                    >
                                      <option value="all">All</option>
                                      <option value="信用卡">信用卡</option>
                                      <option value="匯款">匯款</option>
                                    </select>
                                  </FormGroup>
                                </Col>

                                <Col sm={6} className="col-xl">
                                  <FormGroup className="mb-3">
                                    <Label className="form-label">付款狀態</Label>
                                    <select
                                      className="form-control select2-search-disable"
                                      value={paymentStatus}
                                      onChange={(e)=>{handleSelectPaymentStatus(e)}}
                                    >
                                      <option value="all">All</option>
                                      <option value="1">付款完成</option>
                                      <option value="10100058">付款失敗</option>
                                    </select>
                                  </FormGroup>
                                </Col>

                                <Col sm={6} className="col-xl align-self-end"
                                  style={{ display:"flex" }}
                                >
                                  <div className="mb-3" style={{ marginRight:'0.8vw' }}>
                                    <Button
                                      type="button"
                                      color="primary"
                                      className="w-md"
                                    >
                                      篩選
                                    </Button>
                                  </div>
                                  <div className="mb-3">
                                    <Button
                                      type="button"
                                      color="warning"
                                      className="w-md"
                                      onClick={()=>{resetFilters();}}
                                    >
                                      重設條件
                                    </Button>
                                  </div>
                                </Col>

                              </Row>
                            </Form>

                            <TableContainer
                              columns={columnPoints}
                              data={order || []}
                              isCustomPageSize={true}
                              isPagination={true}
                              isGlobalFilter={true}
                              SearchPlaceholder={`${order?.length || 0} 項購買紀錄..`}
                              paginationWrapper="dataTables_paginate paging_simple_numbers"
                              pagination="pagination"
                              tableClass="table-hover table-nowrap datatable dt-responsive nowrap dataTable no-footer dtr-inline"
                            />

                          </TabPane>

                        {/* 方案購買紀錄 */}
                          <TabPane tabId="2" id="processing">
                            <div>
                              <TableContainer
                                columns={columnPackage}
                                data={packageOrder || []}
                                isCustomPageSize={true}
                                isPagination={true}
                                isGlobalFilter={true}
                                pagination="pagination"
                                SearchPlaceholder={`${packageOrder?.length || 0} 項購買紀錄..`}
                                paginationWrapper="dataTables_paginate paging_simple_numbers"
                                tableClass="table-hover table-nowrap datatable dt-responsive nowrap dataTable no-footer dtr-inline"
                              />
                            </div>
                          </TabPane>
                        </TabContent>
                      </>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

CryptoOrders.propTypes = {};

const mapStateToProps = ({ crypto }) => ({
  loading: crypto.loading
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CryptoOrders));






