import {
  SET_DATABASES,
  SET_DATABASE_IMAGE,

  /*---- Load Database Image ----*/
  LOAD_DATABASE_IMAGES,
  LOAD_DATABASE_IMAGES_SUCCESS,
  LOAD_DATABASE_IMAGES_FAIL,
  
  /*---- Load BaseDb Image ----*/
  LOAD_BASEDB_IMAGES,
  LOAD_BASEDB_IMAGES_SUCCESS,
  LOAD_BASEDB_IMAGES_FAIL,

  GET_DATABASES,
  GET_DATABASES_FAIL,
  GET_DATABASES_SUCCESS,

  GET_BASE_DATABASES,
  GET_BASE_DATABASES_FAIL,
  GET_BASE_DATABASES_SUCCESS,
  SET_BASE_DATABASE_IMAGE,

  ADD_NEW_DATABASE,
  ADD_DATABASE_SUCCESS,
  ADD_DATABASE_FAIL,

  UPDATE_DATABASE,
  UPDATE_DATABASE_SUCCESS,
  UPDATE_DATABASE_FAIL,

  DELETE_DATABASE,
  DELETE_DATABASE_SUCCESS,
  DELETE_DATABASE_FAIL,
} from "./actionTypes";


/*---- Set Database ----*/

export const setDatabases = (databases) => ({
  type: SET_DATABASES,
  payload: databases,
});

export const setDatabaseImage = (dbId, imgUrl) => ({
  type: SET_DATABASE_IMAGE,
  payload: { dbId, imgUrl }
});

/*---- Set Database Tail ----*/



/*---- Load Database Image ----*/

export const loadDatabaseImages = (databases) => ({
  type: LOAD_DATABASE_IMAGES,
  payload: databases,
});

export const loadDatabaseImagesSuccess = (images) => ({
  type: LOAD_DATABASE_IMAGES_SUCCESS,
  payload: images,
});

export const loadDatabaseImagesFail = (error) => ({
  type: LOAD_DATABASE_IMAGES_FAIL,
  payload: error,
});

export const loadBaseDbImages = (baseDbs) => ({
  type: LOAD_BASEDB_IMAGES,
  payload: baseDbs,
});

export const loadBaseDbImagesSuccess = (images) => ({
  type: LOAD_BASEDB_IMAGES_SUCCESS,
  payload: images,
});

export const loadBaseDbImagesFail = (error) => ({
  type: LOAD_DBASEDB_IMAGES_FAIL,
  payload: error,
});

/*---- Load Database Image Tail ----*/



/*---- Get Database ----*/

export const getDatabases = () => ({
  type: GET_DATABASES,
});

export const getDatabasesSuccess = databases => ({
  type: GET_DATABASES_SUCCESS,
  payload: databases,
});

export const getDatabasesFail = error => ({
  type: GET_DATABASES_FAIL,
  payload: error,
});

/*---- Get Database Tail ----*/



/*---- Get Base Database ----*/

export const getBaseDatabase = baseDatabases => ({
  type: GET_BASE_DATABASES,
})

export const getBaseDatabasesSuccess = baseDatabases => ({
  type: GET_BASE_DATABASES_SUCCESS,
  payload: baseDatabases,
})

export const getBaseDatabasesFail = error => ({
  type: GET_BASE_DATABASES_FAIL,
  payload: error,
})

export const setBaseDatabaseImage = (baseDbId, imgUrl) => ({
  type: SET_BASE_DATABASE_IMAGE,
  payload: { baseDbId, imgUrl }
});

/*---- Get Base Database Tail ----*/



/*---- Add Database ----*/

export const addNewDatabase = database => ({
  type: ADD_NEW_DATABASE,
  payload: database,
})

export const addDatabaseSuccess = newDatabase => ({
  type: ADD_DATABASE_SUCCESS,
  payload: newDatabase,
})

export const addDatabaseFail = error => ({
  type: ADD_DATABASE_FAIL,
  payload: error,
})

/*---- Add Database Tail ----*/



/*---- Delete Project ----*/

export const deleteDatabase = dbId => ({
  type: DELETE_DATABASE,
  payload: dbId,
})

export const deleteDatabaseSuccess = databaseId => ({
  type: DELETE_DATABASE_SUCCESS,
  payload: databaseId,
})

export const deleteDatabaseFail = error => ({
  type: DELETE_DATABASE_FAIL,
  payload: error,
})

/*---- Delete Project Tail ----*/



/*---- Update Project ----*/

export const updateDatabase = database => ({
  type: UPDATE_DATABASE,
  payload: database,
})

export const updateDatabaseSuccess = database => ({
  type: UPDATE_DATABASE_SUCCESS,
  payload: database,
})

export const updateDatabaseFail = error => ({
  type: UPDATE_DATABASE_FAIL,
  payload: error,
})

/*---- Update Project Tail ----*/


