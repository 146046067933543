import React, { useState } from "react";
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { Link } from "react-router-dom";
import { size, map, get } from "lodash";

// Redux
import { useDispatch } from "react-redux";
import { deleteChatbot as onDeleteChatbot } from "store/actions";

// Custom CSS
import './custom.css';

// FontAwesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as farStar } from "@fortawesome/free-regular-svg-icons";
import { faStar as fasStar } from "@fortawesome/free-solid-svg-icons";

// Custom Modal
import InfoModal from "./InfoModal";
import EditModal from "./EditModal";
import DeleteModal from "components/Common/DeleteModal";


const CardContact = ({ cb, cbImage, favoriteChatbots, onToggleFavorite }) => {


  /*---- States and Initial Settings ----*/

  // console.log('cb:', cb);
  // console.log('cbImage', cbImage);
  //console.log('favoriteChatbots', favoriteChatbots);


  /*---- Title and Initial Setup ----*/

  const dispatch = useDispatch();
  // Determine if the baseDb is a favorite
  const cardIsFavorite = (favoriteChatbots ? favoriteChatbots.includes(cb?._id) : 0);

  /*---- Title and Initial Setup Tail ----*/



  /*---- State for Edit and Info Modals ----*/

  const [editModal, setEditModal] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const toggleEditModal = () => { setEditModal(!editModal) };
  const toggleInfoModal = () => { setInfoModal(!infoModal) };

  /*---- State for Edit and Info Modals Tail ----*/



  /*---- Modal Deleting a Project ----*/

  // State for Delete Modal Open or Close
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (cb) => { setDeleteModal(true) };

  // Deleting a project and close the delete modal
  const handleDeleteOrder = () => {
    if (cb && cb._id) { dispatch(onDeleteChatbot(cb._id)) }
    setDeleteModal(false);
  };

  /*---- Modal Deleting a Project Tail ----*/

  

  /*---- Main CardContact ----*/
  return (
    <React.Fragment>


      {/*---- Edit, Delete, and Info Modals ----*/}
      <EditModal cb={cb} cbImage={cbImage} editModal={editModal} toggle={toggleEditModal} onUpdateChatbot/>
      <InfoModal cb={cb} cbImage={cbImage} infoModal={infoModal} toggle={toggleInfoModal}/>
      <DeleteModal 
        show={deleteModal} 
        onDeleteClick={handleDeleteOrder} 
        onCloseClick={() => setDeleteModal(false)}
        title={`智能客服 [${cb?.cbName}]`}
      />
      {/*---- Edit, Delete, and Info Modals ----*/}


      {/*---- The cards ----*/}
      <Col xl="3" sm="6">
        <Link to={`/chatbot-user/${cb._id}`} className="text-dark">
          <Card className="text-center">
            <CardBody>

              {/* Favorite Star */}
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  cursor: "pointer",
                  fontSize: "20px",
                }} 
              >
                <FontAwesomeIcon 
                  icon={cardIsFavorite ? fasStar : farStar} 
                  color={cardIsFavorite ? "gold" : "grey"} 
                  // onClick={() => onToggleFavorite(cb._id)}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onToggleFavorite(cb._id);
                  }}
                />
              </div>


              {/*---- Chatbot Img ----*/}

              <div className="mb-4" style={{ display: "flex", justifyContent: "center" }}>
                {!cbImage ? (
                  <span 
                    className="avatar-bigger avatar-title rounded-circle bg-soft bg-primary text-primary font-size-16"
                  >{get(cb, 'cbName', '').charAt(0)}
                  </span>
                ) : (
                  <img
                    className="rounded-circle avatar-bigger"
                    src={cbImage}
                    alt={cb?.cbName || "User Image"}
                    style={{ objectFit: "cover" }}
                  />
                )}
              </div>
            
              {/*---- Chatbot Tail ----*/}



              {/* Chatbot Name and Client */}
              <h5 className="font-size-15 mb-1">
                {cb.cbName}
              </h5>
              <p className="text-muted">{cb?.cbClient}</p>
              {/* Chatbot Name and Client Tail */}



              {/* Attribute Tags */}
              <div>
                {map(cb.tags, (tag, index) =>
                    index < 2 && (
                      <Link to="#" className="badge bg-primary font-size-11 m-1" key={"_tag_" + cb._id + index}>
                        {tag}
                      </Link>
                    )
                )}
                {/*--- show the remaining tag number ---*/}
                {size(cb.tags) > 2 && (
                  <Link to="#" className="badge bg-primary font-size-11 m-1" key={"_tag_" + cb._id}>
                    {size(cb.tags) - 2} + more
                  </Link>
                )}
              </div>
              {/* Attribute Tags Tail */}


            </CardBody>


            {/* Bottom Icons */}
            <CardFooter className="bg-transparent border-top">

              <div className="contact-links d-flex font-size-20">


                {/*---- Edit Icon ----*/}
                <div className="flex-fill" onClick={() => setEditModal(!editModal)}>
                  <Link to="#" id={"edit" + cb._id}>
                    <i className="mdi mdi-pencil font-size-20 text-success me-1"></i>
                    <UncontrolledTooltip placement="top" target={"edit" + cb._id}>
                      編輯
                    </UncontrolledTooltip>
                  </Link>
                </div>
                {/*---- Edit Icon Tail ----*/}


                {/*---- Delete Icon ----*/}
                <div className="flex-fill" onClick={() => onClickDelete(cb)}>
                  <Link to="#" id={"delete" + cb._id}>
                    <i className="mdi mdi-trash-can font-size-20 text-danger me-1"></i>
                    <UncontrolledTooltip placement="top" target={"delete" + cb._id}>
                      刪除
                    </UncontrolledTooltip>
                  </Link>
                </div>
                {/*---- Delete Icon Tail ----*/}

                {/*---- Info Icon ----*/}
                <div className="flex-fill" onClick={() => setInfoModal(!infoModal)}>
                  <Link to="#" id={"info" + cb?._id}>
                    <i className="mdi mdi-help-circle-outline font-size-20 text-secondary me-1"></i>
                    <UncontrolledTooltip
                      placement="top"
                      target={"info" + cb?._id}
                    >
                      資訊
                    </UncontrolledTooltip>
                  </Link>
                </div>
                {/*---- Info Icon Tail ----*/}


              </div>

            </CardFooter>

          </Card>
        </Link>
      </Col>
    </React.Fragment>
  );
};

CardContact.propTypes = {
  cb: PropTypes.object
};

export default CardContact;
