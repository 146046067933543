import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap"
// Redux
import { useSelector } from "react-redux";

// Custom Axios
import axiosInstance from 'utils/axiosInstance';

// Link down to component <LatestTransaction />
import LatestTransaction from "./LatestTransaction";


// Middle Layer for Single Glossary Display and Edit
// index.js -> ActivityComp.js -> LatestTransaction.js
const ActivityComp = (props) => {


  /*---- States ----*/

  const { glossaryId } = props;

  const [entryList, setEntryList] = useState([]);
  const [glossaryData, setGlossaryData] = useState({});
  const [glossaryLoading, setGlossaryLoading] = useState(false);

  const { databases } = useSelector(state => ({
    databases: state.databases.databases,
  }));

  /*---- States Tail ----*/



  /*---- Refresh Database Entries ----*/

  const getEntryList = (showRefreshIcon=false) => {
    console.log("Refreshing glossary data...");

    if(showRefreshIcon){ setGlossaryLoading(true) }

    axiosInstance.get(`/knovia/langcomp/${glossaryId}`)
      .then((response) => {
        setEntryList(response.data);
        console.log('Glossary Entry List', response.data);
        if(showRefreshIcon){ setGlossaryLoading(false) }
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    console.log('[Dashboard-dbmultiple/ActivityComp.js] All databases', databases);
    const thisDatabase = databases.filter(d=>d._id===glossaryId)[0];
    console.log('[Dashboard-dbmultiple/ActivityComp.js] thisDatabase', thisDatabase);
    setGlossaryData(thisDatabase);
    getEntryList(true);
  }, [databases]);

  /*---- Refresh Database Entries Tail ----*/


  return (
    <React.Fragment>

      <Row>
        <Col lg="12">
          <LatestTransaction
            glossaryLoading={glossaryLoading}
            glossaryId={glossaryId} 
            glossaryData={glossaryData} 
            glossaryEntries={entryList}
            refreshData={getEntryList} 
          />
        </Col>
      </Row>

    </React.Fragment>
  );
};

export default ActivityComp;

