export const SET_UNAUTHORIZED_ERROR = 'SET_UNAUTHORIZED_ERROR';
export const CLEAR_LOGIN_STATES = "CLEAR_LOGIN_STATES";

export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_FAILURE = "LOGOUT_USER_FAILURE";


/*export const SOCIAL_LOGIN = "SOCIAL_LOGIN";*/
