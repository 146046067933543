import {
  SET_PROJECTS,
  SET_PROJECT_IMAGE,
  SET_SHARED_PROJECTS,

  /*---- Load Project Image ----*/
  LOAD_PROJECT_IMAGES,
  LOAD_PROJECT_IMAGES_SUCCESS,
  LOAD_PROJECT_IMAGES_FAIL,

  GET_PROJECTS,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAIL,
  
  ADD_NEW_PROJECT,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_FAIL,

  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAIL,

  DELETE_PROJECT,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
} from "./actionTypes";


/*---- Set Project ----*/

// Accessed in store/user
export const setProjects = (projects) => ({
  type: SET_PROJECTS,
  payload: projects,
});

export const setProjectImage = (projectId, imgUrl) => ({
  type: SET_PROJECT_IMAGE,
  payload: { projectId, imgUrl }
});

export const setSharedProjects = (sharedProjects) => ({
  type: SET_SHARED_PROJECTS,
  payload: sharedProjects,
});

/*---- Set Project Tail ----*/



/*---- Load Database Image ----*/

export const loadProjectImages = (projects) => ({
  type: LOAD_PROJECT_IMAGES,
  payload: projects,
});

export const loadProjectImagesSuccess = (images) => ({
  type: LOAD_PROJECT_IMAGES_SUCCESS,
  payload: images,
});

export const loadProjectImagesFail = (error) => ({
  type: LOAD_PROJECT_IMAGES_FAIL,
  payload: error,
});

/*---- Load Database Image Tail ----*/



/*---- Get Project ----*/

export const getProjects = () => ({
  type: GET_PROJECTS,
});

export const getProjectsSuccess = projects => ({
  type: GET_PROJECTS_SUCCESS,
  payload: projects,
});

export const getProjectsFail = error => ({
  type: GET_PROJECTS_FAIL,
  payload: error,
});

/*---- Get Project Tail ----*/



/*---- Add Project ----*/

export const addNewProject = project => ({
  type: ADD_NEW_PROJECT,
  payload: project,
});

export const addProjectSuccess = newProject => ({
  type: ADD_PROJECT_SUCCESS,
  payload: newProject,
});

export const addProjectFail = error => ({
  type: ADD_PROJECT_FAIL,
  payload: error,
});

/*---- Add Project Tail ----*/



/*---- Delete Project ----*/

export const deleteProject = project => ({
  type: DELETE_PROJECT,
  payload: project,
});

export const deleteProjectSuccess = projectId => ({
  type: DELETE_PROJECT_SUCCESS,
  payload: projectId,
});

export const deleteProjectFail = error => ({
  type: DELETE_PROJECT_FAIL,
  payload: error,
});

/*---- Delete Project Tail ----*/



/*---- Update Project ----*/

export const updateProject = project => ({
  type: UPDATE_PROJECT,
  payload: project,
});

export const updateProjectSuccess = project => ({
  type: UPDATE_PROJECT_SUCCESS,
  payload: project,
});

export const updateProjectFail = error => ({
  type: UPDATE_PROJECT_FAIL,
  payload: error,
});

/*---- Update Project Tail ----*/


