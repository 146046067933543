import React, { useEffect, useState, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import {
  Alert,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter, 
  Spinner,
  Input,
} from "reactstrap";

import {
  LangOne,
  TextOne,
  LangTwo,
  TextTwo,
} from "./LatestTransactionCol";

import MemoizedTableContainer from "../../components/Common/TableContainer";

import axiosInstance from 'utils/axiosInstance';
import { dateGen } from 'utils/toolUtils';

// Textarea Keys In Editing Modal
const orderedKeys = ["langOne", "textOne", "langTwo", "textTwo"];

// Mapping keys to titles for textareas in Editing Modal
const keyToLabelMapping = {
  langOne: "語言1",
  langTwo: "語言2",
  textOne: "文本1",
  textTwo: "文本2",
};

// Editing Modal Select Options
const selectOptionsMapping = {
  langOne: [ { value: "語言1", label: "語言1" } ],
  langTwo: [ { value: "語言2", label: "語言2" } ]
};

// Main Component
const LatestTransaction = ({ glossaryLoading, glossaryEntries, glossaryId, refreshData, glossaryData = {} }) => {

  const [optimisticUpdates, setOptimisticUpdates] = useState([]);

  /*---- States and Refs ----*/

  // glossaryEntries: Existing Glossary Entries
  const { 
    dbName = '', 
    dbClient = '', 
    langOne = '',
    langTwo = '',
    tags = [],
  } = glossaryData;
  // Select Checkbox
  const [isCheck, setIsCheck] = useState([]);

  // filteredData: Existing Table Entries Mappings
  const filteredData = useMemo(() => {
    const baseData = glossaryEntries
      .map((dataEntry) => ({
        id: dataEntry._id,
        order: dataEntry.order,
        langOne: dataEntry.langOne,
        langTwo: dataEntry.langTwo,
        textOne: dataEntry.textOne,
        textTwo: dataEntry.textTwo,
      }))
      .sort((a, b) => a.order - b.order)
      .reverse();

    // Apply optimistic updates
    const updatedData = [...baseData];
    optimisticUpdates.forEach(update => {
      const index = updatedData.findIndex(item => item.id === update.id);
      if (index > -1) {
        if (update.action === 'delete') {
          updatedData.splice(index, 1);
        } else {
          updatedData[index] = { ...updatedData[index], ...update.data };
        }
      } else if (update.action === 'add') {
        updatedData.push(update.data);
      }
    });

    return updatedData;
  }, [glossaryEntries, optimisticUpdates]);

  //console.log('filteredData:', filteredData);


  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  // Editing Modal Input Values, change when editing
  const [modalData, setModalData] = useState({
    langOne: "",
    langTwo: "",
    textOne: "",
    textTwo: "",
  });

  // Editing Modal Data Ref, send new data to server when button clicked
  const editingDataRef = useRef({ id: null });

  /*---- States and Refs Tail ----*/




  /*---- Manual Input Select Props: langOne and langTwo ----*/

  function useSelectValue(initialValue = "") {
    const [value, setValue] = useState(initialValue);
    const selectRef = useRef(null);

    // Effect to set the initial value based on the first option
    useEffect(() => {
      if (selectRef.current && selectRef.current.options.length > 0) {
        setValue(selectRef.current.options[0].value);
      }
    }, []);

    return {
      value,
      onChange: (e) => { setValue(e.target.value) },
      selectRef,
    };
  }

  /* langOne */
  const langOneProps = useSelectValue("");
  const { selectRef: langOneSelectRef, ...langOneInputProps } = langOneProps;

  /* langTwo */
  const langTwoProps = useSelectValue("");
  const { selectRef: langTwoSelectRef, ...langTwoInputProps } = langTwoProps;  

  /*---- Manual Input Select Props: langOne and langTwo ----*/





  /*---- Manual Input TextArea Props: textOne, textTwo ----*/

  function useAutoResize(initialValue = "") {
    const [value, setValue] = useState(initialValue);
    const textareaRef = useRef(null);

    const adjustTextareaHeight = () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto"; 
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; 
      }
    };

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
      adjustTextareaHeight();
    }, [value]);

    return {
      value,
      onChange: (e) => {
        setValue(e.target.value);
        adjustTextareaHeight();
      },
      textareaRef,
    };
  }

  /* textOne */
  const textOneProps = useAutoResize("");
  const { textareaRef: textOneRef, ...textOneInputProps } = textOneProps;

  /* textTwo */
  const textTwoProps = useAutoResize("");
  const { textareaRef: textTwoRef, ...textTwoInputProps } = textTwoProps;

  /*---- Manual Input TextArea Props: textOne, textTwo Tail ----*/




  /*---- Auto-adjust height for textareas in Modal ----*/

  const textareaRefs = useRef({});

  const adjustHeight = (key) => {
    if (textareaRefs.current[key]) {
      const textarea = textareaRefs.current[key];
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
      if(textarea.scrollHeight<54){
        textarea.style.height = `30px`;
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      Object.keys(modalData).forEach(key => { adjustHeight(key) });
    }, 0); 
  }, [modalData, isEditModalOpen]);

  /*---- Auto-adjust height for textareas in Modal Tail ----*/





  /*---- Manual Input TextArea: Submit ----*/

  const [responseStatus, setResponseStatus] = useState("inactive"); // retrieving / inactive
  const [userWarning, setUserWarning] = useState("");
  const [editWarning, setEditWarning] = useState("");

  // 添加資料 Button
  async function onSubmitData(e) {
    e.preventDefault();

    const payload = {
      glossaryId: glossaryId,
      langOne: langOneProps.value,
      langTwo: langTwoProps.value,
      textOne: textOneProps.value,
      textTwo: textTwoProps.value,
      createDate: await dateGen(),
    };

    console.log("Sending to server:", payload);

    // Returns true if all values in the object are non-empty, otherwise false
    const allFieldsFilled = Object.values(payload).every(value => value && value.trim() !== "");

    if (allFieldsFilled) {

      // 檢查來源與目標語言是否相同
      if (langOneProps.value === langTwoProps.value){
        setUserWarning("語言1與語言2不能相同")
      } else {
        setUserWarning("");
        setResponseStatus('retrieving');

        // Optimistically add the new row to filteredData
        const newEntry = {
          ...payload,
          id: 'temp-id-' + Math.random().toString(36).substr(2, 9), // Temporary ID
          order: Math.min(...filteredData.map(data => data.order), 0) - 1, // Smallest order
        };
        const newUpdate = { id: newEntry.id, data: newEntry, action: 'add' };
        setOptimisticUpdates(prev => [...prev, newUpdate]);

        try {
          const response = await axiosInstance.post(`/knovia/langcomp`, payload);
          refreshData();
          setResponseStatus('inactive');
          // Replace the temporary ID with the real ID after successful server response
          const realId = response.data.langcompId;
          setOptimisticUpdates(prev => prev.filter(update => update.id !== newEntry.id));
          setOptimisticUpdates(prev => [...prev, { id: realId, data: { ...newEntry, id: realId }, action: 'add' }]);
        } catch (error) {
          console.error(error);
          // Revert the update if the request fails
          setOptimisticUpdates(prev => prev.filter(update => update.id !== newEntry.id));
        }
      }

    } else {
      setUserWarning("請填寫所有欄位再送出!");
    }

  }
  /*---- Manual Input TextArea: Submit Tail ----*/




  /*---- Update and Delete Existing Rows from Glossary through Modal ----*/


  const handleClick = (id) => {
    // console.log('isCheck', isCheck);
    // console.log('includes', isCheck.includes(id));
    setIsCheck([...isCheck, id]);
    if (isCheck.includes(id)) {
      setIsCheck(isCheck.filter(item => item !== id));
    }
  };
  const handleSelectAll = e => {
    const { checked } = e.target;
    const ids = filteredData.map(row => row.id);
    if (checked) {
      setIsCheck(ids);
    } else {
      setIsCheck([]);
    }
  };

  // Updating Existing Rows
  const updateDataEntry = async (id, updatedRowData) => {

    console.log('id:',id);
    console.log('updatedRowData', updatedRowData);

    // Optimistically update the filteredData
    const newUpdate = { id, data: updatedRowData, action: 'update' };
    setOptimisticUpdates(prev => [...prev, newUpdate]);

    try {
      const response = await axiosInstance.put(`/knovia/langcomp/${id}`, updatedRowData);
      console.log(response.data.message);
      console.log('filteredData:', filteredData);
      refreshData();
    } catch (error) {
      console.error(error);
      // Revert the update if the request fails
      setOptimisticUpdates(prev => prev.filter(update => update.id !== id));
    }

  }

  // Deleting Existing Rows
  const deleteDataEntry = async (id) => {
    console.log("Deleting ID:", id);

    // Optimistically update the filteredData
    const newUpdate = { id, action: 'delete' };
    setOptimisticUpdates(prev => [...prev, newUpdate]);

    try {
      const response = await axiosInstance.delete(`/knovia/langcomp/${id}`);
      console.log(response.data.message);
      refreshData();
    } catch (error) {
      console.error(error);
      // Revert the update if the request fails
      setOptimisticUpdates(prev => prev.filter(update => update.id !== id));
    }
  };

  /*---- Update and Delete Existing Rows from Glossary through Modal Tail ----*/



  /*---- Column Table Items ----*/

  const columns = useMemo(
    () => [
      {
        Header: "#",
        filterable: false,
        disableFilters: true,
        width: 50,
        Cell: cellProps => {
          return <Input
                  type="checkbox"
                  className="form-check-input"
                  checked={isCheck.includes(cellProps.row.original.id)}
                  onClick={(e) => handleClick(cellProps.row.original.id)}
                  onChange={e => {}}
                />; 
        },
      },
      {
        Header: "語言1",
        accessor: "langOne",
        disableFilters: true,
        width: 100,
        filterable: false,
        Cell: cellProps => {
          return <LangOne {...cellProps} />;
        },
      },
      {
        Header: "文本1",
        accessor: "textOne",
        disableFilters: true,
        width: 200,
        Cell: cellProps => {
          return <TextOne {...cellProps} />;
        },
      },
      {
        Header: "語言2",
        accessor: "langTwo",
        disableFilters: true,
        width: 100,
        Cell: cellProps => {
          return <LangTwo {...cellProps} />;
        },
      },
      {
        Header: "文本2",
        accessor: "textTwo",
        disableFilters: true,
        width: 200,
        Cell: cellProps => {
          return <TextTwo {...cellProps} />;
        },
      },
      {
        Header: "刪除",
        disableFilters: true,
        width: 20,
        Cell: cellProps => {
          return (
            <button 
              className="btn btn-outline-danger"
              onClick={() => {
                const yes = confirm('你確定要刪除嗎？');
                if (yes) {
                  deleteDataEntry(cellProps.row.original.id);
                }
              }}
              style={{
                height: '2rem',
                width: '2rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 0 // Set to 0 to override any default padding
              }}
            >
              -
            </button>
          );
        },
      },
      {
        Header: "編輯",
        disableFilters: true,
        width: 20,
        Cell: cellProps => {
          return (
            <button 
              className="btn btn-outline-info"
              onClick={() => {

                editingDataRef.current.id = cellProps.row.original.id;

                setModalData({
                  langOne: cellProps.row.original.langOne,
                  langTwo: cellProps.row.original.langTwo,
                  textOne: cellProps.row.original.textOne,
                  textTwo: cellProps.row.original.textTwo,
                });

                // Open the Modal
                setIsEditModalOpen(true);
                // Adjust heights immediately
                Object.keys(modalData).forEach(key => {
                  adjustHeight(key);
                });

              }}
              style={{
                height: '2rem',
                width: '2rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 0  // Set to 0 to override any default padding
              }}
            >
              <i className="fas fa-pen"></i>
            </button>
          );
        },
      },
    ],
    []
  );
  /*---- Column Table Items Tail ----*/




  return (
    <React.Fragment>



      {/* Modal for editing dataentries */}
      <Modal isOpen={isEditModalOpen} toggle={() => setIsEditModalOpen(!isEditModalOpen)}>

        {/* Modal Header */}
        <ModalHeader toggle={() => setIsEditModalOpen(!isEditModalOpen)}>編輯資料</ModalHeader>
        {/* Modal Body */}
        <ModalBody>

            {orderedKeys.map((key) => (
              <div key={key} style={{ marginBottom: "15px" }}>
                <p>{keyToLabelMapping[key] || key}:</p>
                
                {/* Conditionally render select or textarea */}
                {selectOptionsMapping[key] ? (
                  <select
                    value={modalData[key]}
                    onChange={(e) => {
                      setModalData((prevData) => ({
                        ...prevData,
                        [key]: e.target.value
                      }));
                    }}
                    className="form-control"
                  >
                    {selectOptionsMapping[key].map(option => (

                      key==='langOne'?
                      (<option key={langOne} value={langOne}>
                        {langOne}
                      </option>):
                      (<option key={langTwo} value={langTwo}>
                        {langTwo}
                      </option>)

                    ))}
                  </select>
                ) : (
                  <textarea
                    ref={(el) => { textareaRefs.current[key] = el; }}
                    value={modalData[key]}
                    onChange={(e) => {
                      setEditWarning('');
                      setModalData((prevData) => ({
                        ...prevData,
                        [key]: e.target.value
                      }));
                    }}
                    style={{ width: "100%", resize: 'none', overflow: 'hidden' }}
                    className="form-control"
                  />
                )}
                
              </div>
            ))}
            {editWarning ? <Alert color="danger">{editWarning}</Alert> : null}

        </ModalBody>

        {/* Modal Footer */}
        <ModalFooter>
          <Button 
            color="primary" 
            onClick={(e) => {
              e.preventDefault();
              const { textOne, textTwo } = modalData;
              if (textOne.length === 0 || textTwo.length === 0) {
                setEditWarning('文本內容不得空白');
                return;
              }
              var yes = confirm('確定要修改嗎？');
              if (yes) {
                updateDataEntry(editingDataRef.current.id, modalData);
                setIsEditModalOpen(false);
              }
            }}
          >
            儲存變更
          </Button>
          <Button color="secondary" onClick={() => setIsEditModalOpen(false)}>取消變更</Button>
        </ModalFooter>

      </Modal>
      {/* Modal for editing dataentries Tail */}




      {/* Manual Input new DataEntry & Table for Existing Entries */}
      <Card>
        <CardBody>


          {/* TextArea for Inputting new dataentry */}
          <Row>
            <div 
              className="h4 card-title" 
              style={{ 
                fontSize: "1rem",
                background: "#d7eef8",
                padding: "0.5rem 1rem",
                borderRadius: "0.3rem",
                marginTop: "0.2rem",
                marginBottom: "0.3rem",
              }}
            >
              <span>{`${dbName}`}</span>
            </div>
            <div style={{color:"#45ad4a", fontSize: "13px"}} className="mt-2 card-title">對象：{dbClient||''}</div>
            <div style={{color:"#7fbdd8", fontSize: "13px"}} className="mb-4 card-title">標籤：{tags?.join('、')||''}</div>
          </Row>



          <Row style={{ marginTop:"0rem" }}>

            <Col sm={12} style={{ margin:"1rem 0rem 0.5rem 0rem" }}>
              <div className="h4" style={{ fontSize:"1rem" }}>新增對照資料</div>
            </Col>

            {/* 左欄 */}
            <Col sm={5}>

              {/* 語言1 */}
              <Row>
                <Col sm={3} style={{ display:"flex", justifyContent:"center" }}>
                  <div className="mb-4 h4 card-title">語言1</div>
                </Col>
                <Col sm={9}>
                  <select 
                    ref={langOneSelectRef}
                    className="form-control"
                    id="langOneSelection"
                    {...langOneInputProps}
                  >
                    <option value={langOne}>{langOne}</option>
                  </select>
                </Col>
              </Row>

              {/* 文本1 */}
              <Row>
                <Col sm={3} style={{ display:"flex", justifyContent:"center" }}>
                  <div className="mb-4 h4 card-title">文本1</div>
                </Col>
                <Col sm={9}>
                  <div className="toast fade show" style={{"width": "100%", "border": "1px solid #a6b0cf", 'marginBottom': '0.5rem', boxShadow:"none" }} role="alert">
                    <div className="toast-body">
                    <textarea
                      ref={textOneRef}
                      style={{ 'minHeight':'0rem', 'border':'none', 'resize': 'none', padding: '0', minHeight:'8vh' }}
                      className="form-control" 
                      id="textOneEntry"
                      {...textOneInputProps}
                    />
                    </div>
                  </div>
                </Col>
              </Row>

            </Col>

            {/* 中欄 */}
            <Col sm={5}>

              {/* 語言2 */}
              <Row>
                <Col sm={3} style={{ display:"flex", justifyContent:"center" }}>
                  <div className="mb-4 h4 card-title">語言2</div>
                </Col>
                <Col sm={9}>
                  <select 
                    ref={langTwoSelectRef} 
                    className="form-control"
                    id="langTwoSelection"
                    {...langTwoInputProps}
                  >
                    <option value={langTwo}>{langTwo}</option>
                  </select>
                </Col>
              </Row>

              {/* 文本2 */}
              <Row>
                <Col sm={3} style={{ display:"flex", justifyContent:"center" }}>
                  <div className="mb-4 h4 card-title">文本2</div>
                </Col>
                <Col sm={9}>
                  <div className="toast fade show" style={{"width": "100%", "border": "1px solid #a6b0cf", "--bs-toast-box-shadow": "0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.175)", boxShadow:"none" }} role="alert">
                    <div className="toast-body">
                    <textarea
                      ref={textTwoRef}
                      style={{ 'minHeight':'0rem', 'border':'none', 'resize': 'none', padding: '0', minHeight:'8vh' }}
                      className="form-control" 
                      id="textTwoEntry"
                      {...textTwoInputProps}
                    />
                    </div>
                  </div>
                </Col>
              </Row>

            </Col>

            {/* 右欄 */}
            <Col sm={2}>

              {/* 送出資料 */}
              <Row>
                <Col sm={12}>
                  <button type="button" onClick={onSubmitData} className="btn btn-outline-success">
                    {responseStatus==='retrieving' && (
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    )}
                    {/*<i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}*/}
                    添加資料
                  </button>
                </Col>
              </Row>

              {userWarning ? <Alert color="danger" style={{ marginTop:'2vh' }}>{userWarning}</Alert> : null}

            </Col>

          </Row>
          {/* Manual Input new dataentry Section Tail */}




          {/* Table for Showing all existing dataentries */}
          <Row style={{"marginTop":"2rem"}}>
            <Col>
              {glossaryLoading &&
                <div 
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center'
                  }}
                >
                  <Spinner className="ms-2" color="success" />
                </div>
              }
              {!glossaryLoading &&
                <MemoizedTableContainer
                  columns={columns}
                  data={filteredData}
                  isGlobalFilter={true}
                  isAddOptions={false}
                  customPageSize={20}
                  onRowClick={handleClick}
                  handleSelectAll={handleSelectAll}
                />
              }
            </Col>
          </Row>
          {/* Table for Showing all existing dataentries Tail */}



        </CardBody>
      </Card>
      {/* Manual Input new DataEntry & Table for Existing Entries Tail */}

    </React.Fragment>
  );
};


LatestTransaction.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
};


export default withRouter(LatestTransaction);
