import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Alert,
  Badge,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody
} from "reactstrap";
import { map } from "lodash";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// Import Components
import Breadcrumbs from "components/Common/Breadcrumb";

// Import additional Libraries
import * as Yup from "yup";
import { useFormik } from "formik";
import * as moment from "moment";

// Custom Modal
import SharedInfoModal from "./SharedInfoModal";

// Import Images
import placeholder from "assets/images/placeholders/placeholder_card.png"

// FontAwesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as farStar } from "@fortawesome/free-regular-svg-icons";
import { faStar as fasStar } from "@fortawesome/free-solid-svg-icons";

import './custom.css';

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  addNewProject as onAddNewProject,
  updateProject as onUpdateProject,
  deleteProject as onDeleteProject,
  setProjectImage,
} from "store/actions";

// Custom Axios
import axiosInstance from 'utils/axiosInstance';
import { Pagination } from 'utils/pagination';

// Custom Utility Functions
import { dateGen } from 'utils/toolUtils';
import { useDeepCompareMemoize, fetchImage, uploadImg } from 'utils/imageUtils';

const handleValidDate = date => {
  const validDate = moment(new Date(date)).format("YYYY-MM-DD");
  return validDate;
};

const ProjectStatus = ({ status }) => {
  switch (status) {
    case "Not started":
      return <Badge className="bg-secondary"> {status} </Badge>;
    case "Ongoing":
      return <Badge className="bg-info"> {status} </Badge>;
    case "Pending":
      return <Badge className="bg-warning"> {status} </Badge>;
    case "Delay":
      return <Badge className="bg-danger"> {status} </Badge>;
    case "Completed":
      return <Badge className="bg-success"> {status} </Badge>;

    default:
      return <Badge className="bg-success"> {status} </Badge>;
  }
};


const ProjectsShared = () => {

  /*---- Title and Initial Setup ----*/
  document.title = "與我共享的專案 | TheChart.AI";
  const dispatch = useDispatch();

  const [sortedProjects, setSortedProjects] = useState([]);

  const { userData, sharedProjects, projectImages } = useSelector(state => ({
    userData: state.user.userData,
    sharedProjects: state.projects.sharedProjects,
    projectImages: state.projects.projectImages,
  }));
  /*---- Title and Initial Setup Tail ----*/


  useEffect(()=>{
    console.log('sharedProjects in projects-shared:', sharedProjects);
  },[sharedProjects])


  /*---- Modal Editing a Project ----*/

  // Current project in Edit Modal
  const [project, setProject] = useState();
  // State for Edit Modal Open or Close
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  // Open and close the edit modals
  const toggle = () => {
    if (modal) {
      setModal(false);
      setProject(null);
    } else {
      setModal(true);
    }
  };

  // Open the modal to edit a project
  const handleProjectClick = async(arg) => {
    
    const project = arg;
    const iimg = await(fetchImage(project.img));

    setProject({
      _id: project._id,
      img: project.img,
      imgUrl: iimg,
      name: project.name,
      description: project.description,
      status: project.status,
      startDate: project.startDate,
      endDate: project.endDate,
    });
    // Set to edit mode
    setIsEdit(true);
    // Open the Modal
    toggle();

  };

  /*---- Modal Editing a Project Tail ----*/



  /*---- Modal showing shared Info ----*/

  // State for Delete Modal Open or Close
  const [sharedInfoModal, setSharedInfoModal] = useState(false);

  const onClickSharedInfo = (project) => {
    setProject(project);
    setSharedInfoModal(true);
  };

  /*---- Modal showing shared Info ----*/



  /*---- Form Validation ----*/

  const validation = useFormik({

    enableReinitialize: true,

    // sets the inital values of the form fields
    initialValues: {
      _id: (project && project._id) || "",
      img: (project && project.img) || "",
      name: (project && project.name) || "",
      description: (project && project.description) || "",
      status: (project && project.status) || "",
      startDate: (project && project.startDate) || "",
      endDate: (project && project.endDate) || "",
    },

    // define validation rules using Yup
    validationSchema: Yup.object({
      name: Yup.string().required(
        "請輸入專案名稱"
      ),
      description: Yup.string().required(
        "請輸入專案描述"
      ),
      status: Yup.string().required(
        "請選擇專案進行狀態"
      ),
      startDate: Yup.date()
        .required("請選擇開始日期")
        .max(
          Yup.ref('endDate'),
          "開始日期不能晚於結束日期"
        ),
      endDate: Yup.date()
        .required("請選擇結束日期")
    }),

    // gets called when the form is submitted
    onSubmit: (values) => {

      console.log('values', values);

      // Editing project
      if (isEdit) {

        const updateProject = {
          _id: project._id,
          img: values.img,
          name: values.name,
          description: values.description,
          status: values.status,
          startDate: values.startDate,
          endDate: values.endDate,
        };
        dispatch(onUpdateProject(updateProject));

      // Adding new project, not currently in use
      } else {

        const newProject = {
          name: values.name,
          description: values.description,
          status: values.status,
          startDate: values.startDate,
          endDate: values.endDate,
        };
        // save new project
        dispatch(onAddNewProject(newProject));

      }

      toggle(); // Close the modal
    },

  });

  /*---- Form Validation Tail ----*/



  /*-------------------- Filtering, Sorting -----------------------*/
  /*---- Search Input ----*/

  const [searchValue, setSearchValue] = useState("");
  const [filterOption, setFilterOption] = useState('name');

  const filterOptionMappings = {
    'name': '專案名稱',
    'startDate': '開始時間',
    'endDate': '結束時間',
    'status': '狀態',
  };

  // A generic filter function
  const applyFilter = (project) => {
    const searchLower = searchValue.toLowerCase();

    //console.log('project:', project);

    if (filterOption === 'tags') {
      // Check if any tag matches the search value
      return project.tags.some(tag => tag.toLowerCase().includes(searchLower));
    } else {
      //console.log('filterOption:', filterOption);
      // Handle other string-based filters
      const filterValue = project[filterOption] ? project[filterOption].toLowerCase() : '';
      return filterValue.includes(searchLower);
    }
  };

  /*---- Search Input Tail ----*/

  /*---- Sorting Function ----*/

  const [sortAttribute, setSortAttribute] = useState('startDate'); // default sort attribute
  const [sortDirection, setSortDirection] = useState('desc'); // 'asc' or 'desc'

  const sortDirectionMappings = { 'asc': '升序', 'desc': '降序' };
  const sortAttributeMappings = {
    'name': '專案名稱',
    'startDate': '開始時間',
    'endDate': '結束時間',
    'status': '狀態',
    'favorite': '我的最愛',
  };

  // Function to toggle sort direction
  const toggleSortDirection = () => {
    setSortDirection(prevDirection => prevDirection === 'asc' ? 'desc' : 'asc');
  };

  // Function to sort the data
  const sortData = (a, b) => {
    const isAFavorite = (favoriteProjects ? favoriteProjects.includes(a._id) : 0);
    const isBFavorite = (favoriteProjects ? favoriteProjects.includes(b._id) : 0);

    // Custom order for 'status'
    const statusOrder = ['Delay', 'Pending', 'Ongoing', 'Completed'];

    if (sortAttribute === 'favorite') {
      if (isAFavorite && !isBFavorite) {
        return sortDirection === 'desc' ? -1 : 1;
      }
      if (!isAFavorite && isBFavorite) {
        return sortDirection === 'desc' ? 1 : -1;
      }
      // If both are favorites or both are not, sort by another attribute, like dbName
      return a.name.localeCompare(b.name);
    } else if (sortAttribute === 'status') {
      // Find the index of each status in the custom order
      const indexOfA = statusOrder.indexOf(a.status);
      const indexOfB = statusOrder.indexOf(b.status);

      if (indexOfA < indexOfB) {
        return sortDirection === 'asc' ? -1 : 1;
      }
      if (indexOfA > indexOfB) {
        return sortDirection === 'asc' ? 1 : -1;
      }
      // If both statuses are equal, fallback to another attribute, like 'name'
      return a.name.localeCompare(b.name);
    } else {
      // Rest of your sorting logic for other attributes
      if (a[sortAttribute] < b[sortAttribute]) { return sortDirection === 'asc' ? -1 : 1 }
      if (a[sortAttribute] > b[sortAttribute]) { return sortDirection === 'asc' ? 1 : -1 }
    }
    return 0;
  };

  /*---- Sorting Function Tail ----*/

  /*---- Favorite Function ----*/

  const [favoriteProjects, setFavoriteProjects] = useState([]);
  useEffect(()=>{
    setFavoriteProjects(userData?.stats?.favoriteProjects);
    //console.log('favoriteProjects:', userData?.stats?.favoriteProjects);
  },[userData]);

  const toggleFavorite = async (projectId) => {
    try {
      // Optimistically update the state
      const isCurrentlyFavorite = favoriteProjects.includes(projectId);
      const newFavorites = isCurrentlyFavorite 
        ? favoriteProjects.filter(id => id !== projectId)
        : [...favoriteProjects, projectId];
      setFavoriteProjects(newFavorites);

      const response = await axiosInstance.post('/knovia/toggle-favoriteproject', {projectId: projectId});

      if (response.status !== 200) {
        setFavoriteProjects(favoriteProjects); // revert the state
        console.error('Failed to update favorite status with status:', response.status);
      }
      // console.log('favoriteProjects', response.data);
      setFavoriteProjects(response.data);

      /*---- PENDING: Should just update the store instead of fetching again ----*/
      dispatch({ type: 'FETCH_USER_DATA' });

    } catch (error) {
      setFavoriteProjects(favoriteProjects); // revert to the original favorites
      console.error('Error:', error);
    }
  };

  /*---- Favorite Function Tail ----*/

  /*---- Pagination START ----*/

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [totalPages, setTotalPages] = useState(1);
  const [currentItems, setCurrentItems] = useState([]);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const itemsPerPageMappings = {'4': '4', '8': '8', '12': '12'}

  // Updating current items, items viewable in current page
  useEffect(() => {
    const newLastItem = currentPage * itemsPerPage;
    const newFirstItem = newLastItem - itemsPerPage;
    setCurrentItems(sortedProjects.slice(newFirstItem, newLastItem));
  }, [currentPage, itemsPerPage, sortedProjects]);
  // Set and Reset totalPage when paragraphs changes
  useEffect(() => { setTotalPages(Math.ceil(sortedProjects.length / itemsPerPage)) }, [sortedProjects, itemsPerPage]);
  // If itemsPerPage changed by user
  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(Number(value)); // event.target.value is the chosen value
    setCurrentPage(1); // Reset to the first page
  };

  /*---- Pagination TAIL ----*/


  useEffect(()=>{
    if(sharedProjects.length>0){
      const newSortedProjects = sharedProjects.filter(applyFilter).sort(sortData);
      console.log('newSortedProjects:', newSortedProjects);
      setSortedProjects(newSortedProjects);
    } else if(sharedProjects.length===0){
      console.log('sharedProject of the user is empty');
      setSortedProjects([]);
    }
  }, [sharedProjects, 
      favoriteProjects, 
      sortAttribute, 
      sortDirection, 
      searchValue, 
      filterOption]
      );


  return (
    <React.Fragment>

      <SharedInfoModal
        show={sharedInfoModal}
        onCloseClick={() => setSharedInfoModal(false)}
        projectId={project?._id}
        title={`專案共用資訊 [${project?.name}]`}
      />

      {/* The main UI shown */}
      <div className="page-content">

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Projects" breadcrumbItem="專案列表" />

          <Row>



            {/*--- Filtering, Sorting ---*/}
            <Col sm="12" md="7" lg="7" xl="5" 
              style={{ 
                display:"flex"
              }}
            >

              {/* 篩選條件 Dropdown */}
              <div
                style={{ 
                display:"flex",
                flexWrap: "wrap",
                alignContent: "center",
                marginRight: "0.5rem"
              }}
              >
                <UncontrolledDropdown>
                  <DropdownToggle tag="button" className="btn btn-outline-info">
                    {`搜尋條件：${filterOptionMappings[filterOption]}`} 
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>

                  <DropdownMenu>
                    {Object.entries(filterOptionMappings).map(([key, label]) => (
                      <DropdownItem key={key} onClick={() => setFilterOption(key)}>
                        {label}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              {/* 篩選條件 Dropdown Tail */}


              {/* 輸入篩選 Input */}
              <div 
                className="search-box" 
                style={{
                  display: "flex",
                  alignContent: "center",
                  flexWrap: "wrap",
                  marginTop: "0.4rem"
                }}
              >
                <div className="position-relative">
                  <label htmlFor="search-bar-0" className="search-label">
                    <span id="search-bar-0-label" className="sr-only">Search this table</span>
                    <input 
                      id="search-bar-0" 
                      type="text" 
                      className="form-control" 
                      placeholder={`搜尋 ${sortedProjects.length} 筆資料`}
                      value={searchValue}
                      onChange={(e)=>{
                        setSearchValue(e.target.value);
                        //console.log('search changed!', e.target.value);
                      }}
                    />
                  </label>
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
              </div>
              {/* 輸入篩選 Input Tail */}

            </Col>


            <Col sm="7" md="5" lg="5" xl="4" 
              className="custom-sorting-col"
              style={{ display: "flex" }}
            >

              <div 
                style={{ 
                  display: "flex",
                  flexWrap: "wrap",
                  alignContent: "center",
                  marginRight: "0.5rem"
                }}
              >
                {/* Dropdown for selecting sort attribute */}
                <UncontrolledDropdown>
                  <DropdownToggle tag="button" className="btn btn-outline-secondary">
                    排序條件: {sortAttributeMappings[sortAttribute]}
                  </DropdownToggle>
                  <DropdownMenu>
                    {Object.keys(sortAttributeMappings).map(key => (
                      <DropdownItem key={key} onClick={() => setSortAttribute(key)}>
                        {sortAttributeMappings[key]}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>

              {/* Dropdown for selecting sort direction */}
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignContent: "center",
                }}
              >
                <button className="btn btn-outline-secondary" onClick={toggleSortDirection}>
                  排序方向: {sortDirectionMappings[sortDirection]}
                </button>
              </div>

            </Col>

            <Col xs="12" sm="5" md="12" lg="12" xl="3"
              className="custom-item-per-page-col"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
              }}
            >
                {/* Dropdown for selecting sort attribute */}
                <UncontrolledDropdown>
                  <DropdownToggle tag="button" className="btn btn-outline-warning">
                    每頁顯示段落數：{itemsPerPage}
                  </DropdownToggle>
                  <DropdownMenu>
                    {Object.keys(itemsPerPageMappings).map(key => (
                      <DropdownItem key={key} onClick={() => handleItemsPerPageChange(key)}>
                        {itemsPerPageMappings[key]}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>

            </Col> 
            {/*--- Filtering, Sorting ---*/}


            <Col lg="12">
              <div>

                <Card style={{ margin: '4vh 5vw 5vh 5vw' }}>
                  <CardBody>
                    <div className="table-responsive" style={{ minHeight:'50vh' }}>

                      {/* Table for showing the projects */}
                      <Table 
                        className="project-list-table table-nowrap align-middle table-borderless"
                        style={{ padding: '0vh 3vw', marginBottom: '0vh' }}
                      >
                        
                        {/* 表格標題 */}
                        <thead>
                          <tr>
                            <th scope="col" style={{ width: "12%", textAlign:"center" }}>最愛</th>
                            <th scope="col" style={{ width: "12%", textAlign:"center" }}>圖片</th>
                            <th scope="col" style={{ width: "30%", textAlign:"center" }}>專案名稱</th>
                            <th scope="col" style={{ width: "15%", textAlign:"center" }}>開始時間</th>
                            <th scope="col" style={{ width: "15%", textAlign:"center" }}>結束時間</th>
                            <th scope="col" style={{ width: "15%", textAlign:"center" }}>狀態</th>
                            {/*<th scope="col">管理員</th>*/}
                            <th scope="col" style={{ width: "5%", textAlign:"center" }}>編輯</th>
                          </tr>
                        </thead>

                        {/* 表格內容：專案 */}
                        <tbody>

                          {/*有專案時顯示專案資訊*/}

                          {currentItems.length > 0 ? (
                            currentItems.map((project, index) => {

                              const cardIsFavorite = (favoriteProjects ? favoriteProjects.includes(project?._id) : 0);
                              //console.log('project', index, project);
                              return (

                                <tr key={index}>

                                  <td style={{ textAlign:"center" }}>
                                    <FontAwesomeIcon 
                                      icon={cardIsFavorite ? fasStar : farStar} 
                                      color={cardIsFavorite ? "gold" : "grey"} 
                                      onClick={() => toggleFavorite(project._id)}
                                    />
                                  </td>

                                  {/* Importing company logos from the companies array of images */}
                                  <td style={{ textAlign:"center" }}>
                                    <img
                                      src={projectImages[project._id] || placeholder}
                                      alt={project.name}
                                      className="avatar-sm"
                                      style={{
                                        borderRadius: "50%",
                                        objectFit: "cover"
                                      }}
                                    />
                                  </td>
                                  {/* Project names and descriptions */}
                                  <td style={{ textAlign:"center" }}>
                                    <h5 className="text-truncate font-size-14">
                                      <Link
                                        to={`/projects-overview/${project._id}`}
                                        className="text-dark"
                                      >
                                        {project.name}
                                      </Link>
                                    </h5>
                                    <p className="text-muted mb-0">
                                      {project.description}
                                    </p>
                                  </td>

                                  {/* Project startDate formatted */}
                                  <td style={{ textAlign:"center" }}> 
                                    {handleValidDate(project.startDate)}
                                  </td>

                                  {/* Project endDate formatted */}
                                  <td style={{ textAlign:"center" }}>
                                    {handleValidDate(project.endDate)}
                                  </td>

                                  {/* Project status */}
                                  <td style={{ textAlign:"center" }}>
                                    <ProjectStatus status={project.status} />
                                  </td>

                                  <td>
                                    <UncontrolledDropdown>
                                      {/* Drop down for edit actions */}
                                      <DropdownToggle
                                        className="card-drop"
                                        tag="a"
                                      >
                                        <i className="mdi mdi-dots-horizontal font-size-18" />
                                      </DropdownToggle>

                                      <DropdownMenu className="dropdown-menu-end">
                                        {/* Edit actions: Edit */}
                                        <DropdownItem
                                          onClick={() => handleProjectClick(project)}
                                        >
                                          <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
                                          編輯專案資訊
                                        </DropdownItem>

                                        {/* Edit actions: Share */}
                                        <DropdownItem
                                          onClick={() => onClickSharedInfo(project)}
                                        >
                                          <i className="mdi mdi-share-variant-outline font-size-16 text-warning me-1" />{" "}
                                          檢視分享資訊
                                        </DropdownItem>

                                      </DropdownMenu>

                                    </UncontrolledDropdown>
                                  </td>

                                </tr>
                              )
                            })

                          ):(
                            <tr>
                              <td colSpan="6" style={{ textAlign: "center" }}>
                                <Alert className="mt-4" color="warning">
                                  還沒有與我共用的專案喔！
                                </Alert>
                              </td>
                            </tr>
                          )}

                        </tbody>

                      </Table>

                      {/* 專案編輯視窗 */}
                      <Modal isOpen={modal} toggle={toggle}>

                        {/* 專案編輯視窗標題 */}
                        <ModalHeader toggle={toggle} tag="h4">
                          {!!isEdit ? "編輯專案" : "新增專案"}
                        </ModalHeader>

                        {/* 專案編輯視窗內容 */}
                        <ModalBody>

                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit(); // 確定填寫內容符合格式
                              return false;
                            }}
                          >
                            <Row>
                              <Col xs={12}>

                                <div className="mb-3">
                                  <Label for="projectImage" className="form-label">專案圖片</Label>
                                  {project?.imgUrl && (
                                    <div style={{ display:"flex", justifyContent:"center", margin:"1rem 0rem" }}>
                                      <img 
                                        src={project?.imgUrl} 
                                        alt="Project" 
                                        style={{ 
                                            width: '100px', 
                                            height: '100px',
                                            borderRadius: '50%',
                                            objectFit: 'cover'
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>

                                {/* Team cell, hidden */}
                                <Input
                                  type="hidden"
                                  value={validation.values.team || ""}
                                  name="team"
                                />

                                {/* Name cell */}
                                <div className="mb-3">
                                  <Label className="form-label">專案名稱</Label>
                                  <Input
                                    name="name"
                                    type="text"
                                    disabled
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.name || ""}
                                    invalid={
                                      validation.touched.name && validation.errors.name ? true : false
                                    }
                                  />
                                  {/* Display error message */}
                                  {validation.touched.name && validation.errors.name ? (
                                    <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                  ) : null}
                                </div>

                                {/* Description cell */}
                                <div className="mb-3">
                                  <Label className="form-label">專案描述</Label>
                                  <Input
                                    name="description"
                                    type="text"
                                    disabled
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.description || ""}
                                    invalid={
                                      validation.touched.description && validation.errors.description ? true : false
                                    }
                                  />
                                  {validation.touched.description && validation.errors.description ? (
                                    <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                  ) : null}
                                </div>

                                {/* Status select */}
                                <div className="mb-3">
                                  <Label className="form-label">專案進行狀態</Label>
                                  <Input
                                    name="status"
                                    id="status1"
                                    type="select"
                                    className="form-select"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                      validation.values.status || ""
                                    }
                                  >
                                    <option>Not started</option>
                                    <option>Ongoing</option>
                                    <option>Completed</option>
                                    <option>Pending</option>
                                    <option>Delay</option>
                                  </Input>
                                  {validation.touched.status && validation.errors.status ? (
                                    <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
                                  ) : null}
                                </div>

                                {/* Start Date select */}
                                <div className="mb-3">
                                  <Label className="form-label">開始日期</Label>
                                  <Input
                                    name="startDate"
                                    type="date"
                                    disabled
                                    format="YYYY/MM/DD"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.startDate && validation.errors.startDate ? true : false
                                    }
                                    value={validation.values.startDate || ""}
                                  ></Input>
                                  {validation.touched.startDate && validation.errors.startDate ? (
                                    <FormFeedback type="invalid">{validation.errors.startDate}</FormFeedback>
                                  ) : null}
                                </div>

                                {/* End Date select */}
                                <div className="mb-3">
                                  <Label className="form-label">結束日期</Label>
                                  <Input
                                    name="endDate"
                                    type="date"
                                    disabled
                                    format="YYYY/MM/DD"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.endDate && validation.errors.endDate ? true : false
                                    }
                                    value={validation.values.endDate || ""}
                                  ></Input>
                                  {validation.touched.endDate && validation.errors.endDate ? (
                                    <FormFeedback type="invalid">{validation.errors.endDate}</FormFeedback>
                                  ) : null}
                                </div>

                              </Col>
                            </Row>

                            {/* Save Button */}
                            <Row>
                              <Col>
                                <div className="text-end">
                                  <button
                                    type="submit"
                                    className="btn btn-success save-user"
                                  >
                                    儲存
                                  </button>
                                </div>
                              </Col>
                            </Row>

                          </Form>


                        </ModalBody>

                      {/* ^專案編輯視窗 */}
                      </Modal>

                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>

          {/* Pagination Controls */}
          <div style={{ marginBottom:"1.5rem" }}>
            <Pagination currentPage={currentPage} totalPages={totalPages} paginate={paginate} />
          </div>
          {/* Pagination Controls Tail */}

        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ProjectsShared);
