import {
  CLEAR_LOGIN_STATES,
  SET_UNAUTHORIZED_ERROR,

  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,

  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAILURE,
} from "./actionTypes"

const initialState = {
  userData: {},
  error: "",
  loading: false,
  isLoggedIn: false,
}

const login = (state = initialState, action) => {
  switch (action.type) {

    /*--- Unauthorized Event ---*/
    case SET_UNAUTHORIZED_ERROR:
      console.log("[store/auth/login/reducer.js] UNAUTHORIZED!");
      state = {
        ...state,
        error: action.payload,
        loading: false,
        isLoggedIn: false,
      };
      break;


    /*--- Clear States ---*/
    case CLEAR_LOGIN_STATES:
      console.log("[store/auth/login/reducer.js] LOGIN STATES CLEARED!");
      state = {
        ...state,
        error: "",
        loading: false,
      };
      break;


    /*--- Login Request ---*/
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        userData: action.payload,
        loading: false,
        isLoggedIn: true,
      };
      break;
    case LOGIN_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        isLoggedIn: false,
      };
      break;


    /*--- Logout Request ---*/
    case LOGOUT_USER:
      state = { ...state };
      break;
    case LOGOUT_USER_SUCCESS:
      state = { 
        ...state,
        error: null,
        isLoggedIn: false,
      }
      break;
    case LOGOUT_USER_FAILURE:
      state = { 
        ...state,
        error: action.payload,
        loading: false,
      };
      break;


    /*--- Fallback ---*/
    default:
      state = { ...state };
      break;
  }
  return state;
}

export default login;
