import React, { useState, useEffect } from "react";
import { 
  Button, 
  Card, 
  CardBody, 
  CardTitle, 
  Col, 
  Container, 
  Input, 
  Row,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";

// Custom Utils
import { 
  describeOrder, 
  findDifferences, 
  isValidWHDrink,
  validateItemName,
  validateToppings,
  formatDateTimeToChinese,
} from 'utils/kioskUtils';

import { calculateOrderPrice } from 'api/product';


const isValidItemName = async (itemName)=>{return await validateItemName(itemName);}
const isValidQtyDrink = (qtyDrink)=>{return (qtyDrink>0 && qtyDrink<30);}
const isValidQtyIce = (qtyIce)=>{return (qtyIce==='100' || qtyIce==='70' || qtyIce==='40' || qtyIce==='0' || qtyIce==='W' || qtyIce==='H');}
const isValidQtySugar = (qtySugar)=>{return (qtySugar==='100' || qtySugar==='70' || qtySugar==='50' || qtySugar==='25' || qtySugar==='0');}
const isValidSizeDrink = (sizeDrink)=>{return (sizeDrink==='M' || sizeDrink==='L');}
const isValidToppings = (toppings)=>{return validateToppings(toppings);}

/*---- Check if orderDrink is complete for an order ----*/
const isCompleteOrderDrink = async (orderDrink) =>{
  let orderDrinkCheck = 'ok';
  console.log('orderDrink:', orderDrink);
  let k = 0;
  
  for (k in orderDrink){
    let thisOrderDrinkError = [], alternateDrinkList = [];
    const { isOwnCup, itemName, qtyDrink, qtyIce, qtySugar, sizeDrink, toppings } = orderDrink[k];
    console.log(`#${k}`, isOwnCup, itemName, qtyDrink, qtyIce, qtySugar, sizeDrink, toppings);

    while(1){
      const {validItemName, message, options} = await isValidItemName(itemName);
      alternateDrinkList = options;
      /* 顧客給的品項名稱不對 */
      if(!validItemName){ thisOrderDrinkError.push('Invalid Item Name'); }
      if(!isValidQtyDrink(qtyDrink)){ thisOrderDrinkError.push('Invalid QtyDrink'); }

      if(qtyIce==='W' || qtyIce==='H'){
        if(!isValidWHDrink(itemName, qtyIce)){ thisOrderDrinkError.push(`Invalid Warm/Hot Drink`); }
      }
      if(!isValidQtyIce(qtyIce)){ thisOrderDrinkError.push('Invalid QtyIce'); }

      if(!isValidQtySugar(qtySugar)){ thisOrderDrinkError.push('Invalid QtySugar'); }
      if(!isValidSizeDrink(sizeDrink)){ thisOrderDrinkError.push('Invalid SizeDrink'); }
      if(!isValidToppings(toppings)){ thisOrderDrinkError = 'Invalid Toppings'; }

      break;
    }

    console.log('thisOrderDrinkError:', thisOrderDrinkError);

    while(1){


      /*--- Only One Error ----*/
      if(thisOrderDrinkError.includes(`Invalid Item Name`)){
        if(alternateDrinkList.length>0){
          orderDrinkCheck = `Order#${k} is Invalid Item Name, try>>${alternateDrinkList[0]}`;
        } else {
          orderDrinkCheck = `Order#${k} is Invalid Item Name`;
        }
        break;
      }
      if(thisOrderDrinkError.includes(`Invalid Warm/Hot Drink`)){
        orderDrinkCheck = `Order#${k} can not be made Warm/Hot Drink`;
        break;
      }
      if(thisOrderDrinkError.includes(`Invalid Toppings`)){
        orderDrinkCheck = `Order#${k} has invalid toppings`;
        break;
      }
      if(thisOrderDrinkError.length===1){

        if(thisOrderDrinkError.includes(`Invalid QtySugar`)){
          orderDrinkCheck = `Order#${k} has Invalid QtySugar`;
          break;
        } else {
          orderDrinkCheck = thisOrderDrinkError[0]; 
          break;
        }
      }
      /*--- Only One Error ----*/


      if(thisOrderDrinkError.length===2){
        if(thisOrderDrinkError.includes('Invalid QtyIce') && thisOrderDrinkError.includes('Invalid QtySugar')){
          orderDrinkCheck = `Order#${k} has Invalid QtyIce and QtySugar`;
          break;
        }
        if(thisOrderDrinkError.includes('Invalid SizeDrink') && thisOrderDrinkError.includes('Invalid QtySugar')){
          orderDrinkCheck = `Order#${k} has Invalid SizeDrink and QtySugar`;
          break;
        }
      }
      if(thisOrderDrinkError.length===3){
        if(thisOrderDrinkError.includes('Invalid QtyIce') && thisOrderDrinkError.includes('Invalid QtySugar')){
          orderDrinkCheck = `Order#${k} has Invalid SizeDrink, QtyIce and QtySugar`;
          break;
        }
      }
      break;
    }

    if(orderDrinkCheck!=='ok'){break;}
    else {continue;}
  }

  return orderDrinkCheck;
}


/*---- Main Function ----*/
const ProductCreate = () => {

  /*---- Title and Initial Setup ----*/

  document.title = "點餐資料測試 | TheChart.AI";
  
  /*---- Title and Initial Setup ----*/

  const [prevInput, setPrevInput] = useState('');
  const [textInput, setTextInput] = useState('');
  const [jsonAnswer, setJsonAnswer] = useState('');

  const processJson = async () => {

    let newJsonAnswer = '';
    const prevJsonInput = JSON.parse(prevInput);
    const jsonInput = JSON.parse(textInput);
    const jsonDifference = findDifferences(prevJsonInput, jsonInput);
    console.log('prevJsonInput:', prevJsonInput);
    console.log('jsonInput:', jsonInput);
    console.log('jsonDifference:', jsonDifference);

    const { statedOpening: prevStatedOpening, isPreOrder: prevIsPreOrder, preOrderTime: prevPreOrderTime,
      orderDrink: prevOrderDrink, askedConfirmOrder: prevAskedConfirmOrder, repliedConfirmOrder: prevRepliedConfirmOrder,
      askedOrderComplete: prevAskedOrderComplete, repliedOrderComplete: prevRepliedOrderComplete, askedNeedPbagaskedNeedPbag: prevAskedNeedPbag,
      repliedNeedPbag: prevRepliedNeedPbag, needPbag: prevNeedPbag, needPbagNum: prevNeedPbagNum,
      separatePbag: prevSeparatePbag, askedPaymentMethod: prevAskedPaymentMethod, repliedPaymentMethod: prevRepliedPaymentMethod,
      paymentMethod: prevPaymentMethod, invoiceMethod: prevInvoiceMethod,
      receivedBarCodeSuccessSignal: prevReceivedBarCodeSuccessSignal,
      isPaymentCompleted: prevIsPaymentCompleted, receivedPaymentSuccessSignal: prevReceivedPaymentSuccessSignal,
      isPaymentFailed: prevIsPaymentFailed, goToQuery: prevGoToQuery,
    } = prevJsonInput;

    const { statedOpening, isPreOrder, preOrderTime,
      orderDrink, askedConfirmOrder, repliedConfirmOrder,
      askedOrderComplete, repliedOrderComplete, askedNeedPbag,
      repliedNeedPbag, needPbag, needPbagNum,
      separatePbag, askedPaymentMethod, repliedPaymentMethod,
      paymentMethod, invoiceMethod, receivedBarCodeSuccessSignal, isPaymentCompleted, 
      receivedPaymentSuccessSignal, isPaymentFailed, goToQuery,
    } = jsonInput;

    console.log('prevGoToQuery:', prevGoToQuery);
    console.log('isPaymentCompleted:', isPaymentCompleted);
    console.log('receivedPaymentSuccessSignal:', receivedPaymentSuccessSignal);
    console.log('goToQuery:', goToQuery);

    let goToQueryType = '';

    while(1){


      /*---- 對話開始 ----*/
      if(statedOpening==="N"){ newJsonAnswer = '您好! 今天想喝什麼?'; break; }
      /*---- 對話開始 ----*/


      /*---- 預定點單時間 ----*/
      if(isPreOrder==="Y" && preOrderTime && Object.keys(orderDrink).length===0){ 
        newJsonAnswer = '好的，沒問題，請問需要什麼飲品呢?'; break; 
      }
      /*---- 預定點單時間 ----*/



      /* Difference in orderDrink */
      if(jsonDifference?.orderDrink?.length>0){
        const {itemName: diffItemName, qtyDrink: diffQtyDrink, sizeDrink: diffSizeDrink, qtyIce: diffQtyIce, 
          qtySugar: diffQtySugar, toppings: diffToppings, isOwnCup: diffIsOwnCup} = jsonDifference?.orderDrink[0];
        /* 顧客改飲料數量 */
        if(diffQtyDrink){
          newJsonAnswer = `好的，幫您改為${ describeOrder(orderDrink) }，還需要加點什麼嗎？`; 
          break; 
        }
      }

      const orderDrinkCheck = await isCompleteOrderDrink(orderDrink);
      console.log('orderDrinkCheck:', orderDrinkCheck);


      /*---- 顧客點單中 ----*/

      /* 顧客點餐品項完整 *//* 顧客補說大小冰塊甜度 */
      if(orderDrinkCheck==='ok' && repliedOrderComplete==="N"){ 
        const orderDescription = describeOrder(orderDrink);
        // alt: 沒問題，請問還需要加點其他品項嗎? 
        // alt: 還需要加點什麼嗎？ // alt: 還需要加點其他品項嗎？
        newJsonAnswer = `好的，您點的是${orderDescription}，對嗎? 還需要點些什麼呢?`; 
        break; 
      }

      /*--- 品項不合規---*/
      if(orderDrinkCheck.includes('is Invalid Item Name, try>>')){
        const alternateDrink = orderDrinkCheck.split('>>')[1];
        const thisItemOrder = orderDrinkCheck.split('#')[1].split(' ')[0];
        const thisItemName = orderDrink[Number(thisItemOrder)].itemName;
        newJsonAnswer = `好的，請問${thisItemName}是${alternateDrink}嗎?`; break; 
      }
      /* 顧客新增不能做溫熱品項 */
      if(orderDrinkCheck.includes('can not be made Warm/Hot Drink')){
        const thisItemOrder = orderDrinkCheck.split('#')[1].split(' ')[0];
        const thisItemName = orderDrink[Number(thisItemOrder)].itemName;
        newJsonAnswer = `不好意思，${thisItemName}只能製作成冷飲哦`; break; 
      }
      /* 顧客品項加料不合規 */
      if(orderDrinkCheck.includes('has invalid toppings')){
        const thisItemOrder = orderDrinkCheck.split('#')[1].split(' ')[0];
        const thisItemName = orderDrink[Number(thisItemOrder)].itemName;
        const toppings = orderDrink[Number(thisItemOrder)].toppings;
        const toppingCount = Object.keys(toppings).map(t=>toppings[t]).reduce((acc,t)=>{return acc+Number(t);},0);
        newJsonAnswer = `抱歉，為確保飲品最佳風味與份量，最多只能加3種（3份）哦，現在${thisItemName}的加料有${toppingCount}份`; break; 
      }
      /*--- 品項不合規---*/

      /*--- 品項不完整---*/
      /* 顧客新增品項缺少冰塊甜度 */
      if(orderDrinkCheck.includes('has Invalid QtyIce and QtySugar')){
        const thisItemOrder = orderDrinkCheck.split('#')[1].split(' ')[0];
        const thisItemName = orderDrink[Number(thisItemOrder)].itemName;
        /* 優惠套組 */
        if(thisItemName.includes('買一送一')){
          newJsonAnswer = `請問${thisItemName}需要什麼冰塊和甜度，提醒您買一送一的兩杯冰塊和甜度須一致哦`; break; 
        } else {
          newJsonAnswer = `好的，請問${thisItemName}的冰塊跟甜度?`; break; 
        }
      }
      /* 顧客新增品項缺少甜度 */
      if(orderDrinkCheck.includes('has Invalid QtySugar')){
        const thisItemOrder = orderDrinkCheck.split('#')[1].split(' ')[0];
        const thisItemName = orderDrink[Number(thisItemOrder)].itemName;
        newJsonAnswer = `好的，請問${thisItemName}的甜度要多少?`; break; 
      }
      /* 顧客新增品項缺少大小、甜度 */
      if(orderDrinkCheck.includes('has Invalid SizeDrink and QtySugar')){
        const thisItemOrder = orderDrinkCheck.split('#')[1].split(' ')[0];
        const thisItemName = orderDrink[Number(thisItemOrder)].itemName;
        newJsonAnswer = `好的，請問${thisItemName}要大杯還是中杯，甜度如何?`; break; 
      }
      /* 顧客新增品項缺少大小冰塊甜度 */
      if(orderDrinkCheck.includes('has Invalid SizeDrink, QtyIce and QtySugar')){
        const thisItemOrder = orderDrinkCheck.split('#')[1].split(' ')[0];
        const thisItemName = orderDrink[Number(thisItemOrder)].itemName;
        newJsonAnswer = `好的，請問${thisItemName}要大杯還是中杯，甜度、冰塊如何?`; break; 
      }
      /*--- 品項不完整---*/

      /*---- 顧客點單中 ----*/




      /*----  顧客點單完成 ----*/

      /* 顧客確認不再加點 */ /* 顧客確認不再加點，有買一送一品項 */
      if(repliedOrderComplete==="Y" && repliedNeedPbag==="N"){
        const totalPrice = await calculateOrderPrice(orderDrink);
        const totalDrinkCount = orderDrink.reduce((acc, d) => {
          return acc + d.qtyDrink;
        }, 0);
        console.log('totalDrinkCount:', totalDrinkCount);
        const hasBuyOneGetOneFree = (orderDrink.map(d=>d.itemName).filter(d=>d.includes('買一送一')).length>0);
        // alt: 總金額是 85 元，請問要加購塑膠袋嗎? // alt: 一共是110元，請問需要加購塑膠袋嗎?
        if(hasBuyOneGetOneFree){
          newJsonAnswer = `算上買一送一優惠後，總金額是${totalPrice}元。請問要加購塑膠袋嗎? 一個塑膠袋是2元`; break; 
        } else {
          /* 顧客確認不再加點，有大量品項 */
          if(totalDrinkCount>10){
            newJsonAnswer = `您點的飲料總共是${totalPrice}元。請問您要購買塑膠袋嗎? 預計需要${totalDrinkCount/10}個塑膠袋來分裝，每個塑膠袋2元，將增加${totalDrinkCount/5}元費用。`; 
            break;
          } else {
            newJsonAnswer = `您點的飲料總共是${totalPrice}元。請問要加購塑膠袋嗎? 一個塑膠袋是2元`; break; 
          }
        }
      }

      /*----  顧客點單完成 ----*/
      


      console.log('repliedNeedPbag:', repliedNeedPbag);
      console.log('repliedPaymentMethod:', repliedPaymentMethod);


      /*---- 選擇支付方式 ----*/
      if(repliedNeedPbag==="Y" && repliedPaymentMethod=="N"){
        newJsonAnswer = `請問要使用哪一種支付方式呢?`; break;
      }
      /*---- 選擇支付方式 ----*/


      console.log('invoiceMethod:', invoiceMethod);



      /*---- 選擇載具與掃描載具 ----*/
      if(repliedPaymentMethod==="Y" && invoiceMethod=="" && paymentMethod!=='EzCard'){
        newJsonAnswer = `發票要列印還是存載具?`; break;
      }
      console.log('receivedBarCodeSuccessSignal', receivedBarCodeSuccessSignal);
      if(invoiceMethod==="phone" && receivedBarCodeSuccessSignal==="N"){
        newJsonAnswer = `好的，請先將載具對準下方掃描區`; break;
      }
      /*---- 選擇載具與掃描載具 ----*/


      console.log('paymentMethod:', paymentMethod);
      console.log('isPaymentCompleted:', isPaymentCompleted);


      /*---- 依據支付方式進行支付 ----*/
      if(isPaymentCompleted==="N" && isPaymentFailed==="N"){
        /* 顧客選完發票方式，支付方式使用 LinePay */
        if(paymentMethod==="LinePay"){
          newJsonAnswer = `好的，請將Line Pay付款條碼對準下方掃描區`; break;
        }
        /* 顧客選完發票方式，支付方式使用悠遊卡 */
        if(paymentMethod==="EzCard"){
          newJsonAnswer = `好的，請將悠遊卡靠近悠遊卡NFC感應區`; break;
        }
        /* 顧客選完發票方式，支付方式使用信用卡 */
        if(paymentMethod==="CreditCard"){
          newJsonAnswer = `好的，請將信用卡靠近感應掃描區`; break;
        }
        /* 顧客選完發票方式，支付方式使用街口支付 */
        if(paymentMethod==="JkoPay"){
          newJsonAnswer = `沒問題，請將街口支付付款條碼對準下方掃描區`; break;
        }
        /* 顧客要用現金付款 */
        if(paymentMethod==="Cash"){

          const hasOwnCup = orderDrink.map(d=>d.isOwnCup).filter(d=>d==="Y").length>0;
          /* 無環保杯 */
          if(!hasOwnCup){
            newJsonAnswer = `好的，請您領取收據後，先至櫃台結帳，謝謝您!`; break;
          } else {
            newJsonAnswer = `好的，請您領取收據後，先至櫃台結帳並將您的環保杯連同杯蓋拿給櫃檯人員，謝謝您!`; break;
          }
          
        }

        /* 不支援的支付方式 */
        if(paymentMethod==="WeChatPay"){
          newJsonAnswer = `抱歉，我們目前尚不支援微信支付`; break;
        }
        if(paymentMethod==="EzPay"){
          newJsonAnswer = `抱歉，我們目前尚不支援一卡通`; break;
        }


      } else if (isPaymentFailed==="Y"){

        /* 系統回覆付款失敗 */
        newJsonAnswer = `抱歉，付款失敗。請問您要再試一次還是要選擇另一種支付方式呢？`; 
        break;

      }
      /*---- 依據支付方式進行支付 ----*/



      /*---- 成功完成支付 ----*/
      if(receivedPaymentSuccessSignal==="Y"){

        /* 系統回覆付款成功，是預定訂單 */
        if(isPreOrder==="Y"){
          newJsonAnswer = `已完成付款囉! 謝謝您，發票已存入載具，請領取收據。我們會在預定時間前10至15分鐘開始為您製作飲品，請依照您的預訂時間${ formatDateTimeToChinese(preOrderTime) }，攜帶收據至櫃檯取餐，謝謝您!`; 
          break;
        }

        /* 系統回覆 LinePay/信用卡/街口付款成功 */
        if(paymentMethod==="LinePay" || paymentMethod==="Credit" || paymentMethod==="JkoPay"){
          newJsonAnswer = `已完成付款囉! 謝謝您，發票已存入載具，請領取收據。我們將開始為您製作飲品，請稍待片刻，依照收據上的編號取餐`; 
          break;
        }
        /* 系統回覆悠遊卡付款成功 */
        if(paymentMethod==="EzCard"){
          newJsonAnswer = `已完成付款囉! 謝謝您，發票已存入悠遊卡載具，請領取收據。我們將開始為您製作飲品，請稍待片刻後，依照收據上的編號取餐`; 
          break;
        }

      } else if(receivedPaymentSuccessSignal==="N"){

        
      }
      /*---- 成功完成支付 ----*/

      newJsonAnswer = `沒有對應的回答喔`;
      break;
    }

    /*---- Go To Query, TODO: this logic will be replaced using LLM ----*/
    if(goToQuery?.includes("有什麼推薦的嗎?")){ goToQueryType = '顧客詢問推薦飲料'; }
    if(goToQuery?.includes("柚子茶可以做熱的嗎?")){ goToQueryType = '顧客詢問飲料品項是否可做熱的'; }
    if(goToQuery?.includes("你們有賣牛奶嗎?")){ goToQueryType = '顧客詢問有賣牛奶嗎？'; }
    if(goToQuery?.includes("有適合小朋友喝的嗎?")){ goToQueryType = '顧客詢問有適合小朋友喝的嗎？'; }
    if(goToQuery?.includes("可以加料嗎?")){ goToQueryType = '顧客詢問飲料可以加料嗎？'; }
    if(goToQuery?.includes("可以用環保杯優惠嗎?")){ goToQueryType = '顧客問可以用環保杯嗎？'; }
    if(goToQuery?.includes("我想預約一個小時後再來領取可以嗎?")){ goToQueryType = '顧客詢問預約某個時間可以嗎？'; }
    if(goToQuery?.includes("什麼是8冰綠?")){ goToQueryType = '顧客詢問某種品項是什麼'; }
    if(goToQuery?.includes("珍珠跟波霸差在哪裡?")){ goToQueryType = '顧客詢問珍珠跟波霸的差別'; }
    if(goToQuery?.includes("可是我不喜歡喝酸的，有其他推薦嗎?")){ goToQueryType = '顧客問不喜歡酸的話推薦的飲料'; }
    if(goToQuery?.includes("料可以加多一點嗎?")){ goToQueryType = '顧客說配料要多一點'; }
    if(goToQuery?.includes("去冰有冰塊嗎?")){ goToQueryType = '顧客詢問去冰有冰塊嗎?'; }
    if(goToQuery?.includes("那你們奶茶有加牛奶嗎?")){ goToQueryType = '顧客詢問奶茶有加牛奶嗎?'; }
    if(goToQuery?.includes("有賣收費垃圾袋嗎?")){ goToQueryType = '顧客詢問有賣垃圾袋嗎?'; }
    if(goToQuery?.includes("現在有什麼優惠嗎?")){ goToQueryType = '顧客詢問目前優惠'; }
    if(goToQuery?.includes("大杯有優惠嗎?")){ goToQueryType = '顧客詢問大杯是否有優惠'; }
    if(goToQuery?.includes("買一送一冰塊甜度必須相同")){ goToQueryType = '顧客買一送一冰塊甜度不一致'; }
    if(goToQuery?.includes("什麼嘛? 優惠給得這麼不乾脆?")){ goToQueryType = '顧客表示優惠給得不乾脆'; }
    if(goToQuery?.includes("我對奶類過敏，有什麼飲料選擇呢?")){ goToQueryType = '對奶類過敏的飲料選擇'; }
    if(goToQuery?.includes("我對花生也過敏")){ goToQueryType = '顧客表示對花生過敏'; }
    if(goToQuery?.includes("蜂蜜會不會引起過敏")){ goToQueryType = '顧客詢問蜂蜜的過敏問題'; }
    if(goToQuery?.includes("麻煩飲料不要太甜")){ goToQueryType = '顧客說飲料不要太甜'; }
    if(goToQuery?.includes("塑膠袋是免費的嗎?")){ goToQueryType = '顧客詢問塑膠袋是否是免費的'; }
    if(goToQuery?.includes("啥支付方式都不支援，那你們支援啥?")){ goToQueryType = '顧客詢問支援的支付方式'; }
    if(goToQuery?.includes("你是誰?")){ goToQueryType = '顧客詢問虛擬助手是誰'; }
    if(goToQuery?.includes("跟你點和跟店員點有什麼差嗎?")){ goToQueryType = '顧客詢問跟虛擬助手點餐和跟店員點餐的差別'; }
    if(goToQuery?.includes("那我要怎麼點餐呢?")){ goToQueryType = '顧客詢問如何點餐'; }
    if(goToQuery?.includes("公司會支付你薪水嗎?")){ goToQueryType = '顧客詢問五十嵐是否支付虛擬店員薪水'; }
    if(goToQuery?.includes("我沒有領到收據。")){ goToQueryType = '顧客表示沒有領到收據'; }
    if(goToQuery?.includes("還是沒有收據")){ goToQueryType = '顧客再次反應沒有領到收據'; }
    if(goToQuery?.includes("我剛點的是半糖珍奶，但是拿到全糖的。")){ goToQueryType = '顧客反應拿到飲料品項錯誤'; }
    if(goToQuery?.includes("有賣吃的嗎?")){ goToQueryType = '顧客詢問有賣吃的嗎'; }
    if(goToQuery?.includes("你們有賣彩券嗎?")){ goToQueryType = '顧客詢問有賣吃的嗎'; }
    if(goToQuery?.includes("你們有在徵人嗎?")){ goToQueryType = '顧客詢問有在徵人嗎'; }
    if(goToQuery?.includes("車子停在紅線外面會被開單嗎?")){ goToQueryType = '顧客詢問車子停在紅線外面是否會被開單'; }
    
    if(goToQuery?.includes("你們布丁是真的嗎?")){ goToQueryType = '顧客詢問布丁是否是真的'; }
    if(goToQuery?.includes("冰淇淋紅茶去冰冰淇淋會不見嗎?")){ goToQueryType = '顧客詢問冰淇淋飲品去冰是否會去掉冰淇淋'; }
    if(goToQuery?.includes("烏龍茶新鮮嗎?")){ goToQueryType = '顧客詢問飲品是否新鮮'; }
    if(goToQuery?.includes("你們有賣烏龍綠嗎?")){ goToQueryType = '顧客詢問是否有販賣某種飲料品項'; }
    if(goToQuery?.includes("我不要吸管但你們有筷子嗎?")){ goToQueryType = '顧客詢問是否有提供筷子'; }
    if(goToQuery?.includes("我在隔壁飲料店買波霸奶茶，那個波霸很難吃耶")){ goToQueryType = '顧客抱怨其他店的飲品'; }
    if(goToQuery?.includes("有沒有什麼茶類喝起來沒有茶味?")){ goToQueryType = '顧客詢問是否有沒有茶味的茶類'; }
    if(goToQuery?.includes("50嵐是從什麼時候開始的?")){ goToQueryType = '顧客詢問 50 嵐的歷史'; }
      
    console.log('goToQueryType:', goToQueryType);

    // Switch goToQueryType
    if(goToQueryType==='顧客詢問推薦飲料'){ newJsonAnswer = '金桔檸檬綠是當季的期間限定，如果不想喝甜的，推薦您我們的招牌四季春青茶，想喝奶茶的話，可以試試看燕麥烏龍奶';}
    if(goToQueryType==='顧客詢問飲料品項是否可做熱的'){ newJsonAnswer = '可以的';}
    if(goToQueryType==='顧客詢問有賣牛奶嗎？'){ newJsonAnswer = '沒有賣牛奶，但我們有拿鐵系列，是牛奶奶泡加上茶的組合，您可以參考看看';}
    if(goToQueryType==='顧客詢問有適合小朋友喝的嗎？'){ newJsonAnswer = '推薦您我們的阿華田拿鐵，濃郁阿華田巧克力粉加入醇厚的牛奶，內含有豐富的蛋白質及醣類，非常適合小朋友飲用';}
    if(goToQueryType==='顧客詢問飲料可以加料嗎？'){ newJsonAnswer = '可以的，我們可以加珍珠、波霸、燕麥、椰果，每加一樣為10元，最多可加三種（三份）';}
    if(goToQueryType==='顧客問可以用環保杯嗎？'){ newJsonAnswer = '可以的，使用環保杯有折5元的折扣哦，謝謝您支持一起環保愛地球。大杯飲品容量為660ML，請確認您的環保杯容量哦';}
    if(goToQueryType==='顧客詢問預約某個時間可以嗎？'){ newJsonAnswer = '可以的，現在是下午1點26分，您要2點26分再來取，是嗎?';}
    if(goToQueryType==='顧客詢問某種品項是什麼'){ newJsonAnswer = '8冰綠是用綠茶為基底，加上金桔和梅醬，酸酸甜甜，喝起來十分消暑解渴，因為金桔與梅子兩顆果物上下擺在一起，很像是8的造型，故命名8冰綠，沒有喝過的朋友非常推薦';}
    if(goToQueryType==='顧客詢問珍珠跟波霸的差別'){ newJsonAnswer = '珍珠和波霸的差別主要在於大小，波霸直徑約1公分，口感較具咬勁，珍珠直徑約0.5公分，口感偏滑順彈性，如果您喜歡多種口感，不妨試試看混珠';}
    if(goToQueryType==='顧客問不喜歡酸的話推薦的飲料'){ newJsonAnswer = '這樣的話，推薦您我們的經典1號茶「四季春珍波椰」，四季春茶加上小珍珠、大波霸和椰果三種料，喝起來多層次又有咀嚼口感，是咀嚼系手搖飲控的最愛。';}
    if(goToQueryType==='顧客說配料要多一點'){ newJsonAnswer = '配料份量是固定的不能調整哦';}
    if(goToQueryType==='顧客詢問去冰有冰塊嗎?'){ newJsonAnswer = '去冰的話，是完全不放冰塊或小碎冰的';}
    if(goToQueryType==='顧客詢問奶茶有加牛奶嗎?'){ newJsonAnswer = '我們奶茶是用奶精粉，如果您需要牛奶的話，請改點拿鐵哦';}
    if(goToQueryType==='有賣收費垃圾袋嗎?'){ newJsonAnswer = '我們是提供適合杯裝的小塑膠袋，沒有販售收費垃圾袋。';}
    if(goToQueryType==='顧客詢問目前優惠'){ newJsonAnswer = '為了慶祝兒童節，本月有中杯以上珍珠奶茶買一送一的優惠，冰塊和甜度須一致，自備環保杯可以再折10元哦';}
    if(goToQueryType==='顧客詢問大杯是否有優惠'){ newJsonAnswer = '有的，您要購買一組大杯的買一送一優惠飲品嗎?';}
    if(goToQueryType==='顧客買一送一冰塊甜度不一致'){ newJsonAnswer = '抱歉，使用買一送一優惠，兩杯冰塊和甜度須一致哦';}
    if(goToQueryType==='顧客表示優惠給得不乾脆'){ newJsonAnswer = '很抱歉，這是優惠的規定，請您見諒';}
    if(goToQueryType==='對奶類過敏的飲料選擇'){ newJsonAnswer = '那麼請您選擇奶茶、拿鐵以外的飲品，才不會含有過敏原牛奶的成分';}
    if(goToQueryType==='我對花生也過敏'){ newJsonAnswer = '我們目前飲品均未添加花生，您可以安心選擇';}
    if(goToQueryType==='顧客詢問蜂蜜的過敏問題'){ newJsonAnswer = '雖然蜂蜜不在食品藥物管理署公告食品過敏原標示規定項目內，但您若曾對花粉與蜜蜂相關製品，例如蜂王乳、蜂膠，過敏的話，建議您避免食用';}
    if(goToQueryType==='顧客詢問塑膠袋是否是免費的'){ newJsonAnswer = '抱歉，根據政府規定，零售業者不得免費提供購物用塑膠袋，目前塑膠袋是一個 2 元。';}
    if(goToQueryType==='顧客詢問支援的支付方式'){ newJsonAnswer = '您可以使用LinePay、街口支付、悠遊付、悠遊卡、信用卡進行支付，我們也接受現金付款';}
    if(goToQueryType==='顧客詢問虛擬助手是誰'){ newJsonAnswer = '您好，我是50嵐的智慧店員，協助您進行點餐與付款的服務。';}
    if(goToQueryType==='顧客詢問跟虛擬助手點餐和跟店員點餐的差別'){ newJsonAnswer = '跟我點餐就像跟您的好朋友聊天一樣，我會一直在這裡親切地歡迎您！請告訴我您想喝什麼，我會盡力滿足您的需求。';}
    if(goToQueryType==='顧客詢問如何點餐'){ newJsonAnswer = '請看看菜單，告訴我您想喝什麼就可以';}
    if(goToQueryType==='顧客詢問五十嵐是否支付虛擬店員薪水'){ newJsonAnswer = '我是一個虛擬存在，薪水對我來說只是一個虛擬概念，但是，如果您對我服務感到滿意，您可以多多來找我點餐，我就會非常開心了!';}
    if(goToQueryType==='顧客表示沒有領到收據'){ newJsonAnswer = '我已經重新列印一份收據了。請問您有收到嗎？';}
    if(goToQueryType==='顧客再次反應沒有領到收據'){ newJsonAnswer = '非常抱歉，設備似乎故障。我已經通知店員前來處理。請您稍候片刻。這邊也提供您點餐號碼1234供您參考。造成您的不便，深感抱歉。';}
    if(goToQueryType==='顧客反應拿到飲料品項錯誤'){ newJsonAnswer = '非常抱歉造成您的困擾，請您洽詢櫃台人員，我們會立即為您處理。';}
    if(goToQueryType==='顧客詢問有賣吃的嗎'){ newJsonAnswer = '我們目前僅販售飲品為主哦!';}
    if(goToQueryType==='顧客詢問有賣彩券嗎'){ newJsonAnswer = '我們目前僅販售飲品哦!';}
    if(goToQueryType==='顧客詢問有在徵人嗎'){ newJsonAnswer = '這個資訊我並不清楚，請您洽詢櫃檯人員，謝謝!';}
    if(goToQueryType==='顧客詢問車子停在紅線外面是否會被開單'){ newJsonAnswer = '會哦!';}

    if(goToQueryType==='顧客詢問布丁是否是真的'){ newJsonAnswer = '我們放的是整顆統一布丁哦!';}
    if(goToQueryType==='顧客詢問冰淇淋飲品去冰是否會去掉冰淇淋'){ newJsonAnswer = '不會，去冰只會去掉紅茶內的冰塊，冰淇淋會完整保留給您';}
    if(goToQueryType==='顧客詢問飲品是否新鮮'){ newJsonAnswer = '絕對新鮮，我們茶品都是2小時內新鮮熬煮的哦';}
    if(goToQueryType==='顧客詢問是否有販賣某種飲料品項'){ newJsonAnswer = '抱歉，我們沒有販售這個品項哦!';}
    if(goToQueryType==='顧客詢問是否有提供筷子'){ newJsonAnswer = '抱歉，我們是販售飲品為主，只有提供吸管沒有筷子哦';}
    if(goToQueryType==='顧客抱怨其他店的飲品'){ newJsonAnswer = '這樣的話，您一定要試試我們店的波霸奶茶，我們的波霸Q彈有嚼勁，絕對不會讓您失望。';}
    if(goToQueryType==='顧客詢問是否有沒有茶味的茶類'){ newJsonAnswer = '那推薦您試試看果茶類，具有水果的香甜味道，茶的苦澀味會相對減少，像是我們的8冰茶、柚子茶都是很受歡迎的選擇';}
    if(goToQueryType==='顧客詢問 50 嵐的歷史'){ newJsonAnswer = '自1994年始於台南德興路路邊的一個小攤子，三年後，我們有了第一間標準店新孝店。而後，遇到一群同樣對茶飲有熱情的親朋好友加入，陸續於高雄、台中、台北、桃竹分別成立各區總店。這些年來我們只致力在台灣耕耘，並堅持只賣茶飲，用精選茶葉，搭配好的原料，新鮮現調每一杯茶飲。感謝每一位支援50嵐的客人一直以來對我們的愛護，我們將更努力，希望能提供更好的商品與服務給每一位客人。';}

    /*---- Go To Query Tail ----*/


    setJsonAnswer(newJsonAnswer);

  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="點餐" breadcrumbItem="資料測試" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    點餐資料測試
                  </CardTitle>

                  <Row>

                    {/*--- Prev Json ---*/}
                    <Col lg="5">
                      <p>Prev Json</p>
                      <textarea
                        className="form-control scrollable-div"
                        value={prevInput}
                        style={{ height: '50vh' }}
                        onChange={(e) => { setPrevInput(e.target.value)} }
                      />
                    </Col>

                    {/*--- This Json ---*/}
                    <Col lg="5">
                      <p>This Json</p>
                      <textarea
                        className="form-control scrollable-div"
                        value={textInput}
                        style={{ height: '50vh' }}
                        onChange={(e) => { setTextInput(e.target.value)} }
                      />
                    </Col>

                    <Col lg="2">
                      <p>資料分析</p>
                      <Button 
                        color="info"
                        onClick={() => { processJson(); } }
                        onChange={()=>{}}
                      >
                        分析 JSON
                      </Button>
                    </Col>

                  </Row>

                  <Row style={{ marginTop:'1rem' }}>
                    <Col lg="6">
                      <Input
                        id="jsonAnswer"
                        name="jsonAnswer"
                        type="text"
                        className="form-control"
                        placeholder="JSON 對應輸出"
                        value={jsonAnswer}
                        onChange={(e)=>{ setJsonAnswer(e.target.value); }}
                      />
                    </Col>
                  </Row>

                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>

  );
};

export default ProductCreate;
