import React, { useMemo, useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from "reactstrap";

const orderedKeys = ["masterquestion", "masterknowledge"];
const keyToLabelMapping = {
  masterquestion: "提問",
  masterknowledge: "參考資料內容"
};

/*---- Main Modal Component ----*/
const GeneratedSubknowledgeModal = ({ 
  isGenerateSubknowledgeModalOpen, 
  setIsGenerateSubknowledgeModalOpen, 
  textareaRefs,
  questionRefs,
  contentRefs,
  editingDataRef,
  generatedSubknowledges,
  setGeneratedSubknowledges,
  modalData, 
  setModalData,
  updateDataEntry,
}) => {  

  /*
  const addSubknowledge = () => {
    setGeneratedSubknowledges([
      ...generatedSubknowledges,
      { question: "", knowledgecontent: "" }
    ]);
  };
  */

  const deleteSubknowledge = (index) => {
    setGeneratedSubknowledges(generatedSubknowledges.filter((_, idx) => idx !== index));
  };

  const adjustHeight = (textarea) => {
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
      if (textarea.scrollHeight < 54) {
        textarea.style.height = '30px';
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      Object.values(textareaRefs.current).forEach(adjustHeight);
      Object.values(questionRefs.current).forEach(adjustHeight);
      Object.values(contentRefs.current).forEach(adjustHeight);
    }, 0); 
  }, [
    modalData, 
    generatedSubknowledges,
    isGenerateSubknowledgeModalOpen,
  ]);

  return (
    <React.Fragment>
      {/*---- Modal for editing dataentries ----*/}
      <Modal isOpen={isGenerateSubknowledgeModalOpen} toggle={() => setIsGenerateSubknowledgeModalOpen(!isGenerateSubknowledgeModalOpen)}>
        {/* Modal Header */}
        <ModalHeader toggle={() => setIsGenerateSubknowledgeModalOpen(!isGenerateSubknowledgeModalOpen)}>編輯智能客服參考資料</ModalHeader>
        {/* Modal Body */}
        <ModalBody>
          {/* 提問與參考資料內容 */}
          {orderedKeys.map((key) => (
            <div key={key} style={{ marginBottom: "15px" }}>
              <p>{keyToLabelMapping[key] || key}:</p>
              <textarea
                ref={(el) => { textareaRefs.current[key] = el; }}
                value={modalData[key]}
                onChange={(e) => {
                  setModalData((prevData) => ({
                    ...prevData,
                    [key]: e.target.value
                  }));
                  adjustHeight(e.target);
                }}
                style={{ width: "100%", resize: 'none', overflow: 'hidden' }}
                className="form-control"
                readOnly
              />
            </div>
          ))}

          {/* 分隔線 */}
          {generatedSubknowledges.length > 0 && (<div style={{ margin: "5vh 0vw", borderBottom: "1px solid #bdbdbd" }}></div>)}

          {/* 範例問答 */}
          {generatedSubknowledges.map((subknowledge, index) => (
            <Row key={`subknowledge_${index}`} style={{ marginBottom: "5vh" }}>
              <Col sm="10">
                <p style={{ marginBottom: '1.5vh' }}>{`AI 生成提問${index + 1}`}</p>
                <textarea
                  ref={(el) => { questionRefs.current[`question_${index}`] = el; }}
                  value={subknowledge.question}
                  onChange={(e) => {
                    const updatedSubknowledges = generatedSubknowledges.map((sk, idx) =>
                      idx === index ? { ...sk, question: e.target.value } : sk
                    );
                    setGeneratedSubknowledges(updatedSubknowledges);
                    adjustHeight(e.target);
                  }}
                  style={{ width: "100%", resize: 'none', overflow: 'hidden' }}
                  className="form-control"
                />
                <p style={{ margin: '2vh 0vh 1.5vh 0vh' }}>{`AI 生成回答${index + 1}`}</p>
                <textarea
                  ref={(el) => { contentRefs.current[`content_${index}`] = el; }}
                  value={subknowledge.knowledgecontent}
                  onChange={(e) => {
                    const updatedSubknowledges = generatedSubknowledges.map((sk, idx) =>
                      idx === index ? { ...sk, knowledgecontent: e.target.value } : sk
                    );
                    setGeneratedSubknowledges(updatedSubknowledges);
                    adjustHeight(e.target);
                  }}
                  style={{ width: "100%", resize: 'none', overflow: 'hidden' }}
                  className="form-control"
                />
              </Col>
              <Col xm="2"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Button color="outline-danger" onClick={() => deleteSubknowledge(index)}>-</Button>
              </Col>
            </Row>
          ))}
        </ModalBody>

        {/* Modal Footer */}
        <ModalFooter>
          <Row style={{ width:'100%' }}>
            <Col sm="12"
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button 
                color="primary" 
                onClick={() => {
                  updateDataEntry(editingDataRef.current.id, modalData);
                  //console.log('editingDataRef.current.id:', editingDataRef.current.id);
                  //setIsGenerateSubknowledgeModalOpen(false);
                }}
                style={{ marginRight:'1vw' }}
              >
                儲存
              </Button>
              <Button color="secondary" 
                onClick={() => {
                  setIsGenerateSubknowledgeModalOpen(false)}
                }
              >
                取消
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
  {/*---- Modal for editing dataentries Tail ----*/}

};

export default GeneratedSubknowledgeModal;