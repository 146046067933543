import { findItemName } from 'api/product';

export function findDifferences(obj1, obj2) {
    let diff = {};

    const keys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);
    keys.forEach(key => {
        if (obj1[key] && !obj2.hasOwnProperty(key)) {
            diff[key] = obj1[key]; // Key not found in second object
        } else if (obj2[key] && !obj1.hasOwnProperty(key)) {
            diff[key] = obj2[key]; // Key not found in first object
        } else if (obj1[key] && obj2[key] && JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
            if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
                // Special handling for arrays based on the key
                if (key === "orderDrink") {
                    let arrayDiff = compareArraysWithItemName(obj1[key], obj2[key]);
                    if (arrayDiff.length > 0) {
                        diff[key] = arrayDiff;
                    }
                } else {
                    // Regular array comparison
                    let arrayDiff = compareRegularArrays(obj1[key], obj2[key]);
                    if (arrayDiff.length > 0) {
                        diff[key] = arrayDiff;
                    }
                }
            } else if (obj1[key] instanceof Object && obj2[key] instanceof Object) {
                let nestedDiff = findDifferences(obj1[key], obj2[key]);
                if (Object.keys(nestedDiff).length > 0) {
                    diff[key] = nestedDiff;
                }
            } else {
                diff[key] = obj2[key]; // Directly different value
            }
        }
    });

    return diff;
}

function compareArraysWithItemName(array1, array2) {
    let diff = [];
    const maxLength = Math.max(array1.length, array2.length);

    for (let i = 0; i < maxLength; i++) {
        if (i < array1.length && i < array2.length) {
            const itemDiff = findDifferences(array1[i], array2[i]);
            if (Object.keys(itemDiff).length > 0) {
                if (!itemDiff.itemName) itemDiff.itemName = array1[i].itemName; // Include itemName if there are changes
                diff.push(itemDiff);
            }
        } else if (i < array1.length) {
            //diff.push(array1[i]); // Item removed from array2
        } else if (i < array2.length) {
            diff.push(array2[i]); // Item added to array2
        }
    }

    return diff;
}

function compareRegularArrays(array1, array2) {
    let diff = [];
    const unionSet = new Set([...array1, ...array2]);
    unionSet.forEach(item => {
        const inFirst = array1.includes(item);
        const inSecond = array2.includes(item);
        if (inFirst && !inSecond) {
            //diff.push(item);
        } else if (!inFirst && inSecond) {
            diff.push(item);
        }
    });
    return diff;
}

const sizeMap = {"S": "小杯", "M": "中杯", "L": "大杯"};
const iceMap = {
  "100": "正常冰",
  "70": "少冰",
  "40": "微冰",
  "0": "去冰",
  "R": "常溫",
  "W": "溫",
  "H": "熱"
};
const sugarMap = {
  "100": "正常糖",
  "70": "少糖",
  "50": "半糖",
  "25": "微糖",
  "0": "無糖"  
};
const quantityMap = {
  "1": "一杯", "2": "兩杯", "3": "三杯", "4": "四杯", "5": "五杯", "6": "六杯", "7": "七杯", "8": "八杯", 
  "9": "九杯", "10": "十杯",
  "11": "十一杯", "12": "十二杯", "13": "十三杯", "14": "十四杯", "15": "十五杯", "16": "十六杯", 
  "17": "十七杯", "18": "十八杯", "19": "十九杯", "20": "二十杯",
  "21": "二十一杯", "22": "二十二杯", "23": "二十三杯", "24": "二十四杯", "25": "二十五杯", "26": "二十六杯", 
  "27": "二十七杯", "28": "二十八杯", "29": "二十九杯", "30": "三十杯",
};
const quantityGroupMap = {
  "1": "一組", "2": "兩組", "3": "三組", "4": "四組", "5": "五組", "6": "六組", "7": "七組", "8": "八組",
  "9": "九組", "10": "十組",
  "11": "十一組", "12": "十二組", "13": "十三組", "14": "十四組", "15": "十五組", "16": "十六組", 
  "17": "十七組", "18": "十八組", "19": "十九組", "20": "二十組",
  "21": "二十一組", "22": "二十二組", "23": "二十三組", "24": "二十四組", "25": "二十五組", "26": "二十六組", 
  "27": "二十七組", "28": "二十八組", "29": "二十九組", "30": "三十組",
};
const toppingMap = {
  "boba": "波霸",
  "pearl": "珍珠",
  "oats": "燕麥",
  "coconutJelly": "椰果"
}

export const describeOrder = (orderDrink) => {

  let orderDescriptions = [];

  // Convert each drink order into a descriptive string
  for (let d in orderDrink){
    const { itemName, qtyDrink, sizeDrink, qtyIce, qtySugar, toppings, isOwnCup } = orderDrink[d];

    // Determine where to place the ice description
    let iceDescription = iceMap[qtyIce];
    let preItemIce = '';
    if (iceDescription === "熱" || iceDescription === "溫") {
      preItemIce = iceDescription;
      iceDescription = '';
    }

    // Check if the item is a 'Buy one get one free' type
    let quantityDescription = itemName.startsWith('買一送一') ? quantityGroupMap[qtyDrink] : quantityMap[qtyDrink];
    let description = `${quantityDescription}${sizeMap[sizeDrink] || '_'}${iceDescription}${sugarMap[qtySugar] || '_'}的${preItemIce}${itemName}`;

    // Add topping descriptions if any
    if (toppings && Object.keys(toppings).length > 0) {
      const toppingDescriptions = Object.entries(toppings).map(([key, value]) => `${value}份${toppingMap[key]}`).join("、");
      description += `，加${toppingDescriptions}`;
    }

    // Add own cup option if applicable
    if (isOwnCup === "Y") {
      description += "，使用自備環保杯";
    }

    orderDescriptions.push(description);
  }

  // Formatting the output string based on the number of descriptions
  let outputDescription = '';
  if (orderDescriptions.length === 2) {
    outputDescription = orderDescriptions.join('和');
  } else if (orderDescriptions.length > 2) {
    outputDescription = `${orderDescriptions.slice(0, -1).join('、')}，和${orderDescriptions[orderDescriptions.length - 1]}`;
  } else {
    outputDescription = orderDescriptions[0] || '';
  }

  return outputDescription;
}

export const isValidWHDrink = (itemName, qtyIce)=>{
  const invalidDrinkList = ['冰淇淋紅茶', '多多綠', '多多紅', '多多檸檬綠', '冰淇淋奶茶', '冰淇淋紅茶拿鐵', '檸檬汁',
    '檸檬多多', '蜂蜜紅', '蜂蜜綠', '蜂蜜奶茶', '鮮柚綠', '葡萄柚汁', '葡萄柚蜜', '葡萄柚多多', '檸檬蜜', '金桔檸檬蜜'];
  if(invalidDrinkList.includes(itemName)){return false;}
  return true;
}

export const formatDateTimeToChinese = (dateTimeStr) => {
  const monthNames = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];
  const dateTime = new Date(dateTimeStr.replace(' ', 'T'));
  const now = new Date();

  const year = dateTime.getFullYear();
  const month = dateTime.getMonth();
  const date = dateTime.getDate();
  const hours = dateTime.getHours();

  // Calculate the day difference
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const dateToCompare = new Date(year, month, date);
  const diffTime = dateToCompare - today;
  const diffDays = diffTime / (1000 * 60 * 60 * 24);

  // Determine the prefix (today, tomorrow, or date)
  let dayPrefix;
  if (diffDays === 0) {
    dayPrefix = '今天';
  } else if (diffDays === 1) {
    dayPrefix = '明天';
  } else {
    dayPrefix = `${month + 1}月${date}日`;
  }

  // Determine morning or afternoon
  let timeSuffix = hours >= 12 ? '下午' : '上午';
  let hourIn12HourFormat = hours % 12 || 12;  // Convert 0 to 12 for 12-hour time format

  return `${dayPrefix}${timeSuffix}${hourIn12HourFormat}點`;
}

export const validateToppings = (toppings) => {
  const validToppings = ['boba', 'pearl', 'coconutJelly', 'oat'];
  console.log('toppings:', toppings);
  let toppingSum = 0;
  for (let topping in toppings) {
    if (!validToppings.includes(topping)) {
      console.log(`Invalid topping found: ${topping}`);
      return false;
    }
    toppingSum += toppings[topping];
  }
  if (toppingSum > 3) {
    console.log(`Topping sum exceeds the allowed maximum: ${toppingSum}`);
    return false;
  }
  return true;
}

const drinkMapping = {
  '阿薩姆紅茶':['紅茶', '阿薩姆'],
  '黃金烏龍':['烏龍', '烏龍茶', '黃金烏龍茶'],
  '珍珠奶茶':['珍奶'],
  '四季春珍波椰':['珍波椰四季春'],
  '燕麥紅茶拿鐵':['燕麥拿鐵']
}

export const validateItemName = async (itemName) =>{

  console.log('VALIDATING itemName:', itemName);
  let options = [];
  const { message, itemName:name } = await findItemName(itemName);

  if(message==='Found product'){
    return {validItemName:true, message, options};
  } else {

    for (let d in drinkMapping){
      if(drinkMapping[d].includes(itemName)){
        options.push(d);
      }
    }
    console.log(`ALTERNATE OPTIONS for ${itemName}:`, options);

    return {validItemName:false, message, options};
  }

}

export const isValidItemName = async (itemName)=>{return await validateItemName(itemName);}
export const isValidQtyDrink = (qtyDrink)=>{return (qtyDrink>0 && qtyDrink<30);}
export const isValidQtyIce = (qtyIce)=>{return (qtyIce==='100' || qtyIce==='70' || qtyIce==='40' || qtyIce==='0' || qtyIce==='W' || qtyIce==='H');}
export const isValidQtySugar = (qtySugar)=>{return (qtySugar==='100' || qtySugar==='70' || qtySugar==='50' || qtySugar==='25' || qtySugar==='0');}
export const isValidSizeDrink = (sizeDrink)=>{return (sizeDrink==='M' || sizeDrink==='L');}
export const isValidToppings = (toppings)=>{return validateToppings(toppings);}

/*---- Check if orderDrink is complete for an order ----*/
export const isCompleteOrderDrink = async (orderDrink) =>{
  let orderDrinkCheck = 'ok';
  console.log('orderDrink:', orderDrink);
  let k = 0;
  
  for (k in orderDrink){
    let thisOrderDrinkError = [], alternateDrinkList = [];
    const { isOwnCup, itemName, qtyDrink, qtyIce, qtySugar, sizeDrink, toppings } = orderDrink[k];
    console.log(`#${k}`, isOwnCup, itemName, qtyDrink, qtyIce, qtySugar, sizeDrink, toppings);

    while(1){
      const {validItemName, message, options} = await isValidItemName(itemName);
      alternateDrinkList = options;
      /* 顧客給的品項名稱不對 */
      if(!validItemName){ thisOrderDrinkError.push('Invalid Item Name'); }
      if(!isValidQtyDrink(qtyDrink)){ thisOrderDrinkError.push('Invalid QtyDrink'); }

      if(qtyIce==='W' || qtyIce==='H'){
        if(!isValidWHDrink(itemName, qtyIce)){ thisOrderDrinkError.push(`Invalid Warm/Hot Drink`); }
      }
      if(!isValidQtyIce(qtyIce)){ thisOrderDrinkError.push('Invalid QtyIce'); }

      if(!isValidQtySugar(qtySugar)){ thisOrderDrinkError.push('Invalid QtySugar'); }
      if(!isValidSizeDrink(sizeDrink)){ thisOrderDrinkError.push('Invalid SizeDrink'); }
      if(!isValidToppings(toppings)){ thisOrderDrinkError = 'Invalid Toppings'; }

      break;
    }

    console.log('thisOrderDrinkError:', thisOrderDrinkError);

    while(1){


      /*--- Only One Error ----*/
      if(thisOrderDrinkError.includes(`Invalid Item Name`)){
        if(alternateDrinkList.length>0){
          orderDrinkCheck = `Order#${k} is Invalid Item Name, try>>${alternateDrinkList[0]}`;
        } else {
          orderDrinkCheck = `Order#${k} is Invalid Item Name`;
        }
        break;
      }
      if(thisOrderDrinkError.includes(`Invalid Warm/Hot Drink`)){
        orderDrinkCheck = `Order#${k} can not be made Warm/Hot Drink`;
        break;
      }
      if(thisOrderDrinkError.includes(`Invalid Toppings`)){
        orderDrinkCheck = `Order#${k} has invalid toppings`;
        break;
      }
      if(thisOrderDrinkError.length===1){

        if(thisOrderDrinkError.includes(`Invalid SizeDrink`)){
          orderDrinkCheck = `Order#${k} has Invalid SizeDrink`;
          break;
        } else if(thisOrderDrinkError.includes(`Invalid QtySugar`)){
          orderDrinkCheck = `Order#${k} has Invalid QtySugar`;
          break;
        } else {
          orderDrinkCheck = thisOrderDrinkError[0]; 
          break;
        }
      }
      /*--- Only One Error ----*/


      if(thisOrderDrinkError.length===2){
        if(thisOrderDrinkError.includes('Invalid QtyIce') && thisOrderDrinkError.includes('Invalid QtySugar')){
          orderDrinkCheck = `Order#${k} has Invalid QtyIce and QtySugar`;
          break;
        }
        if(thisOrderDrinkError.includes('Invalid SizeDrink') && thisOrderDrinkError.includes('Invalid QtySugar')){
          orderDrinkCheck = `Order#${k} has Invalid SizeDrink and QtySugar`;
          break;
        }
      }
      if(thisOrderDrinkError.length===3){
        if(thisOrderDrinkError.includes('Invalid QtyIce') && thisOrderDrinkError.includes('Invalid QtySugar')){
          orderDrinkCheck = `Order#${k} has Invalid SizeDrink, QtyIce and QtySugar`;
          break;
        }
      }
      break;
    }

    if(orderDrinkCheck!=='ok'){break;}
    else {continue;}
  }

  return orderDrinkCheck;
}

export const mapGoToQueryType = (goToQuery) => {
  const queryMap = [
    { regex: /有什麼推薦的[嗎?？]/, type: '顧客詢問推薦飲料' },
    { regex: /柚子茶可以做熱的[嗎?？]/, type: '顧客詢問飲料品項是否可做熱的' },
    { regex: /你們有賣牛奶[嗎?？]/, type: '顧客詢問有賣牛奶嗎？' },
    { regex: /有適合小朋友喝的[嗎?？]/, type: '顧客詢問有適合小朋友喝的嗎？' },
    { regex: /可以加料[嗎?？]/, type: '顧客詢問飲料可以加料嗎？' },
    { regex: /可以用環保杯優惠[嗎?？]/, type: '顧客問可以用環保杯嗎？' },
    { regex: /我想預約一個小時後再來領取可以[嗎?？]/, type: '顧客詢問預約某個時間可以嗎？' },
    { regex: /什麼是8冰綠[嗎?？]/, type: '顧客詢問8冰綠是什麼' },
    { regex: /那8冰茶[呢?？]/, type: '顧客詢問8冰茶是什麼' },
    { regex: /珍珠跟波霸差在哪裡[嗎?？]/, type: '顧客詢問珍珠跟波霸的差別' },
    { regex: /可是我不喜歡喝酸的，有其他推薦[嗎?？]/, type: '顧客問不喜歡酸的話推薦的飲料' },
    { regex: /料可以加多一點[嗎?？]/, type: '顧客說配料要多一點' },
    { regex: /去冰有冰塊[嗎?？]/, type: '顧客詢問去冰有冰塊嗎' },
    { regex: /那你們奶茶有加牛奶[嗎?？]/, type: '顧客詢問奶茶有加牛奶嗎' },
    { regex: /有賣收費垃圾袋[嗎?？]/, type: '顧客詢問有賣垃圾袋嗎' },
    { regex: /現在有什麼優惠[嗎?？]/, type: '顧客詢問目前優惠' },
    { regex: /大杯有優惠[嗎?？]/, type: '顧客詢問大杯是否有優惠' },
    { regex: /買一送一冰塊甜度必須相同/, type: '顧客買一送一冰塊甜度不一致' },
    { regex: /什麼嘛[?？] 優惠給得這麼不乾脆[嗎?？]/, type: '顧客表示優惠給得不乾脆' },
    { regex: /我對奶類過敏，有什麼飲料選擇呢[?？]/, type: '對奶類過敏的飲料選擇' },
    { regex: /我對花生也過敏/, type: '顧客表示對花生過敏' },
    { regex: /蜂蜜會不會引起過敏[?？]/, type: '顧客詢問蜂蜜的過敏問題' },
    { regex: /麻煩飲料不要太甜/, type: '顧客說飲料不要太甜' },
    { regex: /塑膠袋是免費的[嗎?？]/, type: '顧客詢問塑膠袋是否是免費的' },
    { regex: /啥支付方式都不支援，那你們支援啥[?？]/, type: '顧客詢問支援的支付方式' },
    { regex: /你是誰/, type: '顧客詢問虛擬助手是誰' },
    { regex: /跟你點和跟店員點有什麼差[嗎?？]/, type: '顧客詢問跟虛擬助手點餐和跟店員點餐的差別' },
    { regex: /那我要怎麼點餐呢[?？]/, type: '顧客詢問如何點餐' },
    { regex: /公司會支付你薪水[嗎?？]/, type: '顧客詢問五十嵐是否支付虛擬店員薪水' },
    { regex: /我沒有領到收據。/, type: '顧客表示沒有領到收據' },
    { regex: /還是沒有收據/, type: '顧客再次反應沒有領到收據' },
    { regex: /我剛點的是半糖珍奶，但是拿到全糖的。/, type: '顧客反應拿到飲料品項錯誤' },
    { regex: /有賣吃的[嗎?？]/, type: '顧客詢問有賣吃的嗎' },
    { regex: /你們有賣彩券[嗎?？]/, type: '顧客詢問有賣彩券嗎' },
    { regex: /你們有在徵人[嗎?？]/, type: '顧客詢問有在徵人嗎' },
    { regex: /車子停在紅線外面會被開單[嗎?？]/, type: '顧客詢問車子停在紅線外面是否會被開單' },
    // Add more mappings similarly
    { regex: /你們布丁是真的[嗎?？]/, type: '顧客詢問布丁是否是真的' },
    { regex: /冰淇淋紅茶去冰冰淇淋會不見[嗎?？]/, type: '顧客詢問冰淇淋飲品去冰是否會去掉冰淇淋' },
    { regex: /烏龍茶新鮮[?？]/, type: '顧客詢問飲品是否新鮮' },
    { regex: /你們有賣烏龍綠[?？]/, type: '顧客詢問是否有販賣某種飲料品項' },
    { regex: /我不要吸管但你們有筷子[?？]/, type: '顧客詢問是否有提供筷子' },
    { regex: /我在隔壁飲料店買波霸奶茶，那個波霸很難吃耶[?？]/, type: '顧客抱怨其他店的飲品' },
    { regex: /有沒有什麼茶類喝起來沒有茶味[?？]/, type: '顧客詢問是否有沒有茶味的茶類' },
    { regex: /50嵐是從什麼時候開始的[?？]/, type: '顧客詢問 50 嵐的歷史' },

    { regex: /可以用街口支付[嗎?？]/, type: '顧客詢問支援的支付方式' }
  ];

  for (const { regex, type } of queryMap) {
    if (goToQuery && regex.test(goToQuery)) {
      return type;
    }
  }

  return ''; // Return empty string if no match is found
}

function printCurrentTime() {
  // Create a new Date object for the current time
  let now = new Date();

  // Get the hours and minutes
  let hours = now.getHours();
  let minutes = now.getMinutes();

  // Determine AM or PM and adjust hours for 12-hour format
  let period = '上午';
  if (hours >= 12) {
    period = '下午';
    if (hours > 12) hours -= 12; // Convert to 12-hour format
  }
  // Format minutes to always be two digits
  let formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
  return `${period}${hours}點${formattedMinutes}分`;
}

export const mapGoToQueryAnswer = (goToQueryType)=>{

  let newJsonAnswer = '';

  // Switch goToQueryType
  if(goToQueryType==='顧客詢問推薦飲料'){ newJsonAnswer = '金桔檸檬綠是當季的期間限定，如果不想喝甜的，推薦您我們的招牌四季春青茶，想喝奶茶的話，可以試試看燕麥烏龍奶';}
  if(goToQueryType==='顧客詢問飲料品項是否可做熱的'){ newJsonAnswer = '可以的';}
  if(goToQueryType==='顧客詢問有賣牛奶嗎？'){ newJsonAnswer = '沒有賣牛奶，但我們有拿鐵系列，是牛奶奶泡加上茶的組合，您可以參考看看';}
  if(goToQueryType==='顧客詢問有適合小朋友喝的嗎？'){ newJsonAnswer = '推薦您我們的阿華田拿鐵，濃郁阿華田巧克力粉加入醇厚的牛奶，內含有豐富的蛋白質及醣類，非常適合小朋友飲用';}
  if(goToQueryType==='顧客詢問飲料可以加料嗎？'){ newJsonAnswer = '可以的，我們可以加珍珠、波霸、燕麥、椰果，每加一樣為10元，最多可加三種（三份）';}
  if(goToQueryType==='顧客問可以用環保杯嗎？'){ newJsonAnswer = '可以的，使用環保杯有折5元的折扣哦，謝謝您支持一起環保愛地球。大杯飲品容量為660ML，請確認您的環保杯容量哦';}
  if(goToQueryType==='顧客詢問預約某個時間可以嗎？'){ newJsonAnswer = `可以的，現在是${printCurrentTime()}，您要幾點來取呢?`;}
  if(goToQueryType==='顧客指定預約時間'){ newJsonAnswer = `沒問題，幫您設定好預約時間囉，請問要點些什麼呢？`;}
  if(goToQueryType==='顧客詢問8冰綠是什麼'){ 
    newJsonAnswer = '8冰綠是用綠茶為基底，加上金桔和梅醬，酸酸甜甜，喝起來十分消暑解渴，因為金桔與梅子兩顆果物上下擺在一起，很像是8的造型，故命名8冰綠，沒有喝過的朋友非常推薦';
  }
  if(goToQueryType==='顧客詢問8冰茶是什麼'){ 
    newJsonAnswer = '8冰茶是將8冰綠的綠茶基底換成水，因此喝起來會比8冰綠更酸一點，但因為沒有咖啡因，小孩或孕婦也可以飲用哦';
  }
  if(goToQueryType==='顧客詢問珍珠跟波霸的差別'){ newJsonAnswer = '珍珠和波霸的差別主要在於大小，波霸直徑約1公分，口感較具咬勁，珍珠直徑約0.5公分，口感偏滑順彈性，如果您喜歡多種口感，不妨試試看混珠';}
  if(goToQueryType==='顧客問不喜歡酸的話推薦的飲料'){ newJsonAnswer = '這樣的話，推薦您我們的經典1號茶「四季春珍波椰」，四季春茶加上小珍珠、大波霸和椰果三種料，喝起來多層次又有咀嚼口感，是咀嚼系手搖飲控的最愛。';}
  if(goToQueryType==='顧客說配料要多一點'){ newJsonAnswer = '配料份量是固定的不能調整哦';}
  if(goToQueryType==='顧客詢問去冰有冰塊嗎?'){ newJsonAnswer = '去冰的話，是完全不放冰塊或小碎冰的';}
  if(goToQueryType==='顧客詢問奶茶有加牛奶嗎?'){ newJsonAnswer = '我們奶茶是用奶精粉，如果您需要牛奶的話，請改點拿鐵哦';}
  if(goToQueryType==='顧客詢問有賣垃圾袋嗎?'){ newJsonAnswer = '我們是提供適合杯裝的小塑膠袋，沒有販售收費垃圾袋。';}
  if(goToQueryType==='顧客詢問目前優惠'){ newJsonAnswer = '為了慶祝兒童節，本月有中杯以上珍珠奶茶買一送一的優惠，冰塊和甜度須一致，自備環保杯可以再折10元哦';}
  if(goToQueryType==='顧客詢問大杯是否有優惠'){ newJsonAnswer = '有的，您要購買一組大杯的買一送一珍珠奶茶嗎?';}
  if(goToQueryType==='顧客買一送一冰塊甜度不一致'){ newJsonAnswer = '抱歉，使用買一送一優惠，兩杯冰塊和甜度須一致哦';}
  if(goToQueryType==='顧客表示優惠給得不乾脆'){ newJsonAnswer = '很抱歉，這是優惠的規定，請您見諒';}
  if(goToQueryType==='對奶類過敏的飲料選擇'){ newJsonAnswer = '那麼請您選擇奶茶、拿鐵以外的飲品，才不會含有過敏原牛奶的成分';}
  if(goToQueryType==='顧客表示對花生過敏'){ newJsonAnswer = '我們目前飲品均未添加花生，您可以安心選擇';}
  if(goToQueryType==='顧客詢問蜂蜜的過敏問題'){ newJsonAnswer = '雖然蜂蜜不在食品藥物管理署公告食品過敏原標示規定項目內，但您若曾對花粉與蜜蜂相關製品，例如蜂王乳、蜂膠，過敏的話，建議您避免食用';}
  if(goToQueryType==='顧客詢問塑膠袋是否是免費的'){ newJsonAnswer = '抱歉，根據政府規定，零售業者不得免費提供購物用塑膠袋，目前塑膠袋是一個 2 元。';}
  if(goToQueryType==='顧客詢問支援的支付方式'){ newJsonAnswer = '您可以使用LinePay、街口支付、悠遊付、悠遊卡、信用卡進行支付，我們也接受現金付款';}
  if(goToQueryType==='顧客詢問虛擬助手是誰'){ newJsonAnswer = '您好，我是50嵐的智慧店員，協助您進行點餐與付款的服務。';}
  if(goToQueryType==='顧客詢問跟虛擬助手點餐和跟店員點餐的差別'){ newJsonAnswer = '跟我點餐就像跟您的好朋友聊天一樣，我會一直在這裡親切地歡迎您！請告訴我您想喝什麼，我會盡力滿足您的需求。';}
  if(goToQueryType==='顧客詢問如何點餐'){ newJsonAnswer = '請看看菜單，告訴我您想喝什麼就可以';}
  if(goToQueryType==='顧客詢問五十嵐是否支付虛擬店員薪水'){ newJsonAnswer = '我是一個虛擬存在，薪水對我來說只是一個虛擬概念，但是，如果您對我服務感到滿意，您可以多多來找我點餐，我就會非常開心了!';}
  if(goToQueryType==='顧客表示沒有領到收據'){ newJsonAnswer = '我已經重新列印一份收據了。請問您有收到嗎？';}
  if(goToQueryType==='顧客再次反應沒有領到收據'){ newJsonAnswer = '非常抱歉，設備似乎故障。我已經通知店員前來處理。請您稍候片刻。這邊也提供您點餐號碼1234供您參考。造成您的不便，深感抱歉 #對話結束。';}
  if(goToQueryType==='顧客反應拿到飲料品項錯誤'){ newJsonAnswer = '非常抱歉造成您的困擾，請您洽詢櫃台人員，我們會立即為您處理。';}
  if(goToQueryType==='顧客詢問有賣吃的嗎'){ newJsonAnswer = '我們目前僅販售飲品為主哦!';}
  if(goToQueryType==='顧客詢問有賣彩券嗎'){ newJsonAnswer = '我們目前僅販售飲品哦!';}
  if(goToQueryType==='顧客詢問有在徵人嗎'){ newJsonAnswer = '這個資訊我並不清楚，請您洽詢櫃檯人員，謝謝!';}
  if(goToQueryType==='顧客詢問車子停在紅線外面是否會被開單'){ newJsonAnswer = '會哦!';}

  if(goToQueryType==='顧客詢問布丁是否是真的'){ newJsonAnswer = '我們放的是整顆統一布丁哦!';}
  if(goToQueryType==='顧客詢問冰淇淋飲品去冰是否會去掉冰淇淋'){ newJsonAnswer = '不會，去冰只會去掉紅茶內的冰塊，冰淇淋會完整保留給您';}
  if(goToQueryType==='顧客詢問飲品是否新鮮'){ newJsonAnswer = '絕對新鮮，我們茶品都是2小時內新鮮熬煮的哦';}
  if(goToQueryType==='顧客詢問是否有販賣某種飲料品項'){ newJsonAnswer = '抱歉，我們沒有販售這個品項哦!';}
  if(goToQueryType==='顧客詢問是否有提供筷子'){ newJsonAnswer = '抱歉，我們是販售飲品為主，只有提供吸管沒有筷子哦';}
  if(goToQueryType==='顧客抱怨其他店的飲品'){ newJsonAnswer = '這樣的話，您一定要試試我們店的波霸奶茶，我們的波霸Q彈有嚼勁，絕對不會讓您失望。';}
  if(goToQueryType==='顧客詢問是否有沒有茶味的茶類'){ newJsonAnswer = '那推薦您試試看果茶類，具有水果的香甜味道，茶的苦澀味會相對減少，像是我們的8冰茶、柚子茶都是很受歡迎的選擇';}
  if(goToQueryType==='顧客詢問 50 嵐的歷史'){ newJsonAnswer = '自1994年始於台南德興路路邊的一個小攤子，三年後，我們有了第一間標準店新孝店。而後，遇到一群同樣對茶飲有熱情的親朋好友加入，陸續於高雄、台中、台北、桃竹分別成立各區總店。這些年來我們只致力在台灣耕耘，並堅持只賣茶飲，用精選茶葉，搭配好的原料，新鮮現調每一杯茶飲。感謝每一位支援50嵐的客人一直以來對我們的愛護，我們將更努力，希望能提供更好的商品與服務給每一位客人。';}

  const goToQueryAnswer = newJsonAnswer;
  return goToQueryAnswer;

}


