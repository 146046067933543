import {
  SET_CHATBOTS,
  SET_CHATBOT_IMAGE,

  /*---- Load Chatbot Image ----*/
  LOAD_CHATBOT_IMAGES,
  LOAD_CHATBOT_IMAGES_SUCCESS,
  LOAD_CHATBOT_IMAGES_FAIL,

  GET_CHATBOTS,
  GET_CHATBOTS_SUCCESS,
  GET_CHATBOTS_FAIL,

  ADD_NEW_CHATBOT,
  ADD_CHATBOT_SUCCESS,
  ADD_CHATBOT_FAIL,

  DELETE_CHATBOT,
  DELETE_CHATBOT_SUCCESS,
  DELETE_CHATBOT_FAIL,

  UPDATE_CHATBOT,
  UPDATE_CHATBOT_SUCCESS,
  UPDATE_CHATBOT_FAIL,
} from "./actionTypes";


/*---- Set Chatbots ----*/

export const setChatbots = (chatbots) => ({
  type: SET_CHATBOTS,
  payload: chatbots,
});

export const setChatbotImage = (cbId, imgUrl) => ({
  type: SET_CHATBOT_IMAGE,
  payload: { cbId, imgUrl }
});

/*---- Set Chatbots Tail ----*/




/*---- Load Chatbot Image ----*/

export const loadChatbotImages = (chatbots) => ({
  type: LOAD_CHATBOT_IMAGES,
  payload: chatbots,
});

export const loadChatbotImagesSuccess = (images) => ({
  type: LOAD_CHATBOT_IMAGES_SUCCESS,
  payload: images,
});

export const loadChatbotImagesFail = (error) => ({
  type: LOAD_CHATBOT_IMAGES_FAIL,
  payload: error,
});

/*---- Load Chatbot Image Tail ----*/




/*---- Get Chatbots ----*/

export const getChatbots = () => ({
  type: GET_CHATBOTS,
});

export const getChatbotsSuccess = chatbots => ({
  type: GET_CHATBOTS_SUCCESS,
  payload: chatbots,
});

export const getChatbotsFail = error => ({
  type: GET_CHATBOTS_FAIL,
  payload: error,
});

/*---- Get Chatbots Tail ----*/



/*---- Add Chatbot ----*/

export const addNewChatbot = chatbot => ({
  type: ADD_NEW_CHATBOT,
  payload: chatbot,
})

export const addChatbotSuccess = newChatbot => ({
  type: ADD_CHATBOT_SUCCESS,
  payload: newChatbot,
})

export const addChatbotFail = error => ({
  type: ADD_CHATBOT_FAIL,
  payload: error,
})

/*---- Add Chatbot Tail ----*/



/*---- Delete Chatbot ----*/

export const deleteChatbot = chatbotId => ({
  type: DELETE_CHATBOT,
  payload: chatbotId,
})

export const deleteChatbotSuccess = chatbotId => ({
  type: DELETE_CHATBOT_SUCCESS,
  payload: chatbotId,
})

export const deleteChatbotFail = error => ({
  type: DELETE_CHATBOT_FAIL,
  payload: error,
})

/*---- Delete Chatbot Tail ----*/



/*---- Update Chatbot ----*/

export const updateChatbot = chatbot => ({
  type: UPDATE_CHATBOT,
  payload: chatbot,
})

export const updateChatbotSuccess = chatbot => ({
  type: UPDATE_CHATBOT_SUCCESS,
  payload: chatbot,
})

export const updateChatbotFail = error => ({
  type: UPDATE_CHATBOT_FAIL,
  payload: error,
})

/*---- Update Chatbot Tail ----*/


