import {
  CLEAR_REGISTER_USER,
  CLEAR_REGISTER_USER_SUCCESSFUL,
  CLEAR_REGISTER_USER_FAILED,
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  VERIFY_ACCOUNT_REQUEST,
  VERIFY_ACCOUNT_SUCCESS,
  VERIFY_ACCOUNT_FAILURE,
} from "./actionTypes";

export const clearRegisterUser = user => {
  return {
    type: CLEAR_REGISTER_USER,
  }
}

export const clearRegisterUserSuccessful = user => {
  return {
    type: CLEAR_REGISTER_USER_SUCCESSFUL,
  }
}

export const clearRegisterUserFailed = error => {
  return {
    type: CLEAR_REGISTER_USER_FAILED,
    payload: error,
  }
}

export const registerUser = user => {
  return {
    type: REGISTER_USER,
    payload: { user },
  }
}

export const registerUserSuccessful = response => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: response,
  }
}

export const registerUserFailed = error => {
  return {
    type: REGISTER_USER_FAILED,
    payload: error,
  }
}

export const verifyAccountRequest = (token) => ({
  type: VERIFY_ACCOUNT_REQUEST,
  payload: token,
});

export const verifyAccountSuccess = (data) => ({
  type: VERIFY_ACCOUNT_SUCCESS,
  payload: data,
});

export const verifyAccountFailure = (error) => ({
  type: VERIFY_ACCOUNT_FAILURE,
  payload: error,
});



