import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Alert,
  Button,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

// Custom Axios
import axiosInstance from 'utils/axiosInstance';

// Custom Modals
import CreateModal from './createModal';
import UrlListModal from './urlListModal';

// Custom Utils
import { Pagination } from 'utils/pagination';

import {
  addNewChatbot as onAddNewChatbot,
} from "store/actions";

// Redux
import { useSelector, useDispatch } from "react-redux";

// uuid
import { v4 as uuidv4 } from 'uuid';

// CSS
import './qa-create.css'

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

function parseQAPairs(qaPairsText) {
  // Split the entire text into individual QA pairs
  const qaPairsRaw = qaPairsText.split('\n\n').filter(Boolean);

  // Map over each raw QA pair to further split it into question and answer
  const qaPairs = qaPairsRaw.map(pairText => {
    const [question, answer] = pairText.replace('Q: ', '').split(/\n?\s*A:/);
    return { question, answer };
  });

  return qaPairs;
}

const urlList = {
  '2024 G Camp 國際創新創業訓練營':`https://cmm.ai/gcamp/`, 
  '緯創創新育成':`https://www.wistron.com/ch/AboutWistron/Innovation&Incubation`,
};

/*---- Main Function ----*/
const QACreate = () => {


  /*---- Title and Initial Setup ----*/

  document.title = "自動生成問答集 | TheChart.AI";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  /*---- Title and Initial Setup ----*/

  const [url, setUrl] = useState('https://ec.ltn.com.tw/article/paper/1636012');
  //const [selector, setSelector] = useState('div.text');
  const [content, setContent] = useState('');
  const [paragraph, setParagraph] = useState('');
  const [qaPairsText, setQaPairsText] = useState('');
  const [websiteLoading, setWebsiteLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [qaPairs, setQaPairs] = useState([]);
  const [userWarning, setUserWarning] = useState('');
  const [selectorDictionary, setSelectorDictionary] = useState({});
  const [selectedSelector, setSelectedSelector] = useState('請先抓取網頁資料');
  
  useEffect(()=>{
    const pairs = parseQAPairs(qaPairsText);
    console.log('pairs:', pairs);
    setQaPairs(pairs);
  },[qaPairsText]);

  const [errorMessage, setErrorMessage] = useState('');

  const { error, loading, chatbotAddSuccess } = useSelector(state => ({
    error: state.chatbots.error,
    loading: state.chatbots.loading,
    chatbotAddSuccess: state.chatbots.chatbotAddSuccess,
  }));

  useEffect(() => {
    if (chatbotAddSuccess) {
      dispatch({ type: 'RESET_CHATBOT_ADD_SUCCESS' });
      navigate('/chatbot-menu');
    }
  }, [chatbotAddSuccess, navigate]);


  const fetchSelector = async (e) => {
    e.preventDefault();
    setSelectorDictionary({});
    try {
      const response = await axiosInstance.post('knovia/fetch-selector', { url });
      console.log('selector fetched:', Object.keys(response.data.selectorDictionary));
      setSelectorDictionary(response.data.selectorDictionary);
      setSelectedSelector(Object.keys(response.data.selectorDictionary)[0]);
    } catch (error) {
      console.error('Error fetching selector:', error);
    }
  };

  /*
  const fetchContent = async (e) => {
    e.preventDefault();
    try {
      setWebsiteLoading(true);
      const response = await axiosInstance.post('knovia/fetch-webpage', { url, selector });
      setContent(response.data.content);
      setWebsiteLoading(false);
    } catch (error) {
      console.error('Error fetching content:', error);
    }
  };
  */

  const generateQAPairs = async (e) => {
    e.preventDefault();
    try {
      setDataLoading(true);
      const sendContent = createMode==='webpage' ? selectorDictionary[selectedSelector] : paragraph;
      const response = await axiosInstance.post('knovia/get-qapairs', { sendContent });
      setQaPairsText(response.data.qaPairs);
      setUserWarning('');
      setDataLoading(false);
    } catch (error) {
      console.error('Error generating QA pairs:', error);
      setUserWarning('未完成 QA 集生成，請再試一次');
      setDataLoading(false);
    }
  };


  /*---- Modal Creating a Chatbot ----*/

  // State for Create Modal Open or Close
  const [createModal, setCreateModal] = useState(false);

  // Open and close the Create modal
  const toggle = () => {
    if (createModal) {
      setCreateModal(false);
    } else {
      setCreateModal(true);
    }
  };

  /*---- Modal Creating a Chatbot Tail ----*/

  const [urlListModal, setUrlListModal] = useState(false);
  // Open and close the UrlListModal
  const toggleUrlListModal = () => {
    if (urlListModal) {
      setUrlListModal(false);
    } else {
      setUrlListModal(true);
    }
  };


  const [createMode, setCreateMode] = useState('webpage');


  return (
    <React.Fragment>

      <CreateModal 
        createModal={createModal} 
        toggle={toggle}
        qaPairs={qaPairs}
        onAddNewChatbot={onAddNewChatbot}
        url={url}
        selectedSelector={selectedSelector}
        selectorDictionary={selectorDictionary}
      />

      <UrlListModal 
        urlListModal={urlListModal} 
        toggleUrlListModal={toggleUrlListModal}
        urlList={urlList}
      />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="智能客服" breadcrumbItem="自動生成問答集" />

          <Row>

            <Col lg="12">

              <Row>
                <Col sm="6">
                  <div style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    margin: '0.5rem 0rem 1rem 0rem',
                  }}>
                    <Button color="info" 
                      style={{ 
                        marginRight: '0.5rem' 
                      }}
                      onClick={()=>{setCreateMode('webpage')}}
                    >從網頁生成</Button>
                    <Button 
                      color="warning"
                      onClick={()=>{setCreateMode('manual')}}
                    >貼上段落文字</Button>
                  </div>
                </Col>
                <Col>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    margin: '0.5rem 0rem 1rem 0rem',
                  }}>
                    <Button color="outline-info" onClick={() => setUrlListModal(!urlListModal)}>
                      參考url
                    </Button>
                  </div>
                </Col>
              </Row>

            </Col>

            
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">生成問答集</CardTitle>

                  {userWarning ? <Alert className="mt-4" color="danger">{userWarning}</Alert> : null}

                  <Form>

                    {createMode==='webpage' ? (

                      <FormGroup>

                        {/* https://startup.sme.gov.tw/home/modules/news/detial.php?sId=1848 */}
                        <Row style={{ marginBottom: '0.5rem' }}>
                          <Col sm="2"
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              alignContent: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Label for="url-input" style={{ margin:"0" }}>URL</Label>
                          </Col>
                          <Col sm="10"
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              alignContent: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Input
                              id="url-input"
                              type="text"
                              placeholder="Enter URL"
                              value={url}
                              onChange={(e) => setUrl(e.target.value)}
                            />
                          </Col>
                        </Row>

                        {/* div.news-detial */}
                        <Row style={{ marginBottom: '0.5rem' }}>
                          <Col sm="2"
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              alignContent: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Label for="selector-input" style={{ margin:"0" }}>Selector</Label>
                          </Col>


                          <Col sm="10"
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              alignContent: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            {/*
                            <Input
                              id="selector-input"
                              type="text"
                              placeholder="CSS Selector"
                              value={selector}
                              onChange={(e) => setSelector(e.target.value)}
                            />
                            */}

                            <UncontrolledDropdown style={{ display:"flex", justifyContent:"center", width: '100%' }}>
                              {/* Drop down for edit actions */}
                              <DropdownToggle onClick={(e)=>{e.preventDefault();}}
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  border: '1px solid #ced4da',
                                  background: 'white',
                                  color: '#495057',
                                }}
                              >
                                {selectedSelector || '請先抓取網頁資料'}
                              </DropdownToggle>

                              {Object.keys(selectorDictionary).length!==0 &&
                                <DropdownMenu className="dropdown-menu" 
                                  style={{
                                    maxHeight: '40vh',
                                    overflow: 'scroll',
                                  }}
                                >
                                  {/* 檢視參考資料 */}
                                  {Object.keys(selectorDictionary).map((selector, index) => (
                                    <DropdownItem key={index} onClick={() => {setSelectedSelector(Object.keys(selectorDictionary)[index])}} >
                                      {selector}
                                    </DropdownItem>
                                  ))}
                                  
                                </DropdownMenu>
                              }
                            </UncontrolledDropdown>

                          </Col>


                          {/*
                          <Col sm="2"
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              alignContent: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Button 
                              color="outline-warning"
                              onClick={(e)=>{fetchSelector(e);}}
                              style={{    
                                width: 'fit-content',
                                minWidth: '3rem',
                              }}
                            >生成 Selector</Button>
                          </Col>
                          */}
                        </Row>

                        <Row style={{ display: 'flex', justifyContent: 'center' }}>
                          <Button 
                            color="outline-info"
                            onClick={(e)=>{fetchSelector(e);}}
                            style={{    
                              width: 'fit-content',
                              minWidth: '10rem',
                            }}
                          > 抓取網頁資料
                            {websiteLoading &&
                              (<div style={{ display:'inline-block', marginLeft:'0.3rem' }}>
                                <i className="bx bx-loader bx-spin font-size-16 align-middle"></i>
                              </div>)
                            }
                          </Button>
                        </Row>

                      </FormGroup>
                    ) : (
                      <>
                        <p>直接在下方貼上要用來生成問答集的段落文字：</p>
                        <textarea
                          className="form-control"
                          type="text"
                          style={{
                            minHeight: '15rem',
                            resize: 'none',
                            color: 'rgb(95, 95, 103)',
                            overflowY: 'scroll',
                            border: '1px solid darkgrey',
                            padding: '1rem 2rem',
                          }}
                          value={paragraph}
                          onChange={(e)=>{setParagraph(e.target.value);}}
                        />
                      </>
                    )}


                    {createMode==='webpage' && selectorDictionary[selectedSelector] && (
                      <>
                        <textarea 
                          className="form-control"
                          readOnly
                          type="text"
                          style={{
                            minHeight: '15rem',
                            resize: 'none',
                            color: 'rgb(95, 95, 103)',
                            overflowY: 'scroll',
                            border: '1px solid darkgrey',
                            padding: '1rem 2rem',
                          }}
                          value={selectorDictionary[selectedSelector] || ''}
                          rows={10}
                          cols={50}
                        />

                        <Row style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem', marginBottom: '1rem' }}>
                          <Button 
                            color="outline-warning"
                            onClick={(e)=>{generateQAPairs(e);}}
                            style={{    
                              width: 'fit-content',
                              minWidth: '10rem',
                            }}
                          >
                            生成問答集
                            {dataLoading &&
                              (<div style={{ display:'inline-block', marginLeft:'0.3rem' }}>
                                <i className="bx bx-loader bx-spin font-size-16 align-middle"></i>
                              </div>)
                            }
                          </Button>
                        </Row>
                        <Row style={{ display: 'flex', justifyContent: 'center' }}>
                          {dataLoading && <p style={{ width: 'fit-content', marginBottom:'0rem', color: '#c65555' }}>這可能需要幾分鐘...</p>}
                        </Row>

                      </>
                    )}

                    {createMode==='manual' && paragraph && (
                      <>
                        <Row style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem', marginBottom: '1rem' }}>
                          <Button 
                            color="outline-warning"
                            onClick={(e)=>{generateQAPairs(e);}}
                            style={{    
                              width: 'fit-content',
                              minWidth: '10rem',
                            }}
                          >
                            生成問答集
                            {dataLoading &&
                              (<div style={{ display:'inline-block', marginLeft:'0.3rem' }}>
                                <i className="bx bx-loader bx-spin font-size-16 align-middle"></i>
                              </div>)
                            }
                          </Button>
                        </Row>
                        <Row style={{ display: 'flex', justifyContent: 'center' }}>
                          {dataLoading && <p style={{ width: 'fit-content', marginBottom:'0rem', color: '#c65555' }}>這可能需要幾分鐘...</p>}
                        </Row>

                      </>
                    )}

                    <div 
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {qaPairs.map((pair, index) => (
                        <Row key={index} className="qa-pair" style={{ padding: '1rem 0.5rem', marginBottom: '0rem' }}>
                          <Col sm="1" 
                            style={{ 
                              display:'flex', 
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <div style={{ fontWeight: 'bold' }}>{index + 1}.</div>
                          </Col>
                          <Col sm="11">
                            <div style={{ marginBottom: '0.2rem' }}>Q: {pair.question}</div>
                            <div>A: {pair.answer}</div>
                          </Col>
                        </Row>
                      ))}

                      {qaPairs?.length>0 && 
                        (<div 
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin: '1rem 0rem',
                          }}
                        >
                          <Button color="info" onClick={() => setCreateModal(!createModal)}>
                            新增智能客服
                          </Button>
                        </div>)
                      }

                    </div>
                  
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
};


export default QACreate;
