import React, { useState, useEffect } from "react";
import {
	Row,
	Col,
	Button,
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  Input,
  UncontrolledTooltip
} from "reactstrap";
import { Link } from "react-router-dom";

const LinebotModal = props => {

  /*---- Initial States ----*/
  const { isLinebotModalOpen, setIsLinebotModalOpen, saveLinebotInfo, chatbotId, thisCb } = props;
  const [channelName, setChannelName] = useState('');
	const [channelSecret, setChannelSecret] = useState('');
	const [accessToken, setAccessToken] = useState('');

	useEffect(()=>{
		if(thisCb?.channelName){setChannelName(thisCb.channelName);}
		if(thisCb?.channelSecret){setChannelSecret(thisCb.channelSecret);}
		if(thisCb?.channelAccessToken){setAccessToken(thisCb.channelAccessToken);}
	},[thisCb]);

	// Return Component
  return (
    <React.Fragment>

	    {/*---- Modal for editing dataentries ----*/}
	    <Modal 
	    	isOpen={isLinebotModalOpen} 
	    	toggle={() => setIsLinebotModalOpen(!isLinebotModalOpen)}
	    	style={{
	    		minWidth: '65vw',
	    	}}
	    >
	      {/* Modal Header */}
	      <ModalHeader toggle={() => setIsLinebotModalOpen(!isLinebotModalOpen)}>串接 Linebot</ModalHeader>
	      {/* Modal Body */}
	      <ModalBody>

	      	<Row style={{ marginBottom:'0.5rem' }}>
	      		<p style={{ margin:'0' }}>
	      			設定 Linebot 網址：
	      			<a style={{ marginLeft: '0.3rem' }} 
	      				href="https://developers.line.biz/"
	      				target="_blank"
          			rel="noreferrer"
	      			>https://developers.line.biz/
	      			</a>
	      		</p>
	      	</Row>

	      	<div style={{
	      		borderBottom: '1px solid #adadad',
    				margin: '1.5rem 4rem 2rem 4rem',
	      	}}></div>

	      	<Row style={{ marginBottom:'0.5rem' }}>

	      		<Col sm="3" 
	      			style={{
	      				display: 'flex',
    						alignItems: 'center'
	      			}}
	      		>
		          <p style={{ margin:'0' }}>Channel name</p>
		          {/*---- Info Icon ----*/}
              <Link 
              	to="#" 
              	id={"info_provider_name"}
                style={{ marginLeft: '0.5rem' }}
              >
                <i className="mdi mdi-help-circle-outline font-size-20 text-secondary me-1"></i>
                <UncontrolledTooltip
                  placement="top"
                  target={"info_provider_name"}
                  style={{minWidth:'15rem'}}
                >
                  {`https://developers.line.biz/console/ -> Providers -> Channels`}
                </UncontrolledTooltip>
              </Link>
              {/*---- Info Icon Tail ----*/}
		        </Col>
		          
		        <Col sm="9">
		          <Input
		            value={channelName}
		            onChange={(e) => {setChannelName(e.target.value)}}
		            placeholder={`本欄僅供紀錄使用，無論是否設定皆可運作`}
		            className="form-control"
		          />
		        </Col>
		      </Row>

	      	<Row style={{ marginBottom:'0.5rem' }}>

	      		<Col sm="3" 
	      			style={{
	      				display: 'flex',
    						alignItems: 'center'
	      			}}
	      		>
		          <p style={{ margin:'0' }}>Channel secret</p>
		          {/*---- Info Icon ----*/}
              <Link 
              	to="#" 
              	id={"info_channel_secret"}
                style={{ marginLeft: '0.5rem' }}
              >
                <i className="mdi mdi-help-circle-outline font-size-20 text-secondary me-1"></i>
                <UncontrolledTooltip
                  placement="top"
                  target={"info_channel_secret"}
                  style={{minWidth:'15rem'}}
                >
                  {`https://developers.line.biz/console/ -> Basic settings -> Channel secret`}
                </UncontrolledTooltip>
              </Link>
              {/*---- Info Icon Tail ----*/}
		        </Col>
		          
		        <Col sm="9">
		          <Input
		            value={channelSecret}
		            onChange={(e) => {setChannelSecret(e.target.value)}}
		            placeholder={`還沒有設定 Channel secret`}
		            className="form-control"
		          />
		        </Col>
		      </Row>

		      <Row style={{ marginBottom:'0.5rem' }}>
		        <Col sm="3"
		        	style={{
	      				display: 'flex',
    						alignItems: 'center'
	      			}}
		        >
		          <p style={{ margin:'0' }}>Channel access token</p>
		          {/*---- Info Icon ----*/}
              <Link 
              	to="#" 
              	id={"info_access_token"}
                style={{ marginLeft: '0.5rem' }}
              >
                <i className="mdi mdi-help-circle-outline font-size-20 text-secondary me-1"></i>
                <UncontrolledTooltip
                  placement="top"
                  target={"info_access_token"}
                  style={{minWidth:'15rem'}}
                >
                  {`https://developers.line.biz/console/ -> Messaging API -> Channel access token`}
                </UncontrolledTooltip>
              </Link>
              {/*---- Info Icon Tail ----*/}
		        </Col>
		        <Col sm="9">
		          <Input
		            value={accessToken}
		            onChange={(e) => {setAccessToken(e.target.value)}}
		            placeholder={`還沒有設定 Channel access token`}
		            className="form-control"
		          />
		        </Col>
          </Row>

          <Row style={{ marginBottom:'1.5rem' }}>
		        <Col sm="3"
		        	style={{
	      				display: 'flex',
    						alignItems: 'center'
	      			}}
		        >
		          <p style={{ margin:'0' }}>Webhook URL</p>
		          {/*---- Info Icon ----*/}
              <Link 
              	to="#" 
              	id={"info_webhook_url"}
                style={{ marginLeft: '0.5rem' }}
              >
                <i className="mdi mdi-help-circle-outline font-size-20 text-secondary me-1"></i>
                <UncontrolledTooltip
                  placement="top"
                  target={"info_webhook_url"}
                  style={{minWidth:'15rem'}}
                >
                  {`https://developers.line.biz/console/ -> Messaging API -> Webhook URL -> Edit`}
                </UncontrolledTooltip>
              </Link>
              {/*---- Info Icon Tail ----*/}
		        </Col>
		        <Col sm="9">
		          <Input
		          	style={{ background: '#eff2f7' }}
		          	value={`https://backend.thechart.ai/knovia/linebot/${chatbotId}`}
		            onChange={(e) => {}}
		            className="form-control"
		            readOnly
		          />
		        </Col>
          </Row>

          <div style={{
	      		borderBottom: '1px solid #adadad',
    				margin: '2rem 4rem 1.5rem 4rem',
	      	}}></div>

          <Row>
          	<Col sm="12">
          		<p style={{ marginLeft:'0.5rem', marginBottom:'0.2rem' }}>操作說明請見此網址（第一次操作學習時間約 12 分鐘）：</p>
          		<a style={{ marginLeft:'0.5rem', marginBottom:'0' }}
          			href="https://drive.google.com/drive/folders/13CFTku6DngO9C2nelVA_vhFrlUvoJcB6?usp=sharing"
          			target="_blank"
          			rel="noreferrer"
          		>
          			https://drive.google.com/drive/folders/13CFTku6DngO9C2nelVA_vhFrlUvoJcB6?usp=sharing
          		</a>
          	</Col>
          </Row>

	      </ModalBody>

	      {/* Modal Footer */}
	      <ModalFooter>
          <Button 
            color="primary" 
            onClick={() => { 
            	setIsLinebotModalOpen(false);
            	saveLinebotInfo(channelName, channelSecret, accessToken);
            }}
          >
            儲存 Linebot 串接資訊
          </Button>
          <Button color="secondary" onClick={() => setIsLinebotModalOpen(false)}>取消變更</Button>
	      </ModalFooter>
	    </Modal>

    {/*---- Modal for editing dataentries Tail ----*/}
    </React.Fragment>
  );
};

export default LinebotModal;

