import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider';
import getCroppedImg from './cropImage';

import { 
  Alert,
  Button,
  Card, 
  CardBody, 
  CardTitle, 
  Col, 
  Container, 
  Form, 
  FormGroup, 
  Input, 
  Label,
  Row 
} from "reactstrap";

// Import Components
import Breadcrumbs from "components/Common/Breadcrumb";

import Img1 from 'assets/images/small/img-4.jpg';
import placeholder from 'assets/images/placeholders/placeholder_card.png';


const ImageCrop = ({ classes }) => {


  /*---- States and setups ----*/

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);

  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  /*---- States and setups Tail ----*/


  /*---- Image Upload ----*/

  const [uploadedImage, setUploadedImage] = useState(null);

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.addEventListener('load', () => setUploadedImage(reader.result));
      reader.readAsDataURL(file);
    }
  };

  /*---- Image Upload Tail ----*/



  /*---- Change in Slides ----*/

  /* Change in Zoom Slide */
  const handleZoomChange = (event) => {
    setZoom(event.target.value);
  };
  /* Change in Rotation Slide */
  const handleRotationChange = (event) => {
    setRotation(event.target.value);
  };

  /*---- Change in Slides Tail ----*/


  /*---- Show crop result ----*/

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(uploadedImage, croppedAreaPixels, rotation);
      console.log('done', { croppedImage });
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }

  /*---- Show crop result Tail ----*/


  const onClose = () => { setCroppedImage(null); }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="資料庫" breadcrumbItem="建立資料庫" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">圖片剪裁</CardTitle>

                    {/* File Input for Image Upload */}
                    <FormGroup>
                      <Label for="imageUpload">上傳圖片</Label>
                      <Input type="file" name="file" id="imageUpload" onChange={handleFileChange} />
                    </FormGroup>

                    {/* Cropper Component */}
                    {uploadedImage && (

                      <div
                        style={{
                          position: 'relative',
                          width: '100%',
                          height: '400px',
                          background: '#333',
                        }}
                      >
                        <Cropper
                          image={uploadedImage}
                          crop={crop}
                          rotation={rotation}
                          zoom={zoom}
                          aspect={4 / 3}
                          onCropChange={setCrop}
                          onRotationChange={setRotation}
                          onCropComplete={onCropComplete}
                          onZoomChange={setZoom}
                        />
                      </div>
                    )}

                    {/* Slides */}
                    <div style={{ padding: '16px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                      {/* Zoom Slide */}
                      <div 
                        style={{
                          display: 'flex',
                          flex: '1',
                          alignItems: 'center',
                        }}
                      >
                        <div className="mt-4">
                          <h5 className="font-size-14">Zoom</h5>
                          <input
                            value={zoom}
                            onChange={handleZoomChange}
                            type="range" 
                            className="form-range" 
                            step="0.1" 
                            min="1" 
                            max="3" 
                            id="customRangeZoom" 
                          />
                        </div>
                      </div>


                      {/* Rotation Slide */}
                      <div 
                        style={{
                          display: 'flex',
                          flex: '1',
                          alignItems: 'center',
                        }}
                      >
                        <div className="mt-4">
                          <h5 className="font-size-14">Rotation</h5>
                          <input
                            value={rotation}
                            onChange={handleRotationChange}
                            type="range" 
                            className="form-range" 
                            step="1" 
                            min="0" 
                            max="360" 
                            id="customRangeRotate" 
                          />
                        </div>
                      </div>


                      <Button
                        onClick={showCroppedImage}
                        variant="contained"
                        color="primary"
                        style={{
                          flexShrink: "0",
                          marginLeft: "16px",
                        }}
                      >
                        Show Result
                      </Button>
                    
                    </div>

                    <img 
                      src={croppedImage || placeholder}
                      style={{
                        height:"10rem"
                      }}
                    />

                </CardBody>
              </Card>
            </Col>
          </Row>


        </Container>
      </div>
    </React.Fragment>
  )
}

export default ImageCrop;
