import React, { useEffect, useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { isEmpty } from "lodash";
import { nanoid } from "nanoid";

import {
  Button,
  Card,
  CardBody,
} from "reactstrap";
import { getOrders as onGetOrders } from "store/actions";

// Other Details Modal
import EcommerceOrdersModal from "../Ecommerce/EcommerceOrders/EcommerceOrdersModal";

import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
  Rating,
  TimeStamp,
} from "./LatestTransactionCol";

import MemoizedTableContainer from "../../components/Common/TableContainer";

const LatestTransaction = props => {

  const { interactionList } = props;

  // For Other Details Modal
  const [modal1, setModal1] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null); // New state variable to hold the data for the selected row

  //console.log("Checking data", interactionList);

  const latestTransaction = useMemo(() => interactionList.map((interaction) => ({
    interactId: (interaction.interactId || "706-219-4095"),
    interactQuestion: interaction.question,
    interactResponseBytelingo: interaction.responseBytelingo,
    interactResponseSimple: interaction.responseSimple,
    rating: interaction.rating || "5.0",
    apiCost: (interaction.apiCost
      ?interaction.apiCost
      :Number((interaction.question.length+interaction.responseBytelingo.length)/1000*1.2*0.002*30))
        .toFixed(4),
    timeStamp: interaction.createdAt,
    otherDetail: (interaction.otherDetail?interaction.otherDetail:"-"),
  })), [interactionList]);

  const handleRowClick = useCallback((rowData) => {
    setSelectedRowData(rowData);
  }, []);

  const toggleViewModal = () => setModal1(!modal1);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        filterable: false,
        disableFilters: true,
        width: 50,
        Cell: cellProps => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "翻譯文本",
        accessor: "interactQuestion",
        disableFilters: true,
        filterable: false,
        width: 250,
        Cell: cellProps => {
          return <BillingName {...cellProps} />;
        },
      },
      {
        Header: "生成結果(TheChart)",
        accessor: "interactResponseBytelingo",
        disableFilters: true,
        filterable: false,
        width: 400,
        Cell: cellProps => {
          return <Total {...cellProps} />;
        },
      },
      {
        Header: "生成結果(ChatGPT)",
        accessor: "interactResponseSimple",
        disableFilters: true,
        filterable: false,
        width: 400,
        Cell: cellProps => {
          return <Total {...cellProps} />;
        },
      },
      {
        Header: "API 計費",
        accessor: "apiCost",
        disableFilters: true,
        filterable: false,
        width: 50,
        Cell: cellProps => {
          return <PaymentStatus {...cellProps} />;
        },
      },
      {
        Header: "時間標記",
        accessor: "timeStamp",
        disableFilters: true,
        width: 50,
        Cell: cellProps => {
          return <TimeStamp {...cellProps} />;
        },
      },
      {
        Header: "其他",
        accessor: "otherDetail",
        disableFilters: true,
        width: 100,
        Cell: cellProps => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={toggleViewModal}
            >
              檢視細節
            </Button>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      {/* 問答細節："../Ecommerce/EcommerceOrders/EcommerceOrdersModal" */}
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} selectedRowData={selectedRowData}/>
      <Card>
        <CardBody>
          <div className="mb-4 h4 card-title">翻譯紀錄</div>
            <MemoizedTableContainer
              columns={columns}
              data={latestTransaction}
              isGlobalFilter={true}
              isAddOptions={false}
              customPageSize={5}
              onRowClick={handleRowClick}
            />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

LatestTransaction.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default withRouter(LatestTransaction);
