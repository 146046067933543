import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  UncontrolledTooltip
} from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

//import Charts
import StackedColumnChart from "./StackedColumnChart";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

// redux
import { useSelector, useDispatch } from "react-redux";
import { getChartsData as onGetChartsData } from "../../store/actions";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyEarning";
import SocialSource from "./SocialSource";
import ActivityComp from "./ActivityComp";
import TopCities from "./TopCities";
import LatestTransaction from "./LatestTransaction";

import classNames from "classnames";

// Main Dashboard Component
const Dashboard = props => {


  /*---- Title and Initial Setup ----*/

  document.title = "TheChart.AI 功能主頁";
  const userData = useSelector(state => state.user.userData);
  // useEffect(() => { dispatch({ type: 'FETCH_USER_DATA' }) }, []);再fetch一次userData
  useEffect(() => { console.log('userData in Dashboard:', userData) }, [userData]);

  /*---- Title and Initial Setup ----*/

  /*---- Temp ----*/
  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData
  }));

  const reports = [
    { title: `本月{${(userData?.isPremiumUser ? '專業版' : '試用版' )}}可翻譯字數`, info: "每月初會按照方案補充，使用AI翻譯時會優先扣除", iconClass: "bx-purchase-tag-alt", description: `${(userData?.stats?.wordThisMonth || 0)}`},
    { title: "可建立專案數", info: "可建立專案數由訂閱方案決定，超過時，需要先刪除舊專案才能建立新專案", iconClass: "bx-briefcase-alt-2", description: (userData?.isPremiumUser ? 100 : 10) },
    { title: "與我分享的專案數量", info: "與我分享的專案不會佔用可建立專案數", iconClass: "bx-share-alt", description: (userData?.sharedProjects?.length || 0) },
    { title: "同時參考詞彙組數", info: `同時參考詞彙組數是指專案當中「編輯參考資料庫視窗內」左下角的「總共參考詞組數」，超過時會無法增加參考資料庫`, iconClass: "bx-hdd", description: `${(userData?.isPremiumUser ? 12000 : 6000 )} 組` },
    { title: "額外購買點數", info: `額外購買點數會在方案免費字數使用完後開始扣除，一點對應一個原文字`, iconClass: "bx-dollar-circle", description: `${(userData?.stats?.pointsLeft || 0)} 點` },
  ];

  const [periodData, setPeriodData] = useState([]);
  const [periodType, setPeriodType] = useState("yearly");

  useEffect(() => {
    setPeriodData(chartsData);
  }, [chartsData]);

  const onChangeChartPeriod = pType => {
    setPeriodType(pType);
    dispatch(onGetChartsData(pType));
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onGetChartsData("yearly"));
  }, [dispatch]);

  /*---- Temp ----*/


  return (

    <React.Fragment>

      <div className="page-content">
        <Container fluid>

          {/* Render Breadcrumb */}
          <Breadcrumbs title={props.t("TheChart.AI")} breadcrumbItem={props.t(`功能主頁`)} />

          <Row>

            <Col xl="4">
              <WelcomeComp userData={userData} />
              <MonthlyEarning />
              {/*<AboutTheChart />*/}
            </Col>

            <Col xl="8">

              <Row>
                {/* Reports Render */}
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody style={{ paddingLeft: '2rem' }}>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                          {/*---- Info Icon ----*/}
                          <Link to="#" id={"info_" + key}
                            style={{
                              position: 'absolute',
                              top: '0.9rem',
                              left: '0.5rem',
                            }}
                          >
                            <i className="mdi mdi-help-circle-outline font-size-20 text-secondary me-1"></i>
                            <UncontrolledTooltip
                              placement="top"
                              target={"info_" + key}
                            >
                              {report?.info || '資訊'}
                            </UncontrolledTooltip>
                          </Link>
                          {/*---- Info Icon Tail ----*/}

                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>

              <ActivityComp/>

              {/* Email Sent Card */}
              {/*
              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">Email Sent</h4>
                    <div className="ms-auto">
                      <ul className="nav nav-pills">
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "weekly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("weekly");
                            }}
                            id="one_month"
                          >
                            Week
                          </Link>{" "}
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "monthly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("monthly");
                            }}
                            id="one_month"
                          >
                            Month
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "yearly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("yearly");
                            }}
                            id="one_month"
                          >
                            Year
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                  <StackedColumnChart periodData={periodData} dataColors='["--bs-primary", "--bs-warning", "--bs-success"]' />
                </CardBody>
              </Card>
              */}


            </Col>

          </Row>

        </Container>
      </div>

    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Dashboard);
