import React, { useMemo } from 'react';
import { 
  Row,
  Col
} from "reactstrap";
import DiffMatchPatch from 'diff-match-patch';

const textDiff = (text1, text2) => {
    //console.log('text1', text1);
    //console.log('text2', text2);
    const dmp = new DiffMatchPatch();
    const diffs = dmp.diff_main(text1, text2);
    dmp.diff_cleanupSemantic(diffs);

    const diffElementsArray = diffs.map((part, index) => {
      // Determine the class based on the diff type
      const className = part[0] === 1 ? 'positive' : part[0] === -1 ? 'negative' : '';

      // Wrap the text in a span with the appropriate class, if any
      return <span key={index} className={className}>{part[0] === -1 ? `(-${part[1]})` : part[1]}</span>;
    });

    return diffElementsArray;
  }

// Assuming this is a new component or part of your existing component
const SearchResultItem = ({ select123, paragraph, similarity, glossaryName, secondLang, showDiffText }) => {
  const diffText = useMemo(() => textDiff(select123, paragraph), [select123, paragraph]);

  return (
    <div>
      <p style={{ marginBottom: "0.5rem", fontWeight: "600" }}>
        {`[${similarity}]`}
        <span style={{ marginLeft: "0.5rem" }}>{glossaryName}</span>
      </p>
      {showDiffText ?
        <>{diffText}</> :
        <p style={{ marginBottom: "0rem" }}>{`${paragraph}`}</p>
      }
      <p style={{ margin: "0px", marginTop: "0.5rem" }}>{`=> ${secondLang}`}</p>
      {/* Divider */}
      <Row>
        <Col sm="12"><div style={{ margin: "1rem 0rem", borderBottom: "1px solid #bdbdbd" }}></div></Col>
      </Row>
    </div>
  );
};

export default SearchResultItem;

