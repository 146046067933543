import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Alert,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter, 
  Input
} from "reactstrap";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";

import * as XLSX from 'xlsx';

import './custom.css';

import {
  Date,
  Masterknowledge,
  Masterquestion,
  SubknowledgeCol,
  TimeStamp,
} from "./LatestTransactionCol";

import axiosInstance from "utils/axiosInstance";

import TableContainer from "./TableContainer";
import ChatbotImgTab from "./ChatbotImgTab";
import './custom.css';

import { dateGen } from 'utils/toolUtils';
import { isValidImage, fetchImage, uploadImg } from 'utils/imageUtils';

import qrPlaceholder from 'assets/images/placeholders/placeholder_qrcode.png';

// Custom Modals
import PromptModal from './PromptModal';
import OutputExcelModal from './OutputExcelModal';
import LinebotModal from './LinebotModal';
import KnowledgeEditModal from './KnowledgeEditModal';
import GeneratedSubknowledgeModal from './GeneratedSubknowledgeModal';
import AddSubknowledgeModal from './AddSubknowledgeModal';
import EditSubknowledgeModal from './EditSubknowledgeModal';
import UploadExcel from './UploadExcel';

import block_pressed from 'assets/images/layouts/block_pressed.png';
import block_unpressed from 'assets/images/layouts/block_unpressed.png';
import list_pressed from 'assets/images/layouts/list_pressed.png';
import list_unpressed from 'assets/images/layouts/list_unpressed.png';

function writeDataToExcel(filteredData, filename) {
    // Create a new workbook
    const workbook = xlsx.utils.book_new();

    // Process each item in filteredData
    filteredData.forEach((data, index) => {
        // Prepare the data for the sheet
        const sheetData = [
            ["ID", data.id],
            ["Order", data.order],
            ["Master Question", data.masterquestion],
            ["Master Knowledge", data.masterknowledge],
            [],
            ["Subquestions"]
        ];

        data.subknowledges.forEach(sub => {
            sheetData.push(["Question", sub.question]);
            sheetData.push(["Knowledge Content", sub.knowledgecontent]);
            sheetData.push(["Sub ID", sub._id]);
            sheetData.push([]);
        });

        // Create a new worksheet from the data
        const worksheet = xlsx.utils.aoa_to_sheet(sheetData);

        // Add the worksheet to the workbook
        xlsx.utils.book_append_sheet(workbook, worksheet, `Sheet${index + 1}`);
    });

    // Write the workbook to a file
    xlsx.writeFile(workbook, filename);
}

// Main LatestTransaction Component
const LatestTransaction = props => {


  /*---- Initial States ----*/

  const { 
    thisCb, 
    chatbotId, 
    allData,
    chatbotImgList,
    refreshData, 
    refreshChatbotImgData,
    customPrompt, 
    setCustomPrompt 
  } = props;

  const filteredData = useMemo(() => 
    allData.map((dataEntry) => ({
      id: dataEntry._id,
      order: dataEntry.order,
      masterquestion: dataEntry.question,
      masterknowledge: dataEntry.knowledgecontent,
      subknowledges: dataEntry.subknowledges
    })
  ), [allData]);

  useEffect(()=>{
    //console.log('allData:', allData);
  },[allData])

  useEffect(()=>{
    console.log('filteredData:', filteredData);
  },[filteredData])

  useEffect(()=>{
    //console.log('chatbotImgList:', chatbotImgList);
  },[chatbotImgList])

  const [subknowledges, setSubknowledges] = useState([]);
  useEffect(()=>{
    //console.log('subknowledges:', subknowledges);
  },[subknowledges])



  const [showTab, setShowTab] = useState("QA");

  const [newDataEntry, setNewDataEntry] = useState("");
  const [responseStatus, setResponseStatus] = useState("inactive"); // retrieving / inactive
  const [userWarning, setUserWarning] = useState("");

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isPromptModalOpen, setIsPromptModalOpen] = useState(false);
  const [isAddSubknowledgeModalOpen, setIsAddSubknowledgeModalOpen] = useState(false);
  const [isEditSubknowledgeModalOpen, setIsEditSubknowledgeModalOpen] = useState(false);
  const [isGenerateSubknowledgeModalOpen, setIsGenerateSubknowledgeModalOpen] = useState(false);
  const [generateSubknowledgeLoading, setGenerateSubknowledgeLoading] = useState(false);
  const [generatedSubknowledges, setGeneratedSubknowledges] = useState([]);

  // multi-factor modalValue
  const [modalData, setModalData] = useState({
    masterquestion: "",
    masterknowledge: "",
  });

  const editingDataRef = useRef({ id: null, dataContent: "" });
  const selectOptionsMapping = {};

  const [isLinebotModalOpen, setIsLinebotModalOpen] = useState(false);
  const [linebotQRCode, setLinebotQRCode] = useState('');
  useEffect(()=>{
    const fetchQRCode = async()=>{
      const thisQrCode = await fetchImage(thisCb?.qrcode)
      if(thisQrCode){
        setLinebotQRCode(thisQrCode);
      }
    }
    //console.log("HERE THISCB QRCODE", thisCb?.qrcode);
    if(thisCb?.qrcode){fetchQRCode();}
  },[thisCb?.qrcode])

  /*---- Initial States Tail ----*/



  /*---- Auto Resize Modal TextAreas ----*/

  const [newSubknowledge, setNewSubknowledge] = useState({ question: "", knowledgecontent: "" });

  const textareaRefs = useRef({});
  const questionRefs = useRef({});
  const contentRefs = useRef({});
  const adjustHeight = (key) => {
    let textarea;
    if (textareaRefs.current[key]) {
      textarea = textareaRefs.current[key];
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
      if(textarea.scrollHeight<54){
        textarea.style.height = `30px`;
      }
    }
    if (questionRefs.current[key]) {
      textarea = questionRefs.current[key];
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
      if(textarea.scrollHeight<54){
        textarea.style.height = `30px`;
      }
    }
    if (contentRefs.current[key]) {
      textarea = contentRefs.current[key];
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
      if(textarea.scrollHeight<54){
        textarea.style.height = `30px`;
      }
    }
  }
  useEffect(() => {
    setTimeout(() => {
      Object.keys(modalData).forEach(key => {
        adjustHeight(key);
      });
      Object.keys(newSubknowledge).forEach(key => {
        adjustHeight(key);
      });
      //KnowledgeEditModal
      if(subknowledges){
        subknowledges.map((subknowledge, index) => {
          adjustHeight(`question_${index}`);
          adjustHeight(`content_${index}`);
        });
      }
      //GeneratedSubknowledgeModal
      if(generatedSubknowledges){
        generatedSubknowledges.map((subknowledge, index) => {
          adjustHeight(`question_${index}`);
          adjustHeight(`content_${index}`);
        });
      }
      //console.log('textareaRefs.current:', textareaRefs.current);
    }, 0); 
  }, [
    modalData, 
    subknowledges, 
    newSubknowledge, 
    isEditModalOpen, 
    isAddSubknowledgeModalOpen,
    isEditSubknowledgeModalOpen,
    isGenerateSubknowledgeModalOpen,
  ]);

  /*---- Auto Resize Modal TextAreas Tail ----*/



  /*---- Auto Resize New Entry Input TextAreas ----*/

  function useAutoResize(initialValue = "") {
    const [value, setValue] = useState(initialValue);
    const textareaRef = useRef(null);

    const adjustTextareaHeight = () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto"; 
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; 
      }
    };

    useEffect(() => { setValue(initialValue) }, [initialValue]);
    useEffect(() => { adjustTextareaHeight() }, [value]);

    return {
      value,
      onChange: (e) => {
        setValue(e.target.value);
        //setModalInputValue(e.target.value);
        adjustTextareaHeight();
      },
      textareaRef,
    };
  }

  /* masterquestion */
  const masterquestionProps = useAutoResize(newDataEntry);
  const { textareaRef: masterquestionRef, ...masterquestionInputProps } = masterquestionProps;

  /* masterknowledge */
  const masterknowledgeProps = useAutoResize(newDataEntry);
  const { textareaRef: masterknowledgeRef, ...masterknowledgeInputProps } = masterknowledgeProps;

  /*---- Auto Resize New Entry Input TextAreas Tail ----*/



  /*---- Submit new Data Entry ----*/

  const onSubmitData = async (e) => {
    e.preventDefault();

    const payload = {
      question: masterquestionProps.value,
      knowledgecontent: masterknowledgeProps.value,
    };

    console.log("Sending to server:", payload);

    // Returns true if all values in the object are non-empty, otherwise false
    const allFieldsFilled = Object.values(payload).every(value => value && value.trim() !== "");

    if (allFieldsFilled) {
      setResponseStatus('retrieving');

      try {
        const response = await axiosInstance.post(`/knovia/knowledges/${chatbotId}`, payload);
        setResponseStatus('inactive');
        props.refreshData();
        setNewDataEntry("");
      } catch (err) {
        console.error(err);
      }
    } else {
      setUserWarning("All fields must be filled in!");
    }
  }

  /*---- Submit new Data Entry Tail ----*/



  /*---- Update and Delete existing Data Entries ----*/

  const updateDataEntry = async (id, updatedRowData, newsubknowledge=subknowledges, newGeneratedSubknowledges=generatedSubknowledges) => {

    console.log(`Updating Knowledge Id ${id}:`, updatedRowData, '\n', 
      'Also updating subknowledges:', newsubknowledge);

    try {
      const response = await axiosInstance.put(`/knovia/knowledges/${id}`, {
        question: updatedRowData.masterquestion,
        knowledgecontent: updatedRowData.masterknowledge,
        subknowledges: [...newsubknowledge, ...newGeneratedSubknowledges],
      });
      console.log(response.data.message);
      setIsGenerateSubknowledgeModalOpen(false);
      setSubknowledges((prev) => [...newsubknowledge, ...newGeneratedSubknowledges]);
      setGeneratedSubknowledges([]);
      if(viewSampleQA){setIsEditModalOpen(false);}
      props.refreshData();
    } catch (err) {
      console.error(err);
    }

  }

  const deleteDataEntry = async (id) => {

    console.log("Deleting ID:", id);

    try {
      const response = await axiosInstance.delete(`/knovia/knowledges/${id}`);
      console.log(response.data.message);
      props.refreshData();
    } catch (err) {
      console.error(err);
    }
  }

  const deleteSubknowledgeEntry = async (knowledgeId, subknowledgeId) => {
    try {
      const response = await axiosInstance.delete(`/knovia/subknowledges/${knowledgeId}`, {
        params: { subknowledgeId: subknowledgeId }
      });
      console.log(response.data.message);
      props.refreshData();
    } catch (err) {
      console.error(err);
    }
  }

  /*---- Update and Delete existing Data Entries ----*/


  const saveLinebotInfo = async (channelName, channelSecret, accessToken) =>{

    console.log(`uploading channelName:`, channelName);
    console.log(`uploading channelSecret:`, channelSecret);
    console.log(`uploading accessToken:`, accessToken);

    try {
      const response = await axiosInstance.post(`/knovia/linebot-setting/${chatbotId}`, {
        channelName: (channelName || ''),
        channelSecret: (channelSecret || ''),
        accessToken: (accessToken || ''),
      });
      console.log(response.data.message);
    } catch (err) {
      console.error(err);
    }

  }




  /*---- Data Entries Table ----*/

  const columns = useMemo(
    () => [
      {
        Header: "#",
        filterable: false,
        disableFilters: true,
        width: 20,
        Cell: cellProps => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "刪除",
        disableFilters: true,
        width: 30,
        Cell: cellProps => {
          return (
            <Button 
              color="outline-danger"
              onClick={() => deleteDataEntry(cellProps.row.original.id)}
              style={{
                height: '2rem',
                width: '2rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 0,  // Set to 0 to override any default padding
                marginLeft: 'calc(50% - 16px)',
              }}
            >
              -
            </Button>
          );
        },
      },
      {
        Header: "編輯",
        disableFilters: true,
        width: 30,
        Cell: cellProps => {
          return (
            <Button 
              color="outline-info"
              onClick={() => {
                editingDataRef.current.id = cellProps.row.original.id;

                setSubknowledges(cellProps.row.original.subknowledges);
                //console.log('cellProps:', cellProps);
                //console.log('cellProps.row.original.dataContent:', cellProps.row.original.dataContent);

                // Show user the original content before editing
                //setOriginalModalValue(cellProps.row.original.dataContent);

                // Set the initial editing textarea value same as original content
                setModalData({
                  masterquestion: cellProps.row.original.masterquestion,
                  masterknowledge: cellProps.row.original.masterknowledge,
                });
                //setModalInputValue(cellProps.row.original.dataContent);
                setIsEditModalOpen(true);

                // Adjust heights immediately
                //Object.keys(modalData).forEach(key => { adjustHeight(key) });
              }}
              style={{
                height: '2rem',
                width: '2rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 0,  // Set to 0 to override any default padding
                marginLeft: 'calc(50% - 16px)',
              }}
            >
              <i className="fas fa-pen"></i>
            </Button>
          );
        },
      },
      {
        Header: "知識主題",
        accessor: "masterquestion",
        disableFilters: true,
        width: 150,
        filterable: false,
        Cell: cellProps => {
          return <Masterquestion {...cellProps} />;
        },
      },
      {
        Header: "知識內容",
        accessor: "masterknowledge",
        disableFilters: true,
        width: 300,
        filterable: false,
        Cell: cellProps => {
          return <Masterknowledge {...cellProps} />;
        },
      },
      {
        Header: "範例問答數量",
        accessor: "subknowledges",
        disableFilters: true,
        width: 60,
        filterable: false,
        Cell: cellProps => {
          return <SubknowledgeCol {...cellProps} />;
        },
      },
    ],
    []
  );

  const [editingSubknowledge, setEditingSubknowledge] = useState({});

  const subknowledgecolumns = useMemo(
    () => [
      {
        Header: "#",
        filterable: false,
        disableFilters: true,
        width: 50,
        Cell: cellProps => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "範例提問",
        accessor: "question",
        disableFilters: true,
        width: 150,
        filterable: false,
        Cell: cellProps => {
          return <Masterquestion {...cellProps} />;
        },
      },
      {
        Header: "範例回答",
        accessor: "knowledgecontent",
        disableFilters: true,
        width: 300,
        filterable: false,
        Cell: cellProps => {
          return <Masterknowledge {...cellProps} />;
        },
      },
      {
        Header: "刪除",
        disableFilters: true,
        width: 60,
        Cell: cellProps => {
          return (
            <Button 
              color="outline-danger"
              onClick={() => {
                const yes = confirm('確定要刪除嗎？');
                if (yes) {
                  deleteSubknowledgeEntry(cellProps.row.original.knowledgeId, cellProps.row.original._id);
                }
              }}
              style={{
                height: '2rem',
                width: '2rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 0,  // Set to 0 to override any default padding
                marginLeft: 'calc(50% - 16px)',
              }}
            >
              -
            </Button>
          );
        },
      },
      {
        Header: "編輯",
        disableFilters: true,
        width: 60,
        Cell: cellProps => {
          return (
            <Button 
              color="outline-info"
              onClick={() => {
                editingDataRef.current.id = cellProps.row.original.knowledgeId;
                setEditingSubknowledge(cellProps.row.original);
                setIsEditSubknowledgeModalOpen(true);
              }}
              style={{
                height: '2rem',
                width: '2rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 0,  // Set to 0 to override any default padding
                marginLeft: 'calc(50% - 16px)',
              }}
            >
              <i className="fas fa-pen"></i>
            </Button>
          );
        },
      },
    ],
    []
  );


   /*---- For output Excel checkboxes choosing Columns ----*/

  const [isOutputModalOpen, setIsOutputModalOpen] = useState(false);

  function filterColumns(checkedColumns, data) {
    return data.map((item, index) => {
      let filteredItem = {};
      if (checkedColumns.includes('questionText')){ filteredItem['提問'] = item['masterquestion'].trim() || ''; }
      if (checkedColumns.includes('answerText')){ filteredItem['相關知識'] = item['masterknowledge'].trim() || ''; }
      return filteredItem;
    });
  }

  const downloadExcel = async (checkedColumns) => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Function to filter columns
    const filterColumns = (checkedColumns, data) => {
      return data.map(item => {
        const filteredItem = {};
        checkedColumns.forEach(column => {
          if (item.hasOwnProperty(column)) {
            filteredItem[column] = item[column];
          }
        });
        return filteredItem;
      });
    };

    // Filter data based on checked columns
    const filteredDataWithColumns = filterColumns(checkedColumns, filteredData);

    // Sort the filtered data by the 'order' field in ascending order
    const sortedData = filteredDataWithColumns.sort((a, b) => a.order - b.order);

    console.log('sortedData:', sortedData);

    // Prepare the data for the sheet
    const sheetData = [];

    sortedData.forEach(data => {
      // Add the main knowledge information
      if(data?.order>=0){sheetData.push(["編號", data.order+1]);}
      if(data?.masterquestion!==undefined){sheetData.push(["主題", data.masterquestion]);}
      if(data?.masterknowledge!==undefined){sheetData.push(["主題知識", data.masterknowledge]);}

      // Add subknowledges
      data?.subknowledges?.forEach(sub => {
        sheetData.push(["", "範例提問", sub.question, "範例回答", sub.knowledgecontent]);
      });

      // Add a blank line after each main knowledge item
      sheetData.push([]);
    });

    // Create a new worksheet from the data
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Bytelingo Output');

    // Generate the filename
    const newFileDate = await dateGen();
    const excelFilename = `${newFileDate.slice(0, 8)}_${newFileDate.slice(8, 14)}-[${thisCb?.cbName}]`;

    // Write the workbook to a file
    XLSX.writeFile(workbook, `${excelFilename}.xlsx`);
  };

  /*---- For output Excel checkboxes choosing Columns Tail ----*/


  /*---- Processing QRCode Upload ----*/

  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState('還沒有選擇圖片喔');
  const [uploadStatus, setUploadStatus] = useState(''); // 圖片上傳狀態
  const fileInputRef = useRef(null);  // The original button for choosing image file

  useEffect(()=>{
    const uploadQRCode = async()=>{
      try {
        const response = await axiosInstance.post(`/knovia/chatbots-setLineQRCode/${chatbotId}`, 
          { qrcode: filename }
        );
        console.log(response.data.message);
        props.refreshData();
      } catch (err) {
        console.error(err);
      }
    }
    if(filename!=='還沒有選擇圖片喔'){uploadQRCode();}
  },[filename])

  // OnClick handler for the beautified choosing image file button
  const handleButtonClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    setUploadStatus('');
    console.log("Selected file:", event.target.files[0]);
    setFile(event.target.files[0]);
  };

  useEffect(() => {
    const performUpload = async () => {
      if (file) {
        // Reset upload status
        setUploadStatus('');

        const date14string = await dateGen();
        const newfilename = date14string + '_' + file.name;
        setFilename(newfilename);
        const {newImageUrl, uploadStatus} = await uploadImg(newfilename, file);
        setUploadStatus(uploadStatus);
        setLinebotQRCode(newImageUrl);
      }
    };

    performUpload();
  }, [file]);

  /*---- Processing QRCode Upload Tail ----*/

  /*---- Processing ChatbotImg Upload ----*/

  const [uploadLoading, setUploadLoading] = useState(false); // Link the image filename to the chatbot
  const [chatbotImgUploadLoading, setChatbotImgUploadLoading] = useState(false);
  const [chatbotImgFile, setChatbotImgFile] = useState(null);
  const [chatbotImgFilename, setChatbotImgFilename] = useState('還沒有選擇圖片喔');
  const [chatbotImgUploadStatus, setChatbotImgUploadStatus] = useState(''); // 圖片上傳狀態
  const [chatbotImageUrl, setChatbotImageUrl] = useState('');
  const chatbotImgfileInputRef = useRef(null);  // The original button for choosing image file


  const uploadChatbotImg = async()=>{
    try {
      setUploadLoading(true);
      const response = await axiosInstance.post(`/knovia/chatbots-uploadImg/${chatbotId}`, 
        { chatbotImg: chatbotImgFilename }
      );
      console.log(response.data.message);
      setUploadLoading(false);
      props.refreshChatbotImgData();
    } catch (err) {
      console.error(err);
      setUploadLoading(false);
    }
  }

  // OnClick handler for the beautified choosing image file button
  const handleChatbotImgButtonClick = (e) => {
    e.preventDefault();
    chatbotImgfileInputRef.current.click();
  };

  const handleChatbotImgFileChange = (event) => {
    setChatbotImgUploadStatus('');
    console.log("Selected ChatbotImg file:", event.target.files[0]);
    setChatbotImgFile(event.target.files[0]);
  };

  useEffect(() => {
    const performChatbotImgUpload = async () => {
      if (chatbotImgFile) {
        // Reset upload status
        setChatbotImgUploadStatus('');

        const date14string = await dateGen();
        const newfilename = date14string + '_' + chatbotImgFile.name;
        setChatbotImgFilename(newfilename);
        setChatbotImgUploadLoading(true);
        const {newImageUrl, uploadStatus} = await uploadImg(newfilename, chatbotImgFile);
        setChatbotImgUploadLoading(false);
        setChatbotImgUploadStatus(uploadStatus);
        setChatbotImageUrl(newImageUrl);
      }
    };

    performChatbotImgUpload();
  }, [chatbotImgFile]);

  /*---- Processing QRCode Upload Tail ----*/

  const [viewSampleQA, setViewSampleQA] = useState(true);
  const [viewSampleQAOrder, setViewSampleQAOrder] = useState(0);
  const [canPreviousPage, setCanPreviousPage] = useState(false);
  const [canNextPage, setCanNextPage] = useState(false);
  const [innerTableIndex, setInnerTableIndex] = useState(0);
  useEffect(()=>{
    if(viewSampleQAOrder<=0){
      setCanPreviousPage(false);
    } else {
      setCanPreviousPage(true);
    }
    if(viewSampleQAOrder>=filteredData.length-1){
      setCanNextPage(false);
    } else {
      setCanNextPage(true);
    }
    setInnerTableIndex(0); // When viewing different QA, set the inner table index to 0
  },[viewSampleQAOrder, filteredData])
  const gotoPage = (index)=>{
    setViewSampleQAOrder(index);
  }
  const nextPage = ()=>{if(viewSampleQAOrder<filteredData.length){gotoPage(viewSampleQAOrder+1)}}
  const previousPage = ()=>{if(viewSampleQAOrder>0){gotoPage(viewSampleQAOrder-1)}}
  const onChangeInInput = (e)=>{
    if(Number(e.target.value)-1>=0 && Number(e.target.value)-1<filteredData.length){
      gotoPage(Number(e.target.value)-1);
    }
  }
  console.log("HERE");


  const generateSubknowledge = async () => {
    setGenerateSubknowledgeLoading(true);

    try {
      const response = await axiosInstance.post(`knovia/generateSubknowledge`, 
        { 
          question: modalData.masterquestion, 
          knowledgecontent: modalData.masterknowledge,
          currentSubknowledges: subknowledges
        }
      );
      const { subknowledgePairs } = response.data;
      setGenerateSubknowledgeLoading(false);

      console.log('subknowledgePairs:', subknowledgePairs);

      // Remove the outer brackets and split the string into pairs using regex
      const pairs = subknowledgePairs.slice(1, -1).split(/\),\s*\n?\(/);

      // Initialize the resulting object
      const resultPairs = {};

      // Iterate over each pair
      pairs.forEach(pair => {
        // Remove any leading or trailing characters and split the pair into Q and A using regex
        const [qPart, aPart] = pair.replace(/^\(|\)$/g, '')?.split(/,\s*A/);

        // Extract the key and value for the question
        const qKey = qPart?.split(':')[0].trim();
        const qValue = qPart?.split(':').slice(1).join(':').trim().slice(1, -1);

        // Extract the key and value for the answer
        const aKey = 'A' + aPart?.split(':')[0].trim();
        const aValue = aPart?.split(':').slice(1).join(':').trim().slice(1, -1);

        // Add the question and answer to the result object
        resultPairs[qKey] = qValue;
        resultPairs[aKey] = aValue;
      });

      console.log(resultPairs);

      let newGenerated = [];
      for (let i = 1; i < 20; i++) {
        if (resultPairs['Q' + i] && resultPairs['A' + i]) {
          newGenerated.push({ question: resultPairs['Q' + i], knowledgecontent: resultPairs['A' + i] });
        } else {
          break;
        }
      }
      setGeneratedSubknowledges(newGenerated);
      console.log('newGenerated:', newGenerated);
      setIsGenerateSubknowledgeModalOpen(true);

    } catch (error) {
      console.error('Error generating subknowledge:', error);
      setGenerateSubknowledgeLoading(false);
      // Handle error appropriately, e.g., display a notification to the user
      // setError(error.message); // Optionally set an error message state
    }
  };

  useEffect(()=>{
    if(!isGenerateSubknowledgeModalOpen){setGeneratedSubknowledges([]);}
  },[isGenerateSubknowledgeModalOpen])

  const [loadedExceldata, setLoadedExcelData] = useState([]);

  const handleDataLoaded = (loadedExceldata) => {
    setLoadedExcelData(loadedExceldata);
    console.log('Reconstructed Data:', loadedExceldata);
  };

  // Return Component
  return (
    <React.Fragment>

      <LinebotModal
        isLinebotModalOpen={isLinebotModalOpen} 
        setIsLinebotModalOpen={setIsLinebotModalOpen}
        saveLinebotInfo={saveLinebotInfo}
        thisCb={thisCb}
        chatbotId={chatbotId}
      />

      <PromptModal 
        isPromptModalOpen={isPromptModalOpen} 
        setIsPromptModalOpen={setIsPromptModalOpen}
        customPrompt={customPrompt}
        setCustomPrompt={setCustomPrompt}
      />

      <OutputExcelModal 
        isOutputModalOpen={isOutputModalOpen}
        setIsOutputModalOpen={setIsOutputModalOpen}
        downloadExcel={downloadExcel}
      />

      <KnowledgeEditModal
        isEditModalOpen={isEditModalOpen}
        setIsEditModalOpen={setIsEditModalOpen}
        textareaRefs={textareaRefs}
        questionRefs={questionRefs}
        contentRefs={contentRefs}
        editingDataRef={editingDataRef}
        subknowledges={subknowledges}
        setSubknowledges={setSubknowledges}
        modalData={modalData}
        setModalData={setModalData}
        updateDataEntry={updateDataEntry}
        viewSampleQA={viewSampleQA}
        generateSubknowledge={generateSubknowledge}
        generateSubknowledgeLoading={generateSubknowledgeLoading}
      />

      <GeneratedSubknowledgeModal
        isGenerateSubknowledgeModalOpen={isGenerateSubknowledgeModalOpen}
        setIsGenerateSubknowledgeModalOpen={setIsGenerateSubknowledgeModalOpen}
        textareaRefs={textareaRefs}
        questionRefs={questionRefs}
        contentRefs={contentRefs}
        editingDataRef={editingDataRef}
        generatedSubknowledges={generatedSubknowledges}
        setGeneratedSubknowledges={setGeneratedSubknowledges}
        modalData={modalData}
        setModalData={setModalData}
        updateDataEntry={updateDataEntry}
      />

      <AddSubknowledgeModal
        isAddSubknowledgeModalOpen={isAddSubknowledgeModalOpen} 
        setIsAddSubknowledgeModalOpen={setIsAddSubknowledgeModalOpen} 
        textareaRefs={textareaRefs} 
        editingDataRef={editingDataRef}
        subknowledges={subknowledges} 
        setSubknowledges={setSubknowledges} 
        newSubknowledge={newSubknowledge}
        setNewSubknowledge={setNewSubknowledge}
        modalData={modalData}
        setModalData={setModalData}
        updateDataEntry={updateDataEntry} 
      />

      <EditSubknowledgeModal
        isEditSubknowledgeModalOpen={isEditSubknowledgeModalOpen} 
        setIsEditSubknowledgeModalOpen={setIsEditSubknowledgeModalOpen} 
        textareaRefs={textareaRefs} 
        editingDataRef={editingDataRef}
        subknowledges={subknowledges}
        editingSubknowledge={editingSubknowledge}
        setSubknowledges={setSubknowledges} 
        newSubknowledge={newSubknowledge}
        setNewSubknowledge={setNewSubknowledge}
        modalData={modalData}
        setModalData={setModalData}
        updateDataEntry={updateDataEntry} 
        filteredData={filteredData}
      />


      <Card>
        <CardBody>

          <Row>
            <Col sm="8">
              <h5>{`簡介：${thisCb.cbDesc}`}</h5>
            </Col>
            <Col sm="2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div>
                <img
                  src={linebotQRCode || qrPlaceholder}
                  alt="linebotQRCode"
                  className="avatar-md img-thumbnail"
                  onClick={handleButtonClick}
                  style={{ 
                    height: "7rem",
                    width: "7rem",
                    objectFit: "cover",
                    padding: '0',
                    marginRight: '1rem',
                    cursor: 'pointer'
                  }}
                />
              </div>
            </Col>
            <Col sm="2">
              <div style={{ marginBottom: '0.5rem' }}>
                <Button color="info" onClick={() => setIsOutputModalOpen(true)}>
                  匯出 Excel 檔
                </Button>
              </div>
              <div style={{ marginBottom:'0.5rem' }}>
                <Button color="success" onClick={()=>{ setIsLinebotModalOpen(true); }}>
                  串接 LineBot
                </Button>
              </div>
              <div>
                {/* 選擇圖片按鈕 + 圖片名稱 */}
                <Row>
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                  <Col sm="12">
                    <Button color="warning" onClick={handleButtonClick}>上傳 LineBot QRCode</Button>
                  </Col>
                </Row>
              </div>
              <div style={{ marginTop: '0.5rem' }}>
                <UploadExcel onDataLoaded={handleDataLoaded} />
              </div>
            </Col>
          </Row>



          {/* 分隔線 */}
          <div style={{margin:"1rem 0rem", borderBottom:"1px solid #bdbdbd"}}></div>

          <Row style={{ margin:'2vh 0vw', display:'flex' }}>
            <Col sm="12">
              <Button 
                color='info' 
                style={{ margin:'0vh 1vw 0vh 0vw'}}
                onClick={()=>{setShowTab('QA')}}
              >提問與知識</Button>
              <Button 
                color='warning'
                onClick={()=>{setShowTab('Image')}}
              >圖片管理</Button>
            </Col>
          </Row>

          {/* 分隔線 */}
          <div style={{margin:"1rem 0rem", borderBottom:"1px solid #bdbdbd"}}></div>

          {showTab==='QA' && 
            <>
              {/* TextArea for Inputing new dataentry */}
              <Row style={{ marginBottom:'0.3rem' }}>
                <div className="card-title">新增知識對</div>
              </Row>

              <Row style={{ marginBottom: '1rem' }}>

                {/* 提問輸入 */}
                <Col sm={5}>
                  <div style={{ display: 'flex', justifyContent: 'center', padding: '0.3rem 0rem' }}>
                    <div style={{ fontSize: '1rem' }}>提問(選用)</div>
                  </div>
                  <div className="toast fade show" style={{ width: '100%', border: '1px solid var(--bs-input-border-color)', boxShadow: 'none' }} role="alert">

                    <div className="toast-body" style={{ minHeight:'4rem' }}>
                    <textarea
                      ref={masterquestionRef}
                      style={{ minHeight:'0rem', border:'none', resize: 'none' }}
                      className="form-control" 
                      id="newdataentry"
                      {...masterquestionInputProps}
                    />
                    </div>
                  </div>
                </Col>

                {/* 知識輸入 */}
                <Col sm={5}>
                  <div style={{ display: 'flex', justifyContent: 'center', padding: '0.3rem 0rem' }}>
                    <div style={{ fontSize: '1rem' }}>相關知識</div>
                  </div>
                  <div className="toast fade show" style={{ width: '100%', border: '1px solid var(--bs-input-border-color)', boxShadow: 'none' }} role="alert">

                    <div className="toast-body">
                    <textarea
                      ref={masterknowledgeRef}
                      style={{ 'minHeight':'0rem', 'border':'none', 'resize': 'none' }}
                      className="form-control" 
                      id="newdataentry"
                      {...masterknowledgeInputProps}
                    />
                    </div>
                  </div>
                </Col>


                <Col sm={2}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <div style={{ marginBottom: '0.5rem' }}>
                    <Button color="outline-info" onClick={onSubmitData}>
                      添加資料
                    </Button>
                  </div>
                  <div style={{ marginBottom: '0.3rem' }}>
                    <Button color="outline-warning" onClick={()=>{ setIsPromptModalOpen(true); }}>
                      設定 prompt
                    </Button>
                  </div>
                  
                </Col>

              </Row>


              {/* 分隔線 */}
              <div style={{margin:"2rem 0rem", borderBottom:"1px solid #bdbdbd"}}></div>
            </>
          }


          {/*---- Table for Showing all dataentries ----*/}

          {showTab==='QA' && 
            <Row style={{
              display: 'flex',
              justifyContent: 'flex-start'
            }}>
              {/*
              <label style={{ display:'inline-block', width:'fit-content' }}>
                <input type="checkbox" checked disabled style={{ marginRight: '0.5vw' }}/>
                檢視主題
              </label>
              <label style={{ display:'inline-block', width:'fit-content' }}>
                <input type="checkbox" checked={viewSampleQA} 
                  onClick={()=>{setViewSampleQA(!viewSampleQA)}}
                  onChange={()=>{}}
                  style={{ marginRight: '0.5vw' }}
                />
                檢視範例問答
              </label>
              */}
              <div onClick={()=>{setViewSampleQA(true)}} style={{ maxWidth: '3.5rem', cursor:'pointer', marginRight:'1vw' }}>
                {viewSampleQA && <img src={block_pressed} alt="block_view" style={{ maxWidth: '3.5rem' }} />}
                {!viewSampleQA && <img src={block_unpressed} alt="block_view_na" style={{ maxWidth: '3.5rem' }} />}
              </div>
              <div onClick={()=>{setViewSampleQA(false)}} style={{ maxWidth: '3.5rem', cursor:'pointer', marginBottom:'3vh' }}>
                {!viewSampleQA && <img src={list_pressed} alt="block_view" style={{ maxWidth: '3.5rem' }} />}
                {viewSampleQA && <img src={list_unpressed} alt="block_view_na" style={{ maxWidth: '3.5rem' }} />}
              </div>
              {/* 分隔線 */}
              {/*<div style={{margin:"2rem 0rem", borderBottom:"1px solid #bdbdbd"}}></div>*/}
            </Row>
          }

          {/* 僅檢視主題 */}
          {showTab==='QA' && !viewSampleQA &&
            <>
              <Row>
                <Col>
                  {filteredData?
                    (<TableContainer
                      columns={columns}
                      data={filteredData}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={10}
                    />)
                  :<p>loading...</p>}
                </Col>
              </Row>
            </>
          }

          {/* 檢視主題及範例問答 */}
          {showTab==='QA' && viewSampleQA &&

            <>

              <Row style={{ marginTop:'2vh' }}>
                <Col>
                  {filteredData.slice().sort((a, b) => a.order - b.order).map((knowledge, index)=>(
                    viewSampleQAOrder===index && 
                      (<div key={`knowledge_${index}`} style={{ marginBottom:'3.5vh' }}>
                        <table style={{ borderCollapse: 'collapse', width: '100%', marginBottom:'3vh' }}>
                          <thead>
                            <tr>
                              <th style={{ border: '1px solid #eff2f7', padding: '8px', backgroundColor: '#ffe4b6' }}>
                                <p style={{ margin:'0', wordBreak: 'keep-all' }}>編號</p>
                              </th>
                              <th style={{ border: '1px solid #eff2f7', padding: '8px', backgroundColor: '#ffe4b6' }}>
                                <p style={{ margin:'0', wordBreak: 'keep-all' }}>主題</p>
                              </th>
                              <th style={{ border: '1px solid #eff2f7', padding: '8px', backgroundColor: '#ffe4b6' }}>
                                <p style={{ margin:'0', wordBreak: 'keep-all' }}>主題知識</p>
                              </th>
                              <th style={{ border: '1px solid #eff2f7', padding: '8px', backgroundColor: '#ffe4b6' }}>
                                <p style={{ margin:'0', wordBreak: 'keep-all' }}>刪除</p>
                              </th>
                              <th style={{ border: '1px solid #eff2f7', padding: '8px', backgroundColor: '#ffe4b6' }}>
                                <p style={{ margin:'0', wordBreak: 'keep-all' }}>編輯</p>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ border: '1px solid #eff2f7', padding: '8px', width:'5vw', wordBreak: 'break-word' }}>
                                <p>{index+1}</p>
                              </td>
                              <td style={{ border: '1px solid #eff2f7', padding: '8px', width:'20vw', whiteSpace: 'break-spaces' }}>
                                <p>{knowledge.masterquestion}</p>
                              </td>
                              <td style={{ border: '1px solid #eff2f7', padding: '8px', width:'40vw', whiteSpace: 'break-spaces' }}>
                                <p>{knowledge.masterknowledge}</p>
                              </td>
                              <td style={{ border: '1px solid #eff2f7', padding: '8px', width:'10vw'}}>
                                <Button 
                                  color="outline-danger"
                                  onClick={() => deleteDataEntry(knowledge.id)}
                                  style={{
                                    height: '2rem',
                                    width: '2rem',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: 0,  // Set to 0 to override any default padding
                                    marginLeft: 'calc(50% - 16px)',
                                  }}
                                >
                                  -
                                </Button>
                              </td>
                              <td style={{ border: '1px solid #eff2f7', padding: '8px', width:'10vw'}}>
                                <Button 
                                  color="outline-info"
                                  onClick={() => {
                                    editingDataRef.current.id = knowledge.id;
                                    setSubknowledges(knowledge.subknowledges);
                                    setModalData({
                                      masterquestion: knowledge.masterquestion,
                                      masterknowledge: knowledge.masterknowledge,
                                    });
                                    setIsEditModalOpen(true);
                                  }}
                                  style={{ height: '2rem', width: '2rem', display: 'flex', alignItems: 'center', 
                                    justifyContent: 'center', padding: 0, marginLeft: 'calc(50% - 16px)' }}
                                >
                                  <i className="fas fa-pen"></i>
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <Row style={{ display:'flex', justifyContent:'center'}}>
                          <Col sm="12"
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              margin: '3vh 0vw -7vh 0vw'
                            }}
                          >
                            <Button 
                              color="outline-success" 
                              onClick={()=>{
                                editingDataRef.current.id = knowledge.id;
                                setSubknowledges(knowledge.subknowledges);
                                setModalData({
                                  masterquestion: knowledge.masterquestion,
                                  masterknowledge: knowledge.masterknowledge,
                                });
                                setIsAddSubknowledgeModalOpen(true)
                              }}
                            >範例問答+</Button>
                          </Col>
                        </Row>

                        <TableContainer
                          columns={subknowledgecolumns}
                          data={knowledge.subknowledges.map((s=>({...s, knowledgeId:knowledge.id}))).slice().reverse()}
                          isGlobalFilter={true}
                          isAddOptions={false}
                          customPageSize={10}
                          isSubknowledge={true}
                          pageIndex={innerTableIndex}
                          setPageIndex={setInnerTableIndex}
                        />

                      {/* 分隔線 */}
                      <div style={{margin:"5vh 0vw 2vh 0vw", borderBottom:"1px solid #bdbdbd"}}></div>

                    </div>)
                    
                  ))}
                </Col>
              </Row>


              <Row className="justify-content-center align-items-center" style={{ marginBottom:'1.5vh' }}>
                <Col className="col-md-auto">
                  <div className="d-flex gap-1">
                    <Button
                      color="warning"
                      onClick={() => {gotoPage(0)}}
                      disabled={!canPreviousPage}
                    >
                      {"<<"}
                    </Button>
                    <Button
                      color="warning"
                      onClick={previousPage}
                      disabled={!canPreviousPage}
                    >
                      {"<"}
                    </Button>
                  </div>
                </Col>
                <Col className="col-md-auto d-none d-md-block">
                  Page{" "}
                  <strong>
                    {viewSampleQAOrder + 1} of {filteredData.length}
                  </strong>
                </Col>
                <Col className="col-md-auto">
                  <Input
                    type="number"
                    min={1}
                    style={{ width: 70 }}
                    max={filteredData.length}
                    defaultValue={viewSampleQAOrder + 1}
                    onChange={onChangeInInput}
                  />
                </Col>

                <Col className="col-md-auto">
                  <div className="d-flex gap-1">
                    <Button color="warning" onClick={nextPage} disabled={!canNextPage}>
                      {">"}
                    </Button>
                    <Button
                      color="warning"
                      onClick={() => gotoPage(filteredData.length - 1)}
                      disabled={!canNextPage}
                    >
                      {">>"}
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          }


          {showTab==='Image' && 
            <ChatbotImgTab
              chatbotId={chatbotId}
              chatbotImgList={chatbotImgList}
              chatbotImgfileInputRef={chatbotImgfileInputRef}
              handleChatbotImgFileChange={handleChatbotImgFileChange}
              handleChatbotImgButtonClick={handleChatbotImgButtonClick}
              chatbotImgFilename={chatbotImgFilename}
              chatbotImgUploadStatus={chatbotImgUploadStatus}
              chatbotImageUrl={chatbotImageUrl}
              uploadChatbotImg={uploadChatbotImg}
              uploadLoading={uploadLoading}
              chatbotImgUploadLoading={chatbotImgUploadLoading}
              refreshChatbotImgData={refreshChatbotImgData}
            />
          }


          {/*---- Table for Showing all dataentries Tail ----*/}


        </CardBody>
      </Card>
    </React.Fragment>
  );
};

LatestTransaction.propTypes = {
  orders: PropTypes.array,
};

export default withRouter(LatestTransaction);
