import {
  EDIT_PROFILE,
  PROFILE_SUCCESS,
  PROFILE_ERROR,

  EDIT_PROFILE_IMAGE,
  IMAGE_SUCCESS,
  IMAGE_ERROR,

  EDIT_PROFILE_EMAIL,
  EMAIL_SUCCESS,
  EMAIL_ERROR,

  EDIT_PROFILE_USERNAME,
  USERNAME_SUCCESS,
  USERNAME_ERROR,

  EDIT_PROFILE_PASSWORD,
  PASSWORD_SUCCESS,
  PASSWORD_ERROR,
  
  CLEAR_PROFILE_ERROR,
  CLEAR_PROFILE_SUCCESS,
  RESET_PROFILE_FLAG,
  
} from "./actionTypes"

const initialState = {
  success: "",
  error: "",
  imageSuccess: "",
  imageError: "",
  emailSuccess: "",
  emailError: "",
  usernameSuccess: "",
  usernameError: "",
  passwordSuccess: "",
  passwordError: "",
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {

    case EDIT_PROFILE:
      state = { ...state };
      break;
    case PROFILE_SUCCESS:
      state = { 
        ...state, 
        success: action.payload,
        error: null,
      };
      break;
    case PROFILE_ERROR:
      state = { 
        ...state, 
        success: null,
        error: action.payload,
      };
      break;

    case EDIT_PROFILE_IMAGE:
      state = { ...state };
      break;
    case IMAGE_SUCCESS:
      state = { 
        ...state, 
        imageSuccess: action.payload,
        imageError: null,
      };
      break;
    case IMAGE_ERROR:
      state = { 
        ...state, 
        imageSuccess: null,
        imageError: action.payload,
      };
      break;

    case EDIT_PROFILE_EMAIL:
     state = { ...state };
      break;
    case EMAIL_SUCCESS:
      state = { 
        ...state, 
        emailSuccess: action.payload,
        emailError: null,
      };
      break;
    case EMAIL_ERROR:
      state = { 
        ...state, 
        emailSuccess: null,
        emailError: action.payload,
      };
      break;

    case EDIT_PROFILE_USERNAME:
      state = { ...state };
      break;
    case USERNAME_SUCCESS:
      state = { 
        ...state, 
        usernameSuccess: action.payload,
        usernameError: null,
      };
      break;
    case USERNAME_ERROR:
      state = { 
        ...state, 
        usernameSuccess: null,
        usernameError: action.payload,
      };
      break;

    case EDIT_PROFILE_PASSWORD:
      state = { ...state };
      break;
    case PASSWORD_SUCCESS:
      state = { 
        ...state, 
        passwordSuccess: action.payload,
        passwordError: null,
      };
      break;
    case PASSWORD_ERROR:
      state = { 
        ...state, 
        passwordSuccess: null,
        passwordError: action.payload,
      };
      break;
    
    case CLEAR_PROFILE_SUCCESS:
      console.log('Profile Success Cleared...');
      state = { 
        ...state, 
        success: '', 
        imageSuccess: '', 
        emailSuccess: '', 
        usernameSuccess: '', 
        passwordSuccess: '' 
      };
      break;
    case CLEAR_PROFILE_ERROR:
      console.log('Profile Error Cleared...');
      state = { 
        ...state, 
        error: '',
        imageError: '', 
        emailError: '', 
        usernameError: '', 
        passwordError: '' 
      };
      break;
    case RESET_PROFILE_FLAG:
      state = { ...state, success: null, error: null };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default profileReducer;
