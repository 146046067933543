import React, {useState} from "react";

export const Pagination = ({ currentPage, totalPages, paginate }) => {
  // const [temValue, setTemValue] = useState(currentPage);
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <button
        onClick={() => paginate(1)}
        disabled={currentPage === 1}
        style={{ margin: '0 5px' }}
        className="btn btn-primary"
      >
        &lt;&lt;
      </button>
      <button
        onClick={() => paginate(Number(currentPage - 1))}
        disabled={currentPage === 1}
        style={{ margin: '0 5px' }}
        className="btn btn-primary"
      >
        &lt;
      </button>
      <span style={{ margin: '0 10px' }}>
        Page {currentPage} of {totalPages<1?1:totalPages}
      </span>
      <input 
        type="text" 
        value={currentPage}
        style={{ width: '40px', textAlign: 'center', margin: '0 10px' }}
        pattern="[0-9]*"
        onChange={(event) => {
          const targetPageNumber = Number(event.target.value);
          if (targetPageNumber > totalPages) {
            paginate(totalPages);
          } else if(targetPageNumber < 1) {
            paginate(1);
          } else {
            paginate(event.target.validity.valid ? targetPageNumber : currentPage);
          }
          // console.log('onChange', event.target.validity.valid);
        }}
      />
      <button
        onClick={() => paginate(Number(currentPage + 1))}
        disabled={currentPage === totalPages}
        style={{ margin: '0 5px' }}
        className="btn btn-primary"
      >
        &gt;
      </button>
      <button
        onClick={() => paginate(Number(totalPages))}
        disabled={currentPage === totalPages}
        style={{ margin: '0 5px' }}
        className="btn btn-primary"
      >
        &gt;&gt;
      </button>
    </div>
  );
};
