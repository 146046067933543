import React from 'react';
import { Link } from 'react-router-dom';
import * as moment from "moment";
import { Badge } from 'reactstrap';

const formateDate = (date, format) => {
    const dateFormat = format ? format : "DD MMM Y";
    const date1 = moment(new Date(date)).format(dateFormat);
    return date1;
};
const toLowerCase1 = str => {
    return (
      str === "" || str === undefined ? "" : str.toLowerCase()
    );
  };

const CheckBox = (cell) => {
    return cell.value ? cell.value : '';
};

const Date = (cell) => {
    return cell.value ? cell.value+"*" : '';
};

const Text = (cell) => {
    return cell.value ? cell.value.substring(0,150)+' ...' : '';
};

const Result = (cell) => {
    return cell.value ? cell.value : '';
};

const AbstractLength = (cell) => {
    return cell.value ? cell.value : '';
};

const APICost = (cell) => {
    return (
        <Badge
          className={"font-size-11 badge-soft-" + 
          (Number(cell.value) < 0.1 ? "success" : (Number(cell.value) < 0.3 ? "warning" : "danger"))}          
        >
          {cell.value}
        </Badge>
    )
};

const Rating = (cell) => {

  if (Number(cell.value) > 4.0) {
    return (
      <span className="bg-success font-size-12 badge">
        <i className="mdi mdi-star me-1"></i>{cell.value}
      </span>
    )
  } else if (Number(cell.value) > 3.5){
    return (
      <span className="bg-warning font-size-12 badge">
        <i className="mdi mdi-star me-1"></i>{cell.value}
      </span>
    )
  } else {
    return (
      <span className="bg-danger font-size-12 badge">
        <i className="mdi mdi-star me-1"></i>{cell.value}
      </span>
    )
  }
}

const TimeStamp = (cell) => {
    const formattedDate = `${cell.value.substring(0, 4)}`
      +`/${cell.value.substring(4, 6)}`
      +`/${cell.value.substring(6, 8)}`
      +` ${cell.value.substring(8, 10)}`
      +`:${cell.value.substring(10, 12)}`
      +`:${cell.value.substring(12, 14)}`

    return formattedDate ? `${formattedDate}` : '';
};

export {
    CheckBox,
    Date,
    Result,
    AbstractLength,
    Text,
    APICost,
    Rating,
    TimeStamp,
};