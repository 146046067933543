import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import axiosInstance from 'utils/axiosInstance';

// Login Redux States
import {
  CLEAR_CHANGE_PASSWORD,
  CHANGE_PASSWORD,
} from "./actionTypes"
import {
  clearChangePassword,
  clearChangePasswordSuccess,
  clearChangePasswordFailed,
  userChangePasswordSuccess, 
  userChangePasswordError 
} from "./actions"

function* clearChangeUser() {
  try {
    yield put(clearChangePasswordSuccess());
  } catch {
    yield put(clearChangePasswordFailed());
  }
}

function* changeUserPassword({ payload: {values, token} }) {

  try{

    const response = yield call(axiosInstance.post, `/knovia/reset-password/${token}`, values);

    if (response.status !== 200 && response.status !== 201) {
      throw new Error(response.data.message || 'Failed to change password');
    }

    yield put(userChangePasswordSuccess("成功變更密碼，請重新登入"));
    
  } catch (error) {
    yield put(userChangePasswordError(error.response?.data?.message || error.message));
  }

}

export function* watchUserPasswordChange() {
  yield takeEvery(CLEAR_CHANGE_PASSWORD, clearChangeUser);
  yield takeEvery(CHANGE_PASSWORD, changeUserPassword);
}

function* changePasswordSaga() {
  yield all([fork(watchUserPasswordChange)])
}

export default changePasswordSaga;
