import React from 'react';
import { Link } from 'react-router-dom';
import * as moment from "moment";
import { Badge } from 'reactstrap';

const formateDate = (date, format) => {
    const dateFormat = format ? format : "DD MMM Y";
    const date1 = moment(new Date(date)).format(dateFormat);
    return date1;
};
const toLowerCase1 = str => {
    return (
      str === "" || str === undefined ? "" : str.toLowerCase()
    );
  };

const CheckBox = (cell) => {
    return cell.value ? cell.value : '';
};

const OrderId = (cell) => {
    return (
        <Link to="#" className="text-body fw-bold">{cell.value ? cell.value : ''}</Link>
    );
};

const BillingName = (cell) => {
    return cell.value ? cell.value : '';
};

const Date = (cell) => {
    return cell.value ? cell.value+"*" : '';
};

const Rating = (cell) => {

  if (Number(cell.value) > 4.0) {
    return (
      <span className="bg-success font-size-12 badge">
        <i className="mdi mdi-star me-1"></i>{cell.value}
      </span>
    )
  } else if (Number(cell.value) > 3.5){
    return (
      <span className="bg-warning font-size-12 badge">
        <i className="mdi mdi-star me-1"></i>{cell.value}
      </span>
    )
  } else {
    return (
      <span className="bg-danger font-size-12 badge">
        <i className="mdi mdi-star me-1"></i>{cell.value}
      </span>
    )
  }
}

const TimeStamp = (cell) => {
    const formattedDate = `${cell.value.substring(0, 4)}`
      +`/${cell.value.substring(4, 6)}`
      +`/${cell.value.substring(6, 8)}`
      +` ${cell.value.substring(8, 10)}`
      +`:${cell.value.substring(10, 12)}`
      +`:${cell.value.substring(12, 14)}`

    return formattedDate ? `${formattedDate}` : '';
};

const Total = (cell) => {
    return cell.value ? cell.value : '';
};

const PaymentStatus = (cell) => {
    return (
        <Badge
          className={"font-size-11 badge-soft-" + 
          (Number(cell.value) < 0.006 ? "success" : (Number(cell.value) < 0.008 ? "warning" : "danger"))}          
        >
          {cell.value}
        </Badge>
    )
};
const PaymentMethod = (cell) => {
    return (
        <span>
        <i
        className={
          (cell.value === "Paypal" ? "fab fa-cc-paypal me-1" : "" || 
          cell.value === "COD" ? "fab fas fa-money-bill-alt me-1" : "" ||
          cell.value === "Mastercard" ? "fab fa-cc-mastercard me-1" : "" ||
          cell.value === "Visa" ? "fab fa-cc-visa me-1" : ""
          )}
          />{" "}
            {cell.value}
        </span>
    )
};
export {
    CheckBox,
    OrderId,
    BillingName,
    Date,
    Total,
    PaymentStatus,
    PaymentMethod,
    Rating,
    TimeStamp,
};