import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  Button,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Form
} from "reactstrap"
import Select from "react-select";

// uuid
import { v4 as uuidv4 } from 'uuid';

// Custom Axios
import axiosInstance from 'utils/axiosInstance';

//redux
import { useSelector, useDispatch } from "react-redux";

// Link down to component <LatestTransaction />
import LatestTransaction from "./LatestTransaction";

const langOneOptions = [ 
  {label:"繁體中文", value:"繁體中文"},
  {label:"簡體中文", value:"簡體中文"},
  {label:"英文", value:"英文"}, 
  {label:"日文", value:"日文"},
  {label:"韓文", value:"韓文"},
  {label:"德文", value:"德文"},
  {label:"法文", value:"法文"},
  {label:"西班牙文", value:"西班牙文"},
  {label:"越南文", value:"越南文"},
  {label:"印尼文", value:"印尼文"},
  {label:"泰文", value:"泰文"},
];

const langTwoOptions = [ 
  {label:"繁體中文", value:"繁體中文"},
  {label:"簡體中文", value:"簡體中文"},
  {label:"英文", value:"英文"}, 
  {label:"日文", value:"日文"},
  {label:"韓文", value:"韓文"},
  {label:"德文", value:"德文"},
  {label:"法文", value:"法文"},
  {label:"西班牙文", value:"西班牙文"},
  {label:"越南文", value:"越南文"},
  {label:"印尼文", value:"印尼文"},
  {label:"泰文", value:"泰文"},
];


// Main Dashboard-translate ActivityComp Component
const ActivityComp = (props) => {


  /*---- States and handle Changes ----*/

  const [selectedLangOne, setSelectedLangOne] = useState("");
  const [selectedLangTwo, setSelectedLangTwo] = useState("");

  const handleSelectLangOneChange = (index) => { setSelectedLangOne(langOneOptions[index]?.value) };
  const handleSelectLangTwoChange = (index) => { setSelectedLangTwo(langTwoOptions[index]?.value) };

  const [selectedDb, setSelectedDb] = useState([]);
  const [selectedBaseDb, setSelectedBaseDb] = useState([]);

  const handleSelectDbChange = (index) => { setSelectedDb([dbOptions[index]._id]) };
  const handleSelectBaseDbChange = (index) => { setSelectedBaseDb([baseDbOptions[index]._id]) };

  const [newQuestion, setNewQuestion] = useState("");
  const placeholderText = "點選送出提問開始生成翻譯，再按一次取消";
  const [newResponseBytelingo, setNewResponseBytelingo] = useState(placeholderText);
  const [newResponseSimple, setNewResponseSimple] = useState(placeholderText);

  const [responseStatus, setResponseStatus] = useState("inactive"); // retrieving / inactive
  const [userWarning, setUserWarning] = useState("");

  /*---- States Tail handle Changes Tail ----*/



  /*---- Getting Databases Data ----*/

  /*
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: 'GET_BASE_DATABASES' });
  }, [dispatch]);
  */

  const { baseDatabases: baseDbOptions } = useSelector(state => ({
    baseDatabases: state.databases.baseDatabases,
  }));

  // state.projects.projects is set through fetchUserData
  const { databases: dbOptions } = useSelector(state => ({
    databases: state.databases.databases,
  }));

  /*---- Getting Databases Data Tail ----*/



  async function fetchAndProcessStream(endpoint, translationId) {

    const fetchedData = {};

    try {

      console.log('selectedLangOne:', selectedLangOne);
      console.log('selectedLangTwo:', selectedLangTwo);

      const response = await axiosInstance.post(endpoint, 
        {
          translationId,
          sortedDbList: selectedDb,
          sortedBaseDbList: selectedBaseDb,
          langOne: selectedLangOne || "繁體中文", 
          langTwo: selectedLangTwo || "英文", 
          question: newQuestion,
        },
        {
          headers: { 'Content-Type': 'application/json' },
          onDownloadProgress: (evt) => {
            const currentResponse = evt.event.target.response;
            // console.log(currentResponse);

            // Process stream data here
            let splitIndex = currentResponse.indexOf('\n\n\n\n');

            if (splitIndex !== -1) {
              let textPart = currentResponse.substring(0, splitIndex);
              let jsonPart = currentResponse.substring(splitIndex + 4);

              if(endpoint===`/knovia/transbyte`){
                //console.log('Byte text:', textPart);
                setNewResponseBytelingo(textPart);
              } else {
                //console.log('Simple text:', textPart);
                setNewResponseSimple(textPart);
              }

              try {
                const { responseBytelingo, responseSimple } = JSON.parse(jsonPart);

                fetchedData['responseBytelingo'] = responseBytelingo;
                fetchedData['responseSimple'] = responseSimple;

              } catch (e) {
                console.log('Error parsing JSON:', e);
              }

            } else {
              if(endpoint===`/knovia/transbyte`){
                //console.log('Byte text:', currentResponse);
                setNewResponseBytelingo(currentResponse);
              } else {
                //console.log('Simple text:', currentResponse);
                setNewResponseSimple(currentResponse);
              }
            }

          }

        }
      );

      return fetchedData;

    } catch (error) {
      console.error('Error fetching stream:', error);
    }

  }

  async function onSubmitQuestion(e) {
    e.preventDefault();

    console.log('newQuestion:', newQuestion);

    if(newQuestion){

        setUserWarning("");
        setNewResponseBytelingo("");
        setNewResponseSimple("");
        setResponseStatus('retrieving');

        /*--- Start Streaming Part ---*/

        try {

          const translationId = uuidv4();

          const [response1, response2] = await Promise.all([
            fetchAndProcessStream(`/knovia/transbyte`, translationId),
            fetchAndProcessStream(`/knovia/transsimple`, translationId)
          ]);

          // Handle the responses from both streams here
          console.log('Finished streaming transByte:', response1);
          console.log('Finished streaming transSimple:', response2);

        } catch (error) {
          console.error('Error:', error);
        }

        setResponseStatus('inactive');

    } else {
      setUserWarning("無法送出空白提問");
    }

  }

  const cancelResponse = () => { setResponseStatus('inactive'); }



  /*---- Auto-Resize TextAreas ----*/

  function useAutoResize(initialValue = "") {
    const [value, setValue] = useState(initialValue);
    const textareaRef = useRef(null);

    const adjustTextareaHeight = useCallback(() => {
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto"; 
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; 
      }
    }, [textareaRef]);

    useEffect(() => {
      setValue(initialValue);
      adjustTextareaHeight();
    }, [initialValue, adjustTextareaHeight]);

    useEffect(() => {
      adjustTextareaHeight();
    }, [value, adjustTextareaHeight]);

    console.log('123');

    return {
      value,
      onChange: (e) => {
        setValue(e.target.value);
      },
      textareaRef,
    };
  }

  /* question */
  const questionProps = useAutoResize(newQuestion);
  const { textareaRef: questionRef, ...questionInputProps } = questionProps;

  /* TheChart */
  const theChartProps = useAutoResize(newResponseBytelingo);
  const { textareaRef: theChartRef, ...theChartInputProps } = theChartProps;
  const theChartTextColor = newResponseBytelingo === placeholderText ? "#b5b5b5" : "black";

  /* ChatGPT */
  const chatGPTProps = useAutoResize(newResponseSimple);
  const { textareaRef: chatGPTRef, ...chatGPTInputProps } = chatGPTProps;
  const chatGPTTextColor = newResponseSimple === placeholderText ? "#b5b5b5" : "black";

  /*---- Auto-Resize TextAreas Tail ----*/



  /*---- Getting Past Translation Records ----*/

  const [interactionList, setInteractionList] = useState([]);

  const getInteractionList = () => {

    console.log("refreshing datain getInteractionList...");
    const url = `/knovia/interactions`;

    axiosInstance.get(url)
      .then((response) => {
        setInteractionList(response.data);
        console.log('applicationList', response.data);
      })
      .catch((err) => console.error(err));

  }

  useEffect(() => {
    if (responseStatus === 'inactive'){
      console.log('Refreshing Interaction List...');
      getInteractionList();
    }
  }, [responseStatus]);

  /*---- Getting Past Translation Records Tail ----*/




  /*--- Return Component ---*/

  return (

    <React.Fragment>
      <Card>
        <CardBody style={{ padding: "2rem 5rem" }}>


          {/* Form containing Options and TextAreas */}

            <Row style={{ "marginTop": "0.5rem" }}>

              {/* 翻譯選項 */}
              <Col sm={3}>

                <Row>


                  {/* 選項標題 */}
                  <Col sm={12}>
                    <CardTitle className="mb-3">選項</CardTitle>
                  </Col>
                  {/* 選項標題 Tail */}


                  {/* 來源語言 */}
                  <Col sm={12}>
                    <div className="mb-3">

                      <UncontrolledDropdown>
                        <DropdownToggle tag="button" className="btn btn-soft-secondary">
                          {`來源語言： ${selectedLangOne || "(預設繁體中文)"}`} <i className="mdi mdi-chevron-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {/* 檢視參考資料 */}
                          {langOneOptions.map((langOne, index) => (
                            <DropdownItem key={`dropdown-${index}`} onClick={() => handleSelectLangOneChange(index)} >
                              {langOne.value}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>

                    </div>
                  </Col>
                  {/* 來源語言 Tail */}


                  {/* 目標語言 */}
                  <Col sm={12}>
                    <div className="mb-3">
                      
                      <UncontrolledDropdown>
                        <DropdownToggle tag="button" className="btn btn-soft-primary">
                          {`目標語言： ${selectedLangTwo || "(預設英文)"}`} <i className="mdi mdi-chevron-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {/* 檢視參考資料 */}
                          {langTwoOptions.map((langTwo, index) => (
                            <DropdownItem key={`dropdown-${index}`} onClick={() => handleSelectLangTwoChange(index)} >
                              {langTwo.value}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>

                    </div>
                  </Col>
                  {/* 目標語言 Tail */}

                  {/* 底層資料庫 */}
                  <Col sm={12}>
                    <div className="mb-3">
                      
                      <UncontrolledDropdown>
                        <DropdownToggle tag="button" className="btn btn-soft-warning" style={{ textAlign: "left" }}>
                          底層資料庫：
                          {selectedBaseDb[0]?.dbName ? <><br />{selectedBaseDb[0]?.dbName}</> : "(未設定)"}
                          <i className="mdi mdi-chevron-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {/* 檢視參考資料 */}
                          {baseDbOptions.map((baseDb, index) => (
                            <DropdownItem key={`dropdown-${index}`} onClick={() => handleSelectBaseDbChange(index)} >
                              {baseDb.dbName}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>

                    </div>
                  </Col>
                  {/* 底層資料庫 Tail */}

                  {/* 額外參考資料庫 */}
                  <Col sm={12}>
                    <div className="mb-3">
                      
                      <UncontrolledDropdown>
                        <DropdownToggle tag="button" className="btn btn-soft-success" style={{ textAlign: "left" }}>
                          額外參考資料庫：
                          {selectedDb[0]?.dbName ? <><br />{selectedDb[0]?.dbName}</> : "(未設定)"}
                          <i className="mdi mdi-chevron-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {/* 檢視參考資料 */}
                          {dbOptions.map((db, index) => (
                            <DropdownItem key={`dropdown-${index}`} onClick={() => handleSelectDbChange(index)} >
                              {db.dbName}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>

                    </div>
                  </Col>
                  {/* 額外參考資料庫 Tail */}

                </Row>

              </Col>
              {/* 語言對選項 Tail */}


              {/* 輸入文本 & 送出提問 */}
              <Col sm={9}>

                <Row style={{ width: "100%", height: "100%" }}>

                  <Col 
                    sm={12} 
                    style={{ 
                      display: "flex", 
                      justifyContent: "center",
                      fontSize: "1.1rem",
                      fontWeight: "100",
                      padding: "0.2rem 0rem",
                    }}
                  >
                    <p style={{ margin: "0rem" }}>輸入文本</p>
                  </Col>

                  <Col sm={12} style={{ paddingLeft: "10%", paddingRight: "10%" }}>
                    <div 
                      className="toast fade show" 
                      style={{
                        width: "100%", 
                        border: "1px solid #a6b0cf", 
                        "--bs-toast-box-shadow": "0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.175)",
                      }} 
                      role="alert"
                    >
                      <div className="toast-body">
                      <textarea
                        ref={questionRef}
                        style={{ 'minHeight':'5rem', 'border':'none', 'resize': 'none' }}
                        className="form-control" 
                        id="question"
                        {...questionInputProps}
                        onChange={(e) => {
                          setNewQuestion(e.target.value);
                          questionInputProps.onChange(e);
                        }}
                      />
                      </div>
                    </div>
                  </Col>

                  {/* 送出提問 & userWarning */}
                  <Col sm={12} className="mt-3">

                    <Row>

                      {/* 送出提問 button & userWarning */}
                      <Col sm={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                        {responseStatus === "inactive" ? (
                          <button type="button" onClick={onSubmitQuestion} className="btn btn-rounded btn-success ">
                            <i className="bx bx-check-double font-size-16 align-middle me-2"></i> 
                              送出提問
                          </button>
                        ) : null}
                        {responseStatus === "retrieving" ? (
                          <button type="button" onClick={cancelResponse} className="btn btn-rounded btn-success">
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                              取得回應中
                          </button>
                        ) : null}

                        {userWarning!==""?(
                          <div 
                            className="alert alert-danger fade show"
                            style={{"marginTop":"1rem"}}
                            role="alert">
                            <p>{userWarning}</p>
                          </div>      
                          ) : <></>
                        }    
                        
                      </Col>

                    </Row>

                  </Col>
                  {/* 送出提問 & userWarning Tail */}

                </Row>

              </Col>

            </Row>
            {/* 輸入文本 & 送出提問 Tail */}


            {/* 分隔線 */}
            <hr style={{ borderTop: "1px solid silver" }}/>


            {/* 提問 & 回應內容標題 */}
            <Row style={{"marginTop": "0.5rem"}}>

              {/* 左欄 - 系統生成結果 */}
              <Col sm={6} style={{"display": "flex", "alignItems": "flex-start", "justifyContent": "center"}}>
                
                <Row style={{width: "100%"}}>

                  <Col 
                    sm={12} 
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "1.1rem",
                      fontWeight: "100",
                      padding: "0.2rem 0rem",
                      marginBottom: "0.6rem",
                    }}
                  >
                    <p style={{ margin: "0rem" }}>翻譯結果 - TheChart.AI</p>
                  </Col>

                  <Col sm={12}>
                    <div 
                      className="toast fade show" 
                      style={{
                        "width": "100%", 
                        "border": "1px solid #a6b0cf", 
                        "--bs-toast-box-shadow": "0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.175)"
                      }} 
                      role="alert"
                    >
                      <div className="toast-body">
                        <textarea
                          ref={theChartRef}
                          style={{ 
                            minHeight:'10rem', 
                            border:'none', 
                            resize:'none', 
                            color:theChartTextColor,
                          }}
                          className="form-control" 
                          id="thechartresponse"
                          {...theChartInputProps}
                          readOnly={true}
                        />
                      </div>
                    </div>
                  </Col>

                </Row>

              </Col>
              {/* 左欄 - 系統生成結果 Tail */}

              {/* 右欄 - ChatGPT 直接生成結果 */}
              <Col sm={6} style={{"display": "flex", "alignItems": "flex-start", "justifyContent": "center"}}>
                
                <Row style={{width: "100%"}}>

                  <Col 
                    sm={12} 
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "1.1rem",
                      fontWeight: "100",
                      padding: "0.2rem 0rem",
                      marginBottom: "0.6rem",
                    }}
                  >
                    <p style={{ margin: "0rem" }}>翻譯結果 - ChatGPT4</p>
                  </Col>

                  <Col sm={12}>
                    <div 
                      className="toast fade show" 
                      style={{
                        "width": "100%", 
                        "border": "1px solid #a6b0cf", 
                        "--bs-toast-box-shadow": "0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.175)"
                      }} 
                      role="alert"
                    >
                      <div className="toast-body">
                        <textarea
                          ref={chatGPTRef}
                          style={{ 
                            minHeight:'10rem', 
                            border:'none', 
                            resize:'none', 
                            color:chatGPTTextColor,
                          }}
                          className="form-control" 
                          id="chatresponse"
                          {...chatGPTInputProps}
                          readOnly={true}
                        />
                      </div>
                    </div>
                  </Col>

                </Row>

              </Col>
              {/* 右欄 - ChatGPT 直接生成結果 Tail */}

            </Row>
            {/* 提問 & 回應內容標題 Tail */}


        </CardBody>

      </Card>

      <Row>
        <Col lg="12">
          <LatestTransaction interactionList={interactionList}/>
        </Col>
      </Row>

    </React.Fragment>
  );
};

export default ActivityComp;

