import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone";
import Select from "react-select";
import { 
  Alert,
  Button, 
  Card, 
  CardBody, 
  CardTitle, 
  Col, 
  Container, 
  Form, 
  FormGroup, 
  Input, 
  Label, 
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Progress,
} from "reactstrap";

// XLSX: read Excel Files
import * as XLSX from 'xlsx';

// Custom Axios
import axiosInstance from 'utils/axiosInstance';

// Custom Components
import UploadProgress from './UploadProgress';

// Custom Utils
import { getFileIcon, getUploadStatusColor } from './dbUtils';
import { Pagination } from 'utils/pagination';
import { uploadImg } from 'utils/imageUtils';
import { dateGen, formatBytes } from 'utils/toolUtils';

// Redux
import { useSelector, useDispatch } from "react-redux";

// uuid
import { v4 as uuidv4 } from 'uuid';

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";


const typeList = [
  {label:"詞彙表 Glossary", value:"詞彙表 Glossary"},
  {label:"財報", value:"財報"},
  {label:"年報", value:"年報"},
  {label:"永續報告書", value:"永續報告書"},
  {label:"議事手冊", value:"議事手冊"},
  {label:"重大訊息", value:"重大訊息"},
  {label:"新聞稿", value:"新聞稿"},
];

// Define the languages object
const languages = {
  zh: '中文',
  en: '英文',
  ja: '日文',
  ko: '韓文',
  de: '德文',
  fr: '法文',
  es: '西班牙文',
  vi: '越南文',
  id: '印尼文',
  th: '泰文',
};



/*---- Main Component ----*/
const DatabaseCreate = () => {


  /*---- Title and Initial Setup ----*/

  document.title = "建立資料庫：上傳檔案 | TheChart.AI";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userData } = useSelector(state => ({
    userData: state.user.userData,
  }));
  
  /*---- Title and Initial Setup Tail ----*/

  



  /*---- Form fields States and Changes ----*/

  const [dbName, setDbName] = useState('');
  const [dbDesc, setDbDesc] = useState('');

  const handleDbNameChange = (e) => setDbName(e.target.value);
  const handleDbDescChange = (e) => setDbDesc(e.target.value);

  const [selectedGlossaryTypes, setSelectedGlossaryTypes] = useState([]);

  const handleGlossaryTypeChange = (event) => {
    const options = event.target.options;
    const values = [];
    for (let i = 0, len = options.length; i < len; i++) {
      if (options[i].selected) { values.push(options[i].value) }
    }
    setSelectedGlossaryTypes(values);
  };

  const [selectedClient, setSelectedClient] = useState(null); // {label:'客戶', value:'客戶'}
  const [selectedGlossaryClient, setSelectedGlossaryClient] = useState(''); // '客戶'
  const [isOtherClient, setIsOtherClient] = useState(false);
  const [otherClientName, setOtherClientName] = useState("");  

  const [langSource, setLangSource] = useState('');
  const [langTarget, setLangTarget] = useState('');
  const [isBaseCheckboxChecked, setIsBaseCheckboxChecked] = useState(false);

  const handleClientChange = (option) => { 
    if(option.value==="其他"){ setIsOtherClient(true); } 
    else { setIsOtherClient(false); }
    setSelectedClient(option);
    setSelectedGlossaryClient(option.label);
  };
  const handleOtherClientChange = (event) =>{
    setOtherClientName(event.target.value);
    setSelectedGlossaryClient(event.target.value);
  };
  const handleLangSourceChange = (event) => { setLangSource(event.target.value) };
  const handleLangTargetChange = (event) => { setLangTarget(event.target.value) };
  const handleBaseCheckboxChange = ()=>{ setIsBaseCheckboxChecked(!isBaseCheckboxChecked) }

  /*---- Form fields States and Changes Tail ----*/





  /*---- Retrieve / Add / Delete Client Options ----*/

  // label is the options shown on the page, value is what the program receives
  const [clientOptions, setClientOptions] = useState([]);

  // [{label:'客戶1', value:'客戶1'}, {label:'客戶2', value:'客戶2'}]
  useEffect(()=>{
    const newClientOptions = [
      {
        label: "選擇報告客戶",
        options: [
          ...(userData?.clients ? 
            userData.clients.map(c => ({ label: c.name, value: c.name }))
                           .sort((a, b) => a.label.localeCompare(b.label)) : 
            []),
          { label: "其他", value: "其他" },
        ]
      }
    ];
    setClientOptions(newClientOptions);
  },[userData.clients]);

  const handleAddClient = async () => {
    if (!otherClientName.trim()) {
      alert("不能新增空的客戶名稱");
      return;
    }

    try {
      const response = await axiosInstance.post('/knovia/add-client', { clientName: otherClientName });
      console.log('[Dashboard-dbexcel/db-create.js] Client set updated:', response.data);

      /*---- TODO: Should just update the store instead of fetching again ----*/
      dispatch({ type: 'FETCH_USER_DATA' });

      setSelectedClient({ label: otherClientName, value: otherClientName });
      setSelectedGlossaryClient(otherClientName);

      setOtherClientName('');
      setIsOtherClient(false);
      
    } catch (error) {
      console.error('Error adding client:', error);
    }
  };

  const handleDeleteClient = async () => {
    try {
      await axiosInstance.delete(`/knovia/clients/${selectedGlossaryClient}`);
      const updatedClients = userData.clients.filter(c => c.name !== selectedGlossaryClient);
      const updatedClientOptions = [
        {
          label: "選擇報告客戶",
          options: [
            ...updatedClients.map(c => ({ label: c.name, value: c.name }))
              .sort((a, b) => a.label.localeCompare(b.label)),
            { label: "其他", value: "其他" },
          ]
        }
      ];
      setClientOptions(updatedClientOptions);
      setSelectedClient(null);
      setSelectedGlossaryClient('');
    } catch (error) {
      console.error("Failed to delete client:", error);
    }
  };

  /*---- Retrieve / Add / Delete Client Options Tail ----*/






  /*---- Handle File dropped in the Dropzone ----*/

  // For Excel files
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [excelKeys, setExcelKeys] = useState([]);
  const [excelEntries, setExcelEntries] = useState([]);
  
  const [errorMessage, setErrorMessage] = useState(''); // For Showing file format error


  function handleAcceptedFiles(files) {

    // Only processing the first file selected
    const fileProcessingPromises = files.slice(0,1).map(file => {

      return new Promise((resolve, reject) => {

        // Filename in lowercase, pop() gets the last item of the array
        const fileType = file.name.split('.').pop().toLowerCase();

        // Only process Excel files
        if (fileType === 'xlsx' || fileType === 'csv' || fileType === 'xlsm') {

          setErrorMessage('');

          const fileReader = new FileReader();
          fileReader.readAsArrayBuffer(file);

          fileReader.onload = (e) => {
            const bufferArray = e.target.result;

            const wb = XLSX.read(bufferArray, { type: 'buffer' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];

            // Read the data into an array of arrays
            const rawData = XLSX.utils.sheet_to_json(ws, { header: 1 });

            // Extract keys (first row), ex: ['中文', '英文']
            const keys = rawData[0];

            // Transform the rest of the data, ex: {中文: '玉山', 英文: 'Yu Shan (Mount Jade)'}
            const data = rawData.slice(1).map(row => {
              const obj = {};
              row.forEach((cell, index) => {
                obj[keys[index]] = cell;
              });
              return obj;
            }).filter(d=>Object.keys(d).length!=0);

            resolve({ data, keys });
          };

          fileReader.onerror = (error) => { reject(error); };

        } else {
          setErrorMessage('請上傳 Excel 檔案');
          console.log('Not processing file', file.name, 'as this is a', fileType);
          resolve({});
        }

      }).then((d) => {

        if(d.data){
          // Create a new object with file's properties and additional attributes
          setExcelKeys(d.keys);
          setExcelEntries(d.data);
          console.log('Excel Keys:', d.keys);
          console.log('Excel Entries:', d.data);
          console.log('file.size:', file.size);

          return {
            ...file,
            filename: file.name,
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size)
          };
        } else {
          return {};
        }
      });
    });

    Promise.all(fileProcessingPromises).then(processedFiles => {
      setSelectedFiles(processedFiles);
    });

  }

  /*---- Handle File dropped in the Dropzone Tail ----*/

  


  /*---- Processing Database Profile Image ----*/

  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState('還沒有選擇圖片喔');
  const [imageUrl, setImageUrl] = useState(''); // 顯示伺服器端回傳預覽圖片 url
  const [uploadStatus, setUploadStatus] = useState(''); // 圖片上傳狀態
  const fileInputRef = useRef(null);  // The original button for choosing image file
  const [imgUploadLoading, setImgUploadLoading] = useState(false);

  // OnClick handler for the beautified choosing image file button
  const handleButtonClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    setUploadStatus('');
    console.log("Selected file:", event.target.files[0]);
    setFile(event.target.files[0]);
  };

  useEffect(() => {
    const performUpload = async () => {
      if (file) {
        // Reset upload status
        setUploadStatus('');
        setImgUploadLoading(true);
        try{
          const date14string = await dateGen();
          const newfilename = date14string + '_' + file.name;
          setFilename(newfilename);
          const {newImageUrl, uploadStatus} = await uploadImg(newfilename, file);
          setUploadStatus(uploadStatus);
          setImageUrl(newImageUrl);
          setImgUploadLoading(false);
        } catch(error){
          console.log('Error uploading image:', error);
          setImgUploadLoading(false);
        }
      }
    };

    performUpload();
  }, [file]);

  /*---- Processing Database Profile Image Tail ----*/



  /*----- Create New Database -----*/

  const chunkSize = 100;
  //const speed = 100; // Speed of progress bar
  const baseTime = 0.3; // base time in seconds
  const additionalTimePer1000Entries = 6.5; // additional time in seconds per 1000 entries

  const [uploadLoading, setUploadLoading] = useState(false);
  const [createWarnings, setCreateWarnings] = useState("");
  const [progressDuration, setProgressDuration] = useState(baseTime);

  const calculateDuration = (totalEntries) => {
    const additionalTime = Math.floor(totalEntries / 1000) * additionalTimePer1000Entries;
    return baseTime + additionalTime;
  };

  const sendChunk = async (chunk, transactionId, isFinalChunk = false, dbData) => {

    // submitted entries must have both value in langSource and value in langTarget
    // submitEntries is in the format of [{中文:'蘋果', English:'apple'}, {中文:'香蕉', English:'banana'}, ...]
    // ex: excelKeys[0]=中文, excelKeys[1]=English
    const submitEntries = chunk
      .filter(item =>
        (item[excelKeys[0]] && item[excelKeys[1]])
      )
      .map(item => ({
        [langSource]: item[excelKeys[0]] || '',
        [langTarget]: item[excelKeys[1]] || ''
      }));

    console.log("This chunk of submitEntries:", submitEntries);

    const chunkData = {
      ...dbData,
      entries: submitEntries,
      isChunk: !isFinalChunk,
      transactionId
    };

    try {
      const response = await axiosInstance.post(`/knovia/db`, chunkData, 
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );

      console.log("Chunk sent, server response:", response.data);
      return true;

    } catch (error) {
      const errorMessage = error.response && error.response.data && error.response.data.message;
      if (errorMessage === 'A Langdb with the same name already exists for this user.') {
        setCreateWarnings("無法建立名稱重複的資料庫，請另取資料庫名稱");
      } else {
        setCreateWarnings(`Error sending data to the server: ${errorMessage || "Please try again."}`);
      }
      console.error("Error sending chunk to server:", error);
      return false;

    }
  };

  const createDb = async (e) => {

    e.preventDefault();

    let missingFields = [];
    const chunkEntries = excelEntries.filter(item => (item[excelKeys[0]] && item[excelKeys[1]]));
    if (!dbName) missingFields.push("資料庫名稱");
    if (!selectedGlossaryClient) missingFields.push("資料庫客戶");
    if (!selectedGlossaryTypes.length) missingFields.push("資料庫類型");
    if (!langSource) missingFields.push("來源語言");
    if (!langTarget) missingFields.push("目標語言");
    if (!filename) missingFields.push("上傳檔案");

    if (missingFields.length > 0) {
      setCreateWarnings(`這些欄位不可空白: ${missingFields.join(", ")}`);
      return;
    } else if (chunkEntries.length === 0) {
      setCreateWarnings("上傳 Excel 檔無有效資料");
      return;
    }

    const dbData = {
      dbName,
      langSource,
      langTarget,
      img: filename,
      dbClient: selectedGlossaryClient,
      tags: selectedGlossaryTypes,
      isBaseDb: isBaseCheckboxChecked
    };

    console.log("Creating Database with metadata:", dbData);

    const transactionId = uuidv4();
    let operationSuccessful = true; // Flag to check if the operation was successful
    

    const uploadCount = excelEntries.length;
    /*---- Sending Chunks ----*/
    setUploadLoading(true);
    setCreateWarnings("");
    setProgressDuration(calculateDuration(uploadCount));

    for (let i = 0; i < uploadCount; i += chunkSize) {
      const chunk = excelEntries.slice(i, i + chunkSize);
      const isFinalChunk = (i + chunkSize >= uploadCount);
      try {
        const success = await sendChunk(chunk, transactionId, isFinalChunk, dbData);
        if (!success) {
          operationSuccessful = false;
          if(!createWarnings){ setCreateWarnings(`發生未預期的錯誤，請重新建立資料庫`) }
          break; // Exit the loop if a chunk fails to send
        }
      } catch (error) {
        console.error("Error in createDb:", error);
        setCreateWarnings(`發生未預期的錯誤: ${error.message}`);
        operationSuccessful = false;
        break; // Exit the loop on error
      }
    }
    setUploadLoading(false);
    /*---- Sending Chunks ----*/

    if (operationSuccessful) {
      dispatch({ type: 'FETCH_USER_DATA' });
      navigate('/dashboard-menu');
    } else if (!operationSuccessful) {
      // TODO: Handle the case where the operation was not successful
      // possibly clean up or alert the user
      console.log("Operation was not successful. Please check the warnings and try again.");
    }

  };

  /*----- Create New Database Tail -----*/




  /*---- Pagination START ----*/

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(30);
  const [totalPages, setTotalPages] = useState(1);
  const [currentItems, setCurrentItems] = useState([]);

  // Updating current items, items viewable in current page
  useEffect(() => {
    const newLastItem = currentPage * itemsPerPage;
    const newFirstItem = newLastItem - itemsPerPage;
    setCurrentItems(excelEntries.slice(newFirstItem, newLastItem));
  }, [currentPage, itemsPerPage, excelEntries]);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  // Set and Reset totalPage when paragraphs changes
  useEffect(() => { setTotalPages(Math.ceil(excelEntries.length / itemsPerPage)) }, [excelEntries, itemsPerPage]);
  // If itemsPerPage changed by user
  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(Number(value)); // event.target.value is the chosen value
    setCurrentPage(1); // Reset to the first page
  };
  const itemsPerPageMappings = {
    '30': '30',
    '60': '60',
    '120': '120',
  }
  
  /*---- Pagination TAIL ----*/



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

          {/* Render Breadcrumbs */}
          <Breadcrumbs title="資料庫" breadcrumbItem="建立資料庫" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">新資料庫：上傳檔案</CardTitle>
                  <Form>

                    {/* 資料庫名稱 */}
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="dbname"
                        className="col-form-label col-lg-2"
                      >
                        資料庫名稱
                      </Label>

                      <Col lg="10">
                        <Input
                          id="dbname"
                          name="dbname"
                          type="text"
                          className="form-control"
                          placeholder="輸入資料庫名稱"
                          value={dbName}
                          onChange={handleDbNameChange}
                        />
                      </Col>
                    </FormGroup>

                    {/* 是否為底層資料庫 */}
                    {userData && userData?.role && userData.role==='admin' && (
                      <FormGroup className="mb-4" row>
                        <Col lg="2"></Col>
                        <Col lg="10">
                          <div>
                            <input
                              type="checkbox"
                              id="baseCheckBox"
                              checked={isBaseCheckboxChecked}
                              onChange={()=>{}}
                              onClick={() => handleBaseCheckboxChange()}
                            />
                            <label style={{ marginLeft: "0.5rem" }} htmlFor="baseCheckBox">是底層資料庫？</label>
                          </div>
                        </Col>
                      </FormGroup>
                    )}

                    {/* Select Input for Glossary Client */}
                    <FormGroup className="mb-4" row>
                      <Label htmlFor="glossaryClient" className="col-form-label col-lg-2">
                        資料庫客戶
                      </Label>

                      <Col xs={8}>
                        <div className="mb-3">
                          <Select
                            id='glossaryClient'
                            name="glossaryClient"
                            onChange={handleClientChange}
                            options={clientOptions}
                            placeholder={'選擇報告客戶'}
                            value={selectedClient}
                          />
                        </div>
                      </Col>
                      <Col xs={2}>
                        <Button color="outline-danger" onClick={handleDeleteClient}>- 移除客戶</Button>
                      </Col>

                      {isOtherClient && 
                        (<>
                          <Label htmlFor="glossaryClient" className="col-form-label col-lg-2">
                          </Label>

                          <Col xs={8}>
                            <div className="input-group">
                              <Input
                                id="otherclientname"
                                name="otherclientname"
                                type="text"
                                className="form-control"
                                placeholder="輸入客戶名稱"
                                value={otherClientName}
                                onChange={handleOtherClientChange}
                              />
                            </div>
                          </Col>
                          <Col xs={2}>
                            <button className="btn btn-outline-success" style={{ zIndex:'0' }} type="button" onClick={handleAddClient}>
                              + 新增客戶
                            </button>
                          </Col>
                        </>)
                      }

                    </FormGroup>

                    {/* Multi-Select for Glossary Types */}
                    <FormGroup className="mb-4" row>
                      <Label htmlFor="glossaryType" className="col-form-label col-lg-2">
                        資料庫類型<br/>(使用 Ctrl/Shift 按鈕選取多個項目)
                      </Label>
                      <Col lg="10">
                        <Input
                          id="glossaryType"
                          name="glossaryType"
                          type="select"
                          multiple
                          value={selectedGlossaryTypes} // This should be an array of selected values
                          onChange={handleGlossaryTypeChange} // Handler function needs to be adjusted for multi-select
                        >
                          <optgroup label="選擇資料庫類型">
                          {typeList.map(type => (
                            <option key={type.value} value={type.value}>
                              {type.label}
                            </option>
                          ))}

                          {userData?.role==='admin' && 
                            [{label:'底層資料', value:'底層資料'}].map(type => (
                              <option key={type.value} value={type.value}>
                                {type.label}
                              </option>
                            ))
                          }
                          </optgroup>

                        </Input>
                      </Col>
                    </FormGroup>


                    {/*---- 選擇資料庫圖片 ----*/}
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectpic"
                        className="col-form-label col-lg-2"
                      >
                        資料庫圖片
                      </Label>
                      <Col lg="10">

                        {/* 選擇圖片按鈕 + 圖片名稱 */}
                        <Row>
                          <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                          />
                          <Col sm="3">
                            <button className="btn btn-outline-info" onClick={handleButtonClick}>
                            {imgUploadLoading &&
                              (<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>)
                            }
                              選擇圖片
                            </button>
                          </Col>
                          <Col sm="9" style={{display:"flex", alignItems:"center"}}>
                            <span>{filename}</span>
                          </Col>
                        </Row>

                        {/* 上傳圖片狀態 */}
                        <Row>
                          <Col sm="12">
                            {uploadStatus && (
                              <p style={{ color: getUploadStatusColor(uploadStatus) }}>
                                {uploadStatus}
                              </p>
                            )}
                          </Col>
                        </Row>

                        {/* 已選擇圖片預覽 */}
                        <Row>
                          <div>
                            {imageUrl && <img src={imageUrl} className="profile-pic" alt="Fetched" />}
                          </div>
                        </Row>

                      </Col>
                      
                    </FormGroup>


                    {/*---- 選擇來源語言 langSource ----*/}
                    <FormGroup className="mb-4" row>
                      <Label htmlFor="langSource" className="col-form-label col-lg-2">
                        來源語言
                      </Label>
                      <Col lg="10">
                        <Input
                          id="langSource"
                          name="langSource"
                          type="select"
                          className="form-control"
                          onChange={handleLangSourceChange} // Assume you have a handler function for this
                        >
                          <option value="">選擇來源語言</option>
                          {Object.entries(languages).map(([key, value]) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          ))}
                        </Input>
                      </Col>
                    </FormGroup>
                    {/*---- 選擇來源語言 langSource ----*/}


                    {/*---- 選擇目標語言 langTarget ----*/}
                    <FormGroup className="mb-4" row>
                      <Label htmlFor="langTarget" className="col-form-label col-lg-2">
                        目標語言
                      </Label>
                      <Col lg="10">
                        <Input
                          id="langTarget"
                          name="langTarget"
                          type="select"
                          className="form-control"
                          onChange={handleLangTargetChange} // Assume you have a handler function for this
                        >
                          <option value="">選擇目標語言</option>
                          {Object.entries(languages).map(([key, value]) => (
                            <option key={key} value={key}>
                              {value}
                            </option>
                          ))}
                        </Input>
                      </Col>
                    </FormGroup>
                    {/*---- 選擇目標語言 langTarget ----*/}

                  </Form>



                  {/*---- 上傳 Excel 檔 DropZone ----*/}
                  <Row className="mb-4">

                    <Label className="col-form-label col-lg-2">上傳 Excel 檔</Label>

                    {/* 檔案上傳 */}
                    <Col lg="10">
                      <Form>

                        {/* 上傳區塊 */}
                        <Dropzone
                          onDrop={acceptedFiles => { handleAcceptedFiles(acceptedFiles) }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone" style={{cursor:"pointer"}}>
                              <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="dz-message needsclick">
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>拖曳檔案到此或點擊上傳 Excel 檔</h4>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>

                        {/* 上傳檔案預覽 */}
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {selectedFiles[0]?.filename && selectedFiles.map((f, i) => {

                            let fileIcon='';
                            if(f.filename){ fileIcon = getFileIcon(f) }

                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >

                                <div className="p-2">
                                  <Row className="align-items-center">

                                    {/* 檔案預覽圖片 */}
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.filename}
                                        src={fileIcon || ''}
                                      />
                                    </Col>

                                    <Col>

                                      {/* 檔案名稱 */}
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.filename}
                                      </Link>

                                      {/* 檔案大小 */}
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>

                                    </Col>

                                  </Row>
                                </div>

                              </Card>
                            );
                          })}
                        </div>
                        {errorMessage ? <Alert color="danger">{errorMessage}</Alert> : null}

                      </Form>
                    </Col>

                  </Row>
                  {/*---- 上傳 Excel 檔 DropZone ----*/}




                  {/*---- Preview Excel Content ----*/}
                  <Row className="mb-4">

                    <Col xs="12" lg="2"
                      className="custom-item-per-page-col"
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "column",
                        justifyContent: "flex-start"
                      }}
                    >
                      <Label className="col-form-label">
                        資料庫內容預覽
                      </Label>

                      {/* Dropdown for selecting sort attribute */}
                      <UncontrolledDropdown>
                        <DropdownToggle tag="button" className="btn btn-outline-warning">
                          每頁顯示段落數：{itemsPerPage}
                        </DropdownToggle>
                        <DropdownMenu>
                          {Object.keys(itemsPerPageMappings).map(key => (
                            <DropdownItem key={key} onClick={() => handleItemsPerPageChange(key)}>
                              {itemsPerPageMappings[key]}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>

                    </Col>


                    <Col lg="10">
                      <div 
                        style={{
                          border: "1px dashed #cdcbcb", 
                          borderRadius: "0.5rem", 
                          padding: "1rem 1rem 0rem 1rem",
                          minHeight: "5rem",
                          marginTop: "0.3rem",
                        }}
                      >

                        {/* Rendering paragraphs */}
                        <table style={{ width: "100%" }}>
                          {/* 表格標題 */}
                          <thead>
                            {excelEntries && excelEntries.length>0?(
                              <tr>
                              <th style={{
                                padding: "0.8rem 0rem",
                                marginBottom: "0.5rem",
                              }}>#</th>
                              <th style={{ 
                                textAlign: "center",
                                padding: "0.8rem 0rem",
                                marginBottom: "0.5rem",
                                borderBottom: "2px solid #586c7b",
                                width: "50%",
                              }}>{`Source - ${languages[langSource]}`}</th>
                              <th style={{ 
                                textAlign: "center",
                                padding: "0.8rem 0rem",
                                marginBottom: "0.5rem",
                                borderBottom: "2px solid #586c7b",
                                width:"50%",
                              }}>{`Target - ${languages[langTarget]}`}</th>
                            </tr>
                          ):(<></>)}
                          </thead>
                          {/* 表格內容 */}

                          <tbody>
                            {currentItems && currentItems.map((item, index) => (
                              <tr key={index}>
                                <td style={{ padding: "0.5rem 0.7rem 0.5rem 0.3rem" }}>{((currentPage-1)*itemsPerPage)+index+1}</td>
                                <td style={{ borderBottom: "1px solid #9da5c6", padding:"0.5rem 1.5rem" }}>{item[excelKeys[0]]}</td>
                                <td style={{ borderBottom: "1px solid #9da5c6", padding:"0.5rem 1.5rem" }}>{item[excelKeys[1]]}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                      </div>
                    </Col>
                  </Row>
                  {/*---- Preview Excel Content ----*/}



                  {/* Pagination Controls */}
                  <div style={{ marginBottom:"1.5rem" }}>
                    <Pagination currentPage={currentPage} totalPages={totalPages} paginate={paginate} />
                  </div>
                  {/* Pagination Controls Tail */}



                  {createWarnings && <Alert color="danger">{createWarnings}</Alert> }
                  <UploadProgress uploadLoading={uploadLoading} duration={progressDuration} />


                  {/* 開啟新專案按鈕 */}
                  <Row className="justify-content-end">
                    <Col lg="10" style={{ display: "flex", justifyContent: "center" }}>
                      <Button type="submit" color="primary" onClick={createDb} disabled={uploadLoading}>
                        <span style={{ marginRight: uploadLoading?'0.3rem':'0rem' }}>建立資料庫</span>
                        {uploadLoading && 
                          (<i className="bx bx-loader bx-spin font-size-16 align-middle"></i>)
                        }
                      </Button>
                    </Col>
                  </Row>


                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>

  );
};

export default DatabaseCreate;



