import React, { useEffect, useRef, useState } from "react";
import withRouter from "components/Common/withRouter";
import { 
  Col, 
  Row, 
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
// uuid
import { v4 as uuidv4 } from 'uuid';

import axiosInstance from 'utils/axiosInstance';

import './Chatbot.css';
import chatIcon from '../../assets/images/chatbot.png';
// third-party
import { Client } from '@stomp/stompjs';

/*---- Example Message ----*/
/*
const messages = [
  { id: 1, text: "Hello, how can I help you?", sender: "received", time: "18:45"  },
  { id: 2, text: "I have a question about my order.", sender: "sent", time: "18:45"  },
  { id: 3, text: "Sure, what is your order number?", sender: "received", time: "18:46"  },
  { id: 4, text: "It's #1234.", sender: "sent", time: "18:46"  }
];
*/

// Function to parse text and convert it to JSX with bold formatting
const parseTextToJSX = (text) => {
  const parts = text.split(/(\*\*[^*]+\*\*)/);
  return parts.map((part, index) => {
    if (part.startsWith('**') && part.endsWith('**')) {
      return <strong key={index}>{part.slice(2, -2)}</strong>;
    }
    return <span key={index}>{part}</span>;
  });
};

const client = new Client();
const Chatbot = props => {

  /*---- Props and States ----*/

  const { thisCb, chatbotImg } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [conversationId, setConversationId] = useState(null);

  const [message, setMessage] = useState(''); // message within dialog input
  const [messages, setMessages] = useState([]); // the whole set of dialog

  //const [inputHeight, setInputHeight] = useState('');
  const [recipientId, setRecipientId] = useState('');
  const [sessionId, setSessionId] = useState('');
  const [tempCustomerId, setTempCustomerId] = useState('');

  const messagesEndRef = useRef(null);
  const inputTextareaRef = useRef(null);

  let jsonPart='', textPart='';

  /*---- Props and States Tail ----*/




  /*---- Text Window Related Settings ----*/

  // Use useEffect to scroll to the bottom every time messages change
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, [messages]); // Only runs when messages change

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const adjustTextareaHeight = () => {
    if (inputTextareaRef.current) {
      inputTextareaRef.current.style.height = "auto"; 
      if(inputTextareaRef.current.scrollHeight>36){
        inputTextareaRef.current.style.height = `${inputTextareaRef.current.scrollHeight}px`;
      } else {
        inputTextareaRef.current.style.height = '40px';
      }
    }
  };

  const handleChange = (e) => {
    setMessage(e.target.value);
    adjustTextareaHeight();
  };

  const toggleChatWindow = () => { setIsOpen(!isOpen) };
  const closeChatWindow = () => { setIsOpen(false) };

  /*---- Text Window Related Settings ----*/

  useEffect(()=>{
    console.log('Now messages:', messages);
  },[messages])

  // Function to convert Buffer to base64 string (if needed, but it shouldn't be in this case)
  const bufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };


  const sendMessage = async () => {
    // Reset the input field after sending the message
    setMessage('');
    inputTextareaRef.current.style.height = '40px';

    if (message.trim()) {

      /*---- Optimistic Update of user message ----*/

      const tempMessageId = Date.now(); // Temporary ID for optimistic UI update
      const tempBotMessageId = 'tempBot';
      let conversation, processedMessages, botImageMessages;

      let parsedData = {};
      let jsonPart, textPart;

      const optimisticUserMessage = {
        id: tempMessageId,
        text: message,
        sender: 'sent',
        time: new Date().toLocaleTimeString(),
      };

      // Add the optimistic user message
      setMessages(prevMessages => [...prevMessages, optimisticUserMessage]);

      /*---- Optimistic Update of user message ----*/


      /*---- Post and Receive streaming Response ----*/

      const data = { 
        sender: 'User',
        message, 
        chatbotId: thisCb._id,
        conversationId,
      };

      try {
        if (client?.connected) {
          // ws
          try {
              const chatSendMessageDTO = { message: message, agentId: recipientId, customerId: tempCustomerId };
              client?.publish({
                  destination: `/chatroom/${sessionId}/chat/${recipientId}/sendMessage`,
                  body: JSON.stringify(chatSendMessageDTO),
              });
          } catch (error) {
              console.error("[src/api/api.js] Error in userLogin", error);
              return Promise.reject(error);
          }
        } else {
          const response = await axiosInstance.post(`/knovia/conversations`, data,
            {
              headers: { 'Content-Type': 'application/json' },
              onDownloadProgress: (evt) => {

                const currentResponse = evt.event.target.response.replace('undefined', '');

                // Process stream data here
                let splitIndex = currentResponse.indexOf('\n\n\n\n');

                if (splitIndex !== -1) {
                  textPart = currentResponse.substring(0, splitIndex);
                  jsonPart = currentResponse.substring(splitIndex + 4);

                  console.log('Text Part:', textPart);
                  console.log('Json Part:', jsonPart);

                  // Get JSON Part
                  try {
                    if (jsonPart.trim()) {
                      const parsedData = JSON.parse(jsonPart);

                      // Assuming parsedData contains the necessary information
                      ({ conversation, processedMessages, botImageMessages } = parsedData);

                      // Check and update conversation ID if needed
                      if (!conversationId && conversation._id) {
                        setConversationId(conversation._id);
                      }

                      if(processedMessages){
                        // Update the messages in the UI
                        setMessages(prevMessages => {

                          // Filter out the loading and optimistic messages
                          let updatedMessages = prevMessages.filter(message => message.id !== tempMessageId && message.id !== tempBotMessageId);

                          // Add the user message confirmed by the server
                          if (processedMessages.userMessage) {
                            updatedMessages.push({
                              ...optimisticUserMessage,
                              id: processedMessages.userMessage._id, // Update ID with the one from server
                            });
                          }

                          // Add the new bot message
                          if (processedMessages.botMessage) {
                            updatedMessages.push({
                              id: processedMessages.botMessage._id,
                              text: textPart,
                              sender: 'received',
                              time: new Date().toLocaleTimeString(), // Adjust the time as per your need
                            });
                          }
                          console.log('botImageMessages:', botImageMessages);
                          if (botImageMessages && botImageMessages.length > 0) {
                            
                            botImageMessages.forEach((botImageMessage, index) => {
                              const base64Image = botImageMessage.image.data;
                              console.log('HERE21 base64Image:', base64Image);
                              const imageUrl = `data:${botImageMessage.image.contentType};base64,${base64Image}`;

                              updatedMessages.push({
                                id: `${processedMessages.botMessage._id}-image-${index}`,
                                text: botImageMessage.text,
                                imageUrl: imageUrl,
                                sender: 'received',
                                time: new Date().toLocaleTimeString(),
                              });
                            });
                          }

                          return updatedMessages;
                        });
                      }

                    }
                    
                  } catch (e) {
                    console.log('error', e);
                  }

                } else {
                  //console.log('Streaming:', currentResponse);
                  // Update the messages in the UI
                  setMessages(prevMessages => {
                    // Filter out the previous incomlete bot message
                    let updatedMessages = prevMessages.filter(message => message.id !== tempBotMessageId);
                    let newStreamMessage = {};

                    // Add the new bot message
                    if (currentResponse) {
                      newStreamMessage = {
                        id: tempBotMessageId,
                        text: currentResponse,
                        sender: 'received',
                        time: new Date().toLocaleTimeString(), // Adjust the time as per your need
                      }
                    }

                    return [...updatedMessages, newStreamMessage];
                  });
                }


              }
            }
          );
        }
        

      } catch (error) {
        console.error('Error fetching stream:', error);
      }
    }

  };
  const conversationHandle = (progressEvent) => {};

  /*
  const handleBotMessage = (message) => {
    if (message.imageUrl) {
      const imageElement = document.createElement('img');
      imageElement.src = message.imageUrl;
      document.getElementById('chat').appendChild(imageElement);
    } else {
      const textElement = document.createElement('p');
      textElement.textContent = message.text;
      document.getElementById('chat').appendChild(textElement);
    }
  };
  */

  /*---- Modal viewing the images ----*/

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageTitle, setSelectedImageTitle] = useState('');

  const toggleModal = () => setModalOpen(!modalOpen);

  const onImageClick = (imageData, imageTitle='瀏覽圖片') => {
    setSelectedImageTitle(imageTitle);
    setSelectedImage(imageData);
    setModalOpen(true);
  };

  const connectToHumanSupport = async () => {
    const answer = confirm('要轉真人客服嗎？');
    if(answer){
      const optimisticUserMessage = {
        id: uuidv4(),
        text: '正在為您轉接文字客服專員，請稍候，不要離開喔~',
        sender: 'received',
        time: new Date().toLocaleTimeString(),
      };

      // Add the optimistic user message
      setMessages(prevMessages => [...prevMessages, optimisticUserMessage]);
      try {
        // 發送通知
        const data = {
          chatbotId: thisCb._id,
          conversationId,
        };
        const response = await axiosInstance.post(`/knovia/notifications/email-support`, data);
        const {data: {data: { status, sessionId, tempUserId, agentId}}} = response;
        // console.log('response.data', status, sessionId, tempUserId, agentId);
        // 綁定客服
        setRecipientId(agentId);
        setSessionId(sessionId);
        setTempCustomerId(tempUserId);
        // 連接聊天室
        const wsURL = `${(window.location.protocol === 'https:') ? 'wss://' : 'ws://'}${process.env.REACT_APP_WEB_SOCKET_URL}`;
        client.configure({
            // brokerURL: 'ws://localhost:8080/websocket',
            brokerURL: wsURL,
            debug:(str) => console.log(str),
            onConnect: () => {
                client.subscribe(`/topic/${sessionId}/chat/${tempUserId}`, message => {
                    console.log(`Received: `, message.body);
                    const res = JSON.parse(message.body);
                    const newMessageId = uuidv4();
                    const optimisticUserMessage = {
                      id: newMessageId,
                      text: res.message,
                      sender: "received",
                      time: new Date().toLocaleTimeString(),
                    };
                    console.log(`optimisticUserMessage: ${optimisticUserMessage}`);
                    // Add the optimistic user message
                    setMessages(prevMessages => [...prevMessages, optimisticUserMessage]);
              }, {sessionId: sessionId, agentId: '9999'});
            },
            onWebSocketError: (error) => {
                console.error('Error with websocket', error);
            },
            // reconnectDelay: 0
        });
        // Connect to the WebSocket server
        client.activate();
      } catch (error) {
        console.error(error);
      }
    }
  };
  /*---- Modal viewing the images Tail ----*/


  
  /*---- Main ChatBot Component ----*/

  return (
    <React.Fragment>
      <div className="chatbot-container">

        <div className={`chat-window ${isOpen ? 'open' : ''}`} style={{ right: '15vw' }}>
          {/* Chat Header */}
          <div className="chat-header">
            <Row style={{ width:'100%', marginLeft:'0px' }}>
              <Col sm={10} 
                style={{ 
                  display: 'flex',
                  alignContent: 'center',
                  flexWrap: 'wrap'
                }}
              >
                <h5 className="chat-header-text">{ thisCb.cbName }</h5>
                {/*<p className="chat-header-text" style={{fontSize: "10px"}}>*歡迎問我有關企業大師的問題</p>*/}
              </Col>
              <Col sm={2}>
                <button className="close-chat" onClick={closeChatWindow}>&times;</button>
              </Col>
            </Row>
          </div>

          {/* Chat Messages */}
          <div className="chat-messages" ref={messagesEndRef}>

            {
              [
                {
                  sender:'received', 
                  text:`您好，我是 { ${thisCb.cbName} }，歡迎與我對話！
                  我會根據目前頁面上已經添加的操作資訊進行回覆，您也可以新增、編輯、刪除頁面上的資訊，檢視回覆內容會產生什麼改變。`
                }, 
                ...messages
              ].map((messageItem, index) => {
              // If the message is a loading placeholder, render the loading dots instead
              if (messageItem.sender === 'loading') {
                return (
                  <div key={`${index}${messageItem.id}`} className="dialog-row received-side">
                    <div className="dialog-box">
                      <div className="loading-dots">
                        <div className="dot" style={{ '--dot-index': 0 }}></div>
                        <div className="dot" style={{ '--dot-index': 1 }}></div>
                        <div className="dot" style={{ '--dot-index': 2 }}></div>
                      </div>
                    </div>
                  </div>
                );
              }
              // Otherwise, render the message as usual
              return (
                <div key={`${index}${messageItem.id}`} className={`dialog-row ${messageItem.sender}-side`}>
                  {messageItem.sender === "sent" && <div className="timer-sent">{messageItem.time}</div>}
                  <div className="dialog-box">
                    {messageItem.text && <div className="title">{parseTextToJSX(messageItem.text)}</div>}
                    {messageItem.imageUrl && 
                      <img 
                        src={messageItem.imageUrl} 
                        alt="Chatbot response" 
                        className="chat-image" 
                        style={{ marginTop:'2vh', cursor:'pointer' }}
                        onClick={() => onImageClick(messageItem.imageUrl, messageItem.text)}
                      />
                    }
                  </div>
                  {messageItem.sender === "received" && <div className="timer-received">{messageItem.time}</div>}
                </div>
              );
            })}

          </div>

          {/* Chat Input */}
          <div className="chat-input-104">
            <button className="customer-service" onClick={connectToHumanSupport}>
              <span className="mdi mdi-face-agent"></span>
            </button>
            <textarea
              rows="1"
              ref={inputTextareaRef}
              placeholder="想問什麼呢？" 
              value={message}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              style={{ 'maxHeight':'5rem', 'width': "100%", 'border':'none', 'resize': 'none' }}
            />
            <button className="send-message" onClick={sendMessage}>
              ➤
            </button>
          </div>

        </div>

      </div>

      <button className="chatbot-button" onClick={toggleChatWindow}
        style={{ right: '1vw' }}
      >
        <img 
          className="chatbot-img" 
          style={{ 
            width: '8rem',
            height: '8rem',
            borderRadius: '30%',
            objectFit: 'cover',
            maxWidth: '10vw'
          }} 
          src={ chatbotImg } 
          alt="Chat"
        />
      </button>



      <Modal isOpen={modalOpen} toggle={toggleModal} style={{ display: 'flex', width: 'fit-content', maxWidth: 'fit-content' }}>
        <ModalHeader toggle={toggleModal}>{selectedImageTitle || '瀏覽圖片'}</ModalHeader>
        <ModalBody style={{ width: 'fit-content', height: 'auto', maxWidth:'75vw' }}>
          {selectedImage && (
            <img src={selectedImage} alt="Selected" style={{ maxWidth: '70vw', maxHeight: '70vh' }} />
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>關閉</Button>
        </ModalFooter>
      </Modal>



    </React.Fragment>
  );
};

export default withRouter(Chatbot);
