import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone";
import Select from "react-select";
import { 
  Alert,
  Button, 
  Card, 
  CardBody, 
  CardTitle, 
  Col, 
  Container, 
  Form, 
  FormGroup, 
  Input, 
  Label, 
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavItem,
  NavLink,
  TabPane,
  TabContent
} from "reactstrap";

// Import Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import classnames from "classnames";

// Custom Axios
import axiosInstance from 'utils/axiosInstance';

// Custom Utils
import { Pagination } from 'utils/pagination';

// Tools
import { uploadImg } from 'utils/imageUtils';
import { dateGen, formatBytes } from 'utils/toolUtils';

// Redux
import { useSelector, useDispatch } from "react-redux";

// uuid
import { v4 as uuidv4 } from 'uuid';

// XLSX: read Excel Files
import * as XLSX from 'xlsx';

// Image
import placeholder from "assets/images/placeholders/placeholder_card.png";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import MultipleChoiceQuestion from './MultipleChoiceQuestion';
import ShortAnswerQuestion from './ShortAnswerQuestion';
import TwoWayChoice from './TwoWayChoice';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const typeList = [
  {label:"底層資料", value:"底層資料"},
  {label:"詞彙表 Glossary", value:"詞彙表 Glossary"},
  {label:"財報", value:"財報"},
  {label:"年報", value:"年報"},
  {label:"永續報告書", value:"永續報告書"},
  {label:"議事手冊", value:"議事手冊"},
  {label:"重大訊息", value:"重大訊息"},
  {label:"新聞稿", value:"新聞稿"},
];

function getFileIcon(file) {
  const fileName = file.filename.toLowerCase();
  if (fileName.endsWith('.doc') || fileName.endsWith('.docx')) {
    return wordIcon;
  } else if (fileName.endsWith('.pdf')) {
    return pdfIcon;
  } else if (fileName.endsWith('.xls') || fileName.endsWith('.xlsx') || fileName.endsWith('.csv')) {
    return excelIcon;
  } else {
    return defaultIcon; // Default icon or any other filetype
  }
}

// Function to map uploadStatus to color
const getUploadStatusColor = (status) => {
  // Regex to match the pattern "File format .<extension> not allowed"
  // [\.] is the '.', [.+] is any characters
  const fileFormatNotAllowedRegex = /^File format \..+ not allowed$/;
  
  // Color for file format not allowed
  if (fileFormatNotAllowedRegex.test(status)) { return 'orange'; }
  switch (status) {
    case 'File uploaded successfully!':
      return 'mediumseagreen';
    case 'Failed to upload file.':
    case 'Error occurred during upload.':
      return 'red';
    default:
      return 'black'; // Default color
  }
};


/*---- Main Function ----*/
const QuestionnaireCreate = () => {


  /*---- Title and Initial Setup ----*/

  document.title = "建立資料庫：上傳檔案 | TheChart.AI";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = tab => { if (activeTab !== tab) { setActiveTab(tab);} };
  
  /*---- Title and Initial Setup ----*/


  // state.projects.projects is set through fetchUserData
  const { userData } = useSelector(state => ({
    userData: state.user.userData,
  }));


  /*---- Drag and Drop Utils ----*/

  const [answers, setAnswers] = useState({});
  const [questionItems, setQuestionItems] = useState(
    [
      [
        {
          _id: '1', 
          questionType: 'multiple_choice', 
          questionName: 'Question_多選題1', 
          options: [{text:'Option 1', direction:''}, {text:'Option 2', direction:''}]
        },
        {
          _id: '2', 
          questionType: 'short_answer', 
          questionName: 'Question_簡答題1', 
          placeholder: '輸入答案'
        },
        {
          _id: '3', 
          questionType: 'two_way_choice',
          questionName: 'Question_二分題1',
          directionAName: 'A',
          directionBName: 'B',
          options: [{text:'Option 1', direction:'A'}, {text:'Option 2', direction:'B'}]
        }
      ],[
        {
          _id: '4', 
          questionType: 'multiple_choice', 
          questionName: 'Question_多選題2', 
          options: [{text:'Option 3', direction:''}, {text:'Option 4', direction:''}]
        },
        {
          _id: '5', 
          questionType: 'short_answer', 
          questionName: 'Question_簡答題2', 
          placeholder: '輸入答案'
        },
        {
          _id: '6', 
          questionType: 'two_way_choice',
          questionName: 'Question_二分題2',
          directionAName: 'A',
          directionBName: 'B',
          options: [{text:'Option 1', direction:'A'}, {text:'Option 2', direction:'B'}]
        }
      ]
    ]
  );
  const [modal, setModal] = useState(false);
  const [newQuestion, setNewQuestion] = useState({ 
    questionType: '', 
    questionName: '', 
    options: [
      {text:'Option 1', 'direction':'A'}, 
      {text:'Option 2', 'direction':'B'},
    ], 
    placeholder: '' 
  });
  const toggle = () => setModal(!modal);


  const handleAddQuestion = (type) => {
    setNewQuestion({ 
      questionType: type,
      questionName: questionItems.length ? 'Question '+(questionItems.length+1) : '',
      directionAName: type === 'two_way_choice' ? 'gotoA' : '',
      directionBName: type === 'two_way_choice' ? 'gotoB' : '',
      options: (type === 'multiple_choice' || type === 'two_way_choice') ? 
        [
          {text:'Option 1', 'direction':'A'}, 
          {text:'Option 2', 'direction':'A'},
        ] : [],
      placeholder: type === 'short_answer' ? 'Enter your answer here...' : ''
    });
    toggle();
  };

  useEffect(()=>{
    console.log('questionItems:', questionItems);
  },[questionItems])

  const handleSaveQuestion = () => {
    const newQuestionItem = {
      ...newQuestion,
      _id: Date.now().toString(), // Simple unique ID generation for the example
    };
    setQuestionItems(currentItems => {
      return currentItems.map((c,index) => {
        if(index===activeTab-1){
          return [...c, newQuestionItem];
        }
        return c;
      });
    });
    toggle();
  };

  const handleQuestionDeleteItem = (id) => {
    setQuestionItems(currentItems => {
      return currentItems.map((c,index) => {
        if(index===activeTab-1){
          return c.filter(item => item._id !== id);
        }
        return c;
      })
    });
  };

  const onQuestionDragEnd = (result) => {
    if (!result.destination) {
        return;
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    console.log('sourceIndex:', sourceIndex);
    console.log('destinationIndex:', destinationIndex);


    // Reorder within the same section
    const section = Array.from(questionItems[activeTab-1]);
    const [movedItem] = section.splice(sourceIndex, 1);
    section.splice(destinationIndex, 0, movedItem);

    const newQuestionItems = Array.from(questionItems);
    newQuestionItems[activeTab-1] = section;

    setQuestionItems(newQuestionItems);

};

   const handleAnswerChange = (questionId, answerValue) => {
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [questionId]: answerValue
    }));
  };

  useEffect(()=>{
    console.log('Current Answers:', answers);
  }, [answers])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewQuestion(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  /*---- Drag and Drop Utils Tail ----*/

  const [processArray, setProcessArray] = useState([
    {name:'流程1'}, 
    {name:'流程2'}
  ]);

  const [editModal, setEditModal] = useState(false);

  const handleClick = (e, id) => {
    switch (e.detail) {
      case 1:
        console.log("click");
        break;
      case 2:
        console.log("double click");
        handleOpenModal(id);
        //setEditModal(!editModal);
        break;
      case 3:
        console.log("triple click");
        break;
    }
  };

  const handleQuestionUpdate = (updatedItem) => {
    setQuestionItems(currentItems => {
      return currentItems.map((c, index) => {
        if(index === activeTab-1){
          return c.map(item => item._id === updatedItem._id ? updatedItem : item);
        }
        return c;
      });
    });
  };

  const [openModalQuestionId, setOpenModalQuestionId] = useState(null);

  const handleOpenModal = (id) => {
    setOpenModalQuestionId(id);
  };

  const handleCloseModal = () => {
    setOpenModalQuestionId(null);
  };


  /* Main Return Component */
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

          {/* Render Breadcrumbs */}
          <Breadcrumbs title="問卷" breadcrumbItem="建立新問卷" />

          <Row>
            <Col lg="12">

              {/* NavBar and Buttons */}
              <Card style={{ marginBottom:'0vh' }}>
                <CardBody style={{ padding: '1.5vh 3vw 0.5vh 3vw' }}>

                  {/* Process NavBar */}
                  <Row style={{ marginBottom:'2vh' }}>
                    <Col xs="10">
                      <ul className="nav nav-tabs nav-tabs-custom" role="tablist">

                        {/* Tabs to switch active Processes */}
                        {processArray.map((p, index) => (
                          <div key={index}>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === ''+(index+1),
                                })}
                                onClick={() => { toggleTab(''+(index+1)) }}
                              >{p.name}</NavLink>
                            </NavItem>
                          </div>
                        ))}

                      </ul>
                    </Col>
                  </Row>

                  {/* Add Process Buttons */}
                  <Row>
                    <Col xs="12" style={{ marginBottom:'2vh' }}>
                      <Button color="outline-info" style={{ marginRight:'1vw' }}
                        onClick={() => handleAddQuestion('multiple_choice')}
                      >增加新的多選題</Button>
                      <Button color="outline-warning" style={{ marginRight:'1vw' }}
                        onClick={() => handleAddQuestion('short_answer')}
                      >增加新的簡答題</Button>
                      <Button color="outline-success"
                        onClick={() => handleAddQuestion('two_way_choice')}
                      >增加新的二分單元</Button>
                    </Col>
                  </Row>

                </CardBody>
              </Card>


              {/* Drag and Drop */}
              <TabContent activeTab={activeTab} className="p-3">
                {questionItems.map((items, index) => (
                  <TabPane tabId={'' + (index + 1)} key={index}>
                    <DragDropContext onDragEnd={onQuestionDragEnd}>
                      <Droppable droppableId="questionItemsdroppable">

                        {/* Active Process List of Questions */}
                        {(provided) => (
                          <div {...provided.droppableProps} ref={provided.innerRef} style={{ padding: '3vh 8vw' }}>
                            {items.map((item, idx) => (
                              <Draggable key={item._id} draggableId={item._id} index={idx}>

                                {/* Question in the active process */}
                                {(provided) => (
                                  <div
                                    className="draggable-item"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <Row style={{ width: '100%' }}>
                                      <Col xs="12">
                                        {item.questionType === 'multiple_choice' && (
                                          <MultipleChoiceQuestion
                                            item={item}
                                            onChange={handleAnswerChange}
                                            onClick={(e) => handleClick(e, item._id)}
                                            isOpen={openModalQuestionId === item._id}
                                            onClose={handleCloseModal}
                                            onSave={handleQuestionUpdate}
                                          />
                                        )}
                                        {item.questionType === 'short_answer' && (
                                          <ShortAnswerQuestion 
                                            item={item} 
                                            onChange={handleAnswerChange} 
                                            onClick={(e) => handleClick(e, item._id)}
                                            isOpen={openModalQuestionId === item._id}
                                            onClose={handleCloseModal}
                                            onSave={handleQuestionUpdate}
                                          />
                                        )}
                                        {item.questionType === 'two_way_choice' && (
                                          <TwoWayChoice 
                                            item={item} 
                                            onChange={handleAnswerChange} 
                                            onClick={(e) => handleClick(e, item._id)}
                                            isOpen={openModalQuestionId === item._id}
                                            onClose={handleCloseModal}
                                            onSave={handleQuestionUpdate}
                                          />
                                        )}
                                        <Button
                                          className="btn-close delete-item-btn"
                                          aria-label="Close"
                                          onClick={() => handleQuestionDeleteItem(item._id)}
                                          style={{
                                            position: 'absolute',
                                            right: '0vw',
                                            top: '0.3vh',
                                          }}
                                        ></Button>
                                      </Col>
                                    </Row>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </TabPane>
                ))}
              </TabContent>

            </Col>
          </Row>

        </Container>
      </div>

      {/* Modal */}
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>新增問題</ModalHeader>

        <ModalBody>

          <FormGroup>
            <Label for="questionName">問題名稱</Label>
            <Input type="text" name="questionName" id="questionName" value={newQuestion.questionName} onChange={handleChange} />
          </FormGroup>

          {newQuestion.questionType === 'multiple_choice' && (
            <FormGroup>
              <Label for="options">選項</Label>
              {newQuestion.options.map((option, index) => (
                <Input
                  key={index}
                  type="text"
                  name={`option-${index}`}
                  value={option.text}
                  onChange={(e) => {
                    const newOptions = [...newQuestion.options];
                    newOptions[index].text = e.target.value;
                    setNewQuestion(prevState => ({
                      ...prevState,
                      options: newOptions
                    }));
                  }}
                  style={{ marginBottom: '1rem' }}
                />
              ))}
              <Button onClick={() => setNewQuestion(prevState => ({
                ...prevState,
                options: [...prevState.options, `Option ${prevState.options.length + 1}`]
              }))}>增加選項</Button>
            </FormGroup>
          )}

          {newQuestion.questionType === 'two_way_choice' && (
            <>
              <FormGroup>
                <Label for="directionAName">方向 A 名稱</Label>
                <Input type="text" name="directionAName" id="directionAName" value={newQuestion.directionAName} onChange={handleChange} />
              </FormGroup>
              <FormGroup>
                <Label for="directionBName">方向 B 名稱</Label>
                <Input type="text" name="directionBName" id="directionBName" value={newQuestion.directionBName} onChange={handleChange} />
              </FormGroup>
              <FormGroup>
                <Label for="options">選項</Label>
                {newQuestion.options.map((option, index) => (
                  <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                    <Input
                      type="text"
                      name={`option-${index}`}
                      value={option.text}
                      onChange={(e) => {
                        const newOptions = [...newQuestion.options];
                        newOptions[index].text = e.target.value;
                        setNewQuestion(prevState => ({
                          ...prevState,
                          options: newOptions
                        }));
                      }}
                      style={{ marginRight: '1rem' }}
                    />
                    <Label check style={{ marginRight: '1rem' }}>
                      <Input
                        type="radio"
                        name={`direction-${index}`}
                        checked={option.direction === 'A'}
                        onClick={() => {
                          console.log('newQuestion.options:', newQuestion.options);
                          const newOptions = [...newQuestion.options];
                          console.log('newOptions:', newOptions);
                          console.log('newOptions[index]:', newOptions[index]);
                          newOptions[index].direction = 'A';
                          setNewQuestion(prevState => ({
                            ...prevState,
                            options: newOptions
                          }));
                        }}
                        onChange={()=>{}}
                      />
                      {newQuestion.directionAName}
                    </Label>
                    <Label check>
                      <Input
                        type="radio"
                        name={`direction-${index}`}
                        checked={option.direction === 'B'}
                        onClick={() => {
                          console.log('newQuestion.options:', newQuestion.options);
                          const newOptions = [...newQuestion.options];
                          console.log('newOptions:', newOptions);
                          console.log('newOptions[index]:', newOptions[index]);
                          newOptions[index].direction = 'B';
                          setNewQuestion(prevState => ({
                            ...prevState,
                            options: newOptions
                          }));
                        }}
                        onChange={()=>{}}
                      />
                      {newQuestion.directionBName}
                    </Label>
                  </div>
                ))}
                <Button onClick={() => setNewQuestion(prevState => ({
                  ...prevState,
                  options: [...prevState.options, { text: `Option ${prevState.options.length + 1}`, direction: 'A' }]
                }))}>增加選項</Button>
              </FormGroup>
            </>
          )}


        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={handleSaveQuestion}>保存</Button>
          <Button color="secondary" onClick={toggle}>取消</Button>
        </ModalFooter>

      </Modal>



    </React.Fragment>

  );
};

export default QuestionnaireCreate;
