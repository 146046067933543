import { call, put, takeLatest, fork, all } from "redux-saga/effects";

import axiosInstance from "utils/axiosInstance";

//Account Redux states
import {
  VERIFY_ACCOUNT_REQUEST,
  CLEAR_REGISTER_USER,
  REGISTER_USER,
} from "./actionTypes";

import { 
  clearRegisterUser, 
  clearRegisterUserSuccessful,
  clearRegisterUserFailed,
  registerUserSuccessful, 
  registerUserFailed,
  verifyAccountSuccess,
  verifyAccountFailure,
} from "./actions";

import { verifyAccountApi } from 'api/api';

function* verifyAccount(action) {
  try {
    const response = yield call(verifyAccountApi, action.payload); // Call your API
    yield put(verifyAccountSuccess(response.data)); // Dispatch success action
  } catch (error) {
    yield put(verifyAccountFailure(error)); // Dispatch failure action
  }
}

// Helper function to call API for registering a new user
const postRegister = async (user) => {
  try {
    const response = await axiosInstance.post('/knovia/register', user);
    
    if (response.status !== 200 && response.status !== 201) {
      console.log('Error Message', response.data.message);
      throw new Error(response.data.message || 'Failed to register');
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } }) {
  console.log("Trying to register user (within try block)");
  try {
    const response = yield call(postRegister, user);
    yield put(registerUserSuccessful(response));
  } catch (error) {
    yield put(registerUserFailed(error.response?.data?.message));
  }
}

// Clear registered user info
function* clearregisterUser() {
  try {
    yield put(clearRegisterUserSuccessful());
  } catch (error) {
    yield put(clearRegisterUserFailed(error.message));
  }
}

export function* watchUserRegister() {
  yield takeLatest(CLEAR_REGISTER_USER, clearregisterUser);
  yield takeLatest(REGISTER_USER, registerUser);
  yield takeLatest(VERIFY_ACCOUNT_REQUEST, verifyAccount);
}

function* accountSaga() {
  yield all([fork(watchUserRegister)]);
}

export default accountSaga;
