import {
  CLEAR_REGISTER_USER,
  CLEAR_REGISTER_USER_SUCCESSFUL,
  CLEAR_REGISTER_USER_FAILED,
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  VERIFY_ACCOUNT_REQUEST,
  VERIFY_ACCOUNT_SUCCESS,
  VERIFY_ACCOUNT_FAILURE,
} from "./actionTypes"

const initialState = {
  registrationError: null,
  message: null,
  loading: false,
  user: null,
}

const account = (state = initialState, action) => {
  switch (action.type) {

    case CLEAR_REGISTER_USER:
      state = { ...state }
      break

    case CLEAR_REGISTER_USER_SUCCESSFUL:
      state = {
        ...state,
        registrationError: null,
        message: null,
        user: null,
      }

    case CLEAR_REGISTER_USER_FAILED:
      state = {
        ...state,
        registrationError: action.payload,
        message: null,
        user: null,
      }
      break

    case REGISTER_USER:
      state = {
        ...state,
        loading: true,
        registrationError: null,
        message: null,
      }
      break

    case REGISTER_USER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        user: action.payload.email,
        registrationError: null,
        message: action.payload.message,
      }
      break

    case REGISTER_USER_FAILED:
      state = {
        ...state,
        user: null,
        loading: false,
        registrationError: action.payload,
        message: null,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default account
