import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { get } from "lodash";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from './TableContainer';
import * as Yup from "yup";
import * as moment from "moment";
import { useFormik } from "formik";

//import components
import Breadcrumbs from 'components/Common/Breadcrumb';
import DeleteModal from 'components/Common/DeleteModal';

import {
  ConversationId,
  CbName,
  StartDate,
  FinishDate,
  Total,
  ConversationStatus,
  PaymentMethod
} from "./EcommerceOrderCol";

import './Chatbot.css';

import { 
  format, 
  parseISO,
  differenceInYears, 
  differenceInMonths, 
  differenceInDays, 
  differenceInHours, 
  differenceInMinutes, 
  differenceInSeconds
} from 'date-fns';

// Custom Axios
import axiosInstance from 'utils/axiosInstance';

//redux
import { useSelector, useDispatch } from "react-redux";
import EcommerceOrdersModal from "./EcommerceOrdersModal";

import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  Badge,
} from "reactstrap";

const calculateDetailedTimeDifference = (isoDateString1, isoDateString2) => {
  const targetDate1 = parseISO(isoDateString1);
  const targetDate2 = isoDateString2 ? parseISO(isoDateString2) : new Date();

  //const years = differenceInYears(targetDate2, targetDate1);
  //const months = differenceInMonths(targetDate2, targetDate1) % 12;
  //const days = differenceInDays(targetDate2, targetDate1) % 30;
  const days = differenceInDays(targetDate2, targetDate1);
  const hours = differenceInHours(targetDate2, targetDate1) % 24;
  const minutes = differenceInMinutes(targetDate2, targetDate1) % 60;
  const seconds = differenceInSeconds(targetDate2, targetDate1) % 60;

  //return `${years} years, ${months} months, ${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds ago`;
  return { 
    differenceDays: days, 
    differenceHours: hours, 
    differenceMinutes: minutes,
    differenceSeconds: seconds,
  };
};

const formateDate = (date, format) => {
  const dateFormat = format ? format : "DD MMM Y";
  const date1 = moment(new Date(date)).format(dateFormat);
  return date1;
};

const ConversationStatusBadge = ({ value }) => {
  const { differenceDays, differenceHours, differenceMinutes } = calculateDetailedTimeDifference(value);
  return (
    <Badge
      className={"font-size-12 badge-soft-" +
      (differenceDays>0 ? "warning" : "success")}
      style={{ marginLeft: '2vw' }}
    >
      {differenceDays>0 && (`${differenceDays} Days, ${differenceHours} Hours ago`)}
      {differenceDays===0 && (`${differenceHours} Hours, ${differenceMinutes} Mins ago`)}
    </Badge>
  );
};

function EcommerceOrder() {

  //meta title
  document.title = "檢視所有對話 | TheChart.AI";
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);


  const [conversationsDict, setConversationsDict] = useState([]);
  const [conversation, setConversation] = useState({});

  const { chatbots } = useSelector(state => ({
    chatbots: state.chatbots.chatbots,
  }));

  const getConversations = async()=>{
    try {
      const response = await axiosInstance.get('/knovia/chatbotsConversations');

      if (response.status !== 200) {
        console.error('Failed to get user conversations:', response.status);
      }

      // console.log('User conversations:', response.data);
      const thisConversationDict = response.data;
      // add startDate and finishDate
      thisConversationDict.forEach(conversation => {
        
        const messageIds = conversation.messageIds;
        const chatbotId = conversation.chatbotId;
        if (messageIds.length > 0) {
          conversation.startDate = messageIds[0].time;
          conversation.finishDate = messageIds[messageIds.length - 1].time || '';
          conversation.statusDate = messageIds[messageIds.length - 1].time || '';
        }
        if (chatbotId && chatbots){
          const thisCbName = get(chatbots.filter(c=>c._id===chatbotId), '[0].cbName');
          conversation.cbName = thisCbName;
        }
      });
      // console.log('Transformed conversations:', thisConversationDict);

      setConversationsDict(thisConversationDict);

    } catch (error) {
      console.error('Error:', error);
    }
  }

  useEffect(()=>{
    if(chatbots.length){
      getConversations();
    } else {
      dispatch({ type: 'GET_CHATBOTS' });
    }
  }, [chatbots])


  //const toggleViewModal = () => setModal1(!modal1);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setConversation(null);
    } else {
      setModal(true);
    }
  };

  const handleConversationClick = arg => {
    const thisConversation = arg;
    console.log('thisConversation:', thisConversation);
    setConversation({
      cbName: thisConversation.cbName,
      chatbotId: thisConversation.chatbotId,
      startDate: thisConversation.startDate,
      finishDate: thisConversation.finishDate,
      statusDate: thisConversation.statusDate,
      isLinebotConversation: thisConversation.isLinebotConversation,
      linebotMessageUserId: thisConversation.linebotMessageUserId,
      messageIds: thisConversation.messageIds,
    });
    toggle();
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (order && order.id) {
      dispatch(onDeleteOrder(order.id));
      setDeleteModal(false);
    }
  };

  const columns = useMemo(
    () => [

      {
        Header: '對話 ID',
        accessor: '_id',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <ConversationId {...cellProps} />;
        }
      },
      {
        Header: '智能客服名稱',
        accessor: 'cbName',
        filterable: true,
        Cell: (cellProps) => {
          return <CbName {...cellProps} />;
        }
      },
      {
        Header: '對話開始時間',
        accessor: 'startDate',
        filterable: true,
        Cell: (cellProps) => {
          return <StartDate {...cellProps} />;
        }
      },
      {
        Header: '對話結束時間',
        accessor: 'finishDate',
        filterable: true,
        Cell: (cellProps) => {
          return <FinishDate {...cellProps} />;
        }
      },
      {
        Header: '對話狀態',
        accessor: 'statusDate',
        filterable: true,
        Cell: (cellProps) => {
          return <ConversationStatus {...cellProps} />;
        }
      },
      {
        Header: '估計 API 費用',
        accessor: 'apiCost',
        Cell: (cellProps) => {
          // return <PaymentMethod {...cellProps} />;
          return <p className="text-center">{cellProps.value}</p>;;
        }
      },
      {
        Header: '對話內容',
        accessor: 'view',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={()=>{
                const thisConversation = cellProps.row.original;
                handleConversationClick(thisConversation);
              }}
            >
              檢視內容
            </Button>);
        }
      },
      /*
      {
        Header: 'Action',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  handleOrderClick(orderData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  onClickDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      },
      */
    ],
    []
  );

  const formatTimeDifference = (startDate, finishDate) => {
    const { 
      differenceDays, 
      differenceHours, 
      differenceMinutes, 
      differenceSeconds 
    } = calculateDetailedTimeDifference(startDate, finishDate);
    if(differenceDays>0){
      return `${differenceDays} days, ${differenceHours} hours, ${differenceMinutes} minutes`;
    }
    if(differenceHours>0){
      return `${differenceHours} hours, ${differenceMinutes} minutes, ${differenceSeconds} seconds`;
    }
    return `${differenceMinutes} minutes, ${differenceSeconds} seconds`;
  };

  return (
    <React.Fragment>

      {/*
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      */}

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="智能客服" breadcrumbItem="檢視所有對話" />

          {/* Table showing the conversation list */}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={conversationsDict}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    customPageSize={10}
                    style={{ whiteSpace: 'pre-wrap' }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* Modal showing the messages */}
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Order" : "Add Order"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">AI 客服名稱</Label>
                      {conversation?.cbName &&
                        (<p>{conversation.cbName || ''}</p>)
                      }
                    </div>

                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label className="form-label">對話開始時間</Label>
                          {conversation?.startDate && 
                            (<p>{(format(parseISO(conversation.startDate), 'yyyy-MM-dd HH:mm:ss') || '')}</p>)
                          }
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label className="form-label">最近對話時間</Label>
                          {conversation?.finishDate && 
                            (<p>{(format(parseISO(conversation.finishDate), 'yyyy-MM-dd HH:mm:ss') || '')}</p>)
                          }
                        </div>
                      </Col>
                    </Row>

                    <div className="mb-3">
                      <Label className="form-label">對話時長</Label>
                      {conversation?.startDate && conversation?.finishDate && (
                        <p>{formatTimeDifference(conversation.startDate, conversation.finishDate)}</p>
                      )}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">對話狀態</Label>
                      <ConversationStatusBadge 
                        value={conversation?.statusDate}
                      />
                    </div>


                    {/* Chat Messages */}
                    <div className="chat-messages">

                      {conversation?.messageIds && conversation.messageIds.map((messageItem, index) => {
                        // Otherwise, render the message as usual
                        if(messageItem?.sender==="User" || messageItem?.sender==="AI"){
                          return (
                            <div key={`${index}${messageItem._id}`} className={`dialog-row ${messageItem.sender==='AI'?'received':'sent'}-side`}>
                              {messageItem.sender === "sent" && <div className="timer-sent">{messageItem.time}</div>}
                              <div className="dialog-box">
                                <div className="title">{messageItem.text.replace(/undefined\s*$/, '')}</div>
                              </div>
                              {messageItem.sender === "received" && <div className="timer-received">{messageItem.time}</div>}
                            </div>
                          );
                        }
                      })}

                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="text-end" style={{ marginTop:'2vh' }}>
                      <button
                        onClick={()=>{toggle();}}
                        className="btn btn-success save-user"
                      >
                        完成
                      </button>
                    </div>
                  </Col>
                </Row>


              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
}
EcommerceOrder.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default EcommerceOrder;