import {
  SET_PROJECTS,
  SET_PROJECT_IMAGE,
  SET_SHARED_PROJECTS,

  LOAD_PROJECT_IMAGES,
  LOAD_PROJECT_IMAGES_SUCCESS,
  LOAD_PROJECT_IMAGES_FAIL,

  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAIL,
  
  ADD_NEW_PROJECT,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_FAIL,

  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAIL,

  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  projects: [],
  sharedProjects: [],
  projectImages: {},
  error: {},
  loading: false,
  projectAddSuccess: false,
};

const projects = (state = INIT_STATE, action) => {

  switch (action.type) {

    /*--- SET PROJECT ---*/

    case SET_PROJECTS:
      return {
        ...state,
        projects: action.payload,
      };

    case SET_SHARED_PROJECTS:
      return {
        ...state,
        sharedProjects: action.payload,
      };

    case SET_PROJECT_IMAGE:
      //console.log('state.projectImages:', state.projectImages);
      //console.log(action.payload.projectId, '->', action.payload.imgUrl);
      return {
        ...state,
        projectImages: { 
          ...state.projectImages, 
          [action.payload.projectId]: action.payload.imgUrl,
        }
      };

    /*--- SET PROJECT TAIL ---*/



    /*--- LOAD PROJECT IMAGES ---*/

    case LOAD_PROJECT_IMAGES:
      return { ...state, loading: true };
    case LOAD_PROJECT_IMAGES_SUCCESS:
      return { ...state, loading: false, projectImages: action.payload };
    case LOAD_PROJECT_IMAGES_FAIL:
      return { ...state, loading: false, error: action.payload };

    /*--- LOAD DB PROJECT TAIL ---*/



    /*--- GET PROJECT ---*/

    case 'RESET_PROJECT_ADD_SUCCESS':
      return {
        ...state,
        projectAddSuccess: false,
      };

    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.payload,
      };

    case GET_PROJECTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    /*--- GET PROJECT TAIL ---*/


    /*--- ADD PROJECT ---*/

    case ADD_NEW_PROJECT:
      return {
        ...state,
        loading: true,
        error: null,
        projectAddSuccess: false, // Reset on new request
      };

    case ADD_PROJECT_SUCCESS:
      return {
        ...state,
        projects: [...state.projects, action.payload],
        loading: false,
        projectAddSuccess: true, // Set success flag
      };

    case ADD_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        projectAddSuccess: false, // Ensure flag is false on failure
      };

    /*--- ADD PROJECT TAIL ---*/


    /*--- UPDATE PROJECT ---*/

    // Mapping won't show error when logic isn't right!
    case UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        projects: state.projects.map(project =>
          project._id.toString() === action.payload._id.toString()
            ? { ...project, ...action.payload }
            : project
        ),
      };

    case UPDATE_PROJECT_FAIL:
      console.log("[store/project/reducer.js] Update project failed!");
      return {
        ...state,
        error: action.payload,
      };

    /*--- UPDATE PROJECT TAIL ---*/


    /*--- DELETE PROJECT ---*/

    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        projects: state.projects.filter(
          project => project._id.toString() !== action.payload.toString()
        ),
      };

    case DELETE_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    /*--- DELETE PROJECT TAIL ---*/

    default:
      return state;
  }

};

export default projects;


