import {
  CLEAR_GOOGLE_LOGIN_STATES,
  SET_GOOGLE_UNAUTHORIZED_ERROR,

  GOOGLE_LOGIN_REQUEST,
  GOOGLE_LOGIN_SUCCESS,
  GOOGLE_LOGIN_FAILURE,

  GOOGLE_LOG_OUT,
  GOOGLE_LOG_OUT_SUCCESS,
  GOOGLE_LOG_OUT_FAILURE,
} from "./actionTypes";

const initialState = {
  token:"",
  userData: {},
  error: "",
  loading: false,
  isGoogleLoggedIn: false,
}

const googleLogin = (state = initialState, action) => {
  switch (action.type) {

    case SET_GOOGLE_UNAUTHORIZED_ERROR:
      console.log("[store/auth/googleLogin/reducer.js] UNAUTHORIZED!");
      state = {
        ...state,
        error: action.payload,
        loading: false,
        isGoogleLoggedIn: false,
      };
      break;

    /*--- Clear States ---*/
    case CLEAR_GOOGLE_LOGIN_STATES:
      state = {
        ...state,
        token: "",
        googleUserData: {},
        error: "",
        loading: false,
      };
      break;


    /*--- Login Request ---*/
    case GOOGLE_LOGIN_REQUEST:
      state = {
        ...state,
        token: action.payload,
        loading: true,
      };
      break;
    case GOOGLE_LOGIN_SUCCESS:
      state = {
        ...state,
        googleUserData: action.payload,
        loading: false,
        error: "",
        isGoogleLoggedIn: true,
      };
      break;
    case GOOGLE_LOGIN_FAILURE:
      state = { 
        ...state,
        loading: false,
        error: action.payload,
        isGoogleLoggedIn: false,
      };
      break;


    /*--- Logout Request ---*/
    case GOOGLE_LOG_OUT:
      state = {
        ...state,
        isGoogleLoggedIn: false,
      }
      break

    
    default:
      state = { ...state }
      break;
  }
  return state
}

export default googleLogin;
