import {
  EDIT_PROFILE,
  PROFILE_SUCCESS,
  PROFILE_ERROR,

  EDIT_PROFILE_IMAGE,
  IMAGE_SUCCESS,
  IMAGE_ERROR,

  EDIT_PROFILE_EMAIL,
  EMAIL_SUCCESS,
  EMAIL_ERROR,

  EDIT_PROFILE_USERNAME,
  USERNAME_SUCCESS,
  USERNAME_ERROR,

  EDIT_PROFILE_PASSWORD,
  PASSWORD_SUCCESS,
  PASSWORD_ERROR,
  
  CLEAR_PROFILE_ERROR,
  CLEAR_PROFILE_SUCCESS,
  RESET_PROFILE_FLAG,
  
} from "./actionTypes"

/*---- Profile ----*/

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

/*---- Profile ----*/


/*---- Profile Image ----*/

export const editProfileImage = values => {
  return {
    type: EDIT_PROFILE_IMAGE,
    payload: values,
  }
}

export const profileImageSuccess = msg => {
  return {
    type: IMAGE_SUCCESS,
    payload: msg,
  }
}

export const profileImageError = error => {
  return {
    type: IMAGE_ERROR,
    payload: error,
  }
}

/*---- Profile Image ----*/


/*---- Profile Email ----*/

export const editProfileEmail = values => {
  return {
    type: EDIT_PROFILE_EMAIL,
    payload: values,
  }
}

export const profileEmailSuccess = msg => {
  return {
    type: EMAIL_SUCCESS,
    payload: msg,
  }
}

export const profileEmailError = error => {
  return {
    type: EMAIL_ERROR,
    payload: error,
  }
}

/*---- Profile Email ----*/


/*---- Profile Username ----*/

export const editProfileUsername = values => {
  return {
    type: EDIT_PROFILE_USERNAME,
    payload: values,
  }
}

export const profileUsernameSuccess = msg => {
  return {
    type: USERNAME_SUCCESS,
    payload: msg,
  }
}

export const profileUsernameError = error => {
  return {
    type: USERNAME_ERROR,
    payload: error,
  }
}

/*---- Profile Username ----*/


/*---- Profile Password ----*/

export const editProfilePassword = values => {
  return {
    type: EDIT_PROFILE_PASSWORD,
    payload: values, // Includes currentPassword, newPassword, etc.
  }
}

export const profilePasswordSuccess = msg => {
  return {
    type: PASSWORD_SUCCESS,
    payload: msg,
  }
}

export const profilePasswordError = error => {
  return {
    type: PASSWORD_ERROR,
    payload: error,
  }
}

/*---- Profile Password ----*/


/*---- Clear Message ----*/

export const clearProfileSuccess = () => {
  return {
    type: CLEAR_PROFILE_SUCCESS,
  };
};

export const clearProfileError = () => {
  return {
    type: CLEAR_PROFILE_ERROR,
  };
};

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}

/*---- Clear Message ----*/





