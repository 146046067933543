import PropTypes from 'prop-types'
import React from "react"
import { Modal, ModalBody } from "reactstrap"

const DeleteModal = ({ show, onDeleteClick, onCloseClick, title='' }) => {

  return (

    <Modal size="sm" isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">

        <ModalBody className="px-4 py-5 text-center">

          {/* Close Button */}
          <button type="button" onClick={onCloseClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
          
          {/* Icon */}
          <div className="avatar-sm mb-4 mx-auto">
            <div className="avatar-title bg-danger text-danger bg-opacity-10 font-size-20 rounded-3">
              <i className="mdi mdi-trash-can-outline"></i>
            </div>
          </div>

          <p className="text-muted font-size-16 mb-2">確定要刪除嗎？</p>

          {title && (<p style={{ fontWeight: '600', marginBottom: '1.5rem'}}>{title}</p>)}

          {/* Decision Buttons */}
          <div className="hstack gap-2 justify-content-center mb-0">
            <button type="button" className="btn btn-danger" onClick={onDeleteClick}>刪除</button>
            <button type="button" className="btn btn-secondary" onClick={onCloseClick}>取消</button>
          </div>

        </ModalBody>

      </div>
    </Modal>

  )

}

// Check the types of props passed into the Component
DeleteModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any
}

export default DeleteModal
