// src/hooks/useHandleUnauthorized.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setUnauthorizedError, setGoogleUnauthorizedError } from 'store/actions.js';

import {
  getIsLoggedIn,
  getGoogleLoggedIn,
} from 'store/selectors';

export const useHandleUnauthorized = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(getIsLoggedIn);
  const googleLoggedIn = useSelector(getGoogleLoggedIn);

  useEffect(() => {

    const handleUnauthorized = () => {
      console.log('handling Unauthorized in useHandleUnauthorized hook...');
      sessionStorage.removeItem('isRedirectedToLogin');
      localStorage.removeItem('authUser');

      //console.log('[useHandleUnauthorized]isLoggedIn:', isLoggedIn);
      //console.log('[useHandleUnauthorized]googleLoggedIn:', googleLoggedIn);

      // Dispatch the unauthorized error action
      if(isLoggedIn){
        dispatch(setUnauthorizedError('登入驗證未通過，請重新登入一次'));
      }
      if(googleLoggedIn){
        dispatch(setGoogleUnauthorizedError('登入驗證未通過，請重新登入一次'));
      }

      navigate('/login');
    };

    window.addEventListener('customUnauthorizedEvent', handleUnauthorized);
    return () => {
      window.removeEventListener('customUnauthorizedEvent', handleUnauthorized);
    };

  }, [navigate, dispatch]);
};

export default useHandleUnauthorized;