const yearData = [
    {
        name: "Series A",
        data: [44, 55, 41, 67, 22, 43, 36, 52, 24, 18, 36, 48]
    },
    {
        name: "Series B",
        data: [13, 23, 20, 8, 13, 27, 18, 22, 10, 16, 24, 22]
    },
    {
        name: "Series C",
        data: [11, 17, 15, 15, 21, 14, 11, 18, 17, 12, 20, 18]
    },
];

const monthData = [
    {
        name: "Series A",
        data: [34, 55, 21, 77, 32, 63, 86, 42, 34, 18, 16, 41],
    },
    {
        name: "Series B",
        data: [10, 63, 40, 80, 52, 41, 11, 32, 30, 86, 44, 33],
    },
    {
        name: "Series C",
        data: [11, 17, 15, 85, 21, 14, 80, 58, 17, 12, 20, 18],
    },
];

const weekData = [
    {
        name: "Series A",
        data: [14, 52, 11, 57, 22, 33, 31, 22, 64, 14, 32, 68],
    },
    {
        name: "Series B",
        data: [13, 23, 20, 8, 13, 27, 18, 22, 10, 16, 24, 22],
    },
    {
        name: "Series C",
        data: [11, 17, 15, 15, 34, 55, 21, 18, 17, 12, 20, 18],
    },
];

const userData = { project: "12", credit: "1,257"};

const latestTransaction = [
    {
        visitor: "#001",
        interactId: "706-219-4095",
        interactQuestion: "水母棲息地",
        interactResponse: "水母一般生活在...",
        rating: "3.8",
        apiCost: "0.018",
        timeStamp: "20230802115236",
        otherDetail: "-",
    },
    {
        visitor: "#002",
        interactId: "701-832-5838",
        interactQuestion: "怎麼分辨鯨魚的性別",
        interactResponse: "鯨魚的性別可以用下列方式判別...",
        rating: "4.1",
        apiCost: "0.023",
        timeStamp: "20230802121545",
        otherDetail: "-",
    },
    {
        visitor: "#003",
        interactId: "704-629-9535",
        interactQuestion: "蝦子平常都吃什麼？",
        interactResponse: "蝦子依據種類有不同的食物來源...",
        rating: "4.4",
        apiCost: "0.021",
        timeStamp: "20230802132014",
        otherDetail: "-",
    },
    {
        visitor: "#004",
        interactId: "443-523-4726",
        interactQuestion: "企鵝會跌倒嗎？",
        interactResponse: "企鵝的蹼可以幫助他們在冰面上行走...",
        rating: "4.0",
        apiCost: "0.017",
        timeStamp: "20230802140718",
        otherDetail: "-",
    },
    {
        visitor: "#007",
        interactId: "325-250-1106",
        interactQuestion: "最大的鯨魚是哪種鯨魚？",
        interactResponse: "不同種類的鯨魚體型相差很大...",
        rating: "4.2",
        apiCost: "0.062",
        timeStamp: "20230802141525",
        otherDetail: "-",
    },

/*
    {
        orderId: "#SK2544",
        billingName: "Ronald Taylor",
        orderdate: "04 Oct, 2019",
        total: "$404",
        paymentStatus: "Refund",
        methodIcon: "fab fa-cc-visa",
        paymentMethod: "Visa",
    },
    {
        orderId: "#SK2543",
        billingName: "Barry Dick",
        orderdate: "05 Oct, 2019",
        total: "$412",
        paymentStatus: "Paid",
        methodIcon: "fab fa-cc-mastercard",
        paymentMethod: "Mastercard",
    },
    {
        orderId: "#SK2542",
        billingName: "Juan Mitchell",
        orderdate: "06 Oct, 2019",
        total: "$384",
        paymentStatus: "Paid",
        methodIcon: "fab fa-cc-paypal",
        paymentMethod: "Paypal",
    },
    {
        orderId: "#SK2541",
        billingName: "Jamal Burnett",
        orderdate: "07 Oct, 2019",
        total: "$380",
        paymentStatus: "Chargeback",
        methodIcon: "fab fa-cc-visa",
        paymentMethod: "Visa",
    },
    {
        orderId: "#SK2540",
        billingName: "Neal Matthews",
        orderdate: "07 Oct, 2019",
        total: "$400",
        paymentStatus: "Paid",
        methodIcon: "fab fa-cc-mastercard",
        paymentMethod: "Mastercard",
    },
*/

];

export { yearData, monthData, weekData, latestTransaction, userData };