import React from "react";
import { Container, Row, Col } from "reactstrap";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer" style={{ padding: '1rem' }}>
        <Container fluid={true}>
          <Row>
            <Col xl={6} lg={4} md={4} sm={6} xs={4}>{new Date().getFullYear()} © TheChart.AI</Col>
            <Col xl={3} lg={4} md={4} sm={6} xs={8}>
              <div className="text-sm-end">
                Contact us - Email: <a href="mailto:admin@thechart.ai">admin@thechart.ai</a>
              </div>
            </Col>
            <Col xl={3} lg={4} md={4} sm={12}>
            <div className="text-sm-end d-none d-sm-block">
                Design & Develop by TheChartAI Team
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
