import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone";
import Select from "react-select";
import { 
  Alert,
  Button, 
  Card, 
  CardBody, 
  CardTitle, 
  Col, 
  Container, 
  Form, 
  FormGroup, 
  Input, 
  Label, 
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
} from "reactstrap";

// Import Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Custom Axios
import axiosInstance from 'utils/axiosInstance';

// Custom Utils
import { Pagination } from 'utils/pagination';

// Tools
import { uploadImg } from 'utils/imageUtils';
import { dateGen, formatBytes } from 'utils/toolUtils';

// Redux
import { useSelector, useDispatch } from "react-redux";

// uuid
import { v4 as uuidv4 } from 'uuid';

// Import file icons and images
import placeholder from "assets/images/placeholders/company_profile_placeholder.png";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const typeList = [
  {label:"找好茶", value:"找好茶"},
  {label:"找奶茶", value:"找奶茶"},
  {label:"找拿鐵", value:"找拿鐵"},
  {label:"找新鮮", value:"找新鮮"},
  {label:"季節限定", value:"季節限定"},
];

const testShoppingList = [
  { 
    drinkSize: "中",
    otherNeeds: [],
    pdName: "茉莉綠茶",
    sugarLevel: "全糖",
    temperature: "正常冰",
    toppings: [],
    _id: "66002b079be661d7b276231f",
  },
  {
    drinkSize: "中",
    otherNeeds: ['自備環保杯'],
    pdName: "阿薩姆紅茶",
    sugarLevel: "全糖",
    temperature: "正常冰",
    toppings: ['波霸', '燕麥'],
    _id: "66014d3f5413cd612a53cda7",
  }
];

// Component to display each item
const ShoppingListItem = ({ item, onClickDelete }) => {

  return (
    <div 
      style={{ 
        marginBottom: '1rem',
        background: '#ecebeb',
        borderRadius: '0.2rem',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        paddingLeft: '1rem',
        paddingTop: '1rem',
        paddingBottom: '1rem',
      }}
    >

      <Row>
        <Col sm="10">

          <p style={{ marginBottom: '0.5rem' }}>{item.drinkSize} O {item.pdName}</p>
          <p style={{ marginLeft: '1.5rem', marginBottom: '0.3rem' }}>{item.temperature} / {item.sugarLevel}</p>
          {item.toppings.length > 0 && (
            <p style={{ marginLeft: '1.5rem', marginBottom: '0.3rem' }}>{item.toppings.join(' / ')}</p>
          )}
          {item.otherNeeds.includes('自備環保杯') && (
            <p style={{ color: 'green', marginLeft: '1.5rem', marginBottom: '0rem' }}>使用環保杯</p>
          )}
        </Col>

        <Col sm="2">

          <div className="contact-links d-flex font-size-20">

            <div className="flex-fill" onClick={() => setEditModal(!editModal)}>
              <Link to="#" id={"message" + item._id}>
                <i className="mdi mdi-pencil font-size-20 text-success me-1"></i>
                <UncontrolledTooltip
                  placement="top"
                  target={"message" + item._id}
                >
                  編輯
                </UncontrolledTooltip>
              </Link>
            </div>

            <div className="flex-fill" onClick={() => onClickDelete(item)}>
              <Link to="#" id={"project" + item._id}>
                <i className="mdi mdi-trash-can font-size-20 text-danger me-1"></i>
                <UncontrolledTooltip
                  placement="top"
                  target={"project" + item._id}
                >
                  刪除
                </UncontrolledTooltip>
              </Link>
            </div>

          </div>

        </Col>
      </Row>

    </div>
  );
};

// Function to map uploadStatus to color
const getUploadStatusColor = (status) => {
  // Regex to match the pattern "File format .<extension> not allowed"
  // [\.] is the '.', [.+] is any characters
  const fileFormatNotAllowedRegex = /^File format \..+ not allowed$/;
  
  // Color for file format not allowed
  if (fileFormatNotAllowedRegex.test(status)) { return 'orange'; }
  switch (status) {
    case 'File uploaded successfully!':
      return 'mediumseagreen';
    case 'Failed to upload file.':
    case 'Error occurred during upload.':
      return 'red';
    default:
      return 'black'; // Default color
  }
};


/*---- Main Function ----*/
const ConfirmOrder = () => {


  /*---- Title and Initial Setup ----*/

  document.title = "確認訂單 | TheChart.AI";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  /*---- Title and Initial Setup ----*/

  /*---- Modal Deleting a Project ----*/

  // State for Delete Modal Open or Close
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (item) => {
    console.log('Clicking Delete...');
    setDeleteModal(true);
  };
  const handleCloseDeleteModal = () => { setDeleteModal(false) };
  // Deleting a project and close the delete modal
  const handleDeleteOrder = () => {
    if (item && item._id) {
      dispatch(onDeleteProduct(item._id));
    }
    setDeleteModal(false);
  };

  /*---- Modal Deleting a Project Tail ----*/


  // state.projects.projects is set through fetchUserData
  const { userData } = useSelector(state => ({
    userData: state.user.userData,
  }));


  /*---- Form fields States and Changes ----*/

  const [pdName, setPdName] = useState('');
  const [pdDesc, setPdDesc] = useState('');
  const [productType, setProductType] = useState('Drink'); // 'Drink' or 'Dish'
  const [customizationsAvailable, setCustomizationsAvailable] = useState({
    sellPrice: 25,
    largeSizeAvailable: true,
    extraFeeForLarge: 5,
    bubblesAvailable: true,
    pearlsAvailable: true,
    oatsAvailable: true,
    coconutJellyAvailable: true,
    cupBringOptionAvailable: true,
    reductionForOwnCup: 5,
    toppingAmount: {
      less: true,
      normal: true,
      more: true,
    },
    iceOptions: {
      none: true,
      fortyPercent: true,
      seventyPercent: true,
      hundredPercent: true,
      warm: false,
      hot: false,
    },
    sugarOptions: {
      none: true,
      thirtyPercent: true,
      fiftyPercent: true,
      seventyPercent: true,
      hundredPercent: true,
    },
    containsVanilla: false,
    containsWhippedCream: false,
  });

  const handlePdNameChange = (e) => setPdName(e.target.value);
  const handlePdDescChange = (e) => setPdDesc(e.target.value);

  const handleCustomizationAvailabilityChange = (selection) => {

    // Handle changes from standard inputs (e.g., checkboxes, number inputs)
    if (selection.target?.name) { 
      const { name, value, type, checked } = selection.target;
      const path = name.split('.');

      if (path.length === 1) {
        setCustomizationsAvailable(prevState => ({
          ...prevState,
          [name]: type === 'checkbox' ? checked : value,
        }));
      } else {
        const [parentKey, childKey] = path;
        setCustomizationsAvailable(prevState => ({
          ...prevState,
          [parentKey]: {
            ...prevState[parentKey],
            [childKey]: type === 'checkbox' ? checked : value,
          },
        }));
      }
    } else {
      // react-select change
      const { value, label } = selection;
      name = 'toppingAmount';
      setCustomizationsAvailable(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  useEffect(()=>{

    console.log('customizationsAvailable:', customizationsAvailable);

  },[customizationsAvailable]);

  const [selectedProductTypes, setSelectedProductTypes] = useState([]);

  const handleProductTypeChange = (event) => {
    const options = event.target.options;
    const values = [];
    for (let i = 0, len = options.length; i < len; i++) {
      if (options[i].selected) { values.push(options[i].value) }
    }
    setSelectedProductTypes(values);
  };

  /*---- Form fields States and Changes Tail ----*/

  

  /*---- Processing Product Image ----*/

  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState('還沒有選擇圖片喔');
  const [imageUrl, setImageUrl] = useState(''); // 顯示伺服器端回傳預覽圖片 url
  const [uploadStatus, setUploadStatus] = useState(''); // 圖片上傳狀態
  const fileInputRef = useRef(null);  // The original button for choosing image file

  // OnClick handler for the beautified choosing image file button
  const handleButtonClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    setUploadStatus('');
    console.log("Selected file:", event.target.files[0]);
    setFile(event.target.files[0]);
  };

  useEffect(() => {
    const performUpload = async () => {
      if (file) {
        // Reset upload status
        setUploadStatus('');

        const date14string = await dateGen();
        const newfilename = date14string + '_' + file.name;
        setFilename(newfilename);
        const {newImageUrl, uploadStatus} = await uploadImg(newfilename, file);
        setUploadStatus(uploadStatus);
        setImageUrl(newImageUrl);
      }
    };

    performUpload();
  }, [file]);

  /*---- Processing Product Image Tail ----*/



  /*----- Create New Product -----*/

  const [uploadLoading, setUploadLoading] = useState(false);
  const [createWarnings, setCreateWarnings] = useState("");
  const [continueAdd, setContinueAdd] = useState(false);
  const [createSuccess, setCreateSuccess] = useState('');

  const createProduct = async (e) => {

    e.preventDefault();

    let missingFields = [];
    if (!pdName) missingFields.push("產品名稱");
    if (!selectedProductTypes) missingFields.push("產品類型");
    if (missingFields.length > 0) {
      setCreateWarnings(`這些欄位不可空白: ${missingFields.join(", ")}`);
      return;
    }

    const pdData = {
      pdName,
      pdDesc: (pdDesc || pdName),
      img: filename,
      tags: selectedProductTypes,
      customizations: customizationsAvailable,
    };

    console.log("Creating Product with metadata:", pdData);

    try {
      setUploadLoading(true);
      const response = await axiosInstance.post(`/knovia/product/${productType.toLowerCase()}s`,
        pdData, { headers: {'Content-Type': 'application/json'},
      });
      console.log("Product Info sent, server response:", response.data);

      // Operation successful
      setCreateSuccess('新增產品成功');
      setCreateWarnings('');
      setTimeout(() => setCreateSuccess(''), 1000); // Reset after 1 second

      setUploadLoading(false);
      dispatch({ type: 'FETCH_USER_DATA' });
      if(!continueAdd){navigate('/kiosk-menu');}

    } catch (error) {
      const errorMessage = error.response && error.response.data && error.response.data.message;
      console.error("Error sending chunk to server:", error);
      setUploadLoading(false);
      setCreateWarnings("發生未預期的錯誤，請再試一次");
    }

  };

  /*----- Create New Product Tail -----*/

  const productTypeMappings = {
    'Drink':'飲料',
    'Dish':'盤餐',
  }
  const toppingOptions = [
    { label: '少', value: 'Less' },
    { label: '正常', value: 'Normal' }, // Assuming "Normal" is a correct value according to your backend/model logic
    { label: '多', value: 'More' },
  ];

  const [pickupOption, setPickupOption] = useState('');

  /*---- Main JSX ----*/

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="點餐介面模擬" breadcrumbItem="購物車" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <p style={{
                    fontSize: '1rem',
                    color: '#2077c4',
                    marginBottom: '0.3rem',
                  }}>50嵐 台北長春店</p>
                  <p style={{ 
                    marginBottom: '0rem',
                    fontSize: '0.8rem',
                    color: '#114b7e',
                  }}>
                    <i className="bx bx-phone" style={{ marginRight: '0.3rem' }}></i>
                    02-87121710
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    取貨方式
                  </CardTitle>

                  <div>
                    <FormGroup check className="pickup-option"
                      onClick={() => {}}
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'column',
                      }}
                    >
                      <Label check style={{ marginBottom: '0.5rem' }}>
                        <Input
                          name="selfpickup"
                          type="radio"
                          onClick={()=>{setPickupOption('自取')}}
                          onChange={()=>{}}
                          checked={pickupOption === "自取"}
                        />{' '}
                        自取（約 15 分鐘）
                      </Label>

                      {pickupOption === "自取" && (
                        <>
                          <p 
                            style={{ 
                              marginBottom:'-0.7rem', 
                              marginLeft: '1rem',
                              background: 'white',
                              zIndex:'100',
                              padding: '0rem 0.5rem',
                              width: 'fit-content',
                              color: '#acaaaa',
                            }}
                          >取貨門市位置</p>
                          <Input
                            readOnly
                            defaultValue={'台北市松山區長春路 476 號'}
                            style={{ 
                              paddingLeft: '1rem',
                              marginBottom:'1rem',
                              height: '3rem',
                            }}
                          />
                        </>
                      )}

                      <Label check>
                        <Input
                          name="delivery"
                          type="radio"
                          onClick={()=>{setPickupOption('外送')}}
                          onChange={()=>{}}
                          checked={pickupOption === "外送"}
                        />{' '}
                        外送（約 40~50 分鐘）
                      </Label>

                    </FormGroup>
                  </div>

                  <div style={{ marginTop: '2rem' }}></div>

                  <CardTitle className="mb-2 mt-3">
                    預計取貨時間
                  </CardTitle>

                  <div>
                    <FormGroup check className="pickup-option"
                      onClick={() => {}}
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'column',
                      }}
                    >
                      <p 
                        style={{ 
                          marginBottom:'-0.7rem', 
                          marginLeft: '1rem',
                          background: 'white',
                          zIndex:'100',
                          padding: '0rem 0.5rem',
                          width: 'fit-content',
                          color: '#acaaaa',
                          position: 'relative',
                        }}
                      >取貨時間以門市現場狀況為主</p>

                      <Input
                        readOnly
                        defaultValue={'今日 2024-03-26 17:10'}
                        style={{ 
                          paddingLeft: '1rem',
                          marginBottom:'1rem',
                          height: '3rem',
                        }}
                      />
                    </FormGroup>
                  </div>

                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>

                  <CardTitle className="mb-4">
                    購物車內容
                  </CardTitle>
                  {/* Render each shopping list item */}
                  {testShoppingList.map((item) => (
                    <ShoppingListItem 
                      key={item._id} 
                      item={item} 
                      onClickDelete={onClickDelete}
                    />
                  ))}

                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    新增產品至點餐介面: {productTypeMappings[productType]}
                  </CardTitle>

                  <Row style={{ marginBottom: '1rem' }}>
                    <Col sm="12">
                      <Button
                        color="info"
                        style={{ marginRight: '0.5rem' }}
                        onClick={() => setProductType('Drink')}
                      >餐點類型：飲料</Button>

                      <Button 
                        color="warning"
                        onClick={() => setProductType('Dish')}
                      >餐點類型：盤餐</Button>
                    </Col>
                  </Row>

                  <Form>

                    {/* 產品名稱 */}
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="pdname"
                        className="col-form-label col-lg-2"
                      >
                        產品名稱
                      </Label>

                      <Col lg="10">
                        <Input
                          id="pdname"
                          name="pdname"
                          type="text"
                          className="form-control"
                          placeholder="輸入產品名稱"
                          value={pdName}
                          onChange={handlePdNameChange}
                        />
                      </Col>
                    </FormGroup>


                    {/* 產品描述 */}
                    <FormGroup className="mb-4" row>

                      <Label
                        htmlFor="pdDesc"
                        className="col-form-label col-lg-2"
                      >
                        產品描述
                      </Label>

                      <Col lg="10">
                        <textarea
                          className="form-control"
                          id="pdDesc"
                          rows="3"
                          placeholder={pdName || "輸入產品描述"}
                          value={pdDesc}
                          onChange={handlePdDescChange}
                        />
                      </Col>

                    </FormGroup>


                    {/* Multi-Select for Product Types */}
                    <FormGroup className="mb-4" row>
                      <Label htmlFor="productType" className="col-form-label col-lg-2">
                        產品類型<br/>(使用 Ctrl/Shift 按鈕選取多個項目)
                      </Label>
                      <Col lg="10">
                        <Input
                          id="productType"
                          name="productType"
                          type="select"
                          multiple
                          style={{
                            minHeight: '20vh',
                          }}
                          value={selectedProductTypes} // This should be an array of selected values
                          onChange={handleProductTypeChange} // Handler function needs to be adjusted for multi-select
                        >
                          <option value="">選擇產品類型:</option>
                          {typeList.map(type => (
                            <option key={type.value} value={type.value}>
                              {type.label}
                            </option>
                          ))}
                        </Input>
                      </Col>
                    </FormGroup>


                    {/*---- 選擇產品圖片 ----*/}
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="productpic"
                        className="col-form-label col-lg-2"
                      >
                        產品圖片
                      </Label>
                      <Col lg="10">

                        {/* 選擇圖片按鈕 + 圖片名稱 */}
                        <Row>
                          <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                          />
                          <Col sm="3">
                            <button className="btn btn-outline-info" onClick={handleButtonClick}>選擇圖片</button>
                          </Col>
                          <Col sm="9" style={{display:"flex", alignItems:"center"}}>
                            <span>{filename}</span>
                          </Col>
                        </Row>

                        {/* 上傳圖片狀態 */}
                        <Row>
                          <Col sm="12">
                            {uploadStatus && (
                              <p style={{ color: getUploadStatusColor(uploadStatus) }}>
                                {uploadStatus}
                              </p>
                            )}
                          </Col>
                        </Row>

                        {/* 已選擇圖片預覽 */}
                        <Row>
                          <div>
                            {imageUrl && <img src={imageUrl} className="profile-pic" alt="Fetched" />}
                          </div>
                        </Row>

                      </Col>
                      
                    </FormGroup>

                    <div style={{ borderTop: '1px solid #b9b9b9', margin: '3rem 0rem' }}></div>

                    {productType === 'Drink' && (
                      <div>
                        <h4>飲料可調整選項</h4>

                        <div style={{ borderTop: '1px solid #a8c5cc', margin: '2rem 3rem 1.5rem 3rem' }}></div>

                        {/* Ice and Sugar Options */}
                        <Row style={{ marginLeft: '4rem', marginBottom: '1rem' }}>
                          <h5>冰度選項</h5>
                          <Col sm="12" className='form-check-primary'>
                            <label><Input style={{ margin: '0.2rem 0.4rem 0.2rem 0rem' }} defaultChecked={customizationsAvailable.iceOptions.hundredPercent} type="checkbox" name="iceOptions.hundredPercent" onChange={handleCustomizationAvailabilityChange} />正常冰</label>
                            <label><Input style={{ margin: '0.2rem 0.4rem 0.2rem 1rem' }} defaultChecked={customizationsAvailable.iceOptions.seventyPercent} type="checkbox" name="iceOptions.seventyPercent" onChange={handleCustomizationAvailabilityChange} />少冰</label>
                            <label><Input style={{ margin: '0.2rem 0.4rem 0.2rem 1rem' }} defaultChecked={customizationsAvailable.iceOptions.fortyPercent} type="checkbox" name="iceOptions.fortyPercent" onChange={handleCustomizationAvailabilityChange} />微冰</label>
                            <label><Input style={{ margin: '0.2rem 0.4rem 0.2rem 1rem' }} defaultChecked={customizationsAvailable.iceOptions.none} type="checkbox" name="iceOptions.none" onChange={handleCustomizationAvailabilityChange} />去冰</label>
                            <label><Input style={{ margin: '0.2rem 0.4rem 0.2rem 1rem' }} defaultChecked={customizationsAvailable.iceOptions.warm} type="checkbox" name="iceOptions.warm" onChange={handleCustomizationAvailabilityChange} />溫</label>
                            <label><Input style={{ margin: '0.2rem 0.4rem 0.2rem 1rem' }} defaultChecked={customizationsAvailable.iceOptions.hot} type="checkbox" name="iceOptions.hot" onChange={handleCustomizationAvailabilityChange} />熱</label>
                          </Col>
                        </Row>

                        <Row style={{ marginLeft: '4rem' }}>
                          <h5>糖度選項</h5>
                          <Col sm="12" className='form-check-warning'>
                            <label><Input style={{ margin: '0.2rem 0.4rem 0.2rem 0rem' }} defaultChecked={customizationsAvailable.sugarOptions.hundredPercent} type="checkbox" name="sugarOptions.hundredPercent" onChange={handleCustomizationAvailabilityChange} />全糖</label>
                            <label><Input style={{ margin: '0.2rem 0.4rem 0.2rem 1rem' }} defaultChecked={customizationsAvailable.sugarOptions.seventyPercent} type="checkbox" name="sugarOptions.seventyPercent" onChange={handleCustomizationAvailabilityChange} /> 少糖</label>
                            <label><Input style={{ margin: '0.2rem 0.4rem 0.2rem 1rem' }} defaultChecked={customizationsAvailable.sugarOptions.fiftyPercent} type="checkbox" name="sugarOptions.fiftyPercent" onChange={handleCustomizationAvailabilityChange} /> 半糖</label>
                            <label><Input style={{ margin: '0.2rem 0.4rem 0.2rem 1rem' }} defaultChecked={customizationsAvailable.sugarOptions.thirtyPercent} type="checkbox" name="sugarOptions.thirtyPercent" onChange={handleCustomizationAvailabilityChange} /> 微糖</label>
                            <label><Input style={{ margin: '0.2rem 0.4rem 0.2rem 1rem' }} defaultChecked={customizationsAvailable.sugarOptions.none} type="checkbox" name="sugarOptions.none" onChange={handleCustomizationAvailabilityChange} /> 無糖</label>
                          </Col>
                        </Row>

                        <div style={{ borderTop: '1px solid #a8c5cc', margin: '1.5rem 3rem 1.5rem 3rem' }}></div>

                        <Row style={{ marginLeft: '4rem' }}>
                          <h5>價格</h5>
                          <Col sm="12" className='form-check-primary' style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ display:'flex', alignItems:'center' }}>
                              <label style={{ minWidth: '7rem' }}>
                                中杯售價 (NTD):
                              </label>
                              <Input style={{marginLeft:'0.3rem', marginBottom: '0.3rem'}} defaultValue={customizationsAvailable.sellPrice} type="number" name="sellPrice" onChange={handleCustomizationAvailabilityChange} min="0" step="1" />
                            </div>
                          </Col>
                        </Row>

                        <Row style={{ marginLeft: '4rem' }}>
                          <h5>大杯</h5>
                          <Col sm="12" className='form-check-primary' style={{ display: 'flex', alignItems: 'center' }}>
                            <label style={{ marginRight: '1.5rem' }}>
                              <Input style={{ margin: '0.2rem 0.4rem 0.2rem 0rem' }} defaultChecked={customizationsAvailable.largeSizeAvailable} type="checkbox" name="largeSizeAvailable" onChange={handleCustomizationAvailabilityChange} />
                              有大杯
                            </label>
                            <div style={{ display:'flex', alignItems:'center' }}>
                              <label style={{ minWidth: '7rem' }}>
                                大杯加價 (NTD):
                              </label>
                              <Input style={{marginLeft:'0.3rem', marginBottom: '0.3rem'}} defaultValue={customizationsAvailable.extraFeeForLarge} type="number" name="extraFeeForLarge" onChange={handleCustomizationAvailabilityChange} min="0" step="1" />
                            </div>
                          </Col>
                        </Row>

                        <div style={{ borderTop: '1px solid #a8c5cc', margin: '1.5rem 3rem 1.5rem 3rem' }}></div>

                        <Row style={{ marginLeft: '4rem' }}>
                          <h5>加料種類</h5>
                          <Col sm="12" className='form-check-danger' style={{ marginTop: '0.5rem' }}>
                            <label style={{ marginRight: '1rem' }}>
                              <Input style={{ margin: '0.2rem 0.4rem 0.2rem 0rem' }} defaultChecked={customizationsAvailable.bubblesAvailable} type="checkbox" name="pearlsAvailable" onChange={handleCustomizationAvailabilityChange} />
                              可加波霸
                            </label>
                            <label style={{ marginRight: '1rem' }}>
                              <Input style={{ margin: '0.2rem 0.4rem 0.2rem 0rem' }} defaultChecked={customizationsAvailable.pearlsAvailable} type="checkbox" name="pearlsAvailable" onChange={handleCustomizationAvailabilityChange} />
                              可加珍珠
                            </label>
                            <label style={{ marginRight: '1rem' }}>
                              <Input style={{ margin: '0.2rem 0.4rem 0.2rem 0rem' }} defaultChecked={customizationsAvailable.oatsAvailable} type="checkbox" name="oatsAvailable" onChange={handleCustomizationAvailabilityChange} />
                              可加燕麥
                            </label>
                            <label style={{ marginRight: '1rem' }}>
                              <Input style={{ margin: '0.2rem 0.4rem 0.2rem 0rem' }} defaultChecked={customizationsAvailable.coconutJellyAvailable} type="checkbox" name="coconutJellyAvailable" onChange={handleCustomizationAvailabilityChange} />
                              可加椰果
                            </label>
                          </Col>
                        </Row>

                        {/*
                        <Row style={{ marginLeft: '4rem' }}>
                          <Col sm="12">
                            <label htmlFor="toppingAmount" style={{ marginRight: '0.5rem' }}>加料量</label>
                            <div style={{ marginBottom: '0.5rem' }}>
                              <Select 
                                style={{marginLeft:'0.3rem'}} 
                                name="toppingAmount" 
                                onChange={(selection) => handleCustomizationAvailabilityChange(selection)}
                                options={toppingOptions}
                                placeholder={"正常"}
                              >
                              </Select>
                            </div>
                          </Col>
                        </Row>
                        */}

                        <Row style={{ marginLeft: '4rem', marginTop: '0.5rem' }}>
                          <h5>加料量</h5>
                          <Col sm="12" className='form-check-danger' style={{ marginTop: '0.5rem' }}>
                            <label style={{ marginRight: '1rem' }}>
                              <Input style={{ margin: '0.2rem 0.4rem 0.2rem 0rem' }} defaultChecked={customizationsAvailable.toppingAmount.less} type="checkbox" name="toppingAmount.less" onChange={handleCustomizationAvailabilityChange} />
                              少
                            </label>
                            <label style={{ marginRight: '1rem' }}>
                              <Input style={{ margin: '0.2rem 0.4rem 0.2rem 0rem' }} defaultChecked={customizationsAvailable.toppingAmount.normal} type="checkbox" name="toppingAmount.normal" onChange={handleCustomizationAvailabilityChange} />
                              正常
                            </label>
                            <label style={{ marginRight: '1rem' }}>
                              <Input style={{ margin: '0.2rem 0.4rem 0.2rem 0rem' }} defaultChecked={customizationsAvailable.toppingAmount.more} type="checkbox" name="toppingAmount.more" onChange={handleCustomizationAvailabilityChange} />
                              多
                            </label>
                          </Col>
                        </Row>

                        <Row style={{ marginLeft: '4rem', marginTop: '0.5rem' }}>
                          <h5>特殊成分</h5>
                          <Col sm="12" className='form-check-danger' style={{ marginTop: '0.5rem' }}>
                            <label style={{ marginRight: '1rem' }}>
                              <Input style={{ margin: '0.2rem 0.4rem 0.2rem 0rem' }} defaultChecked={customizationsAvailable.containsWhippedCream} type="checkbox" name="containsWhippedCream" onChange={handleCustomizationAvailabilityChange} />
                              有鮮奶油
                            </label>
                            <label style={{ marginRight: '1rem' }}>
                              <Input style={{ margin: '0.2rem 0.4rem 0.2rem 0rem' }} defaultChecked={customizationsAvailable.containsVanilla} type="checkbox" name="containsVanilla" onChange={handleCustomizationAvailabilityChange} />
                              有香草
                            </label>
                          </Col>
                        </Row>


                        <div style={{ borderTop: '1px solid #a8c5cc', margin: '1.5rem 3rem 1rem 3rem' }}></div>

                        <Row style={{ marginLeft: '4rem' }}>
                          <h5>環保杯</h5>
                          <Col sm="12" className='form-check-success' style={{ display: 'flex', alignItems: 'center' }}>
                            <label style={{ marginRight: '1rem' }}>
                              <Input style={{ margin: '0.2rem 0.4rem 0.2rem 0rem' }} defaultChecked={customizationsAvailable.cupBringOptionAvailable} type="checkbox" name="cupBringOptionAvailable" onChange={handleCustomizationAvailabilityChange} />
                              可自帶環保杯
                            </label>

                            <div style={{ display:'flex', alignItems:'center' }}>
                              <label style={{ minWidth:'10rem' }}>自帶環保杯折價 (NTD):</label>
                              <Input style={{ marginBottom: '0.3rem' }} defaultValue={customizationsAvailable.reductionForOwnCup} type="number" name="reductionForOwnCup" onChange={handleCustomizationAvailabilityChange} min="0" step="1" />
                            </div>
                          </Col>
                        </Row>

                        <div style={{ borderTop: '1px solid #a8c5cc', margin: '1.5rem 3rem 1rem 3rem' }}></div>

                      </div>
                    )}

                    {productType === 'Dish' && (
                      <>
                        <label>
                          Option for Spicy:
                          <input
                            type="checkbox"
                            name="spicy"
                            onChange={handleCustomizationAvailabilityChange}
                          />
                        </label>
                        <label>
                          Option to Remove Lettuce:
                          <input
                            type="checkbox"
                            name="noLettuce"
                            onChange={handleCustomizationAvailabilityChange}
                          />
                        </label>
                        <label>
                          Option to Remove Cheese:
                          <input
                            type="checkbox"
                            name="noCheese"
                            onChange={handleCustomizationAvailabilityChange}
                          />
                        </label>
                      </>
                    )}

                  </Form>

                  <Row>
                    <Col sm="2"></Col>
                    <Col sm="10">
                      {createWarnings && <Alert color="danger">{createWarnings}</Alert> }
                      {createSuccess && (
                        <Alert color="success" 
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            minWidth: '30vh',
                          }}
                        >
                          <p style={{ margin: '0rem' }}>{createSuccess}</p>
                        </Alert>
                      )}
                    </Col>
                  </Row>

                  {/* 開啟新專案按鈕 */}
                  <Row className="justify-content-end">
                    <Col lg="12" style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                      <label style={{ marginRight: '2rem', marginBottom: '0rem' }}>
                        <Input style={{ margin: '0.2rem 0.4rem 0.2rem 0rem' }} defaultChecked={continueAdd} type="checkbox" name="continueAdd" onChange={()=>{setContinueAdd(!continueAdd);}} />
                        接著新增下一筆
                      </label>

                      <Button type="submit" color="primary" onClick={createProduct}>
                        <span style={{ marginRight: uploadLoading?'0.3rem':'0rem' }}>新增產品資訊</span>
                        {uploadLoading && 
                          (<i className="bx bx-loader bx-spin font-size-16 align-middle"></i>)
                        }
                      </Button>
                    </Col>
                  </Row>

                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>

  );
};

export default ConfirmOrder;
