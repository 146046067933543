// TwoWayChoice.js
import React, { useState } from 'react';
import { 
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

const TwoWayChoice = ({ item, onChange, onClick, isOpen, onClose, onSave }) => {

  const [updateItem, setUpdateItem] = useState(item);

  const handleChange = ({ target: { name, value } }) => {
    setUpdateItem(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSaveQuestion = () => {
    onSave(updateItem);
    onClose();
  };

  const handleDeleteOption = index => {
    setUpdateItem(prevState => ({
      ...prevState,
      options: prevState.options.filter((_, i) => i !== index)
    }));
  };

  const handleOptionChange = (index, value) => {
    const newOptions = updateItem.options.map((option, i) => 
      i === index ? { ...option, text: value } : option
    );
    setUpdateItem(prevState => ({ ...prevState, options: newOptions }));
  };

  const addOption = () => {
    setUpdateItem(prevState => ({
      ...prevState,
      options: [...prevState.options, { text: `Option ${prevState.options.length + 1}`, direction: '' }]
    }));
  };



  return (

      <>
      <div className="two-way-choice-question" onClick={onClick}>

        <Row style={{ padding: '2vh 3vw' }}>
          <Col xs="3"
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >  
            <p className="question-text" style={{ margin:'0' }}>{item.questionName}</p>
          </Col>

          <Col xs="9" style={{ padding: '0' }}>
            <div className="options-container">
              {item.options.map((option, index) => (
                <div key={index} className="option" style={{ display:'flex' }}>
                  <input 
                    type="radio" 
                    id={`${item._id}-option-${index}`} 
                    name={item._id} 
                    value={option.text}
                    onChange={(e) => onChange(item._id, e.target.value)}
                    style={{ marginRight:'1vw' }}
                  />
                  <label 
                    htmlFor={`${item._id}-option-${index}`}
                    style={{ margin:'0' }}
                  >{`${option.text}  >>  ${option.direction}`}</label>
                </div>
              ))}
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop:'1.5vh', marginBottom:'1.5vh' }}>
          <Col xs="6"
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '0px'
            }}
          >
            <div
              style={{
                background: '#d0e9ff',
                borderRadius: '0px 0px 0px 8px',
                width: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <p style={{ margin:'0' }}>{item.directionAName}</p>
            </div>
          </Col>
          <Col xs="6"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              padding: '0px'
            }}
          >
            <div
              style={{
                background: '#fff0b8',
                borderRadius: '0px 0px 8px 0px',
                width: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <p style={{ margin:'0' }}>{item.directionBName}</p>
            </div>
          </Col>
        </Row>

      </div>

      <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeader toggle={onClose}>編輯問題</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="questionName">問題名稱</Label>
            <Input 
              type="text" 
              name="questionName" 
              id="questionName" 
              value={updateItem.questionName} 
              onChange={handleChange} 
            />
          </FormGroup>
          <FormGroup>
            <Label for="directionAName">方向 A 名稱</Label>
            <Input type="text" name="directionAName" id="directionAName" value={updateItem.directionAName} onChange={handleChange} />
          </FormGroup>
          <FormGroup>
            <Label for="directionBName">方向 B 名稱</Label>
            <Input type="text" name="directionBName" id="directionBName" value={updateItem.directionBName} onChange={handleChange} />
          </FormGroup>

          <FormGroup>
            <Label for="options">選項</Label>
            {updateItem.options.map((option, index) => (
              <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                <Input
                  type="text"
                  name={`option-${index}`}
                  value={option.text}
                  onChange={(e) => handleOptionChange(index, e.target.value)}
                  style={{ marginRight: '1rem' }}
                />
                <Button color="outline-danger" onClick={() => handleDeleteOption(index)}>-</Button>
                <Label check style={{ marginRight: '1rem' }}>
                  <Input
                    type="radio"
                    name={`direction-${index}`}
                    checked={option.direction === 'A'}
                    onClick={() => {
                      console.log('updateItem.options:', updateItem.options);
                      const newOptions = [...updateItem.options];
                      console.log('newOptions:', newOptions);
                      console.log('newOptions[index]:', newOptions[index]);
                      newOptions[index].direction = 'A';
                      setUpdateItem(prevState => ({
                        ...prevState,
                        options: newOptions
                      }));
                    }}
                    onChange={()=>{}}
                  />
                  {updateItem.directionAName}
                </Label>
                <Label check>
                  <Input
                    type="radio"
                    name={`direction-${index}`}
                    checked={option.direction === 'B'}
                    onClick={() => {
                      console.log('updateItem.options:', updateItem.options);
                      const newOptions = [...updateItem.options];
                      console.log('newOptions:', newOptions);
                      console.log('newOptions[index]:', newOptions[index]);
                      newOptions[index].direction = 'B';
                      setUpdateItem(prevState => ({
                        ...prevState,
                        options: newOptions
                      }));
                    }}
                    onChange={()=>{}}
                  />
                  {updateItem.directionBName}
                </Label>
              </div>
            ))}
            <Button onClick={addOption}>增加選項</Button>
          </FormGroup>

        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSaveQuestion}>保存</Button>
          <Button color="secondary" onClick={onClose}>取消</Button>
        </ModalFooter>
      </Modal>

    </>

  );
};

export default TwoWayChoice;


