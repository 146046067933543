import React, { useEffect, useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { isEmpty } from "lodash";
import { 
  Col, 
  Container,
  Row 
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import ProjectTranslate from "./projectTranslate";
import TeamMembers from "./teamMembers";
import OverviewChart from "./overviewChart";
import { options, series } from "common/data/projects";
import AttachedFiles from "./attachedFiles";
import Comments from "./comments";

// Custom Axios
import axiosInstance from '../../../utils/axiosInstance';
import { useDeepCompareMemoize } from 'utils/imageUtils';

//redux
import { useSelector, useDispatch } from "react-redux";


const ProjectsOverview = props => {

  const [colSize, setColSize] = useState("12");

  // Function to be called by ProjectTranslate to update colSize
  const updateColSize = useCallback((newSize) => {
    setColSize(newSize);
  }, []);

  /*---- Title and Setup ----*/

  document.title = "專案翻譯 | TheChart.AI";
  const dispatch = useDispatch();

  /*---- Title and Setup Tail ----*/



  /*---- Getting Projects Data ----*/

  const { project, projectImages } = useSelector(state => ({
    project: (state.projects.projects.find(p => p._id === props.router.params.id)
      || state.projects.sharedProjects.find(s => s._id === props.router.params.id)),
    projectImages: state.projects.projectImages,
  }));

  /*---- Getting Projects Data Tail ----*/



  /*---- Getting Databases Data ----*/

  useEffect(() => {
    dispatch({ type: 'GET_BASE_DATABASES' });
  }, [dispatch]);

  const { databases, baseDatabases } = useSelector(state => ({
    databases: state.databases.databases,
    baseDatabases: state.databases.baseDatabases,
  }));

  /*---- Getting Databases Data Tail ----*/

  const { showFooter } = useSelector(state => ({
    showFooter: state.Layout.showFooter,
  }));



  /*---- Monitoring Props ----*/

  useEffect(() => {
    //console.log('Props_changed...', props);
  }, [props]);

  useEffect(() => {
    //console.log('Project_changed...', project);
  }, [project]);

  useEffect(() => {
    //console.log('ProjectImages_changed...', projectImages);
  }, [projectImages]);

  useEffect(() => {
    //console.log('Database_changed...', databases);
  }, [databases]);

  useEffect(() => {
    //console.log('baseDbs_changed...', baseDatabases);
  }, [baseDatabases]);

  /*---- Monitoring Props Tail ----*/

  useEffect(() => {
    //console.log('showFooter...', showFooter);
  }, [showFooter]);



  return (
    <React.Fragment>
      <div className="page-content" style={{ paddingBottom: !showFooter && "0rem" }}>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="專案" breadcrumbItem="專案主頁面" colSize={colSize} />

            <>

              {/* First Row */}
              <Row>

                <Col lg="12">
                  {project && baseDatabases && databases && (
                    <ProjectTranslate 
                      project={project} 
                      projectImages={projectImages} 
                      baseDbs={baseDatabases} 
                      dbs={databases}
                      updateColSize={updateColSize}
                    />
                  )}
                  
                </Col>

                {/* Team Card */}
                {/*
                <Col lg="4">
                  <TeamMembers team={projectDetail.team} />
                </Col>
                */}

              </Row>

              {/* Second Row */}
              <Row>

                {/* Overview Card */}
                {/*
                <Col lg="4">
                  <OverviewChart options={options} series={series} />
                </Col>
                */}

                {/* File Card */}
                {/*
                <Col lg="4">
                  <AttachedFiles files={projectDetail.files} />
                </Col>
                */}

                {/* Comments Card */}
                {/*
                <Col lg="4">
                  <Comments comments={projectDetail.comments} />
                </Col>
                */}

              </Row>

            </>

        </Container>
      </div>
    </React.Fragment>
  );
};

ProjectsOverview.propTypes = {
  match: PropTypes.object,
};

export default withRouter(ProjectsOverview);



