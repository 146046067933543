// Custom Axios
import axiosInstance from 'utils/axiosInstance';

// List base Databases
export const getBaseDatabase = async () => {
  console.log("[api/database.js] Getting base Databases...");
  try {
    const response = await axiosInstance.get(`/knovia/db-base`);
    const data = response.data;
    console.log('[api/database.js] base Databases fetched:', data);

    return data || [];
  } catch (error) {
    throw error;
  }
}

// Add new Database
export const addNewDatabase = async (database) => {
	try {
		const response = await axiosInstance.post(`/knovia/db`, database);
		const data = response.data;
   	console.log("[api/database.js] Created new database:", data);
   	return data || [];
   } catch (error) {
   	throw error;
   }
}

// Delete existing Database
export const deleteDatabase = async(dbId) => {
	try {
		const response = await axiosInstance.delete(`/knovia/db/${dbId}`);
		const data = response.data;
   	console.log("[api/database.js] Deleted database of Id:", data);
   	return data || [];
   } catch (error) {
   	throw error;
   }
}

// Update existing Database
export const updateDatabase = async (dbData) => {
  try {
    const response = await axiosInstance.put(`/knovia/db/${dbData._id}`, dbData);
    const data = response.data;
    console.log("[api/database.js] Updated database:", data);
    return response.data;
  } catch (error) {
    console.error("Error updating database:", error);
    throw error;
  }
};


