import React, { useState, useEffect } from "react";
import { Modal, ModalBody, Input, Button, Alert } from "reactstrap";

import axiosInstance from 'utils/axiosInstance';

import PropTypes from 'prop-types';

const SharedInfoModal = ({ show, onCloseClick, projectId, title='' }) => {

  //const [shareEmail, setShareEmail] = useState('');
  //const [shareWith, setShareWith] = useState([]);
  const [shareInfo, setShareInfo] = useState({});
  const [shareError, setShareError] = useState('');

  //const handleEmailChange = (e) => setShareEmail(e.target.value);
  /*
  const handleShareOrder = async (shareEmail, projectId) => {
    console.log('shareEmail:', shareEmail);
    const payload = { shareEmail };
    try {
      const response = await axiosInstance.post(`/knovia/share-project/${projectId}`, payload);
      console.log('Project shared successfully', response.data);
      setShareError('');
      setShareWith(response.data.updatedProject.shareWith);
    } catch (error) {
      console.error('Error sharing project:', error);
      const thisError = error.response.data.message;
      if(thisError==='userToAdd not found'){
        setShareError('沒有此帳號');
      }
    }
  };
  const handleShare = () => {
    if (shareEmail) {
      handleShareOrder(shareEmail, projectId);
      setShareEmail('');
    }
  };
  */
  
  const retrieveShareInfo = async () => {
    try {
      const response = await axiosInstance.get(`/knovia/get-project-shareinfo/${projectId}`);
      const thisShareInfo = response.data.shareInfo;
      console.log('Project shareInfo:', thisShareInfo);
      setShareInfo(thisShareInfo);
    } catch (error) {
      console.error('Error retrieving project shareInfo:', error);
    }
  }
  useEffect(()=>{
    if(show){ retrieveShareInfo(); }
  }, [show, projectId])


  return (

    <Modal size="sm" isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">

        <ModalBody className="text-center"
          style={{
            padding: '3rem 1.5rem 2rem 1.5rem',
          }}
        >

          {/* Close Button */}
          <button type="button" onClick={onCloseClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
          
          {/* Icon */}
          <div className="avatar-sm mb-4 mx-auto">
            <div className="avatar-title bg-warning text-warning bg-opacity-10 font-size-20 rounded-3">
              <i className="mdi mdi-share-variant-outline"></i>
            </div>
          </div>

          {title && (<p className="text-muted font-size-16 mb-2">{title}</p>)}

          <div 
            style={{
              background: '#fef8ee',
              padding: '0.7rem 0.8rem',
              borderRadius: '0.5rem',
              border: '1px solid #f1b34c',
              margin: '2rem 0rem 2rem 0rem',
              minHeight: '10vh',
            }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <span>{`專案擁有者：${shareInfo.projectOwner}`}</span>
            </div>
            <div style={{ margin: '0.4rem 0.4rem' }}></div>
          </div>

          {/*
          <div className="d-flex mt-2 mb-4 justify-content-between align-items-center">
            <Input
              type="email"
              className="form-control"
              placeholder="新增分享帳號（email）"
              value={shareEmail}
              onChange={handleEmailChange}
              style={{ margin: '0rem 0.5rem 0rem 0rem' }}
            />
            <Button style={{margin:'0rem 0.5rem'}} color="outline-success" size="sm" onClick={handleShare}>+</Button>
          </div>
          */}

          {shareError &&
            <Alert color="danger">{shareError}</Alert>
          }

          {/* Decision Buttons */}
          <div className="hstack gap-2 justify-content-center mb-0">
            <button type="button" className="btn btn-primary" onClick={onCloseClick}>確定</button>
          </div>

        </ModalBody>

      </div>
    </Modal>

  )

}

// Check the types of props passed into the Component
SharedInfoModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  title: PropTypes.string,
}

export default SharedInfoModal;
