import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import withRouter from "components/Common/withRouter";
import PropTypes from 'prop-types';
import { Link, useNavigate } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

// Redux
import { connect } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import { 
  loadDatabaseImages, 
  loadBaseDbImages,
  loadProjectImages,
  loadChatbotImages,
  loadProductImages,
} from "store/actions";

// Custom Utils
import { fetchImage } from 'utils/imageUtils';
// Image
import placeholder from "assets/images/placeholders/placeholder_card.png"

// Top Bar Profile Dropdown Menu
const ProfileMenu = props => {


  /*---- Initial Setup ----*/

  const [menu, setMenu] = useState(false);
  const [profilePic, SetProfilePic] = useState(placeholder);
  const [username, setUsername] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  /*---- Initial Setup Tail ----*/



  /*---- User Data ----*/

  const { userData, tokenNotFound } = useSelector(state => ({
    userData: state.user.userData,
    tokenNotFound: state.user.tokenNotFound,
  }));

  const fetchAndSetProfilePic = async () => {
    const newProfilePic = await fetchImage(userData.profileImg);
    SetProfilePic(newProfilePic);
  };

  useEffect(() => {

    if(!Object.keys(userData).length){ 
      dispatch({ type: 'FETCH_USER_DATA' }); 
    } else {
      if(Object.keys(userData).length){
        console.log('[src/components/.../ProfileMenu.js] userData:', userData);
        if(userData.username){setUsername(userData.username);}
        if(userData.profileImg){fetchAndSetProfilePic();}
        sessionStorage.removeItem("isRedirectedToLogin");
      }
    }

  }, [userData]);

  /*---- User Data Tail ----*/



  /*---- Token Not Found ----*/

  useEffect(() => {
    if (tokenNotFound) {
      localStorage.removeItem('authUser');
      navigate('/login');
    }
  }, [tokenNotFound, navigate]);

  /*---- Token Not Found Tail ----*/



  /*---- Databases and Chatbots ----*/

  const { databases, baseDatabases, projects, sharedProjects, chatbots, products } = useSelector(state => ({
    databases: state.databases.databases,
    baseDatabases: state.databases.baseDatabases,
    projects: state.projects.projects,
    sharedProjects: state.projects.sharedProjects,
    chatbots: state.chatbots.chatbots,
    products: state.products.products,
  }));

  useEffect(() => {
    if (databases.length>0) {
      console.log('[ProfileMenu.js] dispatching loadDatabaseImages...')
      dispatch(loadDatabaseImages(databases));
    }
  }, [databases]);

  useEffect(() => {
    if (baseDatabases.length>0) {
      console.log('[ProfileMenu.js] dispatching loadBaseDbImages...')
      dispatch(loadBaseDbImages(baseDatabases));
    }
  }, [baseDatabases]);

  useEffect(() => {
    if ([...projects, ...sharedProjects].length>0) {
      console.log('[ProfileMenu.js] dispatching loadProjectImages...')
      dispatch(loadProjectImages([...projects, ...sharedProjects]));
    }
  }, [projects, sharedProjects]);

  useEffect(() => {
    if (chatbots.length>0) {
      console.log('[ProfileMenu.js] dispatching loadChatbotImages...')
      dispatch(loadChatbotImages(chatbots));
    }
  }, [chatbots]);

  useEffect(() => {
    if (products.length>0) {
      console.log('[ProfileMenu.js] dispatching loadProductImages...')
      dispatch(loadProductImages(products));
    }
  }, [products]);

  /*---- Databases and Chatbots Tail ----*/



  return (

    <React.Fragment>

      <UncontrolledDropdown className="d-inline-block">

        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={profilePic || placeholder}
            style={{ objectFit: "cover" }}
          />
          <span className="d-xl-inline-block ms-2 me-1">{
            username ?(
              username
            ):(
              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            )}
          </span>
          <i className="mdi mdi-chevron-down d-xl-inline-block" />
        </DropdownToggle>

        {/* Action Menu */}
        <DropdownMenu className="dropdown-menu-end">

          {/* 帳號資訊 in Profile Dropdown Menu */}
          <Link to="/profile" className="dropdown-item">
            <i className="bx bx-user font-size-16 align-middle me-1" />
            <span>{props.t("帳號資訊")}</span>
          </Link>

          <div className="dropdown-divider" />

          {/* 登出 in Profile Dropdown Menu */}
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("登出")}</span>
          </Link>

          {/*
          <Link to="/crypto-wallet" className="dropdown-item">
            <i className="bx bx-wallet font-size-16 align-middle me-1" />
            {props.t("My Wallet")}
          </Link>
          */}

          {/*
          <Link to="/setting" className="dropdown-item">
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            <span>{props.t("Settings")}</span>
          </Link>
          */}

          {/*
          <Link to="/auth-lock-screen" className="dropdown-item">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            <span>{props.t("Lock screen")}</span>
          </Link>
          */}
          
        </DropdownMenu>
        {/* Action Menu Tail */}

      </UncontrolledDropdown>

    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
