import React, { useState, useEffect } from "react";
import { 
  Card, 
  CardBody, 
  CardTitle, 
  Progress,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// redux
import { useSelector, useDispatch } from "react-redux";

import './custom.css';

// Custom Axios
import axiosInstance from 'utils/axiosInstance';
import { fetchImage } from 'utils/imageUtils';
import placeholder from "assets/images/placeholders/placeholder_card.png";

import * as moment from "moment";


const TopCities = () => {

  const dispatch = useDispatch();
  const [allAccountInfo, setAllAccountInfo] = useState([]);
  const [allImageUrls, setAllImageUrls] = useState({});
  const [allImages, setAllImages] = useState({});

  const [totalProjectCount, setTotalProjectCount] = useState(0);
  const [totalDbCount, setTotalDbCount] = useState(0);

  /*---- Send Verification email ----*/

  async function getProfileImage(email, imageUrl) {
    const profileImage = await fetchImage(imageUrl);
    setAllImages(currentImages => ({...currentImages, [email]: profileImage}));
  }

  useEffect(()=>{
    console.log('fetching allImages from allImageUrls...');
    Object.keys(allImageUrls).map(email=>getProfileImage(email, allImageUrls[email]));
  },[allImageUrls]);

  useEffect(()=>{
    //console.log('allImages:', allImages);
  },[allImages]);
  

  /*---- Update allAccountImageUrls, totalProjectCount, totalDbCount when allAccountInfo changes ----*/
  useEffect(()=>{
    let allAccountImageUrls = {};
    allAccountInfo.map(a=>{ 
      allAccountImageUrls[a.email] = (a?.profileImg || '');
    });
    setAllImageUrls(allAccountImageUrls); 
    console.log('allImageUrls:', allAccountImageUrls);


    let thisTotalProjectCount = 0;
    allAccountInfo.map(account=>{
      thisTotalProjectCount += account.projects.length;
    });
    setTotalProjectCount(thisTotalProjectCount);

    let thisTotalDbCount = 0;
    allAccountInfo.map(account=>{
      thisTotalDbCount += account.databases.length;
    });
    setTotalDbCount(thisTotalDbCount);


  }, [allAccountInfo]);

  /*---- Update allAccountImageUrls, totalProjectCount, totalDbCount when allAccountInfo changes Tail ----*/


  const getAllUserData = async () => {
    try {
      const response = await axiosInstance.get('/knovia/get-all-userdata');
      console.log('All User Data:', response.data);
      setAllAccountInfo(response.data);
    } catch (error) {
      console.error('Error getting all user data:', error);
    }
  };

  useEffect(()=>{

    getAllUserData();

  }, [dispatch]);

  /*---- Send Verification email Tail ----*/


  const [viewAttribute, setViewAttribute] = useState('overview');
  const [viewAccount, setViewAccount] = useState('');
  const viewAttributeMappings = {
    'overview': '帳戶基本訊息',
    'usageOverview': '用量總覽',
    'databases': '資料庫',
    'projects': '專案'
  }
  // If viewAttribute changed by user
  const handleViewAttributeChange = (value) => { 
    setViewAttribute(value);
    if(value==='overview' || value==='usageOverview'){ 
      setViewAccount('所有帳戶'); 
    } else {
      if(viewAccount==='所有帳戶'){ setViewAccount(allAccountInfo[0].email); }
    }
  };
  // If viewAccount changed by user
  const handleViewAccountChange = (account) => { 
    console.log('account:', account);
    setViewAccount(account);
  };

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">用戶狀態總覽</CardTitle>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <div className="text-center" style={{ marginRight: '3rem', marginBottom: '1.5rem' }}>
              <div className="mb-4">
                <i className="bx bx-key text-primary display-4" />
              </div>
              <h3>{allAccountInfo.length}</h3>
              <p>目前帳號數量</p>
            </div>

            <div className="text-center" style={{ marginRight: '3rem', marginBottom: '1.5rem' }}>
              <div className="mb-4">
                <i className="bx bx-briefcase-alt text-primary display-4" />
              </div>
              <h3>{totalProjectCount}</h3>
              <p>目前專案總數</p>
            </div>

            <div className="text-center" style={{ marginRight: '0rem', marginBottom: '1.5rem' }}>
              <div className="mb-4">
                <i className="bx bx-hdd text-primary display-4" />
              </div>
              <h3>{totalDbCount}</h3>
              <p>目前資料庫總數</p>
            </div>
          </div>


          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {/* Dropdown for selecting view Attribute */}
            <UncontrolledDropdown style={{ marginBottom: '0.5rem', marginRight: '0.5rem' }}>
              <DropdownToggle tag="button" className="btn btn-warning">
                當前顯示項目：{ viewAttributeMappings[viewAttribute] }
              </DropdownToggle>
              <DropdownMenu>
                {Object.keys(viewAttributeMappings).map(key => (
                  <DropdownItem key={key} onClick={() => handleViewAttributeChange(key)}>
                    {viewAttributeMappings[key]}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>

            {/* Dropdown for selecting available options under specific Attributes */}
            <UncontrolledDropdown>
              <DropdownToggle tag="button" className="btn btn-info">
                檢視帳戶：{ viewAccount || '所有帳戶'}
              </DropdownToggle>
              <DropdownMenu>

                {viewAttribute!=='overview' && 
                  viewAttribute!=='usageOverview' && 
                  allAccountInfo.map(account => (
                    <DropdownItem key={account.email} onClick={() => handleViewAccountChange(account.email)}>
                      {account.email}
                    </DropdownItem>
                  )
                )}
                {(viewAttribute==='overview' || viewAttribute==='usageOverview')&&
                  <DropdownItem key={'overview'} onClick={() => handleViewAccountChange('所有帳戶')}>
                    所有帳戶
                  </DropdownItem>
                }

              </DropdownMenu>
            </UncontrolledDropdown>
          </div>


          <div className="table-responsive mt-4">
            <table className="table align-middle">

              <thead>

                {viewAttribute==='overview' && (
                  <tr>
                    <th className="anywrap" style={{ width: "5vw" }}>Pic</th>
                    <th className="anywrap" style={{ width: "15vw" }}>Email</th>
                    <th className="anywrap" style={{ width: "10vw" }}>Username</th>
                    <th className="anywrap" style={{ width: "7vw" }}>Role</th>
                    <th className="anywrap" style={{ width: "7vw" }}>Google</th>
                    <th className="anywrap" style={{ width: "7vw" }}>Premium</th>
                    <th className="anywrap" style={{ width: "7vw" }}>Projects</th>
                    <th className="anywrap" style={{ width: "5vw" }}>Dbs</th>
                    {/*<th style={{ width: '5vw', wordWrap: "anywhere", whiteSpace: "normal"}}>Progress</th>*/}
                  </tr>
                )}

                {viewAttribute==='usageOverview' && (
                  <tr>
                    <th className="anywrap" style={{ width: "5vw" }}>Pic</th>
                    <th className="anywrap" style={{ width: "15vw" }}>Email</th>
                    <th className="anywrap" style={{ width: "8vw" }}>maxProjects</th>
                    <th className="anywrap" style={{ width: "8vw" }}>maxGlossaries</th>
                    <th className="anywrap" style={{ width: "8vw" }}>wordThisMonth</th>
                    <th className="anywrap" style={{ width: "8vw" }}>pointsLeft</th>
                    <th className="anywrap" style={{ width: "15vw" }}>recentUpdate</th>
                    <th className="anywrap" style={{ width: "6vw" }}>favProj</th>
                    <th className="anywrap" style={{ width: "6vw" }}>favBDbs</th>
                    <th className="anywrap" style={{ width: "6vw" }}>favDbs</th>
                    {/*<th style={{ width: '5vw', wordWrap: "anywhere", whiteSpace: "normal"}}>Progress</th>*/}
                  </tr>
                )}



              </thead>

              <tbody>

                {viewAttribute==='overview' && (
                  allAccountInfo.map(account => (
                    <tr key={account.email}>

                      <td className="anywrap" style={{ width: "5vw" }}>
                        <img 
                          src={allImages[account.email] || placeholder} 
                          className="profile-pic" 
                          alt={`profile_pic_${account.email}`}
                          style={{ height:"2rem", width:"2rem" }}
                        />
                      </td>

                      <td className="anywrap" style={{ width: "15vw" }}><p className="mb-0">{account.email}</p></td>
                      <td className="anywrap" style={{ width: "10vw" }}><p className="mb-0">{account.username}</p></td>
                      <td className="anywrap" style={{ width: "7vw" }}><p className="mb-0">{account.role}</p></td>
                      <td className="anywrap" style={{ width: "7vw" }}><p className="mb-0">{account.isGoogleUser ? 'V' : ''}</p></td>
                      <td className="anywrap" style={{ width: "7vw" }}><p className="mb-0">{account.isPremiumUser ? 'V' : ''}</p></td>
                      <td className="anywrap" style={{ width: "7vw" }}><p className="mb-0">{account.projects.length}</p></td>
                      <td className="anywrap" style={{ width: "5vw" }}><p className="mb-0">{account.databases.length}</p></td>
                      {/*
                      <td>
                        <Progress value="94" color="primary" className="bg-transparent progress-sm" size="sm"/>
                      </td>
                      */}
                    </tr>
                  ))
                )}

                {viewAttribute==='usageOverview' && (
                  allAccountInfo.map(account => (
                    <tr key={account.email}>

                      <td className="anywrap" style={{ width: "5vw" }}>
                        <img 
                          src={allImages[account.email] || placeholder} 
                          className="profile-pic" 
                          alt={`profile_pic_${account.email}`}
                          style={{ height:"2rem", width:"2rem" }}
                        />
                      </td>

                      <td className="anywrap" style={{ width: "15vw" }}><p className="mb-0">{account.email}</p></td>
                      <td className="anywrap" style={{ width: "8vw" }}><p className="mb-0">
                        {account?.stats?.projectsCanBuild !== null && account?.stats?.projectsCanBuild !== undefined ? account.stats.projectsCanBuild : 'NA'}
                      </p></td>
                      <td className="anywrap" style={{ width: "8vw" }}><p className="mb-0">
                        {account?.stats?.glossaryCanBuild !== null && account?.stats?.glossaryCanBuild !== undefined ? account.stats.glossaryCanBuild : 'NA'}
                      </p></td>
                      <td className="anywrap" style={{ width: "8vw" }}><p className="mb-0">
                        {account?.stats?.wordThisMonth !== null && account?.stats?.wordThisMonth !== undefined ? account.stats.wordThisMonth : 'NA'}
                      </p></td>
                      <td className="anywrap" style={{ width: "8vw" }}><p className="mb-0">
                        {account?.stats?.pointsLeft !== null && account?.stats?.pointsLeft !== undefined ? account.stats.pointsLeft : 'NA'}
                      </p></td>
                      <td className="anywrap" style={{ width: "15vw" }}><p className="mb-0">
                        {account?.stats?.lastWordThisMonthUpdate !== null && account?.stats?.lastWordThisMonthUpdate !== undefined ? moment(account.stats.lastWordThisMonthUpdate).format('YYYY-MM-DD HH:mm') : 'NA'}
                      </p></td>
                      <td className="anywrap" style={{ width: "5vw" }}><p className="mb-0">
                        {(account?.stats?.favoriteProjects?.length || 0)}
                      </p></td>
                      <td className="anywrap" style={{ width: "5vw" }}><p className="mb-0">
                        {(account?.stats?.favoriteBaseDbs?.length || 0)}
                      </p></td>
                      <td className="anywrap" style={{ width: "5vw" }}><p className="mb-0">
                        {(account?.stats?.favoriteDbs?.length || 0)}
                      </p></td>

                      {/*
                      <td>
                        <Progress value="94" color="primary" className="bg-transparent progress-sm" size="sm"/>
                      </td>
                      */}
                    </tr>
                  ))
                )}
                
              </tbody>

            </table>
          </div>


        </CardBody>
      </Card>
    </React.Fragment>
  )

}

export default TopCities;
