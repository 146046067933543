import React from 'react';

//const Date = (cell) => { return cell.value ? cell.value+"*" : ''; };
const Masterknowledge = (cell) => { return cell.value ? cell.value : ''; };
const Masterquestion = (cell) => { return cell.value ? cell.value : ''; };

const TimeStamp = (cell) => {
  const formattedDate = `${cell.value.substring(0, 4)}`
    +`/${cell.value.substring(4, 6)}`
    +`/${cell.value.substring(6, 8)}`
    +` ${cell.value.substring(8, 10)}`
    +`:${cell.value.substring(10, 12)}`
    +`:${cell.value.substring(12, 14)}`
  return formattedDate ? `${formattedDate}` : '';
};

const ImageName = (cell) => {
  return `<<${cell.value?.split('_').slice(1).join('_')}>>` || '<<>>';
}

const ImageContent = ({ value, imageData, onImageClick }) => {
  return (
    <div>
      <img
        src={imageData}
        alt={value}
        style={{ width: '100px', height: 'auto', cursor: 'pointer' }}
        onClick={() => onImageClick(imageData)}
      />
    </div>
  );
};


const SubknowledgeCol = (cell) =>{
  return cell.value.length;
}

function formatDate(dateStr) {
  const date = new Date(dateStr);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

const UploadTimeStamp = (cell) =>{
  return formatDate(cell.value) || '';
}

export {
  ImageName,
  ImageContent,
  Masterknowledge,
  Masterquestion,
  SubknowledgeCol,
  TimeStamp,
  UploadTimeStamp
};