// Custom Axios
import axiosInstance from 'utils/axiosInstance';

export const calculateOrderPrice = async (orderDrink) =>{

  let totalPrice = 0;
  try {
    const response = await axiosInstance.post(`/knovia/calculate-order`, { orderDrink });
    const data = response.data;
    const { totalPrice } = data;
    console.log('[api/product.js] Amount calculated:', data);

    return totalPrice || [];
  } catch (error) {
    throw error;
  }

}

export const getProductSet = async () => {
  console.log("[api/[product].js] Getting ProductSet...");

  try {
    const response = await axiosInstance.get(`/knovia/products-user`);
    const data = response.data;
    console.log('[api/product.js] Products fetched:', data);

    return data || [];
  } catch (error) {
    throw error;
  }

} 

// Add new Product
export const addNewProduct = async (product) => {
	try {
		const response = await axiosInstance.post(`/knovia/products`, product);
		const data = response.data;
   	console.log("[api/product.js] Created new product:", data);
   	return data || [];
   } catch (error) {
   	throw error;
   }
}

// Delete existing Product
export const deleteProduct = async(pdId) => {
	try {
		const response = await axiosInstance.delete(`/knovia/product/${pdId}`);
		const data = response.data;
   	console.log("[api/product.js] Deleted product Id:", data);
   	return data || [];
   } catch (error) {
   	throw error;
   }
}

// Update existing Product
export const updateProduct = async (pdData) => {
  try {
    const response = await axiosInstance.put(
    	`/knovia/products/${pdData._id}`, 
    	cbData,
    );
    const data = response.data;
    console.log("[api/product.js] Updated product:", data);
    return response.data;
  } catch (error) {
    console.error("Error updating product:", error);
    throw error;
  }
};

// Validate an Item Name
export const findItemName = async(itemName) => {
  try {
    const response = await axiosInstance.post(
      `/knovia/find-itemName`, 
      { itemName },
    );
    const { message, itemName:name } = response.data;
    console.log("[api/product.js] Complete finding itemName:", itemName, ";result:", message);
    return { message, itemName:name };
  } catch (error) {
    console.error("Error finding itemName:", error);
    throw error;
  }
}



