// Custom Axios
import axiosInstance from '../utils/axiosInstance';

export const getUserData = async () => {
  console.log("[api/api.js] Getting UserData from server...");

  try {
    const response = await axiosInstance.get(`/knovia/userdata`);
    return response.data;
  } catch (error) {
    throw error;
  }

};

export const updateUser = async (userData) => {
  console.log("[api/api.js] Updating UserData from server...");
  
  try {
    const response = await axiosInstance.put(`/knovia/updateuser`, userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const userLogin = async (res) => {
  try {

    const {email, password} = res;
    const response = await axiosInstance.post('/knovia/login', { email, password });

    // Setting the JWT token in localStorage
    const { accessToken, refreshToken } = response.data;
    const authUser = { accessToken, refreshToken, email };
    localStorage.setItem("authUser", JSON.stringify(authUser));
    // Reset isRedirectedToLogin
    sessionStorage.removeItem("isRedirectedToLogin");

    return response.data;
  } catch (error) {
    console.error("[src/api/api.js] Error in userLogin", error);
    return Promise.reject(error);
  }
};

export const googleUserLogin = async (res) => {
  console.log("[api/api.js] Google user Login Processing...");
  try {
    console.log('res', res);
    const googleToken = res.credential;
    //console.log("Encoded JWT ID token:", googleToken);
    const response = await axiosInstance.post(`/knovia/google-login`,{ googleToken });
    const { accessToken, refreshToken } = response.data;
    //console.log('token', token);
    // Setting the JWT token in localStorage
    localStorage.setItem("authUser", JSON.stringify({ accessToken, refreshToken }));
    // Reset isRedirectedToLogin
    sessionStorage.removeItem("isRedirectedToLogin");
    
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verifyAccountApi = async (token) => {
  console.log("[api/api.js] Verifying account from server...");
  const response = await axiosInstance.post('/knovia/verify-account', { token });
  return response.data;
};



