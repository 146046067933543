import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";

import { layoutTypes } from "./constants/layout";

// Import all routes
import { authProtectedRoutes, publicRoutes } from "./routes";

// Import middlewares
import Authmiddleware from "./routes/route";

// Layout Formats
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Custom Axios
import axiosInstance from './utils/axiosInstance';
// Custom Hook
import useHandleUnauthorized from './hooks/useHandleUnauthorized';

// Import scss
import "./assets/scss/theme.scss";

import fakeBackend from "./helpers/AuthType/fakeBackend";
// Activating fake backend
fakeBackend();

const getLayout = (layoutType) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout;
      break;
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout;
      break;
    default:
      break;
  }
  return Layout;
};

const App = () => {

  // Navigate the user to login page if not authorized in AxiosInstance
  useHandleUnauthorized();

  const { layoutType } = useSelector((state) => ({
    layoutType: state.Layout.layoutType,
  }));

  const Layout = getLayout(layoutType);

  return (
    <React.Fragment>
      
      <Routes>

        {/* Public Routes */}
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <NonAuthLayout>
                {route.component}
              </NonAuthLayout>
            }
            key={idx}
            exact
          />
        ))}

        {/* Protected Routes */}
        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                <Layout>{route.component}</Layout>
              </Authmiddleware>}
            key={idx}
            exact
          />
        ))}

      </Routes>

    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

export default App;
