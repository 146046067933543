import React, { useState, useEffect } from 'react';
import { Row, Col, Progress } from 'reactstrap';

const UploadProgress = ({ uploadLoading, duration }) => {
  const [progressValue, setProgressValue] = useState(1);

  useEffect(() => {
    if (uploadLoading) {
      setProgressValue(1);
      const totalIntervalTime = duration * 1000;
      const progressSpeed = totalIntervalTime / 100;

      console.log('totalIntervalTime:', totalIntervalTime);
      console.log('progressSpeed:', progressSpeed);

      const interval = setInterval(() => {
        setProgressValue(prevValue => {
          if (prevValue >= 100) {
            clearInterval(interval);
            return 100;
          }
          return prevValue + 1;
        });
      }, progressSpeed);

      return () => clearInterval(interval); // Clear interval on component unmount
    }
  }, [uploadLoading, duration]);

  const getProgressColor = (value) => {
    if (value >= 0 && value <= 20) {
      return 'danger';
    } else if (value >= 21 && value <= 50) {
      return 'warning';
    } else if (value >= 51 && value <= 80) {
      return 'primary';
    } else if (value >= 81 && value <= 100) {
      return 'success';
    }
    return 'primary'; // default color
  };

  return (
    uploadLoading && (
      <Row style={{ marginTop: '2rem', marginBottom: '0.8rem' }}>
        <Col lg="2"></Col>
        <Col lg="10">
          <div>
            <Progress color={getProgressColor(progressValue)} value={progressValue}>
              {progressValue}%
            </Progress>
          </div>
        </Col>
      </Row>
    )
  );
};

export default UploadProgress;




