import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone";
import Select from "react-select";
import { 
  Alert,
  Button, 
  Card, 
  CardBody, 
  CardTitle, 
  Col, 
  Container, 
  Form, 
  FormGroup, 
  Input, 
  Label, 
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Toast,
  ToastHeader,
} from "reactstrap";

// Import Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Custom Axios
import axiosInstance from 'utils/axiosInstance';

import { uploadImg } from 'utils/imageUtils';
import { dateGen, formatBytes } from 'utils/toolUtils';

// Custom Utils
import { Pagination } from 'utils/pagination';

// Redux
import { useSelector, useDispatch } from "react-redux";
import { 
  addNewProject
} from "../../store/actions";

// Mammoth: read Word
import mammoth from "mammoth";
// XLSX: read Excel
import * as XLSX from 'xlsx';

// Import file icons and images
import wordIcon from "../../assets/images/small/word_file_icon.png";
import pdfIcon from "../../assets/images/small/pdf_file_icon.png";
import excelIcon from "../../assets/images/small/excel_file_icon.png";
import defaultIcon from "../../assets/images/small/unknown_file_icon.png";
import placeholder from "assets/images/placeholders/company_profile_placeholder.png";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { UPLOAD_MAX_NUMBER_FIELDS } from "constants/uploadConstants";

function getFileIcon(file) {
  const fileName = file.name.toLowerCase();
  if (fileName.endsWith('.doc') || fileName.endsWith('.docx')) {
    return wordIcon;
  } else if (fileName.endsWith('.pdf')) {
    return pdfIcon;
  } else if (fileName.endsWith('.xls') || fileName.endsWith('.xlsx') || fileName.endsWith('.csv')) {
    return excelIcon;
  } else {
    return defaultIcon; // Default icon or any other filetype
  }
}

// Function to map uploadStatus to color
const getUploadStatusColor = (status) => {
  // Regex to match the pattern "File format .<extension> not allowed"
  // [\.] is the '.', [.+] is any characters
  const fileFormatNotAllowedRegex = /^File format \..+ not allowed$/;

  // Color for file format not allowed
  if (fileFormatNotAllowedRegex.test(status)) { return 'orange'; }
  switch (status) {
    case 'File uploaded successfully!':
      return 'mediumseagreen';
    case 'Failed to upload file.':
    case 'Error occurred during upload.':
      return 'red';
    default:
      return 'black'; // Default color
  }
};


/*---- Main Function ----*/
const ProjectsCreate = () => {


  /*---- Title and Initial Setup ----*/

  document.title = "新增專案 | TheChart.AI";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector(state => state.user.userData);

  /*---- Title and Initial Setup ----*/

  

  /*---- Form fields States and Changes ----*/

  const [projectName, setProjectName] = useState('');
  const [projectDesc, setProjectDesc] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [toastOpen, setToastOpen] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');

  const handleProjectNameChange = (e) => setProjectName(e.target.value);
  const handleProjectDescChange = (e) => setProjectDesc(e.target.value);
  const handleStartDateChange = (date) => setStartDate(date);
  const handleEndDateChange = (date) => setEndDate(date);

  /*---- Form fields States and Changes Tail ----*/


  const [hasTitleRow, setHasTitleRow] = useState(false);

  const handleCheckboxChange = (e) => {
    setHasTitleRow(!hasTitleRow);
  };


  /*---- Read Word Files in Dropzone and set paragraphs ----*/


  const [filesData, setFilesData] = useState([]); // To store processed data of files
  const [currentPreviewIndex, setCurrentPreviewIndex] = useState(null);


  const [paragraphs, setParagraphs] = useState([]);
  // For Showing file format error
  const [fileErrorMessage, setFileErrorMessage] = useState('');

  function handleAcceptedFiles(files) {
    const fileNames = filesData.map(file => file.name);
    const remainingQuota = UPLOAD_MAX_NUMBER_FIELDS - filesData.length;

    // Processing the first files selected
    const fileProcessingPromises = files.slice(0,remainingQuota).map(file => {
      return new Promise((resolve, reject) => {
        // duplicated handle
        if (fileNames.includes(file.name)) {
          setToastOpen(true);
          setWarningMessage('上傳檔案名稱重複');
          return '上傳檔案名稱重複';
        }

        const fileType = file.name.split('.').pop().toLowerCase();
        if (fileType === 'doc' || fileType === 'docx') {

          setFileErrorMessage('');

          const reader = new FileReader();

          reader.onload = (event) => {
            mammoth.extractRawText({ arrayBuffer: event.target.result })
              .then(result => {
                const newFileData = {
                  name: file.name,
                  paragraphs: result.value.split('\n\n'),
                  formattedSize: formatBytes(file.size),
                  type: 'word',
                };
                setFilesData(prevFilesData => [...prevFilesData, newFileData]);
                resolve();
              })
              .catch(err => {
                console.log(err);
                reject(err);
              });
          };

          reader.readAsArrayBuffer(file);

          reader.onerror = (error) => { reject(error); };

        // Process Excel Files
        } else if (fileType === 'xls' || fileType === 'xlsx' || fileType === 'csv'){
        
          setErrorMessage('');

          const fileReader = new FileReader();
          fileReader.readAsArrayBuffer(file);

          fileReader.onload = (e) => {
            const bufferArray = e.target.result;

            const wb = XLSX.read(bufferArray, { type: 'buffer' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];

            // Read the data into an array of arrays
            const rawData = XLSX.utils.sheet_to_json(ws, { header: 1 });

            let keys;
            let data;

            if (hasTitleRow) {
              keys = rawData[0];
              data = rawData.slice(1).map(row => {
                const obj = {};
                [row[0]].forEach((cell, index) => {
                  obj[keys[index]] = cell;
                });
                return obj;
              });
            } else {
              // First row is data, generate keys based on column index
              keys = rawData[0].map((_, index) => `Column ${index + 1}`);
              data = rawData.map(row => {
                const obj = {};
                [row[0]].forEach((cell, index) => {
                  obj[keys[index]] = cell;
                });
                return obj;
              });
            }

            const newFileData = {
              name: file.name,
              paragraphs: data.map(d=>d['Column 1']),
              //data: { data, keys },
              formattedSize: formatBytes(file.size),
              type: 'excel',
            };
            setFilesData(prevFilesData => [...prevFilesData, newFileData]);
            resolve();

          };

          fileReader.onerror = (error) => { reject(error); };

        } else {  
          setFileErrorMessage('請上傳 Word 檔');
          console.log(`Not processing file ${file.name} as this is a ${fileType} file`);
          resolve(null); // Resolve the promise for non-Word documents
        }

      }).then((d) => {

        // Create a new object with file's properties and additional attributes
        console.log("Now previewing File:", {
          ...file,
          filename: file.name,
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size)
        })
        return {
          ...file,
          filename: file.name,
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size)
        };

      });
    });

    Promise.all(fileProcessingPromises).then(filesData => {
      //setFilesData(filesData.filter(file => file != null)); // Filter out nulls (skipped files)
      setCurrentPreviewIndex(filesData.length > 0 ? 0 : null); // Automatically set to preview the first file
    });

  }

  useEffect(()=>{
    console.log(`filesData${currentPreviewIndex}`, filesData[currentPreviewIndex]);
    if(filesData[currentPreviewIndex]){
      setParagraphs(filesData[currentPreviewIndex].paragraphs);
    }
  }, [currentPreviewIndex])

  /*---- Read Word Files in Dropzone and set paragraphs Tail ----*/



  /*---- Processing Project Profile Image ----*/

  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState('還沒有選擇圖片喔');
  const [imageUrl, setImageUrl] = useState(placeholder || '');
  const [uploadStatus, setUploadStatus] = useState('');
  const fileInputRef = useRef(null);

  const handleButtonClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    setUploadStatus('');
    console.log("Selected file:", event.target.files[0]);
    setFile(event.target.files[0]);
  };

  useEffect(() => {
    const performUpload = async () => {
      if (file) { 
        // Reset upload status
        setUploadStatus('');
        const date14string = await dateGen();
        const newfilename = date14string + '_' + file.name;
        setFilename(newfilename);
        const {newImageUrl, uploadStatus} = await uploadImg(newfilename, file);
        setUploadStatus(uploadStatus);
        setImageUrl(newImageUrl);
      }
    }

    performUpload();
  }, [file]);

  /*---- Processing Project Profile Image Tail ----*/



  /*---- Create New Project when Form Submit ----*/

  const [errorMessage, setErrorMessage] = useState('');

  const { error, loading, projectAddSuccess } = useSelector(state => ({
    error: state.projects.error,
    loading: state.projects.loading,
    projectAddSuccess: state.projects.projectAddSuccess,
  }));

  useEffect(() => {
    if (projectAddSuccess) {
      dispatch({ type: 'RESET_PROJECT_ADD_SUCCESS' });
      navigate('/projects-list');
    }
  }, [projectAddSuccess, navigate]);

  useEffect(()=>{
    console.log('error:', error);
    if(error?.response?.data?.message){setErrorMessage(error.response.data.message);}
  },[error]);

  const createProject = async (e) => {

    e.preventDefault();


    /*---- Step 1: Prepare projectData ----*/
    const nonBlankParagraphs = paragraphs.filter(p => Boolean(p) && p.trim() !== '');
    const paragraphsObject = nonBlankParagraphs.reduce((obj, paragraph, index) => {
      obj[index + 1] = paragraph;
      return obj;
    }, {});

    const projectData = {
      projectName,
      projectDesc: (projectDesc || projectName),
      langSource,
      langTarget,
      projectImg: filename === "還沒有選擇圖片喔" ? '預設圖片' : filename,
      selectedFiles: filesData,
      startDate: await dateGen(startDate),
      endDate: await dateGen(endDate),
      user: userData.email,
    };


    /*---- Step 2: Implement Validation Functions ----*/
    const isValidProjectName = name => name.trim() !== '';
    const isValidProjectDesc = desc => desc.trim() !== '';
    const isValidLangSource = langs => langs.trim() !== '';
    const isValidLangTarget = langt => langt.trim() !== '';
    const isValidProjectImg = img => img.trim() !== '';
    const isValidProjectFile = selectedFiles => selectedFiles.length>0;
    const isValidDateRange = (start, end) => new Date(start) <= new Date(end);
    const isNotEqual = (langs, langt) => langs !== langt;

    console.log("Create Project...")
    console.log("Project metadata:", projectData)
    console.log("Project paragraphs:", projectData.paragraphs);



    /*---- Step 3: Validate Before Sending to Server ----*/
    const validations = [
      () => isValidProjectName(projectData.projectName) ? null : '請輸入專案名稱',
      () => isValidProjectImg(projectData.projectImg) ? null : '請上傳專案圖片',
      () => isValidLangSource(projectData.langSource) ? null : '請選擇專案來源語言',
      () => isValidLangTarget(projectData.langTarget) ? null : '請選擇專案目標語言',
      () => isNotEqual(projectData.langTarget, projectData.langSource) ? null : '專案來源語言與目標語言不得相同',
      () => isValidProjectFile(projectData.selectedFiles) ? null : '請上傳專案檔案',
      () => isValidDateRange(startDate, endDate) ? null : '請調整專案時程',
    ];

    // Iterate over validations and process the first error encountered
    for (let validate of validations) {
      const errorMessage = validate();
      if (errorMessage) {
        setErrorMessage(errorMessage);
        console.error(errorMessage);
        return; // Stop validation process and prevent sending data
      }
    }

    try {
      // Send new projectData
      dispatch(addNewProject(projectData));
    } catch (error) {
      console.error("Error sending data to server:", error);
    }

  };

  /*---- Create New Project when Form Submit Tail ----*/


  const [langSource, setLangSource] = useState('');
  const [langTarget, setLangTarget] = useState('');
  const handleLangSourceChange = (event) => { setLangSource(event.target.value) };
  const handleLangTargetChange = (event) => { setLangTarget(event.target.value) };


  // console.log('Rerendered...');



  /*---- Pagination START ----*/

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(30);
  const [totalPages, setTotalPages] = useState(1);
  const [currentItems, setCurrentItems] = useState([]);

  // Updating current items, items viewable in current page
  useEffect(() => {
    const newLastItem = currentPage * itemsPerPage;
    const newFirstItem = newLastItem - itemsPerPage;
    if(paragraphs){setCurrentItems(paragraphs.slice(newFirstItem, newLastItem));}
  }, [currentPage, itemsPerPage, paragraphs]);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  // Set and Reset totalPage when paragraphs changes
  useEffect(() =>{
    setCurrentPage(1);
  },[currentPreviewIndex])
  useEffect(() => { 
    if(paragraphs){setTotalPages(Math.ceil(paragraphs.length / itemsPerPage));}
  }, [paragraphs, itemsPerPage]);
  // If itemsPerPage changed by user
  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(Number(value)); // event.target.value is the chosen value
    setCurrentPage(1); // Reset to the first page
  };
  const itemsPerPageMappings = {
    '30': '30',
    '60': '60',
    '120': '120',
  }
  
  /*---- Pagination TAIL ----*/


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="專案" breadcrumbItem="開啟新專案" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">新專案：上傳文件</CardTitle>

                  {/*--- 專案資訊表格 ---*/}
                  <Form>

                    {/* 輸入專案名稱 */}
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectname"
                        className="col-form-label col-lg-2"
                      >
                        專案名稱
                      </Label>

                      <Col lg="10">
                        <Input
                          id="projectname"
                          name="projectname"
                          type="text"
                          className="form-control"
                          placeholder="輸入專案名稱"
                          value={projectName}
                          onChange={handleProjectNameChange}
                        />
                      </Col>
                    </FormGroup>

                    {/* 選擇專案圖片 */}
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="projectpic"
                        className="col-form-label col-lg-2"
                      >
                        專案圖片
                      </Label>
                      <Col lg="10">

                        <Row>
                          <input
                            type="file"
                            accept=".png, .jpg, .jpeg, .gif"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                          />
                          <Col sm="3">
                            <button className="btn btn-outline-info" onClick={handleButtonClick}>選擇圖片</button>
                          </Col>
                          <Col sm="9" style={{display:"flex", alignItems:"center"}}>
                            <span>{filename}</span>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm="12">
                            {uploadStatus && (
                              <p style={{ color: getUploadStatusColor(uploadStatus) }}>
                                {uploadStatus}
                              </p>
                            )}
                          </Col>
                        </Row>

                        <Row>
                          <div>
                            {imageUrl && <img src={imageUrl} className="profile-pic" alt="Fetched" />}
                          </div>
                        </Row>

                      </Col>
                      
                    </FormGroup>


                    {/* 輸入專案描述 */}
                    <FormGroup className="mb-4" row>

                      <Label
                        htmlFor="projectdesc"
                        className="col-form-label col-lg-2"
                      >
                        專案描述
                      </Label>

                      <Col lg="10">
                        <textarea
                          className="form-control"
                          id="projectdesc"
                          rows="3"
                          placeholder={projectName || "輸入專案描述"}
                          value={projectDesc}
                          onChange={handleProjectDescChange}
                        />
                      </Col>

                    </FormGroup>


                    {/*---- 選擇來源語言 langSource ----*/}
                    <FormGroup className="mb-4" row>
                      <Label htmlFor="langSource" className="col-form-label col-lg-2">
                        來源語言
                      </Label>
                      <Col lg="10">
                        <Input
                          id="langSource"
                          name="langSource"
                          type="select"
                          className="form-control"
                          onChange={handleLangSourceChange} // Assume you have a handler function for this
                          // TODO: langOptions 模組化
                        >
                          <option value="">選擇來源語言</option>
                          <option value="中文">中文</option>
                          <option value="英文">英文</option>
                          <option value="日文">日文</option>
                          <option value="韓文">韓文</option>
                          <option value="德文">德文</option>
                          <option value="法文">法文</option>
                          <option value="西班牙文">西班牙文</option>
                          <option value="越南文">越南文</option>
                          <option value="印尼文">印尼文</option>
                          <option value="泰文">泰文</option>
                        </Input>
                      </Col>
                    </FormGroup>

                    {/* 選擇目標語言 langTarget */}
                    <FormGroup className="mb-4" row>
                      <Label htmlFor="langTarget" className="col-form-label col-lg-2">
                        目標語言
                      </Label>
                      <Col lg="10">
                        <Input
                          id="langTarget"
                          name="langTarget"
                          type="select"
                          className="form-control"
                          onChange={handleLangTargetChange} // Assume you have a handler function for this
                        >
                          <option value="">選擇目標語言</option>
                          <option value="中文">中文</option>
                          <option value="英文">英文</option>
                          <option value="日文">日文</option>
                          <option value="韓文">韓文</option>
                          <option value="德文">德文</option>
                          <option value="法文">法文</option>
                          <option value="西班牙文">西班牙文</option>
                          <option value="越南文">越南文</option>
                          <option value="印尼文">印尼文</option>
                          <option value="泰文">泰文</option>
                        </Input>
                      </Col>
                    </FormGroup>


                    {/* 輸入專案時程 */}
                    <FormGroup className="mb-4" row>

                      <Label className="col-form-label col-lg-2">
                        專案時程
                      </Label>

                      <Col lg="10">
                        <Row>

                          {/* 開始時間 */}
                          <Col md={6} className="pr-0">
                            <DatePicker
                              className="form-control"
                              selected={startDate}
                              onChange={handleStartDateChange}
                            />
                          </Col>

                          {/* 結束時間 */}
                          <Col md={6} className="pl-0">                               
                            <DatePicker
                              className="form-control"
                              selected={endDate}
                              onChange={handleEndDateChange}
                            />
                          </Col>

                        </Row>
                      </Col>

                    </FormGroup>

                  </Form>
                  {/*--- 以上為專案資訊表格 ---*/}


                  <Row className="mb-4">


                    <Col lg="2">
                      <Label className="col-form-label">
                        <span style={{ display: 'block'}}>上傳文件 (Word/Excel)</span>
                        <span style={{ display: 'block', marginTop: '0.5rem', marginBottom: '0.5rem'}}>
                          最多 {UPLOAD_MAX_NUMBER_FIELDS} 個檔案
                        </span>
                      </Label>


                      <label>
                        <Input
                          type="checkbox"
                          //defaultChecked={true}
                          checked={hasTitleRow}
                          onClick={handleCheckboxChange}
                          onChange={()=>{}}
                        />
                        <span style={{ marginLeft: "0.3rem" }}>檔案有標題列</span>
                      </label>
                    </Col>

                    {/* 檔案上傳 */}
                    <Col lg="10">
                      <Form>

                        {/* Word 上傳區塊 */}
                        <Dropzone onDrop={acceptedFiles => { handleAcceptedFiles(acceptedFiles) }}>
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone" style={{cursor:"pointer"}}>
                              <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="dz-message needsclick">
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>拖曳檔案到此或點擊上傳檔案</h4>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>

                        {/* 上傳 Word 檔資訊 */}
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          <Toast className="bg-warning w-100" isOpen={toastOpen}>
                            <ToastHeader toggle={() => setToastOpen(false)}>
                              {warningMessage}
                            </ToastHeader>
                          </Toast>
                          {filesData.map((f, i) => {
                            const fileIcon = getFileIcon(f);
                            return (

                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >

                                <div className="p-2">
                                  <Row className="align-items-center">

                                    {/* 檔案預覽圖片 */}
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={fileIcon}
                                      />
                                    </Col>

                                    {/* 檔案名稱及大小 */}
                                    <Col>
                                      <Link to="#" className="text-muted font-weight-bold">
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>

                                  </Row>
                                </div>

                              </Card>

                            );
                          })}
                        </div>

                      </Form>
                    </Col>

                  </Row>

                  {/* Preview Word Content */}
                  <Row className="mb-4">

                    <Col xs="2"
                      className="custom-item-per-page-col"
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "column",
                        justifyContent: "flex-start"
                      }}
                    >
                      <Label className="col-form-label">
                        檔案內容預覽
                      </Label>

                      {/* Dropdown for selecting sort attribute */}
                      <UncontrolledDropdown>
                        <DropdownToggle tag="button" className="btn btn-outline-warning">
                          每頁顯示段落數：{itemsPerPage}
                        </DropdownToggle>
                        <DropdownMenu>
                          {Object.keys(itemsPerPageMappings).map(key => (
                            <DropdownItem key={key} onClick={() => handleItemsPerPageChange(key)}>
                              {itemsPerPageMappings[key]}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>

                    </Col>

                    {/* 檔案上傳 */}
                    <Col lg="10">

                      <div style={{ marginBottom: '0.7rem' }}>
                        {filesData.map((file, index) => (
                          <Button color="outline-info" key={index} onClick={() => setCurrentPreviewIndex(index)}
                            style={{ marginRight: '0.3rem' }}
                          >
                           { index+1 }
                          </Button>
                        ))}
                      </div>

                      <p>{`檔案 [ ${currentPreviewIndex+1} ]：${filesData[currentPreviewIndex]?filesData[currentPreviewIndex].name:''}`}</p>
                      {paragraphs && <p>{`資料筆數：${paragraphs.length}`}</p>}

                      <div 
                        style={{
                          border: "1px dashed #cdcbcb", 
                          borderRadius: "0.5rem", 
                          padding: "1rem 1rem 0rem 1rem",
                          minHeight: "5rem",
                          marginTop: "0.3rem",
                        }}
                      >
                        {/* Rendering paragraphs */}
                        {currentItems.map((paragraph, index) => {

                          //console.log('LLparagraph:', paragraph);
                          //include both undefined and empty string cases
                          const paragraphText = paragraph ? paragraph.trim() : '';

                          return (
                            <Row key={((currentPage-1)*itemsPerPage)+index+1}>
                              <Col sm={1}>
                                <p 
                                  style={{color:"#626875", borderBottom:"0px solid #e2e2e2"}}
                                >{((currentPage-1)*itemsPerPage)+index+1}
                                </p>
                              </Col>

                              {paragraphText === '' ?
                              (<Col sm={11}>
                                <p 
                                  style={{color:"#c9d4e9", borderBottom:"1px solid #e2e2e2"}}
                                >{"[ 空白 ]"}
                                </p>
                              </Col>):
                              (<Col sm={11}>
                                <p 
                                  style={{borderBottom:"1px solid #e2e2e2"}}
                                >{paragraph}
                                </p>
                              </Col>)
                              }

                            </Row>
                          )
                        })}

                      </div>
                    </Col>
                  </Row>


                  {/* Preview Word Content */}
                  <Row className="mb-4">

                    <Label className="col-form-label col-lg-2"></Label>
                    {/* Pagination Controls */}
                    {/* 檔案上傳 */}
                    <Col lg="10">
                      <div style={{ marginBottom:"1.5rem" }}>
                        <Pagination currentPage={currentPage} totalPages={totalPages} paginate={paginate} />
                      </div>
                      {/* Pagination Controls Tail */}
                    </Col>
                  </Row>


                  {/* 開啟新專案按鈕 */}
                  <Row className="justify-content-end">
                    <Col lg="10" style={{ display: "flex", justifyContent: "center" }}>
                      <Row style={{ width: "100%" }}>

                        <Col lg="12">
                          {errorMessage ? <Alert color="danger">{errorMessage}</Alert> : null}
                        </Col>
                        <Col lg="12" style={{ display: "flex", justifyContent: "center" }}>
                          <Button type="submit" color="primary" onClick={createProject}>
                            {loading &&
                              (<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>)
                            }
                            開新專案
                          </Button>
                        </Col>
                        
                      </Row>
                    </Col>
                  </Row>

                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>

  );
};

export default ProjectsCreate;
