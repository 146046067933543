// ShortAnswerQuestion.js
import React, { useState } from 'react';
import { 
  Button,
  Row,
  Col,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label
} from "reactstrap";

const ShortAnswerQuestion = ({ item, onChange, onClick, isOpen, onClose, onSave }) => {

  const [editModal, setEditModal] = useState(false);
  const [updateItem, setUpdateItem] = useState(item);

  const toggleEditModal = () => setEditModal(!editModal);

  const handleChange = ({ target: { name, value } }) => {
    setUpdateItem(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSaveQuestion = () => {
    onSave(updateItem);
    onClose();
  };

  return (
    <>
      <div className="short-answer-question"
        style={{ paddingRight: '10vw' }}
        onClick={onClick}
      >
        <Row style={{ padding: '2vh 3vw' }}>
          <Col xs="3"
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <p className="question-text" style={{ margin:'0' }}>{item.questionName}</p>
          </Col>
          <Col xs="9">
            <Input 
            	type="text" 
            	className="short-answer-input" 
            	placeholder={item.placeholder || 'Type your answer here...'} 
            	onChange={(e) => onChange(item._id, e.target.value)}
            />
          </Col>
        </Row>
      </div>

      <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeader toggle={onClose}>編輯問題</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="questionName">問題名稱</Label>
            <Input 
              type="text" 
              name="questionName" 
              id="questionName" 
              value={updateItem.questionName} 
              onChange={handleChange} 
            />
          </FormGroup>
          <FormGroup>
            <Label for="placeholder">Placeholder</Label>
            <Input 
              type="text" 
              name="placeholder" 
              id="placeholder" 
              value={updateItem.placeholder} 
              onChange={handleChange} 
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSaveQuestion}>保存</Button>
          <Button color="secondary" onClick={onClose}>取消</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ShortAnswerQuestion;

