import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Row,
  Button,
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter, 
} from "reactstrap";

import { dateGen } from 'utils/toolUtils';
import { uploadImg } from 'utils/imageUtils';

const selectOptionsMapping = {
  dbClient: [
    { value: "", label: "選擇客戶" },
    { value: "台聚", label: "台聚" },
    { value: "京城", label: "京城" },
    { value: "是方", label: "是方" },
    { value: "賦語科技", label: "賦語科技" },
  ],
  tags: [
    { value: "", label: "選擇標籤" },
    { value: "財報", label: "財報" },
    { value: "年報", label: "年報" },
    { value: "永續報告書", label: "永續報告書" },
    { value: "議事手冊", label: "議事手冊" },
    { value: "網站", label: "網站" },
    { value: "產品型錄", label: "產品型錄" },
    { value: "其他", label: "其他" },
  ]
};

const orderedKeys = ["dbName", "img", "dbClient", "tags"];
const keyToLabelMapping = {
  dbName: "資料庫名稱",
  img: "資料庫圖像",
  dbClient: "建立者/客戶",
  tags: "標籤",
};

const NewDataModal = ({ isAddNewDbModalOpen, setIsAddNewDbModalOpen }) => {


  /*---- Glossary Icon image file and file selection ----*/

  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState('還沒有選擇圖片喔');

  const handleFileChange = (event) => {
    setUploadStatus('');
    console.log("Selected file:", event.target.files[0]);
    setFile(event.target.files[0]);
  };

  /*---- Glossary Icon image file and file selection Tail ----*/

  const [imageUrl, setImageUrl] = useState('');
  const [uploadStatus, setUploadStatus] = useState('');
  const [uploadLoading, setUploadLoading] = useState(false);

  useEffect(() => {

    const uploadNewImage = async() => {
      if (file) { 
        setUploadLoading(true);
        try {
          const date14string = await dateGen();
          const newfilename = date14string+'_'+file.name;
          setFilename(newfilename);
          const {newImageUrl, uploadStatus} = await uploadImg(newfilename, file); 

          setImageUrl(newImageUrl);
          setUploadStatus(uploadStatus);
          setUploadLoading(false);
        } catch(error){
          console.log('error uploading Image:', error);
          setUploadLoading(false);
        }
      }
    }
    uploadNewImage();
    
  }, [file]);

  const [modalData, setModalData] = useState({
    dbName: "客戶名稱+資料庫性質",
    img: "",
    dbClient: "",
    tags: "",
  });

  const createDataEntry = async (newGlossaryData) => {

    console.log('Content in newGlossaryData, creating a glossary:', newGlossaryData)

    const response = await axiosInstance.post(`/knovia/db`, newGlossaryData);
    console.log('Added new glossary successfully:', newGlossaryData.dbName);

    // Resetting States
    setFile(null);
    setFilename('');
    setImageUrl('');
    setUploadStatus('');

  }

  // Runs isAddNewDbModalOpen changes
  useEffect(() => {
    if (!isAddNewDbModalOpen) {
      // Reset states when the modal is closed
      setFile(null);
      setFilename('');
      setImageUrl('');
      setUploadStatus('');
    }
  }, [isAddNewDbModalOpen]); 

  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const textareaRefs = useRef({});

  const adjustHeight = () => {
    Object.keys(textareaRefs.current).forEach((key) => {
      const textarea = textareaRefs.current[key];
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
        if(textarea.scrollHeight < 54){
          textarea.style.height = `30px`;
        }
      }
    });
  }

  useEffect(() => {
    if (isAddNewDbModalOpen) {
      setTimeout(() => {
        adjustHeight();
      }, 0);
    }
  }, [isAddNewDbModalOpen, modalData]);

  return (
    <React.Fragment>

      {/* Modal for adding new datasets */}
      <Modal isOpen={isAddNewDbModalOpen} toggle={() => setIsAddNewDbModalOpen(!isAddNewDbModalOpen)}>

        {/* Modal Header */}
        <ModalHeader toggle={() => setIsAddNewDbModalOpen(!isAddNewDbModalOpen)}>新增資料庫</ModalHeader>

        {/* Modal Body */}
        <ModalBody>

            {orderedKeys.map((key) => (
              <div key={key} style={{ marginBottom: "15px" }}>
                <p>{keyToLabelMapping[key] || key}:</p>
                
                {/* Conditionally render select or textarea */}

                {key === "img" ? (
                  // Render component for 'img' key
                  <div>

                    <Row>
                      <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                      />
                      <Col sm="4">
                        <button className="btn btn-outline-info" onClick={handleButtonClick}>
                          {uploadLoading &&
                            (<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>)
                          }
                          選擇圖片
                        </button>
                      </Col>
                      <Col sm="8" style={{display:"flex", alignItems:"center"}}>
                        <span>{filename}</span>
                      </Col>
                      
                    </Row>
                    <Row>
                      <Col sm="12">
                        {uploadStatus && (
                          <p style={{
                            color: uploadStatus === 'File uploaded successfully!' ? 'mediumseagreen' :
                                  (uploadStatus === 'Failed to upload file.' || uploadStatus === 'Error occurred during upload.') ? 'red' : 
                                  'black' // Replace 'defaultColor' with the color you want for other statuses
                          }}>
                            {uploadStatus}
                          </p>
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <div>
                        {imageUrl && <img src={imageUrl} className="profile-pic" alt="Fetched" />}
                      </div>
                    </Row>

                  </div>


                ) : selectOptionsMapping[key] ? (
                  <select
                    value={modalData[key]}
                    onChange={(e) => {
                      setModalData((prevData) => ({
                        ...prevData,
                        [key]: e.target.value
                      }));
                    }}
                    className="form-control"
                  >
                    {selectOptionsMapping[key].map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                ) : (
                  <textarea
                    ref={(el) => { textareaRefs.current[key] = el; }}
                    value={modalData[key]}
                    onChange={(e) => {
                      setModalData((prevData) => ({
                        ...prevData,
                        [key]: e.target.value
                      }));
                    }}
                    style={{ width: "100%", resize: 'none', overflow: 'hidden', height: '30px' }}
                    className="form-control"
                  />
                )}
                
              </div>
            ))}

        </ModalBody>

        {/* Modal Footer */}
        <ModalFooter>
          <Button 
            color="primary" 
            onClick={() => {
              createDataEntry(modalData);
              setIsAddNewDbModalOpen(false);
            }}
          >
            儲存變更
          </Button>
          <Button color="secondary" onClick={() => setIsAddNewDbModalOpen(false)}>取消變更</Button>
        </ModalFooter>

      </Modal>

    </React.Fragment>
  );
};

export default NewDataModal;


